import { useRef, useEffect } from 'react'
import { compose } from 'ramda'
import { withFormik, connect } from 'formik'
import classnames from 'classnames'

import { Form, FormErrorMessages } from '../../form'
import { parseGraphqlErrors } from '../../../helpers/error'
import {
  InitialsAvatar,
  Button,
  LoadingMessage
} from '../../misc'
import { formatDateAndTime } from './../../../helpers/date'
import { buildInitialValues } from '../../../helpers/form'
import { taskCommentSchema } from '../../../schemas/tasks'
import { TextAreaExpandable } from '../../misc/TextAreaExpandable'
import { TaskCommentActionsMenu } from '../TaskCommentActionsMenu'
import { TaskCommentDetails } from '../TaskCommentDetails'

import './TaskCommentForm.less'

const TaskCommentForm = ({
  currentUser,
  className,
  values,
  resetForm,
  handleSubmit,
  inputName,
  isEditing,
  setIsEditing,
  placeholder,
  loading,
  mutationError,
  errors,
  comment,
  isUpdate,
  setErrors,
  taskId,
  canDoActions
}) => {
  const inputRef = useRef(null)
  const actionsRef = useRef(null)
  const isError = Object.values(errors).some(err => !!err)
  const hasValue = values[inputName]?.length

  const executeScroll = () => {
    actionsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  useEffect(() => {
    if (isEditing) {
      setTimeout(executeScroll, 220)
    }
  }, [executeScroll, isEditing])

  const handleEdit = () => {
    setIsEditing(true)
    inputRef.current.focus()
  }

  const handleEsc = (event) => {
    if (event?.key === 'Escape') {
      inputRef.current.blur()
      setIsEditing(false)
    }
  }

  const handleCancel = () => {
    resetForm()
    setIsEditing(false)
  }

  const handleOnBlur = () => {
    if (!isUpdate) {
      !hasValue && setIsEditing(false)
    }
  }

  const handleOnFocus = () => {
    if (!isUpdate) {
      setIsEditing(true)
    }
  }

  return (
    <Form
      className={classnames(
        'comment-form',
        className
      )} onSubmit={handleSubmit}
    >
      <InitialsAvatar {...{
        user: comment?.updatedBy || currentUser,
        size: InitialsAvatar.SIZES.LG
      }}
      />
      <div className='comment-form__content'>
        {isUpdate && (
          <div className='comment-form__content__header'>
            <div className='comment-form__content__header__info'>
              <span>{comment?.updatedBy?.fullName}</span>|
              <span>{formatDateAndTime(comment?.updatedAt)}</span>
            </div>
            {canDoActions && isUpdate && !isEditing && (
              <TaskCommentActionsMenu
                handleEdit={handleEdit}
                removeProps={{
                  taskId,
                  setErrors,
                  commentId: comment?.id
                }}
              />
            )}
          </div>
        )}

        {isUpdate && <TaskCommentDetails
          className='comment-form__content__details'
          isEditing={isEditing}
          text={comment?.textContent || placeholder}
                     />}

        <TextAreaExpandable {...{
          name: inputName,
          isEditing,
          handleOnBlur,
          handleOnFocus,
          inputProps: {
            placeholder,
            inputRef,
            handleEsc,
            handleOnLongPress: handleEdit,
            rows: 3
          },
          className: classnames('comment-form__content__textarea', {
            'comment-form__content__textarea--hidden': isUpdate && !isEditing
          })
        }}
        />

        <div className={classnames('comment-form__content__controls', {
          'comment-form__content__controls--show-msg': isError || loading
        })}
        >
          {loading && <LoadingMessage text={`${isUpdate ? 'Saving...' : 'Creating...'}`} />}
          {isError && <FormErrorMessages
            validationErrors={errors}
            serverError={mutationError}
            serverErrorMessage={mutationError?.message}
                      />}

          <div
            ref={actionsRef} className={classnames('comment-form__content__buttons-container', {
              'comment-form__content__buttons-container--show': isEditing
            })}
          >
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              color={Button.Colors.BLUE_TEXT}
              disabled={!hasValue}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

const EnhancedTaskCommentForm = compose(
  withFormik({
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: ({ comment = {}, inputName }) => buildInitialValues(
      taskCommentSchema, { inputName, ...comment }
    ),
    handleSubmit: async (
      { inputName, [inputName]: inputValue },
      { props: { onSubmit, setIsEditing, isUpdate }, resetForm, setErrors }
    ) => {
      try {
        await onSubmit({ [inputName]: inputValue })
        resetForm()
        setIsEditing(false)
      } catch (error) {
        setErrors(parseGraphqlErrors(
          error,
          `There was an error ${isUpdate ? 'creating' : 'saving'
          } the comment, please try again later`
        ))
      }
    }
  }),
  connect
)(TaskCommentForm)

export { EnhancedTaskCommentForm as TaskCommentForm }
