const UserIcon = (props) => (
  <svg viewBox='0 0 24 24' height={24} width={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M16 14c2.1421954 0 3.8910789 1.6839685 3.9951047 3.8003597L20 18v2c0 .5522847-.4477153 1-1 1-.5128358 0-.9355072-.3860402-.9932723-.8833789L18 20v-2c0-1.0543618-.8158778-1.9181651-1.8507377-1.9945143L16 16H8c-1.0543618 0-1.91816512.8158778-1.99451426 1.8507377L6 18v2c0 .5522847-.44771525 1-1 1-.51283584 0-.93550716-.3860402-.99327227-.8833789L4 20v-2c0-2.1421954 1.68396847-3.8910789 3.80035966-3.9951047L8 14h8zM12 3c2.7614237 0 5 2.23857625 5 5 0 2.7614237-2.2385763 5-5 5-2.76142375 0-5-2.2385763-5-5 0-2.76142375 2.23857625-5 5-5zm0 2c-1.6568542 0-3 1.34314575-3 3s1.3431458 3 3 3 3-1.34314575 3-3-1.3431458-3-3-3z'
      />
    </g>
  </svg>
)

export default UserIcon
