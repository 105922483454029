import { useMemo } from 'react'
import * as yup from 'yup'
import { stringSchema } from '../../../../schemas/helpers'
import { useWorkflowGql } from './../../../../hooks/useWorkflowGql'
import { pipe, isNil, prop, ifElse, always, pick, map } from 'ramda'
import { WorkflowTargetTypes } from '@elenfs/elen-constants'

import { PlusIcon } from '../../../icons'
import { SelectField } from '../../../form/fields'
import { DynamicFormModal, Button } from '../../../misc'

const validationSchema = yup.object().shape({
  workflowTemplateId: stringSchema().required()
})

const buildOptions = ifElse(
  isNil,
  always([]),
  pipe(
    prop('workflowTemplates'),
    map(pick(['name', 'id'])),
    map(v => ({ label: v.name, value: v.id }))
  )
)

const TriggerWorkflowTemplateModalButton = props => {
  const { fetchClientWorkflowInstances, clientId } = props

  const {
    fetchLoading,
    fetchError,
    fetchWorkflowTemplates,
    workflowTemplatesData,
    triggerWorkflowTemplateRun,
    mutationLoading,
    mutationError
  } = useWorkflowGql()

  const modalProps = useMemo(
    () => ({
      formProps: {
        inputsArr: [
          [
            {
              InputComponent: SelectField,
              inputProps: {
                options: buildOptions(workflowTemplatesData),
                name: 'workflowTemplateId'
              }
            }
          ]
        ],
        error: fetchError || mutationError,
        loading: fetchLoading || mutationLoading,
        onSubmit: async ({ workflowTemplateId }) => {
          try {
            await triggerWorkflowTemplateRun({
              variables: {
                input: {
                  workflowTemplateId,
                  targetType: WorkflowTargetTypes.CLIENT,
                  targetId: clientId
                }
              }
            })
            fetchClientWorkflowInstances({ variables: { clientId } })
          } catch (error) {}
        },
        submitButtonLabel: 'Trigger',
        validationSchema
      },
      heading: 'Select a workflow template to trigger',
      onOpenModal: fetchWorkflowTemplates
    }),
    [
      workflowTemplatesData,
      fetchWorkflowTemplates,
      fetchLoading,
      fetchError,
      clientId,
      mutationLoading,
      mutationError
    ]
  )

  return (
    <DynamicFormModal.Trigger
      as={Button}
      color={Button.Colors.BLUE}
      modalProps={modalProps}
      responsive
    >
      <PlusIcon /> <span>Trigger workflow</span>
    </DynamicFormModal.Trigger>
  )
}

export { TriggerWorkflowTemplateModalButton }
