import { useCallback, useMemo } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'

import { Spinner, ErrorMessage } from './../../../misc'
import { AccountOverviewPage } from './AccountOverviewPage'
import { withAccountOwner } from '../../../hoc/container'

import activeSubscriptionQueryGql from '../../../../graphql/queries/stripeSubscriptions/activeSubscription.graphql'
import updateSubscriptionSeatsGql from '../../../../graphql/mutations/stripeSubscriptions/updateSubscriptionSeats.graphql'
import cancelSubscriptionGql from '../../../../graphql/mutations/stripeSubscriptions/cancelSubscription.graphql'
import usersGql from '../../../../graphql/queries/user/users.graphql'
import customerPortalUrlGql from '../../../../graphql/queries/stripeSubscriptions/customerPortalUrl.graphql'

import {
  getTrialDaysRemaining,
  getBillingDateAfterTrial
} from './../../../../helpers/stripe'
import { splitToActiveAndInactiveByStatus } from '../../../../helpers/users'
import { formatCouponAsText, toMajorUnits } from '../helpers'

const AccountOverviewPageContainer = () => {
  const {
    loading,
    data,
    error,
    refetch
  } = useQuery(activeSubscriptionQueryGql)

  const { data: usersData } = useQuery(usersGql)
  const [updateSeats, {
    loading: updateSeatsLoading,
    error: updateSeatsError
  }] = useMutation(updateSubscriptionSeatsGql, { onCompleted: refetch })

  const [cancelSubscription] = useMutation(cancelSubscriptionGql, { onCompleted: refetch })

  const {
    id,
    interval,
    pricePerSeat,
    seats,
    status,
    company,
    currentPeriodStart,
    currentPeriodEnd,
    trialStartedAt,
    trialPeriodDays
  } = data?.activeSubscription || {}

  const upcomingInvoice = data?.upcomingInvoice || {}

  const [getCustomerPortalUrl, {
    called: customerPortalUrlCalled,
    loading: customerPortalUrlLoading,
    error: customerPortalUrlError
  }] = useLazyQuery(customerPortalUrlGql, {
    onCompleted: ({ customerPortalUrl }) => (
      window.location.assign(customerPortalUrl)
    )
  })

  const onGetCustomerPortalUrl = useCallback(
    async () => {
      if (
        id &&
        !customerPortalUrlCalled &&
        !customerPortalUrlLoading
      ) {
        await getCustomerPortalUrl({
          variables: {
            input: {
              subscriptionId: id,
              returnUrl: window.location.href
            }
          }
        })
      }
    }, [
      id,
      getCustomerPortalUrl,
      customerPortalUrlCalled,
      customerPortalUrlLoading
    ])

  const customerPortalProps = {
    customerPortalUrlLoading,
    customerPortalUrlError,
    onGetCustomerPortalUrl
  }

  const onUpdateSeats = useCallback(
    (values) => {
      updateSeats({ variables: { input: { id, ...values } } })
    }, [id, updateSeats]
  )

  const { activeUserList } = splitToActiveAndInactiveByStatus(usersData?.users)

  const onCancelSubscription = useCallback(
    () => cancelSubscription({ variables: { subscriptionId: id } }),
    [id]
  )

  const accountOwners = useMemo(
    () =>
      usersData?.users
        .filter(({ isAccountOwner, enabled }) => isAccountOwner && enabled)
        .map(({ fullName }) => fullName) || [],
    [usersData]
  )

  const onDeleteAccount = useCallback(() => console.log('onDeleteAccount'), [])

  const accountDetailsData = {
    accountOwners,
    companyName: company?.name,
    companySubdomain: company?.subdomain
  }

  const subscriptionPlanData = {
    status,
    couponText: formatCouponAsText(
      upcomingInvoice?.discount?.coupon,
      loading,
      error
    ),
    costPerLicense: {
      licenseType: interval,
      licenseFee: pricePerSeat
    },
    totalLicenses: seats,
    totalFee: {
      ...pricePerSeat,
      amount: toMajorUnits(upcomingInvoice?.total)
    },
    currentPeriodStart,
    currentPeriodEnd,
    trialDaysRemaining: getTrialDaysRemaining({
      status,
      trialStartedAt,
      trialPeriodDays
    }),
    billingDateAfterTrial: getBillingDateAfterTrial({
      status,
      trialStartedAt,
      trialPeriodDays
    })
  }

  if (loading && !data) return <Spinner />
  if (error) return <ErrorMessage text='Failed to load data' />

  return (
    <AccountOverviewPage
      {...{
        accountDetailsData,
        subscriptionPlanData,
        onDeleteAccount,
        onCancelSubscription,
        onUpdateSeats,
        updateSeatsLoading,
        updateSeatsError,
        activeUsers: activeUserList?.length,
        getCustomerPortalUrl,
        onGetCustomerPortalUrl,
        customerPortalProps
      }}
    />
  )
}

const AccountOverviewPageContainerWithAccountOwner = withAccountOwner(
  AccountOverviewPageContainer
)

export { AccountOverviewPageContainerWithAccountOwner as AccountOverviewPageContainer }
