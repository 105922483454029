import { useState, useCallback } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

const ModalStickyContent = ({
  children,
  className,
  ...restProps
}) => {
  const [isTopShadowVisible, setIsTopShadowVisible] = useState(false)
  const [isBottomShadowVisible, setIsBottomShadowVisible] = useState(true)

  const handleScroll = useCallback((e) => {
    const scrollFarFromTop = e.target.scrollTop
    const scrollOnEndBottom = (e.target.scrollTop + e.target.clientHeight + 1)
      .toFixed() - e.target.scrollHeight <= 0

    if (scrollFarFromTop) {
      setIsTopShadowVisible(true)
    } else {
      setIsTopShadowVisible(false)
    }

    if (scrollOnEndBottom) {
      setIsBottomShadowVisible(true)
    } else {
      setIsBottomShadowVisible(false)
    }
  }, [isTopShadowVisible, isBottomShadowVisible])

  return (
    <div
      onScroll={handleScroll} className={classnames('modal__sticky-layout__content', {
        'modal__sticky-layout__content--top-shadow': isTopShadowVisible,
        'modal__sticky-layout__content--bottom-shadow': isBottomShadowVisible,
        className
      })} {...restProps}
    >
      {children}
    </div>
  )
}

ModalStickyContent.propTypes = {
  children: T.node.isRequired,
  className: T.string
}

export { ModalStickyContent }
