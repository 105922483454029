import { useState, useRef, useEffect } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { Heading } from '../.'
import { DropdownIcon } from './../../icons'

import './CollapsableContainer.less'

const MD_BREAKPOINT = 767
const SM_BREAKPOINT = 576

const CollapsableContainer = props => {
  const {
    className,
    title,
    summary,
    children,
    listenToValues,
    valuesTransition,
    isClosedOnMobile,
    isSmallBreakPoint,
    autoMaxHeightOnInit
  } = props

  const mobileBreakPoint = isSmallBreakPoint ? SM_BREAKPOINT : MD_BREAKPOINT

  const [show, setShow] = useState(false)
  const [isInitialized, setInitialized] = useState(false)

  const animationWrapperRef = useRef()
  const contentWrapperRef = useRef()

  const setAnimationWrapperHeight = (isHide = false, isAuto = false) => {
    const contentHeight = contentWrapperRef.current.offsetHeight
    animationWrapperRef.current.style.maxHeight = contentHeight + 'px'

    animationWrapperRef.current.style.maxHeight =
      (isHide ? 0 : contentHeight) + 'px'

    if (isAuto) animationWrapperRef.current.style.maxHeight = 'inherit'
  }

  const toggleContent = (options) => {
    setShow(!show)
    setAnimationWrapperHeight(show, options?.autoMaxHeightOnInit)
  }

  const isMobile = currentWidth => currentWidth < mobileBreakPoint

  useEffect(() => {
    if (listenToValues.length) {
      if (valuesTransition) {
        setAnimationWrapperHeight(false, true)
        if (isInitialized) {
          setTimeout(() => setAnimationWrapperHeight(), valuesTransition)
        }
      } else {
        setAnimationWrapperHeight()
      }
    }
  }, [...listenToValues, valuesTransition])

  useEffect(() => {
    if (isClosedOnMobile) {
      if (isMobile(window.innerWidth)) {
        setAnimationWrapperHeight(true)
      } else {
        toggleContent({
          autoMaxHeightOnInit: !isInitialized && autoMaxHeightOnInit
        })
      }
    } else {
      toggleContent()
    }

    setInitialized(true)
  }, [isClosedOnMobile])

  useEffect(() => {
    let prevWindowWidth = window.innerWidth
    const onResize = () => {
      if (prevWindowWidth !== window.innerWidth) {
        prevWindowWidth = window.innerWidth
        setShow(false)
        setAnimationWrapperHeight(true, !isMobile(window.innerWidth))
      }
    }

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div
      className={classnames('collapsable-container', className, {
        'collapsable-container--small-breakpoint': isSmallBreakPoint
      })}
    >
      <div
        className={classnames('collapsable-container__header', {
          'collapsable-container__header--with-summary': summary
        })}
        onClick={toggleContent}
      >
        <Heading as='h3'> {title} </Heading>

        {summary && (
          <Heading as='h3' className={classnames({ 'fade-when-open': show })}>
            {summary}
          </Heading>
        )}

        <DropdownIcon
          className={classnames('collapsable-container__header__icon', {
            'collapsable-container__header__icon--rotate': show
          })}
        />
      </div>

      <div
        className={classnames('collapsable-container__animation-wrapper', {
          'collapsable-container__animation-wrapper--show': show
        })}
        ref={animationWrapperRef}
      >
        <div className='collapsable-container__content' ref={contentWrapperRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

CollapsableContainer.defaultProps = {
  listenToValues: [],
  valuesTransition: 0
}

CollapsableContainer.propTypes = {
  title: T.string.isRequired,
  summary: T.string,
  isClosedOnMobile: T.bool,
  // Values to listen to readjust the height of the animation wrapper
  listenToValues: T.array,
  // If there are is animation transition of the html elements related with values
  valuesTransition: T.number,
  isSmallBreakPoint: T.bool,
  autoMaxHeightOnInit: T.bool
}

export { CollapsableContainer }
