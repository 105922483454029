import T from 'prop-types'
import { last } from 'ramda'
import classnames from 'classnames'

import { getFileIcon } from '../../../helpers/clientDocument'
import { CloseModalIcon, ExclamationErrorIcon, RetryIcon } from '../../icons'
import { Spinner } from '../../misc'
import { AttachmentListActionMenu } from './AttachmentListActionMenu'
import { RemoveAttachmentConfirmation } from './RemoveAttachmentConfirmation'

import './AttachmentList.less'

const spinnerConfig = {
  lines: 7,
  length: 3,
  width: 3,
  radius: 4,
  position: 'relative'
}

const fileNameBaseClass = 'attachment-list__file__info__file-name'

const FileIcon = ({ fileName }) => {
  const Icon = getFileIcon(last(fileName.split('.')))
  return <Icon />
}

const AttachmentList = ({
  attachments,
  handleDownload,
  onRemoveAttachment,
  fileRemoveLoading,
  uploadFile,
  className
}) => (
  <>
    {attachments?.map(
      ({ id, fileId, fileName, isLoading, isError, file, uploadUrl }, index) => {
        return (
          <div
            key={index} className={classnames(
              'attachment-list__file-wrapper',
              className
            )}
          >
            <div className='attachment-list__file'>
              <div className='attachment-list__file__info'>
                {(!isError && !isLoading) && <FileIcon fileName={fileName} />}
                {isLoading && <Spinner config={spinnerConfig} />}
                {(isError && !isLoading) && (
                  <ExclamationErrorIcon
                    className='attachment-list__file__info__error-icon'
                  />
                )}
                <span
                  className={classnames(
                    fileNameBaseClass,
                    isLoading && `${fileNameBaseClass}--uploading`
                  )}
                >
                  {fileName}
                </span>
              </div>

              {
                id
                  ? (
                    <AttachmentListActionMenu
                      className='attachment-list__file__actions-menu'
                      onRemoveAttachment={() => onRemoveAttachment(id, fileName)}
                      handleDownload={() => handleDownload && handleDownload(fileId)}
                    />
                    )
                  : (
                    <div className='attachment-list__file__buttons'>
                      {isError &&
                        <RetryIcon
                          className='attachment-list__file__buttons__retry'
                          onClick={() => uploadFile && uploadFile(uploadUrl, file)}
                        />}
                      <RemoveAttachmentConfirmation
                        Component={CloseModalIcon}
                        className='attachment-list__file__buttons__remove'
                        onRemoveAttachment={() =>
                          !fileRemoveLoading && onRemoveAttachment(id, fileName)}
                      />
                    </div>
                    )
              }

            </div>
            {isError && (
              <span className='file-upload-error-msg'>
                Failed to upload. Review the import file, and then try again.
              </span>
            )}
          </div>
        )
      }
    )}
  </>
)

AttachmentList.defaultProps = {
  attachments: []
}

AttachmentList.propTypes = {
  attachments: T.arrayOf(
    T.shape({
      fileName: T.string.isRequired,
      fileId: T.string,
      id: T.string,
      file: T.instanceOf(window.File),
      isLoading: T.bool,
      isError: T.bool,
      uploadUrl: T.string
    })
  ).isRequired,
  onRemoveAttachment: T.func.isRequired,
  uploadFile: T.func
}

export { AttachmentList }
