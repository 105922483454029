import { Button, Modal } from '../../misc'
import { PlusIcon } from '../../icons'
import { PolicyValuationDetailsModal } from './PolicyValuationDetailsModal'

const NewPolicyValuationButton = ({ policyId }) => (
  <Modal.Trigger
    as={Button}
    color={Button.Colors.BLUE}
    modal={PolicyValuationDetailsModal}
    modalProps={{ policyId }}
  >
    <PlusIcon />
    <span>Add valuation</span>
  </Modal.Trigger>
)

export { NewPolicyValuationButton }
