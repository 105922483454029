import Card from '../../../misc/Card'
import Summary from '../../../misc/Summary'

const AmountCard = ({ headingText, headingValue, cardData }) => (
  <Card className='policy-details__card'>
    <Card.Group className='card-new-group--inline'>
      <Card.Heading>
        {headingText}
      </Card.Heading>
      <Card.Heading>
        {headingValue}
      </Card.Heading>
    </Card.Group>

    <Summary
      className='summary__row--full-view'
      data={cardData}
    />
  </Card>
)

export default AmountCard
