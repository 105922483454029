import { SelectField } from '../../form/fields'
import { taskStatusOptionsColored } from '../../tasks/const'
import { ControlWithColoredFill } from '../DropdownSelection/DropdownCustom'
import { withFormik } from 'formik'
import { compose } from 'ramda'

export const TaskStatus = (props) => {
  return (
    <SelectField
      name='status'
      options={taskStatusOptionsColored}
      inputProps={{ isClearable: false, isSearchable: false }}
      className='dropdown-selection__control__coloured_no_indicator'
      selectProps={{ Control: ControlWithColoredFill }}
      {...props}
    />
  )
}

export const TaskStatusStory = compose(
  withFormik({})
)(TaskStatus)
