const AccountIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M16 14a4 4 0 013.995 3.8L20 18v2a1 1 0 01-1.993.117L18 20v-2a2 2 0 00-1.85-1.995L16 16H8a2 2 0 00-1.995 1.85L6 18v2a1 1 0 01-1.993.117L4 20v-2a4 4 0 013.8-3.995L8 14h8zM12 3a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { AccountIcon }
