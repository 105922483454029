import classnames from 'classnames'
import { components } from 'react-select'

import { ActivityLogEntryTypes } from '@elenfs/elen-constants'
import { TaskIcon } from '../../TaskIcon'

import './DropdownCustom.less'

const { WORK_ON_FILE } = ActivityLogEntryTypes

const ControlWithActivity = ({
  children,
  className,
  cx,
  getStyles,
  getValue,
  innerProps,
  isFocused,
  isDisabled,
  isSelected,
  selectProps,
  ...rest
}) => {
  let iconType = WORK_ON_FILE

  if (getValue()?.length) {
    iconType = getValue()?.[0]?.[selectProps?.asyncProps?.valueProp || 'value']
  }

  return (
    <components.Control
      {...{
        cx,
        getStyles,
        getValue,
        innerProps,
        css: getStyles('control', rest),
        className: classnames('dropdown-selection__control--with-icon', cx(
          {
            option: true,
            'control--is-disabled': isDisabled,
            'control--is-focused': isFocused,
            'control--is-selected': isSelected
          },
          className
        )),
        ...rest
      }}
    >
      <TaskIcon type={iconType} />
      {children}
    </components.Control>
  )
}

export { ControlWithActivity }
