import T from 'prop-types'

import ClientAuditLogCard from './ClientAuditLogCard'
import ClientNotesCard from './ClientNotesCard'
import PageContent from '../../misc/PageContent'
import { PageDivider } from '../../misc'
import ClientPortalAccess from './ClientPortalAccess'
import { ClientActions } from './ClientActions'
import { ClientWorkflowInstancesCard } from './ClientWorkflowInstancesCard'

import './ClientSummaryPage.less'

const ClientSummaryPage = ({
  auditLogEntries,
  client,
  refetch,
  ClientSpecificDetailsCard
}) => (
  <PageContent withMaxWidthXXXL>
    <div className='client-summary'>
      <div className='client-summary__content'>
        <div className='client-summary__content__column client-summary-content__column__column-1'>
          <ClientSpecificDetailsCard client={client} />
          <ClientPortalAccess clientEmail={client.email} clientId={client.id} />
        </div>
        <div className='client-summary__content__column client-summary__content__column__column-2'>
          <ClientNotesCard client={client} />
          <ClientAuditLogCard clientId={client.id} auditLogEntries={auditLogEntries} refetch={refetch} />
          <ClientWorkflowInstancesCard clientId={client.id} />
        </div>
      </div>
    </div>
    <PageDivider className='client-summary__divider' />
    <ClientActions client={client} />
  </PageContent>
)

ClientSummaryPage.propTypes = {
  client: T.object.isRequired,
  auditLogEntries: T.arrayOf(T.object).isRequired
}

export { ClientSummaryPage }
