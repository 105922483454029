import * as yup from 'yup'

import stringSchema from '../helpers/stringSchema'

const customerSupportFormSchema = yup.object().shape({
  reason: stringSchema()
    .label('Reason')
    .nullable()
    .required(),

  subject: stringSchema()
    .label('Subject')
    .max(255, 'Subject exceeds maximum number of characters (255)')
    .required(),

  message: stringSchema()
    .label('Message')
    .required()
})

export { customerSupportFormSchema }
