import * as yup from 'yup'
import stringSchema from '../helpers/stringSchema'

const p1ImportSchema = yup.object().shape({
  login: stringSchema()
    .label('Email')
    .required(),

  password: stringSchema()
    .label('Password')
    .required()

}).noUnknown(true)

export { p1ImportSchema }
