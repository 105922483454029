import T from 'prop-types'
import { pluck } from 'ramda'

import { useOnChangeCallback } from '../../../form/inputs/hooks'
import { DropdownSelection } from '../../../misc/DropdownSelection'
import {
  DropdownSelectionWithAsync
} from '../../../misc/DropdownSelection/DropdownSelectionWithAsync'
import { BaseField } from '../BaseField'

const SelectField = ({
  options,
  value,
  handleChange,
  inputProps,
  name,
  error,
  isMulti,
  isGrouped,
  label,
  selectProps,
  inputId,
  className,
  required,
  optionsToResolve
}) => {
  const Component = inputProps?.async
    ? DropdownSelectionWithAsync
    : DropdownSelection

  const extractValueFn = (selectedValue) => {
    if (isMulti) {
      return selectedValue
        ? pluck(inputProps?.asyncProps?.valueProp || 'value', selectedValue)
        : []
    } else {
      return selectedValue
        ? selectedValue[inputProps?.asyncProps?.valueProp || 'value']
        : null
    }
  }

  return (
    <BaseField {...{ error, label, className, required }}>
      <Component
        {...{
          name,
          options,
          optionsToResolve,
          onChange: useOnChangeCallback(handleChange, extractValueFn),
          selected: value,
          inputProps,
          selectProps,
          isMulti,
          isGrouped,
          inputId
        }}
      />
    </BaseField>
  )
}

SelectField.propTypes = {
  /**
   * Options to be passed to the DropdownSelection.
   */
  options: T.arrayOf(
    T.shape({
      label: T.string.isRequired,
      value: T.any,
      options: T.array
    })
  ),
  /**
   * Value to be passed to the DropdownSelection.
   */
  value: T.any,
  /**
   * Props to be passed to the DropdownSelection.
   */
  inputProps: T.object,
  /**
   * Function that receives new values from the DropdownSelection.
   */
  handleChange: T.func,
  /**
   * Error from Formik.
   */
  error: T.string,
  /**
   * Label to be displayed in the field.
   */
  label: T.string,
  /**
   * Allow selecting multiple values.
   */
  isMulti: T.bool,
  /**
   * Color selected value for selected option if provided.
   */
  isSelectedColoured: T.bool,
  /**
   * Pass this prop to make sure no duplicates if iterating.
   */
  inputId: T.string
}

export { SelectField }
