import {
  cloneElement,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'

import './Dropdown.less'

const windowEventsToListen = ['click', 'touchstart']
const mobileBreakPointWidth = 767

const Dropdown = ({
  children,
  className,
  contentClassName,
  trigger,
  behaveAsModalOnMobile,
  closeIconClass
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperEl = useRef(null)
  const location = useLocation()

  const isMobileModalOpened = useMemo(
    () =>
      behaveAsModalOnMobile &&
      window.innerWidth <= mobileBreakPointWidth &&
      isOpen,
    [behaveAsModalOnMobile, isOpen, mobileBreakPointWidth]
  )

  useEffect(() => {
    let unListen
    if (isMobileModalOpened) {
      unListen = () => setIsOpen(false)
    }
    return () => unListen && unListen()
  }, [location, isMobileModalOpened])

  const handleToggleClick = useCallback(
    (e) => {
      const isNotModalCloseIcon = !e.target?.classList?.contains(closeIconClass)
      if (isMobileModalOpened && isNotModalCloseIcon) {
        return null
      }

      setIsOpen(!isOpen)
    },
    [isOpen, isMobileModalOpened, closeIconClass]
  )

  const handleWindowClick = useCallback(
    (e) => {
      const { current } = wrapperEl
      const { target } = e
      if ((target !== current) && !current?.contains(target)) {
        setIsOpen(false)
      }
    },
    [isOpen]
  )

  useEffect(() => {
    windowEventsToListen.forEach(event =>
      window.addEventListener(event, handleWindowClick))

    return () => {
      windowEventsToListen.forEach(event =>
        window.removeEventListener(event, handleWindowClick))
    }
  }, [handleWindowClick])

  const enhancedTrigger = cloneElement(trigger, {
    onClick: handleToggleClick
  })

  return (
    <div
      className={classnames('dropdown', className, {
        'dropdown--open': isOpen,
        [`${className}--open`]: className && isOpen
      })}
      ref={wrapperEl}
    >
      {enhancedTrigger}
      <div
        onClick={handleToggleClick}
        className={classnames('dropdown__content', contentClassName, {
          'dropdown__content--open': isOpen,
          [`${contentClassName}--open`]: contentClassName && isOpen
        })}
      >
        {children}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  closeIconClass: PropTypes.string,
  behaveAsModalOnMobile: PropTypes.bool
}

export default Dropdown
