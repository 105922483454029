import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { TaskCommentForm } from './TaskCommentForm'
import taskCommentsGql from '../../../graphql/queries/tasks/taskComments.graphql'
import createTaskCommentGql from '../../../graphql/mutations/tasks/createTaskComment.graphql'
import updateTaskCommentGql from '../../../graphql/mutations/tasks/updateTaskComment.graphql'
import { onlyAccountOwnerOrAdminAccess } from '../../hoc/container/WithPermissionCheck'

const createTaskComment = (taskId) => useMutation(createTaskCommentGql, {
  update: (cache, { data }) => {
    try {
      const { createTaskComment: comment } = data
      const { taskComments, ...restCache } = cache.readQuery({
        query: taskCommentsGql,
        variables: { taskId }
      })
      cache.writeQuery({
        query: taskCommentsGql,
        variables: { taskId },
        data: {
          ...restCache,
          taskComments: taskComments.concat([comment])
        }
      })
    } catch (error) {
      // pass
    }
  }
})

const TaskCommentFormContainer = ({
  comment,
  taskId,
  currentUser,
  ...restProps
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const isUpdate = comment && comment.id
  const isCommentOwner = comment?.createdBy?.id === currentUser?.id
  const isAdmin = onlyAccountOwnerOrAdminAccess(currentUser)
  const canDoActions = isAdmin || isCommentOwner

  let useMutationResult
  if (!isUpdate) {
    useMutationResult = createTaskComment(taskId)
  } else {
    useMutationResult = useMutation(updateTaskCommentGql)
  }

  const [mutate, mutationState] = useMutationResult

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          taskId,
          ...isUpdate && { id: comment?.id },
          ...values
        }
      }
    })
  }

  return (
    <TaskCommentForm {...{
      ...restProps,
      mutate,
      onSubmit,
      mutationState,
      isEditing,
      setIsEditing,
      comment,
      isUpdate,
      inputName: 'textContent',
      taskId,
      currentUser,
      canDoActions
    }}
    />
  )
}

export { TaskCommentFormContainer }
