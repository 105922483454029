import { FieldArray, connect } from 'formik'

import AddNewFormRowButton from '../../../misc/AddNewFormRowButton'
import { PolicyHoldersSectionInputRow } from './PolicyHoldersSectionInputRow'
import { companyClient } from '../../../../helpers/propTypes'

import './PolicyHoldersSection.less'

const PolicyHoldersSection = ({
  currentClient,
  formik: {
    values: { currentHoldersIds = [] }
  }
}) => (
  <FieldArray
    validateOnChange={false}
    name='currentHoldersIds'
    render={(arrayHelpers) => (
      <div className='policy-holders-section'>
        {currentHoldersIds.map((policyHolder, index) => (
          <PolicyHoldersSectionInputRow
            key={index}
            index={index}
            arrayHelpers={arrayHelpers}
            currentHoldersIds={currentHoldersIds}
            policyHolder={policyHolder}
            currentClient={currentClient}
          />
        ))}
        <AddNewFormRowButton
          label='add policy holder'
          onClick={() => arrayHelpers.push('')}
        />
      </div>
    )}
  />
)

const PolicyHoldersSectionEnhanced = connect(PolicyHoldersSection)

PolicyHoldersSectionEnhanced.propTypes = {
  /**
   * Object with current client
   */
  currentClient: companyClient().isRequired
}

export { PolicyHoldersSectionEnhanced as PolicyHoldersSection }
