import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { Toastr } from '../../misc'
import { ImportSyncButton } from './ImportSyncButton'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

const ImportSyncButtonContainer = ({ gqlMutation, dataImportType }) => {
  const platform = DataImportPlatformLabels[dataImportType]

  const [mutate, mutationState] = useMutation(gqlMutation, {
    onCompleted: ({ syncP1 }) => {
      if (syncP1?.success) {
        toast(<Toastr
          type='success'
          title={`Syncing with ${platform} in progress`}
          content='This page will be updated once done'
              />)
      }
    }
  })

  return (
    <ImportSyncButton {...{
      mutate,
      ...mutationState
    }}
    />
  )
}

export { ImportSyncButtonContainer }
