const ShapeHorizontal2 = props => (
  <svg width={307} height={163} viewBox='0 0 307 163' {...props}>
    <g stroke='#FFF' fill='none' fillRule='evenodd'>
      <path d='M7.334 200c-5.722-28.101-9.898-58.723-1.897-85.799C15.919 78.73 45.004 58.863 73.176 54.312c28.171-4.552 56.536 2.971 84.83 5.866 28.292 2.894 58.593.39 81.913-21.36 32.341-30.162 42.956-88.94 72.138-124.458 24.334-29.617 61.065-39.384 91.943-24.444' />
      <path d='M28.988 200c-5.41-26.593-9.358-55.574-1.794-81.196 9.91-33.572 37.407-52.371 64.04-56.68 26.635-4.308 53.452 2.812 80.2 5.552 26.747 2.739 55.396.369 77.441-20.213 30.577-28.546 40.612-84.172 68.2-117.786 23.006-28.028 57.732-37.27 86.925-23.132' />
      <path d='M49.659 200c-5.113-24.998-8.843-52.24-1.696-76.324 9.364-31.558 35.345-49.229 60.511-53.279 25.166-4.049 50.505 2.644 75.779 5.218 25.274 2.575 52.342.347 73.173-19 28.89-26.833 38.373-79.121 64.442-110.718C343.605-80.45 376.418-89.137 404-75.848' />
      <path d='M71.312 200c-4.799-23.668-8.301-49.46-1.591-72.265 8.792-29.879 33.185-46.61 56.812-50.444 23.629-3.834 47.42 2.503 71.148 4.94 23.73 2.438 49.144.33 68.702-17.99 27.125-25.405 36.027-74.912 60.504-104.828 20.409-24.946 51.215-33.17 77.113-20.588' />
      <path d='M92.967 200c-4.488-22.073-7.761-46.126-1.489-67.393 8.22-27.864 31.026-43.468 53.115-47.044 22.09-3.575 44.333 2.334 66.517 4.608 22.186 2.273 45.945.306 64.23-16.778 25.36-23.692 33.683-69.861 56.566-97.76 19.08-23.265 47.882-30.935 72.094-19.2' />
    </g>
  </svg>
)
export { ShapeHorizontal2 }
