import { Table, ColorsAvatar } from '../../../../misc'
import { TableHeaders } from '../constants'
import { UpdateServiceLevelsButton } from '../../UpdateServiceLevelsButton'

import './ServiceLevelsTableRow.less'
import { ColourTypesCodes } from '../../../../../constants/serviceLevels'

const ServiceLevelsTableRow = ({ serviceLevels, className }) => {
  const { colour, description, name } = serviceLevels

  const transformColorCode = (color) => ColourTypesCodes[color] ?? color

  const serviceColor = transformColorCode(colour)?.toLowerCase()

  return (
    <Table.Row className={className}>
      <Table.Cell
        data-label={TableHeaders.COLOUR}
        className='service-levels__table__row__cell'
      >
        <ColorsAvatar color={serviceColor} />
      </Table.Cell>
      <Table.Cell
        data-label={TableHeaders.NAME}
        className='service-levels__table__row__cell'
      >
        {name}
      </Table.Cell>
      <Table.Cell
        data-label={TableHeaders.DESCRIPTION}
        className='service-levels__table__row__cell'
      >
        {description}
      </Table.Cell>
      <Table.Cell
        data-label={TableHeaders.ACTION}
        className='service-levels__table__row__cell'
      >
        <Table.Actions>
          <UpdateServiceLevelsButton
            serviceLevel={serviceLevels}
          />
        </Table.Actions>
      </Table.Cell>
    </Table.Row>
  )
}

export { ServiceLevelsTableRow }
