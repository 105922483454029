import PropTypes from 'prop-types'
import { Table, TableResponsiveWrapper, Pagination } from '../../../misc'
import { ImportLogTableRow, ImportLogTableHeader } from './index'
import { sortListByProp } from '../../../../helpers/sort'

import './ImportLogTable.less'

export const ImportLogTable = (props) => {
  const { dataImportJobs, pageCount, handlePaginationChange, link, page } = props
  return (
    <div className='import-log-table'>
      <TableResponsiveWrapper>
        <Table className='import-log-table__content'>
          <colgroup>
            <col className='import-log-table__content__columns__status' />
            <col className='import-log-table__content__columns__date' />
          </colgroup>

          <Table.Header>
            <Table.Row>
              {Object.values(ImportLogTableHeader)?.map((item, index) => (
                <Table.HeaderCell key={index}>
                  {item}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {dataImportJobs && sortListByProp(dataImportJobs, 'completedAt').map(job => (
              <ImportLogTableRow
                job={job}
                key={job.id}
                link={link}
                page={page}
              />
            ))}
          </Table.Body>
        </Table>
      </TableResponsiveWrapper>

      {!!dataImportJobs?.length && pageCount > 1 && (
        <div className='import-log-table__pagination'>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePaginationChange}
            forcePage={page - 1}
          />
        </div>
      )}
    </div>
  )
}

ImportLogTable.propTypes = {
  dataImportJobs: PropTypes.array.isRequired,
  pageCount: PropTypes.number,
  handlePaginationChange: PropTypes.func
}
