import { compose } from 'ramda'
import T from 'prop-types'

import { withCurrentUser } from '../../hoc/container'
import CurrentUserMenu from './CurrentUserMenu'

const CurrentUserMenuContainer = compose(
  withCurrentUser()
)(CurrentUserMenu)

CurrentUserMenuContainer.defaultProps = {
  logoutRedirect: '/login'
}

CurrentUserMenuContainer.propTypes = {
  logoutRedirect: T.string
}

export default CurrentUserMenuContainer
