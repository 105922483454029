import T from 'prop-types'

import ImportPlatformLayout from '../ImportPlatformLayout'
import { PraemiumGuide } from '../PraemiumGuide'
import { ImportFooterConnection, ImportGuide } from '../ImportFooter'
import { Card } from './../../misc'
import AppConfig from '../../../appConfig'
import { PraemiumImportForm } from '../PraemiumImportForm'

const PraemiumImportPage = ({ data, onCustomerSupportClick }) => {
  const pageTitle = 'Morningstar Wealth Platform'
  return (
    <ImportPlatformLayout pageTitle={pageTitle}>
      <ImportPlatformLayout.LeftColumn>
        <Card.Group>
          <Card>
            <PraemiumImportForm {...data} />
          </Card>
          <Card>
            <ImportFooterConnection
              pageTitle={`${pageTitle} import data`}
              link='/secure/admin/morningstar/logs'
            />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.LeftColumn>

      <ImportPlatformLayout.RightColumn>
        <Card.Group>
          <Card>
            <Card.Heading>Morningstar Wealth Platform</Card.Heading>
            <PraemiumGuide />
          </Card>
          <Card>
            <ImportGuide link={AppConfig.PRAEMIUM_IMPORT_STEP_BY_STEP_LINK} />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.RightColumn>
    </ImportPlatformLayout>
  )
}

PraemiumImportPage.propTypes = {
  data: T.shape({
    id: T.string,
    isActive: T.bool,
    type: T.string
  })
}

export { PraemiumImportPage }
