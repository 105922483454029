const PinIcon = props => (
  <svg width={24} height={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M7.501 4.636a7.038 7.038 0 015.76-1.527c2.881.506 5.206 2.882 5.654 5.778a6.991 6.991 0 01-1.31 5.31L12.8 20.6a1 1 0 01-1.6 0l-4.804-6.404A6.942 6.942 0 015 10c0-2.074.912-4.029 2.501-5.363zm5.415.443c-1.508-.265-2.975.12-4.129 1.088A4.993 4.993 0 007 10c0 1.09.345 2.127.997 2.997l4.004 5.337 4.003-5.337a5.007 5.007 0 00.935-3.803c-.32-2.063-1.974-3.754-4.023-4.114zM12 6.999c1.654 0 3 1.346 3 3s-1.346 3-3 3c-1.655 0-3-1.346-3-3s1.345-3 3-3zm0 2a1.001 1.001 0 000 2 1.001 1.001 0 000-2z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { PinIcon }
