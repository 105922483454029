import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import constants from '@elenfs/elen-constants'

import { PraemiumImportForm } from './PraemiumImportForm'

import { Toastr } from '../../misc'

import connectPraemiumGql from '../../../graphql/mutations/dataImport/connectPraemium.graphql'
import disconnectPraemiumGql from '../../../graphql/mutations/dataImport/disconnectPraemium.graphql'
import praemiumConnectionGql
  from '../../../graphql/queries/dataImport/praemiumConnection.graphql'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

const { DataImportTypes } = constants.dataImport
const platform = DataImportPlatformLabels[DataImportTypes.PRAEMIUM]

const connectPraemium = () => useMutation(connectPraemiumGql, {
  update: (cache, { data }) => {
    const { connectPraemium } = data
    try {
      cache.writeQuery({
        query: praemiumConnectionGql,
        data: { dataImportPraemiumConnection: connectPraemium }
      })
      return toast(
        <Toastr
          type='success'
          title={`Syncing with ${platform} in progress`}
          content='This page will be updated once done'
        />
      )
    } catch (error) {
      return toast(
        <Toastr
          type='error'
          title={`Failed to connect to ${platform}`}
          content='Please try again later!'
        />
      )
    }
  }
})

const disConnectPraemium = () => useMutation(disconnectPraemiumGql, {
  update: (cache) => {
    try {
      cache.writeQuery({
        query: praemiumConnectionGql,
        data: { dataImportPraemiumConnection: null }
      })
      return toast(
        <Toastr
          type='success'
          title={`Successfully disconnected from ${platform}`}
        />
      )
    } catch (error) {
      toast(
        <Toastr
          type='error'
          title={`Failed to disconnect from ${platform}`} content='Please try again later!'
        />
      )
      throw error
    }
  }
})

export const PraemiumImportFormContainer = ({ isActive, ...restProps }) => {
  // username is astericked because the data is hashed from the server
  const password = isActive ? 'fake-pass' : null
  const username = isActive ? '**********' : null

  let useMutationResult

  if (!isActive) {
    useMutationResult = connectPraemium()
  } else {
    useMutationResult = disConnectPraemium()
  }

  const [mutate, mutationState] = useMutationResult

  return (
    <PraemiumImportForm {...{
      mutate,
      loading: mutationState.loading,
      isActive,
      password,
      username,
      ...restProps
    }}
    />
  )
}
