import { Tooltip } from '..'
import { InfoIcon } from '../../icons'

import './IncomeToolTip.less'

const IncomeToolTip = () => (
  <div className='income-tooltip'>
    <Tooltip.Trigger
      as={InfoIcon}
      tooltip={Tooltip.Info}
      tooltipId='income-tooltip'
      tooltipProps={{
        text:
          'The income is calculated from the net amount if available. Otherwise the gross amount is used.',
        position: 'bottom'
      }}
    />
  </div>
)

export { IncomeToolTip }
