"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionStatuses = exports.SubscriptionIntervals = exports.SubscriptionInactivationReasons = exports.PaymentStatuses = exports.PaymentProviders = exports.INACTIVE_SUBSCRIPTION_STATUSES = exports.DEFAULT_TRIAL_LENGTH_DAYS = exports.AWAITING_SUBSCRIPTION_STATUSES = exports.ACTIVE_SUBSCRIPTION_STATUSES = void 0;
const PaymentProviders = exports.PaymentProviders = Object.freeze({
  STRIPE: 'STRIPE'
});
const PaymentStatuses = exports.PaymentStatuses = Object.freeze({
  PENDING: 'PENDING',
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE'
});
const SubscriptionIntervals = exports.SubscriptionIntervals = Object.freeze({
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
});
const SubscriptionStatuses = exports.SubscriptionStatuses = Object.freeze({
  // Subscription has this status before we get confirmation from Stripe
  // that the user has successfully subscribed.
  AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
  // Inactive state.
  // After cancelling,
  // after grace period for failed payments is over, and so on.
  INACTIVE: 'INACTIVE',
  // When the user cancels a subscription,
  // the subscription has this status before
  // the current period that they paid for ends.
  AWAITING_INACTIVATION: 'AWAITING_INACTIVATION',
  // In trial.
  TRIALING: 'TRIALING',
  // In trial, but still haven't checked out on Stripe.
  // Only existing companies that were created prior to deployment
  // will have subscriptions with this status.
  TRIALING_AWAITING_SUBSCRIPTION: 'TRIALING_AWAITING_SUBSCRIPTION',
  // Between trial and inactivation.
  // Regular status that a subscription has in its lifetime,
  // when everything is fine (paid, and so on).
  ACTIVE: 'ACTIVE',
  // Payment hasn't been made in time.
  // Subscription is in this state before transitioning to UNPAID
  // at the end of the grace period.
  PAYMENT_PAST_DUE: 'PAYMENT_PAST_DUE',
  // Subscription is in this state after the grace period ends.
  // It transitions to this state from PAYMENT_PAST_DUE.
  UNPAID: 'UNPAID'
});
const ACTIVE_SUBSCRIPTION_STATUSES = exports.ACTIVE_SUBSCRIPTION_STATUSES = [SubscriptionStatuses.TRIALING, SubscriptionStatuses.TRIALING_AWAITING_SUBSCRIPTION, SubscriptionStatuses.ACTIVE, SubscriptionStatuses.PAYMENT_PAST_DUE, SubscriptionStatuses.AWAITING_INACTIVATION];
const INACTIVE_SUBSCRIPTION_STATUSES = exports.INACTIVE_SUBSCRIPTION_STATUSES = [SubscriptionStatuses.AWAITING_CONFIRMATION, SubscriptionStatuses.AWAITING_INACTIVATION, SubscriptionStatuses.INACTIVE];
const AWAITING_SUBSCRIPTION_STATUSES = exports.AWAITING_SUBSCRIPTION_STATUSES = [SubscriptionStatuses.AWAITING_CONFIRMATION, SubscriptionStatuses.TRIALING_AWAITING_SUBSCRIPTION];
const SubscriptionInactivationReasons = exports.SubscriptionInactivationReasons = Object.freeze({
  USER_CANCELLED: 'USER_CANCELLED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PROVIDER_CANCELLED: 'PROVIDER_CANCELLED'
});
const DEFAULT_TRIAL_LENGTH_DAYS = exports.DEFAULT_TRIAL_LENGTH_DAYS = 30;