import { Fragment } from 'react'
import T from 'prop-types'

import { Table } from '../../../misc'
import { TasksTableRow } from '../TasksTableRow'
import { SubTasksTable } from '../SubTasksTable'

import { taskPropType } from './../propTypes'

const TasksTableBody = props => {
  const {
    toggleState,
    subTasksWrapperRef,
    sortedTasks,
    toggleSubtaskTable,
    onTaskClick,
    onCreateSubtask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    currentUser,
    isClientTasks
  } = props

  return (
    <Table.Body>
      {sortedTasks.map(task => (
        <Fragment key={task.id}>
          <TasksTableRow
            {...{
              task,
              toggleSubtaskTable,
              isToggleOpen: toggleState[task.id],
              onTaskClick,
              currentUser
            }}
          />

          <Table.Row className='tasks-table__subtasks-row'>
            <Table.Cell colSpan={5}>
              <div
                ref={ref => {
                  subTasksWrapperRef.current[task.id] = ref
                }}
              >
                <SubTasksTable
                  {...{
                    subTasks: task?.subtasks || [],
                    isNotNestedTable: false,
                    onCreateSubtask,
                    loadingOnCreateSubtask,
                    errorsOnCreateSubtask: errorsOnCreateSubtask && errorsOnCreateSubtask[task.id],
                    taskId: task.id,
                    onTaskClick,
                    isClientTasks
                  }}
                />
              </div>
            </Table.Cell>
          </Table.Row>
        </Fragment>
      ))}
    </Table.Body>
  )
}

TasksTableBody.propTypes = {
  sortedTasks: T.arrayOf(taskPropType().isRequired).isRequired,
  toggleSubtaskTable: T.func,
  toggleState: T.object,
  subTasksWrapperRef: T.object,
  onTaskClick: T.func,
  onCreateSubtask: T.func,
  loadingOnCreateSubtask: T.bool,
  errorsOnCreateSubtask: T.object.isRequired
}

export { TasksTableBody }
