import { useState, useCallback } from 'react'

const USER_SETTINGS_KEY = '__elen_user_settings'

const getUserSettingsLS = () =>
  JSON.parse(window.localStorage.getItem(USER_SETTINGS_KEY)) || {}

const setUserSettingsLS = newValue => {
  window.localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(newValue))
}

export const localStorageUserSettingsKeys = {
  TASK_FILTER: 'tasksFilter',
  TASK_SORT: 'tasksSort',
  SHOW_MY_CLIENTS_ONLY: 'showMyClientsOnly'
}

export const useLocalStorageUserSettings = (userSettingsDataKey) => {
  const [userSettingData, setUserSettingData] = useState(
    getUserSettingsLS()[userSettingsDataKey]
  )

  const persistUserSettingsData = useCallback((data) => {
    if (userSettingsDataKey) {
      setUserSettingData(data)
      const userSettings = getUserSettingsLS()
      setUserSettingsLS({ ...userSettings, [userSettingsDataKey]: data })
    }
  }, [])

  return {
    userSettingData,
    persistUserSettingsData
  }
}
