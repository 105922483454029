import T from 'prop-types'

import { Modal } from '../../../misc'
import { UserDetailsForm } from './UserDetailsForm'

const UserDetailsModal = (props) => {
  const { close, user } = props

  return (
    <Modal {...props}>
      <Modal.Heading>
        {user ? 'Edit user' : 'New user'}
      </Modal.Heading>
      <UserDetailsForm onDone={close} user={user} />
    </Modal>
  )
}

UserDetailsModal.propTypes = {
  user: T.object
}

export { UserDetailsModal }
