import { ESignatureUserStatuses } from '@elenfs/elen-constants'

import { MenuDotsIcon } from '../../../icons'
import { DropdownMenu } from '../../../misc'
import RemoveDocumentButton from '../RemoveDocumentButton'
import { RequestSignatureButton } from '../RequestSignatureButton'
import { CancelSigningButton } from '../CancelSigningButton'

const { PENDING, WAITING, COMPLETED } = ESignatureUserStatuses

const ActionList = props => {
  const {
    onStartDownload,
    clientDocument,
    onRequestSignatureDocument,
    clientNotRegistered,
    refetchDocuments,
    isError
  } = props

  const isSignatureInProcess = [PENDING, WAITING].includes(
    clientDocument?.eSignature?.status
  )

  const isNotCompleted = clientDocument?.eSignature?.status !== COMPLETED

  return (
    <DropdownMenu
      className='action'
      direction={DropdownMenu.Directions.RIGHT}
      fullWidth={false}
      trigger={<MenuDotsIcon />}
    >
      <DropdownMenu.List>
        {isNotCompleted && (
          <>
            {isSignatureInProcess
              ? (
                <CancelSigningButton {...{ clientDocument, refetchDocuments }} />
                )
              : isError
                ? null
                : (
                  <RequestSignatureButton
                    {...{
                      clientDocument,
                      onRequestSignatureDocument,
                      clientNotRegistered
                    }}
                  />
                  )}
          </>
        )}

        <DropdownMenu.Item onClick={onStartDownload}>
          Download
        </DropdownMenu.Item>
        <RemoveDocumentButton clientDocument={clientDocument} />
      </DropdownMenu.List>
    </DropdownMenu>
  )
}

export { ActionList }
