import {
  SubscriptionIntervals,
  SubscriptionStatuses
} from '@elenfs/elen-constants'

export const LicenseTypeLabels = Object.freeze({
  [SubscriptionIntervals.MONTH]: 'monthly',
  [SubscriptionIntervals.YEAR]: 'annually',
  [SubscriptionIntervals.DAY]: 'daily'
})

export const SubscriptionIntervalsLabels = Object.freeze({
  [SubscriptionIntervals.DAY]: 'day',
  [SubscriptionIntervals.MONTH]: 'month',
  [SubscriptionIntervals.YEAR]: 'year'
})

export const SubscriptionStatusLabels = Object.freeze({
  [SubscriptionStatuses.TRIALING]: 'Trial',
  [SubscriptionStatuses.ACTIVE]: 'Active',
  [SubscriptionStatuses.INACTIVE]: 'Inactive',
  [SubscriptionStatuses.UNPAID]: 'Unpaid',
  [SubscriptionStatuses.PAYMENT_PAST_DUE]: 'Payment past due',
  [SubscriptionStatuses.AWAITING_CONFIRMATION]: 'Awaiting confirmation',
  [SubscriptionStatuses.AWAITING_INACTIVATION]: 'Awaiting inactivation',
  [SubscriptionStatuses.TRIALING_AWAITING_SUBSCRIPTION]: 'Action required'
})
