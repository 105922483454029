import { ActionList } from '../ActionList'
import DownloadIcon from './../../../icons/Download'

const DocumentActions = props => {
  const {
    isClientPortal,
    onStartDownload,
    clientDocument,
    onRequestSignatureDocument,
    clientNotRegistered,
    refetchDocuments,
    isError
  } = props

  return (
    <div className='client-document-row__management-cell__icons'>
      {!isClientPortal
        ? (
          <ActionList
            {...{
              onStartDownload,
              clientDocument,
              onRequestSignatureDocument,
              clientNotRegistered,
              refetchDocuments,
              isError
            }}
          />
          )
        : (
          <DownloadIcon
            className='client-document-row__management-cell__icons__download'
            onClick={onStartDownload}
          />
          )}
    </div>
  )
}

export { DocumentActions }
