import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import updateCompanyUserLastVisitedGql from '../graphql/mutations/user/updateCompanyUserLastVisited.graphql'

export const useLastVisitedClientMutation = () => {
  const { clientId } = useParams()
  const [updateCompanyUserLastVisited] = useMutation(
    updateCompanyUserLastVisitedGql
  )

  useEffect(() => {
    if (clientId) {
      updateCompanyUserLastVisited({ variables: { input: { clientId } } })
    }
  }, [clientId, updateCompanyUserLastVisited])
}
