import T from 'prop-types'
import { isEmpty } from 'ramda'

import { Table, TableResponsiveWrapper } from '../../../misc'
import { sortListByProp } from '../../../../helpers/sort'
import { PolicyTransactionsHeader } from './PolicyTransactionsHeader'
import { PolicyTransactionsTableHeader } from './PolicyTransactionsTableHeader'
import { PolicyTransactionsTableRow } from './PolicyTransactionsTableRow'

const PolicyTransactionsTable = ({
  policyTransactionType,
  policyTransactions,
  readOnly
}) => (
  <div className='policy-transactions__table'>
    <PolicyTransactionsHeader
      policyTransactionType={policyTransactionType}
      readOnly={readOnly}
    />
    {
      !isEmpty(policyTransactions) &&
        <TableResponsiveWrapper>
          <Table>
            <PolicyTransactionsTableHeader policyTransactionType={policyTransactionType} />
            <Table.Body>
              {sortListByProp(policyTransactions, 'startDate', 'DESC').map((transaction) => (
                <PolicyTransactionsTableRow
                  key={transaction.id}
                  policyTransaction={transaction}
                  policyTransactionType={policyTransactionType}
                  readOnly={readOnly}
                />
              ))}
            </Table.Body>
          </Table>
        </TableResponsiveWrapper>
    }
  </div>
)

PolicyTransactionsHeader.propTypes = {
  policyTransactions: T.string,
  policyTransactionType: T.string.isRequired
}

export { PolicyTransactionsTable }
