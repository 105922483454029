import { map, sum, pipe, prop, omit } from 'ramda'
import moment from 'moment'

const listItemMargin = 26

const mapHeight = map(prop('offsetHeight'))

export const setListHeight = ({ containerRef, itemsToShow }) => {
  const clientListEl = containerRef?.current?.children[0]
  const listItems = [...clientListEl?.children]
  const listItemsToShow = listItems.slice(0, itemsToShow)
  const height = pipe(mapHeight, sum)(listItemsToShow)
  const margins = itemsToShow * listItemMargin

  clientListEl.style.maxHeight = height + margins + 'px'
}

export const fromToDateFilter = {
  fromDate: moment()
    .startOf('day')
    .toDate(),
  toDate: moment()
    .add(30, 'days')
    .endOf('day')
    .toDate()
}

export const mapClientList = (clients, dateProp) =>
  clients.map(
    pipe(item => ({ ...item, date: item[dateProp] }), omit(['knownAs']))
  )
