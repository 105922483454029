import { useCallback } from 'react'
import T from 'prop-types'
import { useNavigate } from 'react-router-dom'
import constants from '@elenfs/elen-constants'

import LoggedOutLayout from '../../misc/LoggedOutLayout'
import SuccessIcon from '../../icons/Success'
import Button from '../../misc/Button'

import './RecoverPasswordSuccessPage.less'

const { UserTypes } = constants.user

const RecoverPasswordSuccessPage = ({ userType }) => {
  const navigate = useNavigate()

  const onLoginButtonClick = useCallback(() => {
    const loginRedirectionPath = userType === UserTypes.CLIENT ? '/clients/login' : '/login'
    navigate(loginRedirectionPath)
  }, [userType])

  return (
    <LoggedOutLayout>
      <div className='recover-password-success-page'>
        <div className='recover-password-success-page-content'>
          <h2>Password successfully changed</h2>
          <SuccessIcon className='user-form-success-icon' />
          <p>Congratulations, you have successfully changed your password. You can now login with your new password.</p>
          <Button color={Button.Colors.BLUE} onClick={onLoginButtonClick}>
            Log in
          </Button>
        </div>
      </div>
    </LoggedOutLayout>
  )
}

RecoverPasswordSuccessPage.defaultProps = {
  userType: UserTypes.COMPANY_USER
}

RecoverPasswordSuccessPage.proptypes = {
  userType: T.oneOf(Object.values(UserTypes))
}

export default RecoverPasswordSuccessPage
