import constants from '@elenfs/elen-constants'

const { UserTypes, CompanyUserRoles, CompanyUserAdminAccess } = constants.user

export const UserTypesLabels = Object.freeze({
  [UserTypes.COMPANY_USER]: 'Regular user',
  [UserTypes.ADMIN]: 'Administrator',
  [UserTypes.CLIENT]: 'Client'
})

export const UserRolesLabels = Object.freeze({
  [CompanyUserRoles.ADVISER]: 'Financial Adviser',
  [CompanyUserRoles.PARAPLANNER]: 'Paraplanner',
  [CompanyUserRoles.COMPLIANCE]: 'Compliance',
  [CompanyUserRoles.OFFICE_ADMINISTRATOR]: 'Office Administrator'
})

export const UserPermissionsLabels = Object.freeze({
  [CompanyUserAdminAccess.ADMINISTRATOR]: 'Administrator',
  [CompanyUserAdminAccess.USER]: 'User'
})
