import { Route, Routes, Navigate } from 'react-router-dom'
import { CompanyUserPermissions } from '@elenfs/elen-constants'
import { useAutoLogout } from './../../../hooks'

import { ClientsSection } from '../../client/ClientsSection'
import { AdminSection } from '../../admin/AdminSection'
import { IncomeSection } from '../../income/IncomeSection'
import { ReportsSection } from '../../reports/ReportsSection'
import { NotFoundPage } from '../'
import { Header, MainNav, MobileSideMenu } from '../../nav'
import CurrentUserMenu from '../../user/CurrentUserMenu'
import { UserMessagesNotificationsMenu } from '../../user/UserMessagesNotificationsMenu'
import { CustomerSupportSection } from '../../CustomerSupport'
import { TasksPage } from '../../tasks'
import { SubscriptionWarningBanner } from './SubscriptionWarningBanner'
import { DashboardPage } from '../../dashboard/DashboardPage'
import { WithPermissionCheck } from '../../hoc/container/WithPermissionCheck'
import { WorkflowsSwitch } from '../../workflows/WorkflowsSwitch'
import { ClientSearchContainer as ClientSearch } from './ClientSearchContainer'
import { GlobalCreateButton } from '../../nav/GlobalCreateButton'

import './SecureSection.less'

const IncomeSectionWithPermissionCheck = WithPermissionCheck({
  permissionName: CompanyUserPermissions.INCOME_ALL
})(IncomeSection)

const ReportsSectionWithPermissionCheck = WithPermissionCheck(
  { permissionName: CompanyUserPermissions.REPORTS_ALL }
)(ReportsSection)

const SecureSection = props => {
  const {
    showWarningBanner,
    closeWarningBanner,
    trialDays,
    billingDateAfterTrial,
    awaitingSubscriptionProps,
    subscriptionStatus,
    subscriptionIntervalLabel
  } = props

  useAutoLogout()

  return (
    <div className='secure-section'>
      <SubscriptionWarningBanner
        {...{
          showWarningBanner,
          closeWarningBanner,
          trialDays,
          billingDateAfterTrial,
          awaitingSubscriptionProps,
          subscriptionStatus,
          subscriptionIntervalLabel
        }}
      />

      <Header>
        <Header.Left>
          <MobileSideMenu />
          <MainNav />
        </Header.Left>
        <Header.Right>
          <ClientSearch />
          <UserMessagesNotificationsMenu behaveAsModalOnMobile />
          <CurrentUserMenu showName />
          <GlobalCreateButton />
        </Header.Right>
      </Header>

      <div className='secure-section__content'>
        <Routes>
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='clients/*' element={<ClientsSection />} />
          <Route path='income/*' element={<IncomeSectionWithPermissionCheck />} />
          <Route path='reports/*' element={<ReportsSectionWithPermissionCheck />} />
          <Route path='admin/*' element={<AdminSection />} />
          <Route path='tasks/*' element={<TasksPage />} />
          <Route path='customer-support' element={<CustomerSupportSection />} />
          <Route path='workflows/*' element={<WorkflowsSwitch />} />
          <Route path='/' element={<Navigate replace to='dashboard' />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  )
}

export default SecureSection
