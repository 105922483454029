import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import ImportError from './ImportError'

const {
  GeneralDataImportErrors,
  DataImportTransactErrors,
  DataImportCashCalcErrors
} = constants.dataImport

const { INVALID_CREDENTIALS } = GeneralDataImportErrors
const {
  LOCKED_OUT,
  WRONG_USER_TYPE,
  MAX_REQUEST_EXCEEDED
} = DataImportTransactErrors
const {
  AUTH_EXPIRED
} = DataImportCashCalcErrors

const PlatformFailure = ({ job }) => {
  let heading = <b>Import failed</b>
  let description

  switch (job.platformFailureType) {
    // General
    case INVALID_CREDENTIALS:
      heading = (
        <>
          <b>Import failed</b>&nbsp;-&nbsp;Authorization issue
        </>
      )
      description = 'Provided credentials are invalid'
      break
    // Transact
    case LOCKED_OUT:
      description = 'You are locked out due to multiple failed authentication attempts. Please contact Transact'
      break
    case WRONG_USER_TYPE:
      description = 'Wrong user type account. Required user type is Adviser or an Adviser Group. Please contact Transact'
      break
    case MAX_REQUEST_EXCEEDED:
      description = 'A daily maximum of valuation requests for your account has been exceeded'
      break
      // CashCalc
    case AUTH_EXPIRED:
      description = 'Log in expired. Please log in again.'
      break
    default:
      description = 'Server error'
  }

  return <ImportError heading={heading} description={description} />
}

PlatformFailure.propTypes = {
  job: T.shape({
    platformFailureType: T.oneOf(Object.values(GeneralDataImportErrors))
  }).isRequired
}

export { PlatformFailure }
