import { useState, useRef, useEffect } from 'react'
import { withFormik } from 'formik'
import classnames from 'classnames'
import T from 'prop-types'

import { Button } from '..'
import { DropdownIcon } from '../../icons'
import { Form } from '../../form'
import { CheckboxField } from '../../form/fields/CheckboxField'

const CookieItem = props => {
  const { type, description, disabled, name } = props
  const inputProps = { disabled }

  return (
    <div className='cookie-consent__cookies-list__cookie-item'>
      <h3> {type} </h3>
      <div className='cookie-consent__cookies-list__cookie-item__container'>
        <p> {description} </p>
        <CheckboxField
          name={name}
          inputId={`cookie-checkbox-${name}}`}
          inputProps={inputProps}
        />
      </div>
    </div>
  )
}

const CookieConsentForm = ({ handleSubmit, cookiesList }) => {
  const [showCookies, setShowCookies] = useState(false)
  const listRef = useRef()

  useEffect(() => {
    const resizeFunc = () => setShowCookies(false)
    window.addEventListener('resize', resizeFunc)
    return () => window.removeEventListener('resize', resizeFunc)
  }, [showCookies])

  return (
    <Form>
      <div className='cookie-consent'>
        <div className='cookie-consent__header'>
          <div className='cookie-consent__header__info'>
            <h2>This site uses cookies</h2>
            <p>
              We use cookies to personalise content and ads, to provide social
              media features and to analyse our traffic. We also share
              information about your use of our site with our social media,
              advertising and analytics partners who may combine it with other
              information that you've provided to them or that they've collected
              from your use of their services
            </p>
          </div>
          <div
            className={classnames(
              'cookie-consent__header__actions',
              showCookies && 'cookie-consent__header__actions--visible-list'
            )}
          >
            <Button color={Button.Colors.BLUE} onClick={handleSubmit}>
              Allow cookies
            </Button>
            <Button
              color={Button.Colors.GREY}
              onClick={() => setShowCookies(!showCookies)}
            >
              <DropdownIcon /> Show {showCookies ? 'less' : 'more'}
            </Button>
          </div>
        </div>

        <div
          className={classnames(
            'cookie-consent__cookies-list-container',
            showCookies &&
              'cookie-consent__cookies-list-container--visible-list'
          )}
          style={{
            maxHeight: `${showCookies ? listRef.current.offsetHeight : 0}px`
          }}
        >
          <div className='cookie-consent__cookies-list' ref={listRef}>
            {cookiesList.map((cookie, i) => (
              <CookieItem {...cookie} key={i} />
            ))}
          </div>
        </div>
      </div>
    </Form>
  )
}

CookieConsentForm.defaultProps = {
  cookiesList: [],
  onSubmit: () => {}
}

CookieConsentForm.propTypes = {
  cookiesList: T.arrayOf(
    T.shape({
      type: T.string.isRequired,
      name: T.string.isRequired,
      description: T.string.isRequired,
      checked: T.bool.isRequired,
      disabled: T.bool
    }).isRequired
  ).isRequired,
  onSubmit: T.func.isRequired
}

const EnhancedCookieConsentForm = withFormik({
  mapPropsToValues: ({ cookiesList }) =>
    cookiesList.reduce(
      (acc, cookie) => ({ ...acc, [cookie.name]: cookie.checked }),
      {}
    ),
  handleSubmit: async (values, { props: { onSubmit, close } }) => {
    await onSubmit(values)
    close()
  }
})(CookieConsentForm)

export { EnhancedCookieConsentForm as CookieConsentForm }
