import { useCallback } from 'react'
import { withFormik, connect } from 'formik'
import { isEmpty, compose } from 'ramda'
import T from 'prop-types'

import { Form, FormErrorMessages } from '../../../../form'
import { DateField, MoneyField } from '../../../../form/fields'
import {
  Button,
  LoadingMessage
} from '../../../../misc'

import { buildInitialValues } from '../../../../../helpers/form'
import { parseGraphqlErrors } from '../../../../../helpers/error'
import { policyValuationSchema } from '../../../../../schemas/policy'
import { RemovePolicyValuationButton } from '../../RemovePolicyValuationButton'

import './PolicyValuationDetailsForm.less'

const PolicyValuationDetailsForm = (props) => {
  const {
    policyValuation,
    errors: validationErrors,
    formik: { resetForm, submitForm, validateForm, setErrors },
    onDone,
    loading,
    policyId
  } = props
  const isEdit = policyValuation && policyValuation.id

  const validateAndSubmit = useCallback(
    async (fn) => {
      const errors = await validateForm()

      if (!isEmpty(errors)) { return }

      const result = await submitForm()

      if (!result.graphQLErrors) { fn && fn() }
    },
    [validateForm, submitForm, validationErrors]
  )

  const onDoneClick = useCallback(
    () => validateAndSubmit(onDone),
    [validateAndSubmit, onDone])

  const onSaveAndAddNewClick = useCallback(
    () => validateAndSubmit(resetForm),
    [validateAndSubmit, resetForm])

  return (
    <>
      <Form className='policy-valuation-form'>
        <Form.Group className='policy-valuation-form__fields-row'>
          <DateField
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
            name='date'
            label='Date'
          />
          <MoneyField
            name='value'
            label='Value'
          />
        </Form.Group>
      </Form>

      {loading && <LoadingMessage />}
      <FormErrorMessages validationErrors={validationErrors} />

      <div className='policy-valuation-form__buttons form-new__button-group'>
        <div className='policy-valuation-form__buttons__save-buttons'>
          <Button
            color={Button.Colors.BLUE}
            onClick={onDoneClick}
          >
            Save
          </Button>

          {!isEdit && (
            <Button
              color={Button.Colors.GREEN}
              onClick={onSaveAndAddNewClick}
            >
              Save and add new
            </Button>
          )}
        </div>

        {isEdit && (
          <RemovePolicyValuationButton
            {...{
              closeModal: onDone,
              valuationId: policyValuation.id,
              policyId,
              setErrors,
              className: 'policy-valuation-form__buttons__delete-buttons'
            }}
          />
        )}
      </div>
    </>
  )
}

const PolicyValuationDetailsFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: policyValuationSchema,
    mapPropsToValues: ({ policyValuation = {} }) => (
      buildInitialValues(policyValuationSchema, { ...policyValuation })
    ),
    handleSubmit: async (values, formik) => {
      const { props, setErrors } = formik
      const { policyId, policyValuation, mutate } = props
      values = policyValuationSchema.cast(values, { stripUnknown: true })

      if (policyValuation && policyValuation.id) {
        values = {
          ...values,
          id: policyValuation.id,
          policyId
        }
      }

      values = { ...values, policyId }

      try {
        const result = await mutate({ variables: { input: { ...values } } })
        return result
      } catch (error) {
        setErrors(parseGraphqlErrors(
          error,
          'There was an error saving the valuation, please try again later')
        )
        return error
      }
    }
  }),
  connect
)(PolicyValuationDetailsForm)

PolicyValuationDetailsFormEnhanced.defaultProps = {
  policyValuation: {}
}

PolicyValuationDetailsFormEnhanced.propTypes = {
  /**
  * Is any mutation loading. Eg mutation for saving or for deleting the valuation.
  */
  loading: T.bool,
  /**
  * Callback to execute when form submits.
  */
  onDone: T.func.isRequired,
  /**
  * Callback to mutate (submit) the form.
  */
  mutate: T.func.isRequired,
  /**
  * Callback to remove the valuation.
  */
  onRemoveValuation: T.func,
  /**
  * Policy Valuation that's being edited.
  */
  policyValuation: T.object,
  /**
  * policyId of the policy
  */
  policyId: T.string.isRequired,
  /**
  * Is any mutation loading. Eg mutation for saving or for deleting the valuation.
  */
  mutationState: T.shape({
    loading: T.bool.isRequired
  }).isRequired
}

export { PolicyValuationDetailsFormEnhanced as PolicyValuationDetailsForm }
