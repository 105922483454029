const MobilePhoneIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M16.5 2a3 3 0 013 3v14a3 3 0 01-3 3h-9a3 3 0 01-3-3V5a3 3 0 013-3h9zm0 2h-1v.275c0 .4-.325.725-.725.725h-5.55a.725.725 0 01-.725-.725V4h-1a1 1 0 00-.993.883L6.5 5v14a1 1 0 00.883.993L7.5 20h9a1 1 0 00.993-.883L17.5 19V5a1 1 0 00-.883-.993L16.5 4zm-2.225 13.5a.725.725 0 010 1.45h-4.55a.725.725 0 010-1.45h4.55z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { MobilePhoneIcon }
