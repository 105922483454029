import { is } from 'ramda'
import moment from 'moment'

export const formatDate = (date, index, format = 'D MMM YYYY') => (date ? moment(date).format(format) : '')

export const formatDateAndTime = (date, index, format = 'D MMM YYYY - HH:mm') => (date ? moment(date).format(format) : '')

export const calculateAge = (date) => (date ? moment().diff(date, 'years') : '')

export const combineDateAndTime = (date, time) => {
  if (is(String, date)) {
    date = new Date(date)
  }

  if (is(String, time)) {
    time = new Date(time)
  }

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  )
}

export const calculateDiff = (startDate, endDate, diffUnit) => moment(endDate).diff(startDate, diffUnit)

export const getEndOfDay = (date) => moment(date).endOf('day').toDate()

export const daysToMilliSeconds = (days = 0) =>
  moment.duration(+days, 'days').asMilliseconds()

export const millisecondsToDays = (milliseconds = 0) =>
  moment.duration(milliseconds, 'milliseconds').asDays()
