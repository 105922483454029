import { PageContent } from '../../../misc'
import { ServiceLevelsEmptyPage } from '../ServiceLevelsEmptyPage'
import { ServiceLevelsTable } from '../ServiceLevelsTable/ServiceLevelsTable'

const ServiceLevelsPage = ({ serviceLevels }) => {
  const isEmpty = !(serviceLevels && serviceLevels.length)

  return (
    <PageContent className='service_levels'>
      {!isEmpty && (
        <ServiceLevelsTable serviceLevels={serviceLevels} />
      )}
      <ServiceLevelsEmptyPage hidden={!isEmpty} />
    </PageContent>
  )
}

export { ServiceLevelsPage }
