import { useQuery } from '@apollo/client'

import companyUsersGql from '../../../../graphql/queries/user/companyUsers.graphql'

const withCompanyUsers = (
  { fetchPolicy = 'cache-and-network' } = {}
) => (WrappedComponent) => (props) => {
  const { data, error, loading } = useQuery(companyUsersGql, {
    fetchPolicy
  })

  // Ignore errors, just pass null or undefined companyClients prop.
  const companyUsers = data?.users || []

  return (
    <WrappedComponent
      isCompanyUsersLoading={loading}
      companyUsersError={error}
      companyUsers={companyUsers}
      {...props}
    />
  )
}

export { withCompanyUsers }
