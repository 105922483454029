import T from 'prop-types'

import { ConfirmationModal, Button } from '../../../misc'

const RemovePolicyValuationButton = ({
  removeValuationCallback,
  className,
  Component
}) => (
  <ConfirmationModal.Trigger
    as={Component}
    color={Button.Colors.RED_TEXT}
    className={className}
    modalProps={{
      confirmationCallback: removeValuationCallback,
      heading: 'Delete Valuation',
      text: 'Are you sure you want to delete this valuation?',
      yesButtonText: 'Delete',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
  >
    Delete
  </ConfirmationModal.Trigger>
)

RemovePolicyValuationButton.defaultProps = {
  Component: Button
}

RemovePolicyValuationButton.propTypes = {
  removeValuationCallback: T.func.isRequired,
  Component: T.elementType.isRequired,
  className: T.string
}

export { RemovePolicyValuationButton }
