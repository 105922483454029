export const normalizeFiles = (files) => {
  if (!files) return
  const newFiles = []
  files.forEach((file) => {
    newFiles.push({
      id: file.id,
      fileId: file.fileId,
      fileName: file.fileName
    })
  })

  return newFiles
}

export const filteredDuplicatedFilelist = (selectedFilesList, currentList) => (
  [...selectedFilesList]
    .filter(file => !currentList.find(doc => doc.fileName === file.name))
)

export const parseFilesToLocalState = (files, selectedFileList) => {
  const fileList = []

  const getSelectedFile = fileName => selectedFileList.find(({ name }) => name === fileName)

  files.forEach(({ attachment, uploadUrl }) => {
    fileList.push({
      ...attachment,
      isLoading: true,
      isError: false,
      uploadUrl,
      file: getSelectedFile(attachment.fileName)
    })
  })

  return fileList
}
