import { useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import T from 'prop-types'

import DocumentRow from './DocumentRow'

import downloadLinkGql from '../../../graphql/queries/clientDocument/clientDocumentDownloadLink.graphql'

const DocumentRowContainer = ({
  clientDocument,
  isClientPortal,
  policies,
  ...props
}) => {
  const [downLoadFile] = useLazyQuery(downloadLinkGql, {
    fetchPolicy: 'network-only',
    onCompleted: ({ clientDocument: { downloadUrl } }) =>
      window.location.assign(downloadUrl)
  })

  const onStartDownload = useCallback(
    () => downLoadFile({ variables: { documentId: clientDocument.id } }),
    [clientDocument]
  )

  const [getDownloadLink, { data }] = useLazyQuery(downloadLinkGql, {
    variables: { documentId: clientDocument.id },
    fetchPolicy: 'no-cache'
  })

  return (
    <DocumentRow
      {...{
        clientDocument,
        isClientPortal,
        onStartDownload,
        policies,
        getDownloadLink,
        fileDownloadUrl: data?.clientDocument?.downloadUrl,
        ...props
      }}
    />
  )
}

DocumentRowContainer.propTypes = {
  clientDocument: T.object.isRequired,
  isClientPortal: T.bool.isRequired
}

export default DocumentRowContainer
