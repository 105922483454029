import PropTypes from 'prop-types'

import { EmptyPage, PageContent } from '../../misc'
import AddDocumentsButton from '../AddDocumentsButton'

const DocumentsPageEmpty = ({ hidden, clientId, policyId }) => (
  <PageContent>
    <EmptyPage
      className='client-documents-page--empty'
      hidden={hidden}
      text='No documents currently available, add your first one'
      renderButton={() => (
        <AddDocumentsButton
          clientId={clientId}
          responsive={false}
          policyId={policyId}
        />
      )}
    />
  </PageContent>
)

DocumentsPageEmpty.propTypes = {
  hidden: PropTypes.bool
}

export default DocumentsPageEmpty
