import * as yup from 'yup'
import stringSchema from '../helpers/stringSchema'
import moneySchema from '../helpers/moneySchema'

export default yup.object().shape({
  incomes: yup
    .array()
    .of(
      yup.object().shape(
        {
          id: stringSchema(),
          source: stringSchema()
            .max(50)
            .label('Income & Expenditure section: Source')
            .required(),

          netAmount: moneySchema().when('grossAmount', {
            is: money => !money?.amount,
            then: moneySchema()
              .required()
              .label('Income & Expenditure section: Net or Gross amount'),
            otherwise: moneySchema()
          }),

          grossAmount: moneySchema().when('netAmount', {
            is: money => !money?.amount,
            then: moneySchema()
              .required()
              .label('Income & Expenditure section: Net or Gross amount'),
            otherwise: moneySchema()
          }),
          frequency: stringSchema()
            .label('Income & Expenditure section: Frequency')
            .required(),

          type: stringSchema()
            .label('Income & Expenditure section: Type')
            .required()
        },
        ['netAmount', 'grossAmount']
      )
    )
    .default([]),

  incomeNotesAndComments: stringSchema().label(
    'Income additional notes and comments'
  ),

  expenses: yup
    .array()
    .of(
      yup.object().shape({
        id: stringSchema(),
        expense: stringSchema()
          .max(50)
          .label('Income & Expenditure section: Expense')
          .required(),

        amount: moneySchema({
          amountLabel: 'Amount',
          currencyLabel: 'Amount currency'
        })
          .label('Income & Expenditure section: Amount')
          .required(),

        frequency: stringSchema()
          .label('Income & Expenditure section: Frequency')
          .required(),

        priority: stringSchema()
          .label('Income & Expenditure section: Priority')
          .required()
      })
    )
    .default([]),

  expenditureNotesAndComments: stringSchema().label(
    'Income & Expenditure section: Expenditure notes and comments'
  )
})
