import classnames from 'classnames'

import { Button, Modal } from '../../../misc'
import { PlusIcon } from '../../../icons'
import { ServiceLevelsModal } from '../ServiceLevelsModal'

const NewServiceLevelButton = props => {
  const { className, ...restProps } = props

  return (
    <Modal.Trigger
      as={Button}
      color={Button.Colors.BLUE}
      modal={ServiceLevelsModal}
      className={classnames(className)}
      {...restProps}
    >
      <PlusIcon />
      <span>New service level</span>
    </Modal.Trigger>
  )
  // return (
  //   <Modal.Trigger
  //     responsive
  //     as={Button}
  //     color={Button.Colors.BLUE}
  //     modal={ServiceLevelsModal}
  //     // modalProps={{ policyId, chargeType, isPolicyCharges }}
  //     {...props}
  //   >
  //     <PlusIcon />
  //     <span>New service level</span>
  //   </Modal.Trigger>
  // )
}

export { NewServiceLevelButton }
