import classnames from 'classnames'

import fileIcons from '../../../icons/File'
import { DownloadIcon, FileIcon } from '../../../icons'

import { formatFileSize } from '../../../../helpers/clientDocument'
import { formatDate } from '../../../../helpers/date'

import './SignatureDashboardCardList.less'

const PDFIcon = fileIcons.pdf

const EmptyState = () => (
  <div className='signature-dashboard-card-list__empty-message'>
    <FileIcon /> No pending signatures
  </div>
)

const SignatureDashboardCardList = props => {
  const {
    documents,
    onClickToSign,
    loading,
    isClientPortal,
    onDownloadDocument,
    onNameClick
  } = props

  return (
    <div
      className={classnames('signature-dashboard-card-list', {
        'signature-dashboard-card-list--empty': !documents.length
      })}
    >
      {!documents?.length && <EmptyState />}

      {documents?.map(doc => (
        <div className='signature-dashboard-card-list__item' key={doc.id}>
          <PDFIcon className='signature-dashboard-card-list__item__icon' />

          <div
            className={classnames(
              'signature-dashboard-card-list__item__details',
              {
                'signature-dashboard-card-list__item__details--client-portal': isClientPortal
              }
            )}
          >
            <div>
              <span onClick={() => {
                onNameClick(doc.id)
                onClickToSign(doc)
              }}
              >
                {doc.fileName}
              </span>
            </div>

            <div>
              {formatDate(doc.uploadedAt, 'DD MMM YYYY - HH:mm')} |{' '}
              {doc.createdBy.fullName} | {formatFileSize(doc.fileSize)}
            </div>
          </div>

          <div
            className={classnames(
              'signature-dashboard-card-list__item__click-to-sign',
              {
                'signature-dashboard-card-list__item__click-to-sign--loading': loading,
                'signature-dashboard-card-list__item__click-to-sign--client-portal': isClientPortal
              }
            )}
            onClick={() => onClickToSign(doc)}
          >
            Click to sign
          </div>

          {isClientPortal && (
            <DownloadIcon
              onClick={() => onDownloadDocument(doc.id)}
              className={classnames(
                'signature-dashboard-card-list__item__download',
                {
                  'signature-dashboard-card-list__item__download--loading': loading
                }
              )}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export { SignatureDashboardCardList }
