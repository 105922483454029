export const ColourTypes = Object.freeze({
  RED: '#ec2a2a',
  SALMON_PINK: '#f86666',
  BROWN: '#a46325',
  ORANGE: '#f7941d',
  YELLOW: '#f7bf47',
  GREEN: '#21c056',
  TURQUOISE: '#53D1ED',
  LIGHT_BLUE: '#82ACFF',
  BLUE: '#4d70ff',
  DARK_BLUE: '#1A3DCB',
  PURPLE: '#6c47db',
  DARK_GREY: '#516173',
  GREY: '#9fa2b0'
})

export const ColourTypesLabels = Object.freeze({
  [ColourTypes.RED]: 'Red',
  [ColourTypes.SALMON_PINK]: 'Salmon pink',
  [ColourTypes.BROWN]: 'Brown',
  [ColourTypes.ORANGE]: 'Orange',
  [ColourTypes.YELLOW]: 'Yellow',
  [ColourTypes.GREEN]: 'Green',
  [ColourTypes.TURQUOISE]: 'Turquoise',
  [ColourTypes.LIGHT_BLUE]: 'Light blue',
  [ColourTypes.BLUE]: 'Blue',
  [ColourTypes.DARK_BLUE]: 'Dark blue',
  [ColourTypes.PURPLE]: 'Purple',
  [ColourTypes.DARK_GREY]: 'Dark grey',
  [ColourTypes.GREY]: 'Grey'
})

export const ColourTypesCodes = Object.freeze({
  [ColourTypes.RED]: 'red',
  [ColourTypes.SALMON_PINK]: 'salmon-pink',
  [ColourTypes.BROWN]: 'brown',
  [ColourTypes.ORANGE]: 'orange',
  [ColourTypes.YELLOW]: 'yellow',
  [ColourTypes.GREEN]: 'green',
  [ColourTypes.TURQUOISE]: 'turquoise',
  [ColourTypes.LIGHT_BLUE]: 'light-blue',
  [ColourTypes.BLUE]: 'blue',
  [ColourTypes.DARK_BLUE]: 'dark-blue',
  [ColourTypes.PURPLE]: 'purple',
  [ColourTypes.DARK_GREY]: 'dark-grey',
  [ColourTypes.GREY]: 'grey'
})
