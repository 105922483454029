import T from 'prop-types'

import { InputField } from '../../form/fields'
import { ChevronLeftIcon } from '../../icons'

import './TitleInput.less'

const TitleInput = ({ name, placeholder, onBackBtnClick }) => (
  <div className='title-input'>
    {onBackBtnClick && (
      <ChevronLeftIcon
        className='title-input__back-button'
        onClick={onBackBtnClick}
      />
    )}

    <InputField name={name} inputProps={{ placeholder }} />
  </div>
)

TitleInput.propTypes = {
  name: T.string.isRequired,
  placeholder: T.string,
  onBackBtnClick: T.func
}

export { TitleInput }
