import { useMutation } from '@apollo/client'

import { ServiceLevelsForm } from './ServiceLevelsForm'

import createServiceLevelGql from '../../../../graphql/mutations/serviceLevel/createServiceLevel.graphql'
import updateServiceLevelGql from '../../../../graphql/mutations/serviceLevel/updateServiceLevel.graphql'
import serviceLevelsGql from '../../../../graphql/queries/serviceLevel/serviceLevels.graphql'

const createServiceLevel = () => useMutation(createServiceLevelGql, {
  update: (cache, { data }) => {
    try {
      const { createServiceLevel: serviceLevel } = data
      const { serviceLevels, ...restCache } = cache.readQuery({
        query: serviceLevelsGql
      })
      cache.writeQuery({
        query: serviceLevelsGql,
        data: {
          ...restCache,
          serviceLevels: serviceLevels.concat([serviceLevel])
        }
      })
    } catch (error) {
      // pass
    }
  }
})

const ServiceLevelsFormContainer = props => {
  const { serviceLevel } = props
  const isUpdate = serviceLevel && serviceLevel.id

  let useMutationResult
  if (!isUpdate) {
    useMutationResult = createServiceLevel()
  } else {
    useMutationResult = useMutation(updateServiceLevelGql)
  }

  const [mutate, mutationState] = useMutationResult

  return (
    <ServiceLevelsForm {...{
      mutate,
      mutationState,
      ...props
    }}
    />
  )
}

export { ServiceLevelsFormContainer }
