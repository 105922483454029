import { TaskTypes, TaskStatuses } from '@elenfs/elen-constants'

import { buildSelectOptions } from './../form/fields/SelectField/helpers'
import { TaskStatusLabels, TaskTypeLabels } from './../../helpers/enumLabels'

export const taskStatusOptions = buildSelectOptions(
  TaskStatuses,
  TaskStatusLabels
)

export const taskStatusOptionsColored = taskStatusOptions.map(option => {
  let color

  switch (option.value) {
    case TaskStatuses.TO_DO:
      color = 'grey'
      break
    case TaskStatuses.IN_PROGRESS:
      color = 'orange'
      break
    case TaskStatuses.COMPLETED:
      color = 'green'
      break
    default:
  }

  return { ...option, color }
})

export const taskTypeOptions = buildSelectOptions(
  TaskTypes,
  TaskTypeLabels
)

export const newTaskStatusOptions = taskStatusOptionsColored.filter(options => options.value?.toLowerCase() !== 'completed')
