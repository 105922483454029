import { Heading, LineChart } from '../../../misc'
import { PolicyValuationFilter } from '../PolicyValuationChart/PolicyValuationFilter'
import {
  LINE_CHART_LABEL_DATE_FORMAT,
  LINE_CHART_TOOLTIP_DATE_FORMAT,
  LINE_CHART_BACKGROUND_COLOR,
  LINE_CHART_BORDER_COLOR
} from './constants'

import './PolicyValuationChart.less'

const PolicyValuationChart = ({
  filterProps,
  lineChartProps,
  downloadReportProps,
  ...restProps
}) => {
  const lineChartData = {
    datasets: [{
      data: lineChartProps?.chartData?.map(item => {
        return {
          x: new Date(item.date),
          y: item.value.amount
        }
      }),
      borderDashOffset: 4,
      borderColor: LINE_CHART_BORDER_COLOR,
      backgroundColor: LINE_CHART_BACKGROUND_COLOR,
      tension: 0.1,
      pointRadius: 5,
      pointBorderWidth: 2,
      borderWidth: 1.5
    }]
  }

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: false }
    },
    scales: {
      x: {
        ticks: { font: { size: 10 } },
        grid: { display: false },
        type: 'time',
        time: {
          minUnit: 'day',
          tooltipFormat: LINE_CHART_TOOLTIP_DATE_FORMAT,
          displayFormats: {
            day: LINE_CHART_LABEL_DATE_FORMAT,
            week: LINE_CHART_LABEL_DATE_FORMAT,
            month: LINE_CHART_LABEL_DATE_FORMAT,
            quarter: LINE_CHART_LABEL_DATE_FORMAT,
            year: LINE_CHART_LABEL_DATE_FORMAT
          }
        }
      }
    },
    animation: {
      duration: 500,
      easing: 'easeInQuad'
    }
  }

  return (
    <div className='policy-valuation-chart'>
      <div className='policy-valuation-chart__header'>
        <Heading as='h3' className='policy-valuation-chart__header__title'>
          Policy valuation chart
        </Heading>

        <div className='policy-valuation-chart__header__actions'>
          <PolicyValuationFilter
            downloadReportProps={downloadReportProps}
            {...filterProps}
          />
        </div>
      </div>
      <div className='policy-valuation-chart__content'>
        <LineChart
          isEmpty={lineChartProps.isEmpty}
          data={lineChartData}
          options={lineChartOptions}
          {...restProps}
        />
      </div>
    </div>
  )
}

export { PolicyValuationChart }
