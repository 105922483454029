import { useQuery } from '@apollo/client'

import { PolicyTransactionsTab } from './PolicyTransactionsTab'
import { Spinner, ErrorMessage } from '../../misc'

import policyTransactionsGql
  from '../../../graphql/queries/policy/policyTransactions.graphql'
import clientPolicyTransactionsGql
  from '../../../graphql/queries/policy/clientPolicyTransactions.graphql'

const PolicyTransactionsTabContainer = (props) => {
  const { policy: { id: policyId }, isClientPolicy } = props

  const { loading, error, data } = useQuery(
    isClientPolicy ? clientPolicyTransactionsGql : policyTransactionsGql, {
      variables: {
        policyId
      },
      fetchPolicy: 'cache-and-network'
    })

  if (loading && !data) {
    return <Spinner />
  }

  if (error) {
    return <ErrorMessage text='Failed to load policy transactions' />
  }

  const { withdrawals, contributions } = data

  return (
    <PolicyTransactionsTab {...{
      contributions,
      withdrawals,
      readOnly: isClientPolicy
    }}
    />
  )
}

export { PolicyTransactionsTabContainer }
