import T from 'prop-types'

import { DropdownMenu } from '../../misc'
import { MenuDotsIcon } from '../../icons'
import { RemoveTaskCommentButton } from '../RemoveTaskCommentButton'

const TaskCommentActionsMenu = ({
  handleEdit,
  removeProps
}) => (
  <DropdownMenu
    className='task-comment-actions-menu'
    direction={DropdownMenu.Directions.RIGHT}
    trigger={<MenuDotsIcon width={18} height={18} />}
  >
    <DropdownMenu.List>
      <DropdownMenu.Item onClick={handleEdit}>
        Edit
      </DropdownMenu.Item>
      <RemoveTaskCommentButton {...{
        Component: (props) => (
          <DropdownMenu.Item {...props}>Delete</DropdownMenu.Item>
        ),
        ...removeProps
      }}
      />
    </DropdownMenu.List>
  </DropdownMenu>
)

TaskCommentActionsMenu.propTypes = {
  handleEdit: T.func.isRequired,
  removeProps: T.object.isRequired
}

export { TaskCommentActionsMenu }
