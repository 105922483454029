import T from 'prop-types'

import { Form } from '../../Form'

const BaseField = (props) => {
  const { error, label, children, required, ...restProps } = props

  return (
    <Form.Field
      error={error}
      {...restProps}
    >
      {label && <label>{label}{required && '*'}</label>}
      {children}
    </Form.Field>
  )
}

BaseField.propTypes = {
  /**
   * Error string, probably coming from Formik validation.
   */
  error: T.string,
  /**
   * Value for the input, probably coming from Formik.
   */
  label: T.string,
  /**
   * Children prop, probably containing input.
   */
  children: T.node
}

export { BaseField }
