import PropTypes from 'prop-types'

import { EmptyPage } from '../../misc'

const PendingIncomeEmpty = ({ text }) => (
  <EmptyPage
    text={text}
    hidden={false}
  />
)

PendingIncomeEmpty.defaultProps = {
  text: 'No pending income found!'
}

PendingIncomeEmpty.propTypes = {
  text: PropTypes.string.isRequired
}

export { PendingIncomeEmpty }
