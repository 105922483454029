import classnames from 'classnames'

import { DashboardCardTitle } from '../DashboardCardTitle'
import { IncomeCardFilter } from './IncomeCardFilter'
import { IncomeChart } from './IncomeChart'
import { LoadingMessage } from '../../misc'

import './IncomeCard.less'

const IncomeCard = props => {
  const {
    className,
    filterProps,
    myIncome,
    companyIncome,
    loading,
    initialLoading
  } = props

  return (
    <div className={classnames('income-card', className)}>
      <DashboardCardTitle>Income</DashboardCardTitle>
      <div className='income-card__body'>
        <IncomeCardFilter {...filterProps} />

        {initialLoading && <LoadingMessage text='Fetching' />}

        <div className='income-card__body__charts'>
          {myIncome && (
            <div className='income-card__body__charts__chart'>
              <div className='income-card__body__charts__chart__title'>
                My Income
              </div>
              <div>
                <IncomeChart income={myIncome} loading={loading} />
              </div>
            </div>
          )}

          {myIncome && companyIncome && (
            <div className='income-card__body__charts__separator' />
          )}

          {companyIncome && (
            <div className='income-card__body__charts__chart'>
              <div className='income-card__body__charts__chart__title'>
                Company Income
              </div>
              <div>
                <IncomeChart income={companyIncome} loading={loading} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { IncomeCard }
