import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { CompanyUserRoles } from '@elenfs/elen-constants'
import { withCurrentUser } from './../../hoc/container'

import { useNavigate } from 'react-router-dom'
import { UsersIcon } from '../../icons'
import { ClientsDashboardCard } from './ClientsDashboardCard'
import { CreateClientButton } from '../../client/CreateClientButton'
import { DropdownMenu, Button } from '../../misc'
import { useCashCalc } from '../../../hooks'

import clientsCountGql from '../../../graphql/queries/client/clientsCount.graphql'

const NewClientLink = () => {
  const { isCashCalcConnectionActive } = useCashCalc()
  return (
    <CreateClientButton
      buttonColor={Button.Colors.BLUE_TEXT}
      direction={DropdownMenu.Directions.BOTTOM}
      isCashCalcConnectionActive={isCashCalcConnectionActive}
    />
  )
}

const ClientsDashboardCardContainer = ({ currentUser }) => {
  const navigate = useNavigate()
  const { data } = useQuery(clientsCountGql, {
    fetchPolicy: 'cache-first'
  })
  const isFA = CompanyUserRoles.ADVISER === currentUser?.role

  const { myClientsCount, allClientsCount } = data?.clientsCount || {}

  const boxes = [
    { quantity: myClientsCount || 0, description: 'My Clients' },
    {
      quantity: allClientsCount || 0,
      description: isFA ? 'All Clients' : 'Clients'
    }
  ]

  const cardProps = {
    isColumn: true,
    Icon: UsersIcon,
    boxes: isFA ? boxes : boxes.slice(1, 2)
  }

  const titleProps = {
    ActionComponent: NewClientLink
  }

  const onCardClick = useCallback(() => navigate('/secure/clients'), [navigate])

  return <ClientsDashboardCard {...{ cardProps, titleProps, onCardClick }} />
}

const EnhancedClientsDashboardCardContainer = withCurrentUser()(
  ClientsDashboardCardContainer
)
export { EnhancedClientsDashboardCardContainer as ClientsDashboardCardContainer }
