import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import T from 'prop-types'

import { PolicyNoteCard } from './PolicyNoteCard'

import updatePolicyNoteGql from '../../../graphql/mutations/policy/updatePolicyNote.graphql'

const PolicyNoteCardContainer = ({ note, policyId }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [mutationError, setMutationError] = useState()

  const [updatePolicyNote, { loading }] = useMutation(updatePolicyNoteGql, {
    onCompleted: () => setIsEdit(false),

    onError: setMutationError
  })

  const onSubmit = async ({ content }) => {
    await updatePolicyNote({ variables: { input: { content, id: policyId } } })
  }

  const noteCardProps = {
    onSubmit,
    isEdit,
    setIsEdit,
    note,
    placeholder: 'Edit policy notes here',
    mutationLoading: loading,
    mutationError
  }

  useEffect(() => {
    if (!isEdit && mutationError) setMutationError(null)
  }, [isEdit, mutationError])

  return <PolicyNoteCard {...noteCardProps} />
}

PolicyNoteCardContainer.defaultProps = {
  note: {}
}

PolicyNoteCardContainer.propTypes = {
  note: T.shape({
    content: T.string,
    updatedAt: T.string,
    updatedBy: T.string
  }),
  policyId: T.string
}

export { PolicyNoteCardContainer }
