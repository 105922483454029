import moment from 'moment'

import ImportJobStats from '../../ImportJobSummary/ImportJobStats'

export const SingleImportLogHeaderSummary = ({ stats, job }) => {
  return (
    <div className='single-import-layout__mid-content__header_summary'>
      <ImportJobStats {...stats} />
      <div className='single-import-layout__mid-content__header_summary__date'>
        {moment(job?.completedAt).format('D MMM YYYY - HH:mm')}
      </div>
    </div>
  )
}
