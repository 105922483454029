import T from 'prop-types'

const ImportInfo = ({ heading, description }) => (
  <div className='import-job-summary__log__info'>
    <p className='import-job-summary__log__info__heading'>
      {heading}
    </p>
    {description && (
      <p className='import-job-summary__log__info__desc'>
        {description}
      </p>
    )}
  </div>
)

ImportInfo.propTypes = {
  heading: T.node.isRequired,
  description: T.node
}

export default ImportInfo
