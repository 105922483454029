import T from 'prop-types'

import { EmptyPage } from '../../misc'

const ImportLogEmpty = ({ hidden }) => (
  <EmptyPage
    text='Logs will appear here for each data import.'
    className='import-log__empty-content'
    hidden={hidden}
  />
)

ImportLogEmpty.propTypes = {
  hidden: T.bool.isRequired
}

export { ImportLogEmpty }
