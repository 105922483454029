import './Pagination.less'

import { ChevronLeftIcon, ChevronRightIcon } from '../../icons'

export const PrevLabel = () => {
  return (
    <div className='pagination__pagination_label pagination__pagination_label__svg'>
      <ChevronLeftIcon />
      Prev
    </div>
  )
}

export const NextLabel = () => {
  return (
    <div className='pagination__pagination_label'>
      Next
      <ChevronRightIcon />
    </div>
  )
}
