import constants from '@elenfs/elen-constants'

import { DebtTypesLabels } from './debtPolicy'
import { InvestmentTypesLabels } from './investmentPolicy'
import { MortgageTypesLabels } from './mortgagePolicy'
import { PensionTypesLabels } from './pensionPolicy'
import { ProtectionTypesLabels } from './protectionPolicy'

// TODO: split this module into at least three:
// policy/policy, policy/transactions, policy/honorariums

const {
  PolicyCategories,
  PolicyHolders,
  Frequencies,
  PolicyStatuses,
  Contributors,
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} = constants.policy.common

const { Currencies, RecurrentFrequencies } = constants.misc

export const PolicyTypeByCategory = Object.freeze({
  [PolicyCategories.DEBTS]: 'debtType',
  [PolicyCategories.INVESTMENT]: 'investmentType',
  [PolicyCategories.MORTGAGES]: 'mortgageType',
  [PolicyCategories.PENSIONS]: 'pensionType',
  [PolicyCategories.PROTECTION]: 'protectionType'
})

export const PolicyHoldersLabels = Object.freeze({
  [PolicyHolders.CLIENT]: 'Client',
  [PolicyHolders.JOINT]: 'Joint',
  [PolicyHolders.PARTNER]: 'Partner'
})

export const RecurrentFrequenciesLabels = Object.freeze({
  [RecurrentFrequencies.ANNUALLY]: 'Annually',
  [RecurrentFrequencies.MONTHLY]: 'Monthly',
  [RecurrentFrequencies.QUARTERLY]: 'Quarterly'
})

export const FrequenciesLabels = Object.freeze({
  ...RecurrentFrequenciesLabels,
  [Frequencies.SINGLE]: 'Single'
})

export const HonorariumTypesLabels = Object.freeze({
  INITIAL: 'Initial',
  ONGOING: 'Ongoing',
  SWITCH: 'Switch',
  AD_HOC: 'Ad-hoc'
})

export const HonorariumFrequenciesLabels = Object.freeze({
  SINGLE: 'Single',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  HALF_YEARLY: 'Half-yearly',
  ANNUALLY: 'Annually'
})

export const TransactionExecutorsLabels = Object.freeze({
  CLIENT: 'Client',
  EMPLOYER: 'Employer'
})

export const TransactionFrequenciesLabels = Object.freeze({
  SINGLE: 'Single',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  HALF_YEARLY: 'Half-yearly',
  ANNUALLY: 'Annually'
})

export const CurrenciesLabels = Object.freeze({
  [Currencies.AED]: 'د.إ',
  [Currencies.AFN]: '؋',
  [Currencies.All]: 'L',
  [Currencies.AMD]: '֏',
  [Currencies.ANG]: 'ƒ',
  [Currencies.AOA]: 'Kz',
  [Currencies.AFN]: '؋',
  [Currencies.ARD]: '$',
  [Currencies.AUD]: '$',
  [Currencies.AWG]: 'ƒ',
  [Currencies.AZN]: '₼',
  [Currencies.BAM]: 'KM',
  [Currencies.BBD]: '$',
  [Currencies.BDT]: '৳',
  [Currencies.BGN]: 'лв',
  [Currencies.BHD]: '.د.ب',
  [Currencies.BIF]: 'FBu',
  [Currencies.BMD]: '$',
  [Currencies.BND]: '$',
  [Currencies.BOB]: '$b',
  [Currencies.BOV]: 'BOV',
  [Currencies.BRL]: 'R$',
  [Currencies.BSD]: '$',
  [Currencies.BTC]: '₿',
  [Currencies.BTN]: 'Nu.',
  [Currencies.BWP]: 'P',
  [Currencies.BYN]: 'Br',
  [Currencies.BYR]: 'Br',
  [Currencies.BZD]: 'BZ$',
  [Currencies.CAD]: '$',
  [Currencies.CDF]: 'FC',
  [Currencies.CHE]: 'CHE',
  [Currencies.CHF]: 'CHF',
  [Currencies.CHW]: 'CHW',
  [Currencies.CLF]: 'CLF',
  [Currencies.CLP]: '$',
  [Currencies.CNH]: '¥',
  [Currencies.CNY]: '¥',
  [Currencies.COP]: '$',
  [Currencies.COU]: 'COU',
  [Currencies.CRC]: '₡',
  [Currencies.CUC]: '$',
  [Currencies.CUP]: '₱',
  [Currencies.CVE]: '$',
  [Currencies.CZK]: 'Kč',
  [Currencies.DJF]: 'Fdj',
  [Currencies.DKK]: 'kr',
  [Currencies.DOP]: 'RD$',
  [Currencies.DZD]: 'دج',
  [Currencies.EEK]: 'kr',
  [Currencies.EGP]: '£',
  [Currencies.ERN]: 'Nfk',
  [Currencies.ETB]: 'Br',
  [Currencies.ETH]: 'Ξ',
  [Currencies.EUR]: '€',
  [Currencies.FJD]: '$',
  [Currencies.FKP]: '£',
  [Currencies.GBP]: '£',
  [Currencies.GEL]: '₾',
  [Currencies.GGP]: '£',
  [Currencies.GHC]: '₵',
  [Currencies.GHS]: 'GH₵',
  [Currencies.GIP]: '£',
  [Currencies.GMD]: 'D',
  [Currencies.GNF]: 'FG',
  [Currencies.GTQ]: 'Q',
  [Currencies.GYD]: '$',
  [Currencies.HKD]: '$',
  [Currencies.HNL]: 'L',
  [Currencies.HRK]: 'kn',
  [Currencies.HTG]: 'G',
  [Currencies.HUF]: 'Ft',
  [Currencies.IDR]: 'Rp',
  [Currencies.ILS]: '₪',
  [Currencies.IMP]: '£',
  [Currencies.INR]: '₹',
  [Currencies.IQD]: 'ع.د',
  [Currencies.IRR]: '﷼',
  [Currencies.ISK]: 'kr',
  [Currencies.JEP]: '£',
  [Currencies.JMD]: 'J$',
  [Currencies.JOD]: 'JD',
  [Currencies.JPY]: '¥',
  [Currencies.KES]: 'KSh',
  [Currencies.KGS]: 'лв',
  [Currencies.KHR]: '៛',
  [Currencies.KMF]: 'CF',
  [Currencies.KPW]: '₩',
  [Currencies.KRW]: '₩',
  [Currencies.KWD]: 'KD',
  [Currencies.KYD]: '$',
  [Currencies.KZT]: '₸',
  [Currencies.LAK]: '₭',
  [Currencies.LBP]: '£',
  [Currencies.LKR]: '₨',
  [Currencies.LRD]: '$',
  [Currencies.LSL]: 'M',
  [Currencies.LTC]: 'Ł',
  [Currencies.LTL]: 'Lt',
  [Currencies.LVL]: 'Ls',
  [Currencies.LYD]: 'LD',
  [Currencies.MAD]: 'MAD',
  [Currencies.MDL]: 'lei',
  [Currencies.MGA]: 'Ar',
  [Currencies.MKD]: 'ден',
  [Currencies.MMK]: 'K',
  [Currencies.MNT]: '₮',
  [Currencies.MOP]: 'MOP$',
  [Currencies.MRO]: 'UM',
  [Currencies.MRU]: 'UM',
  [Currencies.MUR]: '₨',
  [Currencies.MVR]: 'Rf',
  [Currencies.MWK]: 'MK',
  [Currencies.MXN]: '$',
  [Currencies.MXV]: 'MXV',
  [Currencies.MYR]: 'RM',
  [Currencies.MZN]: 'MT',
  [Currencies.NAD]: '$',
  [Currencies.NGN]: '₦',
  [Currencies.NIO]: 'C$',
  [Currencies.NOK]: 'kr',
  [Currencies.NPR]: '₨',
  [Currencies.NZD]: '$',
  [Currencies.OMR]: '﷼',
  [Currencies.PAB]: 'B/.',
  [Currencies.PEN]: 'S/.',
  [Currencies.PGK]: 'K',
  [Currencies.PHP]: '₱',
  [Currencies.PKR]: '₨',
  [Currencies.PLN]: 'zł',
  [Currencies.PYG]: 'Gs',
  [Currencies.QAR]: '﷼',
  [Currencies.RMB]: '￥',
  [Currencies.RON]: 'lei',
  [Currencies.RSD]: 'Дин.',
  [Currencies.RUB]: '₽',
  [Currencies.RWF]: 'R₣',
  [Currencies.SAR]: '﷼',
  [Currencies.SBD]: '$',
  [Currencies.SCR]: '₨',
  [Currencies.SDG]: 'ج.س.',
  [Currencies.SEK]: 'kr',
  [Currencies.SGD]: 'S$',
  [Currencies.SHP]: '£',
  [Currencies.SLL]: 'Le',
  [Currencies.SOS]: 'S',
  [Currencies.SRD]: '$',
  [Currencies.SSP]: '£',
  [Currencies.STD]: 'Db',
  [Currencies.STN]: 'Db',
  [Currencies.SVC]: '$',
  [Currencies.SYP]: '£',
  [Currencies.SZL]: 'E',
  [Currencies.THB]: '฿',
  [Currencies.TJS]: 'SM',
  [Currencies.TMT]: 'T',
  [Currencies.TND]: 'د.ت',
  [Currencies.TOP]: 'T$',
  [Currencies.TRL]: '₤',
  [Currencies.TRY]: '₺',
  [Currencies.TTD]: 'TT$',
  [Currencies.TVD]: '$',
  [Currencies.TWD]: 'NT$',
  [Currencies.TZS]: 'TSh',
  [Currencies.UAH]: '₴',
  [Currencies.UGX]: 'USh',
  [Currencies.USD]: '$',
  [Currencies.UYI]: 'UYI',
  [Currencies.UYU]: '$U',
  [Currencies.UYW]: 'UYW',
  [Currencies.UZS]: 'лв',
  [Currencies.VEF]: 'Bs',
  [Currencies.VES]: 'Bs.S',
  [Currencies.VND]: '₫',
  [Currencies.VUV]: 'VT',
  [Currencies.WST]: 'WS$',
  [Currencies.XAF]: 'FCFA',
  [Currencies.XBT]: 'Ƀ',
  [Currencies.XCD]: '$',
  [Currencies.XOF]: 'CFA',
  [Currencies.XPF]: '₣',
  [Currencies.XSU]: 'Sucre',
  [Currencies.XUA]: 'XUA',
  [Currencies.YER]: '﷼',
  [Currencies.ZAR]: 'R',
  [Currencies.ZMW]: 'ZK',
  [Currencies.ZWD]: 'Z$',
  [Currencies.ZWL]: '$'
})

export const PolicyTableHeaders = Object.freeze({
  CATEGORY: 'Category',
  POLICY_TYPE: 'Policy type',
  NUMBER: 'Number',
  POLICY_HOLDER: 'Policy holder(s)',
  PROVIDER: 'Provider',
  LATEST_VALUATION: 'Latest Valuation',
  VALUATION_DATE: 'Valuation Date'
})

export const PolicyContributionsTableHeader = Object.freeze({
  CONTRIBUTOR: 'Contributor',
  METHOD: 'Method',
  FREQUENCY: 'Frequency',
  DATE: 'Date',
  AMOUNT: 'Amount',
  UNITS: 'No. of payments',
  TOTAL_AMOUNT: 'Total amount',
  EMPTY: ''
})

export const PolicyWithdrawalsTableHeader = Object.freeze({
  PAID_TO: 'Paid to',
  METHOD: 'Method',
  FREQUENCY: 'Frequency',
  DATE: 'Date',
  AMOUNT: 'Amount',
  UNITS: 'No. of payments',
  TOTAL_AMOUNT: 'Total amount',
  EMPTY: ''
})

export const PolicyStatusLabels = Object.freeze({
  [PolicyStatuses.EXISTING]: 'No',
  [PolicyStatuses.NEW]: 'Yes'
})

export const ContributorsLabels = Object.freeze({
  [Contributors.EMPLOYER]: 'Employer',
  [Contributors.CLIENT]: 'Client'
})

export const AllPolicyTypesLabels = {
  ...DebtTypesLabels,
  ...InvestmentTypesLabels,
  ...MortgageTypesLabels,
  ...PensionTypesLabels,
  ...ProtectionTypesLabels
}

export {
  PolicyCategoriesLabels,
  PolicyProvidersLabels
}
