import { useCallback } from 'react'
import { compose } from 'ramda'
import { withFormik, connect } from 'formik'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import T from 'prop-types'

import { Form } from '../../form/Form'
import { Button, GoBackHeader } from '../../misc'
import clientComplienceSchema from '../../../schemas/client/clientComplienceSchema'
import { buildInitialValues } from '../../../helpers/form'
import { parseGraphqlErrors } from '../../../helpers/error'
import { ClientComplianceDetailsForm } from './ClientComplianceDetailsForm'
import { ClientFormPageWrapper } from '../ClientFormPageWrapper'

import './ClientComplianceForm.less'

const ClientComplianceForm = ({
  loading,
  formik: { errors, submitForm, validateForm },
  clientId,
  serviceLevelsOptions
}) => {
  const navigate = useNavigate()
  const validateAndSubmit = useCallback(
    async () => {
      try {
        const errors = await validateForm()

        if (Object.keys(errors).length) { return false }

        await submitForm()
        navigate(`/secure/clients/${clientId}/compliance/details`)
        return true
      } catch (e) {
        return false
      }
    },
    [submitForm]
  )

  return (
    <ClientFormPageWrapper>
      <GoBackHeader title='Edit compliance' className='clients-section__content--header' />

      <Form className={classnames('compliance-form', 'clients-section__content--body')}>
        <ClientComplianceDetailsForm {...{ loading, errors, serviceLevelsOptions }} />

        <div className='compliance-form__buttons-container'>
          <Button color={Button.Colors.BLUE} onClick={validateAndSubmit}>
            Save
          </Button>
        </div>
      </Form>
    </ClientFormPageWrapper>
  )
}

const ClientComplianceFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: clientComplienceSchema,
    mapPropsToValues: ({ client }) =>
      buildInitialValues(clientComplienceSchema, {
        ...client
      }),
    handleSubmit: async (values, { props, setErrors }) => {
      const { client, onSubmit } = props
      const valuesToSubmit = clientComplienceSchema.cast(values, { stripUnknown: true })

      const newValues = Object.assign({}, valuesToSubmit)

      const input = { clientData: newValues }

      if (client && client.id) {
        input.id = client.id

        newValues.firstName = client.firstName
        newValues.lastName = client.lastName
        newValues.name = client.name
      }

      try {
        const res = await onSubmit({ variables: { input } })
        return res
      } catch (err) {
        setErrors(parseGraphqlErrors(
          err,
          'There was an error saving the complience, please try again later'
        ))
        throw err
      }
    }
  }),
  connect
)(ClientComplianceForm)

ClientComplianceFormEnhanced.defaultProps = {
  loading: false,
  client: {}
}

ClientComplianceFormEnhanced.propTypes = {
  /**
   * Is any mutation loading. Eg mutation for saving or for deleting the charge.
   */
  loading: T.bool,
  /**
   * Callback to submit the form.
   */
  onSubmit: T.func.isRequired
}

export { ClientComplianceFormEnhanced as ClientComplianceForm }
