import T from 'prop-types'

import { Heading, TableResponsiveActionHeader } from '../../../misc'
import { NewPolicyTransactionButton } from '../NewPolicyTransactionButton'
import { capitalizeFirstLetter } from '../../../../helpers/misc'

const PolicyTransactionsHeader = ({ policyTransactionType, readOnly }) => (
  <TableResponsiveActionHeader>
    <Heading as='h3'>{capitalizeFirstLetter(policyTransactionType)}s</Heading>
    {!readOnly && <NewPolicyTransactionButton {...{ policyTransactionType }} />}
  </TableResponsiveActionHeader>
)

PolicyTransactionsHeader.propTypes = {
  policyTransactionType: T.string.isRequired
}

export { PolicyTransactionsHeader }
