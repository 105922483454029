import { useState } from 'react'
import { compose } from 'ramda'
import { withFormik, connect } from 'formik'
import T from 'prop-types'

import { Form, FormErrorMessages } from '../../form'
import { InputField } from '../../form/fields'
import { Button, LoadingMessage } from '..'

const ConfirmPasswordModalForm = props => {
  const {
    setErrors,
    errors,
    values: { password },
    close,
    description,
    onSubmit
  } = props

  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors({ mutationError: '' })
    setLoading(true)
    try {
      await onSubmit({ password })
    } catch (error) {
      setErrors({ mutationError: 'Error: ' + error?.message })
    }
    setLoading(false)
  }

  return (
    <Form
      className='confirm-password-modal__content__form'
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      <div className='confirm-password-modal__content__form__description'>
        {description}
      </div>

      <InputField
        name='password'
        label='Password'
        className='confirm-password-modal__content__form__input'
        inputProps={{
          type: 'password',
          autoComplete: 'off'
        }}
      />

      {loading && <LoadingMessage text='Please wait...' />}
      {errors?.mutationError && <FormErrorMessages validationErrors={errors} />}

      <div className='confirm-password-modal__content__buttons'>
        <Button
          className='confirm-password-modal__content__buttons__button'
          color={Button.Colors.BLUE}
          type='submit'
          disabled={loading || !password?.length}
        >
          Confirm
        </Button>

        <Button
          className='confirm-password-modal__content__buttons__button'
          color={Button.Colors.GREY}
          disabled={loading}
          onClick={close}
        >
          Cancel
        </Button>
      </div>
    </Form>
  )
}

ConfirmPasswordModalForm.propTypes = {
  onSubmit: T.func.isRequired,
  // Close modal func
  close: T.func.isRequired,
  // Props passed from container component (mutation)
  description: T.node
}

const EnhancedConfirmPasswordModalForm = compose(
  withFormik({
    validateOnChange: false
  }),
  connect
)(ConfirmPasswordModalForm)

export { EnhancedConfirmPasswordModalForm as ConfirmPasswordModalForm }
