import T from 'prop-types'
import classnames from 'classnames'

import './EmptyPage.less'

const EmptyPage = ({ text, renderButton, hidden, className, readOnly }) => (
  <div className={classnames('empty-page', hidden && 'empty-page--hidden', className)}>
    <div className='empty-page__text'>{text}</div>
    {renderButton && !readOnly &&
      <div className='empty-page__button-container'>{renderButton()}</div>}
  </div>
)

EmptyPage.propTypes = {
  /**
   * Text for empty page. Eg: `No entities. Click the button to add entities`
   */
  text: T.oneOfType([T.string, T.object]).isRequired,
  /**
   * Render prop function that returns buttons
   */
  renderButton: T.func,
  // Explanation for this: buttons often open modals.
  // Since they're modal triggers,
  // if they're not rendered
  // (eg in case that you submit the form, a policy is added, and no policies page isn't rendered anymore),
  // the modal they open isn't rendered either;
  // so the solution is to use display: none
  // to hide the buttons that open modals.
  /**
   * Whether the page is hidden using `display: none` or not. Used when we need it rendered, but not displayed.
   */
  hidden: T.bool,
  /**
   * readOnly: Whether is read-only, Actions are not allowed to read only empty page
   */
  readOnly: T.bool,
  /**
   * Classname for empty page.
   */
  className: T.string
}

export { EmptyPage }
