"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.NI_NUMBER_REGEX = exports.MaritalStatus = exports.Gender = exports.EmploymentStatus = exports.ClientTypes = exports.ClientTitles = exports.ClientTaxRates = exports.ClientRelationshipTypes = exports.ClientRelationshipReverseTypes = exports.ClientIncomeTypes = exports.ClientIncomeTypeLabels = exports.ClientIncomeFrequencyLabels = exports.ClientIncomeFrequencies = exports.ClientExpensePriorityLabels = exports.ClientExpensePriorities = exports.ClientExpenseFrequencyLabels = exports.ClientExpenseFrequencies = void 0;
const ClientTypes = exports.ClientTypes = Object.freeze({
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATE: 'CORPORATE'
});
const ClientTitles = exports.ClientTitles = Object.freeze({
  MR: 'Mr',
  MRS: 'Mrs',
  MS: 'Ms',
  MISS: 'Miss',
  DR: 'Dr'
});
const Gender = exports.Gender = Object.freeze({
  MALE: 'MALE',
  FEMALE: 'FEMALE'
});
const MaritalStatus = exports.MaritalStatus = Object.freeze({
  MARRIED: 'MARRIED',
  SINGLE: 'SINGLE',
  CIVIL_UNION: 'CIVIL_UNION',
  DIVORCED: 'DIVORCED',
  COHABITING: 'COHABITING',
  WIDOWED: 'WIDOWED',
  PARTNERED: 'PARTNERED',
  SEPARATED: 'SEPARATED'
});
const EmploymentStatus = exports.EmploymentStatus = Object.freeze({
  EMPLOYED: 'EMPLOYED',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  UNEMPLOYED: 'UNEMPLOYED',
  RETIRED: 'RETIRED'
});
const ClientTaxRates = exports.ClientTaxRates = Object.freeze({
  ADDITIONAL_RATE_TAXPAYER: 'ADDITIONAL_RATE_TAXPAYER',
  BASIC_RATE_TAXPAYER: 'BASIC_RATE_TAXPAYER',
  HIGHER_RATE_TAXPAYER: 'HIGHER_RATE_TAXPAYER',
  NON_TAXPAYER: 'NON_TAXPAYER'
});
const ClientRelationshipTypes = exports.ClientRelationshipTypes = Object.freeze({
  SPOUSE: 'SPOUSE',
  PARTNER: 'PARTNER',
  BUSINESS_PARTNER: 'BUSINESS_PARTNER',
  CO_TRUSTEE: 'CO_TRUSTEE',
  PARENT: 'PARENT',
  CHILD: 'CHILD',
  GRANDPARENT: 'GRANDPARENT',
  GRANDCHILD: 'GRANDCHILD'
});
const ClientRelationshipReverseTypes = exports.ClientRelationshipReverseTypes = Object.freeze({
  [ClientRelationshipTypes.PARENT]: ClientRelationshipTypes.CHILD,
  [ClientRelationshipTypes.CHILD]: ClientRelationshipTypes.PARENT,
  [ClientRelationshipTypes.GRANDPARENT]: ClientRelationshipTypes.GRANDCHILD,
  [ClientRelationshipTypes.GRANDCHILD]: ClientRelationshipTypes.GRANDPARENT
});
const ClientIncomeFrequencies = exports.ClientIncomeFrequencies = Object.freeze({
  ONE_OFF: 'ONE_OFF',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY'
});
const ClientIncomeFrequencyLabels = exports.ClientIncomeFrequencyLabels = Object.freeze({
  [ClientIncomeFrequencies.ONE_OFF]: 'One off',
  [ClientIncomeFrequencies.WEEKLY]: 'Weekly',
  [ClientIncomeFrequencies.MONTHLY]: 'Monthly',
  [ClientIncomeFrequencies.YEARLY]: 'Yearly'
});
const ClientIncomeTypes = exports.ClientIncomeTypes = Object.freeze({
  PAYE: 'PAYE',
  DIVIDEND: 'DIVIDEND',
  SALE_OF_ASSET: 'SALE_OF_ASSET'
});
const ClientIncomeTypeLabels = exports.ClientIncomeTypeLabels = Object.freeze({
  [ClientIncomeTypes.PAYE]: 'PAYE',
  [ClientIncomeTypes.DIVIDEND]: 'Dividend',
  [ClientIncomeTypes.SALE_OF_ASSET]: 'Sale of asset'
});
const ClientExpenseFrequencies = exports.ClientExpenseFrequencies = Object.freeze({
  ONE_OFF: 'ONE_OFF',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY'
});
const ClientExpenseFrequencyLabels = exports.ClientExpenseFrequencyLabels = Object.freeze({
  [ClientExpenseFrequencies.ONE_OFF]: 'One off',
  [ClientExpenseFrequencies.WEEKLY]: 'Weekly',
  [ClientExpenseFrequencies.MONTHLY]: 'Monthly',
  [ClientExpenseFrequencies.YEARLY]: 'Yearly'
});
const ClientExpensePriorities = exports.ClientExpensePriorities = Object.freeze({
  ESSENTIAL: 'ESSENTIAL',
  LIFESTYLE: 'LIFESTYLE',
  DISCRETIONARY: 'DISCRETIONARY'
});
const ClientExpensePriorityLabels = exports.ClientExpensePriorityLabels = Object.freeze({
  [ClientExpensePriorities.ESSENTIAL]: 'Essential',
  [ClientExpensePriorities.LIFESTYLE]: 'Lifestyle',
  [ClientExpensePriorities.DISCRETIONARY]: 'Discretionary'
});
const NI_NUMBER_REGEX = exports.NI_NUMBER_REGEX = /^\S{1,}$/;
var _default = exports.default = {
  ClientTypes,
  ClientTitles,
  Gender,
  MaritalStatus,
  EmploymentStatus,
  ClientTaxRates,
  ClientRelationshipTypes,
  ClientRelationshipReverseTypes,
  ClientIncomeFrequencies,
  ClientIncomeFrequencyLabels,
  ClientIncomeTypes,
  ClientIncomeTypeLabels,
  ClientExpenseFrequencies,
  ClientExpenseFrequencyLabels,
  ClientExpensePriorities,
  ClientExpensePriorityLabels
};