import { connect } from 'formik'

import Card from '../../misc/Card'
import FormFieldsCard from '../../form/FormFieldsCard'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import { SelectField } from '../../../components/form/fields'

import './ClientFinancialAdvisersForm.less'

const ClientFinancialAdvisersForm = ({
  financialAdviserOptions,
  financialAdvisersError,
  prevLink,
  isClientPortal
}) => (
  <Card className='client-current-fa-form'>
    <FormFieldsCard title='Financial Adviser'>
      <div className='client-current-fa-form__row'>
        <SelectField
          name='financialAdviserId'
          label='Current adviser'
          options={financialAdviserOptions}
          error={financialAdvisersError}
          inputProps={{ isDisabled: isClientPortal }}
        />
      </div>
    </FormFieldsCard>

    <NextAndPreviousLinks previousLinkTo={prevLink} />
  </Card>
)

export default connect(ClientFinancialAdvisersForm)
