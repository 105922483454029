import { useState, useEffect, useRef, useCallback } from 'react'
import classnames from 'classnames'
import T from 'prop-types'
import { map, always } from 'ramda'

import { useTableSort } from './../../../misc/Table/useSortTable'
import { localStorageUserSettingsKeys, useSortDND } from '../../../../hooks'

import { Table, Button } from '../../../misc'
import { InlineForm } from '../../../misc/InlineForm/InlineForm'
import { TasksTableBody, TasksTableBodyDraggable } from '../TasksTableBody'

import { TABLE_HEADERS, TASK_CELL_ENTITY_DATA_LABEL } from '../const'
import { taskPropType } from '../propTypes'

import { TasksFilter } from '../../TasksFilter'
import { TasksChangeViewSwitch } from '../../TasksChangeViewSwitch'
import { PlusIcon } from '../../../icons'

import './TasksTable.less'

const TasksTable = props => {
  const {
    tasks,
    className,
    loadingOnCreateTask,
    errorOnCreateTask,
    onCreateTask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    onCreateSubtask,
    onTaskClick,
    currentUser,
    isDraggable,
    onSortDND,
    filterProps,
    openNewTaskModal,
    isClientTasks
  } = props

  const [toggleState, setToggleState] = useState({})

  const subTasksWrapperRef = useRef({})

  useEffect(() => {
    setTimeout(() => {
      for (const [key, value] of Object.entries(toggleState)) {
        const el = subTasksWrapperRef.current[key]
        if (el) {
          el.parentElement.style.height = (value ? el.offsetHeight : 0) + 'px'
          el.parentElement.style.overflow = (value ? 'visible' : 'hidden')
        }
      }
    }, 0)
  }, [toggleState, errorsOnCreateSubtask, tasks])

  useEffect(() => {
    const resizeFunc = () => setToggleState(map(always(false)))
    window.addEventListener('resize', resizeFunc)
    return () => window.removeEventListener('resize', resizeFunc)
  }, [])

  const toggleSubtaskTable = useCallback(key =>
    setToggleState(current => ({
      ...current,
      [key]: !current[key]
    }))
  )

  const {
    sortedData: sortedTasksByHeader,
    headersState,
    onSort,
    onResetSort
  } = useTableSort({
    tableHeaders: TABLE_HEADERS,
    data: tasks,
    persistKeyLS: localStorageUserSettingsKeys.TASK_SORT
  })

  const { sortedData: sortedTasksByDND, onDragEnd } = useSortDND({
    data: tasks,
    onSortDND
  })

  const tableBodyProps = {
    sortedTasks: isDraggable ? sortedTasksByDND : sortedTasksByHeader,
    toggleState,
    subTasksWrapperRef,
    toggleSubtaskTable,
    onTaskClick,
    onCreateSubtask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    currentUser
  }

  return (
    <>
      {!isClientTasks ? <TasksFilter {...filterProps} /> : null}

      <div className='tasks-section__header-container'>
        {!isClientTasks ? <TasksChangeViewSwitch /> : null}
        <Button
          color={Button.Colors.BLUE}
          responsive
          onClick={openNewTaskModal}
        >
          <PlusIcon /> <span> New Task </span>
        </Button>
      </div>

      <Table className={classnames('tasks-table', className)}>
        <Table.Header>
          <Table.Row>
            {headersState?.map((header, i) => (
              <Table.HeaderCell
                key={i}
                {...{
                  key: i,
                  className: 'tasks-table__header-cell',
                  isSortable: true,
                  header,
                  onSort,
                  onResetSort,
                  [TASK_CELL_ENTITY_DATA_LABEL]: header.dataTaskCellEntity
                }}
              />
            ))}
          </Table.Row>
        </Table.Header>
        {
          isDraggable
            ? (
              <TasksTableBodyDraggable
                {...{ ...tableBodyProps, setToggleState, onDragEnd, isClientTasks }}
              />
              )
            : (
              <TasksTableBody isClientTasks={isClientTasks} {...tableBodyProps} />
              )
        }
      </Table>

      <InlineForm
        {...{
          onSubmit: onCreateTask,
          inputName: 'summary',
          inputId: 'task',
          mutationError: errorOnCreateTask,
          loading: loadingOnCreateTask,
          dontShowLoading: true,
          placeholder: '+ add new task',
          className: 'tasks-table-form'
        }}
      />
    </>
  )
}

TasksTable.defaultProps = {
  tasks: []
}

TasksTable.propTypes = {
  tasks: T.arrayOf(taskPropType().isRequired).isRequired,
  loadingOnCreateTask: T.bool.isRequired,
  errorOnCreateTask: T.string.isRequired,
  onCreateTask: T.func.isRequired,
  loadingOnCreateSubtask: T.bool.isRequired,
  errorsOnCreateSubtask: T.object.isRequired,
  onCreateSubtask: T.func.isRequired,
  onTaskClick: T.func.isRequired,
  isDraggable: T.bool,
  onSortDND: T.func
}

export { TasksTable }
