import * as yup from 'yup'
import { values } from 'ramda'

import constants from '@elenfs/elen-constants'

import {
  enumSchema,
  moneySchema,
  dateSchema
} from '../helpers'
import { endDateSchema } from '../helpers/endDateSchema'

const {
  Contributors,
  ContributionFrequencies,
  ContributionMethods,
  PolicyHolders,
  WithdrawalMethods,
  WithdrawalFrequencies
} = constants.policy.common

const contributionSchema = yup.object().shape({
  contributor: enumSchema(values(Contributors))
    .label('Contributor')
    .default(Contributors.CLIENT)
    .required(),

  method: enumSchema(values(ContributionMethods))
    .label('Method')
    .default(ContributionMethods.CONTRIBUTION)
    .required(),

  amount: moneySchema({
    amountLabel: 'Amount',
    currencyLabel: 'Amount currency',
    min: 1
  }).required(),

  frequency: enumSchema(values(ContributionFrequencies))
    .label('Frequency')
    .default(ContributionFrequencies.INITIAL)
    .required(),

  startDate: dateSchema()
    .label('Start date')
    .required(),

  endDate: endDateSchema()
    .label('End date')
})

const withdrawalSchema = yup.object().shape({
  paidTo: enumSchema(values(PolicyHolders))
    .label('Paid to')
    .default(PolicyHolders.CLIENT)
    .required(),

  method: enumSchema(values(WithdrawalMethods))
    .label('Method')
    .default(WithdrawalMethods.WITHDRAWAL)
    .required(),

  amount: moneySchema({
    amountLabel: 'Amount',
    currencyLabel: 'Amount currency',
    min: 1
  }).required(),

  frequency: enumSchema(values(WithdrawalFrequencies))
    .label('Frequency')
    .default(WithdrawalFrequencies.SINGLE)
    .required(),

  startDate: dateSchema()
    .label('Start date')
    .required(),

  endDate: endDateSchema()
    .label('End date')
})

export { contributionSchema, withdrawalSchema }
