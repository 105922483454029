import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Table.less'

const Table = ({ children, className, fixedLayout, ...rest }) => (
  <table
    className={classnames('table-new', className, {
      'table-new--fixed-layout': fixedLayout
    })}
    {...rest}
  >
    {children}
  </table>
)

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Table
