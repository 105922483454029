import { useMutation } from '@apollo/client'

import createFeeGql from '../../../graphql/mutations/charge/policyCharges/createFee.graphql'
import createCommissionGql from '../../../graphql/mutations/charge/policyCharges/createCommission.graphql'
import removeChargeGql from '../../../graphql/mutations/charge/removeCharge.graphql'
import clientChargesGql from '../../../graphql/queries/charge/clientCharges.graphql'

import { ChargeDetailsModal } from './ChargeDetailsModal'

const createFee = (clientId) => useMutation(
  createFeeGql,
  {
    update: (cache, { data }) => {
      try {
        const { createFee: fee } = data
        const { clientCharges, ...restCache } = cache.readQuery({
          query: clientChargesGql,
          variables: {
            clientId
          }
        })
        cache.writeQuery({
          query: clientChargesGql,
          variables: {
            clientId
          },
          data: {
            ...restCache,
            clientCharges: clientCharges.concat([fee])
          }
        })
      } catch (err) {
        // pass
      }
    }
  }
)

const createCommission = (clientId) => useMutation(
  createCommissionGql,
  {
    update: (cache, { data }) => {
      try {
        const { createCommission: commission } = data
        const { clientCharges, ...restCache } = cache.readQuery({
          query: clientChargesGql,
          variables: {
            clientId
          }
        })
        cache.writeQuery({
          query: clientChargesGql,
          variables: {
            clientId
          },
          data: {
            ...restCache,
            clientCharges: clientCharges.concat([commission])
          }
        })
      } catch (err) {
        // pass
      }
    }
  }
)

const useRemoveCharge = (id, clientId) => useMutation(
  removeChargeGql,
  {
    variables: {
      id
    },
    // TODO: remove individual payments associated with from cache
    update: (cache, { data: { removeCharge: { id: removedId } } }) => {
      try {
        const { clientCharges } = cache.readQuery({
          query: clientChargesGql,
          variables: { clientId }
        })

        cache.writeQuery({
          query: clientChargesGql,
          variables: { clientId },
          data: {
            clientCharges: clientCharges.filter(charge => charge.id !== removedId)
          }
        })
      } catch (e) {
        // Pass for now. Report to the logging tool later
      }
    }
  }
)

const ClientChargeDetailsModalContainer = (props) => (
  <ChargeDetailsModal
    createFee={createFee}
    createCommission={createCommission}
    useRemoveCharge={useRemoveCharge}
    isPolicy
    {...props}
  />
)

export { ClientChargeDetailsModalContainer }
