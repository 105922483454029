const Plus = props => (
  <svg width={16} height={16} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path
        d='M8 2c.552 0 1 .456 1 1.002V7L12.998 7c.514 0 .937.383.995.883L14 8c0 .552-.456 1-1.002 1L9 8.999v3.999a.999.999 0 01-.883.995L8 14c-.552 0-1-.456-1-1.002V8.999L3.002 9a.999.999 0 110-2L7 6.999V3.002c0-.514.383-.937.883-.995L8 2z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export default Plus
