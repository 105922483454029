import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import ImportError from './ImportError'
import ClientErrorHeading from './ClientErrorHeading'
import { dataImportParsingError } from '../../../../helpers/dataImport'

const { DataImportTypes } = constants.dataImport
const { TRANSACT, CSV_FILE } = DataImportTypes

const ParsingError = ({ clientFirstName, clientLastName, dataImportType, logEntries }) => {
  let heading = (
    <ClientErrorHeading
      {...{
        clientFirstName,
        clientLastName
      }}
    />
  )

  let description = ''

  if (dataImportType === TRANSACT) {
    heading = (
      <ClientErrorHeading
        {...{
          clientFirstName,
          clientLastName
        }}
      />
    )
    description = 'Transact Server Error'
  }

  if (dataImportType === CSV_FILE) {
    const { rowNumber, description: rowDescription } = dataImportParsingError(logEntries)

    heading = (
      <ClientErrorHeading
        {...{
          rowNumber,
          clientFirstName,
          clientLastName
        }}
      />
    )

    description = rowDescription
  }

  return (
    <ImportError
      {...{
        heading,
        description
      }}
    />
  )
}

ParsingError.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  dataImportType: T.oneOf(Object.values(DataImportTypes)).isRequired
}

export default ParsingError
