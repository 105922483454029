import PropTypes from 'prop-types'

import { Modal, Button, Heading } from '../../misc'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

import './ImportDisconnectModal.less'

const ImportDisconnectModal = ({
  isOpen,
  close,
  trigger,
  dataImportType,
  handleDisconnect
}) => {
  const platformName = DataImportPlatformLabels[dataImportType]

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      trigger={trigger}
      className='import-disconnect-modal'
    >
      <div className='import-disconnect-modal__content'>
        <Heading as='h3'>Disconnect from {platformName}</Heading>
        <p className='import-disconnect-modal__content__caution'>
          Are you sure you want to disconnect from {platformName}?
          <br />
          You will no longer receive updates to your database from this platform
        </p>
        <div className='import-disconnect-modal__content__buttons'>
          <Button
            color={Button.Colors.GREY}
            onClick={() => close()}
          >
            Go back
          </Button>
          <Button
            color={Button.Colors.BLUE}
            onClick={() => handleDisconnect()}
          >
            Disconnect
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ImportDisconnectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
  dataImportType: PropTypes.string.isRequired,
  handleDisconnect: PropTypes.func.isRequired
}

export default ImportDisconnectModal
