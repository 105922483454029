import { Button, Card } from '../../misc'
import { DownloadIcon } from '../../icons'
import { CSVImportForm } from './CSVImportForm'

import './CSVImportPage.less'

const CSVImport = ({ onDownloadTemplate, onSubmit, loading, error }) => (
  <Card.Group>
    <Card>
      <Card.Heading large className='csv-import-card__heading--large'> CSV File import</Card.Heading>
      <div className='csv-import-card__text'>
        The process requires a file to be uploaded. Here is an empty
        template which you can use to populate the data:
      </div>
      <Button
        className='csv-import-card__download-btn'
        color={Button.Colors.GREY}
        onClick={onDownloadTemplate}
      >
        <DownloadIcon /> Download Template
      </Button>
    </Card>
    <Card>
      <CSVImportForm {...{ onSubmit, loading, error }} />
    </Card>
  </Card.Group>
)

export { CSVImport }
