import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Heading.less'

const Heading = ({ as: Component, children, className, bold, italic, ...props }) => (
  <Component
    className={classnames('heading', {
      'heading--bold': bold,
      'heading--italic': italic,
      [className]: className
    })}
    {...props}
  >
    {children}
  </Component>
)

Heading.defaultProps = {
  as: 'h1',
  bold: true,
  italic: false
}

Heading.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  className: PropTypes.string
}

export default Heading
