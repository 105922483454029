import classnames from 'classnames'

import { PageContent, PageDivider } from '../misc'
import { ChargesTable } from './ChargesTable'
import { ChargeTotals } from './ChargeTotals'
import { ChargesEmpty } from './ChargesEmpty'
import { FEE, COMMISSION } from '../../constants/charge'

import './Charges.less'

const Charges = props => {
  const { fees, commissions, isPolicyCharges } = props
  const isEmpty = !(
    (fees && fees.length) ||
    (commissions && commissions.length)
  )

  return (
    <div
      className={classnames('charges', {
        'charges--full-width': !isPolicyCharges
      })}
    >
      <PageContent>
        {!isEmpty && (
          <>
            <ChargeTotals fees={fees} commissions={commissions} />

            <PageDivider />

            <ChargesTable
              charges={fees}
              chargeType={FEE}
              isPolicyCharges={isPolicyCharges}
            />

            <PageDivider />

            <ChargesTable
              charges={commissions}
              chargeType={COMMISSION}
              isPolicyCharges={isPolicyCharges}
            />
          </>
        )}
        <ChargesEmpty hidden={!isEmpty} isPolicyCharges={isPolicyCharges} />
      </PageContent>
    </div>
  )
}

export { Charges }
