import { useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import { SelectField } from '../../../form/fields'
import { ClientBirthdayReportPage } from './ClientBirthdayReportPage'
import { withFinancialAdvisers } from '../../../hoc/container'

import { getFromToDateByDateRange } from './../../../../helpers/filterDate'
import { filterDateRangeOptions, filterDateRanges, rowSchema } from '../helpers'
import { TableHeaders } from './../const'

import clientBirthdaysReportGql from '../../../../graphql/queries/reports/clientBirthdaysReport.graphql'

const ClientBirthdayReportPageContainer = props => {
  const { financialAdviserOptions } = props

  const [currentFilter, setCurrentFilter] = useState()

  const [getBirthdayReport, { data, loading, error, called }] = useLazyQuery(
    clientBirthdaysReportGql,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  const initialLoading = loading && !data

  const onFilter = useCallback(
    filter => {
      getBirthdayReport({ variables: { filter } })
      setCurrentFilter(filter)
    },
    [getBirthdayReport, setCurrentFilter]
  )

  const filterProps = {
    onSubmit: onFilter,
    getFromToDateByDateRange,
    filterDateRangeOptions,
    filterDateRanges,
    inputsArr: [
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'adviserId',
          options: financialAdviserOptions,
          inputProps: { placeholder: 'Current Adviser', isClearable: true }
        }
      }
    ],
    initialValues: {
      dateRange: filterDateRanges.FROM_TODAY_ONWARD
    }
  }

  const downloadBtnProps = {
    currentFilter,
    reportName: 'client-birthdays'
  }

  const records = data?.clientBirthdaysReport?.rows || []

  const tableProps = {
    rows: records,
    rowSchema,
    tableHeaderLabels: Object.values(TableHeaders),
    className: 'birthday-report-table'
  }

  return (
    <ClientBirthdayReportPage
      {...{
        filterProps,
        tableProps,
        records,
        loading: initialLoading,
        error,
        called,
        downloadBtnProps
      }}
    />
  )
}

const EnhancedClientBirthdayReportPageContainer = withFinancialAdvisers()(
  ClientBirthdayReportPageContainer
)

export { EnhancedClientBirthdayReportPageContainer as ClientBirthdayReportPageContainer }
