const ListFull = props => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <defs>
      <clipPath id='list-a'>
        <path d='M1440 0v1962H0V0h1440Z' />
      </clipPath>
      <clipPath id='list-b'>
        <path d='M14.4 11.703a1.6 1.6 0 0 1 1.595 1.469l.005.13V14.4a1.6 1.6 0 0 1-1.469 1.595L14.4 16H1.6a1.6 1.6 0 0 1-1.595-1.469L0 14.4v-1.097a1.6 1.6 0 0 1 1.469-1.595l.131-.005h12.8Zm0 1.371H1.6a.229.229 0 0 0-.223.176l-.006.053V14.4c0 .108.076.199.177.223l.052.006h12.8a.229.229 0 0 0 .223-.177l.006-.052v-1.097a.229.229 0 0 0-.177-.223l-.052-.006Zm0-7.223a1.6 1.6 0 0 1 1.595 1.47l.005.13V8.55a1.6 1.6 0 0 1-1.469 1.594l-.131.006H1.6a1.6 1.6 0 0 1-1.595-1.47L0 8.55V7.45a1.6 1.6 0 0 1 1.469-1.594L1.6 5.85h12.8Zm0 1.372H1.6a.229.229 0 0 0-.223.176l-.006.052V8.55c0 .108.076.198.177.222l.052.006h12.8a.229.229 0 0 0 .223-.176l.006-.052V7.45a.229.229 0 0 0-.177-.222l-.052-.006ZM14.4 0a1.6 1.6 0 0 1 1.595 1.469L16 1.6v1.097a1.6 1.6 0 0 1-1.469 1.595l-.131.005H1.6A1.6 1.6 0 0 1 .005 2.828L0 2.698V1.6A1.6 1.6 0 0 1 1.469.005L1.6 0h12.8Zm0 1.371H1.6a.229.229 0 0 0-.223.177l-.006.052v1.097c0 .108.076.199.177.223l.052.006h12.8a.229.229 0 0 0 .223-.176l.006-.053V1.6a.229.229 0 0 0-.177-.223l-.052-.006Z' />
      </clipPath>
    </defs>
    <g clipPath='url(#list-a)' transform='translate(-307 -215)'>
      <g clipPath='url(#list-b)' transform='translate(307 215)'>
        <path fill='currentColor' d='M0 0h16v16H0V0z' />
      </g>
    </g>
  </svg>
)

export { ListFull }
