import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import PoliciesTableRow from './PoliciesTableRow'
import NoPolicies from './NoPolicies'
import { withNoItems } from '../../hoc/pure'
import { CreatePolicyButton } from '../CreatePolicyButton'
import {
  Table,
  Heading,
  TableResponsiveWrapper,
  TableResponsiveActionHeader
} from '../../misc'
import { PolicyTableHeaders } from '../../../helpers/enumLabels/policy'
import { sortListByProp, sortByTwoPropsAsc } from '../../../helpers/sort'

import './PoliciesTable.less'

const PoliciesTable = ({
  clientId,
  activePolicies,
  expiredPolicies,
  readOnly
}) => {
  return (
    <div className='policies-table-wrapper'>
      {activePolicies &&
        <div className='policies-table'>
          <TableResponsiveActionHeader>
            <Heading as='h3'>Active policies</Heading>
            {!readOnly && <CreatePolicyButton clientId={clientId} />}
          </TableResponsiveActionHeader>

          <div className='policies-table__table-content'>
            <TableResponsiveWrapper>
              <Table>
                <Table.Header>
                  <Table.Row>
                    {Object.values(PolicyTableHeaders).map((item) => (
                      <Table.HeaderCell key={item}>
                        {item}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {sortByTwoPropsAsc(activePolicies, 'category', 'number').map(
                    (policy) => (
                      <PoliciesTableRow
                        key={policy.id}
                        policy={policy}
                        url={`${policy.id}`}
                      />
                    ))}
                </Table.Body>
              </Table>
            </TableResponsiveWrapper>
          </div>
        </div>}

      {!isEmpty(expiredPolicies) &&
        <div className='policies-table policies-table--expired'>
          <div className='policies-table__table-info'>
            <Heading as='h3'>Expired policies</Heading>
          </div>

          <div className='policies-table__table-content'>
            <TableResponsiveWrapper className='table-responsive--disabled'>
              <Table>
                <Table.Header>
                  <Table.Row>
                    {Object.values(PolicyTableHeaders).map((item) => (
                      <Table.HeaderCell key={item}>
                        {item}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {sortListByProp(expiredPolicies, 'endDate').map(policy => (
                    <PoliciesTableRow
                      key={policy.id}
                      policy={policy}
                      url={policy.id}
                    />
                  ))}
                </Table.Body>
              </Table>
            </TableResponsiveWrapper>
          </div>
        </div>}
    </div>
  )
}

PoliciesTable.propTypes = {
  policies: PropTypes.arrayOf(PropTypes.object).isRequired
}

const PoliciesTableEnhanced = withNoItems('policies', NoPolicies)(
  PoliciesTable
)

export default PoliciesTableEnhanced
