import { ActivityLogEntryTypes } from '@elenfs/elen-constants'
import T from 'prop-types'
import classNames from 'classnames'

import {
  FileIcon,
  EnvelopeIcon,
  UsersIcon,
  PostBoxIcon,
  MobilePhoneIcon,
  AccountEditIcon
} from '../../icons'

import './TaskIcon.less'

const {
  EMAIL,
  MEETING,
  PHONE_CALL,
  POST,
  WORK_ON_FILE,
  AUDIT_TRAIL
} = ActivityLogEntryTypes

const TaskIconType = ({ type }) => {
  switch (type) {
    case EMAIL:
      return <EnvelopeIcon />
    case MEETING:
      return <UsersIcon />
    case PHONE_CALL:
      return <MobilePhoneIcon />
    case POST:
      return <PostBoxIcon />
    case WORK_ON_FILE:
      return <FileIcon />
    case AUDIT_TRAIL:
      return <AccountEditIcon />
    default:
      return <FileIcon />
  }
}

const TaskIcon = ({ type, isKanban }) => (
  <div className={classNames('task-icon__wrapper', {
    'task-icon__wrapper__kanban': isKanban
  })}
  >
    <TaskIconType type={type} />
  </div>
)

TaskIcon.defaultProps = {
  type: WORK_ON_FILE,
  isKanban: false
}

TaskIcon.propTypes = {
  type: T.oneOf(Object.values(ActivityLogEntryTypes))
}

export { TaskIcon }
