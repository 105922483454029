import { useCallback } from 'react'
import classnames from 'classnames'
import T from 'prop-types'

import { Table } from '../../../misc'
import { InlineForm } from '../../../misc/InlineForm/InlineForm'
import { TasksTableRow } from '../TasksTableRow'

import { taskPropType } from './../propTypes'

import './SubTasksTable.less'

const SubTasksTable = props => {
  const {
    subTasks,
    isNotNestedTable,
    onCreateSubtask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    taskId,
    onTaskClick,
    isModal,
    isClientTasks
  } = props

  const onFormSubmit = useCallback(
    ({ summary }) => onCreateSubtask({ summary, taskId }),
    [taskId, onCreateSubtask]
  )

  return (
    <>
      <Table
        className={classnames('subtasks-table', {
          'subtasks-table--not-nested': isNotNestedTable
        })}
      >
        <Table.Header>
          <Table.Row>
            {/* eslint-disable */}
            <Table.HeaderCell children='' />
            <Table.HeaderCell children='' />
            <Table.HeaderCell children='' />
            <Table.HeaderCell children='' />
            {(isClientTasks  || isNotNestedTable) && <Table.HeaderCell children='' />}
            {/* eslint-enable */}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {subTasks.map((subTask, i) => (
            <TasksTableRow
              key={i}
              {...{ task: subTask, key: i, onTaskClick, index: i }}
            />
          ))}
        </Table.Body>
      </Table>

      <InlineForm
        {...{
          onSubmit: onFormSubmit,
          inputName: 'summary',
          inputId: taskId + (isModal && '-modal-subtasks'),
          mutationError: errorsOnCreateSubtask || '',
          dontShowLoading: true,
          loading: loadingOnCreateSubtask,
          placeholder: '+ add new subtask',
          className: classnames('subtasks-table-form', {
            'subtasks-table-form--not-nested': isNotNestedTable
          })
        }}
      />
    </>
  )
}

SubTasksTable.defaultProps = {
  isNotNestedTable: true,
  subTasks: []
}

SubTasksTable.propTypes = {
  subTasks: T.arrayOf(taskPropType().isRequired).isRequired,
  onCreateSubtask: T.func.isRequired,
  loadingOnCreateSubtask: T.bool.isRequired,
  errorsOnCreateSubtask: T.string,
  taskId: T.string,
  onTaskClick: T.func.isRequired,
  isNotNestedTable: T.bool
}

export { SubTasksTable }
