import { sortBy, map, values, compose, prop, is } from 'ramda'

import { isPlainObject } from './misc'

const emptyValuesToEmptyStrings = (obj) => {
  const isArray = Array.isArray(obj)
  const initialAccValue = isArray ? [] : {}

  return Object.keys(obj).reduce((acc, key) => {
    let value = obj[key]
    if ((value === undefined) || (value === null)) value = ''

    if (isPlainObject(value) || Array.isArray(value)) {
      value = emptyValuesToEmptyStrings(value)
    }

    return isArray
      ? [...acc, value]
      : { ...acc, [key]: value }
  }, initialAccValue)
}

export const buildInitialValues = (schema, existingDoc, castOptions = {}) =>
  emptyValuesToEmptyStrings(schema.cast(existingDoc, castOptions))

export const buildSelectBoxOptions = (options, labels) => compose(
  sortBy(prop('text')),
  map(value => ({ text: labels[value], value })),
  values
)(options)

export const extractValidationErrorMessages = (errorsObj) => {
  return Object.keys(errorsObj).reduce((acc, field) => {
    const fieldVal = errorsObj[field]
    if (is(String, fieldVal)) {
      return [...acc, fieldVal]
    } else if (is(Object, fieldVal)) {
      return [...acc, ...extractValidationErrorMessages(fieldVal)]
    }
    return acc
  }, [])
}

export const buildBooleanSelectBoxOptions = () => [{
  label: 'Yes',
  value: true
}, {
  label: 'No',
  value: false
}]
