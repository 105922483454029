import T from 'prop-types'
import classnames from 'classnames'

import Spinner from '../Spinner'

import './LoadingMessage.less'

const LoadingMessage = ({ text, className }) => (
  <div className={classnames('loading-message', className)}>
    <Spinner config={{
      lines: 7,
      length: 3,
      width: 3,
      radius: 4,
      position: 'absolute',
      top: '10px',
      left: '10px'
    }}
    />
    <span className='loading-message-text'>{text}</span>
  </div>
)

LoadingMessage.propTypes = {
  text: T.string
}

LoadingMessage.defaultProps = {
  text: 'Saving...'
}

export default LoadingMessage
