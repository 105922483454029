import { useCallback } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { InputField } from '../../fields'

import './FileInput.less'

const FileInput = ({ name, label, className, onChange, value, accept }) => {
  const handleChange = useCallback(e => {
    onChange(e.target.files[0])
    // reset value of the real input
    e.target.value = null
  }, [onChange, value])

  return (
    <div className={classnames(className, 'file-input')}>
      <label htmlFor='file-input' className='file-input__label'>
        <span className='file-input__caption'> Browse file </span>
        <InputField
          name={name}
          label={label}
          value={value}
          inputProps={{
            className: 'file-input__visible-input',
            placeholder: 'No file chosen'
          }}
        />
      </label>
      <input type='file' id='file-input' {... { accept, onChange: handleChange }} />
    </div>
  )
}

FileInput.propTypes = {
  name: T.string.isRequired,
  onChange: T.func.isRequired,
  value: T.string.isRequired,
  label: T.string,
  className: T.string
}

export { FileInput }
