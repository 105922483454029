import { TaskTypes, TaskStatuses } from '@elenfs/elen-constants'

const { EMAIL, MEETING, PHONE_CALL, POST, WORK_ON_FILE } = TaskTypes

export const TaskStatusLabels = Object.freeze({
  [TaskStatuses.TO_DO]: 'To do',
  [TaskStatuses.IN_PROGRESS]: 'In progress',
  [TaskStatuses.COMPLETED]: 'Completed'
})

export const TaskTypeLabels = Object.freeze({
  [EMAIL]: 'Email',
  [MEETING]: 'Meeting',
  [PHONE_CALL]: 'Phone call',
  [POST]: 'Post',
  [WORK_ON_FILE]: 'Work on file'
})
