import { Component } from 'react'

const withMutationState = (mutationProp = 'mutate') => WrappedComponent =>
  class WithMutationState extends Component {
    state = {
      loading: false,
      error: null,
      data: null
    }

    _mutate = (...args) => {
      this.setState({
        loading: true,
        error: null,
        data: null
      })

      return this.props[mutationProp](...args)
        .then((res) => {
          this.setState({
            loading: false,
            data: res
          })

          return res
        })
        .catch((err) => {
          this.setState({
            loading: false,
            error: err
          })

          return err
        })
    }

    render () {
      const { loading, error, data } = this.state

      return (
        <WrappedComponent
          {...this.props}
          {...{
            loading,
            error,
            data,
            [mutationProp]: this._mutate
          }}
        />
      )
    }
  }

export default withMutationState
