import { useCallback } from 'react'
import T from 'prop-types'
import { useMutation } from '@apollo/client'

import { RemoveTaskCommentButton } from './RemoveTaskCommentButton'
import { parseGraphqlErrors } from '../../../helpers/error'

import taskCommentsGql from '../../../graphql/queries/tasks/taskComments.graphql'
import removeTaskCommentGql from '../../../graphql/mutations/tasks/removeTaskComment.graphql'

const RemoveTaskCommentButtonContainer = ({
  commentId,
  taskId,
  setErrors,
  ...restProps
}) => {
  const [removeMutation] = useMutation(removeTaskCommentGql, {
    variables: { taskId, id: commentId },
    onError: (err) => {
      if (setErrors) {
        setErrors(parseGraphqlErrors(
          err,
          'There was an error deleting the comment, please try again later'))
      } else {
        throw new Error(err)
      }
    },
    update: (cache, operationResult) => {
      const { id: removedInstanceId } = operationResult.data.removeTaskComment
      const { taskComments } = cache.readQuery({
        query: taskCommentsGql,
        variables: { taskId }
      })
      const filterFn = ({ id }) => id !== removedInstanceId

      cache.writeQuery({
        query: taskCommentsGql,
        variables: { taskId },
        data: { taskComments: taskComments.filter(filterFn) }
      })
    }
  }
  )

  const removeCommentCallback = useCallback(
    async (removeConfirmationFlag) => {
      if (removeConfirmationFlag) {
        await removeMutation()
      }
    }, [removeMutation]
  )

  return (
    <RemoveTaskCommentButton {...{
      removeCommentCallback,
      ...restProps
    }}
    />
  )
}

RemoveTaskCommentButtonContainer.propTypes = {
  commentId: T.string.isRequired,
  taskId: T.string.isRequired,
  setErrors: T.func
}

export { RemoveTaskCommentButtonContainer }
