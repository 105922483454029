import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import booleanSchema from '../helpers/booleanSchema'
import dateSchema from '../helpers/dateSchema'
import enumSchema from '../helpers/enumSchema'
import moneySchema from '../helpers/moneySchema'
import numberSchema from '../helpers/numberSchema'
import commonFields from './commonFields'

const {
  DeathBenefits,
  Guarantees,
  PensionTypes,
  PCLSEntitlements,
  PlanStatuses,
  Protections
} = constants.policy.pensionPolicy

export default yup.object().shape({
  ...commonFields(),

  pensionType: enumSchema(values(PensionTypes))
    .label('Pension type')
    .required(),

  planStatus: enumSchema(values(PlanStatuses))
    .label('Plan status'),

  waiver: booleanSchema()
    .label('Waiver'),

  startDate: dateSchema()
    .label('Start date'),

  maturityDate: dateSchema()
    .label('Maturity date'),

  selectedRetirementAge: numberSchema()
    .label('Selected retirement age')
    .min(10, 'Minimum age is 10')
    .max(99, 'Maximum age is 99'),

  deathBenefit: enumSchema(values(DeathBenefits))
    .label('Death benefit'),

  additionalLifeCover: moneySchema({
    amountLabel: 'Additional life cover',
    currencyLabel: 'Additional life cover currency'
  }),

  pclsEntitlement: enumSchema(values(PCLSEntitlements))
    .label('PCLS Entitlement'),

  protection: enumSchema(values(Protections))
    .label('Protection'),

  guarantees: enumSchema(values(Guarantees))
    .label('Guarantees'),

  earmarking: booleanSchema()
    .label('Earmarking / Pension sharing order'),

  transferValue: moneySchema({
    amountLabel: 'Transfer value',
    currencyLabel: 'Transfer value currency'
  }),

  transferDate: dateSchema()
    .label('Transfer date')
}).noUnknown(true)
