/* global window */
import constants from '@elenfs/elen-constants'

import AppConfig from './appConfig'
import { client } from './graphql/client'
import { getErrorMessageByCode } from './helpers/error'

const { UserTypes } = constants.user
const AUTH_TOKEN_KEY = '__token'

const saveAuthToken = token => window.localStorage.setItem(AUTH_TOKEN_KEY, token)

export const getAuthToken = () => window.localStorage.getItem(AUTH_TOKEN_KEY)

const generateRequestOptions = (body) => ({
  body: JSON.stringify(body),
  cache: 'no-cache',
  headers: { 'Content-Type': 'application/json' },
  method: 'POST'
})

export const login = async (email, password, loginType = UserTypes.COMPANY_USER) => {
  if (!email) {
    throw new Error('E-mail address is required')
  }

  if (!password) {
    throw new Error('Password is required')
  }

  let loginUrl
  if (loginType === UserTypes.COMPANY_USER) loginUrl = AppConfig.LOGIN_URL
  else if (loginType === UserTypes.CLIENT) loginUrl = AppConfig.CLIENT_LOGIN_URL
  else throw new Error(`Invalid loginType: ${loginType}`)

  const response = await window.fetch(loginUrl, generateRequestOptions({ email, password }))
  const json = await response.json()

  if (!(response.ok && json.token)) {
    const { error = {} } = json
    throw new Error(
      getErrorMessageByCode(error.code, 'Login failed')
    )
  }

  saveAuthToken(json.token)
}

export const forgotPassword = async (email, type) => {
  if (!email) {
    return Promise.reject(new Error('E-mail address is required'))
  }

  const res = await window.fetch(AppConfig.FORGOT_PASSWORD_URL, generateRequestOptions({ email, type }))
  const isRequestSuccessful = res.ok
  const json = await res.json()
  if (!isRequestSuccessful || !json.success) {
    const { error = {} } = json
    const errMsg = getErrorMessageByCode(error.code) || 'Something went wrong, please try again'
    throw new Error(errMsg)
  }
}

export const resetPassword = async (token, password, confirmPassword, userType) => {
  if (!token) return Promise.reject(new Error('Invalid token'))

  if (!password || !confirmPassword) {
    return Promise.reject(new Error('Fill form propery to continue'))
  }

  if (password !== confirmPassword) {
    return Promise.reject(new Error('Password does not match'))
  }

  const res = await window.fetch(
    AppConfig.RESET_PASSWORD_URL,
    generateRequestOptions({
      token, password, userType
    })
  )
  const isRequestSuccessful = res.ok
  const json = await res.json()
  if (!isRequestSuccessful || !json.success) {
    const { error = {} } = json
    const errMsg = getErrorMessageByCode(error.code) || 'Password reset failed'
    throw new Error(errMsg)
  }
}

export const logout = () => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY)
  client.resetStore()
  const pathname = window.location.pathname
  return pathname.startsWith('/clients')
    ? window.location.replace('/clients/login')
    : window.location.replace('/')
}
