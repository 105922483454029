import T from 'prop-types'

import { EmptyPage } from '../misc'
import { NewChargeButton } from './NewChargeButton'
import { FEE, COMMISSION } from '../../constants/charge'

const ChargesEmpty = ({ hidden, isPolicyCharges }) => (
  <EmptyPage
    className='charges--empty'
    text='No fees or commissions currently added, add your first one'
    renderButton={() => (
      <>
        <NewChargeButton chargeType={FEE} isPolicyCharges={isPolicyCharges} />
        <NewChargeButton chargeType={COMMISSION} isPolicyCharges={isPolicyCharges} />
      </>
    )}
    hidden={hidden}
  />
)

ChargesEmpty.propTypes = {
  hidden: T.bool.isRequired
}

export { ChargesEmpty }
