import { useEffect } from 'react'
import PropTypes from 'prop-types'
import constants from '@elenfs/elen-constants'
import { withFormik } from 'formik'
import { toast } from 'react-toastify'

import { Form } from '../../form/Form'
import { Input } from '../../form/inputs'
import { Modal, Button, Toastr } from '../../misc'
import ImportDisconnectModal from '../ImportDisconnectModal'
import TransactSyncButton from '../TransactSyncButton'
import validationSchema from '../../../schemas/dataImport/transactImportSchema'
import { buildInitialValues } from '../../../helpers/form'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

import './TransactImportForm.less'

const { DataImportTypes } = constants.dataImport

const TransactImportForm = ({
  accessCode,
  pin,
  isMutationLoading,
  mutationRes,
  values,
  errors,
  handleChange,
  handleSubmit,
  submitForm,
  resetForm
}) => {
  const isConnected = accessCode && pin
  const platform = DataImportPlatformLabels[DataImportTypes.TRANSACT]

  useEffect(() => {
    if (!mutationRes) return

    if (isConnected) {
      toast(<Toastr
        type='success'
        title={`Syncing with ${platform} in progress`}
        content='This page will be updated once done'
            />)
    } else {
      resetForm()

      toast(<Toastr
        type='success'
        title={`Successfully disconnected from ${platform}`}
            />)
    }
  }, [isConnected, mutationRes])

  return (
    <Form
      className='transact-import-form'
      onSubmit={handleSubmit}
    >
      <Form.Field
        className='transact-import-page__form__field'
        error={errors.accessCode}
      >
        <label>Access code</label>
        <Input
          type='text'
          name='accessCode'
          value={values.accessCode}
          disabled={isConnected}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field
        className='transact-import-page__form__field'
        error={errors.pin}
      >
        <label>Pin</label>
        <Input
          type='password'
          name='pin'
          value={values.pin}
          disabled={isConnected}
          onChange={handleChange}
        />
      </Form.Field>
      <div className='transact-import-form__buttons'>
        {isConnected
          ? (
            <>
              <Modal.Trigger
                as={Button}
                color={Button.Colors.GREY}
                modal={ImportDisconnectModal}
                modalProps={{
                  dataImportType: DataImportTypes.TRANSACT,
                  handleDisconnect: submitForm
                }}
              >
                Disconnect from Transact
              </Modal.Trigger>
              <TransactSyncButton />
            </>
            )
          : (
            <Button
              type='submit'
              color={Button.Colors.BLUE}
              disabled={isMutationLoading}
            >
              Connect to Transact
            </Button>
            )}
      </div>
    </Form>
  )
}

const TransactImportFormEnhanced = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema,

  mapPropsToValues: ({ accessCode, pin }) =>
    buildInitialValues(validationSchema, { accessCode, pin }),

  handleSubmit: (values, { props: { mutate } }) => {
    validationSchema.cast(values)
    return mutate && mutate({ variables: { input: values } })
  }
})(TransactImportForm)

TransactImportFormEnhanced.propTypes = {
  accessCode: PropTypes.string,
  pin: PropTypes.string,
  isMutationLoading: PropTypes.bool,
  mutationErr: PropTypes.object,
  mutationRes: PropTypes.object
}

export default TransactImportFormEnhanced
