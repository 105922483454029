import { Route, Routes, Navigate } from 'react-router-dom'

import { PendingIncome } from '../PendingIncome'
import { ReceivedIncome } from '../ReceivedIncome'
import { IncomeNav } from '../IncomeNav'

import './IncomeSection.less'

const IncomeSection = () => (
  <div className='income-section'>
    <IncomeNav />
    <div className='income-section__section-content'>
      <div>
        <Routes>
          <Route path='pending' element={<PendingIncome />} />
          <Route path='received' element={<ReceivedIncome />} />
          <Route path='/' element={<Navigate replace to='pending' />} />
        </Routes>
      </div>
    </div>
  </div>
)

export { IncomeSection }
