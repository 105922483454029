import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { AuditLogEntriesPage } from './AuditLogEntriesPage'
import { Spinner, ErrorMessage } from '../../misc'

import activityLogListGql from '../../../graphql/queries/client/activityLogList.graphql'

const AuditLogEntriesPageError = () => (
  <ErrorMessage text='Failed to retrieve client activity log' />
)

const AuditLogEntriesPageContainer = () => {
  const { clientId } = useParams()
  const { error, loading, data, refetch } =
  useQuery(activityLogListGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) return <Spinner />
  if (error) return <AuditLogEntriesPageError />

  const { activityLogEntries } = data

  return (
    <AuditLogEntriesPage
      auditLogEntries={activityLogEntries}
      clientId={clientId}
      refetch={refetch}
    />
  )
}

export { AuditLogEntriesPageContainer }
