export const COLORS = [
  'red',
  'salmon-pink',
  'brown',
  'orange',
  'yellow',
  'green',
  'turquoise',
  'light-blue',
  'blue',
  'dark-blue',
  'purple',
  'dark-grey',
  'grey'
]

export const SIZES = Object.freeze({
  LG: 'large',
  XLG: 'extra-large'
})
