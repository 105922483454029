const CakeIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M20.413 19.826h-.783v-4.109c0-.97-.79-1.76-1.76-1.76h-.587v-4.11c0-.97-.79-1.76-1.761-1.76h-2.935v-.493a1.762 1.762 0 00.939-2.538l-1.018-1.763a.587.587 0 00-1.016 0l-1.018 1.763a1.762 1.762 0 00.939 2.538v.493H8.478c-.97 0-1.76.79-1.76 1.76v4.11H6.13c-.97 0-1.76.79-1.76 1.76v4.109h-.783a.587.587 0 000 1.174h16.826a.587.587 0 000-1.174zM11.491 5.643L12 4.761l.51.882a.587.587 0 11-1.02 0zm-3.6 4.205c0-.324.264-.587.587-.587h7.044c.323 0 .587.263.587.587v1.69a7.228 7.228 0 01-.18-.19c-.341-.37-.807-.877-1.607-.877-.8 0-1.265.507-1.606.877-.325.354-.483.498-.742.498-.267 0-.438-.171-.744-.5-.343-.37-.813-.875-1.604-.875-.799 0-1.265.507-1.605.877l-.13.139v-1.64zm0 3.06c.446-.17.752-.503.994-.766.325-.353.483-.497.741-.497.267 0 .439.171.744.5.343.37.813.875 1.604.875.8 0 1.266-.507 1.606-.878.326-.353.484-.497.742-.497.259 0 .417.144.742.498.252.273.572.621 1.045.785v1.028H7.89V12.91zm-2.348 6.918v-1.943l.001.001c.409.448.917 1.006 1.734 1.006.8 0 1.266-.508 1.607-.878.325-.354.483-.498.741-.498.267 0 .439.172.744.5.343.37.813.876 1.604.876.8 0 1.266-.508 1.606-.878.326-.354.484-.498.742-.498.259 0 .417.144.742.498.34.37.806.878 1.606.878s1.266-.508 1.606-.878c.067-.072.126-.135.18-.19v2.004H5.544zm12.913-3.393c-.472.163-.793.511-1.044.785-.325.354-.484.498-.742.498s-.417-.144-.742-.498c-.34-.37-.806-.878-1.606-.878s-1.266.507-1.606.878c-.325.354-.483.498-.742.498-.267 0-.438-.172-.744-.5-.343-.37-.813-.876-1.604-.876-.8 0-1.265.508-1.605.878-.326.354-.484.498-.743.498-.279 0-.513-.235-.866-.622-.247-.272-.519-.569-.869-.764v-.613c0-.323.264-.587.587-.587h11.74c.323 0 .586.264.586.587v.716z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { CakeIcon }
