"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _common = _interopRequireDefault(require("./common"));
var _debtPolicy = _interopRequireDefault(require("./debtPolicy"));
var _investmentPolicy = _interopRequireDefault(require("./investmentPolicy"));
var _mortgagePolicy = _interopRequireDefault(require("./mortgagePolicy"));
var _pensionPolicy = _interopRequireDefault(require("./pensionPolicy"));
var _protectionPolicy = _interopRequireDefault(require("./protectionPolicy"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  common: _common.default,
  debtPolicy: _debtPolicy.default,
  investmentPolicy: _investmentPolicy.default,
  mortgagePolicy: _mortgagePolicy.default,
  pensionPolicy: _pensionPolicy.default,
  protectionPolicy: _protectionPolicy.default
};