import classnames from 'classnames'

const TaskCommentDetails = ({ isEditing, text, className, ...restProps }) => (
  <div
    className={classnames(className, {
      [`${className}--hidden`]: isEditing
    })}
    {...restProps}
  >
    {text}
  </div>
)

export { TaskCommentDetails }
