import { BackButton } from '../../misc'

const ImportPlatformHeader = ({ pageTitle }) => {
  return (
    <BackButton to='/secure/admin/sections/import'>
      <div className='back-button__page_title'>
        {pageTitle}
      </div>
    </BackButton>
  )
}

export default ImportPlatformHeader
