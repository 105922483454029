import { useMemo, useCallback } from 'react'
import { FieldArray, connect } from 'formik'
import T from 'prop-types'
import { useNavigate } from 'react-router-dom'
import constants from '@elenfs/elen-constants'

import Card from '../../misc/Card'
import FormFieldsCard from '../../form/FormFieldsCard'
import ClientFinancialAdvisersFormInputRow from './ClientRelationshipsFormInputRow'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import { FieldArrayItem } from '../../form'

import { ClientRelationshipLabels } from './../../../helpers/enumLabels/client'
import { buildSelectOptions } from '../../form/fields/SelectField'
import { validateAndNavigate } from '../../../helpers/client'
import clientRelationshipsSchema from '../../../schemas/client/clientRelationshipsSchema'

import './ClientRelationshipsForm.less'

const { ClientRelationshipTypes } = constants.client

const buildRelationshipOptions = relationships => {
  if (!relationships) {
    return {}
  }

  return relationships.map(relation => {
    return {
      label: relation?.relatedClient?.fullName,
      value: relation?.relatedClientId
    }
  })
}

const relationshipOptions = buildSelectOptions(
  ClientRelationshipTypes,
  ClientRelationshipLabels
)

const ClientPortalRelationshipForm = ({
  formik,
  nextLink,
  prevLink,
  setValidationSchema,
  client
}) => {
  const navigate = useNavigate()
  const {
    values: { relationships = [] },
    validateForm
  } = formik

  const companyRelationOptions = useMemo(() =>
    buildRelationshipOptions(client?.relationships),
  [client?.relationships])

  const handleNavButtonClick = useCallback((isNext) => {
    validateAndNavigate({
      setSchema: setValidationSchema,
      schema: clientRelationshipsSchema,
      validate: validateForm,
      nextLocation: isNext ? nextLink : prevLink,
      navigate
    })
  }, [
    navigate,
    nextLink,
    prevLink,
    validateForm,
    clientRelationshipsSchema,
    setValidationSchema
  ])

  return (
    <Card className='relationships-form'>
      <FormFieldsCard title='Relationships'>
        <FieldArray
          validateOnChange={false}
          name='relationships'
          render={arrayHelpers => (
            <div className='relationships-input-rows-container'>
              {
                relationships.map((relationship, index) => (
                  <FieldArrayItem
                    key={index}
                    index={index}
                    label='Relationship'
                    onRemove={() => arrayHelpers.remove(index)}
                    disabled
                  >
                    <ClientFinancialAdvisersFormInputRow
                      index={index}
                      arrayHelpers={arrayHelpers}
                      companyClientOptions={companyRelationOptions}
                      relationshipOptions={relationshipOptions}
                      disabled
                      relationships={relationships}
                    />
                  </FieldArrayItem>
                ))
              }
            </div>
          )}
        />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}

const EnhancedClientPortalRelationshipForm = connect(ClientPortalRelationshipForm)

EnhancedClientPortalRelationshipForm.propTypes = {
  client: T.isRequired,
  nextLink: T.string,
  prevLink: T.string
}

export { EnhancedClientPortalRelationshipForm as ClientPortalRelationshipForm }
