import PropTypes from 'prop-types'

import { useModal } from './useModal'

const ModalTrigger = ({
  as: Component,
  modal: ModalComponent,
  modalProps,
  children,
  ...rest
}) => {
  const { isOpen, open, close } = useModal()

  const trigger = (
    <Component onClick={open} {...rest}>
      {children}
    </Component>
  )

  return (
    <ModalComponent
      isOpen={isOpen}
      close={close}
      trigger={trigger}
      {...modalProps}
    />
  )
}

ModalTrigger.defaultProps = {
  modalProps: {}
}

ModalTrigger.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  modal: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  modalProps: PropTypes.object,
  children: PropTypes.node
}

export { ModalTrigger }
