import { Badge, Tooltip, ColorsAvatar } from '../'
import { ColourTypesCodes } from '../../../constants/serviceLevels'

const ServiceLevelBadge = ({ serviceLevel }) => {
  if (!serviceLevel) {
    return null
  }

  return (
    <Tooltip.Trigger
      className='service-level-badge'
      as={Badge}
      tooltip={Tooltip.Info}
      tooltipId='service-level-badge--tooltip'
      tooltipProps={{
        text: `${serviceLevel?.name} service level`,
        position: 'bottom'
      }}
      action
    >
      <ColorsAvatar
        color={ColourTypesCodes[serviceLevel?.colour]?.toLowerCase()}
        size={ColorsAvatar.Sizes.XL}
      />
    </Tooltip.Trigger>
  )
}

export { ServiceLevelBadge }
