const ChartIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <title>Icon/24/Chart_24</title>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M10.645 21c-.374 0-.677-.269-.677-.6 0-.331.303-.6.677-.6l1.064-.001v-2.4l-6.024.001c-1.162 0-2.104-.957-2.104-2.137L3.58 7.19A.698.698 0 013 6.5V5.1C3 3.94 3.93 3 5.077 3h13.846C20.07 3 21 3.94 21 5.1v1.4a.698.698 0 01-.58.69v8.072c0 1.181-.943 2.138-2.105 2.138l-5.445-.001v2.4l.485.001c.374 0 .677.269.677.6 0 .331-.303.6-.677.6h-2.71zm8.371-13.575H4.984v7.837c0 .394.314.713.701.713h12.63a.707.707 0 00.701-.713V7.425zM17.314 8.62a.8.8 0 01.149.82.773.773 0 01.053.28v2.16c0 .398-.312.72-.697.72a.709.709 0 01-.696-.72l-.001-.904-2.255 2.454a.648.648 0 01-.843.113l-2.986-1.949-2.377 2.586a.65.65 0 01-.975 0 .797.797 0 010-1.06l2.758-3a.648.648 0 01.842-.113l2.986 1.949 1.397-1.521-.035-.002a.713.713 0 01-.602-.713c0-.398.312-.72.697-.72l1.261-.001.35-.38a.65.65 0 01.974 0zm1.61-4.22H5.076a.696.696 0 00-.692.7v.7h15.23v-.7c0-.387-.31-.7-.692-.7z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { ChartIcon }
