import { UNASSIGNED_TYPE } from './../misc/DropdownSelection/DropdownCustom/ControlWithInitials'

const buildUserOption = user => ({ user, label: user?.fullName, value: user?.id })

const unassignedUserOption = {
  label: 'Unassigned',
  value: '',
  user: {
    id: 'unassigned',
    firstName: 'unassigned',
    lastName: 'unassigned',
    fullName: 'unassigned unassigned',
    type: UNASSIGNED_TYPE
  }
}

export const buildAssigneeOptions = ({ currentUser, users }) => {
  const group2Users = users.filter(({ id }) => id !== currentUser.id)

  const group1 = { label: '', options: [unassignedUserOption, buildUserOption(currentUser)] }
  const group2 = { label: '', options: group2Users.map(buildUserOption) }

  return [group1, group2]
}
