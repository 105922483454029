import { Fragment } from 'react'
import constants from '@elenfs/elen-constants'

import {
  SelectField,
  DateField,
  MoneyField,
  RadioGroupField
} from '../../../form/fields'
import { FormGroup } from '../../../form/Form/FormGroup'
import { SharedPolicyFormFields } from '../SharedPolicyFormFields'

import {
  DeathBenefitsLabels,
  GuaranteesLabels,
  HeldInTrustLabels,
  InvestmentTypesLabels,
  PlanStatusesLabels
} from '../../../../helpers/enumLabels/investmentPolicy'
import { buildSelectOptions } from '../../../form/fields/SelectField'

const { PlanStatuses } = constants.policy.investmentPolicy
const {
  DeathBenefits,
  Guarantees,
  HeldInTrustValues,
  InvestmentTypes
} = constants.policy.investmentPolicy

const InvestmentPolicyDetailsForm = ({ currentClient, companyClients }) => (
  <>
    <div className='policy-type-form investment-policy-form'>
      <SharedPolicyFormFields
        {...{
          companyClients,
          currentClient,
          policyTypeLabel: 'Policy type',
          policyTypeName: 'investmentType',
          policyTypeOptions: buildSelectOptions(
            InvestmentTypes,
            InvestmentTypesLabels
          ),
          planStatusOptions: buildSelectOptions(
            PlanStatuses,
            PlanStatusesLabels
          )
        }}
      />

      <FormGroup label='Dates'>
        <div className='policy-type-form__row policy-type-form__row--4'>
          <DateField
            name='applicationDate'
            label='Application date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='startDate'
            label='Start date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='maturityDate'
            label='Maturity date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>
      </FormGroup>

      <FormGroup label='Other details'>
        <div className='policy-type-form__row policy-type-form__row--3-1'>
          <div className='policy-type-form__row policy-type-form__row--2'>
            <SelectField
              clearable
              name='deathBenefit'
              label='Death benefit'
              options={buildSelectOptions(DeathBenefits, DeathBenefitsLabels)}
            />

            <MoneyField
              name='additionalLifeCover'
              label='Additional life cover'
            />
          </div>
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-held-in-rus'
            options={buildSelectOptions(HeldInTrustValues, HeldInTrustLabels)}
            name='heldInTrust'
            groupLabel='Held in trust'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--3-1'>
          <div className='policy-type-form__row policy-type-form__row--2'>
            <SelectField
              clearable
              name='guarantees'
              label='Guarantees'
              options={buildSelectOptions(Guarantees, GuaranteesLabels)}
            />
          </div>
        </div>
      </FormGroup>

      <FormGroup label='Surrender value'>
        <div className='policy-type-form__hint'>
          Manual valuations are only needed when the policy is not integrated
          with Transact.
        </div>

        <div className='policy-type-form__row policy-type-form__row--4'>
          <MoneyField name='surrenderValue' label='Surrender value' />

          <DateField
            label='Surrender date'
            name='surrenderDate'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>
      </FormGroup>
    </div>
  </>
)

export default InvestmentPolicyDetailsForm
