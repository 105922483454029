import Heading from '../../misc/Heading'
import { AddImportPlatformButton } from '../AddImportPlatformButton'
import { TableResponsiveActionHeader } from '../../misc/StylePlaceholders'

const ImportPageHeading = () => (
  <TableResponsiveActionHeader>
    <Heading as='h3'>Sources</Heading>
    <AddImportPlatformButton />
  </TableResponsiveActionHeader>
)

export { ImportPageHeading }
