import T from 'prop-types'

import { Tooltip } from './../../../misc/Tooltip'
import { Badge } from './../../../misc'

const BadgeTooltipList = ({ id, list, title, children }) => {
  if (!list.length || !id || list.length === 1) {
    return null
  }
  return (
    <Tooltip.Trigger
      as={Badge}
      tooltip={Tooltip.List}
      tooltipId={id}
      tooltipProps={{ list, title }}
      color={Badge.Colors.PICTON_BLUE}
      round
      action
    >
      {children}
    </Tooltip.Trigger>
  )
}

BadgeTooltipList.defaultProps = {
  list: []
}

BadgeTooltipList.propTypes = {
  id: T.string.isRequired,
  list: T.arrayOf(T.node).isRequired,
  title: T.string.isRequired
}

export default BadgeTooltipList
