const File = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M14.375 2l5.552 5.58v12.076A2.346 2.346 0 0117.583 22H6.416a2.346 2.346 0 01-2.344-2.344V4.344A2.346 2.346 0 016.416 2h7.96zm-1.127 1.563H6.416c-.43 0-.781.35-.781.78v15.313c0 .431.35.782.78.782h11.168c.431 0 .782-.351.782-.782V8.684H15.59a2.346 2.346 0 01-2.343-2.344V3.563zm3.049 12.596a.706.706 0 110 1.412H7.603a.706.706 0 110-1.412h8.694zm0-3.012a.706.706 0 110 1.412H7.603a.706.706 0 110-1.412h8.694zm0-3.012a.706.706 0 110 1.412H7.603a.706.706 0 110-1.412h8.694zM14.81 4.652V6.34c0 .431.35.782.781.782h1.676l-2.457-2.47z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { File }
