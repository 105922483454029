import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

import { PrevLabel, NextLabel } from './PaginationLabel'

import './Pagination.less'

const Pagination = ({
  pageCount,
  pageRangeDisplayed = 3,
  marginPagesDisplayed = 3,
  onPageChange,
  ...rest
}) => {
  return (
    <ReactPaginate
      {...rest}
      pageCount={pageCount}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      onPageChange={(item) => onPageChange?.(item?.selected)}
      containerClassName='pagination__container'
      activeClassName='pagination__activeClassName'
      activeLinkClassName='pagination__activeClassName'
      pageClassName='pagination__pageClassName'
      disabledClassName='pagination__disabledClassName'
      breakClassName='pagination__breakLinkClassName'
      previousClassName='pagination__previousClassName'
      nextClassName='pagination__nextClassName'
      pageLinkClassName='pagination__pageLinkClassName'
      previousLabel={<PrevLabel />}
      nextLabel={<NextLabel />}
    />
  )
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  onPageChange: PropTypes.func
}

export default Pagination
