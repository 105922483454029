import constants from '@elenfs/elen-constants'

import { SingleImportLogLayout } from '../SingleImportLogLayout'

const { DataImportTypes } = constants.dataImport

export const P1SingleLogPage = () => {
  const link = '/secure/admin/p1/logs'

  return (
    <SingleImportLogLayout
      link={link}
      dataImportType={DataImportTypes.P1}
    />
  )
}
