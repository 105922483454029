import {
  GoBackHeader,
  EmptyPage,
  ErrorMessage,
  Spinner,
  Filter,
  DownloadReportBtn
} from '../../../misc'
import { ReportTable } from '../../ReportTable'

import './AssetsUnderManagementPage.less'

const AssetsUnderManagementPage = props => {
  const {
    filterProps,
    tableProps,
    loading,
    error,
    called,
    downloadBtnProps
  } = props

  const isEmptyRows = !tableProps?.rows?.length

  return (
    <div className='aum-page'>
      <GoBackHeader title='Assets Under Management' />

      <Filter loading={loading} {...filterProps} />

      {loading && <Spinner />}

      {isEmptyRows && called && !loading && !error && (
        <EmptyPage text='No records found' hidden={false} />
      )}

      {error && <ErrorMessage text='Failed to load records' />}

      {!isEmptyRows && (
        <>
          <DownloadReportBtn className='dl-report-btn' {...downloadBtnProps} />

          <div className='table-wrap'>
            <ReportTable {...tableProps} />
          </div>
        </>
      )}
    </div>
  )
}

export { AssetsUnderManagementPage }
