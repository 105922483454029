import T from 'prop-types'

import { OverviewCard } from '../OverviewCard'

const AccountDetails = ({ accountOwners, companyName, companySubdomain }) => {
  const summaries = [
    { label: 'Account owners', value: accountOwners.join(' | ') },
    { label: 'Company name', value: companyName },
    { label: 'Company subdomain', value: companySubdomain }
  ]

  return <OverviewCard {...{ heading: 'Account Details', summaries }} />
}
AccountDetails.defaultProps = {
  accountOwners: ['Account Owner'],
  companyName: 'Company name',
  companySubdomain: 'Company subdomain'
}

AccountDetails.propTypes = {
  accountOwners: T.arrayOf(T.string.isRequired).isRequired,
  companyName: T.string.isRequired,
  companySubdomain: T.string.isRequired
}

export { AccountDetails }
