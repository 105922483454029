import constants from '@elenfs/elen-constants'

const {
  DeathBenefits,
  Guarantees,
  PCLSEntitlements,
  PlanStatuses,
  Protections,
  PensionTypesLabels
} = constants.policy.pensionPolicy

export const DeathBenefitsLabels = Object.freeze({
  [DeathBenefits.RETURN_OF_CONTRIBUTIONS]: 'Return of contributions',
  [DeathBenefits.RETURN_OF_CONTRIBUTIONS_WITH_INTEREST]: 'Return of contributions with interest',
  [DeathBenefits.RETURN_OF_FUND]: 'Return of fund',
  [DeathBenefits.RETURN_OF_FUND_PLUS_LIFE_COVER]: 'Return of fund plus life cover'
})

export const GuaranteesLabels = Object.freeze({
  [Guarantees.GUARANTEED_ANNUITY_RATE]: 'Guaranteed Annuity rate',
  [Guarantees.GUARANTEED_MVA_FREE_DATE]: 'Guaranteed MVA-free date',
  [Guarantees.GUARANTEED_BONUS_RATE]: 'Guaranteed bonus rate',
  [Guarantees.GUARANTEED_FUTURE_FUND_VALUE]: 'Guaranteed future fund value',
  [Guarantees.GUARANTEED_GROWTH_RATE]: 'Guaranteed growth rate',
  [Guarantees.GUARANTEED_INCOME_BASE]: 'Guaranteed income base',
  [Guarantees.OTHER_GUARANTEE]: 'Other guarantee'
})

export const PCLSEntitlementsLabels = Object.freeze({
  [PCLSEntitlements.PERCENT_OF_FUND_25]: '25% of fund',
  [PCLSEntitlements.PROTECTED]: 'Protected'
})

export const PlanStatusesLabels = Object.freeze({
  [PlanStatuses.APPLICATION_SUBMITTED]: 'Application submitted',
  [PlanStatuses.LIVE]: 'Live',
  [PlanStatuses.SURRENDERED]: 'Surrendered',
  [PlanStatuses.NTU]: 'Not Taken Up',
  [PlanStatuses.TRANSFERRED_OUT]: 'Transferred out'
})

export const ProtectionsLabels = Object.freeze({
  [Protections.ENHANCED_PROTECTION]: 'Enhanced Protection',
  [Protections.FIXED_PROTECTION_2012]: 'Fixed Protection 2012',
  [Protections.FIXED_PROTECTION_2014]: 'Fixed Protection 2014',
  [Protections.INDIVIDUAL_PROTECTION_2014]: 'Individual Protection 2014',
  [Protections.PRIMARY_PROTECTION]: 'Primary Protection'
})

export { PensionTypesLabels }
