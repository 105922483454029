import T from 'prop-types'
import classnames from 'classnames'

import { MoneyInput } from '../../inputs'
import { BaseField } from '../BaseField'

import './MoneyField.less'

/**
 * Field for money inputs.
 */
const MoneyField = ({
  value,
  handleChange,
  positiveOnly,
  inputProps,
  label,
  error,
  className,
  required
}) => (
  <BaseField {...{
    label,
    error,
    required,
    className: classnames(className, 'money-field')
  }}
  >
    <div className='money-field__input-wrapper'>
      <MoneyInput
        value={value}
        positiveOnly={positiveOnly}
        handleChange={handleChange}
        required
        {...inputProps}
      />
    </div>
  </BaseField>
)

MoneyField.defaultProps = {
  inputProps: {}
}

MoneyField.propTypes = {
  /**
   * Value for MoneyInput.
   */
  value: T.oneOfType([T.string, T.number]),
  /**
   * Function that receives new values from MoneyInput.
   */
  handleChange: T.func,
  /**
   * Props for MoneyInput.
   */
  inputProps: T.object,
  /**
   * Label for the field.
   */
  label: T.string,
  /**
   * Error from Formik.
   */
  error: T.string,
  /**
   * Classname for the field
   */
  className: T.string
}

export { MoneyField }
