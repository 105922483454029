"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WithdrawalMethodsLabels = exports.WithdrawalFrequenciesLabels = exports.PolicyValuationSupportedCategories = exports.PolicyTransactionTypesLabels = exports.PolicyHoldersLabels = exports.PolicyExpiredPlanStatuses = exports.ContributorsLabels = exports.ContributionMethodsLabels = exports.ContributionFrequenciesLabels = void 0;
var _misc = require("../misc");
var _debtPolicy = require("./debtPolicy");
var _investmentPolicy = require("./investmentPolicy");
var _mortgagePolicy = require("./mortgagePolicy");
var _pensionPolicy = require("./pensionPolicy");
var _protectionPolicy = require("./protectionPolicy");
const PolicyCategories = Object.freeze({
  DEBTS: 'DEBTS',
  INVESTMENT: 'INVESTMENT',
  MORTGAGES: 'MORTGAGES',
  PENSIONS: 'PENSIONS',
  PROTECTION: 'PROTECTION'
});
const AUMCategories = Object.freeze({
  INVESTMENT: 'INVESTMENT',
  PENSIONS: 'PENSIONS'
});
const PolicyValuationCategories = Object.freeze({
  PENSIONS: 'PENSIONS',
  INVESTMENT: 'INVESTMENT'
});
const AUMSubCategories = Object.freeze({
  ..._investmentPolicy.InvestmentTypes,
  ..._pensionPolicy.PensionTypes
});
const PolicySubCategories = Object.freeze({
  ..._debtPolicy.DebtTypes,
  ..._investmentPolicy.InvestmentTypes,
  ..._mortgagePolicy.MortgageTypes,
  ..._pensionPolicy.PensionTypes,
  ..._protectionPolicy.ProtectionTypes
});
const PolicyHolders = Object.freeze({
  CLIENT: 'CLIENT',
  JOINT: 'JOINT',
  PARTNER: 'PARTNER'
});

/**
 * A list of all the providers
 */
const PolicyProviders = Object.freeze({
  ABRDN: 'ABRDN',
  ACCORD: 'ACCORD',
  AEGON: 'AEGON',
  AIG: 'AIG',
  AIG_LIFE: 'AIG_LIFE',
  AJ_BELL: 'AJ_BELL',
  ALDERMORE: 'ALDERMORE',
  ALLTRUST: 'ALLTRUST',
  AON: 'AON',
  AVALON: 'AVALON',
  AVIVA_HEALTH_UK: 'AVIVA_HEALTH_UK',
  AVIVA_WRAP: 'AVIVA_WRAP',
  AVIVA: 'AVIVA',
  AXA: 'AXA',
  BANK_OF_SCOTLAND: 'BANK_OF_SCOTLAND',
  BARCLAYS: 'BARCLAYS',
  BATH_BUILDING_SOCIETY: 'BATH_BUILDING_SOCIETY',
  BC_AND_E: 'BC_AND_E',
  BLUESTONE_MORTGAGES: 'BLUESTONE_MORTGAGES',
  CANACCORD: 'CANACCORD',
  CANADA_LIFE_INTERNATIONAL: 'CANADA_LIFE_INTERNATIONAL',
  CANADA_LIFE: 'CANADA_LIFE',
  CAPITA: 'CAPITA',
  SCOTTISH_FRIENDLY: 'SCOTTISH_FRIENDLY',
  CITIBANK: 'CITIBANK',
  CLERICAL_MEDICAL: 'CLERICAL_MEDICAL',
  COUNTRYWIDE: 'COUNTRYWIDE',
  COVENTRY_BUILDING_SOCIETY: 'COVENTRY_BUILDING_SOCIETY',
  CURTIS_BANKS: 'CURTIS_BANKS',
  DARLINGTON_BUILDING_SOCIETY: 'DARLINGTON_BUILDING_SOCIETY',
  DOWNING: 'DOWNING',
  DUDLEY_BUILDING_SOCIETY: 'DUDLEY_BUILDING_SOCIETY',
  EARL_OF_SHILTON_BUILDING_SOCIETY: 'EARL_OF_SHILTON_BUILDING_SOCIETY',
  EDGE_INVESTMENTS: 'EDGE_INVESTMENTS',
  EMBARK_ZURICH: 'EMBARK_ZURICH',
  EQUITABLE_LIFE: 'EQUITABLE_LIFE',
  EXETER_FRIENDLY: 'EXETER_FRIENDLY',
  FAMILY_BUILDING_SOCIETY: 'FAMILY_BUILDING_SOCIETY',
  FIDELITY_INTERNATIONAL: 'FIDELITY_INTERNATIONAL',
  FIDELITY: 'FIDELITY',
  FORESIGHT_GROUP: 'FORESIGHT_GROUP',
  FOUNDATIONS_HOME_LOANS: 'FOUNDATIONS_HOME_LOANS',
  FRIENDS_LIFE: 'FRIENDS_LIFE',
  FRIENDS_PROVIDENT: 'FRIENDS_PROVIDENT',
  FUNDMENT: 'FUNDMENT',
  GLENHAWK: 'GLENHAWK',
  GODIVA_MORTGAGES: 'GODIVA_MORTGAGES',
  GOLDMAN_SACHS: 'GOLDMAN_SACHS',
  HALIFAX: 'HALIFAX',
  HAMPSHIRE_TRUST_BANK: 'HAMPSHIRE_TRUST_BANK',
  HANDELSBANKEN: 'HANDELSBANKEN',
  HANLEY_ECONOMIC_BUILDING_SOCIETY: 'HANLEY_ECONOMIC_BUILDING_SOCIETY',
  HARGREAVES_LANSDOWN: 'HARGREAVES_LANSDOWN',
  HENDERSON: 'HENDERSON',
  HINCKLEY_AND_RUGBY_BUILDING_SOCIETY: 'HINCKLEY_AND_RUGBY_BUILDING_SOCIETY',
  HODGE_BANK: 'HODGE_BANK',
  HOLLOWAY_FRIENDLY: 'HOLLOWAY_FRIENDLY',
  HOPE_CAPITAL: 'HOPE_CAPITAL',
  HSBC: 'HSBC',
  INGENIOUS: 'INGENIOUS',
  INVESCO_PERPETUAL: 'INVESCO_PERPETUAL',
  INVESTEC: 'INVESTEC',
  IPM_TRUSTEES_LTD: 'IPM_TRUSTEES_LTD',
  JAMES_HAY: 'JAMES_HAY',
  JUST: 'JUST',
  KENSINGTON_MORTGAGES: 'KENSINGTON_MORTGAGES',
  KENT_RELIANCE: 'KENT_RELIANCE',
  KEYSTONE: 'KEYSTONE',
  KING_AND_SHAXSON: 'KING_AND_SHAXSON',
  LANDBAY: 'LANDBAY',
  LEEDS_BUILDING_SOCIETY: 'LEEDS_BUILDING_SOCIETY',
  LEEK_UNITED_BUILDING_SOCIETY: 'LEEK_UNITED_BUILDING_SOCIETY',
  LEGAL_AND_GENERAL: 'LEGAL_AND_GENERAL',
  LINK_FINANCIAL: 'LINK_FINANCIAL',
  LOMBARD_INTERNATIONAL_ASSURANCE: 'LOMBARD_INTERNATIONAL_ASSURANCE',
  LV: 'LV',
  MANSFIELD_BUILDING_SOCIETY: 'MANSFIELD_BUILDING_SOCIETY',
  MARGETTS: 'MARGETTS',
  MARSDEN: 'MARSDEN',
  MBS_LENDING: 'MBS_LENDING',
  MELTON_MOWBRAY_BUILDING_SOCIETY: 'MELTON_MOWBRAY_BUILDING_SOCIETY',
  MERCER: 'MERCER',
  METLIFE: 'METLIFE',
  METRO_BANK: 'METRO_BANK',
  MGM: 'MGM',
  MONMOUTHSHIRE_BUILDING_SOCIETY: 'MONMOUTHSHIRE_BUILDING_SOCIETY',
  MT_FINANCE: 'MT_FINANCE',
  M_AND_G: 'M_AND_G',
  NATIONAL_SAVINGS: 'NATIONAL_SAVINGS',
  NATIONWIDE_BUILDING_SOCIETY: 'NATIONWIDE_BUILDING_SOCIETY',
  NATWEST: 'NATWEST',
  NEST: 'NEST',
  NEWCASTLE_BUILDING_SOCIETY: 'NEWCASTLE_BUILDING_SOCIETY',
  NOTTINGHAM_BUILDING_SOCIETY: 'NOTTINGHAM_BUILDING_SOCIETY',
  NOVIA: 'NOVIA',
  NOW_PENSIONS: 'NOW_PENSIONS',
  NPI: 'NPI',
  NUCLEUS: 'NUCLEUS',
  OCTOPUS: 'OCTOPUS',
  ODYSSEY: 'ODYSSEY',
  OLD_MUTUAL_INTERNATIONAL: 'OLD_MUTUAL_INTERNATIONAL',
  OLD_MUTUAL_WEALTH: 'OLD_MUTUAL_WEALTH',
  ONEFAMILY: 'ONEFAMILY',
  OTHER: 'OTHER',
  P1_INVESTMENT_MANAGEMENT: 'P1_INVESTMENT_MANAGEMENT',
  PARAGON: 'PARAGON',
  PARMENION: 'PARMENION',
  PARTNERSHIP: 'PARTNERSHIP',
  PENINSULA_PENSIONS: 'PENINSULA_PENSIONS',
  PENRITH_BUILDING_SOCIETY: 'PENRITH_BUILDING_SOCIETY',
  PENSION_PROTECTION_FUND: 'PENSION_PROTECTION_FUND',
  PEPPER_MONEY: 'PEPPER_MONEY',
  PHOENIX_LIFE: 'PHOENIX_LIFE',
  PLATFORM: 'PLATFORM',
  PRAEMIUM: 'PRAEMIUM',
  PRECISE: 'PRECISE',
  PREMIER_ASSET_MANAGEMENT: 'PREMIER_ASSET_MANAGEMENT',
  PRINCIPALITY_BUILDING_SOCIETY: 'PRINCIPALITY_BUILDING_SOCIETY',
  PRUDENTIAL_INTERNATIONAL: 'PRUDENTIAL_INTERNATIONAL',
  PRUDENTIAL: 'PRUDENTIAL',
  RBS: 'RBS',
  RE_ASSURE: 'RE_ASSURE',
  REDWOOD_BANK: 'REDWOOD_BANK',
  ROTHESAY_LIFE: 'ROTHESAY_LIFE',
  ROYAL_LONDON: 'ROYAL_LONDON',
  ROYAL_SKANDIA: 'ROYAL_SKANDIA',
  SAFFRON_BUILDING_SOCIETY: 'SAFFRON_BUILDING_SOCIETY',
  SANLAM: 'SANLAM',
  SANTANDER: 'SANTANDER',
  SCOTTISH_LIFE: 'SCOTTISH_LIFE',
  SCOTTISH_MUTUAL: 'SCOTTISH_MUTUAL',
  SCOTTISH_PROVIDENT: 'SCOTTISH_PROVIDENT',
  SCOTTISH_WIDOWS: 'SCOTTISH_WIDOWS',
  SHAWBROOK_IMPACT: 'SHAWBROOK_IMPACT',
  SKIPTON_BUILDING_SOCIETY: 'SKIPTON_BUILDING_SOCIETY',
  ST_JAMES_PLACE: 'ST_JAMES_PLACE',
  STAFFORD_RAILWAY_BUILDING_SOCIETY: 'STAFFORD_RAILWAY_BUILDING_SOCIETY',
  STANDARD_LIFE: 'STANDARD_LIFE',
  STERLING: 'STERLING',
  SUFFOLK_BUILDING_SOCIETY: 'SUFFOLK_BUILDING_SOCIETY',
  SUN_LIFE_FINANCIAL_OF_CANADA: 'SUN_LIFE_FINANCIAL_OF_CANADA',
  SWANSEA_BUILDING_SOCIETY: 'SWANSEA_BUILDING_SOCIETY',
  TALBOT_AND_MUIR: 'TALBOT_AND_MUIR',
  THE_EXETER: 'THE_EXETER',
  THE_MORTGAGE_LENDER: 'THE_MORTGAGE_LENDER',
  THE_MORTGAGE_WORKS: 'THE_MORTGAGE_WORKS',
  THE_NOTTINGHAM: 'THE_NOTTINGHAM',
  THE_PENSION_WORKS: 'THE_PENSION_WORKS',
  THE_PEOPLES_PENSION: 'THE_PEOPLES_PENSION',
  TIPTON_AND_COSELEY_BUILDING_SOCIETY: 'TIPTON_AND_COSELEY_BUILDING_SOCIETY',
  TOGETHER: 'TOGETHER',
  TOWERS_WATSON: 'TOWERS_WATSON',
  TRANSACT: 'TRANSACT',
  TRIPLE_POINT: 'TRIPLE_POINT',
  UINSURE: 'UINSURE',
  UTB: 'UTB',
  UTMOST_WEALTH: 'UTMOST_WEALTH',
  VERNON_BUILDING_SOCIETY: 'VERNON_BUILDING_SOCIETY',
  VIDA_HOMELOANS: 'VIDA_HOMELOANS',
  VIRGIN_MONEY: 'VIRGIN_MONEY',
  VITALITY_HEALTH: 'VITALITY_HEALTH',
  VITALITY_LIFE: 'VITALITY_LIFE',
  WEALTHTIME: 'WEALTHTIME',
  WEST_ONE: 'WEST_ONE',
  ZURICH: 'ZURICH'
});

// DEPRECATED
// TODO: remove when not used anywhere
const Frequencies = Object.freeze({
  ..._misc.RecurrentFrequencies,
  SINGLE: 'SINGLE'
});
const PolicyStatuses = {
  EXISTING: 'EXISTING',
  NEW: 'NEW'
};
const CategoryStrings = Object.freeze({
  [PolicyCategories.DEBTS]: 'Debt',
  [PolicyCategories.INVESTMENT]: 'Investment',
  [PolicyCategories.MORTGAGES]: 'Mortgage',
  [PolicyCategories.PENSIONS]: 'Pension',
  [PolicyCategories.PROTECTION]: 'Protection'
});
const PolicyTransactionTypes = Object.freeze({
  CONTRIBUTION: 'CONTRIBUTION',
  WITHDRAWAL: 'WITHDRAWAL'
});
const Contributors = Object.freeze({
  CLIENT: 'CLIENT',
  EMPLOYER: 'EMPLOYER'
});
const ContributionFrequencies = Object.freeze({
  INITIAL: 'INITIAL',
  SINGLE: 'SINGLE',
  ..._misc.RecurrentFrequencies
});
const ContributionMethods = Object.freeze({
  CONTRIBUTION: 'CONTRIBUTION',
  TRANSFER_IN: 'TRANSFER_IN'
});
const WithdrawalFrequencies = Object.freeze({
  SINGLE: 'SINGLE',
  ..._misc.RecurrentFrequencies
});
const WithdrawalMethods = Object.freeze({
  WITHDRAWAL: 'WITHDRAWAL',
  TRANSFER_OUT: 'TRANSFER_OUT'
});
const PolicyCategoriesLabels = Object.freeze({
  [PolicyCategories.DEBTS]: 'Debt',
  [PolicyCategories.INVESTMENT]: 'Investment',
  [PolicyCategories.MORTGAGES]: 'Mortgages',
  [PolicyCategories.PENSIONS]: 'Pension',
  [PolicyCategories.PROTECTION]: 'Protection'
});
const PolicySubCategoriesLabels = Object.freeze({
  ..._debtPolicy.DebtTypesLabels,
  ..._investmentPolicy.InvestmentTypesLabels,
  ..._mortgagePolicy.MortgageTypesLabels,
  ..._pensionPolicy.PensionTypesLabels,
  ..._protectionPolicy.ProtectionTypesLabels
});
const AUMCategoriesLabels = Object.freeze({
  [PolicyCategories.INVESTMENT]: 'Investment',
  [PolicyCategories.PENSIONS]: 'Pension'
});
const AUMSubCategoriesLabels = Object.freeze({
  ..._investmentPolicy.InvestmentTypesLabels,
  ..._pensionPolicy.PensionTypesLabels
});
const PolicyProvidersLabels = Object.freeze({
  [PolicyProviders.ABRDN]: 'Abrdn',
  [PolicyProviders.ACCORD]: 'Accord',
  [PolicyProviders.AEGON]: 'Aegon',
  [PolicyProviders.AIG]: 'AIG',
  [PolicyProviders.AIG_LIFE]: 'AIG Life',
  [PolicyProviders.AJ_BELL]: 'AJ Bell',
  [PolicyProviders.ALDERMORE]: 'Aldermore',
  [PolicyProviders.ALLTRUST]: 'Alltrust',
  [PolicyProviders.AON]: 'AON',
  [PolicyProviders.AVALON]: 'Avalon',
  [PolicyProviders.AVIVA_HEALTH_UK]: 'Aviva Health UK',
  [PolicyProviders.AVIVA_WRAP]: 'Aviva Wrap',
  [PolicyProviders.AVIVA]: 'Aviva',
  [PolicyProviders.AXA]: 'Axa',
  [PolicyProviders.BANK_OF_SCOTLAND]: 'Bank of Scotland',
  [PolicyProviders.BARCLAYS]: 'Barclays',
  [PolicyProviders.BATH_BUILDING_SOCIETY]: 'Bath Building Society',
  [PolicyProviders.BC_AND_E]: 'BC&E',
  [PolicyProviders.BLUESTONE_MORTGAGES]: 'Bluestone Mortgages',
  [PolicyProviders.CANACCORD]: 'Canaccord',
  [PolicyProviders.CANADA_LIFE_INTERNATIONAL]: 'Canada Life International',
  [PolicyProviders.CANADA_LIFE]: 'Canada Life',
  [PolicyProviders.CAPITA]: 'Capita',
  [PolicyProviders.SCOTTISH_FRIENDLY]: 'Scottish Friendly',
  [PolicyProviders.CITIBANK]: 'Citibank',
  [PolicyProviders.CLERICAL_MEDICAL]: 'Clerical Medical',
  [PolicyProviders.COUNTRYWIDE]: 'Countrywide',
  [PolicyProviders.COVENTRY_BUILDING_SOCIETY]: 'Coventry Building Society',
  [PolicyProviders.CURTIS_BANKS]: 'Curtis Banks',
  [PolicyProviders.DARLINGTON_BUILDING_SOCIETY]: 'Darlington Building Society',
  [PolicyProviders.DOWNING]: 'Downing',
  [PolicyProviders.DUDLEY_BUILDING_SOCIETY]: 'Dudley Building Society',
  [PolicyProviders.EARL_OF_SHILTON_BUILDING_SOCIETY]: 'Earl of Shilton Building Society',
  [PolicyProviders.EDGE_INVESTMENTS]: 'Edge Investments',
  [PolicyProviders.EMBARK_ZURICH]: 'Embark / Zurich',
  [PolicyProviders.EQUITABLE_LIFE]: 'Equitable Life',
  [PolicyProviders.EXETER_FRIENDLY]: 'Exeter Friendly',
  [PolicyProviders.FAMILY_BUILDING_SOCIETY]: 'Family Building Society',
  [PolicyProviders.FIDELITY_INTERNATIONAL]: 'Fidelity International',
  [PolicyProviders.FIDELITY]: 'Fidelity',
  [PolicyProviders.FORESIGHT_GROUP]: 'Foresight Group',
  [PolicyProviders.FOUNDATIONS_HOME_LOANS]: 'Foundations Home Loans',
  [PolicyProviders.FRIENDS_LIFE]: 'Friends Life',
  [PolicyProviders.FRIENDS_PROVIDENT]: 'Friends Provident',
  [PolicyProviders.FUNDMENT]: 'Fundment',
  [PolicyProviders.GLENHAWK]: 'Glenhawk',
  [PolicyProviders.GODIVA_MORTGAGES]: 'Godiva Mortgages',
  [PolicyProviders.GOLDMAN_SACHS]: 'Goldman Sachs',
  [PolicyProviders.HALIFAX]: 'Halifax',
  [PolicyProviders.HAMPSHIRE_TRUST_BANK]: 'Hampshire Trust Bank',
  [PolicyProviders.HANDELSBANKEN]: 'Handelsbanken',
  [PolicyProviders.HANLEY_ECONOMIC_BUILDING_SOCIETY]: 'Hanley Economic Building Society',
  [PolicyProviders.HARGREAVES_LANSDOWN]: 'Hargreaves Lansdown',
  [PolicyProviders.HENDERSON]: 'Janus Henderson',
  [PolicyProviders.HINCKLEY_AND_RUGBY_BUILDING_SOCIETY]: 'Hinckley & Rugby Building Society',
  [PolicyProviders.HODGE_BANK]: 'Hodge Bank',
  [PolicyProviders.HOLLOWAY_FRIENDLY]: 'Holloway Friendly',
  [PolicyProviders.HOPE_CAPITAL]: 'Hope Capital',
  [PolicyProviders.HSBC]: 'HSBC',
  [PolicyProviders.INGENIOUS]: 'Ingenious',
  [PolicyProviders.INVESCO_PERPETUAL]: 'Invesco Perpetual',
  [PolicyProviders.INVESTEC]: 'Investec',
  [PolicyProviders.IPM_TRUSTEES_LTD]: 'IPM Trustees Ltd',
  [PolicyProviders.JAMES_HAY]: 'James Hay',
  [PolicyProviders.JUST]: 'Just',
  [PolicyProviders.KENSINGTON_MORTGAGES]: 'Kensington Mortgages',
  [PolicyProviders.KENT_RELIANCE]: 'Kent Reliance',
  [PolicyProviders.KEYSTONE]: 'Keystone',
  [PolicyProviders.KING_AND_SHAXSON]: 'King & Shaxson',
  [PolicyProviders.LANDBAY]: 'Landbay',
  [PolicyProviders.LEEDS_BUILDING_SOCIETY]: 'Leeds Building Society',
  [PolicyProviders.LEEK_UNITED_BUILDING_SOCIETY]: 'Leek United Building Society',
  [PolicyProviders.LEGAL_AND_GENERAL]: 'Legal & General',
  [PolicyProviders.LINK_FINANCIAL]: 'Link Financial',
  [PolicyProviders.LOMBARD_INTERNATIONAL_ASSURANCE]: 'Lombard International Assurance',
  [PolicyProviders.LV]: 'LV=',
  [PolicyProviders.MANSFIELD_BUILDING_SOCIETY]: 'Mansfield Building Society',
  [PolicyProviders.MARGETTS]: 'Margetts',
  [PolicyProviders.MARSDEN]: 'Marsden',
  [PolicyProviders.MBS_LENDING]: 'MBS Lending',
  [PolicyProviders.MELTON_MOWBRAY_BUILDING_SOCIETY]: 'Melton Mowbray Building Society',
  [PolicyProviders.MERCER]: 'Mercer',
  [PolicyProviders.METLIFE]: 'MetLife',
  [PolicyProviders.METRO_BANK]: 'Metro Bank',
  [PolicyProviders.MGM]: 'MGM',
  [PolicyProviders.MONMOUTHSHIRE_BUILDING_SOCIETY]: 'Monmouthshire Building Society',
  [PolicyProviders.MT_FINANCE]: 'MT Finance',
  [PolicyProviders.M_AND_G]: 'M&G',
  [PolicyProviders.NATIONAL_SAVINGS]: 'National Savings',
  [PolicyProviders.NATIONWIDE_BUILDING_SOCIETY]: 'Nationwide Building Society',
  [PolicyProviders.NATWEST]: 'Natwest',
  [PolicyProviders.NEST]: 'NEST',
  [PolicyProviders.NEWCASTLE_BUILDING_SOCIETY]: 'Newcastle Building Society',
  [PolicyProviders.NOTTINGHAM_BUILDING_SOCIETY]: 'Nottingham Building Society',
  [PolicyProviders.NOVIA]: 'Novia',
  [PolicyProviders.NOW_PENSIONS]: 'NOW Pensions',
  [PolicyProviders.NPI]: 'NPI',
  [PolicyProviders.NUCLEUS]: 'Nucleus',
  [PolicyProviders.OCTOPUS]: 'Octopus',
  [PolicyProviders.ODYSSEY]: 'Odyssey',
  [PolicyProviders.OLD_MUTUAL_INTERNATIONAL]: 'Quilter International',
  [PolicyProviders.OLD_MUTUAL_WEALTH]: 'Quilter',
  [PolicyProviders.ONEFAMILY]: 'OneFamily',
  [PolicyProviders.OTHER]: 'Other',
  [PolicyProviders.P1_INVESTMENT_MANAGEMENT]: 'P1 Platform',
  [PolicyProviders.PARAGON]: 'Paragon',
  [PolicyProviders.PARMENION]: 'Parmenion',
  [PolicyProviders.PARTNERSHIP]: 'Partnership',
  [PolicyProviders.PENINSULA_PENSIONS]: 'Peninsula Pensions',
  [PolicyProviders.PENRITH_BUILDING_SOCIETY]: 'Penrith Building Society',
  [PolicyProviders.PENSION_PROTECTION_FUND]: 'Pension Protection Fund',
  [PolicyProviders.PEPPER_MONEY]: 'Pepper Money',
  [PolicyProviders.PHOENIX_LIFE]: 'Phoenix Life',
  [PolicyProviders.PLATFORM]: 'Platform',
  [PolicyProviders.PRAEMIUM]: 'Morningstar Wealth Platform',
  [PolicyProviders.PRECISE]: 'Precise',
  [PolicyProviders.PREMIER_ASSET_MANAGEMENT]: 'Premier Asset Management',
  [PolicyProviders.PRINCIPALITY_BUILDING_SOCIETY]: 'Principality Building Society',
  [PolicyProviders.PRUDENTIAL_INTERNATIONAL]: 'Prudential International',
  [PolicyProviders.PRUDENTIAL]: 'Prudential',
  [PolicyProviders.RBS]: 'RBS',
  [PolicyProviders.RE_ASSURE]: 'ReAssure',
  [PolicyProviders.REDWOOD_BANK]: 'Redwood Bank',
  [PolicyProviders.ROTHESAY_LIFE]: 'Rothesay Life',
  [PolicyProviders.ROYAL_LONDON]: 'Royal London',
  [PolicyProviders.ROYAL_SKANDIA]: 'Royal Skandia',
  [PolicyProviders.SAFFRON_BUILDING_SOCIETY]: 'Saffron Building Society',
  [PolicyProviders.SANLAM]: 'Sanlam',
  [PolicyProviders.SANTANDER]: 'Santander',
  [PolicyProviders.SCOTTISH_LIFE]: 'Scottish Life',
  [PolicyProviders.SCOTTISH_MUTUAL]: 'Scottish Mutual',
  [PolicyProviders.SCOTTISH_PROVIDENT]: 'Scottish Provident',
  [PolicyProviders.SCOTTISH_WIDOWS]: 'Scottish Widows',
  [PolicyProviders.SHAWBROOK_IMPACT]: 'Shawbrook / Impact',
  [PolicyProviders.SKIPTON_BUILDING_SOCIETY]: 'Skipton Building Society',
  [PolicyProviders.ST_JAMES_PLACE]: "St James' Place",
  [PolicyProviders.STAFFORD_RAILWAY_BUILDING_SOCIETY]: 'Stafford Railway Building Society',
  [PolicyProviders.STANDARD_LIFE]: 'Standard Life',
  [PolicyProviders.STERLING]: 'Sterling',
  [PolicyProviders.SUFFOLK_BUILDING_SOCIETY]: 'Suffolk Building Society',
  [PolicyProviders.SUN_LIFE_FINANCIAL_OF_CANADA]: 'Sun Life Financial of Canada',
  [PolicyProviders.SWANSEA_BUILDING_SOCIETY]: 'Swansea Building Society',
  [PolicyProviders.TALBOT_AND_MUIR]: 'Talbot and Muir',
  [PolicyProviders.THE_EXETER]: 'The Exeter',
  [PolicyProviders.THE_MORTGAGE_LENDER]: 'The Mortgage Lender',
  [PolicyProviders.THE_MORTGAGE_WORKS]: 'The Mortgage Works',
  [PolicyProviders.THE_NOTTINGHAM]: 'The Nottingham',
  [PolicyProviders.THE_PENSION_WORKS]: 'The Pension Works',
  [PolicyProviders.THE_PEOPLES_PENSION]: "The People's Pension",
  [PolicyProviders.TIPTON_AND_COSELEY_BUILDING_SOCIETY]: 'Tipton and Coseley Building Society',
  [PolicyProviders.TOGETHER]: 'Together',
  [PolicyProviders.TOWERS_WATSON]: 'Towers Watson',
  [PolicyProviders.TRANSACT]: 'Transact',
  [PolicyProviders.TRIPLE_POINT]: 'Triple Point',
  [PolicyProviders.UINSURE]: 'Uinsure',
  [PolicyProviders.UTB]: 'UTB',
  [PolicyProviders.UTMOST_WEALTH]: 'Utmost Wealth',
  [PolicyProviders.VERNON_BUILDING_SOCIETY]: 'Vernon Building Society',
  [PolicyProviders.VIDA_HOMELOANS]: 'Vida Homeloans',
  [PolicyProviders.VIRGIN_MONEY]: 'Virgin Money',
  [PolicyProviders.VITALITY_HEALTH]: 'Vitality Health',
  [PolicyProviders.VITALITY_LIFE]: 'Vitality Life',
  [PolicyProviders.WEALTHTIME]: 'Wealthtime',
  [PolicyProviders.WEST_ONE]: 'West One',
  [PolicyProviders.ZURICH]: 'Zurich'
});
const PolicyHoldersLabels = exports.PolicyHoldersLabels = Object.freeze({
  [PolicyHolders.CLIENT]: 'Client',
  [PolicyHolders.JOINT]: 'Joint',
  [PolicyHolders.PARTNER]: 'Partner'
});
const PolicyTransactionTypesLabels = exports.PolicyTransactionTypesLabels = Object.freeze({
  [PolicyTransactionTypes.CONTRIBUTION]: 'Contribution',
  [PolicyTransactionTypes.WITHDRAWAL]: 'Withdrawal'
});
const ContributorsLabels = exports.ContributorsLabels = Object.freeze({
  [Contributors.EMPLOYER]: 'Employer',
  [Contributors.CLIENT]: 'Client'
});
const ContributionMethodsLabels = exports.ContributionMethodsLabels = Object.freeze({
  [ContributionMethods.CONTRIBUTION]: 'Contribution',
  [ContributionMethods.TRANSFER_IN]: 'Transfer In'
});
const WithdrawalMethodsLabels = exports.WithdrawalMethodsLabels = Object.freeze({
  [WithdrawalMethods.WITHDRAWAL]: 'Withdrawal',
  [WithdrawalMethods.TRANSFER_OUT]: 'Transfer Out'
});
const ContributionFrequenciesLabels = exports.ContributionFrequenciesLabels = Object.freeze({
  [ContributionFrequencies.INITIAL]: 'Initial',
  [ContributionFrequencies.SINGLE]: 'Single',
  [ContributionFrequencies.WEEKLY]: 'Weekly',
  [ContributionFrequencies.MONTHLY]: 'Monthly',
  [ContributionFrequencies.QUARTERLY]: 'Quarterly',
  [ContributionFrequencies.HALF_YEARLY]: 'Half-yearly',
  [ContributionFrequencies.ANNUALLY]: 'Annually'
});
const WithdrawalFrequenciesLabels = exports.WithdrawalFrequenciesLabels = Object.freeze({
  [WithdrawalFrequencies.SINGLE]: 'Single',
  [WithdrawalFrequencies.WEEKLY]: 'Weekly',
  [WithdrawalFrequencies.MONTHLY]: 'Monthly',
  [WithdrawalFrequencies.QUARTERLY]: 'Quarterly',
  [WithdrawalFrequencies.HALF_YEARLY]: 'Half-yearly',
  [WithdrawalFrequencies.ANNUALLY]: 'Annually'
});
const PolicyExpiredPlanStatuses = exports.PolicyExpiredPlanStatuses = Object.freeze({
  TRANSFERRED_OUT: 'TRANSFERRED_OUT',
  SURRENDERED: 'SURRENDERED',
  NTU: 'NTU',
  CANCELLED: 'CANCELLED',
  LAPSED: 'LAPSED',
  DECLINED: 'DECLINED',
  MATURED: 'MATURED'
});
const PolicyValuationSupportedCategories = exports.PolicyValuationSupportedCategories = [PolicyCategories.INVESTMENT, PolicyCategories.PENSIONS];
var _default = exports.default = {
  PolicyCategories,
  PolicySubCategories,
  PolicyHolders,
  PolicyProviders,
  Frequencies,
  PolicyStatuses,
  CategoryStrings,
  PolicyTransactionTypes,
  Contributors,
  ContributionFrequencies,
  ContributionMethods,
  WithdrawalFrequencies,
  WithdrawalMethods,
  PolicyCategoriesLabels,
  PolicySubCategoriesLabels,
  PolicyProvidersLabels,
  PolicyHoldersLabels,
  PolicyTransactionTypesLabels,
  ContributorsLabels,
  ContributionMethodsLabels,
  WithdrawalMethodsLabels,
  ContributionFrequenciesLabels,
  WithdrawalFrequenciesLabels,
  AUMCategories,
  AUMSubCategories,
  AUMCategoriesLabels,
  AUMSubCategoriesLabels,
  PolicyExpiredPlanStatuses,
  PolicyValuationSupportedCategories,
  PolicyValuationCategories
};