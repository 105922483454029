import { useCallback } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { IntegrationPlatforms } from '../../../../constants'

import { Toastr } from '../../../misc'
import googleAuthURLGql from '../../../../graphql/queries/integration/googleAuthURL.graphql'
import microsoftAuthURLGql from '../../../../graphql/queries/integration/microsoftAuthURL.graphql'
import integrationConnectionsGql from '../../../../graphql/queries/integration/integrationConnections.graphql'
import removeGoogleConnectionGql from '../../../../graphql/mutations/integration/removeGoogleConnection.graphql'
import removeMicrosoftConnectionGql from '../../../../graphql/mutations/integration/removeMicrosoftConnection.graphql'
import connectGoogleGql from '../../../../graphql/mutations/integration/connectGoogle.graphql'
import connectMicrosoftGql from '../../../../graphql/mutations/integration/connectMicrosoft.graphql'

const withIntegrationConnection = () => WrappedComponent => props => {
  const { loading, error, data, refetch } = useQuery(integrationConnectionsGql, {
    fetchPolicy: 'cache-and-network'
  })

  const currentHref = window.location.href.split('?')[0]
  const state = encodeURIComponent(
    window.btoa(JSON.stringify({ redirectURL: currentHref }))
  )
  const initialConnectionLoading = loading && !data
  const emailConnections = data?.integrationConnections
  const connection = emailConnections?.length && emailConnections[0]
  const isConnected = !!connection
  const isExpired = !connection?.isExpired
  const platform = connection?.platform

  const [
    getGoogleAuthURL,
    { data: googleUrlData }
  ] = useLazyQuery(googleAuthURLGql, {
    variables: { state },
    fetchPolicy: 'cache-and-network'
  })
  const [
    getMicrosoftAuthURL,
    { data: microsoftUrlData }
  ] = useLazyQuery(microsoftAuthURLGql, {
    variables: { state },
    fetchPolicy: 'cache-and-network'
  })

  const onConnect = useCallback(
    (platform) => {
      switch (platform) {
        case IntegrationPlatforms.GOOGLE:
          getGoogleAuthURL()
          break
        case IntegrationPlatforms.MICROSOFT:
          getMicrosoftAuthURL()
          break
      }
    }, [getGoogleAuthURL, getMicrosoftAuthURL])

  const url = googleUrlData?.googleAuthURL || microsoftUrlData?.microsoftAuthURL

  const [
    connectGoogle,
    { loading: googleConnectLoading, error: googleConnectError }
  ] = useMutation(connectGoogleGql, {
    onCompleted: () => {
      refetch()
      toast(
        <Toastr
          type={Toastr.Types.SUCCESS}
          title='Account successfully connected'
        />
      )
    },
    onerror: () => toast(
      <Toastr
        type={Toastr.Types.ERROR}
        title='Something went wrong, please try again'
      />
    ),
    errorPolicy: 'all'
  })

  const [
    connectMicrosoft,
    { loading: microsoftConnectLoading, error: microsoftConnectError }
  ] = useMutation(connectMicrosoftGql, {
    onCompleted: () => {
      refetch()
      toast(
        <Toastr
          type={Toastr.Types.SUCCESS}
          title='Account successfully connected'
        />
      )
    },
    onerror: () => toast(
      <Toastr
        type={Toastr.Types.ERROR}
        title='Something went wrong, please try again'
      />
    ),
    errorPolicy: 'all'
  })

  const [
    removeGoogleConnection,
    { called: removeGoogleCalled, loading: removeGoogleLoading, removeGoogleError }
  ] = useMutation(removeGoogleConnectionGql, {
    onError: (error) => toast(
      <Toastr type={Toastr.Types.ERROR} title={error.message} />
    ),
    update: (cache, operationResult) => {
      const isSuccessful = operationResult?.data?.removeGoogleConnection?.success

      if (isSuccessful) {
        cache.readQuery({
          query: integrationConnectionsGql
        })

        cache.writeQuery({
          query: integrationConnectionsGql,
          data: {
            integrationConnections: []
          }
        })
      }
    },
    onCompleted: () => {
      toast(
        <Toastr type={Toastr.Types.SUCCESS} title='Account successfully removed' />
      )
    }
  })

  const [
    removeMicrosoftConnection,
    { called: removeMicrosoftCalled, loading: removeMicrosoftLoading, removeMicrosoftError }
  ] = useMutation(removeMicrosoftConnectionGql, {
    onError: (error) => toast(
      <Toastr type={Toastr.Types.ERROR} title={error.message} />
    ),
    update: (cache, operationResult) => {
      const isSuccessful = operationResult?.data?.removeMicrosoftConnection?.success

      if (isSuccessful) {
        cache.readQuery({
          query: integrationConnectionsGql
        })

        cache.writeQuery({
          query: integrationConnectionsGql,
          data: {
            integrationConnections: []
          }
        })
      }
    },
    onCompleted: () => {
      toast(
        <Toastr type={Toastr.Types.SUCCESS} title='Account successfully removed' />
      )
    }
  })

  let removeConnection
  let removeCalled
  let removeLoading
  let removeError

  switch (platform) {
    case IntegrationPlatforms.GOOGLE:
      removeConnection = removeGoogleConnection
      removeCalled = removeGoogleCalled
      removeLoading = removeGoogleLoading
      removeError = removeGoogleError
      break

    case IntegrationPlatforms.MICROSOFT:
      removeConnection = removeMicrosoftConnection
      removeCalled = removeMicrosoftCalled
      removeLoading = removeMicrosoftLoading
      removeError = removeMicrosoftError
      break
  }

  const connectProps = {
    connectGoogle,
    connectMicrosoft,
    connectLoading: googleConnectLoading || microsoftConnectLoading,
    connectError: googleConnectError || microsoftConnectError
  }

  const removeProps = {
    removeConnection,
    removeCalled,
    removeLoading,
    removeError
  }

  return (
    <WrappedComponent {...{
      connection,
      platform,
      isConnected,
      isExpired,
      connectionsLoading: initialConnectionLoading,
      connectionsError: error,
      refetchConnections: refetch,
      connectProps,
      removeProps,
      url,
      onConnect,
      ...props
    }}
    />
  )
}

export { withIntegrationConnection }
