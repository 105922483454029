import classnames from 'classnames'

import { Trash } from './../../icons'

import './FieldArrayItem.less'

const FieldArrayItem = ({ children, index, label, onRemove, disabled }) => (
  <div
    className={classnames('field-array-item', {
      'field-array-item--disabled': disabled
    })}
  >
    <div className='field-array-item__header'>
      <span>
        {label} {++index}
      </span>

      {!disabled && <Trash onClick={onRemove} />}
    </div>
    <div className='field-array-item__content'>{children}</div>
  </div>
)

export { FieldArrayItem }
