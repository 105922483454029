import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import clientDetailsGql from '../../../../graphql/queries/client/clientDetails.graphql'

const withCurrentClient = (
  { fetchPolicy = 'cache-and-network' } = {}
) => (WrappedComponent) => (props) => {
  const { clientId: paramClientId } = useParams()
  const { clientId: localClientId } = props
  const clientId = paramClientId || localClientId

  const { loading, data, error } = useQuery(clientDetailsGql, {
    variables: { clientId },
    fetchPolicy,
    // ! if page crashing because data=undefined: uncomment this!
    // returnPartialData: true,
    nextFetchPolicy: 'cache-first'
  })

  const currentClient = data && data.client

  return (
    <WrappedComponent
      isCurrentClientLoading={loading}
      currentClientError={error}
      currentClient={currentClient}
      {...props}
    />
  )
}

export { withCurrentClient }
