import Card from '../../misc/Card'
import Summary from '../../misc/Summary'
import { ClientCardTable } from '../ClientCardTable'
import { PageDivider } from '../../misc/PageDivider'

import {
  assetsCardTableProps,
  liabilitiesCardTableProps
} from './const'

const ClientDetailsBasePageCards = ({ client, baseUrl, canEdit, isClientPortal }) => (
  <>
    <Card className='client-details-page__client-details__assets-and-liabilities'>
      <Card.Header allowToNavigate={canEdit} id='assets-and-liabilities' baseUrl={baseUrl}>
        Assets
      </Card.Header>

      {
        !!client?.assets?.length && (
          <ClientCardTable
            {...assetsCardTableProps}
            data={client.assets}
            comments={client.assetsNotesAndComments}
          />
        )
      }

      <PageDivider />

      <Card.Header>Liabilities</Card.Header>

      {
        !!client?.liabilities?.length && (
          <ClientCardTable
            {...liabilitiesCardTableProps}
            data={client.liabilities}
            comments={client.liabilitiesNotesAndComments}
          />
        )
      }

    </Card>

    <Card className='client-details-page__client-details__objectives-and-actions'>
      <Card.Header allowToNavigate={canEdit} id='objectives-and-actions' baseUrl={baseUrl}>
        Objectives
      </Card.Header>
      <Summary marginEachRow={1} data={[{ label: 'Notes taken', value: client.objectives }]} />
      <PageDivider />
      <Card.Header>Agreed actions</Card.Header>
      <Summary
        data={[{ label: 'Notes taken', value: client.actions }]}
      />
    </Card>

    <Card className='client-details-page__client-details__financial-advisers'>
      <Card.Header allowToNavigate={!isClientPortal && canEdit} id='financial-advisers' baseUrl={baseUrl}>
        Financial adviser
      </Card.Header>
      <Summary
        marginEachRow={2}
        data={[
          {
            label: 'Current',
            value: client.financialAdviser ? client.financialAdviser.fullName : ''
          }
        ]}
      />
    </Card>
  </>
)

export { ClientDetailsBasePageCards }
