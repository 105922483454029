import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { PolicyPage } from './PolicyPage'
import { Spinner, ErrorMessage } from '../../misc'
import { removeCommonFieldsAliases } from '../../../helpers/document'
import { formatPolicyHolders } from './helpers'

import policyGql from '../../../graphql/queries/policy/policy.graphql'
import clientPolicyGql from '../../../graphql/queries/policy/clientPolicy.graphql'

import { withCurrentClient } from '../../hoc/container'

const PolicyPageContainer = ({
  currentClient,
  isClientPortal,
  ...restProps
}) => {
  const isClientPolicy = isClientPortal
  const params = useParams()

  const [getPolicy, { loading, error, data }] = useLazyQuery(
    !isClientPolicy ? policyGql : clientPolicyGql,
    {
      variables: { id: params?.policyId },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  useEffect(() => {
    if (params?.policyId) {
      getPolicy()
    }
  }, [params])

  if (loading && !data) {
    return <Spinner />
  }

  if (error) {
    return <ErrorMessage text='Failed to retrieve policy' />
  }

  const policyWithHolders = data?.policy && formatPolicyHolders({
    policy: removeCommonFieldsAliases(data?.policy),
    currentClient
  })

  return (
    <PolicyPage {...{
      policy: policyWithHolders,
      hasReconciledCharges: data?.hasReconciledCharges,
      currentClient,
      isClientPolicy,
      ...restProps
    }}
    />
  )
}

const EnhancedPolicyPageContainer = withCurrentClient()(
  PolicyPageContainer
)

export { EnhancedPolicyPageContainer as PolicyPageContainer }
