"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FieldErrorCodes = exports.ErrorCodes = void 0;
const ErrorCodes = exports.ErrorCodes = Object.freeze({
  // Common
  DUPLICATE: 'DUPLICATE',
  FORBIDDEN: 'FORBIDDEN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_INPUT: 'INVALID_INPUT',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  NOT_FOUND: 'NOT_FOUND',
  REF_ENTITY_ACCESS_DENIED: 'REF_ENTITY_ACCESS_DENIED',
  REF_ENTITY_NOT_FOUND: 'REF_ENTITY_NOT_FOUND',
  NO_FIELDS_TO_UPDATE: 'NO_FIELDS_TO_UPDATE',
  // User
  EMAIL_USED_IN_COMPANY: 'EMAIL_USED_IN_COMPANY',
  INVALID_AUTH_CREDENTIALS: 'INVALID_AUTH_CREDENTIALS',
  USER_ACCOUNT_DISABLED: 'USER_ACCOUNT_DISABLED',
  INVALID_INVITATION_TOKEN: 'INVALID_INVITATION_TOKEN',
  CANNOT_ENABLE_OR_DISABLE_OWN_ACCOUNT: 'CANNOT_ENABLE_OR_DISABLE_OWN_ACCOUNT',
  NO_PERMISSIONS_SET: 'NO_PERMISSIONS_SET',
  NUMBER_OF_USER_LICENCES_EXCEEDED: 'NUMBER_OF_USER_LICENCES_EXCEEDED',
  CANNOT_DOWNGRADE_OWN_ACCOUNT: 'CANNOT_DOWNGRADE_OWN_ACCOUNT',
  // Company
  INVALID_SUBDOMAIN: 'INVALID_SUBDOMAIN',
  SUBDOMAIN_ALREADY_USED: 'SUBDOMAIN_ALREADY_USED',
  SUBDOMAIN_RESERVED: 'SUBDOMAIN_RESERVED',
  // Client
  INVALID_NI_NUMBER: 'INVALID_NI_NUMBER',
  NI_NUMBER_ALREADY_USED: 'NI_NUMBER_ALREADY_USED',
  LEI_ALREADY_USED: 'LEI_ALREADY_USED',
  CANNOT_REMOVE_CLIENT: 'CANNOT_REMOVE_CLIENT',
  // Policy
  CANNOT_DELETE_RECONCILED_POLICY: 'CANNOT_DELETE_RECONCILED_POLICY',
  POLICY_NUMBER_ALREADY_USED: 'POLICY_NUMBER_ALREADY_USED',
  // Policy transaction
  CANNOT_UPDATE_WHEN_USED_BY_CHARGE: 'CANNOT_UPDATE_WHEN_USED_BY_CHARGE',
  CANNOT_REMOVE_WHEN_USED_BY_CHARGE: 'CANNOT_REMOVE_WHEN_USED_BY_CHARGE',
  // Charge
  AMOUNT_OR_PERCENTAGE_IS_REQUIRED: 'AMOUNT_OR_PERCENTAGE_IS_REQUIRED',
  CLIENT_OR_POLICY_IS_REQUIRED: 'CLIENT_OR_POLICY_IS_REQUIRED',
  CANNOT_SET_AMOUNT_AND_PERCENTAGE: 'CANNOT_SET_AMOUNT_AND_PERCENTAGE',
  CANNOT_BIND_TO_CLIENT_AND_POLICY: 'CANNOT_BIND_TO_CLIENT_AND_POLICY',
  END_DATE_BEFORE_LAST_RECONCILIATION: 'END_DATE_BEFORE_LAST_RECONCILIATION',
  UPDATE_AFTER_RECONCILIATION: 'UPDATE_AFTER_RECONCILIATION',
  REMOVAL_AFTER_RECONCILIATION: 'REMOVAL_AFTER_RECONCILIATION',
  // Income
  ALREADY_RECONCILED: 'ALREADY_RECONCILED',
  ALREADY_UNRECONCILED: 'ALREADY_UNRECONCILED',
  // Payments
  STRIPE_PRICE_INVALID: 'STRIPE_PRICE_INVALID',
  INVALID_WEBHOOK_SIGNATURE: 'INVALID_WEBHOOK_SIGNATURE',
  WEBHOOK_PROCESSING_ERROR: 'WEBHOOK_PROCESSING_ERROR',
  FAILED_TO_CREATE_SUBSCRIPTION_SESSION: 'FAILED_TO_CREATE_SUBSCRIPTION_SESSION',
  CANNOT_UPDATE_INACTIVE_SUBSCRIPTION: 'CANNOT_UPDATE_INACTIVE_SUBSCRIPTION',
  LESS_SEATS_THAN_ENABLED_USERS: 'LESS_SEATS_THAN_ENABLED_USERS',
  SUBSCRIPTION_ALREADY_EXISTS: 'SUBSCRIPTION_ALREADY_EXISTS',
  // Data import
  DATA_IMPORT_FILE_NOT_SUPPORTED: 'DATA_IMPORT_FILE_NOT_SUPPORTED',
  DATA_IMPORT_PLATFORM_NOT_CONNECTED: 'DATA_IMPORT_PLATFORM_NOT_CONNECTED',
  // E-Signatures
  E_SIGNATURE_ALREADY_EXISTS: 'E_SIGNATURE_ALREADY_EXISTS',
  E_SIGNATURE_INVALID_DOCUMENT_TYPE: 'E_SIGNATURE_INVALID_DOCUMENT_TYPE',
  // workflow
  WORKFLOW_TEMPLATE_NAME_ALREADY_EXISTS: 'WORKFLOW_TEMPLATE_NAME_ALREADY_EXISTS',
  TASK_DELETION_NOT_ALLOWED: 'TASK_DELETION_NOT_ALLOWED',
  // Service Level
  SERVICE_LEVEL_NAME_OR_COLOUR_ALREADY_EXISTS: 'SERVICE_LEVEL_NAME_OR_COLOUR_ALREADY_EXISTS'
});
const FieldErrorCodes = exports.FieldErrorCodes = Object.freeze({
  MISSING_VALUE: 'MISSING_VALUE',
  INVALID_VALUE: 'INVALID_VALUE'
});
var _default = exports.default = {
  ErrorCodes,
  FieldErrorCodes
};