import PropTypes from 'prop-types'

import './NoItems.less'

const NoItems = ({ children }) => (
  <div className='no-items'>
    <div className='no-items__content'>
      {children}
    </div>
  </div>
)

NoItems.propTypes = {
  children: PropTypes.node
}

export default NoItems
