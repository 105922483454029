import T from 'prop-types'
import { isEmpty } from 'ramda'

import { Spinner, Table, TableResponsiveWrapper } from '../../misc'
import { ClientsListTableHeader, ClientsListActionHeader } from './ClientsListTableHeader'
import { ClientsListTableRow } from './ClientsListTableRow/ClientsListTableRow'
import { ClientsSectionEmpty } from '../ClientsSection/ClientsSectionEmpty'

import './ClientsListTable.less'

const ClientsListTable = (props) => {
  const { clients = [], loading = false, ...rest } = props

  return (
    <div className='clients-list__table'>
      <>
        <ClientsListActionHeader {...rest} />
        {loading && <Spinner />}
        {!loading && !isEmpty(clients) && (
          <TableResponsiveWrapper>
            <Table>
              <colgroup>
                <col className='clients-list__table__columns__name' />
                <col className='clients-list__table__columns__email' />
                <col className='clients-list__table__columns__mobile' />
                <col className='clients-list__table__columns__adviser' />
                <col className='clients-list__table__columns__actions' />
                <col className='clients-list__table__columns__mobile-actions' />
              </colgroup>

              <Table.Header>
                <Table.Row>
                  {Object.values(ClientsListTableHeader)?.map((item, index) => (
                    <Table.HeaderCell key={index}>
                      {item}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {!loading && clients?.map((client, index) => (
                  <ClientsListTableRow key={index} client={client} />
                ))}
              </Table.Body>
            </Table>
          </TableResponsiveWrapper>
        )}
        {!loading && <ClientsSectionEmpty hidden={!!clients?.length} />}
      </>
    </div>
  )
}

ClientsListTable.propTypes = {
  clients: T.array.isRequired,
  loading: T.bool.isRequired
}

export { ClientsListTable }
