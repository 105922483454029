import { useEffect, useState, useMemo } from 'react'
import { pipe, prop } from 'ramda'
import { useLazyQuery } from '@apollo/client'
import { withCurrentUser } from '../../hoc/container'
import {
  CompanyUserPermissions,
  CompanyUserRoles
} from '@elenfs/elen-constants'

import { IncomeCard } from './IncomeCard'

import { getFromToDateByDateRange } from './../../../helpers/filterDate'
import {
  filterDateRangeOptions,
  setStartOfEndOfDay
} from './helpers'

import companyIncomeTotalsGql from '../../../graphql/queries/reports/companyIncomeTotals.graphql'

const { INCOME_ALL } = CompanyUserPermissions

const IncomeCardContainer = ({ currentUser }) => {
  const { isAdmin, permissions, role } = currentUser || {}
  const isAdviser = role === CompanyUserRoles.ADVISER

  const [chartProps, setChartProps] = useState({})
  const [filter, setFilter] = useState()

  const [getIncomeData, { data, loading }] = useLazyQuery(
    companyIncomeTotalsGql,
    { fetchPolicy: 'cache-and-network' }
  )

  const initialLoading = loading && !data

  useEffect(() => {
    if (filter) {
      getIncomeData({ variables: { ...filter } })
    }
  }, [filter, getIncomeData])

  const filterProps = useMemo(
    () => ({
      filterOptions: filterDateRangeOptions,
      onFilter: pipe(
        prop('dateRange'),
        getFromToDateByDateRange,
        setStartOfEndOfDay,
        setFilter
      )
    }),
    []
  )

  const { companyIncome, myIncome } = data?.companyIncomeTotals || {}

  useEffect(() => {
    if (!initialLoading) {
      setChartProps(() => {
        const chartProps = {}
        if (companyIncome) {
          chartProps.companyIncome = companyIncome
        }
        if (myIncome) {
          chartProps.myIncome = myIncome
        }
        return chartProps
      })
    }
  }, [myIncome, companyIncome, initialLoading])

  const showIncomeCard = isAdmin ||
    (isAdviser || permissions?.map(prop('name'))?.includes(INCOME_ALL))

  if (!showIncomeCard) {
    return null
  }

  return (
    <IncomeCard
      {...{
        ...chartProps,
        filterProps,
        loading: initialLoading,
        initialLoading: !Object.keys(chartProps).length
      }}
    />
  )
}

const EnhancedIncomeCardContainer = withCurrentUser()(IncomeCardContainer)

export { EnhancedIncomeCardContainer as IncomeCardContainer }
