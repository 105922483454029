import { useState, useEffect, useCallback } from 'react'
import { groupBy, prop } from 'ramda'
import { TaskStatuses } from '@elenfs/elen-constants'
import { useMutation } from '@apollo/client'

import updateTaskGql from '../../../graphql/mutations/tasks/updateTask.graphql'

import {
  useTasksGql,
  useTasksModal,
  useLocalStorageUserSettings,
  localStorageUserSettingsKeys
} from '../../../hooks'
import { withCurrentUser } from './../../hoc/container'
import { TasksKanbanBoard } from './TasksKanbanBoard'

import './TasksKanbanBoard.less'

const { TO_DO, IN_PROGRESS, COMPLETED } = TaskStatuses

const TasksKanbanBoardContainer = ({ currentUser }) => {
  const { userSettingData, persistUserSettingsData } = useLocalStorageUserSettings(
    localStorageUserSettingsKeys.TASK_FILTER
  )

  const [queryFilters, setQueryFilters] = useState(userSettingData)

  const onFilter = (values) => {
    setQueryFilters(values)
    persistUserSettingsData(values)
  }

  const {
    tasks,
    loadingTasks,
    errorTasks,

    createTask,
    loadingOnCreateTask,
    errorOnCreateTask,

    createSubtask,
    loadingOnCreateSubtask,
    errorOnCreateSubtask
  } = useTasksGql({
    queryFilters: { ...queryFilters, status: [TO_DO, IN_PROGRESS, COMPLETED] }
  })

  const taskModalProps = useTasksModal({ tasks })

  const { onTaskClick, openNewTaskModal } = taskModalProps

  const [updatedTaskId, setUpdatedTaskId] = useState('')
  const [errorsOnCreateSubtask, setErrorsOnCreateSubtask] = useState({})

  const onCreateSubtask = useCallback(async ({ summary, taskId: parentId }) => {
    setUpdatedTaskId(parentId)
    await createSubtask({ variables: { input: { summary, parentId } } })
  }, [])

  useEffect(
    () =>
      setErrorsOnCreateSubtask(current => ({
        ...current,
        [updatedTaskId]: errorOnCreateSubtask?.message
      })),
    [errorOnCreateSubtask]
  )

  const onCreateTask = useCallback(
    input => createTask({ variables: { input: { ...input } } }),
    [createTask]
  )

  const filterProps = {
    onSubmit: onFilter,
    tasksFilter: userSettingData
  }

  const tableProps = {
    loadingTasks,
    errorTasks,
    loadingOnCreateTask,
    errorOnCreateTask: errorOnCreateTask?.message || '',
    onCreateTask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    onCreateSubtask,
    onTaskClick,
    currentUser
  }

  const groupedTasksByStatus = groupBy(prop('status'))(tasks)

  const [mutate] = useMutation(updateTaskGql)

  const completedTasks = groupedTasksByStatus[COMPLETED]
  const isViewAllCompletedShown = completedTasks?.length > 10
  const first10completedTasks = isViewAllCompletedShown
    ? completedTasks.slice(0, 10)
    : completedTasks

  return (
    <TasksKanbanBoard
      {...{
        mutate,
        filterProps,
        tableProps,
        taskModalProps: { ...taskModalProps, queryFilters },
        openNewTaskModal,
        tasksFilter: userSettingData,
        saveTasksFilter: persistUserSettingsData,
        toDoTasks: groupedTasksByStatus[TO_DO],
        inProgressTasks: groupedTasksByStatus[IN_PROGRESS],
        completedTasks: first10completedTasks,
        isViewAllCompletedShown
      }}
    />
  )
}

const TasksKanbanBoardContainerWithCurrentUser = withCurrentUser()(TasksKanbanBoardContainer)

export { TasksKanbanBoardContainerWithCurrentUser as TasksKanbanBoardContainer }
