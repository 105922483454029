import SideMenuNavItem from './SideMenuNavItem'

import './SideMenuNav.less'

const SideMenuNav = ({ urlPrefix, items, onItemClick }) => (
  <nav className='mobile-side-menu__nav'>
    {items.map((item) => {
      const { path } = item

      return (
        <SideMenuNavItem
          key={path}
          path={path}
          item={item}
          onItemClick={onItemClick}
        />
      )
    })}
  </nav>
)

export default SideMenuNav
