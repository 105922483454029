import { useCallback } from 'react'
import { FieldArray, connect } from 'formik'
import T from 'prop-types'
import { useNavigate } from 'react-router-dom'

import Card from '../../misc/Card'
import { FieldArrayItem } from '../../form'
import FormFieldsCard from '../../form/FormFieldsCard'
import AddNewFormRowButton from '../../misc/AddNewFormRowButton'
import { ClientDependantsFormInputRow } from './ClientDependantsFormInputRow'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'

import { validateAndNavigate } from '../../../helpers/client'
import clientDependantsSchema from '../../../schemas/client/clientDependantsSchema'

import './ClientDependantsForm.less'

const buildDependantRowInput = () => ({
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  relationship: '',
  dependantFrom: '',
  dependantUntil: '',
  note: ''
})

const ClientDependantsForm = ({ formik, nextLink, prevLink, setValidationSchema }) => {
  const navigate = useNavigate()
  const {
    values: { dependants = [] },
    validateForm
  } = formik

  const handleNavButtonClick = useCallback((isNext) => {
    validateAndNavigate({
      setSchema: setValidationSchema,
      schema: clientDependantsSchema,
      validate: validateForm,
      nextLocation: isNext ? nextLink : prevLink,
      navigate
    })
  }, [navigate, nextLink, prevLink, validateForm, clientDependantsSchema, setValidationSchema])

  return (
    <Card className='dependants-form'>
      <FormFieldsCard title='Dependants'>
        <FieldArray
          validateOnChange={false}
          name='dependants'
          render={arrayHelpers => (
            <div className='dependants-input-rows-container'>
              {dependants.map((dependants, index) => (
                <FieldArrayItem
                  key={index}
                  index={index}
                  label='Dependant'
                  onRemove={() => arrayHelpers.remove(index)}
                >
                  <ClientDependantsFormInputRow
                    index={index}
                    arrayHelpers={arrayHelpers}
                  />
                </FieldArrayItem>
              ))}
              <AddNewFormRowButton
                label='add dependants'
                onClick={() => arrayHelpers.push(buildDependantRowInput())}
              />
            </div>
          )}
        />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}

const EnhancedClientDependantsForm = connect(ClientDependantsForm)

EnhancedClientDependantsForm.propTypes = {
  nextLink: T.string,
  prevLink: T.string
}

export { EnhancedClientDependantsForm as ClientDependantsForm }
