import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import DocumentsTable from './DocumentsTable'
import policiesTableGql from '../../../graphql/queries/policy/policiesTable.graphql'

const DocumentsTableContainer = (props) => {
  const { clientId } = useParams()

  const { loading, error, data } =
  useQuery(policiesTableGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  const policies = (loading || error) ? null : data.policies

  return (
    <DocumentsTable
      policies={policies}
      loading={loading}
      error={error}
      {...props}
    />
  )
}

export default DocumentsTableContainer
