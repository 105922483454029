/**
 * This module is meant for HOCs which don't communicate with the backend.
 */

export { updateAnchor } from './UpdateAnchor'
// all of the components below are DEPRECATED.
// Use alternatives from hooks, form/fields, form/inputs and similar.
export { withIcon } from './WithIcon'
export { withMutationState } from './WithMutationState'
export { withNoItems } from './WithNoItems'
