import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { PolicyValuationDetailsForm } from './PolicyValuationDetailsForm'

import createPolicyValuationGql from '../../../../../graphql/mutations/policy/policyValuations/createPolicyValuation.graphql'
import updatePolicyValuationGql from '../../../../../graphql/mutations/policy/policyValuations/updatePolicyValuation.graphql'
import policyValuationsGql from '../../../../../graphql/queries/policy/policyValuations.graphql'
import { LIMIT_NUMBERS_OF_VALUATIONS } from '../../constants'

const createPolicyValuation = (policyId) => useMutation(
  createPolicyValuationGql, {
    update: (cache, { data }) => {
      try {
        const { createPolicyValuation: valuation } = data
        const { policyValuations, ...restCache } = cache.readQuery({
          query: policyValuationsGql,
          variables: { policyId, limit: LIMIT_NUMBERS_OF_VALUATIONS }
        })
        cache.writeQuery({
          query: policyValuationsGql,
          variables: { policyId, limit: LIMIT_NUMBERS_OF_VALUATIONS },
          data: {
            ...restCache,
            policyValuations: policyValuations.concat([valuation])
          }
        })
      } catch (error) {
        // pass
      }
    }
  }
)

const PolicyValuationDetailsFormContainer = props => {
  const { policyId } = useParams()

  const { policyValuation } = props
  const isUpdate = policyValuation && policyValuation.id

  let useMutationResult
  if (!isUpdate) {
    useMutationResult = createPolicyValuation(policyId)
  } else {
    useMutationResult = useMutation(updatePolicyValuationGql)
  }

  const [mutate, mutationState] = useMutationResult

  return (
    <PolicyValuationDetailsForm {...{
      mutate,
      mutationState,
      policyId,
      ...props
    }}
    />
  )
}

export { PolicyValuationDetailsFormContainer }
