import { Link } from 'react-router-dom'
import T from 'prop-types'

import { ChevronLeftIcon } from '../../icons'

import './PreviousButton.less'

const PreviousButton = ({ to, onClick }) => (
  <>
    {onClick
      ? (
        <div className='previous-button' onClick={onClick}>
          <ChevronLeftIcon />
          <div>Previous</div>
        </div>
        )
      : (
        <Link className='previous-button' to={to} replace>
          <ChevronLeftIcon />
          <div>Previous</div>
        </Link>
        )}
  </>
)

PreviousButton.propTypes = {
  to: T.string,
  onClick: T.func
}

export default PreviousButton
