import PropTypes from 'prop-types'
import classnames from 'classnames'

const SummaryRow = ({ children, className }) => (
  <div className={classnames('summary__row', className)}>
    {children}
  </div>
)

SummaryRow.propTypes = {
  children: PropTypes.node.isRequired
}

export default SummaryRow
