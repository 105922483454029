import { useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import pendingIncomeGql from '../../../graphql/queries/income/pendingIncome.graphql'
import pendingIncomeTotalGql from '../../../graphql/queries/income/pendingIncomeTotal.graphql'
import { INCOME_TYPES } from '../../../constants'

import { PendingIncome } from './PendingIncome'
import { ErrorMessage } from '../../misc'
import { paginationLimit } from '../constant'

const PendingIncomeContainer = () => {
  const [currentFilter, setCurrentFilter] = useState()
  const [incomeRecords, setIncomeRecords] = useState([])

  const [paginationData, setPaginationData] = useState({
    page: 0,
    perPage: 1,
    count: null
  })

  const limit = paginationLimit
  const pageCount = Math.ceil(paginationData?.count / limit)

  const handleOnCompleteCallback = (data) => {
    const { result, ...rest } = data?.companyIncome
    setIncomeRecords(data?.companyIncome?.result)
    setPaginationData((prevState) => ({
      ...prevState, ...rest
    }))
  }

  const [getPendingIncomes, {
    loading,
    error,
    data,
    called,
    fetchMore
  }] = useLazyQuery(
    pendingIncomeGql, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onCompleted: (data) => handleOnCompleteCallback(data)
    }
  )

  const [getPendingIncomesTotal, {
    loading: incomeTotalLoading,
    error: incomeTotalError,
    data: incomeTotalData,
    called: incomeTotalCalled,
    refetch
  }] = useLazyQuery(
    pendingIncomeTotalGql, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  const incomeTotal = incomeTotalData?.companyIncomeTotal || {}
  const initialLoading = loading && !data

  const onFilter = useCallback(
    filter => {
      getPendingIncomes({ variables: { limit, offset: 0, ...filter } })
      getPendingIncomesTotal({ variables: filter })
      setCurrentFilter(filter)
    },
    [getPendingIncomes, getPendingIncomesTotal, setCurrentFilter]
  )

  const handlePaginationChange = useCallback(
    (page) => {
      const offset = Math.ceil(Math.abs(limit * page))
      fetchMore({
        variables: {
          limit,
          offset,
          ...currentFilter
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.companyIncome) return prev
          return { ...prev, ...fetchMoreResult }
        }
      })
      refetch(currentFilter)
    },
    [fetchMore, currentFilter]
  )

  if (error || incomeTotalError) {
    return <ErrorMessage text='Failed to load pending income' />
  }

  return (
    <PendingIncome
      {...{
        loading: initialLoading,
        incomeRecords,
        incomeTotal,
        incomeTotalCalled,
        incomeTotalLoading,
        pageCount,
        handlePaginationChange,
        incomeType: INCOME_TYPES.EXPECTED,
        onFilter,
        currentFilter,
        called
      }}
    />
  )
}

export { PendingIncomeContainer }
