import { useState, useEffect } from 'react'

const MOBILE_BREAK_POINT = 991

export const useMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const handleWindowSizeChange = () => setWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => window.removeEventListener('resize', handleWindowSizeChange)
  }, [])

  const isMobile = width <= MOBILE_BREAK_POINT

  return { isMobile }
}
