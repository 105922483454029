import T from 'prop-types'

import PageContent from '../../../misc/PageContent'
import UsersTable from '../UsersTable'
import { NewUserButton } from '../NewUserButton'
import { TableResponsiveActionHeader } from '../../../misc/StylePlaceholders'
import Heading from '../../../misc/Heading'

import './Users.less'

const Users = props => {
  const { usersProps, currentUser, seatsCount } = props

  const activeUsersCount = usersProps?.activeUserList?.length || 0

  return (
    <PageContent>
      <div className='users'>
        <TableResponsiveActionHeader>
          <div className='users__header'>
            <Heading as='h4' className='users__header__text' italic bold={false}>
              Active users: {activeUsersCount}, remaining licenses: {seatsCount - activeUsersCount}
            </Heading>
          </div>
          <NewUserButton className='users__header__button' />
        </TableResponsiveActionHeader>
        <UsersTable
          currentUser={currentUser}
          {...usersProps}
        />
      </div>
    </PageContent>
  )
}

Users.propTypes = {
  currentUser: T.object,
  usersProps: T.shape({
    users: T.arrayOf(T.object),
    activeList: T.arrayOf(T.object),
    inactiveUserList: T.arrayOf(T.object)
  }),
  seatsCount: T.number.isRequired
}

export default Users
