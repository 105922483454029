import Proptypes from 'prop-types'

import PlusIcon from '../../icons/Plus'
import './AddNewFormRowButton.less'

const AddNewFormRowButton = ({ label, onClick }) => (
  <div
    className='add-new-form-row-button'
    onClick={onClick}
  >
    <PlusIcon />
    <span> {label} </span>
  </div>
)

AddNewFormRowButton.propTypes = {
  label: Proptypes.string.isRequired,
  onClick: Proptypes.func.isRequired
}

export default AddNewFormRowButton
