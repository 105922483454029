const ExclamationError = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx={12} cy={12} r={12} fill='#EC2A2A' opacity={0.2} />
      <path d='M2.4 2.4h19.2v19.2H2.4z' />
      <path
        d='M12 16.96a.96.96 0 110 1.92.96.96 0 010-1.92zM12 4.8a.8.8 0 01.8.8v8a.8.8 0 11-1.6 0v-8a.8.8 0 01.8-.8z'
        fill='#EC2A2A'
      />
    </g>
  </svg>
)

export default ExclamationError
