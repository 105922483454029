const AccountEditIcon = (props) => (
  <svg width={24} height={24} {...props}>
    <title>Icon/24/Account_edit_24</title>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M16.825 13.364a1.974 1.974 0 0 1 2.805.001c.773.78.773 2.049 0 2.829l-3.623 3.646a.66.66 0 0 1-.29.17l-2.67.746a.655.655 0 0 1-.649-.177.67.67 0 0 1-.16-.66l.791-2.624a.668.668 0 0 1 .166-.278Zm-.13 2.014-2.449 2.465-.393 1.304 1.343-.375 2.435-2.45-.935-.944Zm-2.528-2.711a.833.833 0 1 1 0 1.666H8.333c-.878 0-1.598.68-1.662 1.543L6.667 16v1.667a.833.833 0 0 1-1.661.097L5 17.667V16a3.333 3.333 0 0 1 3.167-3.33l.166-.003h5.834Zm4.528 1.64a.658.658 0 0 0-.935 0l-.128.13.935.942.128-.129a.671.671 0 0 0 0-.942ZM11.667 3.5a4.167 4.167 0 1 1 0 8.333 4.167 4.167 0 0 1 0-8.333Zm0 1.667a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { AccountEditIcon }
