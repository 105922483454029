import * as yup from 'yup'
import stringSchema from '../helpers/stringSchema'
import dateSchema from '../helpers/dateSchema'
import moneySchema from '../helpers/moneySchema'

export default yup.object().shape({
  assets: yup.array().of(yup.object().shape({
    id: stringSchema(),
    owner: stringSchema()
      .label('Assets & Liabilities section: Owner')
      .required(),

    description: stringSchema()
      .label('Assets & Liabilities section: Asset description')
      .required(),

    value: moneySchema({
      amountLabel: 'Value',
      currencyLabel: 'Value currency'
    }).label('Assets & Liabilities section: Value').required()
  })).default([]),

  assetsNotesAndComments: stringSchema()
    .label('Assets & Liabilities section: Additional notes and comments'),

  liabilities: yup.array().of(yup.object().shape({
    id: stringSchema(),
    owner: stringSchema()
      .label('Assets & Liabilities section: Owner')
      .required(),

    liabilityType: stringSchema()
      .label('Assets & Liabilities section: Liability type'),

    outstandingValue: moneySchema({
      amountLabel: 'Outstanding value',
      currencyLabel: 'Outstanding value currency'
    }).label('Assets & Liabilities section: Outstanding value').required(),

    monthlyCost: moneySchema({
      amountLabel: 'Monthly cost',
      currencyLabel: 'Monthly cost currency'
    }),

    startDate: dateSchema()
      .label('Assets & Liabilities section: Start date'),

    endDate: dateSchema()
      .label('Assets & Liabilities section: End date')

  })).default([]),

  liabilitiesNotesAndComments: stringSchema()
    .label('Assets & Liabilities section: Additional notes and comments')
})
