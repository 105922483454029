import T from 'prop-types'
import classnames from 'classnames'

import { Checkbox } from '../../../misc'
import { useOnChangeCallback } from '../../inputs/hooks'
import { Form } from '../../Form'

import './CheckboxField.less'

const extractValueFunction = (event) => event.target.checked

const CheckboxField = ({ label, value, handleChange, inputId, error, className, inputProps, ...restProps }) => {
  return (
    <Form.Field error={error} className={classnames(className, 'checkbox-field')} {...restProps} onClick={e => e.stopPropagation()}>
      <Checkbox
        className='checkbox-field__checkbox'
        checked={value}
        onChange={useOnChangeCallback(handleChange, extractValueFunction)}
        inputId={inputId}
        {...inputProps}
      />
      {label && (
        <label className='checkbox-field__label' htmlFor={inputId}>
          {label}
        </label>
      )}
    </Form.Field>
  )
}

CheckboxField.propTypes = {
  /**
   * Label for the checkbox.
   */
  label: T.node,
  /**
   * True or false.
   */
  value: T.bool,
  /**
   * Function that handles the value changes.
   */
  handleChange: T.func,
  /**
   * InputId. Needed for the label to be clickable.
   */
  inputId: T.string.isRequired,
  /**
   * Error associated with the field.
   */
  error: T.string,
  /**
   * Classname for the field.
   */
  className: T.string,
  /**
   * Props for the Checkbox.
   */
  inputProps: T.object
}

export { CheckboxField }
