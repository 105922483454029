import classnames from 'classnames'
import { path } from 'ramda'

import { ActivityLogEntryTypesLabels } from '../../../helpers/enumLabels/auditLogEntry'
import { formatDate } from '../../../helpers/date'

import { TaskIcon } from '../../misc'

import './AuditLogEntryItem.less'

const AuditLogEntryItem = ({ showExpanded, editable, auditLogEntry, onClick, className }) => {
  const isUpdated = auditLogEntry.createdAt !== auditLogEntry.updatedAt
  const showUpdated = isUpdated && showExpanded

  const change = showUpdated ? 'Edited ' : 'Added '
  const by = path([showUpdated ? 'updatedBy' : 'createdBy', 'fullName'], auditLogEntry)
  const dateUnformatted = showExpanded
    ? (isUpdated ? auditLogEntry.updatedAt : auditLogEntry.createdAt)
    : auditLogEntry.date
  const date = formatDate(dateUnformatted, 'DD MMM YYYY')
  const time = formatDate(dateUnformatted, 0, 'HH:mm')
  const type = ActivityLogEntryTypesLabels[auditLogEntry.type]

  return (
    <div className={classnames('audit-log-entry-item-form-wrapper', className)}>
      <div className='audit-log-entry'>
        <div className={classnames('audit-log-entry__item', { editable })} onClick={onClick}>
          <div className='audit-log-entry__item__content'>
            <TaskIcon type={auditLogEntry?.type} />
            <div>
              <div className='audit-log-entry__item__content__summary'>
                <span>{type}</span>
                {by && <span>{by}</span>}
                <span>{`${showExpanded ? change : ''}${date} - ${time}`}</span>
              </div>
              <div className='audit-log-entry__item__content__note'>
                {auditLogEntry.summary}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

AuditLogEntryItem.defaultProps = {
  showExpanded: false
}

export default AuditLogEntryItem
