import { useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router, Routes, Navigate, Route } from 'react-router-dom'
import constants from '@elenfs/elen-constants'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from './appConfig'
import LoginPage from './components/user/LoginPage'
import { withCurrentUser } from './components/hoc/container'
import RecoverPasswordPage from './components/user/RecoverPasswordPage'
import RecoverPasswordSuccessPage from './components/user/RecoverPasswordSuccessPage'
import ForgotPasswordPage from './components/user/ForgotPasswordPage'
import SecureSection from './components/misc/SecureSection'
import { MODAL_CONTAINER_ID } from './components/misc/Modal'
import { client } from './graphql/client'
import CompanyUserSignupPage from './components/admin/Users/CompanyUserSignupPage'
import { CookieConsentModal } from './components/misc/CookieConsentModal/CookieConsentModal'
import { useCookieConsent } from './components/misc/CookieConsentModal/useCookieConsent'
import { SIDE_PANEL_CONTAINER_ID } from './components/misc/SidePanel'
import {
  EmailIntegrationWaitingPage,
  CashCalcWaitingPage,
  FileUploadTracker,
  ToastifyContainer,
  ClientUserSection,
  NotFoundPage,
  Spinner
} from './components/misc'
import { IntegrationPlatforms } from './constants'

const { UserTypes } = constants.user

const Index = ({ currentUser }) => {
  if (!currentUser) {
    return <Navigate to='/login' />
  }

  if (currentUser.type === UserTypes.COMPANY_USER) {
    return <Navigate to='/secure' />
  }

  if (currentUser.type === UserTypes.CLIENT) {
    return <Navigate to='/clients' />
  }

  return null
}

const AppSwitch = props => {
  const { currentUser, isCurrentUserLoading } = props
  const [stripePromise] = useState(() => {
    const initialState = loadStripe(config.STRIPE_PUBLISHABLE_KEY)
    return initialState
  })

  if (isCurrentUserLoading) {
    return <Spinner />
  }

  return (
    <Routes>
      <Route index element={<Index currentUser={currentUser} />} />
      <Route
        path='/secure/*'
        element={
          <Elements stripe={stripePromise}>
            <SecureSection {...props} />
          </Elements>
        }
      />
      <Route path='clients/*' element={<ClientUserSection {...props} />} />
      <Route path='login' element={<LoginPage />} />
      <Route path='signup/:invitationToken' element={<CompanyUserSignupPage {...props} />} />
      <Route path='reset-password/success' element={<RecoverPasswordSuccessPage />} />
      <Route path='reset-password/:token' element={<RecoverPasswordPage />} />
      <Route path='forgot-password' element={currentUser ? <Navigate to='/' /> : <ForgotPasswordPage />} />
      <Route path='cashcalc' element={<CashCalcWaitingPage />} />
      <Route path='google' element={<EmailIntegrationWaitingPage platform={IntegrationPlatforms.GOOGLE} />} />
      <Route path='microsoft' element={<EmailIntegrationWaitingPage platform={IntegrationPlatforms.MICROSOFT} />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}

const AppSwitchEnhanced = withCurrentUser()(AppSwitch)

const CookieConsent = () => {
  const props = useCookieConsent(true)
  return <CookieConsentModal {...props} />
}

const App = () => (
  <>
    <ApolloProvider client={client}>
      <Router>
        <AppSwitchEnhanced />
      </Router>
      <CookieConsent />
    </ApolloProvider>
    <div id={MODAL_CONTAINER_ID} />
    <div id={SIDE_PANEL_CONTAINER_ID} />
    <ToastifyContainer />
    <FileUploadTracker />
  </>
)

export default App
