import T from 'prop-types'

import { ImportSources } from '../ImportSources'
import { ImportPageNote } from './ImportPageNote'
import { ImportPageHeading } from './ImportPageHeading'
import { PageContent } from '../../misc'

import './ImportPage.less'

const ImportPage = ({ dataImportSources }) => (
  <PageContent className='import-page'>
    <ImportPageHeading />
    <ImportSources items={dataImportSources} />
    <ImportPageNote />
  </PageContent>
)

ImportPage.propTypes = {
  dataImportSources: T.array.isRequired
}

export { ImportPage }
