import { Route, Routes } from 'react-router-dom'

import { ReportsPage } from '../ReportsPage'
import { NBRPage } from '../NBR'
import { ClientListReportPage } from '../ClientListReport'
import { ClientBirthdayReportPage } from '../ClientBirthdayReport'
import { TransactionReportPage } from '../TransactionReport'
import { MaturingPoliciesPage } from '../MaturingPolicies'
import { AssetsUnderManagementPage } from '../AssetsUnderManagement'
import { UpcomingReviewsPage } from '../UpcomingReviews'

import './ReportsSection.less'

const ReportsSection = ({ match }) => (
  <div className='reports-section'>
    <div className='reports-section__content'>
      <div className='reports-section__content__wrapper'>
        <Routes>
          <Route index element={<ReportsPage />} />
          <Route path='nbr' element={<NBRPage />} />
          <Route
            path='client-list'
            element={<ClientListReportPage />}
          />
          <Route
            path='client-birthdays'
            element={<ClientBirthdayReportPage />}
          />
          <Route
            path='contribution'
            element={<TransactionReportPage {...{ isContribution: true }} />}
          />
          <Route
            path='withdraw'
            element={<TransactionReportPage />}
          />
          <Route
            path='maturing-policies'
            element={<MaturingPoliciesPage />}
          />
          <Route
            path='assets-under-management'
            element={<AssetsUnderManagementPage />}
          />
          <Route
            path='upcoming-reviews'
            element={<UpcomingReviewsPage />}
          />
        </Routes>
      </div>
    </div>
  </div>
)

export { ReportsSection }
