import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ErrorPage, ErrorMessage, Toastr } from '../../misc'
import { IndividualClientDetailsPage } from '../IndividualClientDetailsPage'
import { CorporateClientDetailsPage } from '../../corporateClient/CorporateClientDetailsPage'
import Spinner from '../../misc/Spinner'
import clientPortalDetailsGql from '../../../graphql/queries/client/clientPortalDetails.graphql'
import createFactFindDocumentGql from '../../../graphql/mutations/clientDocument/createFactFindDocument.graphql'
import { isCorporateClient } from '../../../helpers/client'

const ClientDetailsPageError = () => <ErrorMessage text='Failed to retrieve client details' />

const ClientDetailsPageWithClient = (props) => {
  const params = useParams()
  const clientId = params.clientId || props?.clientId

  const { loading, error, data, refetch } = useQuery(clientPortalDetailsGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  const client = data?.client
  const DetailsPageComponent = isCorporateClient(client)
    ? CorporateClientDetailsPage
    : IndividualClientDetailsPage

  const [
    createFactFindDocument,
    { loading: mutationLoading }
  ] = useMutation(createFactFindDocumentGql, {
    variables: { clientId },
    onCompleted: () => toast(
      <Toastr
        type='success'
        title='Fact Find version successfully saved to documents'
      />
    )
  })

  if (loading && !data) return <Spinner />
  if (error) {
    return (
      <ErrorPage {...error}>
        <ClientDetailsPageError />
      </ErrorPage>
    )
  }

  const clientDetailsProps = {
    client,
    createFactFindDocument,
    mutationLoading,
    ...props
  }

  return <DetailsPageComponent refetchClientDetails={refetch} {...clientDetailsProps} />
}

const ClientDetailsPage = ({
  loadClient = true,
  canEdit = false,
  client,
  ...props
}) => {
  const detailsProps = { client, canEdit, ...props }

  const pageContent = isCorporateClient(client)
    ? <CorporateClientDetailsPage {...detailsProps} />
    : <IndividualClientDetailsPage {...detailsProps} />

  if (loadClient) {
    return (
      <ClientDetailsPageWithClient canEdit={canEdit} clientId={client?.id} {...props} />
    )
  } else {
    return pageContent
  }
}

export { ClientDetailsPage }
