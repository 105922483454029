export const PadlockIcon = (props) => (
  <svg viewBox='0 0 163 156' {...props}>
    <defs>
      <filter id='a' width='135.6%' height='1300%' x='-17.8%' y='-600%' filterUnits='objectBoundingBox'>
        <feGaussianBlur in='SourceGraphic' stdDeviation='6 0' />
      </filter>
      <filter id='b' width='112.8%' height='900%' x='-6.4%' y='-400%' filterUnits='objectBoundingBox'>
        <feGaussianBlur in='SourceGraphic' stdDeviation='2' />
      </filter>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g fill='#000' transform='translate(31 113)'>
        <rect width='101' height='3' filter='url(#a)' opacity='.05643136' rx='1.5' />
        <rect width='83' height='1.5' x='9' y='.75' opacity='.228014' rx='.75' />
        <rect width='94' height='1.5' x='4' y='.75' filter='url(#b)' opacity='.600024' rx='.75' />
      </g>
      <path
        fill='#F8F9FC'
        d='M120 36c4.9705627 0 9 4.0294373 9 9v60c0 4.970563-4.0294373 9-9 9H42c-4.97056275 0-9-4.029437-9-9V45c0-4.9705627 4.02943725-9 9-9h78z'
      />
      <path
        fill='#D1D1D8'
        d='M57 36c3.3137085 0 6 2.6862915 6 6 0 3.2383969-2.5655749 5.8775718-5.7750617 5.9958615L57 48H45v54h72V48H75c-3.2383969 0-5.8775718-2.5655749-5.9958615-5.7750617L69 42c0-3.2383969 2.5655749-5.8775718 5.7750617-5.9958615L75 36h45c4.8818027 0 8.8557914 3.8868137 8.9961629 8.7346377L129 45v60c0 4.881803-3.8868137 8.855791-8.7346377 8.996163L120 114H42c-4.8818027 0-8.85579142-3.886814-8.9961629-8.734638L33 105V45c0-4.8818027 3.88681368-8.8557914 8.73463775-8.9961629L42 36h15z'
      />
      <path
        fill='#4D70FF'
        d='M81 0c16.4028571 0 29.7310571 13.1641715 29.9959809 29.5038951L111 30v6H99v-6c0-9.9411255-8.0588745-18-18-18-9.8306685 0-17.8206815 7.8807833-17.9970237 17.6693348L63 30v12c0 3.3137085-2.6862915 6-6 6s-6-2.6862915-6-6V30C51 13.4314575 64.4314575 0 81 0z'
      />
      <g fill='#4D70FF' transform='translate(73 62)'>
        <rect width='10' height='20' x='3' y='6' rx='5' />
        <circle cx='8' cy='8' r='8' />
      </g>
    </g>
  </svg>
)
