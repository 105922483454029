import moment from 'moment'
import dateSchema from './dateSchema'

export const endDateSchema = (startDateName = 'startDate', errorMessage = 'End date must be after start date') => dateSchema().test({
  name: 'endAfterStart',
  message: errorMessage,
  test: function (endDate) {
    // yup will bind 'this'
    const startDate = this.parent[startDateName]
    return !startDate || !endDate || moment(startDate).isBefore(moment(endDate))
  }
})
