const HouseIcon = props => (
  <svg width={24} height={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M20.638 11.266l-8.002-8.002a.9.9 0 00-1.272 0l-8.1 8.1A.9.9 0 003.9 12.9h.9v5.4c0 1.489 1.211 2.7 2.7 2.7h9c1.489 0 2.7-1.211 2.7-2.7v-5.4h.909c.526.008.909-.4.909-.9a.898.898 0 00-.38-.734zM12.9 19.2h-1.8v-3.6h1.8v3.6zm4.5-7.2v6.3c0 .496-.404.9-.9.9h-1.8v-4.5a.9.9 0 00-.9-.9h-3.6a.9.9 0 00-.9.9v4.5H7.5a.901.901 0 01-.9-.9V12a.9.9 0 00-.584-.843L12 5.173l5.984 5.984A.9.9 0 0017.4 12z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { HouseIcon }
