import colorFn from 'less/lib/less/functions/color'
import Color from 'less/lib/less/tree/color'
// using less library methods

export const ligthen = color => {
  return colorFn.lighten(new Color(color), { value: 40 }, { value: 'relative' }).toRGB()
}

export const darken = color => {
  return colorFn.darken(new Color(color), { value: 10 }, { value: 'relative' }).toRGB()
}

export const generateRandomHexColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`
