import { useCallback } from 'react'
import { values } from 'ramda'
import { connect } from 'formik'
import constants from '@elenfs/elen-constants'

import FormFieldsCard from '../../form/FormFieldsCard'
import { FormGroup } from '../../form/Form/FormGroup'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import Card from '../../misc/Card'
import {
  MaritalStatusLabels,
  GenderLabels
} from '../../../helpers/enumLabels/client'
import { useNavigate } from 'react-router-dom'
import clientPersonalDetailsSchema from '../../../schemas/client/clientPersonalDetailsSchema'

import { SelectField, InputField, DateField, RadioGroupField } from '../../form/fields'
import { buildSelectOptions } from '../../form/fields/SelectField'
import { validateAndNavigate } from '../../../helpers/client'

import './ClientPersonalDetailsForm.less'

const { ClientTitles, Gender, MaritalStatus } = constants.client

const ClientPersonalDetailsForm = props => {
  const { setValidationSchema, nextLink, formik, isClientPortal } = props

  const navigate = useNavigate()
  const { validateForm } = formik

  const handleNextButtonClick = useCallback(() => {
    validateAndNavigate({
      setSchema: setValidationSchema,
      schema: clientPersonalDetailsSchema,
      validate: validateForm,
      nextLocation: nextLink,
      navigate
    })
  }, [
    navigate,
    validateForm,
    nextLink,
    clientPersonalDetailsSchema,
    setValidationSchema
  ])

  return (
    <Card className='personal-and-contacts-form'>
      <FormFieldsCard
        title='Personal'
        className='client-details-form__personal'
      >
        <FormGroup label='Basic Information'>
          <div className='personal-and-contacts-form__row'>
            <RadioGroupField
              inputId='client-personal-form-title-radio-group'
              options={values(ClientTitles).map(value => ({
                value,
                label: value
              }))}
              name='title'
              groupLabel='Title'
              isTextButton
            />
          </div>

          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <InputField name='firstName' label='First name' required />
            <InputField name='middleName' label='Middle name' />
            <InputField name='lastName' label='Last name' required />
          </div>

          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <InputField
              name='knownAs'
              label='Known as'
              inputProps={{ placeholder: 'If different' }}
            />
          </div>

          <div className='personal-and-contacts-form__row'>
            <RadioGroupField
              inputId='client-personal-form-gender-radio-group'
              options={buildSelectOptions(Gender, GenderLabels)}
              name='gender'
              groupLabel='Gender'
              isTextButton
            />
          </div>

          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <DateField
              label='Date of birth'
              name='dateOfBirth'
              inputProps={{ placeholder: 'dd/mm/yy' }}
            />
            <InputField name='placeOfBirth' label='Place of birth' />
          </div>

          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <SelectField
              name='maritalStatus'
              label='Marital status'
              clearable={false}
              options={buildSelectOptions(MaritalStatus, MaritalStatusLabels)}
            />
          </div>
        </FormGroup>

        <FormGroup label='Address'>
          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <InputField name='address1' label='Address line 1' />
          </div>

          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <InputField name='address2' label='Address line 2' />
          </div>

          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <InputField name='city' label='City' />
            <InputField name='postCode' label='Post Code' />
          </div>
        </FormGroup>

        <FormGroup label='Contacts'>
          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <InputField name='email' label='Email' inputProps={{ disabled: isClientPortal }} />
          </div>
          <div className='personal-and-contacts-form__row personal-and-contacts-form__row--grid'>
            <InputField name='mobile' label='Mobile phone' />
            <InputField name='workTel' label='Work phone' />
            <InputField name='homeTel' label='Home phone' />
          </div>
        </FormGroup>
      </FormFieldsCard>

      <NextAndPreviousLinks onNextButtonClick={handleNextButtonClick} />
    </Card>
  )
}

export default connect(ClientPersonalDetailsForm)
