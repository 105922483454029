"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyLegalStatuses = exports.CompanyLegalStatusLabels = void 0;
const CompanyLegalStatuses = exports.CompanyLegalStatuses = Object.freeze({
  LIMITED: 'LIMITED',
  LLP: 'LLP',
  PARTNERSHIP: 'PARTNERSHIP',
  SOLE_TRADER: 'SOLE_TRADER'
});
const CompanyLegalStatusLabels = exports.CompanyLegalStatusLabels = Object.freeze({
  [CompanyLegalStatuses.LIMITED]: 'Limited',
  [CompanyLegalStatuses.LLP]: 'LLP',
  [CompanyLegalStatuses.PARTNERSHIP]: 'Partnership',
  [CompanyLegalStatuses.SOLE_TRADER]: 'Sole trader'
});