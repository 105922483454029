import constants from '@elenfs/elen-constants'

const { DataImportTypes } = constants.dataImport

export const DataImportPlatformLabels = Object.freeze({
  [DataImportTypes.TRANSACT]: 'Transact',
  [DataImportTypes.CSV_FILE]: 'CSV',
  [DataImportTypes.CASH_CALC]: 'CashCalc',
  [DataImportTypes.P1]: 'P1',
  [DataImportTypes.PRAEMIUM]: 'Morningstar Wealth Platform'
})

export const DataImportPlatformIntegrationBadgeLabels = Object.freeze({
  ...DataImportPlatformLabels,
  [DataImportTypes.PRAEMIUM]: 'Morningstar'
})
