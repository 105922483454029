import { InputField, MoneyField } from '../../../form/fields'

const AssetsFormInputRow = ({ index }) => (
  <div className='assets-input-row'>
    <InputField name={`assets.${index}.owner`} label='Owner' required />
    <InputField
      name={`assets.${index}.description`}
      label='Asset description'
      required
    />
    <MoneyField
      name={`assets.${index}.value`}
      label='Value'
      inputProps={{ placeholder: '0.00' }}
      required
    />
  </div>
)

export default AssetsFormInputRow
