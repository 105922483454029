import { useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import { UpcomingReviewsPage } from './UpcomingReviewsPage'
import { TableHeaders } from '../const'
import {
  rowSchema,
  filterDateRangeOptions,
  filterDateRanges
} from '../helpers'

import { SelectField } from '../../../form/fields'

import { withFinancialAdvisers } from '../../../hoc/container'
import { getFromToDateByDateRange } from './../../../../helpers/filterDate'

import upcomingReviewsReportGql from '../../../../graphql/queries/reports/upcomingReviewsReport.graphql'

const UpcomingReviewsPageContainer = props => {
  const { financialAdviserOptions } = props

  const [currentFilter, setCurrentFilter] = useState()

  const [getUpcomingReviews, { data, loading, error, called }] = useLazyQuery(
    upcomingReviewsReportGql,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  const initialLoading = loading && !data
  const rows = data?.upcomingReviewsReport?.rows || []

  const onFilter = useCallback(
    filter => {
      getUpcomingReviews({ variables: { filter } })
      setCurrentFilter(filter)
    },
    [getUpcomingReviews, setCurrentFilter]
  )

  const filterProps = {
    onSubmit: onFilter,
    getFromToDateByDateRange,
    filterDateRangeOptions,
    filterDateRanges,
    inputsArr: [
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'adviserId',
          options: financialAdviserOptions,
          inputProps: { placeholder: 'Adviser', isClearable: true }
        }
      }
    ],
    initialValues: {
      dateRange: filterDateRanges.THIS_WEEK
    }
  }

  const downloadBtnProps = { currentFilter, reportName: 'upcoming-reviews' }
  const tableProps = {
    rows,
    rowSchema,
    tableHeaderLabels: Object.values(TableHeaders),
    className: 'upcoming-reviews-report-table'
  }

  return (
    <UpcomingReviewsPage {...{
      tableProps,
      filterProps,
      downloadBtnProps,
      loading: initialLoading,
      error,
      called,
      ...props
    }}
    />
  )
}

const EnhancedComponent = withFinancialAdvisers()(
  UpcomingReviewsPageContainer
)

export { EnhancedComponent as UpcomingReviewsPageContainer }
