import { useState, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'

import { SideMenuLink } from './SideMenuLink'
import { ClientDetailsBaseSideActions } from './ClientDetailsBaseSideActions'

import './ClientDetailsBaseSideMenu.less'

const componentClassName = 'client-details-aside'

const ClientDetailsBaseSideMenu = props => {
  const {
    isHash,
    className,
    isNewClient,
    onSubmitForm,
    isClientPortal,
    clientUserProps,
    saveToDocumentsButton,
    clientSpecificItems,
    toggleClientFactFindMutation,
    requestClientFactFindEditMutation,
    submitClientFactFindMutationCallback
  } = props

  const { hash, pathname } = useLocation()
  const items = useMemo(() => (
    [
      ...clientSpecificItems,
      { caption: 'Assets & Liabilities', to: 'assets-and-liabilities' },
      { caption: 'Objectives & Actions', to: 'objectives-and-actions' },
      { caption: 'Financial Adviser', to: 'financial-advisers' }
    ]
  ), [clientSpecificItems])

  const [loading, setLoading] = useState(false)

  const onButtonClick = useCallback(async () => {
    setLoading(true)
    await onSubmitForm()
    setLoading(false)
  }, [onSubmitForm])

  return (
    <div
      className={classnames(`${componentClassName}`, className)}
    >
      <div className={`${componentClassName}__menu`}>
        {items.map(({ caption, to }, index) => (
          <SideMenuLink
            key={index}
            {...{
              to,
              hash,
              pathname,
              isHash,
              caption,
              key: index,
              className: componentClassName
            }}
          />
        ))}
      </div>

      <ClientDetailsBaseSideActions {...{
        loading,
        isNewClient,
        componentClassName,
        saveToDocumentsButton,
        toggleClientFactFindMutation,
        requestClientFactFindEditMutation,
        submitClientFactFindMutationCallback,
        onSaveButtonClick: onButtonClick,
        clientUserProps,
        isClientPortal,
        ...props
      }}
      />
    </div>
  )
}

export { ClientDetailsBaseSideMenu }
