import { useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkflowGql } from '../../../hooks/useWorkflowGql'

import { Spinner, ErrorMessage } from '../../misc'
import { WorkflowsPage } from './WorkflowsPage'

const WorkflowsPageContainer = () => {
  const navigate = useNavigate()

  const {
    fetchWorkflowTemplates,
    workflowTemplatesData,
    fetchLoading,
    fetchError,
    fetchWorkflowTemplatesCalled,
    removeWorkflowTemplate
  } = useWorkflowGql()

  useEffect(() => {
    const shouldFetchWorkflowTemplates =
      !fetchLoading && !fetchWorkflowTemplatesCalled

    if (shouldFetchWorkflowTemplates) {
      fetchWorkflowTemplates()
    }
  }, [fetchLoading, fetchWorkflowTemplatesCalled])

  const workflowTemplates = workflowTemplatesData?.workflowTemplates || []

  const workflowTemplatesTableProps = useMemo(
    () => ({
      workflowTemplates,
      onNameClick: id => navigate(`/secure/workflows/edit/${id}`),
      onDeleteTemplate: async id => {
        await removeWorkflowTemplate({ variables: { id } })
        fetchWorkflowTemplates()
      }
    }),
    [workflowTemplates]
  )

  const onNewWorkflowButtonClick = useCallback(
    () => navigate('/secure/workflows/create'),
    []
  )

  if (fetchLoading) {
    return <Spinner />
  }

  if (fetchError) {
    return <ErrorMessage text='Failed to retrieve workflow templates' />
  }

  return (
    <WorkflowsPage
      {...{ workflowTemplatesTableProps, onNewWorkflowButtonClick }}
    />
  )
}

export { WorkflowsPageContainer }
