import { useCallback } from 'react'
import T from 'prop-types'

import { RemoveConnectionButton } from './RemoveConnectionButton'

const RemoveConnectionButtonContainer = ({
  removeConnection,
  ...restProps
}) => {
  const removeConnectionCallback = useCallback(
    (confirmed) => {
      if (confirmed) {
        try {
          removeConnection()
        } catch (error) {
          throw new Error()
        }
      }
    }, [removeConnection]
  )

  return (
    <RemoveConnectionButton {...{
      removeConnectionCallback,
      ...restProps
    }}
    />
  )
}

RemoveConnectionButtonContainer.propTypes = {
  removeConnection: T.func.isRequired
}

export { RemoveConnectionButtonContainer }
