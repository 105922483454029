import { useCallback, useEffect, useState, useMemo } from 'react'
import { isEmpty, compose } from 'ramda'
import { withFormik, connect } from 'formik'
import { useParams, useNavigate, matchPath, useLocation, matchRoutes } from 'react-router-dom'

import Button from '../../misc/Button'
import { buildInitialValues } from '../../../helpers/form'
import { RouteLeavingGuard } from '../../misc'
import FormErrorMessages from '../../form/FormErrorMessages'
import LoadingMessage from '../../misc/LoadingMessage'
import individualClientFormValidationSchema from '../../../schemas/client/clientSchema'
import individualClientPortalFormValidationSchema from '../../../schemas/client/clientPortalSchema'
import corporateClientFormValidationSchema from '../../../schemas/corporateClient/corporateClientSchema'
import corporateClientPortalFormValidationSchema from '../../../schemas/corporateClient/corporateClientPortalSchema'
import { parseGraphqlErrors } from '../../../helpers/error'
import { Form } from '../../form/Form'
import { withCurrentUser } from '../../hoc/container'
import ConfirmationModal from '../../misc/ConfirmationModal'
import { IndividualClientDetailsFormSwitch } from '../IndividualClientDetailsFormSwitch'
import { CorporateClientDetailsFormSwitch } from '../../corporateClient'
import { isCorporateClient } from '../../../helpers/client'

import './ClientDetailsBaseForm.less'

const factFindRoutes = [
  { path: '/secure/clients/:clientId/fact-find/edit/:rest' },
  { path: '/secure/clients/create/individual/:rest' },
  { path: '/clients/secure/overview/fact-find/edit/:rest' },
  { path: '/secure/clients/create/corporate/:rest' }
]

const ClientDetailsBaseForm = (props) => {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const factFindRoutesPattern = () => {
    const [{ route }] = matchRoutes(factFindRoutes, location)
    return route.path
  }

  const {
    errors: validationErrors,
    loading,
    setValidationSchema,
    formik: { dirty, submitForm, validateForm, values, setErrors },
    currentUser,
    client,
    financialAdviserOptions,
    financialAdvisersError,
    setOnSubmitForm,
    isCorporate: isCorporateFromProps,
    isClientPortal,
    isSubmitting
  } = props

  const isCorporate = !client
    ? isCorporateFromProps
    : isCorporateClient(client)

  const currentFinancialAdviser = client?.financialAdviserId
  const currentUserAdmin = currentUser?.isAdmin
  const currentUserId = currentUser?.id
  const financialAdviserProps = {
    financialAdviserOptions,
    financialAdvisersError
  }

  const clientFormValidationSchema = isCorporate
    ? corporateClientFormValidationSchema
    : individualClientFormValidationSchema

  const selectedAdviserLabel = financialAdviserOptions.find(
    adviser => values?.financialAdviserId === adviser.id
  )?.label

  const [modalVisible, setModalVisible] = useState(false)
  const closeModal = useCallback(() => setModalVisible(false))

  const handleSubmit = useCallback(
    async (url) => {
      await setValidationSchema(clientFormValidationSchema)

      if (isEmpty(await validateForm())) {
        try {
          const result = await submitForm()
          if (!result.graphQLErrors) {
            if (url) {
              navigate(url)
            } else {
              if (isClientPortal) {
                navigate('/clients/secure/overview/fact-find')
              } else {
                isCorporate
                  ? navigate(`/secure/clients/${result.data[clientId ? 'updateCorporateClient' : 'createCorporateClient']?.id}/fact-find`)
                  : navigate(`/secure/clients/${result.data[clientId ? 'updateIndividualClient' : 'createIndividualClient']?.id}/fact-find`)
              }
            }
          }
        } catch (error) {
          setErrors({ mutationError: 'Error: ' + error?.message })
        }
      }
    }, [
      navigate,
      submitForm,
      validateForm,
      setValidationSchema,
      clientFormValidationSchema,
      clientId
    ])

  const onDoneClick = useCallback(
    async () => {
      if (
        !currentUserAdmin &&
        currentUserId === currentFinancialAdviser &&
        currentFinancialAdviser !== values?.financialAdviserId
      ) {
        setModalVisible(true)
      } else {
        handleSubmit()
      }
    }, [
      currentUserAdmin,
      currentUser,
      modalVisible,
      setModalVisible,
      values
    ])

  const handleModalCallback = useCallback(
    async (accepted) => {
      if (accepted) {
        handleSubmit('/')
      }
      closeModal()
    }, [closeModal, handleSubmit]
  )

  useEffect(() => {
    if (handleSubmit) {
      setOnSubmitForm(() => handleSubmit)
    }
  }, [handleSubmit])

  const isRouteGuarded = useMemo(
    () => !isSubmitting && dirty, [dirty, isSubmitting]
  )

  return (
    <Form className='client-details-form'>
      {
        isCorporate
          ? <CorporateClientDetailsFormSwitch {...{
            setValidationSchema,
            financialAdviserProps,
            isClientPortal
          }}
            />
          : <IndividualClientDetailsFormSwitch {...{
            client,
            clientId,
            setValidationSchema,
            financialAdviserProps,
            isClientPortal
          }}
            />
      }

      <RouteLeavingGuard
        enabled={isRouteGuarded}
        shouldBlockNavigation={pathname => matchPath(
          { path: factFindRoutesPattern() },
          pathname
        )}
        yesButtonText='Yes'
        noButtonText='No'
      />

      <ConfirmationModal
        awaitCallback
        isOpen={modalVisible}
        close={closeModal}
        confirmationCallback={handleModalCallback}
        heading='Setting a current adviser'
        text={
          <>
            {
              selectedAdviserLabel
                ? (
                  <>
                    <span>Are you sure you want to set <b>{selectedAdviserLabel}</b> as the current adviser?</span><br /><br />
                  </>
                  )
                : ''
            }
            <span>You will no longer have access to this client unless</span><br />
            <span>access is provided by an administrator.</span>
          </>
        }
        yesButtonText='Yes'
        noButtonText='No'
      />

      {loading && <LoadingMessage />}

      <FormErrorMessages validationErrors={validationErrors} />

      <div className='client-details-form__buttons'>
        <Button
          onClick={onDoneClick}
          type='button'
          color={Button.Colors.BLUE}
        >
          {clientId || isClientPortal ? 'Save changes' : 'Save'}
        </Button>
      </div>
    </Form>
  )
}

const ClientDetailsBaseFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ({ validationSchema }) => validationSchema,

    mapPropsToValues: ({ client, isCorporate: isCorporateFromProps }) => {
      const isCorporate = !client
        ? isCorporateFromProps
        : isCorporateClient(client)

      return buildInitialValues(
        isCorporate ? corporateClientFormValidationSchema : individualClientFormValidationSchema,
        client,
        { stripUnknown: true }
      )
    },

    handleSubmit: async (values, { props, setErrors, resetForm }) => {
      const {
        setValidationSchema,
        client,
        mutate,
        isCorporate: isCorporateFromProps,
        isClientPortal
      } = props
      const isCorporate = !client
        ? isCorporateFromProps
        : isCorporateClient(client)

      const validationSchema = isCorporate
        ? isClientPortal
          ? corporateClientPortalFormValidationSchema
          : corporateClientFormValidationSchema
        : isClientPortal
          ? individualClientPortalFormValidationSchema
          : individualClientFormValidationSchema

      await setValidationSchema(validationSchema)

      values = validationSchema.cast(values)
      const { id, note, ...clientData } = values

      if (isClientPortal) {
        if (!isCorporate) {
          delete clientData.relationships
        }

        delete clientData.email
        delete clientData.financialAdviserId
      }

      const input = { clientData }

      if (client && client.id) {
        input.id = client.id
      }

      const result = await mutate({ variables: { input } })

      if (result.graphQLErrors) {
        setErrors(parseGraphqlErrors(result, 'There was an error saving the client'))
      } else {
        resetForm({ values })
      }

      return result
    }
  }),
  connect,
  withCurrentUser()
)(ClientDetailsBaseForm)

const ClientDetailsBaseFormEnhancedWithCurrUser = withCurrentUser()(ClientDetailsBaseFormEnhanced)

export { ClientDetailsBaseFormEnhancedWithCurrUser }
