import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { IntegrationPlatforms } from '../../../../constants'

import { EmailPage } from './EmailPage'
import { Spinner } from '../../../misc'
import { GmailIcon, OutlookIcon } from '../../../icons'
import { withIntegrationConnection } from '../../../hoc/container'
import { EmailIntegrationStatus } from '../EmailIntegrationStatus'
import { EmailInfo } from './EmailInfo'

const emailList = [
  {
    name: 'Gmail',
    platform: IntegrationPlatforms.GOOGLE,
    IconComponent: GmailIcon
  },
  {
    name: 'Outlook',
    platform: IntegrationPlatforms.MICROSOFT,
    IconComponent: OutlookIcon
  }
]

const platformIcon = {
  [IntegrationPlatforms.GOOGLE]: GmailIcon,
  [IntegrationPlatforms.MICROSOFT]: OutlookIcon
}

const EmailPageContainer = (props) => {
  const {
    connection,
    platform,
    isConnected,
    isExpired,
    connectionsLoading,
    connectionsError,
    connectProps,
    removeProps,
    onConnect,
    url
  } = props
  const { connectGoogle, connectMicrosoft, connectLoading, connectError } = connectProps
  const { removeCalled } = removeProps

  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const codeParam = searchParams.get('code')
  const platformParam = searchParams.get('platform')
  const connectionProps = {
    removeProps,
    onConnect,
    errorMessage: connectionsError?.message || connectError?.message,
    loading: connectionsLoading || connectLoading,
    connection,
    isConnected,
    isExpired,
    platform,
    email: connection?.account,
    Icon: platformIcon[platform]
  }

  useEffect(() => {
    if (url) {
      return window.location.assign(url)
    }
  }, [url])

  useEffect(() => {
    if (codeParam && platformParam && !removeCalled && !isConnected) {
      switch (platformParam) {
        case IntegrationPlatforms.GOOGLE:
          connectGoogle({ variables: { input: { code: codeParam } } })
          break
        case IntegrationPlatforms.MICROSOFT:
          connectMicrosoft({ variables: { input: { code: codeParam } } })
          break
      }

      const queryParams = new URLSearchParams(location.search)
      if (queryParams.has('code') && queryParams.has('platform')) {
        queryParams.delete('code')
        queryParams.delete('platform')

        navigate({
          search: queryParams.toString(),
          replace: true
        })
      }
    }
  }, [removeCalled, location, isConnected])

  if (connectionsLoading || connectLoading) {
    return <Spinner />
  }

  if (connection) {
    return (
      <>
        <EmailIntegrationStatus {...connectionProps} />
        <EmailInfo />
      </>
    )
  }

  return (
    <>
      <EmailPage {...{ list: emailList, onConnect }} />
      <EmailInfo />
    </>
  )
}

const EnhancedEmailPageContainer = withIntegrationConnection()(EmailPageContainer)

export { EnhancedEmailPageContainer as EmailPageContainer }
