import PropTypes from 'prop-types'
import classnames from 'classnames'

const DropdownMenuList = ({ children, className, ...rest }) => (
  <ul
    className={classnames('dropdown-menu__list', className)}
    {...rest}
  >
    {children}
  </ul>
)

DropdownMenuList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default DropdownMenuList
