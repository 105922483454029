import { Routes, Route, Navigate } from 'react-router-dom'
import constants from '@elenfs/elen-constants'
import { useAutoLogout } from './../../../hooks'

import Header from '../../nav/Header'
import { HeaderLogo } from '../../nav/HeaderLogo'
import CurrentUserMenu from '../../user/CurrentUserMenu'
import { UserMessagesNotificationsMenu } from '../../user/UserMessagesNotificationsMenu'
import { NotFoundPage } from '../../misc'
import { withAuthorization } from '../../hoc/container'
import { ClientPortalOverview } from '../clientPortal/ClientPortalOverview'

import './ClientSecureSection.less'

const { UserTypes } = constants.user

const ClientSecureSection = props => {
  useAutoLogout()

  return (
    <div className='client-secure-section'>
      <Header>
        <Header.Left>
          <HeaderLogo />
        </Header.Left>
        <Header.Right>
          <UserMessagesNotificationsMenu isClientPortal behaveAsModalOnMobile />
          <CurrentUserMenu isClientPortal logoutRedirect='/clients/login' />
        </Header.Right>
      </Header>

      <div className='client-secure-section__content'>
        <Routes>
          <Route path='overview/*' element={<ClientPortalOverview {...props} />} />
          <Route path='*' element={<NotFoundPage />} />
          <Route path='/' element={<Navigate replace to='overview' />} />
        </Routes>
      </div>
    </div>
  )
}

const ClientSecureSectionWithAuthorization = withAuthorization(
  { redirectTo: '/clients/login', userType: UserTypes.CLIENT }
)(ClientSecureSection)

export { ClientSecureSectionWithAuthorization }
