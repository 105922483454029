import { useMutation } from '@apollo/client'

import { PolicyTransactionDetailsForm } from './PolicyTransactionDetailsForm'
import { isContribution } from '../../../../../helpers/policy'

import createContributionGql from '../../../../../graphql/mutations/policy/policyTransactions/createContribution.graphql'
import createWithdrawalGql from '../../../../../graphql/mutations/policy/policyTransactions/createWithdrawal.graphql'
import updateContributionGql from '../../../../../graphql/mutations/policy/policyTransactions/updateContribution.graphql'
import updateWithdrawalGql from '../../../../../graphql/mutations/policy/policyTransactions/updateWithdrawal.graphql'
import policyTransactionsGql from '../../../../../graphql/queries/policy/policyTransactions.graphql'

const createContribution = (policyId) => useMutation(
  createContributionGql,
  {
    update: (cache, { data }) => {
      try {
        const { createContribution: contribution } = data
        const { contributions, ...restCache } = cache.readQuery({
          query: policyTransactionsGql,
          variables: {
            policyId
          }
        })
        cache.writeQuery({
          query: policyTransactionsGql,
          variables: {
            policyId
          },
          data: {
            ...restCache,
            contributions: contributions.concat([{ ...contribution, isUsedByCharge: false }])
          }
        })
      } catch (e) {
        // pass
      }
    }
  }
)

const createWithdrawal = (policyId) => useMutation(
  createWithdrawalGql,
  {
    update: (cache, { data }) => {
      try {
        const { createWithdrawal: withdrawal } = data
        const { withdrawals, ...restCache } = cache.readQuery({
          query: policyTransactionsGql,
          variables: {
            policyId
          }
        })
        cache.writeQuery({
          query: policyTransactionsGql,
          variables: {
            policyId
          },
          data: {
            ...restCache,
            withdrawals: withdrawals.concat([{ ...withdrawal, isUsedByCharge: false }])
          }
        })
      } catch (e) {
        // pass
      }
    }
  }
)

const PolicyTransactionDetailsFormContainer = (props) => {
  const { policyTransaction, policyTransactionType, policyId } = props
  const isUpdate = policyTransaction && policyTransaction.id

  let useMutationResult
  if (!isUpdate) {
    useMutationResult = (
      isContribution(policyTransactionType)
        ? createContribution
        : createWithdrawal)(policyId)
  } else {
    useMutationResult = useMutation(
      isContribution(policyTransactionType)
        ? updateContributionGql
        : updateWithdrawalGql)
  }
  const [mutate, mutationState] = useMutationResult

  return (
    <PolicyTransactionDetailsForm
      mutate={mutate}
      mutationState={mutationState}
      {...props}
    />
  )
}

export { PolicyTransactionDetailsFormContainer }
