import { useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import constants from '@elenfs/elen-constants'

import { TransactionReportPage } from './TransactionReportPage'
import { SelectField } from '../../../form/fields'

import {
  buildTableHeaders,
  buildRowSchema,
  filterDateRangeOptions,
  filterDateRanges
} from './../helpers'

import { buildSelectOptions } from './../../../form/fields/SelectField/helpers'
import { getFromToDateByDateRange } from './../../../../helpers/filterDate'
import { formatMoney } from '../../../../helpers/policy'

import contributionReportGql from '../../../../graphql/queries/reports/contributionReport.graphql'
import withdrawalReportGql from '../../../../graphql/queries/reports/withdrawalReport.graphql'

const {
  ContributionFrequencies,
  ContributionMethods,
  WithdrawalMethods,
  WithdrawalFrequencies,
  ContributionMethodsLabels,
  ContributionFrequenciesLabels,
  WithdrawalMethodsLabels,
  WithdrawalFrequenciesLabels
} = constants.policy.common

const TransactionReportPageContainer = ({ isContribution }) => {
  const [currentFilter, setCurrentFilter] = useState()

  const [getTransactionReport, { data, loading, error, called }] = useLazyQuery(
    isContribution ? contributionReportGql : withdrawalReportGql,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  const initialLoading = loading && !data

  const rows =
    data?.contributionReport?.rows || data?.withdrawalReport?.rows || []
  const total = data?.contributionReport?.total || data?.withdrawalReport?.total

  const onFilter = useCallback(
    filter => {
      getTransactionReport({ variables: { filter } })
      setCurrentFilter(filter)
    },
    [getTransactionReport, setCurrentFilter]
  )

  const methodOptions = isContribution
    ? buildSelectOptions(ContributionMethods, ContributionMethodsLabels)
    : buildSelectOptions(WithdrawalMethods, WithdrawalMethodsLabels)

  const frequencyOptions = isContribution
    ? buildSelectOptions(ContributionFrequencies, ContributionFrequenciesLabels)
    : buildSelectOptions(WithdrawalFrequencies, WithdrawalFrequenciesLabels)

  const filterProps = {
    onSubmit: onFilter,
    getFromToDateByDateRange,
    filterDateRangeOptions,
    filterDateRanges,
    inputsArr: [
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'clientId',
          inputProps: {
            async: true,
            isClearable: true,
            placeholder: 'Client',
            asyncProps: {
              labelProp: 'fullName',
              valueProp: 'id'
            }
          }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'method',
          options: methodOptions,
          inputProps: { placeholder: 'Method', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'frequency',
          options: frequencyOptions,
          inputProps: { placeholder: 'Frequency', isClearable: true }
        }
      }
    ],
    initialValues: {
      dateRange: filterDateRanges.THIS_MONTH_TO_DATE
    },
    summary: !!total?.amount && formatMoney(total)
  }

  const downloadBtnProps = {
    currentFilter,
    reportName: isContribution ? 'contribution' : 'withdrawal'
  }

  const tableHeaders = buildTableHeaders(isContribution)
  const rowSchema = buildRowSchema(isContribution, tableHeaders)

  const tableProps = {
    rows,
    tableHeaderLabels: Object.values(tableHeaders),
    rowSchema,
    className: 'transaction-report-table'
  }

  return (
    <TransactionReportPage
      {...{
        filterProps,
        downloadBtnProps,
        rows,
        loading: initialLoading,
        error,
        called,
        isContribution,
        tableProps
      }}
    />
  )
}

export { TransactionReportPageContainer }
