import { withFinancialAdvisers } from '../../hoc/container'

import { SelectField } from '../../form/fields'

import { getFromToDateByDateRange } from './../../../helpers/filterDate'
import { filterDateRangeOptions, filterDateRanges } from './helpers'
import { providerOptions } from './../../../helpers/selectOptions'
import { Filter } from '../../misc'

const FilterIncomeEntriesFormContainer = props => {
  const { onSubmit, summary, financialAdviserOptions } = props

  const filterProps = {
    onSubmit,
    getFromToDateByDateRange,
    filterDateRangeOptions,
    filterDateRanges,
    inputsArr: [
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'adviserId',
          options: financialAdviserOptions,
          inputProps: { placeholder: 'Adviser', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'provider',
          options: providerOptions,
          inputProps: { placeholder: 'Provider', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'clientId',
          inputProps: {
            async: true,
            isClearable: true,
            placeholder: 'Client',
            asyncProps: {
              labelProp: 'fullName',
              valueProp: 'id'
            }
          }
        }
      }
    ],
    initialValues: {
      dateRange: filterDateRanges.THIS_MONTH_TO_DATE
    },
    summary
  }

  return <Filter {...filterProps} />
}

const EnhancedFilterIncomeEntriesFormContainer = withFinancialAdvisers()(
  FilterIncomeEntriesFormContainer
)

export { EnhancedFilterIncomeEntriesFormContainer as FilterIncomeEntriesFormContainer }
