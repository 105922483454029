import T from 'prop-types'

import { Button, Modal } from '../../../../misc'
import { PlusIcon } from '../../../../icons'
import { PolicyValuationDetailsModal } from '../../PolicyValuationDetailsModal'

const NewPolicyValuationButton = ({ policyId }) => (
  <Modal.Trigger
    as={Button}
    color={Button.Colors.BLUE}
    modal={PolicyValuationDetailsModal}
    modalProps={{ policyId }}
  >
    <PlusIcon />
    <span>Add valuation</span>
  </Modal.Trigger>
)

NewPolicyValuationButton.propTypes = {
  policyId: T.string.isRequired
}

export { NewPolicyValuationButton }
