import { useMemo } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { formatDate } from './../../../../../helpers/date'
import { userType } from './../../../../../helpers/propTypes'

import { Badge, InitialsAvatar } from './../../../../misc'
import { CogWheelIcon } from './../../../../icons'
import { MessageTypes } from '../../../../../constants'

import MessageDocumentsList from './MessageDocumentsList'

import './Message.less'

const checkIsNewMsg = (fromUser, currentUser, isRead) =>
  fromUser?.id !== currentUser?.id && !isRead

const Message = ({
  fromUser,
  isRead,
  createdAt,
  body,
  attachments,
  currentUser,
  onDownloadFile,
  type
}) => {
  const isNewMsg = useMemo(() => checkIsNewMsg(fromUser, currentUser, isRead), [
    fromUser,
    currentUser,
    isRead
  ])

  return (
    <div
      className={classnames(
        'single-message',
        isNewMsg && 'single-message--new'
      )}
    >
      <div className='single-message__initials'>
        <InitialsAvatar
          IconAvatar={type === MessageTypes.SYSTEM ? CogWheelIcon : null}
          user={fromUser}
          size={InitialsAvatar.SIZES.LG}
        />
      </div>
      <div className='single-message__content'>
        <div className='single-message__content__header'>
          <div className='single-message__content__header__name-and-date'>
            <span>
              {type === MessageTypes.SYSTEM && 'System message'}
              {type === MessageTypes.USER && fromUser && fromUser?.fullName}
            </span>
            <span>{formatDate(createdAt, 'DD MMM YYYY HH:mm')}</span>
          </div>
          {isNewMsg && (
            <div className='single-message__content__header__badge'>
              <Badge color={Badge.Colors.BLUE} size={Badge.Sizes.SM}>
                New
              </Badge>
            </div>
          )}
        </div>
        <div className='single-message__content__body'> {body} </div>
        {
          attachments && attachments.length
            ? (
              <MessageDocumentsList
                attachments={attachments}
                onDownloadFile={onDownloadFile}
              />
              )
            : null
        }
      </div>
    </div>
  )
}

Message.defaultProps = {
  documetns: []
}

Message.propTypes = {
  fromUser: T.shape({
    isClient: T.bool,
    fullName: T.string.isRequired,
    firstName: T.string,
    lastName: T.string,
    id: T.string.isRequired
  }),
  isRead: T.bool,
  createdAt: T.string.isRequired,
  body: T.string.isRequired,
  documents: T.arrayOf(T.shape({ fileName: T.string.isRequired })),
  currentUser: userType().isRequired,
  onDownloadFile: T.func.isRequired
}

export { Message }
