import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { Card } from '../../misc'

import './ClientRelationshipsCard.less'

const ClientRelationshipsCard = ({ canEdit, relationships, id }) => {
  const baseUrl = 'edit/relationships'

  return (
    <Card className='client-relationships-card' id={id}>
      <Card.Header
        allowToNavigate={canEdit}
        baseUrl={baseUrl}
      >
        Relationships
      </Card.Header>

      <div className='client-relationships-card__container'>
        {relationships.map(({ fullName, to, relation, disabled }, i) => (
          <div className='client-relationships-card__container__box' key={i}>
            {to
              ? (
                <Link
                  to={to}
                  className={classnames(
                    'client-relationships-card__container__box__link',
                    {
                      'client-relationships-card__container__box__link--disabled': disabled
                    }
                  )}
                  disabled={disabled}
                >
                  {fullName}
                </Link>
                )
              : (
                <span>{fullName}</span>
                )}
            <span> {relation} </span>
          </div>
        ))}
      </div>
    </Card>
  )
}

export { ClientRelationshipsCard }
