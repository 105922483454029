import T from 'prop-types'
import { compose } from 'ramda'
import { connect, withFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'

import { Form } from '../../form'
import { InputField } from '../../form/fields'
import { Button } from '../../misc'

import UserFormSuccess from '../UserFormSuccess'
import FormErrorMessages from '../../form/FormErrorMessages'
import forgotPasswordSchema from '../../../schemas/users/forgotPasswordSchema'
import { buildInitialValues } from '../../../helpers/form'

const ForgotPasswordForm = (props) => {
  const {
    success,
    error: serverError,
    errors: validationErrors,
    loading,
    goBackLinkPath,
    handleSubmit
  } = props
  const navigate = useNavigate()

  if (success) {
    return (
      <UserFormSuccess
        text='If we have an account associated with the email you provided,
        you can check it now for instructions for recovering your password'
        doneBtnHandler={() => navigate(goBackLinkPath)}
      />
    )
  }

  const disableBtn = loading

  return (
    <>
      <p>We all forget things! Enter your email address below and we'll send you a reset password link</p>
      <Form className='forgot-password-form' onSubmit={handleSubmit}>

        <InputField
          name='email'
          label='Email'
        />

        <FormErrorMessages
          serverError={serverError}
          validationErrors={validationErrors}
          serverErrorMessage='Something went wrong, please try again'
        />

        <Button
          type='submit'
          className='forgot-password-form__submit-btn'
          color={Button.Colors.BLUE}
          disabled={disableBtn}
        >
          Send
        </Button>

        <Link className='link' to={goBackLinkPath}>
          Step back
        </Link>
      </Form>
    </>
  )
}

ForgotPasswordForm.defaultProps = {
  success: true,
  loading: false,
  error: ''
}

ForgotPasswordForm.propTypes = {
  success: T.bool,
  error: T.string,
  loading: T.bool,
  handleSubmit: T.func.isRequired,
  goBackLinkPath: T.string.isRequired
}

const ForgotPasswordFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: forgotPasswordSchema,

    mapPropsToValues: () => {
      const values = buildInitialValues(forgotPasswordSchema)
      return values
    },

    handleSubmit: ({ email }, { props }) => {
      const { handleForgotPassword } = props

      handleForgotPassword(email?.toLowerCase())
    }
  }),
  connect
)(ForgotPasswordForm)

export default ForgotPasswordFormEnhanced
