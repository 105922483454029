import { DashboardCardTitle } from '../DashboardCardTitle'
import { SignatureDashboardCardList } from './SignatureDashboardCardList'

const SignaturesDashboardCard = ({ listProps }) => (
  <div>
    <DashboardCardTitle> Pending signatures</DashboardCardTitle>
    <SignatureDashboardCardList {...listProps} />
  </div>
)

export { SignaturesDashboardCard }
