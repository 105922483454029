import { Modal } from '../Modal'
import { GeneralErrorPage } from '../GeneralErrorPage'

const GeneralErrorModal = props => {
  const { icon, text, className, title, close, isOpen } = props

  return (
    <Modal {...{ className, isOpen, close }}>
      <GeneralErrorPage {...{ icon, text, title }} />
    </Modal>
  )
}

export { GeneralErrorModal }
