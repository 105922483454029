import { Modal, ModalStickyLayout } from '../../misc'
import { TaskDetailsForm } from './TasksDetailsForm'
import { ChevronLeftIcon } from '../../icons'

import './TaskDetailsModal.less'

const TaskDetailsModal = props => {
  const { close, isSubtask, newTask, isOpen, prevTask } = props

  return (
    <ModalStickyLayout className='tasks-details__modal' {...{ isOpen, close }}>
      {isOpen && (
        <TaskDetailsForm
          {...{
            onDone: close,
            modalHeader: <Modal.Heading>
              {(prevTask || newTask) && isSubtask && (
                <ChevronLeftIcon onClick={close} />
              )}

              {`${newTask ? (isSubtask ? 'Edit' : 'New') : 'Edit'} ${isSubtask ? 'subtask' : 'task'
              }`}
            </Modal.Heading>, // eslint-disable-line
            ...props
          }}
        />
      )}
    </ModalStickyLayout>
  )
}

export { TaskDetailsModal }
