import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { WorkflowActionStatuses } from '@elenfs/elen-constants'
import { useWorkflowGql } from './../../../hooks/useWorkflowGql'
import {
  path,
  sortBy,
  prop,
  pipe,
  isNil,
  ifElse,
  always,
  map,
  all,
  propEq
} from 'ramda'

import { ErrorMessage, Spinner } from './../../misc'
import { WorkflowInstancePage } from './WorkflowInstancePage'

const { COMPLETED } = WorkflowActionStatuses

const mapAction = ({
  task: { assignedTo, description, dueDate, summary, type, status }
}) => ({
  taskAssignee: assignedTo,
  taskDescription: description,
  taskDueDate: dueDate,
  taskSummary: summary,
  taskType: type,
  status
})

const isInstanceCompleted = all(propEq('status', COMPLETED))

const WorkflowInstancePageContainer = () => {
  const { clientId, workflowInstanceId } = useParams()

  const {
    workflowInstanceData,
    fetchWorkflowInstance,
    fetchWorkflowInstanceCalled,
    fetchLoading,
    fetchError
  } = useWorkflowGql()

  useEffect(() => {
    const shouldFetch = !fetchLoading && !fetchWorkflowInstanceCalled
    if (shouldFetch) {
      fetchWorkflowInstance({ variables: { id: workflowInstanceId } })
    }
  }, [fetchLoading, fetchWorkflowInstanceCalled])

  const diagramProps = useMemo(
    () => ({
      isWorkflowInstance: true,
      actions: ifElse(
        isNil,
        always([]),
        pipe(
          path(['workflowInstance', 'actions']),
          sortBy(prop('order')),
          map(mapAction)
        )
      )(workflowInstanceData)
    }),
    [workflowInstanceData]
  )

  const headerProps = useMemo(
    () => ({
      startedOn: path(['workflowInstance', 'createdAt'])(workflowInstanceData),
      triggeredByName: path(['workflowInstance', 'triggeredBy', 'fullName'])(
        workflowInstanceData
      ),
      status: isInstanceCompleted(diagramProps.actions)
        ? 'Completed'
        : 'In progress'
    }),
    [workflowInstanceData, diagramProps]
  )

  const goBackHeaderProps = useMemo(
    () => ({
      to: `/secure/clients/${clientId}`,
      title: path(['workflowInstance', 'name'])(workflowInstanceData)
    }),
    [clientId, workflowInstanceData]
  )

  if (fetchLoading && !workflowInstanceData) {
    return <Spinner />
  }

  if (fetchError) {
    return <ErrorMessage text='Failed to retrieve workflow instance ' />
  }

  return (
    <WorkflowInstancePage
      {...{ headerProps, diagramProps, goBackHeaderProps }}
    />
  )
}

export { WorkflowInstancePageContainer }
