import PropTypes from 'prop-types'
import classnames from 'classnames'

import './TableResponsiveWrapper.less'

const TableResponsiveWrapper = ({ children, className }) => (
  <div
    className={classnames('table-responsive', className)}
  >
    {children}
  </div>
)

TableResponsiveWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default TableResponsiveWrapper
