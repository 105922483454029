export const BASE_INCOME_COLORS_ARR = [
  '#1A3DCB',
  '#4D70FF',
  '#4CC9F0',
  '#F7BF47',
  '#7F8FA4',
  '#5C69A0'
]

export const BASE_EXPENDITURE_COLORS_ARR = [
  '#F85359',
  '#1A3DCB',
  '#4D70FF',
  '#F7BF47',
  '#5C69A0'
]
