import classnames from 'classnames'

import './ColorsAvatar.less'

const ColorsAvatar = ({ color, size, className }) => {
  if (!color) {
    return null
  }

  return (
    <div className={classnames(
      'colors-avatar', {
        [`colors-avatar--${color}`]: color,
        [`colors-avatar--${size}`]: size
      }, className)}
    />
  )
}

export { ColorsAvatar }
