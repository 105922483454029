import constants from '@elenfs/elen-constants'

import { buildSelectOptions } from './../components/form/fields/SelectField'
import { ChargeFrequenciesLabels, ChargeTypesLabels } from './enumLabels'

const {
  PolicyCategories,
  PolicyProviders,
  PolicyProvidersLabels,
  PolicyCategoriesLabels
} = constants.policy.common

const { ChargeFrequencies, ChargeTypes } = constants.charge

export const providerOptions = buildSelectOptions(PolicyProviders, PolicyProvidersLabels)
export const policyTypeOptions = buildSelectOptions(PolicyCategories, PolicyCategoriesLabels)
export const frequencyOptions = buildSelectOptions(ChargeFrequencies, ChargeFrequenciesLabels)
export const chargeTypeOptions = buildSelectOptions(ChargeTypes, ChargeTypesLabels)
