import { HashLink } from 'react-router-hash-link'
import { NavLink } from 'react-router-dom'
import T from 'prop-types'
import classnames from 'classnames'
import toRegex from 'to-regex'

const SideMenuLink = ({ caption, to, hash, pathname, isHash, replace, className }) => {
  to = `${isHash ? '#' : ''}${to}`

  const regExp = toRegex(to, { contains: true, flags: 'i' })
  const testRegX = str => regExp.test(str)
  const matchRegX = testRegX(pathname)

  const NavLinkComponent = isHash ? HashLink : NavLink
  const isHashLinkActive = isHash ? to === hash : matchRegX

  return (
    <NavLinkComponent
      replace={replace}
      className={classnames(`${className}__link`, {
        [`${className}__link--active`]: isHashLinkActive
      })}
      to={to}
      {...isHash}
    >
      {caption}
    </NavLinkComponent>
  )
}

SideMenuLink.defaultProps = {
  replace: true
}

SideMenuLink.propTypes = {
  replace: T.bool,
  to: T.string,
  isHash: T.bool,
  caption: T.string
}

export { SideMenuLink }
