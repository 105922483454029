import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { TasksDashboardCard } from './TasksDashboardCard'
import { PlusIcon, TasksIcon } from '../../icons'
import { Button } from '../../misc'

import tasksCount from '../../../graphql/queries/tasks/tasksCount.graphql'
import { TaskModalButton } from '../../tasks/TaskModalButton'

const TasksLink = () => (
  <TaskModalButton>
    <Button>
      <PlusIcon />
      <span className='dashboard-page__container__task_button_span'>
        New Task
      </span>
    </Button>
  </TaskModalButton>
)

const TasksDashboardCardContainer = () => {
  const navigate = useNavigate()
  const { data } = useQuery(tasksCount, { fetchPolicy: 'cache-first' })

  const { countOutstanding, countDueToday, countDueThisWeek } =
    data?.tasksCount || {}

  const cardProps = {
    isColumn: false,
    Icon: TasksIcon,
    boxes: [
      { quantity: countOutstanding || 0, description: 'Outstanding' },
      {
        quantity: countDueToday || 0,
        description: 'Due today'
      },
      {
        quantity: countDueThisWeek || 0,
        description: 'Due this week'
      }
    ]
  }

  const titleProps = {
    ActionComponent: TasksLink
  }

  const onCardClick = useCallback(() => navigate('/secure/tasks'), [navigate])

  return <TasksDashboardCard {...{ cardProps, titleProps, onCardClick }} />
}

export { TasksDashboardCardContainer }
