
import { useQuery } from '@apollo/client'

import serviceLevelsGql from '../../../../graphql/queries/serviceLevel/serviceLevels.graphql'

import { Spinner, ErrorMessage } from '../../../misc'
import { ServiceLevelsPage } from './ServiceLevelsPage'

const ServiceLevelsContainer = () => {
  const { loading, error, data } = useQuery(serviceLevelsGql, {
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) {
    return <Spinner />
  }

  if (error) {
    return <ErrorMessage text='Failed to load service levels' />
  }

  const serviceLevels = data?.serviceLevels || []

  return (
    <ServiceLevelsPage serviceLevels={serviceLevels} />
  )
}

export { ServiceLevelsContainer }
