import { Card, PageContent, Summary } from '../../misc'
import { formatDate } from '../../../helpers/date'

import './ClientCompliancePage.less'

const ClientCompliancePage = ({ client, clientId }) => {
  const {
    clientSince,
    clientUntil,
    nextReviewAt,
    termsOfBusinessSignedAt,
    feeAgreementSignedAt,
    vulnerable,
    serviceLevel
  } = client

  const baseUrl = `/secure/clients/${clientId}/compliance/edit`
  const serviceLevelName = serviceLevel && serviceLevel.name

  return (
    <PageContent>
      <div className='client-compliance'>
        <Card.Group className='client-compliance__card-group'>
          <Card>
            <Card.Header baseUrl={baseUrl}>
              Details
            </Card.Header>
            <Summary
              data={[
                { label: 'Service level', value: serviceLevelName },
                { label: 'Client since', value: formatDate(clientSince) },
                { label: 'Client until', value: formatDate(clientUntil) },
                { label: 'Next review date ', value: formatDate(nextReviewAt) },
                { label: 'Last terms of business signed', value: formatDate(termsOfBusinessSignedAt) },
                { label: 'Last fee agreement signed ', value: formatDate(feeAgreementSignedAt) },
                { label: 'Vulnerable ', value: vulnerable ? 'Yes' : 'No' }
              ]}
            />
          </Card>
        </Card.Group>
      </div>
    </PageContent>
  )
}

export { ClientCompliancePage }
