import { InputField, MoneyField, SelectField } from '../../../form/fields'

const IncomeFormInputRow = props => {
  const { index, frequencyOptions, typeOptions } = props

  return (
    <div className='income-input-row'>
      <div className='income-input-row__money-row'>
        <InputField
          name={`incomes.${index}.source`}
          label='Source'
          required
          inputProps={{ maxLength: 50 }}
        />

        <MoneyField
          name={`incomes.${index}.netAmount`}
          label='Net'
          required
          className='income-input-row__money-field'
          inputProps={{
            placeholder: '0.00'
          }}
        />

        <MoneyField
          name={`incomes.${index}.grossAmount`}
          label='Gross'
          className='income-input-row__money-field'
          inputProps={{
            placeholder: '0.00'
          }}
        />
      </div>

      <div className='income-input-row__options-row'>
        <SelectField
          name={`incomes.${index}.frequency`}
          label='Frequency'
          options={frequencyOptions}
        />

        <SelectField
          name={`incomes.${index}.type`}
          label='Type'
          options={typeOptions}
        />
      </div>
    </div>
  )
}

export default IncomeFormInputRow
