import PropTypes from 'prop-types'

import AuditLogEntryItem from '../AuditLogEntryItem'
import { AuditLogEntryModal } from '../AuditLogEntryModal'
import { Modal } from '../../misc'

const AuditLogEntryEditableItem = ({
  auditLogEntry,
  refetch
}) => (
  <Modal.Trigger
    as={AuditLogEntryItem}
    editable
    auditLogEntry={auditLogEntry}
    modal={AuditLogEntryModal}
    modalProps={{ auditLogEntry, refetchLogEntries: refetch }}
  />
)

AuditLogEntryEditableItem.propTypes = {
  auditLogEntry: PropTypes.object.isRequired
}

export default AuditLogEntryEditableItem
