import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { FieldArray, connect } from 'formik'

import { AddNewFormRowButton, Card } from '../../misc'
import { FieldArrayItem, FormFieldsCard } from '../../form'
import { validateAndNavigate } from '../../../helpers/client'
import { ProfitAndLossFormRow } from './ProfitAndLossFormRow'
import NextAndPreviousLinks from './../../misc/NextAndPreviousButton'
import profitAndLossSchema from '../../../schemas/corporateClient/profitAndLossSchema'

import './ProfitAndLossForm.less'

const buildRow = () => ({
  yearEndDate: '',
  amount: {
    amount: ''
  }
})

const ProfitAndLossForm = props => {
  const navigate = useNavigate()
  const { formik, nextLink, prevLink, setValidationSchema } = props

  const {
    values: { profitAndLoss = [] },
    validateForm
  } = formik

  const handleNavButtonClick = useCallback(
    isNext => {
      validateAndNavigate({
        setSchema: setValidationSchema,
        schema: profitAndLossSchema,
        validate: validateForm,
        nextLocation: isNext ? nextLink : prevLink,
        navigate
      })
    },
    [
      navigate,
      nextLink,
      prevLink,
      validateForm,
      profitAndLossSchema,
      setValidationSchema
    ]
  )

  return (
    <Card className='profit-and-loss-form'>
      <FormFieldsCard title='Profit and loss'>
        <FieldArray
          validateOnChange={false}
          name='profitAndLoss'
          render={arrayHelpers => (
            <div className='relationships-input-rows-container'>
              {profitAndLoss.map((_, index) => (
                <FieldArrayItem
                  key={index}
                  index={index}
                  label='Profit and loss'
                  onRemove={() => arrayHelpers.remove(index)}
                >
                  <ProfitAndLossFormRow
                    index={index}
                    arrayHelpers={arrayHelpers}
                  />
                </FieldArrayItem>
              ))}

              <AddNewFormRowButton
                label='add profit/loss'
                onClick={() => arrayHelpers.push(buildRow())}
              />
            </div>
          )}
        />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}

const EnhancedProfitAndLossForm = connect(ProfitAndLossForm)
export { EnhancedProfitAndLossForm as ProfitAndLossForm }
