const ReportIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M12.2 3c.833 0 1.576.516 1.852 1.288h1.42c.362 0 .655.288.655.643h1.31c1.084 0 1.963.863 1.963 1.928v12.213c0 1.065-.88 1.928-1.964 1.928H6.964C5.879 21 5 20.137 5 19.072V6.859c0-1.065.88-1.928 1.964-1.928h1.309c0-.355.293-.643.654-.643h1.42A1.962 1.962 0 0112.2 3zM8.273 6.217h-1.31a.649.649 0 00-.654.642v12.213c0 .355.293.642.655.642h10.472a.649.649 0 00.655-.642V6.859a.649.649 0 00-.655-.642h-1.309v1.285a.649.649 0 01-.654.643H8.927a.649.649 0 01-.654-.643V6.217zM8 14.4a.6.6 0 01.6.6v1.8a.6.6 0 01-1.2 0V15a.6.6 0 01.6-.6zm3-1.2a.6.6 0 01.6.6v3a.6.6 0 01-1.2 0v-3a.6.6 0 01.6-.6zm2.4 1.2a.6.6 0 01.6.6v1.8a.6.6 0 01-1.2 0V15a.6.6 0 01.6-.6zm2.4-1.2a.6.6 0 01.6.6v3a.6.6 0 01-1.2 0v-3a.6.6 0 01.6-.6zm-.429-2.88a.681.681 0 01.639-.072.607.607 0 01.385.48.583.583 0 01-.252.552l-2.572 1.8a.68.68 0 01-.771 0l-2.186-1.53-2.185 1.53a.674.674 0 01-.9-.12.574.574 0 01.128-.84l2.572-1.8a.68.68 0 01.771 0l2.186 1.53zM12.2 4.288a.649.649 0 00-.655.643l-.007.095a.651.651 0 01-.647.548H9.58v1.285h5.237V5.574H13.51a.649.649 0 01-.654-.643l-.008-.095a.651.651 0 00-.647-.548z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { ReportIcon }
