import { ClientDetailsBasePage } from '../ClientDetailsBasePage'
import { IndividualClientDetailsPageCards } from './IndividualClientDetailsPageCards'
import { IndividualClientDetailsSideMenu } from '../IndividualClientDetailsSideMenu'

const IndividualClientDetailsPage = props => {
  const { canEdit } = props
  const baseUrl = canEdit && 'edit/'

  const anchorSpecificIds = [
    'personal',
    'relationships',
    'dependants',
    'employment'
  ]

  return (
    <ClientDetailsBasePage
      baseUrl={baseUrl}
      anchorSpecificIds={anchorSpecificIds}
      ClientDetailsSpecificSideMenu={IndividualClientDetailsSideMenu}
      ClientSpecificDetailsCards={IndividualClientDetailsPageCards}
      {...props}
    />
  )
}

export { IndividualClientDetailsPage }
