import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import T from 'prop-types'
import { ESignatureUserStatuses } from '@elenfs/elen-constants'

import VisibleForClientCheckbox from './VisibleForClientCheckbox'
import updateClientDocumentGql from '../../../../graphql/mutations/clientDocument/updateClientDocument.graphql'

const { PENDING, WAITING } = ESignatureUserStatuses

const VisibleForClientCheckboxContainer = ({ clientDocument, ...restProps }) => {
  const { visibleForClient } = clientDocument

  const [visibleForClientMutation] = useMutation(updateClientDocumentGql)

  const mutateVisibleForClient = useCallback(async () => {
    const { error } = await visibleForClientMutation({
      variables: {
        input: {
          id: clientDocument.id,
          visibleForClient: !visibleForClient
        }
      }
    })

    if (error) {
      throw error
    }
  }, [visibleForClientMutation, visibleForClient])

  const confirmationCallback = useCallback((confirmed) => {
    if (confirmed) {
      return mutateVisibleForClient()
    }
  }, [mutateVisibleForClient])

  const disabled = [PENDING, WAITING].includes(clientDocument?.eSignature?.status)

  return (
    <VisibleForClientCheckbox
      confirmationCallback={confirmationCallback}
      clientDocument={clientDocument}
      disabled={disabled}
      {...restProps}
    />
  )
}

VisibleForClientCheckboxContainer.propTypes = {
  clientDocument: T.object.isRequired,
  className: T.string
}

export default VisibleForClientCheckboxContainer
