const GoIcon = ({ fill = '#e2e7ee', backgroundFill = '#fff', ...props }) => {
  return (
    <svg width='29px' height='29px' viewBox='0 0 29 29' {...props}>
      <g fill='none' fillRule='evenodd'>
        <circle cx='14.5' cy='14.5' r='14.5' fill={fill} />
        <circle cx='14.5' cy='14.5' r='11.5' fill={backgroundFill} />
        <g fill={fill} transform='translate(5.8 7.72503)'>
          <path
            transform='rotate(45 10.44656 6.965325)'
            d='M15.08 2.32v.01l.013119.00065006v8.28687744c0 .5484864-.4527555.9931226-1.005383.9931226h-.7310521c-.5552577 0-1.005383-.4475937-1.005383-.9931226L12.351 5.061l-5.55787744.00081818C6.24463611 5.06181818 5.8 4.60906267 5.8 4.05643515v-.73105211C5.8 2.77012532 6.24759369 2.32 6.79312256 2.32H15.08z'
          />
          <path d='M1 5.38297045h12.92v3.016H1c-.55228475 0-1-.44771525-1-1v-1.016c0-.55228475.44771525-1 1-1z' />
        </g>
      </g>
    </svg>
  )
}

export default GoIcon
