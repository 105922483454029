import { DropdownMenu } from './../../../misc'
import { RecipientModalTrigger } from '../../RecipientModal'

import { isFilePdf } from './../../../../helpers/document'

const RequestSignatureButton = ({ clientNotRegistered, ...props }) => (
  <>
    {isFilePdf(props?.clientDocument) && (
      <RecipientModalTrigger {...props} disabled={clientNotRegistered}>
        <DropdownMenu.Item disabled={clientNotRegistered}>
          Request signature
        </DropdownMenu.Item>
      </RecipientModalTrigger>
    )}
  </>
)

export { RequestSignatureButton }
