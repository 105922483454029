import { Card, Summary } from './../../misc'
import { UserIcon } from '../../icons'

import './ClientPersonalAndContactsCard.less'

const ClientPersonalAndContactsCard = props => {
  const {
    canEdit,
    title,
    fullName,
    gender,
    personalSummaries,
    contactsSummaries,
    id
  } = props
  const baseUrl = 'edit/personal'

  return (
    <Card className='client-personal-and-contacts-card' id={id}>
      <div>
        <Card.Header
          allowToNavigate={canEdit}
          baseUrl={baseUrl}
        >
          Personal
        </Card.Header>

        <div className='client-personal-and-contacts-card__name-and-avatar'>
          <div className='client-personal-and-contacts-card__name-and-avatar__avatar-container'>
            <UserIcon />
          </div>

          <div className='client-personal-and-contacts-card__name-and-avatar__name-container'>
            <h3>
              {title} {fullName}
            </h3>
            <span> {gender} </span>
          </div>
        </div>

        <Summary data={personalSummaries} />
      </div>

      <div>
        <Card.Header
          allowToNavigate={canEdit}
          baseUrl={baseUrl}
        >
          Contacts
        </Card.Header>

        <Summary data={contactsSummaries} />
      </div>
    </Card>
  )
}

export { ClientPersonalAndContactsCard }
