import PropTypes from 'prop-types'
import { is } from 'ramda'

import { UsersDisableButton } from '../UsersDisableButton'
import { UserDetailsModal } from '../UserDetailsModal'
import { Table, Modal } from '../../../misc'
import PencilIcon from '../../../icons/Pencil'

import { UserRolesLabels, UserPermissionsLabels } from '../../../../helpers/enumLabels/user'

const EditButton = (props) => (
  <PencilIcon {...props} />
)

const UsersTableRow = ({ user, currentUser }) => {
  const canEdit = currentUser?.isAccountOwner || !user?.isAccountOwner

  // TODO refactor when switch isAccountOwner to not be just a flag in CompanyUser
  const permissionLabel = user?.isAccountOwner ? 'Account Owner' : UserPermissionsLabels[user.isAdmin]

  return (
    <Table.Row>
      <Table.Cell data-label='First name'>
        {user.firstName}
      </Table.Cell>
      <Table.Cell data-label='Last name'>
        {user.lastName}
      </Table.Cell>
      <Table.Cell data-label='Job role'>
        {UserRolesLabels[user.role]}
      </Table.Cell>
      <Table.Cell data-label='Permissions'>
        {permissionLabel}
      </Table.Cell>
      <Table.Cell data-label='Default rate'>
        {user.defaultRate}{is(Number, user.defaultRate) && '%'}
      </Table.Cell>
      <Table.Cell data-label='Alternative rate'>
        {user.alternativeRate}{is(Number, user.alternativeRate) && '%'}
      </Table.Cell>
      <Table.Cell data-label='Email'>
        {user.email}
      </Table.Cell>
      <Table.Cell data-label=''>
        <Table.Actions>
          {
            canEdit &&
              <Modal.Trigger
                as={EditButton}
                modal={UserDetailsModal}
                modalProps={{ user }}
                className='table-responsive__action--navigate'
              >
                <PencilIcon />
              </Modal.Trigger>
          }
          <UsersDisableButton
            user={user}
            canEdit={canEdit}
            className='users-table__button--disable'
          />
        </Table.Actions>
      </Table.Cell>
    </Table.Row>
  )
}

UsersTableRow.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    email: PropTypes.string,
    enabled: PropTypes.bool.isRequired
  }).isRequired
}

export default UsersTableRow
