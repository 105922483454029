import T from 'prop-types'
import classnames from 'classnames'

import './IncreasedOrDecreasedRecord.less'

const IncreasedOrDecreasedRecord = ({
  icon: IconComponent,
  currentRecord,
  previousRecord
}) => {
  const isDecreased = currentRecord?.amount < previousRecord?.amount

  return (
    <div
      className={classnames('increased-decreased-record',
        `increased-decreased-record--${isDecreased ? 'red' : 'green'}`,
        `increased-decreased-record--${isDecreased ? 'down' : 'up'}`
      )}
    >
      <IconComponent />
    </div>
  )
}

IncreasedOrDecreasedRecord.propTypes = {
  IconComponent: T.elementType,
  currentRecord: T.object.isRequired,
  previousRecord: T.object
}

export { IncreasedOrDecreasedRecord }
