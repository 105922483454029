"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RepaymentTypes = exports.RateTypes = exports.PlanStatuses = exports.LifeCoverInPlaceValues = exports.DebtTypesLabels = exports.DebtTypes = exports.CICInPlaceValues = exports.AccountHolders = void 0;
const AccountHolders = exports.AccountHolders = Object.freeze({
  CLIENT: 'CLIENT',
  JOINT: 'JOINT',
  PARTNER: 'PARTNER'
});
const CICInPlaceValues = exports.CICInPlaceValues = Object.freeze({
  BOTH_LIVES_PARTIALLY_COVERED: 'BOTH_LIVES_PARTIALLY_COVERED',
  LIFE_1_ONLY: 'LIFE_1_ONLY',
  LIFE_2_ONLY: 'LIFE_2_ONLY',
  NO: 'NO',
  YES: 'YES'
});
const DebtTypes = exports.DebtTypes = Object.freeze({
  CAR_FINANCE: 'CAR_FINANCE',
  CREDIT_CARD: 'CREDIT_CARD',
  EMPLOYER_LOAN: 'EMPLOYER_LOAN',
  FAMILY_LOAN: 'FAMILY_LOAN',
  HIRE_PURCHASE: 'HIRE_PURCHASE',
  OTHER: 'OTHER',
  PERSONAL_LOAN: 'PERSONAL_LOAN',
  STUDENT_LOAN: 'STUDENT_LOAN',
  UNKNOWN_CASH_CALC: 'UNKNOWN_CASH_CALC'
});
const LifeCoverInPlaceValues = exports.LifeCoverInPlaceValues = Object.freeze({
  BOTH_LIVES_PARTIALLY_COVERED: 'BOTH_LIVES_PARTIALLY_COVERED',
  LIFE_1_ONLY: 'LIFE_1_ONLY',
  LIFE_2_ONLY: 'LIFE_2_ONLY',
  NO: 'NO',
  YES: 'YES'
});
const PlanStatuses = exports.PlanStatuses = Object.freeze({
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  LAPSED: 'LAPSED',
  LIVE: 'LIVE',
  NTU: 'NTU',
  OFFERED: 'OFFERED',
  REPAID: 'REPAID'
});
const RateTypes = exports.RateTypes = Object.freeze({
  FIXED: 'FIXED',
  VARIABLE: 'VARIABLE'
});
const RepaymentTypes = exports.RepaymentTypes = Object.freeze({
  INTEREST_ONLY: 'INTEREST_ONLY',
  REPAYMENT: 'REPAYMENT',
  REPAYMENT_AND_INTEREST_ONLY: 'REPAYMENT_AND_INTEREST_ONLY'
});
const DebtTypesLabels = exports.DebtTypesLabels = Object.freeze({
  [DebtTypes.CAR_FINANCE]: 'Car Finance',
  [DebtTypes.CREDIT_CARD]: 'Credit Card',
  [DebtTypes.EMPLOYER_LOAN]: 'Employer Loan',
  [DebtTypes.FAMILY_LOAN]: 'Family Loan',
  [DebtTypes.HIRE_PURCHASE]: 'Hire Purchase',
  [DebtTypes.OTHER]: 'Other',
  [DebtTypes.PERSONAL_LOAN]: 'Personal Loan',
  [DebtTypes.STUDENT_LOAN]: 'Student Loan',
  [DebtTypes.UNKNOWN_CASH_CALC]: 'Unknown (CashCalc)'
});
var _default = exports.default = {
  AccountHolders,
  CICInPlaceValues,
  DebtTypes,
  LifeCoverInPlaceValues,
  PlanStatuses,
  RateTypes,
  RepaymentTypes,
  DebtTypesLabels
};