import { ConfirmationModal, DropdownMenu } from './../../../misc'

const DropdownMenuItem = props => (
  <DropdownMenu.Item {...props}>Cancel signing process</DropdownMenu.Item>
)

const CancelSigningButton = ({ onCancelESignature }) => (
  <ConfirmationModal.Trigger
    as={DropdownMenuItem}
    modalProps={{
      confirmationCallback: onCancelESignature,
      heading: 'Cancel signing process',
      text: 'Are you sure you want to cancel the signing process?',
      awaitCallback: true
    }}
  />
)

export { CancelSigningButton }
