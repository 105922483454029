import T from 'prop-types'
import classnames from 'classnames'

const ModalStickyFooter = ({ className, children }) => (
  <div className={classnames('modal__sticky-layout__content__footer', className)}>
    {children}
  </div>
)

ModalStickyFooter.propTypes = {
  children: T.node.isRequired,
  className: T.string
}

export { ModalStickyFooter }
