import { useCallback, useEffect } from 'react'
import T from 'prop-types'
import { useMutation } from '@apollo/client'

import { RemoveServiceLevelsButton } from './RemoveServiceLevelsButton'
import { parseGraphqlErrors } from '../../../../helpers/error'

import removeServiceLevelGql from '../../../../graphql/mutations/serviceLevel/removeServiceLevel.graphql'
import serviceLevelsGql from '../../../../graphql/queries/serviceLevel/serviceLevels.graphql'

const RemoveServiceLevelsButtonContainer = props => {
  const { serviceLevelId, closeModal, setErrors, ...rest } = props

  const [removeMutation, { called, loading, error }] = useMutation(removeServiceLevelGql, {
    variables: {
      id: serviceLevelId
    },
    onError: (err) => {
      if (setErrors) {
        setErrors(parseGraphqlErrors(
          err,
          'There was an error deleting the service level, please try again later'))
      } else {
        throw new Error(err)
      }
    },
    update: (cache, serviceLevelId) => {
      const { id: removedInstanceId } = serviceLevelId.data.removeServiceLevel
      try {
        const { serviceLevels } = cache.readQuery({
          query: serviceLevelsGql
        })

        const filterFn = ({ id }) => id !== removedInstanceId

        cache.writeQuery({
          query: serviceLevelsGql,
          data: {
            serviceLevels: serviceLevels.filter(filterFn)
          }
        })
      } catch (error) {
        // Throw error here
      }
    }
  })

  useEffect(() => {
    if (called && !loading && !error) {
      closeModal && closeModal()
    }
  }, [called, loading, error, closeModal])

  const removeServiceLevelCallback = useCallback(
    async (removeConfirmationFlag) => {
      if (removeConfirmationFlag) {
        await removeMutation()
      }
    }, [removeMutation]
  )

  return (
    <RemoveServiceLevelsButton {...{
      removeServiceLevelCallback,
      ...rest
    }}
    />
  )
}

RemoveServiceLevelsButtonContainer.propTypes = {
  serviceLevelId: T.string.isRequired,
  closeModal: T.func,
  setErrors: T.func
}

export { RemoveServiceLevelsButtonContainer }
