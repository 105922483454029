"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkflowTargetTypes = exports.WorkflowActionTypes = exports.WorkflowActionTypeLabels = exports.WorkflowActionStatuses = exports.WorkflowActionStatusLabels = void 0;
const WorkflowTargetTypes = exports.WorkflowTargetTypes = Object.freeze({
  CLIENT: 'CLIENT'
});
const WorkflowActionTypes = exports.WorkflowActionTypes = Object.freeze({
  TASK: 'TASK'
});
const WorkflowActionStatuses = exports.WorkflowActionStatuses = Object.freeze({
  PENDING: 'PENDING',
  SCHEDULED: 'SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
});
const WorkflowActionStatusLabels = exports.WorkflowActionStatusLabels = Object.freeze({
  [WorkflowActionStatuses.PENDING]: 'Not created yet',
  [WorkflowActionStatuses.SCHEDULED]: 'Not created yet',
  [WorkflowActionStatuses.IN_PROGRESS]: 'In progress',
  [WorkflowActionStatuses.COMPLETED]: 'Completed'
});
const WorkflowActionTypeLabels = exports.WorkflowActionTypeLabels = Object.freeze({
  [WorkflowActionTypes.TASK]: 'Task'
});