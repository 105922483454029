import {
  map,
  propSatisfies,
  filter,
  pipe,
  values,
  omit,
  concat,
  prop,
  propEq
} from 'ramda'

export const parseFilesToCreate = (fileList) => {
  const result = []

  for (let i = 0; i < fileList.length; i++) {
    const currFile = fileList[i]

    if (!currFile?.id) {
      result.push({
        name: currFile.name,
        size: currFile.size,
        // Indexes will allow to easily identify how the upload URLs
        // and documents received from the API correspond with the files.
        index: i
      })
    }
  }

  return result.filter(Boolean)
}

export const mapFile = pipe(
  filter(prop('file')),
  map(prop('file'))
)

const mapFileName = map(prop('fileName'))

const filterOutDuplicates = attachments => filter(
  propSatisfies(
    fileName => !mapFileName(attachments).includes(fileName),
    'fileName'
  )
)

const mapFileAndName = map(file => ({ file, fileName: file.name }))

export const mapFilesToAttachments = (attachments, fileList) =>
  pipe(
    omit(['length', 'item']),
    values,
    mapFileAndName,
    filterOutDuplicates(attachments),
    concat(attachments)
  )(fileList)

export const parseFilesToLocalState = (files, selectedFileList) => {
  const findFile = fileName =>
    mapFile(selectedFileList).find(propEq('name', fileName))

  return files.map(({ attachment, uploadUrl }) => ({
    ...attachment,
    uploadUrl,
    file: findFile(attachment.fileName)
  }))
}
