import { Button, Card } from './../../misc'

const FAQCard = () => {
  return (
    <Card className='customer-support-section__faq-card'>
      <Card.Heading>Knowledge base</Card.Heading>
      <i> Please check our knowledge base to see if it helps with your query </i>

      <Button as='a' href='https://support.elen.co/support/home' target='_blanc' color={Button.Colors.BLUE}> FAQ </Button>
    </Card>
  )
}

export { FAQCard }
