import { useQuery } from '@apollo/client'

import { HeaderLogo } from './HeaderLogo'
import { Logo } from './Logo'
import { LogoIcon } from '../../icons'

import companyLogoGql from '../../../graphql/queries/user/companyLogo.graphql'

const HeaderLogoContainer = ({ additionalIcon, className, ...restProps }) => {
  const { data, loading } = useQuery(companyLogoGql, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const logoUrl = data?.companyLogo?.logoUrl
  const initialLoading = loading && !data

  const renderIcon = () => (
    <>
      {additionalIcon ? additionalIcon() : null}
      <Logo {...{
        as: logoUrl || initialLoading ? 'img' : LogoIcon,
        logoUrl,
        className,
        ...restProps
      }}
      />
    </>
  )

  return (
    <HeaderLogo
      renderIcon={renderIcon}
      className={className}
      {...restProps}
    />
  )
}

export { HeaderLogoContainer }
