import { useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { debounce } from 'debounce'

import { InitialsAvatar } from '../InitialsAvatar'
import { Spinner } from '../../misc'
import { PlaceholderConstants } from './constants'
import { ChevronRightIcon, SearchIcon, ChevronLeftIcon, CloseXIcon } from '../../icons'

import './ClientGlobalSearch.less'
import { Input } from '../../form/inputs'
import { useOnClickOutside } from '../../../hooks'

const ClientGlobalSearch = ({
  autoSuggestion = true,
  placeholder = PlaceholderConstants.FIND_CLIENT,
  loading = false,
  onInputChange = () => {},
  handleSelectChange,
  autoCompleteData,
  handleShowMore = () => {},
  showSearchIconOnMobile = true,
  debounceTime = 600
}) => {
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('')
  const [showSearchOnMobile, setShowSearchOnMobile] = useState(false)
  const [popOverOpen, setPopOverOpen] = useState(false)

  const globalSearchRef = useRef(null)
  useOnClickOutside(globalSearchRef, () => setPopOverOpen(false))

  const data = Array.isArray(autoCompleteData) ? autoCompleteData : []

  // setting inputValue in this function causes input field to be slow.
  // i.e update after debounce time and only updates the last value, hence the need to extract it and set the value directly in the onChange function so that clearing the input field will be possible
  const handleInputChange = useCallback(
    debounce(onInputChange, debounceTime),
    []
  )

  const clearAndCloseSearch = () => {
    setInputValue('')
    setShowSearchOnMobile(false)
  }

  useEffect(() => {
    const handleEscKey = e => {
      if (e.key === 'Escape') {
        setPopOverOpen(false)
      }
    }

    if (!inputValue) {
      setPopOverOpen(false)
    } else if (data?.length) {
      setPopOverOpen(true)
    }

    document.addEventListener('keydown', handleEscKey)
    return () => document.removeEventListener('keydown', handleEscKey)
  }, [data, inputValue])

  return (
    <>
      {!showSearchOnMobile && showSearchIconOnMobile && (
        <div className='mobile-search-icon' onClick={() => setShowSearchOnMobile(true)}>
          <SearchIcon />
        </div>
      )}

      <div className={showSearchOnMobile ? 'show-search-on-mobile' : ''} ref={globalSearchRef}>
        {showSearchOnMobile && showSearchIconOnMobile && (
          <div onClick={() => clearAndCloseSearch()}>
            <ChevronLeftIcon className='global-chevron-left' />
          </div>
        )}

        <div onSelect={handleSelectChange} className='global-search'>
          <div>
            <Input
              value={inputValue}
              handleChange={(e) => {
                setInputValue(e)
                handleInputChange(e)
              }}
              placeholder={placeholder}
              className={`${autoSuggestion ? 'global-search__autocomplete' : 'global-search__input'} ${showSearchOnMobile ? 'global-search__showOnMobile' : !showSearchIconOnMobile ? '' : 'global-search__hideOnMobile'} `}
            />

            {showSearchOnMobile && (
              <div className='global-search__close-section' onClick={() => setInputValue('')}>
                <CloseXIcon className='global-search__close-section__close-icon' />
              </div>
            )}
          </div>
        </div>

        {autoSuggestion && (popOverOpen || loading) && (
          <div className='global-search__popover'>
            {loading && !data.length && (
              <div className='global-search__popover__loading-container__web'>
                <Spinner config={{
                  lines: 7,
                  length: 4,
                  width: 5,
                  radius: 5
                }}
                />
              </div>
            )}

            {loading && !data.length && (
              <div className='global-search__popover__loading-container__mobile'>
                <Spinner />
              </div>
            )}

            <div className='global-search__popover__list-container'>
              {!!data.length && data?.map((autoComplete) => (
                <div
                  className='global-search__popover__list-cont' key={autoComplete.id} onClick={() => {
                    setPopOverOpen(false)
                    navigate(`/secure/clients/${autoComplete?.id}/summary`)
                  }}
                >
                  <InitialsAvatar user={autoComplete} />
                  <span key={autoComplete?.id} className='global-search__popover__option'>
                    {autoComplete?.fullName}
                  </span>
                </div>
              ))}
            </div>

            {data.length > 20 && (
              <div
                className='global-search__popover__show-all' onClick={() => {
                  setPopOverOpen(false)
                  handleShowMore()
                }}
              >
                Show all results <ChevronRightIcon className='global-search__popover__show-all__chevron-right' />
              </div>
            )}

            {!loading && !data.length && (
              <div className='global-search__popover__emptyState'>
                No clients found
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

ClientGlobalSearch.propTypes = {
  autoSuggestion: PropTypes.bool,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  autoCompleteData: PropTypes.arrayOf(PropTypes.object),
  handleShowMore: PropTypes.func,
  debounceTime: PropTypes.number
}

export { ClientGlobalSearch }
