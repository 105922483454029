import { useCallback, useState } from 'react'
import { withFormik, connect } from 'formik'
import { compose } from 'ramda'
import { useQuery, useMutation } from '@apollo/client'

import { withCurrentUser } from '../../hoc/container'
import { buildAssigneeOptions } from '../../tasks/helpers'
import { TaskCard } from './TaskCard'
import companyUsersGql from '../../../graphql/queries/user/companyUsers.graphql'
import updateTaskGql from '../../../graphql/mutations/tasks/updateTask.graphql'
import { buildInitialValues } from '../../../helpers/form'
import { taskSchema } from '../../../schemas/tasks/taskSchema'

export const TaskCardContainer = (props) => {
  const { currentUser, draggableRef, formik: { values: { task } } } = props

  const [openAssignee, setOpenAssignee] = useState(false)
  const handleAssigneeMouseDown = () => setOpenAssignee(!openAssignee)

  const [mutate] = useMutation(updateTaskGql)

  const { data: usersData } = useQuery(companyUsersGql)
  const assigneeOptions = buildAssigneeOptions({
    users: usersData?.users || [],
    currentUser
  })

  const onSelectAssignee = useCallback(
    async (assignedTo) => {
      const input = {
        attachments: task?.attachments,
        clientId: task?.client?.id || null,
        description: task?.description || null,
        dueDate: task?.dueDate || null,
        id: task?.id,
        status: task?.status,
        summary: task?.summary || null,
        type: task?.type,
        assignedTo: assignedTo || null
      }

      setOpenAssignee(false)

      return await mutate({
        variables: {
          input
        }
      })
    },
    []
  )

  return (
    <TaskCard
      {
        ...{
          ...props,
          assigneeOptions,
          onSelectAssignee,
          ref: draggableRef,
          handleAssigneeMouseDown,
          openAssignee,
          setOpenAssignee
        }
      }
    />
  )
}

const EnhancedTaskCard = compose(
  withCurrentUser({ fetchPolicy: 'cache-only' }),
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: taskSchema,
    mapPropsToValues: ({ task = {} }) => buildInitialValues(taskSchema, {
      task,
      assignedTo: task?.assignedTo?.id
    })
  }), connect)(TaskCardContainer)

export { EnhancedTaskCard as TaskCard }
