import T from 'prop-types'

const List = ({ list }) => (
  <div className='list-tooltip__list'>
    {list.map((node, index) => (
      <div className='list-tooltip__list__item' key={index}> {node} </div>
    ))}
  </div>
)

List.defaultProps = {
  list: []
}

List.propTypes = {
  list: T.arrayOf(T.node).isRequired
}

export { List }
