import { useState, useEffect } from 'react'
import T from 'prop-types'

import { ConfirmationModal } from '../../misc'
import { PrintIcon, ChevronLeftIcon } from './../../icons'
import { MobileActionsDots } from './MobileActionsDots'

import './PreviewerPDF.less'

const HeaderPDF = props => {
  const {
    fileName,
    downloadUrl,
    onZoomIn,
    onZoomOut,
    onCancel,
    cancelModalProps,
    documentUnavailable
  } = props

  const [url, setUrl] = useState()

  useEffect(() => {
    const getUrl = async () => {
      const res = await window.fetch(downloadUrl)
      let blob = await res.blob()
      blob = new window.Blob([blob], { type: 'application/pdf' })
      const blobURL = URL.createObjectURL(blob)
      setUrl(blobURL)
    }
    if (downloadUrl) getUrl()
  }, [downloadUrl])

  return (
    <div className='preview-pdf__header'>
      <div className='preview-pdf__header__left-container'>
        {!cancelModalProps
          ? (
            <ChevronLeftIcon onClick={onCancel} />
            )
          : (
            <ConfirmationModal.Trigger
              as={ChevronLeftIcon}
              modalProps={cancelModalProps}
            />
            )}

        <h3 className='preview-pdf__header__left-container__file-name'>
          {documentUnavailable ? 'Back to Documents' : fileName}
        </h3>
      </div>

      {!documentUnavailable && (
        <div className='preview-pdf__header__right-container'>
          <MobileActionsDots
            className='preview-pdf__header__right-container__mobile-actions-dots'
            {...{ url, onZoomIn, onZoomOut }}
          />
          {url && (
            <a href={url} target='_blank' rel='noreferrer'>
              <PrintIcon />
            </a>
          )}
          <div className='preview-pdf__header__right-container__zoom-buttons'>
            <div onClick={onZoomIn}> + </div>
            <div onClick={onZoomOut}> - </div>
          </div>
        </div>
      )}
    </div>
  )
}

HeaderPDF.propTypes = {
  fileName: T.string,
  downloadUrl: T.string,
  onZoomIn: T.func.isRequired,
  onZoomOut: T.func.isRequired,
  onCancel: T.func.isRequired,
  documentUnavailable: T.bool,
  cancelModalProps: T.shape({
    heading: T.string,
    text: T.string,
    confirmationCallback: T.func
  })
}

export { HeaderPDF }
