import { useEffect } from 'react'

import { logout } from './../auth'

const milliseconds = 1800000
const eventsArr = ['mousemove', 'keyup', 'touchstart']

export const useAutoLogout = () => {
  let time

  useEffect(() => {
    if (!time) {
      time = setTimeout(logout, milliseconds)
    }

    const resetTimer = () => {
      if (time) {
        clearTimeout(time)
        time = setTimeout(logout, milliseconds)
      }
    }

    eventsArr.forEach(ev => document.addEventListener(ev, resetTimer))

    return () => {
      eventsArr.forEach(ev => document.removeEventListener(ev, resetTimer))
    }
  }, [])
}
