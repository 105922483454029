import { isEmpty } from 'ramda'

import { ClientTypes } from '@elenfs/elen-constants'

import { formatDate } from './date'
import { Tooltip } from '../components/misc'
import { InfoIcon } from '../components/icons'

export const getFullName = (client) => (client.fullName)

export const formatAdviserData = ({ from, to, adviser }) => {
  const dateFrom = from ? formatDate(from) : 'Not specified'
  const dateTo = to ? formatDate(to) : 'Present'

  return `${adviser.fullName}  |  ${dateFrom} - ${dateTo}`
}

export const buildArrayFieldData = (keyNameMap, arrayField, formattersMap = {}) => {
  return arrayField
    .map((fieldValue, index) => {
      return Object.keys(keyNameMap).map((nameKey) => {
        const label = index === 0 ? keyNameMap[nameKey] : ''

        const formatter = formattersMap[nameKey]
        const unformattedValue = fieldValue[nameKey]
        const value = formatter ? formatter(unformattedValue) : unformattedValue

        return {
          label,
          value
        }
      })
    })
    .flat()
}

export const validateAndNavigate = async ({
  setSchema,
  schema,
  validate,
  nextLocation,
  navigate
}) => {
  await setSchema(schema)

  const errors = await validate()

  if (isEmpty(errors)) {
    navigate(`../${nextLocation}`, { replace: true })
  } else {
    return false
  }
}

export const formatNote = (note, index) => {
  return note
    ? (
      <Tooltip.Trigger
        as={InfoIcon}
        tooltip={Tooltip.List}
        tooltipId={index}
        tooltipProps={{
          title: 'Notes',
          list: [<span key={index}>{note}</span>]
        }}
      />
      )
    : null
}

export const isCorporateClient = client => (
  client?.type === ClientTypes.CORPORATE
)
