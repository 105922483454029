import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'ramda'
import T from 'prop-types'
import constants from '@elenfs/elen-constants'
import { withFormik, connect } from 'formik'

import { Form } from '../../form'
import FormErrorMessages from '../../form/FormErrorMessages'
import { InputField } from '../../form/fields'
import { Button, LoadingMessage } from '../../misc'
import { loginSchema } from '../../../schemas/users/loginSchema'

import './LoginForm.less'
import { buildInitialValues } from '../../../helpers/form'

const { UserTypes } = constants.user

const LoginForm = (props) => {
  const {
    userType,
    loading,
    error: serverError,
    errors: validationErrors,
    handleSubmit
  } = props

  const forgotPasswordLinkPath = useMemo(
    () => userType === UserTypes.CLIENT ? '/clients/forgot-password' : '/forgot-password',
    [userType])

  return (
    <Form className='login-form' onSubmit={handleSubmit}>
      <Form.Section>
        <InputField
          name='email'
          label='Email'
        />

        <InputField
          label='Password'
          name='password'
          inputProps={{
            type: 'password'
          }}
        />
      </Form.Section>

      <Link className='login-form__link' to={forgotPasswordLinkPath}>
        Forgotten password?
      </Link>

      {loading && <LoadingMessage text='Loading...' />}

      {
        !loading && (
          <FormErrorMessages
            serverError={serverError}
            serverErrorMessage={serverError.message}
            validationErrors={validationErrors}
          />
        )
      }

      <Button
        type='submit'
        color={Button.Colors.BLUE}
        className='login-form__login-button'
        disabled={loading}
      >
        Login
      </Button>
    </Form>
  )
}

const LoginFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    mapPropsToValues: () => {
      const values = buildInitialValues(loginSchema)
      return values
    },
    handleSubmit: async (values, { props: { onSubmit } }) => {
      await onSubmit(loginSchema.cast(values))
    }
  }),
  connect
)(LoginForm)

LoginFormEnhanced.defualtProps = {
  loading: false,
  error: '',
  userType: UserTypes.COMPANY_USER
}

LoginFormEnhanced.propTypes = {
  error: T.string,
  userType: T.oneOf(Object.values(UserTypes)),
  /**
   * Callback to submit the form.
   */
  onSubmit: T.func.isRequired,
  /**
   * Is onSubmit executing.
   */
  loading: T.bool
}

export { LoginFormEnhanced as LoginForm }
