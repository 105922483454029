import T from 'prop-types'
import classnames from 'classnames'

import { FormDivider } from '../FormDivider'

import './FormGroup.less'

const FormGroup = props => {
  const { children, className, label, ...restProps } = props

  return (
    <div className={classnames('form-new__group', className)} {...restProps}>
      {label && (
        <>
          <span className='form-new__group__label'> {label} </span>
          <FormDivider />
        </>
      )}
      {children}
    </div>
  )
}

FormGroup.propTypes = {
  children: T.node.isRequired,
  label: T.string,
  className: T.string
}

export { FormGroup }
