import { useRef, useEffect, useState } from 'react'
import classnames from 'classnames'
import T from 'prop-types'
import moment from 'moment'
import { prop, sum } from 'ramda'

import { EnvelopeIcon, DropdownIcon } from './../../icons'
import { MessageTypes } from '../../../constants/message'
import { Badge } from '../'

import './MessageNotificationList.less'

const mobileWidth = 375
const isMobile = () => window.innerWidth <= mobileWidth

const MessageNotificationList = props => {
  const {
    className,
    messagesNotificationList,
    navigateOnMsgClick,
    isClientPortal
  } = props

  const [showAll, setShowAll] = useState(false)
  const listContainerRef = useRef()

  const isEmptyList = !messagesNotificationList.length
  const showMoreBtn = messagesNotificationList.length > 1

  useEffect(() => {
    if (isMobile()) {
      const messageItems = [...listContainerRef.current.children]
      if (messageItems.length) {
        const firstMessageHeight = messageItems[0].offsetHeight
        const allMessagesHeight = sum(messageItems.map(prop('offsetHeight')))
        listContainerRef.current.style.maxHeight =
          (showAll ? allMessagesHeight : firstMessageHeight) + 'px'
      }
    }
  }, [listContainerRef.current, showAll])

  const toggleShowAll = () => setShowAll(current => !current)

  return (
    <div
      className={classnames('message-notification-list', className, {
        'message-notification-list--empty-list': isEmptyList
      })}
    >
      <div
        ref={listContainerRef}
        className='message-notification-list__container'
      >
        {messagesNotificationList.map(
          ({ client, createdAt, body, count, clientId, fromUser, type }) => (
            <div
              className='message-notification-list__item'
              onClick={() => navigateOnMsgClick(clientId)}
              key={clientId}
            >
              <EnvelopeIcon className='message-notification-list__item__envelope-icon' />

              <div
                className={classnames('message-notification-list__item__info', {
                  'message-notification-list__item__info--no-margin-right': isClientPortal
                })}
              >
                <div className='message-notification-list__item__info__name-and-date'>
                  <div className='message-notification-list__item__info__name-and-date__name'>
                    {type === MessageTypes.SYSTEM ? 'System message' : client?.fullName || fromUser?.fullName}
                  </div>
                  <div className='message-notification-list__item__info__name-and-date__date'>
                    {moment(createdAt).format('D MMM YYYY - HH:mm')}
                  </div>
                </div>

                <div className='message-notification-list__item__message-body'>
                  {body}
                </div>
              </div>

              {!isClientPortal && (
                <Badge
                  color={Badge.Colors.BLUE}
                  className='message-notification-list__item__count-badge'
                >
                  {count}
                </Badge>
              )}
            </div>
          )
        )}
      </div>

      {isEmptyList && (
        <div className='message-notification-list__no-messages'>
          <EnvelopeIcon />
          <i>No new messages</i>
        </div>
      )}

      {showMoreBtn && isMobile() && (
        <span
          className={classnames('message-notification-list__view-more', {
            'message-notification-list__view-more--rotate-up': showAll
          })}
          onClick={toggleShowAll}
        >
          View {showAll ? 'less' : 'more'} <DropdownIcon />
        </span>
      )}
    </div>
  )
}

MessageNotificationList.defaultProps = {
  messagesNotificationList: []
}

MessageNotificationList.propTypes = {
  messagesNotificationList: T.arrayOf(
    T.shape({
      count: T.number.isRequired,
      clientId: T.string.isRequired,
      client: T.shape({ fullName: T.string.isRequired }),
      createdAt: T.string.isRequired,
      body: T.string.isRequired
    })
  ),
  navigateOnMsgClick: T.func.isRequired
}

export { MessageNotificationList }
