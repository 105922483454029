import PropTypes from 'prop-types'

import Summary from '../../misc/Summary'

import './TransactImportStats.less'

const buildSummaryData = (loading, error, stats) => {
  const { policiesCount = 0 } = stats || {}

  let rowValue
  if (loading) {
    rowValue = (
      <span className='transact-import-stats__loading'>
        Loading...
      </span>
    )
  } else if (error) {
    rowValue = (
      <span className='transact-import-stats__error'>
        Error
      </span>
    )
  }

  return [
    { label: 'Policies', value: rowValue || policiesCount },
    { label: 'Type', value: 'Auto Sync' }
  ]
}

const TransactImportStats = ({ loading, error, stats }) => (
  <Summary
    className='transact-import-stats'
    data={buildSummaryData(loading, error, stats)}
  />
)

TransactImportStats.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  stats: PropTypes.shape({
    policiesCount: PropTypes.number
  })
}

export default TransactImportStats
