import T from 'prop-types'
import classnames from 'classnames'

import { InitialsAvatar } from '../../misc'
import { Link } from 'react-router-dom'

import { formatDate } from './../../../helpers/date'

import './DashboardClientsList.less'

const DashboardClientsList = props => {
  const {
    className,
    clientList,
    withInitials,
    ActionComponent,
    emptyListText
  } = props

  const isEmptyList = !clientList.length

  return (
    <div className={classnames('dashboard-client-list', className)}>
      {clientList.map(
        ({ id, firstName, lastName, fullName, name, date, knownAs, hideAction }) => (
          <div
            key={id}
            className={classnames('dashboard-client-list__item', {
              'dashboard-client-list__item--with-initials': withInitials
            })}
          >
            {withInitials && (
              <InitialsAvatar
                user={{ id, firstName, lastName, fullName, nameOfBusiness: name }}
                size='extra-large'
              />
            )}

            <div className='dashboard-client-list__item__info'>
              <Link
                to={`/secure/clients/${id}/summary`}
                className='dashboard-client-list__item__info__name'
              >
                {fullName || `${firstName} ${lastName}`}
              </Link>

              {date && (
                <div className='dashboard-client-list__item__info__secondary-text'>
                  {formatDate(date)}
                </div>
              )}

              {knownAs && (
                <div className='dashboard-client-list__item__info__secondary-text fade'>
                  Known as {knownAs}
                </div>
              )}
            </div>

            {ActionComponent && !hideAction && (
              <div className='dashboard-client-list__item__modal-trigger'>
                <ActionComponent clientId={id} clientName={fullName} />
              </div>
            )}
          </div>
        )
      )}

      {isEmptyList && (
        <div className='dashboard-client-list__empty-text'>
          <i>{emptyListText}</i>
        </div>
      )}
    </div>
  )
}

DashboardClientsList.defaultProps = {
  clientList: [],
  emptyListText: 'No records'
}

DashboardClientsList.propTypes = {
  clientList: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      firstName: T.string,
      lastName: T.string,
      name: T.string,
      fullName: T.string,
      date: T.string,
      knownAs: T.string,
      hideAction: T.bool
    })
  ),
  withInitials: T.bool,
  ActionComponent: T.elementType
}

export { DashboardClientsList }
