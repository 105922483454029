import InvestmentPolicyForm from './InvestmentPolicyForm'
import policyFormContainer from '../../PolicyFormContainer'
import createInvestmentPolicyGql from '../../../../graphql/mutations/policy/createInvestmentPolicy.graphql'
import updateInvestmentPolicyGql from '../../../../graphql/mutations/policy/updateInvestmentPolicy.graphql'

const InvestmentPolicyFormContainer = policyFormContainer({
  createPolicyGql: createInvestmentPolicyGql,
  updatePolicyGql: updateInvestmentPolicyGql
})(InvestmentPolicyForm)

export default InvestmentPolicyFormContainer
