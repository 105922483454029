import { useQuery } from '@apollo/client'

import Spinner from '../../misc/Spinner'
import ErrorMessage from '../../misc/ErrorMessage'
import TransactImportPage from './TransactImportPage'
import transactConnectionGql
  from '../../../graphql/queries/dataImport/transactConnection.graphql'

const TransactImportPageContainer = (props) => {
  const { loading, error, data } = useQuery(
    transactConnectionGql,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const initialLoading = loading && !data

  if (initialLoading) return <Spinner />
  if (error) return <ErrorMessage text='Failed to get Transact data' />

  return (
    <TransactImportPage
      transactData={data && data.dataImportTransactConnection}
      {...props}
    />
  )
}

export default TransactImportPageContainer
