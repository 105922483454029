import * as yup from 'yup'

import moneySchema from '../helpers/moneySchema'
import dateSchema from '../helpers/dateSchema'

const policyValuationSchema = yup.object().shape({
  date: dateSchema()
    .label('Date')
    .required(),

  value: moneySchema({
    amountLabel: 'Value',
    currencyLabel: 'Value currency'
  }).required()
}).noUnknown(true)

export { policyValuationSchema }
