import { useMutation, useLazyQuery } from '@apollo/client'

import createWorkflowTemplateGql from '../graphql/mutations/workflow/createWorkflowTemplate.graphql'
import updateWorkflowTemplateGql from '../graphql/mutations/workflow/updateWorkflowTemplate.graphql'
import triggerWorkflowTemplateRunGql from '../graphql/mutations/workflow/triggerWorkflowTemplateRun.graphql'
import removeWorkflowTemplateGql from '../graphql/mutations/workflow/removeWorkflowTemplate.graphql'

import workflowTemplateGql from '../graphql/queries/workflow/workflowTemplate.graphql'
import workflowTemplatesGql from '../graphql/queries/workflow/workflowTemplates.graphql'
import clientWorkflowInstancesGql from '../graphql/queries/workflow/clientWorkflowInstances.graphql'
import workflowInstanceGql from '../graphql/queries/workflow/workflowInstance.graphql'

export const useWorkflowGql = ({ onSuccessfulMutation } = {}) => {
  onSuccessfulMutation = onSuccessfulMutation || (() => {})

  const [
    removeWorkflowTemplate,
    {
      loading: removeWorkflowTemplateLoading,
      error: removeWorkflowTemplateError
    }
  ] = useMutation(removeWorkflowTemplateGql)

  const [
    triggerWorkflowTemplateRun,
    {
      loading: triggerWorkflowTemplateRunLoading,
      error: triggerWorkflowTemplateRunError
    }
  ] = useMutation(triggerWorkflowTemplateRunGql)

  const [
    createWorkflowTemplate,
    {
      loading: createWorkflowTemplateLoading,
      error: createWorkflowTemplateError
    }
  ] = useMutation(createWorkflowTemplateGql, {
    onCompleted: onSuccessfulMutation
  })

  const [
    updateWorkflowTemplate,
    {
      loading: updateWorkflowTemplateLoading,
      error: updateWorkflowTemplateError
    }
  ] = useMutation(updateWorkflowTemplateGql, {
    onCompleted: onSuccessfulMutation
  })

  const [
    fetchWorkflowTemplate,
    {
      data: workflowTemplateData,
      loading: loadingFetchWorkflowTemplate,
      error: errorFetchWorkflowTemplate,
      called: fetchWorkflowTemplateCalled
    }
  ] = useLazyQuery(workflowTemplateGql, {
    fetchPolicy: 'cache-and-network'
  })

  const [
    fetchWorkflowTemplates,
    {
      data: workflowTemplatesData,
      loading: loadingFetchWorkflowTemplates,
      error: errorFetchWorkflowTemplates,
      called: fetchWorkflowTemplatesCalled
    }
  ] = useLazyQuery(workflowTemplatesGql, {
    fetchPolicy: 'cache-and-network'
  })

  const [
    fetchClientWorkflowInstances,
    {
      data: fetchClientWorkflowInstancesData,
      loading: loadingFetchClientWorkflowInstances,
      error: errorFetchClientWorkflowInstances,
      called: fetchClientWorkflowInstancesCalled
    }
  ] = useLazyQuery(clientWorkflowInstancesGql, {
    fetchPolicy: 'cache-and-network'
  })

  const [
    fetchWorkflowInstance,
    {
      data: workflowInstanceData,
      loading: loadingFetchWorkflowInstance,
      error: errorFetchWorkflowInstance,
      called: fetchWorkflowInstanceCalled
    }
  ] = useLazyQuery(workflowInstanceGql, { fetchPolicy: 'cache-and-network' })

  return {
    createWorkflowTemplate,
    workflowTemplateData,
    fetchWorkflowTemplate,
    fetchWorkflowTemplateCalled,
    updateWorkflowTemplate,
    mutationLoading:
      updateWorkflowTemplateLoading ||
      createWorkflowTemplateLoading ||
      triggerWorkflowTemplateRunLoading ||
      removeWorkflowTemplateLoading,
    mutationError:
      updateWorkflowTemplateError ||
      createWorkflowTemplateError ||
      removeWorkflowTemplateError,
    triggerWorkflowTemplateRunError,
    fetchLoading:
      (loadingFetchWorkflowTemplate && !workflowTemplateData) ||
      (loadingFetchWorkflowTemplates && !workflowTemplatesData) ||
      (loadingFetchClientWorkflowInstances && !fetchClientWorkflowInstancesData) ||
      (loadingFetchWorkflowInstance && !workflowInstanceData),
    fetchError:
      errorFetchWorkflowTemplate ||
      errorFetchWorkflowTemplates ||
      errorFetchClientWorkflowInstances ||
      errorFetchWorkflowInstance,
    fetchWorkflowTemplates,
    workflowTemplatesData,
    fetchWorkflowTemplatesCalled,
    fetchClientWorkflowInstancesData,
    fetchClientWorkflowInstancesCalled,
    fetchClientWorkflowInstances,
    triggerWorkflowTemplateRun,
    fetchWorkflowInstance,
    fetchWorkflowInstanceCalled,
    workflowInstanceData,
    removeWorkflowTemplate
  }
}
