import { useReducer, useCallback } from 'react'

const ActionTypes = {
  FN_CALLED: 'FN_CALLED',
  FN_SUCCEEDED: 'FN_SUCCEEDED',
  FN_FAILED: 'FN_FAILED'
}

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.FN_CALLED:
      return { isMutationLoading: true, mutationErr: null, mutationRes: null }
    case ActionTypes.FN_SUCCEEDED:
      return { ...state, isMutationLoading: false, mutationRes: action.payload }
    case ActionTypes.FN_FAILED:
      return { ...state, isMutationLoading: false, mutationErr: action.payload }
    default:
      return state
  }
}

const useMutationState = (fn) => {
  const [state, dispatch] = useReducer(reducer, {
    isMutationLoading: false,
    mutationErr: null,
    mutationRes: null
  })

  const mutate = useCallback((...args) => {
    dispatch({ type: ActionTypes.FN_CALLED })

    return fn(...args)
      .then((res) => {
        dispatch({ type: ActionTypes.FN_SUCCEEDED, payload: res })
        return res
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.FN_FAILED, payload: err })
        return err
      })
  }, [fn, dispatch])

  return [mutate, state]
}

export default useMutationState
