import { WorkflowActionTypes } from '@elenfs/elen-constants'

import { TaskActionBody } from './TaskActionBody'

import { actionType } from './../../../../helpers/propTypes'

import './ActionBody.less'

const ActionBody = ({ action }) => {
  if (action.type === WorkflowActionTypes.TASK) {
    return <TaskActionBody action={action} />
  }

  return <TaskActionBody action={action} />
}

ActionBody.propTypes = {
  action: actionType().isRequired
}

export { ActionBody }
