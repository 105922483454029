import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import enumSchema from '../helpers/enumSchema'
import stringSchema from '../helpers/stringSchema'
import moneySchema from '../helpers/moneySchema'
import booleanSchema from '../helpers/booleanSchema'
import dateSchema from '../helpers/dateSchema'
import percentSchema from '../helpers/percentSchema'
import { endDateSchema } from '../helpers/endDateSchema'

const { AdviceTypes, SourceTypes, ChargeFrequencies } = constants.charge

const chargeSchema = yup
  .object()
  .shape({
    clientId: stringSchema()
      .label('Client')
      .required(),

    adviserId: stringSchema()
      .label('Adviser'),

    adviserRate: percentSchema()
      .label('Adviser rate')
      .when(
        'adviserId',
        (adviserId, schema) => (
          adviserId
            ? schema.required()
            : schema)
      ),

    policyId: stringSchema()
      .label('Policy'),

    source: enumSchema(Object.values(SourceTypes))
      .label('Source')
      .required(),

    adviceType: enumSchema(Object.values(AdviceTypes))
      .label('Advice type')
      .required()
      .default(AdviceTypes.INDEPENDENT),

    frequency: enumSchema(Object.values(ChargeFrequencies))
      .label('Frequency')
      .required()
      .default(ChargeFrequencies.INITIAL),

    startDate: dateSchema()
      .label('Start date')
      .required(),

    endDate: endDateSchema()
      .label('End date'),

    // This field shouldn't be submitted.
    useAmount: booleanSchema()
      .required()
      .default(true),

    policyTransactionPercentage: percentSchema()
      .when('useAmount',
        (useAmount, schema) => (
          useAmount
            ? schema
            : schema.required())
      )
      .default(100),

    amount: moneySchema({
      amountLabel: 'Amount',
      currencyLabel: 'Amount currency',
      min: false
    })
      .when('useAmount',
        (useAmount, schema) => (
          useAmount
            ? schema.required()
            : schema)
      ),

    policyTransactionId: stringSchema()
      .label('Contribution / withdrawal')
      .when(
        'useAmount',
        (useAmount, schema) => (
          useAmount
            ? schema
            : schema.required())
      )
  })

export { chargeSchema }
