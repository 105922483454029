import {
  map,
  prop,
  propEq,
  pick
} from 'ramda'
import FileUploadManager from './../../../lib/FileUploadManager'

const attachmentManager = new FileUploadManager(window.fetch.bind(window))

export const uploadFile = async (uploadUrl, file) => {
  await attachmentManager.upload(uploadUrl, file)
}

export const mapFile = map(prop('file'))

export const parseFilesToLocalState = (files, selectedFileList) => {
  const findFile = fileName =>
    mapFile(selectedFileList).find(propEq('name', fileName))

  return files.map(({ attachment, uploadUrl }) => ({
    ...attachment,
    uploadUrl,
    file: findFile(attachment.fileName)
  }))
}

export const normalizeFiles = map(pick(['id', 'fileId', 'fileName']))
