import Card from '../../misc/Card'
import { ClientCardTable } from '../ClientCardTable'
import { PageDivider } from '../../misc/PageDivider'
import { formatMoney } from '../../../helpers/policy'

import { ClientPersonalAndContactsCard } from '../ClientPersonalAndContactsCard'
import { ClientRelationshipsCard } from '../ClientRelationshipsCard'
import { ClientDependantsCard } from '../ClientDependantsCard'
import { ClientEmploymentCard } from '../ClientEmploymentCard'

import {
  buildClientPersonalAndContacts,
  buildClientRelationships,
  buildClientEmployment
} from './helpers'

import {
  incomeCardTableProps,
  expenditureCardTableProps
} from './const'

const IndividualClientDetailsPageCards = ({
  client,
  canEdit,
  baseUrl,
  isClientPortal
}) => (
  <>
    <ClientPersonalAndContactsCard
      clientId={client.id}
      canEdit={canEdit}
      {...buildClientPersonalAndContacts(client)}
      id='personal'
    />

    <ClientRelationshipsCard
      clientId={client.id}
      canEdit={!isClientPortal && canEdit}
      relationships={buildClientRelationships({ client, isClientPortal })}
      id='relationships'
    />

    <ClientDependantsCard
      clientId={client.id}
      canEdit={canEdit}
      dependants={client.dependants}
      id='dependants'
    />

    <ClientEmploymentCard
      canEdit={canEdit}
      clientId={client.id}
      {...buildClientEmployment(client)}
      id='employment'
    />

    <Card className='client-details-page__client-details__income-and-expenditure'>
      <Card.Header
        allowToNavigate={canEdit}
        id='income-and-expenditure'
        baseUrl={baseUrl}
      >
        Income
      </Card.Header>

      {
        !!client.incomes.length && (
          <ClientCardTable
            {...incomeCardTableProps}
            total={formatMoney(client.totalMonthlyIncome)}
            data={client.incomes}
            comments={client.incomeNotesAndComments}
            className='client-details-page__client-details__income-expenses-table'
          />
        )
      }

      <PageDivider />

      <Card.Header>Expenditure</Card.Header>

      {
        !!client.expenses.length && (
          <ClientCardTable
            {...expenditureCardTableProps}
            data={client.expenses}
            total={formatMoney(client.totalMonthlyExpense)}
            comments={client.expenditureNotesAndComments}
            className='client-details-page__client-details__income-expenses-table'
          />
        )
      }
    </Card>
  </>
)

export { IndividualClientDetailsPageCards }
