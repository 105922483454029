import { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DataImportJobStatuses } from '@elenfs/elen-constants'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import { Toastr } from '../components/misc'

import {
  filterAccounts,
  buildCashCalcAccountOptions
} from '../components/misc/ImportClientModal/helpers'

import dataImportCashCalcClientsGql from '../graphql/queries/dataImport/dataImportCashCalcClients.graphql'
import importCashCalcClientGql from '../graphql/mutations/dataImport/importCashCalcClient.graphql'

const { SUCCESS, FAILURE } = DataImportJobStatuses

export const useImportClientCashCalcModal = props => {
  const { closeModal, refetch } = props

  const navigate = useNavigate()
  const [fetchAccounts, { data, loading, error }] = useLazyQuery(
    dataImportCashCalcClientsGql,
    { fetchPolicy: 'network-only' }
  )

  const [
    onSubmit,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(importCashCalcClientGql, {
    errorPolicy: 'all',
    onCompleted: res => {
      const { status, clients } = res?.importCashCalcClient
      if (status === SUCCESS) {
        refetch()
        if (clients.length) {
          navigate(`/secure/clients/${clients[0].clientId}/summary`)
        }
        toast(<Toastr type='success' title='Client successfully imported' />)
        closeModal()
      }
      if (status === FAILURE) {
        refetch()
        toast(<Toastr type='error' title='Client import failed' />)
      }
    }
  })

  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    const clientAccounts = data?.dataImportCashCalcClients || []
    const filtered = filterAccounts(searchText, clientAccounts)
    setOptions(buildCashCalcAccountOptions(filtered))
  }, [searchText, data])

  const accountsProps = {
    accountsOptions: options,
    accountsLoading: loading,
    accountsError: error ? 'Failed to load clients' : '',
    fetchAccounts,
    onFilterAccounts: setSearchText
  }

  const mutationProps = {
    mutationLoading,
    mutationError: mutationError ? 'Something went wrong' : '',
    onSubmit: ({ account }) =>
      onSubmit({ variables: { input: { id: account } } })
  }

  return { accountsProps, mutationProps }
}
