import './Header.less'

const Header = ({ children }) => (
  <header className='main-header'>
    <div className='main-header__content'>
      {children}
    </div>
  </header>
)

export default Header
