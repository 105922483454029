import constants from '@elenfs/elen-constants'

import CompanyUserDetailsGql from '../../../../graphql/queries/user/companyUserDetailsByInvitationToken.graphql'
import companyUserInvitation from '../../../../graphql/mutations/user/acceptCompanyUserInvitation.graphql'
import companyUserSetupPasswordSchema from '../../../../schemas/users/companyUserSetupPasswordSchema'
import InvitationSignupPage from '../../../misc/InvitationSignupPage'

const { UserTypes } = constants.user

const CompanyUserSignupPage = () => {
  return (
    <InvitationSignupPage
      query={CompanyUserDetailsGql}
      queryResultPath='companyUserByInvitationToken'
      formMutation={companyUserInvitation}
      formRedirectUrl='/secure'
      formValidationSchema={companyUserSetupPasswordSchema}
      formLoginType={UserTypes.COMPANY_USER}
    />
  )
}

export default CompanyUserSignupPage
