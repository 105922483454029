import { useMemo } from 'react'

import Card from '../../misc/Card'
import Summary from '../../misc/Summary'
import { formatDate, calculateAge } from '../../../helpers/date'
import { PageDivider } from '../../misc/PageDivider'
import { ClientRelationshipLabels, GenderLabels } from '../../../helpers/enumLabels/client'

const IndividualClientSummaryDetailsCard = ({ client }) => {
  const formattedRelationships = useMemo(() => {
    const { relationships } = client
    if (!relationships) return []
    return client.relationships.map(({ relatedClient, type, relatedClientId }) => ({
      label: ClientRelationshipLabels[type],
      value: relatedClient?.fullName,
      link: `/secure/clients/${relatedClientId}`
    }))
  }, [client])

  return (
    <Card className='client-summary-details-card'>
      <div className='client-notes-card-content__header'>
        <Card.Heading as='h3'>Summary details</Card.Heading>
      </div>

      <Summary
        data={[
          { label: 'Title', value: client.title },
          { label: 'First name', value: client.firstName },
          { label: 'Middle name', value: client.middleName },
          { label: 'Last name', value: client.lastName },
          { label: 'Known as', value: client.knownAs },
          { label: 'Gender', value: GenderLabels[client.gender] },
          { label: 'Date of birth', value: formatDate(client.dateOfBirth) },
          { label: 'Age', value: calculateAge(client.dateOfBirth) }
        ]}
      />

      <Summary data={[{ label: 'NI number', value: client.niNumber }]} />

      <PageDivider />

      <Summary
        data={[
          { label: 'Address line 1', value: client.address1 },
          { label: 'Address line 2', value: client.address2 },
          { label: 'City', value: client.city },
          { label: 'Post Code', value: client.postCode }
        ]}
      />

      <Summary
        data={[
          { label: 'Email', value: client.email },
          { label: 'Mobile', value: client.mobile },
          { label: 'Home', value: client.homeTel },
          { label: 'Work', value: client.workTel }
        ]}
      />

      <Summary
        className='line-margin'
        data={[
          {
            label: 'Financial Adviser',
            value: client.financialAdviser ? client.financialAdviser.fullName : ''
          }
        ]}
      />

      {
        !!formattedRelationships.length && (
          <>
            <PageDivider />
            <Summary data={formattedRelationships} />
          </>
        )
      }
    </Card>
  )
}

export { IndividualClientSummaryDetailsCard }
