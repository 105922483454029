import { assocPath, curry, pipe, path } from 'ramda'

const buildInputName = curry((name, index, path, inputProps) => {
  let baseName = `${name}.${index}`

  if (path) baseName += `.${path}`

  inputProps.name = baseName

  return inputProps
})

const checkIfDisabled = curry(
  (disableFirst, disablePath, disableOnlyArr, path, index, inputProps) => {
    if (disableOnlyArr && !disableOnlyArr.includes(path)) {
      return inputProps
    }

    if (disableFirst && !disablePath) {
      inputProps.disabled = !index
    }

    if (disableFirst && disablePath && !index) {
      inputProps = assocPath(disablePath, true, inputProps)
    }

    return inputProps
  }
)

const buildUniqueSelectOptions = curry(
  (
    isUniqueSelectOptions,
    uniqueSelectOptionsPathArr,
    valueList,
    index,
    inputProps
  ) => {
    if (!isUniqueSelectOptions || !inputProps?.options) return inputProps

    uniqueSelectOptionsPathArr = uniqueSelectOptionsPathArr || []

    const isOptionCurrentlySelected = option =>
      option.value === path(uniqueSelectOptionsPathArr, valueList[index])

    const isOptionNotYetSelected = option =>
      !valueList.map(path(uniqueSelectOptionsPathArr)).includes(option.value)

    const options = inputProps.options.filter(
      option =>
        isOptionCurrentlySelected(option) || isOptionNotYetSelected(option)
    )

    return assocPath(['options'], options, inputProps)
  }
)

export const buildInputProps = props => {
  const {
    inputProps,
    index,
    disableFirst,
    disablePath,
    disableOnlyArr,
    path,
    name,
    valueList,
    isUniqueSelectOptions,
    uniqueSelectOptionsPathArr
  } = props

  return pipe(
    buildInputName(name, index, path),
    checkIfDisabled(disableFirst, disablePath, disableOnlyArr, path, index),
    buildUniqueSelectOptions(
      isUniqueSelectOptions,
      uniqueSelectOptionsPathArr,
      valueList,
      index
    )
  )(inputProps)
}

export const buildRow = (rowInputs = []) => {
  if (rowInputs.length === 1) return ''

  const rowProps = {}
  rowInputs.forEach(({ path }) => (rowProps[path] = ''))

  return rowProps
}

export const showDeleteIcon = (cantRemoveFirst, index) =>
  !(cantRemoveFirst && !index)
