import constants from '@elenfs/elen-constants'

const { ErrorCodes } = constants.error

const DEFAULT_ERROR_MESSAGE = 'Something went wrong'

export const getErrorMessageByCode = (errCode) => ({
  [ErrorCodes.INVALID_AUTH_CREDENTIALS]:
    'Either your email or password has been entered incorrectly, please try again',
  [ErrorCodes.USER_ACCOUNT_DISABLED]: 'This account is disabled. Please contact your company administrator.',
  [ErrorCodes.EMAIL_USED_IN_COMPANY]: 'Email already used',
  [ErrorCodes.INVALID_NI_NUMBER]: 'Invalid NI number. NI number example: AB123456C',
  [ErrorCodes.DATA_IMPORT_FILE_NOT_SUPPORTED]: 'File is of invalid format, must be CSV',
  [ErrorCodes.NI_NUMBER_ALREADY_USED]: 'NI number must be unique',
  [ErrorCodes.POLICY_NUMBER_ALREADY_USED]: 'Policy number must be unique',
  [ErrorCodes.NUMBER_OF_USER_LICENCES_EXCEEDED]: 'Number of user licences exceeded'
}[errCode])

export const parseGraphqlErrors = (
  graphqlError,
  defaultMessage = DEFAULT_ERROR_MESSAGE,
  messages = {}
) => {
  const errors = {}
  try {
    const { extensions } = graphqlError.graphQLErrors[0]
    const { details } = extensions.exception

    // In the most recent error shape the code lives in the
    // `extensions.code` property.
    // For compatibility with the old error shapes we check
    // `extensions.exception.details`
    // TODO: use only `extensions.code` when the back end has
    // fully transitioned to the new error shape.
    const errorCode = details
      ? (details.code || details)
      : extensions.code

    if (errorCode === ErrorCodes.EMAIL_USED_IN_COMPANY) {
      errors.email = messages.email || getErrorMessageByCode(ErrorCodes.EMAIL_USED_IN_COMPANY)
    } else if (errorCode === ErrorCodes.NI_NUMBER_ALREADY_USED) {
      errors.niNumber = messages.niNumber || getErrorMessageByCode(ErrorCodes.NI_NUMBER_ALREADY_USED)
    } else if (errorCode === ErrorCodes.POLICY_NUMBER_ALREADY_USED) {
      errors.number = messages.number || getErrorMessageByCode(ErrorCodes.POLICY_NUMBER_ALREADY_USED)
    } else {
      errors[''] = getErrorMessageByCode(errorCode) || defaultMessage
    }
  } catch (e) {
    errors[''] = defaultMessage
  }
  return errors
}
