const Check = (props) => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M3.793 10.793a1 1 0 011.32-.083l.094.083 4.543 4.542 9.043-9.042a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-9.566 9.566a1.26 1.26 0 01-1.68.092l-.102-.092-5.066-5.066a1 1 0 010-1.414z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { Check }
