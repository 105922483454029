import { useState, useEffect } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { Navigate } from 'react-router-dom'

import paymentSubscriptionCreatedGql from '../../../graphql/subscriptions/paymentSubscriptionCreated.graphql'
import hasActiveSubscriptionGql from '../../../graphql/queries/stripeSubscriptions/hasActiveSubscription.graphql'

import { WaitingScreen } from '../../misc'

const WaitingPage = () => {
  const [success, setSuccess] = useState(false)

  const { data: hasActiveSubscriptionData } = useQuery(
    hasActiveSubscriptionGql,
    {
      fetchPolicy: 'network-only'
    }
  )

  useSubscription(paymentSubscriptionCreatedGql, {
    onData: () => setSuccess(true)
  })

  useEffect(() => {
    if (hasActiveSubscriptionData?.hasActiveSubscription) {
      setSuccess(true)
    }
  }, [hasActiveSubscriptionData, success, setSuccess])

  if (success) {
    return <Navigate to='/secure' />
  }

  const text = (
    <>
      Your payment is being processed. You'll be <br /> redirected to Elen soon.
    </>
  )

  return <WaitingScreen text={text} />
}

export { WaitingPage }
