import { evolve, unless, always, is, ifElse, pipe, toString } from 'ramda'
import constants, {
  CompanyUserRoles,
  ActivityLogEntryTypes
} from '@elenfs/elen-constants'

import { buildSelectOptions } from '../../form/fields/SelectField/helpers'
import { millisecondsToDays } from '../../../helpers/date'

const { UserRolesLabels } = constants.user
const { WORK_ON_FILE } = ActivityLogEntryTypes

export const taskAssigneeRoleOptions = [
  { value: '', label: 'Unassigned' },
  ...buildSelectOptions(CompanyUserRoles, UserRolesLabels)
]

export const buildInitialValues = ({ action }) => ({
  taskType: WORK_ON_FILE,
  taskAssigneeTeamRole: '',
  ...evolve(
    {
      taskDueDateAfterMs: ifElse(
        is(Number),
        pipe(millisecondsToDays, toString),
        always('')
      ),
      taskAssigneeTeamRole: unless(Boolean, always(''))
    },
    action
  )
})
