import { map, always } from 'ramda'
import { TASK_CELL_ENTITY_DATA_LABEL } from './const'

const isDesktop = () => window.innerWidth > 768

const getTableHeaderCells = () =>
  [...document.querySelector('.client-tasks-table thead tr').cells].filter(el =>
    el.getAttribute(TASK_CELL_ENTITY_DATA_LABEL)
  )

const getDraggedRow = draggableId =>
  document.querySelector(`tr[data-rbd-draggable-id="${draggableId}"]`)

const getDraggedRowCells = draggedRow =>
  [...draggedRow.children].filter(el => el.getAttribute(TASK_CELL_ENTITY_DATA_LABEL))

const toggleSubtaskTables = props => {
  const { isCollapse, subTasksWrapperRef, toggleState } = props

  for (const [key] of Object.entries(toggleState)) {
    const el = subTasksWrapperRef.current[key]
    if (el) {
      el.parentElement.style.transition = isCollapse ? '0s' : '0.3s'
      el.parentElement.style.maxHeight = isCollapse ? '0px' : '100%'
    }
  }
}

const setDraggedRowCellsWidth = ({ draggableId, isDragged }) => {
  const tableHeaderCells = getTableHeaderCells()
  const draggedRow = getDraggedRow(draggableId)
  const draggedRowCells = getDraggedRowCells(draggedRow)

  if (isDragged) {
    draggedRow.classList.add('task-dragged-row')
  } else {
    draggedRow.classList.remove('task-dragged-row')
  }

  if (isDesktop()) {
    tableHeaderCells.forEach(thead => {
      const taskEntityAttr = thead.getAttribute(TASK_CELL_ENTITY_DATA_LABEL)
      const draggedRowCell = draggedRowCells.find(
        el => el.getAttribute(TASK_CELL_ENTITY_DATA_LABEL) === taskEntityAttr
      )
      if (isDragged) {
        draggedRowCell.style.width = `${thead.offsetWidth}px`
      } else {
        draggedRowCell.style.removeProperty('width')
      }

      draggedRowCell.style.maxWidth = isDragged
        ? `${thead.offsetWidth}px`
        : '100%'
    })
  }
}

// Collapse all the opened subtask tables on start dragging
export const onBeforeDragCapture = props => {
  const { toggleState, subTasksWrapperRef, setToggleState } = props

  if (isDesktop()) {
    toggleSubtaskTables({ toggleState, subTasksWrapperRef, isCollapse: true })
    setToggleState(map(always(false)))
  }
}

// Get the dragged table row cells and set their width to be the same as the table headers
// It is because the row gets position fixed which removes the width coming from the table headers
export const onBeforeDragStart = ({ draggableId, isSubtask }) => {
  setDraggedRowCellsWidth({ draggableId, isSubtask, isDragged: true })
}

// Remove the applied styles from onBeforeDragStart func
export const onDragEndWrapper = props => {
  const { result, onDragEnd, toggleState, subTasksWrapperRef } = props
  const { draggableId } = result

  setDraggedRowCellsWidth({ draggableId, isDragged: false })

  if (isDesktop()) {
    toggleSubtaskTables({ isCollapse: false, toggleState, subTasksWrapperRef })
  }

  onDragEnd(result)
}
