import { forwardRef, useState } from 'react'
import classnames from 'classnames'
import T from 'prop-types'
import { Link } from 'react-router-dom'
import { TaskStatuses } from '@elenfs/elen-constants'
import { useQuery, useMutation } from '@apollo/client'
import { withFormik, connect } from 'formik'
import { compose } from 'ramda'

import {
  ChevronRightIcon,
  LockIcon,
  BranchArrowsIcon
} from '../../../icons'
import { Table, InitialsAvatar, TaskIcon, TaskAssignee, TaskStatus } from '../../../misc'
import { WorkflowToolTip } from './WorkflowToolTip'

import { taskPropType } from '../propTypes'
import { formatDate } from './../../../../helpers/date'
import { buildAssigneeOptions } from '../../helpers'
import { taskSchema } from '../../../../schemas/tasks'
import { buildInitialValues } from '../../../../helpers/form'
import { withCurrentUser } from '../../../hoc/container'

import companyUsersGql from '../../../../graphql/queries/user/companyUsers.graphql'
import updateTaskGql from '../../../../graphql/mutations/tasks/updateTask.graphql'
import updateSubtaskGql from '../../../../graphql/mutations/tasks/updateSubtask.graphql'

import './TasksTableRow.less'

const TasksTableRow = forwardRef((props, ref) => {
  const {
    task,
    toggleSubtaskTable,
    isToggleOpen,
    onTaskClick,
    index,
    currentUser,
    draggableProps,
    draggableRef
  } = props

  const {
    subtasks,
    type,
    summary,
    client,
    dueDate,
    assignedTo,
    status = TaskStatuses.TO_DO,
    id,
    parentTask
  } = task

  const isSubTask = !!parentTask
  const onSummaryClick = () => onTaskClick(task, index)

  const showPrivateIcon =
    !client && (!assignedTo || assignedTo?.id === currentUser?.id)

  const showWorkflowIcon = !!task.workflowInstanceId

  const { data: usersData } = useQuery(companyUsersGql)
  const assigneeOptions = buildAssigneeOptions({
    users: usersData?.users || [],
    currentUser
  })

  const [mutate] = isSubTask ? useMutation(updateSubtaskGql) : useMutation(updateTaskGql)

  const [input, setInput] = useState({
    attachments: task?.attachments,
    description: task?.description || null,
    dueDate: task?.dueDate || null,
    id: task?.id,
    status: task?.status || status,
    summary: task?.summary || null,
    type: task?.type,
    assignedTo: task?.assignedTo?.id,
    ...(isSubTask && { parentId: parentTask?.id }),
    ...(!isSubTask && { clientId: task?.client?.id || null })
  })

  const onSelectAssignee = async ({ assignedTo, status }) => {
    setInput({
      ...input,
      assignedTo,
      status
    })

    // This value is derived and declared due to the fact that useState is asynchronous, and the mutate function won't receive the updated value until the next re-render phase
    const updatedValue = { ...input, assignedTo, status }

    await mutate({
      variables: {
        input: updatedValue
      },
      onCompleted: (data) => {
        const task = data?.updateTask
        setInput({
          ...input,
          attachments: task?.attachments,
          clientId: task?.client?.id || null,
          description: task?.description || null,
          dueDate: task?.dueDate || null,
          id: task?.id,
          status: task?.status,
          summary: task?.summary || null,
          type: task?.type,
          assignedTo: task?.assignedTo?.id
        })
      }
    })
  }

  return (
    <Table.Row className='tasks-table-row' {...draggableProps} ref={draggableRef}>
      {/* Title */}
      <Table.Cell
        className='tasks-table-row__title-cell'
        data-task-cell-entity='summary'
      >
        {!!subtasks?.length && (
          <ChevronRightIcon
            className={classnames(
              'tasks-table-row__title-cell__toggle-icon',
              isToggleOpen &&
                'tasks-table-row__title-cell__toggle-icon--toggled'
            )}
            onClick={() => toggleSubtaskTable(id)}
          />
        )}
        <div>
          <TaskIcon type={type} />
        </div>

        <div className='tasks-table-row__title-cell__title'>
          <span onClick={onSummaryClick}> {summary} </span>
        </div>

        {showWorkflowIcon && (
          <WorkflowToolTip className='tasks-table-row__title-cell__workflow-icon' taskId={id} />
        )}

        {showPrivateIcon && (
          <LockIcon className='tasks-table-row__title-cell__private-icon' />
        )}
        {/* TODO */}
        <span
          className={classnames(
            'tasks-table-row__title-cell__subtasks-count',
            !subtasks?.length &&
              'tasks-table-row__title-cell__subtasks-count--hide-on-mobile'
          )}
          onClick={() => toggleSubtaskTable(id)}
        >
          <BranchArrowsIcon className='tasks-table-row__title-cell__subtasks-icon' />
          {subtasks?.length || '+'}
        </span>
      </Table.Cell>

      {/* Spacer */}
      <Table.Cell className='tasks-table-row__spacer' />

      {/* Client */}
      <Table.Cell
        className={classnames(
          'tasks-table-row__client-cell',
          !client && 'tasks-table-row__client-cell--empty'
        )}
      >
        {client && (
          <Link
            to={`/secure/clients/${client.id}`}
            className='tasks-table-row__client-cell__link'
          >
            <span className='tasks-table-row__client-cell__name'>
              {client.fullName}
            </span>
            <InitialsAvatar
              user={client}
              className='tasks-table-row__client-cell__avatar'
            />
          </Link>
        )}
      </Table.Cell>

      {/* Due Date */}
      <Table.Cell
        className='tasks-table-row__due-date-cell'
        data-task-cell-entity='due-date'
      >
        {formatDate(dueDate)}
      </Table.Cell>

      {/* Assignee */}
      <Table.Cell
        className='tasks-table-row__assignee-cell'
        data-task-cell-entity='assignee'
      >
        <TaskAssignee
          options={assigneeOptions}
          isHandleOnSelect
          handleOnSelect={(assignedTo) => onSelectAssignee({
            assignedTo: assignedTo === '' ? null : assignedTo
          })}
          inputProps={{ isSearchable: false, isListIcon: true }}
        />
      </Table.Cell>

      {/* Status */}
      <Table.Cell
        className='tasks-table-row__status-badge-cell'
        data-task-cell-entity='status'
      >
        <TaskStatus
          isHandleOnSelect
          handleOnSelect={(status) => onSelectAssignee({ status })}
        />
      </Table.Cell>
    </Table.Row>
  )
})

TasksTableRow.propTypes = {
  task: taskPropType().isRequired,
  onTaskClick: T.func.isRequired,
  toggleSubtaskTable: T.func,
  isToggleOpen: T.bool,
  // used instead of ID to find a subtask if we create a new Task
  index: T.number
}

const EnhancedTasksTableRow = compose(
  withCurrentUser({ fetchPolicy: 'cache-only' }),
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: taskSchema,
    mapPropsToValues: ({ task = {} }) => buildInitialValues(taskSchema, {
      task,
      assignedTo: task?.assignedTo?.id,
      status: task?.status
    })
  }), connect)(TasksTableRow)

export { EnhancedTasksTableRow as TasksTableRow }
