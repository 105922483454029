import { InputField, MoneyField, DateField } from '../../../form/fields'

const LiabilitiesFormInputRow = ({ index }) => (
  <div className='liabilities-input-row'>
    <div className='liabilities-input-row__fields-row'>
      <InputField name={`liabilities.${index}.owner`} label='Owner' required />
      <InputField
        name={`liabilities.${index}.liabilityType`}
        label='Liability type'
      />
    </div>
    <div className='liabilities-input-row__fields-row'>
      <MoneyField
        name={`liabilities.${index}.outstandingValue`}
        label='Outstanding value'
        inputProps={{ placeholder: '0.00' }}
        required
      />

      <MoneyField
        name={`liabilities.${index}.monthlyCost`}
        label='Monthly cost'
        inputProps={{ placeholder: '0.00' }}
      />
    </div>

    <div className='liabilities-input-row__fields-row'>
      <DateField
        name={`liabilities.${index}.startDate`}
        label='Start date'
        inputProps={{ placeholder: 'dd/mm/yyyy' }}
      />
      <DateField
        name={`liabilities.${index}.endDate`}
        label='End date'
        inputProps={{ placeholder: 'dd/mm/yyyy' }}
      />
    </div>
  </div>
)

export default LiabilitiesFormInputRow
