import {
  GoBackHeader,
  EmptyPage,
  ErrorMessage,
  Spinner,
  DownloadReportBtn
} from '../../../misc'
import { ReportTable } from '../../ReportTable'

import { TableHeaders } from '../const'
import { rowSchema } from '../helpers'

import './ClientListReportPage.less'
import './ClientListReportTable.less'

const ClientListReportPage = ({ rows, loading, error }) => {
  const tableProps = {
    rows,
    tableHeaderLabels: Object.values(TableHeaders),
    rowSchema,
    className: 'client-list-report-table'
  }

  return (
    <div className='client-list-report'>
      <GoBackHeader title='Clients' />

      {loading && <Spinner />}

      {!rows.length && !loading && !error && (
        <EmptyPage text='No records found' hidden={false} />
      )}

      {error && <ErrorMessage text='Failed to load records' />}

      {!!rows.length && (
        <>
          <div className='client-list-report__count-and-btn'>
            <span>{rows.length} clients </span>
            <DownloadReportBtn reportName='clients' />
          </div>

          <div className='table-wrap'>
            <ReportTable {...tableProps} />
          </div>
        </>
      )}
    </div>
  )
}

export { ClientListReportPage }
