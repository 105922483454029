import classnames from 'classnames'
import { components } from 'react-select'

import { InitialsAvatar } from '../../../misc'
import { UserIcon } from '../../../icons'

import './DropdownCustom.less'

export const UNASSIGNED_TYPE = 'unassigned'

const ControlWithInitials = ({
  children,
  className,
  cx,
  getStyles,
  getValue,
  innerProps,
  ...rest
}) => {
  let isUneassigned = true
  const { isTaskIcon, isListIcon } = rest?.selectProps

  if (getValue()?.length) {
    if (getValue()?.[0]?.user?.type !== UNASSIGNED_TYPE) {
      isUneassigned = false
    }
  }

  const iconComponent = isUneassigned
    ? (
      <div className={classnames('dropdown-selection__control__unassigned-icon-wrapper', {
        'dropdown-selection__control__unassigned-task-icon-wrapper': isTaskIcon,
        'dropdown-selection__control__list-unassigned-task-icon-wrapper': isListIcon
      })}
      >
        <UserIcon />
      </div>
      )
    : getValue()?.length
      ? <InitialsAvatar
          user={getValue()?.[0]?.user}
          className={classnames({
            'dropdown-selection__control__list-avatar-icon': isListIcon
          })}
        />
      : null

  return (
    <components.Control
      {...{
        cx,
        getStyles,
        getValue,
        innerProps,
        css: getStyles('control', rest),
        className: classnames('dropdown-selection__control--with-icon', cx(
          {
            option: true
          },
          className
        )),
        ...rest
      }}
    >
      {iconComponent}
      {children}
    </components.Control>
  )
}

export { ControlWithInitials }
