import { CookieConsentModal } from './CookieConsentModal'
import { Modal } from '../Modal'

import { useCookieConsent } from './useCookieConsent'

const CookieConsentModalTrigger = props => {
  const { onSubmit, cookiesList } = useCookieConsent()

  return (
    <Modal.Trigger
      as='span'
      modal={CookieConsentModal}
      modalProps={{ onSubmit, cookiesList }}
      {...props}
    />
  )
}

export { CookieConsentModalTrigger }
