import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import dateSchema from '../helpers/dateSchema'
import enumSchema from '../helpers/enumSchema'
import moneySchema from '../helpers/moneySchema'
import commonFields from './commonFields'

const {
  DeathBenefits,
  Guarantees,
  HeldInTrustValues,
  InvestmentTypes,
  PlanStatuses
} = constants.policy.investmentPolicy

export default yup.object().shape({
  ...commonFields(),

  investmentType: enumSchema(values(InvestmentTypes))
    .label('Policy type')
    .required(),

  planStatus: enumSchema(values(PlanStatuses))
    .label('Plan status'),

  startDate: dateSchema()
    .label('Start date'),

  maturityDate: dateSchema()
    .label('Maturity date'),

  deathBenefit: enumSchema(values(DeathBenefits))
    .label('Death benefit'),

  additionalLifeCover: moneySchema({
    amountLabel: 'Additional life cover',
    currencyLabel: 'Additional life cover currency'
  }),

  heldInTrust: enumSchema(values(HeldInTrustValues))
    .label('Held in trust'),

  guarantees: enumSchema(values(Guarantees))
    .label('Guarantees'),

  surrenderDate: dateSchema()
    .label('Surrender date'),

  surrenderValue: moneySchema({
    amountLabel: 'Surrender value',
    currencyLabel: 'Surrender value currency'
  })
}).noUnknown(true)
