import T from 'prop-types'

import { Card } from '../../misc'
import ImportPlatformLayout from '../ImportPlatformLayout'
import { CSVImport } from './CSVImport'
import { CSVGuide } from '../CSVGuide'
import { ImportFooterConnection, ImportGuide } from '../ImportFooter'
import appConfig from '../../../appConfig'

const { CSV_IMPORT_STEP_BY_STEP_LINK } = appConfig

const CSVImportPage = (props) => {
  const pageTitle = 'CSV File import'

  return (
    <ImportPlatformLayout pageTitle={pageTitle}>
      <ImportPlatformLayout.LeftColumn>
        <CSVImport {...props} />
        <Card>
          <ImportFooterConnection
            pageTitle={pageTitle}
            link='/secure/admin/csv-import/logs'
          />
        </Card>
      </ImportPlatformLayout.LeftColumn>

      <ImportPlatformLayout.RightColumn>
        <Card.Group>
          <Card>
            <Card.Heading>Import data</Card.Heading>
            <CSVGuide />
          </Card>
          <Card>
            <ImportGuide link={CSV_IMPORT_STEP_BY_STEP_LINK} />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.RightColumn>
    </ImportPlatformLayout>
  )
}

CSVImportPage.propTypes = {
  onDownloadTemplate: T.func.isRequired,
  onSubmit: T.func.isRequired
}

export { CSVImportPage }
