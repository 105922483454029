const UserDarkIcon = (props) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' color='#A8AAB7' {...props}>
    <defs>
      <path
        id='user'
        d='M8 1a4.45 4.45 0 0 1 2.333.636 4.88 4.88 0 0 1 1.697 1.697 4.45 4.45 0 0 1 .637 2.334A4.45 4.45 0 0 1 12.03 8a4.875 4.875 0 0 1-1.697 1.697A4.45 4.45 0 0 1 8 10.333a4.45 4.45 0 0 1-2.333-.636A4.875 4.875 0 0 1 3.97 8a4.45 4.45 0 0 1-.637-2.333c0-.849.212-1.627.637-2.334a4.875 4.875 0 0 1 1.697-1.697A4.45 4.45 0 0 1 8 1zm7 13a.966.966 0 0 1-.286.717.986.986 0 0 1-.724.283H2.01a.986.986 0 0 1-.724-.283A.966.966 0 0 1 1 14v-.833c0-.556.168-1.056.505-1.5a2.461 2.461 0 0 1 1.312-.9l1.548-.434c1.077.8 2.289 1.2 3.635 1.2 1.346 0 2.558-.4 3.635-1.2l1.548.434c.538.155.976.455 1.312.9.337.444.505.944.505 1.5V14z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='user-mask' fill='#fff'>
        <use xlinkHref='#user' />
      </mask>
      <g fill='currentColor' mask='url(#user-mask)'>
        <path d='M0 0h17v16H0z' />
      </g>
    </g>
  </svg>
)

export default UserDarkIcon
