import { Fragment } from 'react'
import T from 'prop-types'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Table } from '../../../misc'
import { TasksTableRow } from '../TasksTableRow'
import { SubTasksTable } from '../SubTasksTable'

import { taskPropType } from './../propTypes'
import {
  onBeforeDragStart,
  onDragEndWrapper,
  onBeforeDragCapture
} from '../helpers'

const TasksTableBodyDraggable = props => {
  const {
    onDragEnd,
    toggleState,
    subTasksWrapperRef,
    setToggleState,
    sortedTasks,
    toggleSubtaskTable,
    onTaskClick,
    onCreateSubtask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    currentUser,
    isClientTasks
  } = props

  return (
    <DragDropContext
      onDragEnd={result =>
        onDragEndWrapper({
          result,
          onDragEnd,
          toggleState,
          subTasksWrapperRef
        })}
      onBeforeDragStart={onBeforeDragStart}
      onBeforeCapture={() =>
        onBeforeDragCapture({
          toggleState,
          subTasksWrapperRef,
          setToggleState
        })}
    >
      <Droppable droppableId='client-tasks-table-droppable-dnd'>
        {provided => (
          <Table.Body ref={provided.innerRef} {...provided.droppableProps}>
            {sortedTasks.map((task, index) => (
              <Fragment key={task.id}>
                <Draggable draggableId={task.id} index={index}>
                  {provided => (
                    <TasksTableRow
                      {...{
                        task,
                        toggleSubtaskTable,
                        isToggleOpen: toggleState[task.id],
                        onTaskClick,
                        currentUser,
                        draggableRef: provided.innerRef,
                        draggableProps: {
                          ...provided.draggableProps,
                          ...provided.dragHandleProps
                        }
                      }}
                    />
                  )}
                </Draggable>

                <Table.Row className='tasks-table__subtasks-row'>
                  <Table.Cell colSpan={5}>
                    <div
                      ref={ref => {
                        subTasksWrapperRef.current[task.id] = ref
                      }}
                    >
                      <SubTasksTable
                        {...{
                          subTasks: task?.subtasks || [],
                          isNotNestedTable: false,
                          onCreateSubtask,
                          loadingOnCreateSubtask,
                          errorsOnCreateSubtask: errorsOnCreateSubtask[task.id],
                          taskId: task.id,
                          onTaskClick,
                          isClientTasks
                        }}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Fragment>
            ))}
            {provided.placeholder}
          </Table.Body>
        )}
      </Droppable>
    </DragDropContext>
  )
}

TasksTableBodyDraggable.propTypes = {
  sortedTasks: T.arrayOf(taskPropType().isRequired).isRequired,
  toggleSubtaskTable: T.func,
  onDragEnd: T.func,
  toggleState: T.object,
  setToggleState: T.func,
  subTasksWrapperRef: T.object,
  onTaskClick: T.func,
  onCreateSubtask: T.func,
  loadingOnCreateSubtask: T.bool,
  errorsOnCreateSubtask: T.object.isRequired
}

export { TasksTableBodyDraggable }
