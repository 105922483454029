import { useEffect, useRef } from 'react'
import T from 'prop-types'

import { EnvelopeIcon } from '../../icons'
import { Modal } from '../../misc'
import { SendMessageModal } from '../../misc/SendMessageModal'
import { DashboardClientsList } from '../DashboardClientsList'

import { setListHeight } from './helpers'
import { mapToClientListProp } from './../DashboardClientsList/helpers'

const MessageModalTrigger = ({ clientId, clientName }) => (
  <Modal.Trigger
    as='span'
    modal={SendMessageModal}
    modalProps={{ clientId, clientName }}
  >
    <EnvelopeIcon />
  </Modal.Trigger>
)

const mobileBreakPoint = 575

const ClientListTab = ({ hint, clientList, emptyListText }) => {
  const containerRef = useRef()

  useEffect(() => {
    const onResize = () => {
      const itemsToShow = window.innerWidth > mobileBreakPoint ? 5 : 3
      setListHeight({ containerRef, itemsToShow })
    }

    onResize()

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [clientList, containerRef])

  return (
    <div className='client-list-tab'>
      {
        clientList && clientList.length > 0 &&
          <div className='client-list-tab__hint-text'>
            {hint}
          </div>
      }

      <div ref={containerRef}>
        <DashboardClientsList
          emptyListText={emptyListText}
          clientList={mapToClientListProp(clientList)}
          ActionComponent={MessageModalTrigger}
        />
      </div>
    </div>
  )
}

ClientListTab.defaultProps = {
  clientList: []
}

ClientListTab.propTypes = {
  hint: T.string,
  clientList: T.arrayOf(
    T.shape({ fullName: T.string, date: T.string, id: T.string })
  )
}

export { ClientListTab }
