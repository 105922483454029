import { useCallback } from 'react'
import { connect } from 'formik'
import { useNavigate } from 'react-router-dom'
import {
  CompanyLegalStatuses,
  CompanyLegalStatusLabels
} from '@elenfs/elen-constants'

import { Card } from '../../misc'
import { FormFieldsCard } from '../../form'
import { FormGroup } from '../../form/Form/FormGroup'
import NextAndPreviousLinks from './../../misc/NextAndPreviousButton'
import { DateField, InputField, SelectField } from '../../form/fields'

import { validateAndNavigate } from '../../../helpers/client'
import { buildSelectOptions } from '../../form/fields/SelectField'
import companyDetailsSchema from '../../../schemas/corporateClient/companyDetailsSchema'

import './CompanyDetailsForm.less'

const CompanyDetailsForm = props => {
  const { setValidationSchema, nextLink, formik, isClientPortal } = props

  const navigate = useNavigate()
  const { validateForm } = formik

  const handleNextButtonClick = useCallback(() => {
    validateAndNavigate({
      setSchema: setValidationSchema,
      schema: companyDetailsSchema,
      validate: validateForm,
      nextLocation: nextLink,
      navigate
    })
  }, [
    navigate,
    validateForm,
    nextLink,
    companyDetailsSchema,
    setValidationSchema
  ])

  return (
    <Card className='company-details-form'>
      <FormFieldsCard title='Company details'>
        <FormGroup label='Basic Information'>
          <div className='company-details-form__row company-details-form__row--3'>
            <InputField
              required
              name='name'
              label='Name of business'
              inputProps={{
                maxLength: 50
              }}
            />

            <InputField
              name='tradingName'
              label='Trading as'
              inputProps={{
                maxLength: 50
              }}
            />
          </div>

          <div className='company-details-form__row company-details-form__row--3'>
            <InputField
              name='legalEntityIdentifier'
              label='Legal entity identifier (LEI)'
            />

            <DateField
              label='LEI expiry date'
              name='leiExpiryDate'
              inputProps={{ placeholder: 'dd/mm/yy' }}
            />
          </div>

          <div className='company-details-form__row company-details-form__row--3'>
            <SelectField
              name='legalStatus'
              label='Legal status'
              inputProps={{
                isClearable: true
              }}
              options={buildSelectOptions(
                CompanyLegalStatuses,
                CompanyLegalStatusLabels
              )}
            />

            <InputField
              name='natureOfBusiness'
              label='Nature of business'
              inputProps={{
                maxLength: 50
              }}
            />
          </div>

          <div className='company-details-form__row company-details-form__row--3'>
            <InputField
              name='fullTimeEmployees'
              label='Full time employees'
              inputProps={{ regex: /^[1-9][0-9]{0,9}$/ }}
            />
            <InputField
              name='partTimeEmployees'
              label='Part time employees'
              inputProps={{ regex: /^[1-9][0-9]{0,9}$/ }}
            />
          </div>

          <div className='company-details-form__row company-details-form__row--3'>
            <DateField
              label='Date founded'
              name='dateFounded'
              inputProps={{ placeholder: 'dd/mm/yy' }}
            />
          </div>
        </FormGroup>

        <FormGroup label='Address'>
          <div className='company-details-form__row company-details-form__row--2-1'>
            <InputField name='address1' label='Address line 1' />
          </div>

          <div className='company-details-form__row company-details-form__row--2-1'>
            <InputField name='address2' label='Address line 2' />
          </div>

          <div className='company-details-form__row company-details-form__row--3'>
            <InputField name='city' label='City' />
            <InputField name='postCode' label='Post code' />
          </div>
        </FormGroup>

        <FormGroup label='Contacts'>
          <div className='company-details-form__row company-details-form__row--3'>
            <InputField required name='firstName' label='First name' />
            <InputField required name='lastName' label='Last name' />
          </div>

          <div className='company-details-form__row company-details-form__row--3'>
            <InputField name='email' label='Email' inputProps={{ disabled: isClientPortal }} />
          </div>

          <div className='company-details-form__row company-details-form__row--3'>
            <InputField name='mobile' label='Mobile phone' />
            <InputField name='landlineTel' label='Landline' />
          </div>
        </FormGroup>
      </FormFieldsCard>

      <NextAndPreviousLinks onNextButtonClick={handleNextButtonClick} />
    </Card>
  )
}

const EnhancedCompanyDetailsForm = connect(CompanyDetailsForm)
export { EnhancedCompanyDetailsForm as CompanyDetailsForm }
