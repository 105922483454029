import classnames from 'classnames'
import T from 'prop-types'

import {
  ShapeHorizontalIcon,
  ShapeVerticalIcon,
  ShapeHorizontal2
} from '../../icons'

import './DashboardBoxesCard.less'

const DashboardBoxesCard = ({
  className,
  Icon,
  isColumn,
  boxes,
  iconText,
  onCardClick
}) => {
  const showColumnShape = isColumn && !iconText
  const showHorizontalShape = !isColumn && !iconText

  return (
    <div
      className={classnames('dashboard-boxes-card', className, {
        'dashboard-boxes-card--column': isColumn,
        'dashboard-boxes-card--with-icon-text': iconText
      })}
      onClick={onCardClick}
    >
      <div className='dashboard-boxes-card__shape'>
        {showColumnShape && (
          <ShapeVerticalIcon className='dashboard-boxes-card__shape__vertical' />
        )}
        {showHorizontalShape && (
          <ShapeHorizontalIcon className='dashboard-boxes-card__shape__horizontal' />
        )}
        {!!iconText && (
          <ShapeHorizontal2 className='dashboard-boxes-card__shape__horizontal-new' />
        )}
      </div>

      {Icon && (
        <div
          className={classnames('dashboard-boxes-card__icon', {
            'dashboard-boxes-card__icon--with-text': iconText
          })}
        >
          <Icon />
          {iconText && (
            <span className='dashboard-boxes-card__icon__text'>{iconText}</span>
          )}
        </div>
      )}

      <div
        className={classnames('dashboard-boxes-card__boxes', {
          'dashboard-boxes-card__boxes--column': isColumn
        })}
      >
        {boxes.map(({ quantity, description }, i) => (
          <div key={i} className='dashboard-boxes-card__boxes__box'>
            <div className='dashboard-boxes-card__boxes__box__quantity'>
              {quantity}
            </div>
            <div className='dashboard-boxes-card__boxes__box__description'>
              {description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

DashboardBoxesCard.defaultProps = {
  isColumn: false
}

DashboardBoxesCard.propTypes = {
  Icon: T.elementType,
  isColumn: T.bool,
  boxes: T.arrayOf(
    T.shape({
      quantity: T.number,
      description: T.string
    })
  ),
  iconText: T.node
}

export { DashboardBoxesCard }
