import { useState } from 'react'
import classnames from 'classnames'
import { Link, createSearchParams } from 'react-router-dom'
import { TaskStatuses } from '@elenfs/elen-constants'

import { InlineForm } from '../../../misc'
import { ChevronRightIcon } from '../../../icons'

import './TaskKanbanColumnFooter.less'

const { COMPLETED } = TaskStatuses
const textareaFieldClass = 'kanban-board__column__inline-form'

const TaskKanbanColumnFooter = ({
  onClick,
  onCreateTask,
  errorOnCreateTask,
  loadingOnCreateTask,
  additionalInitialValues,
  isViewAllCompletedShown,
  title,
  ...restProps
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const searchParams = createSearchParams({
    status: [COMPLETED]
  }).toString()

  return (
    <>
      {title?.toLowerCase() !== 'completed' && (
        <InlineForm
          {...{
            additionalInitialValues,
            onSubmit: onCreateTask,
            inputName: 'summary',
            inputId: 'task',
            mutationError: errorOnCreateTask,
            loading: loadingOnCreateTask,
            dontShowLoading: true,
            placeholder: '+ add new task',
            isCollapsed,
            setIsCollapsed,
            className: classnames(
              textareaFieldClass,
              !isCollapsed && `${textareaFieldClass}--expanded`
            )
          }}
        />
      )}

      {
        isViewAllCompletedShown && (
          <Link className='view-all-link' to={`../list-view?${[searchParams]}`}>
            View all Completed tasks
            <ChevronRightIcon />
          </Link>
        )
      }
    </>
  )
}

export { TaskKanbanColumnFooter }
