import moment from 'moment'

/*
  The official way from the Moment docs
  to prevent parsing year inputs of 20-68 as 2020-2068.
  (or to customize two digit year parsing however you like)
*/
moment.parseTwoDigitYear = (yearString) => {
  const twoDigitYear = parseInt(yearString)
  const currentYear = new Date().getFullYear()
  const currentTwoDigitYear = currentYear % 100

  let parsedYear
  if (currentTwoDigitYear >= twoDigitYear) {
    // eg 2020 - 20 + 15 = 2015
    // eg 2095 - 95 + 94 = 2094
    parsedYear = currentYear - currentTwoDigitYear + twoDigitYear
  } else {
    // eg 2020 - 20 - 100 + 46 = 1946
    // eg 2095 - 95 - 100 + 96 = 1996
    parsedYear = currentYear - currentTwoDigitYear - 100 + twoDigitYear
  }

  return parsedYear
}
