import constants from '@elenfs/elen-constants'

import { withAuthorization } from '../../hoc/container'
import { PaymentContainer } from './PaymentContainer'

const { UserTypes } = constants.user

const SecureSectionWithAuthorization = withAuthorization({
  redirectTo: '/login',
  userType: UserTypes.COMPANY_USER
})(PaymentContainer)

export default SecureSectionWithAuthorization
