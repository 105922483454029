import { prop, path, pick } from 'ramda'
import { Link } from 'react-router-dom'

import { TableHeaders } from './const'
import { formatDate } from './../../../helpers/date'
import {
  buildSelectOptionsWithNoSort
} from './../../form/fields/SelectField/helpers'
import {
  FilterDateRangeLabels,
  FilterDateRanges
} from './../../../constants/filterDate'

const buildFirstNameCell = row => (
  <Link className='emphasize' to={`/secure/clients/${row.id}/summary`}>
    <span>{row.fullName}</span>
  </Link>
)

export const rowSchema = [
  {
    dataLabel: TableHeaders.NEXT_REVIEW_DATE,
    buildData: row => formatDate(prop('nextReviewAt', row))
  },
  {
    dataLabel: TableHeaders.CLIENT_NAME,
    buildData: buildFirstNameCell
  },
  {
    dataLabel: TableHeaders.CURRENT_ADVISER,
    buildData: path(['financialAdviser', 'fullName'])
  },
  {
    dataLabel: TableHeaders.ADDRESS_LINE_1,
    buildData: prop('address1')
  },
  {
    dataLabel: TableHeaders.ADDRESS_LINE_2,
    buildData: prop('address2')
  },
  {
    dataLabel: TableHeaders.CITY,
    buildData: prop('city')
  },
  {
    dataLabel: TableHeaders.POST_CODE,
    buildData: prop('postCode')
  },
  {
    dataLabel: TableHeaders.EMAIL,
    buildData: row => <a href={`mailto: ${row.email}`}>{row.email}</a>
  },
  {
    dataLabel: TableHeaders.MOBILE_PHONE,
    buildData: row => <a href={`tel: ${row.mobile}`}>{row.mobile}</a>
  }
]

const filterDateRangeKeys = [
  FilterDateRanges.THIS_WEEK,
  FilterDateRanges.THIS_MONTH,
  FilterDateRanges.NEXT_WEEK,
  FilterDateRanges.NEXT_MONTH,
  FilterDateRanges.CUSTOM
]

export const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)

const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)
