import { useState } from 'react'

import { Form } from '../../../form/Form'
import FormErrorMessages from '../../../form/FormErrorMessages'
import { TextAreaField } from '../../../form/fields'
import { Button, Card } from '../../../misc'
import { PencilIcon } from '../../../icons'
import { NoteDetails } from './NoteDetails'

import './ClientNotesCard.less'

const PLACEHOLDER = 'Enter notes about the client here'

const ClientNotesCard = (props) => {
  const {
    client: { note },
    loading,
    errors: validationErrors,
    handleSubmit,
    setFieldValue
  } = props
  const noteContent = note && note.content

  const [isInEditMode, setIsInEditMode] = useState(false)

  const _enterEditMode = () => {
    setIsInEditMode(true)
  }
  const _exitEditMode = () => {
    setIsInEditMode(false)
  }

  const _handleCancel = () => {
    setFieldValue('content', noteContent)
    setIsInEditMode(false)
  }

  const _handleSubmit = async (...args) => {
    await handleSubmit(...args)
    _exitEditMode()
  }

  return (
    <Card>
      <div className='client-notes-card-content'>
        <div className='client-notes-card-content__header'>
          <Card.Heading as='h3'>Notes</Card.Heading>
          {
            !isInEditMode && (
              <>
                {noteContent && (
                  <div className='client-notes-card-content__header__info'>
                    <NoteDetails note={note} />
                  </div>
                )}
                <PencilIcon onClick={_enterEditMode} className='icon' />
              </>
            )
          }
        </div>
        <div className='client-notes-card-content__body'>
          {isInEditMode
            ? (
              <Form loading={loading} onSubmit={_handleSubmit}>
                <TextAreaField
                  name='content'
                  inputProps={{
                    maxLength: 5000,
                    rows: 4,
                    placeholder: PLACEHOLDER
                  }}
                />

                <FormErrorMessages validationErrors={validationErrors} />

                <Button color={Button.Colors.BLUE} type='submit'>
                  Done
                </Button>
                <Button
                  disabled={loading}
                  color={Button.Colors.GREY}
                  onClick={_handleCancel}
                  type='button'
                >
                  Cancel
                </Button>
              </Form>
              )
            : (
              <div
                onClick={_enterEditMode}
                className={'client-notes-card-content__body_uneditable text-content' + (noteContent ? '' : ' text-light')}
              >
                {noteContent || PLACEHOLDER}
              </div>
              )}
        </div>
      </div>
    </Card>
  )
}

export default ClientNotesCard
