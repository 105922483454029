import PropTypes from 'prop-types'

import { Button } from '../../misc'
import SyncIcon from '../../icons/Sync'

import './ImportSyncButton.less'

const ImportSyncButton = ({ mutate, loading }) => (
  <Button
    className='import-sync-button'
    type='button'
    color={Button.Colors.BLUE}
    disabled={loading}
    onClick={mutate}
  >
    <SyncIcon /> Manual Sync
  </Button>
)

ImportSyncButton.propTypes = {
  mutate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  called: PropTypes.bool
}

export { ImportSyncButton }
