import { DropdownMenu } from '../../../misc'
import { ClientGlobalSearch } from '../../../misc/ClientGlobalSearch/ClientGlobalSearch'
import { CreateClientButton } from '../../CreateClientButton'
import { ToggleMyClients } from '../ToggleMyClients'

import './ClientsListActionHeader.less'

export const ClientsListActionHeader = ({
  persistUserSettingsData,
  userSettingData,
  isCurrentUserFA,
  handleClientSearch,
  clients,
  loading,
  refetchClients,
  isCashCalcConnectionActive
}) => (
  <div className='clients-list-action-header'>
    <div className='clients-list-action-header__clientFind'>
      <div>
        <ClientGlobalSearch
          autoCompleteData={clients}
          autoSuggestion={false}
          onInputChange={handleClientSearch}
          loading={loading}
          showSearchIconOnMobile={false}
        />
      </div>

      <div className='clients-list-action-header__clientFind--actions'>
        {isCurrentUserFA && (
          <ToggleMyClients
            onChange={persistUserSettingsData}
            isInitiallyActive={userSettingData}
          />
        )}
        <CreateClientButton
          direction={DropdownMenu.Directions.RIGHT}
          refetchClients={refetchClients}
          isCashCalcConnectionActive={isCashCalcConnectionActive}
          responsive
        />
      </div>
    </div>
  </div>
)
