import classnames from 'classnames'

import { PadlockIcon } from '../../icons'

import './GeneralErrorPage.less'

const GeneralErrorPage = ({ icon: IconComponent, text, className, title }) => (
  <div className={classnames('general-error-page', className)}>
    <div className='general-error-page__content'>
      {IconComponent
        ? (
          <IconComponent className='general-error-page__content__icon' />
          )
        : (
          <PadlockIcon className='general-error-page__content__icon' />
          )}
      {title && (
        <h2 className='general-error-page__content__title'> {title} </h2>
      )}
      <span className='general-error-page__content__text'>
        {text || (
          <>
            You do not have permission to view this page, <br />
            contact your administrator to gain access.
          </>
        )}
      </span>
    </div>
  </div>
)

export { GeneralErrorPage }
