const CommentIcon = (props) => (
  <svg viewBox='0 0 24 24' width={24} height={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M21 5.5v10.9944178c0 .7796961-.5948881 1.4204487-1.35554 1.4931334l-.1500318.0068562-9.0358986-.0335645-2.75142282 2.7514228c-.39052429.3905243-1.02368927.3905243-1.41421356 0C6.10535684 20.5247293 6 20.2703754 6 20.0051589V18l-1.51957688-.0198341C3.65970107 17.9694536 3 17.3010856 3 16.4802937V5.5C3 4.67157288 3.67157288 4 4.5 4h15c.8284271 0 1.5.67157288 1.5 1.5zM19 6H5v10h14V6z'
      />
    </g>
  </svg>
)

export default CommentIcon
