import classnames from 'classnames'

import { Button, Modal } from '../../../misc'
import { ServiceLevelsModal } from '../ServiceLevelsModal'
import { PencilIcon } from '../../../icons'

const EditButton = (props) => (
  <PencilIcon {...props} className='pencil-icon' />
)

const UpdateServiceLevelsButton = props => {
  const { className, serviceLevel, ...restProps } = props

  return (
    <Modal.Trigger
      as={Button}
      modal={ServiceLevelsModal}
      className={classnames(className)}
      modalProps={{ serviceLevel }}
      {...restProps}
    >
      <EditButton />
    </Modal.Trigger>
  )
}

export { UpdateServiceLevelsButton }
