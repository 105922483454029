import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './ToastifyContainer.less'

const ToastifyContainer = (props) => (
  <ToastContainer
    autoClose={4000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnVisibilityChange
    draggable={false}
    pauseOnHover
    className='react-toastify'
    bodyClassName='react-toastify__body'
    closeButton={false}
    {...props}
  />
)

export default ToastifyContainer
