import T from 'prop-types'
import classnames from 'classnames'

import Heading from '../../misc/Heading'

export const FormSectionHeader = ({ children, className }) => (
  <Heading
    as='h3'
    className={classnames(
      'form-new__section__header',
      'form-section-header', {
        [className]: !!className
      })}
  >
    {children}
  </Heading>
)

FormSectionHeader.propTtpes = {
  children: T.string,
  className: T.string
}
