import React from 'react'
import constants from '@elenfs/elen-constants'

import ClientLog from '../ImportJobSummary/ImportJobLog/ClientLog'
import ImportJobFailure from '../ImportJobSummary/ImportJobLog/ImportJobFailure'

const {
  DataImportJobStatuses: { SUCCESS }
} = constants.dataImport

const isFailure = job => job?.status !== SUCCESS

const hasProcessedClients = log => !!log?.length

const showLog = ({ job, logs }) => hasProcessedClients(logs) || isFailure(job)

const showJobFailure = job => isFailure(job) && !hasProcessedClients(job)

export const SingleImportLogSummary = ({ job, logs, dataImportType }) => {
  if (!showLog({ job, logs })) {
    return null
  }

  return (
    <div className='single-import-layout__mid-content__log'>
      {showJobFailure(job) && (
        <ImportJobFailure
          job={job}
          dataImportType={dataImportType}
        />
      )}

      {
        logs?.map((log, i) => (
          <ClientLog
            key={i}
            dataImportType={dataImportType}
            {...log}
          />
        ))
      }
    </div>
  )
}
