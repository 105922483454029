import { pick, pipe, map, prepend, assoc, uniq, propEq } from 'ramda'

export const setActive = bool => assoc('isActive', bool)
export const isCategory = propEq('category')

export const buildCategories = (list, allCategoriesLabel) =>
  pipe(
    map(pipe(pick(['category']), setActive(false))),
    uniq,
    prepend({ category: allCategoriesLabel, isActive: true })
  )(list)

export const filterListByCategory = (list, categories, allCategoriesLabel) => {
  const { category } = categories.find(propEq('isActive', true))
  if (category === allCategoriesLabel) return list
  return list.filter(isCategory(category))
}
