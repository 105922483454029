import constants, { ParsingFailureKinds } from '@elenfs/elen-constants'
import { propEq } from 'ramda'

const {
  MISSING_VALUE,
  INVALID_VALUE,
  DUPLICATED_VALUE
} = constants.dataImport.InvalidFieldErrors

const {
  PolicyTransactionTypes: { CONTRIBUTION, WITHDRAWAL }
} = constants.policy.common

const {
  DataImportProcessingEvents: {
    WARNING_NO_POLICY_TO_UPDATE,
    ERROR_PARSING_FAILURE,
    ERROR_CLIENT_INVALID,
    ERROR_POLICY_INVALID,
    ERROR_CLIENT_INTERNAL_FAILURE,
    ERROR_POLICY_INTERNAL_FAILURE,
    ERROR_POLICY_TRANSACTION_INTERNAL_FAILURE,
    ERROR_POLICY_TRANSACTION_INVALID
  }
} = constants.dataImport

const warningLogEntries = [
  WARNING_NO_POLICY_TO_UPDATE
]

const errorLogEntries = [
  ERROR_PARSING_FAILURE,
  ERROR_CLIENT_INVALID,
  ERROR_POLICY_INVALID,
  ERROR_CLIENT_INTERNAL_FAILURE,
  ERROR_POLICY_INTERNAL_FAILURE,
  ERROR_POLICY_TRANSACTION_INTERNAL_FAILURE,
  ERROR_POLICY_TRANSACTION_INVALID
]

const fieldsLabel = Object.freeze({
  firstName: 'First name',
  lastName: 'Last name',
  niNumber: 'NI number',
  email: 'Email',
  policyType: 'Policy type',
  policyNumber: 'Policy number',
  provider: 'Provider',
  'currentValue.amount': 'Current value',
  currentValue: 'Current value',
  gender: 'Gender',
  dateOfBirth: 'Date of birth',
  startDate: 'Start date',
  endDate: 'End date',
  number: 'Policy number',
  'amount.amount': 'Amount',
  amount: 'Amount',
  holders: 'Holders',
  valuationDate: 'Valuation Date'
})

const errorCodeLabel = Object.freeze({
  [MISSING_VALUE]: 'missing',
  [INVALID_VALUE]: 'invalid',
  [DUPLICATED_VALUE]: 'duplicated'
})

const ensureArray = value => (Array.isArray(value) ? value : [value])

export const isLogEntryOfType = (typesToCheck, logEntry) =>
  ensureArray(typesToCheck).includes(logEntry.eventType)

export const isWarningLogEntry = logEntry =>
  isLogEntryOfType(warningLogEntries, logEntry)

export const isErrorLogEntry = logEntry =>
  isLogEntryOfType(errorLogEntries, logEntry)

export const findLogEntryWithType = (typesToCheck, logEntries) =>
  logEntries.find(entry => isLogEntryOfType(typesToCheck, entry))

export const filterLogEntriesWithType = (typesToCheck, logEntries) =>
  logEntries.filter(entry => isLogEntryOfType(typesToCheck, entry))

export const filterErrorLogEntries = logEntries =>
  filterLogEntriesWithType(errorLogEntries, logEntries)

const fieldText = fields =>
  `${fields.map(field => `${fieldsLabel[field]}`)}`.replace(',', ' and ')

export const descriptionByFailureKind = logEntry => {
  const {
    parsingFailureKind,
    invalidFields,
    missingFields,
    additionalInfo
  } = logEntry
  const { rowNumber } = additionalInfo
  let description = ''
  const rowText = rowNumber ? `Row ${rowNumber} - ` : ''

  switch (parsingFailureKind) {
    case ParsingFailureKinds.MISSING_FIELDS:
      description = `${rowText} ${fieldText(missingFields)} ${
        missingFields?.length > 1 ? 'are' : 'is'
      } missing`

      return { rowNumber, description }
    case ParsingFailureKinds.INVALID_FIELDS:
      description = `${rowText} ${fieldText(invalidFields)} ${
        invalidFields?.length > 1 ? 'are' : 'is'
      } of invalid format`

      return { rowNumber, description }
    case ParsingFailureKinds.INTERNAL_FAILURE:
      return { rowNumber, description }
    default:
      return { rowNumber, description }
  }
}

export const dataImportParsingError = logEntries =>
  descriptionByFailureKind(findLogEntryWithType(errorLogEntries, logEntries))

export const buildLogErrorDescription = fields => (
  <>
    {fields.map(({ path, errorCode }, i) => (
      <span key={i}>
        {fieldsLabel[path]} is {errorCodeLabel[errorCode]}
      </span>
    ))}
  </>
)

export const filterLogEntriesByTransactionType = (logEntries, isContribution) =>
  logEntries.filter(
    propEq('policyTransactionType', isContribution ? CONTRIBUTION : WITHDRAWAL)
  )
