const TimeRoundedIcon = props => (
  <svg width={16} height={16} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M16 16V0H0v16z' />
      <path
        d='M8 2c3.308 0 6 2.691 6 6 0 3.308-2.692 6-6 6-3.309 0-6-2.692-6-6C2 4.69 4.691 2 8 2zm0 1.311A4.694 4.694 0 003.31 8 4.694 4.694 0 008 12.688 4.694 4.694 0 0012.688 8 4.694 4.694 0 008 3.31zm.01 1.673c.282 0 .51.228.51.508v2.515l1.743 1.744a.508.508 0 01-.718.719L7.65 8.577a.505.505 0 01-.146-.384l-.002-.024V5.492c0-.28.227-.508.508-.508z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { TimeRoundedIcon }
