import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { compose } from 'ramda'

import { UserMessagesNotificationsMenu } from './UserMessagesNotificationsMenu'

import { withUnreadMessagesSubscription, withCurrentUser } from './../../hoc/container'

const UserMessagesNotificationsMenuContainer = ({ unreadMessagesCount, ...otherProps }) => {
  const navigate = useNavigate()

  const navigateOnMsgClick = useCallback(
    clientId => navigate(`/secure/clients/${clientId}/messages`),
    [navigate]
  )

  const navigateToMessagesTabClientPortal = useCallback(
    () => navigate('/clients/secure/overview/messages'),
    [navigate]
  )

  return (
    <UserMessagesNotificationsMenu
      {...{
        ...otherProps,
        unreadMessageThreadsList: unreadMessagesCount,
        navigateOnMsgClick,
        navigateToMessagesTabClientPortal
      }}
    />
  )
}

const EnhancedUserMessagesNotificationsMenuContainer = compose(
  withCurrentUser(),
  withUnreadMessagesSubscription()
)(UserMessagesNotificationsMenuContainer)

export { EnhancedUserMessagesNotificationsMenuContainer as UserMessagesNotificationsMenuContainer }
