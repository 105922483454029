import { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { SingleImportLogLayout } from './SingleImportLogLayout'
import { paginationLimit } from '../ImportLog'
import { Spinner, ErrorMessage } from '../../misc'
import { ImportLogEmpty } from '../ImportLog/ImportLogEmpty'
import { getJobStats, getJobInfo } from '../ImportLogLayout/helpers'

import dataImportLog from '../../../graphql/queries/dataImport/dataImportLog.graphql'

export const SingleImportLogLayoutContainer = ({ link, dataImportType }) => {
  const { jobId } = useParams()

  const [job, setJob] = useState({})
  const [dataImportLogs, setDataImportLogs] = useState([])
  const [paginationData, setPaginationData] = useState({
    page: 0,
    perPage: 1,
    count: null
  })

  const limit = paginationLimit

  const handleOnCompleteCallback = (data) => {
    const { result, ...rest } = data?.dataImportLog
    setJob(result?.dataImportJob)
    setDataImportLogs(result?.logs)
    setPaginationData((prevState) => ({
      ...prevState, ...rest
    }))
  }

  const { error, loading, data, fetchMore } = useQuery(dataImportLog, {
    fetchPolicy: 'cache-and-network',
    variables: { jobId, limit, source: dataImportType },
    onCompleted: (data) => handleOnCompleteCallback(data)
  })

  const stats = getJobStats({ job, logs: dataImportLogs })
  const { statusText } = getJobInfo({ job, stats })

  const handlePaginationChange = useCallback(
    (page) => {
      const offset = Math.ceil(Math.abs(limit * page))
      fetchMore({
        variables: { limit, offset },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.dataImportLog?.result?.logs) return prev
          return { ...prev, ...fetchMoreResult }
        }
      })
    },
    [fetchMore]
  )

  const isEmpty = !job && !!dataImportLogs?.length

  const pageCount = Math.ceil(paginationData?.count / limit)

  const initialLoading = loading && !data

  if (initialLoading) return <Spinner />

  if (error) {
    return <ErrorMessage text='Failed to get data import log' />
  }

  return (
    <>
      <SingleImportLogLayout
        link={link}
        pageStatus={statusText}
        stats={stats}
        job={job}
        logs={dataImportLogs}
        dataImportType={dataImportType}
        pageCount={pageCount}
        handlePaginationChange={handlePaginationChange}
      />

      <ImportLogEmpty {...{ hidden: !isEmpty }} />
    </>
  )
}

SingleImportLogLayoutContainer.propTypes = {
  link: PropTypes.string.isRequired
}
