import classnames from 'classnames'

import { AuditLogEntryModal } from '../AuditLogEntryModal'
import { PlusIcon, CommentIcon } from '../../icons'
import { Modal, Button } from '../../misc'

import './LogActivityButton.less'

const commentIconClass = 'log-activity-button__comment-icon'
const LogActivityButton = ({
  clientId,
  children,
  showPlusIconOnMobile,
  ...props
}) => (
  <Modal.Trigger
    as={Button}
    color={Button.Colors.BLUE}
    modal={AuditLogEntryModal}
    modalProps={{
      auditLogEntry: { clientId }
    }}
    {...props}
  >
    {showPlusIconOnMobile && <PlusIcon className='log-activity-button__plus-icon' />}
    <CommentIcon className={classnames(commentIconClass, showPlusIconOnMobile && `${commentIconClass}--hidden`)} />
    <span>
      {children}
    </span>
  </Modal.Trigger>
)

LogActivityButton.defaultProps = {
  children: 'Log activity'
}

export default LogActivityButton
