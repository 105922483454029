const CogWheelIcon = props => (
  <svg viewBox='0 0 16 16' width={24} height={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M16 16V0H0v16z' />
      <path
        fill='currentColor'
        d='M9.185 1c.197 0 .367.137.41.33l.233 1.075c.286.094.566.21.835.347l.927-.596a.418.418 0 0 1 .522.056l1.676 1.676c.139.14.162.357.056.522l-.596.927c.137.27.253.549.347.835l1.076.234c.192.042.329.212.329.409v2.37a.418.418 0 0 1-.33.41l-1.075.233c-.094.286-.21.566-.347.835l.596.927a.418.418 0 0 1-.056.522l-1.676 1.676a.418.418 0 0 1-.522.056l-.927-.596c-.27.137-.549.253-.835.347l-.234 1.076a.418.418 0 0 1-.409.329h-2.37a.418.418 0 0 1-.41-.33l-.233-1.075a5.856 5.856 0 0 1-.835-.347l-.927.596a.418.418 0 0 1-.522-.056l-1.676-1.676a.418.418 0 0 1-.056-.522l.596-.927a5.856 5.856 0 0 1-.347-.835L1.33 9.594A.418.418 0 0 1 1 9.185v-2.37c0-.197.137-.367.33-.41l1.075-.233c.094-.286.21-.566.347-.835l-.596-.927a.418.418 0 0 1 .056-.522l1.676-1.676a.418.418 0 0 1 .522-.056l.927.596c.27-.137.549-.253.835-.347l.234-1.076A.418.418 0 0 1 6.815 1Zm-.337.837H7.152l-.215.986a.419.419 0 0 1-.296.314 5.022 5.022 0 0 0-1.118.464.418.418 0 0 1-.432-.013l-.85-.546-1.199 1.2.546.85c.084.13.09.296.013.431a5.02 5.02 0 0 0-.464 1.118.419.419 0 0 1-.314.296l-.986.215v1.696l.986.215a.418.418 0 0 1 .314.296c.109.389.265.765.464 1.118a.418.418 0 0 1-.013.432l-.546.85 1.2 1.199.85-.546a.418.418 0 0 1 .431-.013c.353.2.73.355 1.118.464.15.041.263.162.296.314l.215.986h1.696l.215-.986a.419.419 0 0 1 .296-.314 5.022 5.022 0 0 0 1.118-.464.418.418 0 0 1 .432.013l.85.546 1.199-1.2-.546-.85a.418.418 0 0 1-.013-.431c.2-.353.355-.73.464-1.118a.419.419 0 0 1 .314-.296l.986-.215V7.152l-.986-.215a.418.418 0 0 1-.314-.296 5.02 5.02 0 0 0-.464-1.118.418.418 0 0 1 .013-.432l.546-.85-1.2-1.199-.85.546a.418.418 0 0 1-.431.013 5.02 5.02 0 0 0-1.118-.464.419.419 0 0 1-.296-.314l-.215-.986ZM8 4.653A3.35 3.35 0 0 1 11.347 8 3.35 3.35 0 0 1 8 11.347 3.35 3.35 0 0 1 4.653 8 3.35 3.35 0 0 1 8 4.653Zm0 .837A2.513 2.513 0 0 0 5.49 8 2.513 2.513 0 0 0 8 10.51 2.513 2.513 0 0 0 10.51 8 2.513 2.513 0 0 0 8 5.49Z'
      />
    </g>
  </svg>
)

export { CogWheelIcon }
