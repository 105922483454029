import Checked from './Checked'
import Unchecked from './Unchecked'
import DisabledUnchecked from './DisabledUnchecked'
import DisabledChecked from './DisabledChecked'

export const CheckboxIcon = {
  Checked,
  Unchecked,
  DisabledUnchecked,
  DisabledChecked
}
