import { useMemo } from 'react'
import T from 'prop-types'
import { withFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { pipe, prop, pick, equals } from 'ramda'

import { Form } from '../../form'
import { Button, TitleInput, ErrorMessage, RouteLeavingGuard } from '../../misc'
import { WorkflowDiagramList } from '../WorkflowDiagramList'

import { actionType } from './../../../helpers/propTypes'

import './WorkflowPage.less'

const WorkflowPage = props => {
  const {
    values,
    workflowDiagramProps,
    loading,
    handleSubmit,
    error,
    dirty,
    workflowTemplate,
    isSubmitting
  } = props
  const navigate = useNavigate()

  const disabled = useMemo(
    () =>
      !values?.name?.length ||
      loading ||
      !workflowDiagramProps?.actions?.length,
    [loading, workflowDiagramProps, values]
  )

  const isRouteGuarded = useMemo(
    () =>
      !isSubmitting &&
      (dirty ||
        !equals(
          workflowTemplate.actionTemplates || workflowTemplate.actions,
          workflowDiagramProps.actions
        )),
    [dirty, workflowTemplate, workflowDiagramProps, isSubmitting]
  )

  return (
    <>
      <RouteLeavingGuard
        enabled={isRouteGuarded}
        yesButtonText='Yes'
        noButtonText='No'
      />

      <div className='workflow-page'>
        <div>
          <Form className='workflow-page__form' onSubmit={handleSubmit}>
            <div className='workflow-page__form__header'>
              <div className='workflow-page__form__header__container'>
                <TitleInput
                  name='name'
                  placeholder='Add workflow name'
                  onBackBtnClick={() => navigate('/secure/workflows')}
                />

                <Button
                  color={Button.Colors.BLUE}
                  disabled={disabled}
                  type='submit'
                >
                  Save
                </Button>
              </div>

              {error && <ErrorMessage text='Something went wrong!' />}
            </div>

            <div className='workflow-page__form__actions'>
              <WorkflowDiagramList {...workflowDiagramProps} />
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

WorkflowPage.propTypes = {
  workflowDiagramProps: T.shape({
    actions: T.arrayOf(actionType()).isRequired,
    onDeleteAction: T.func.isRequired,
    onSummaryClick: T.func.isRequired,
    onAddNewAction: T.func.isRequired,
    isWorkflowInstance: T.bool.isRequired
  }).isRequired,
  loading: T.bool.isRequired,
  handleSubmit: T.func.isRequired,
  error: T.object
}

const EnhancedWorkflowPage = withFormik({
  mapPropsToValues: pipe(prop('workflowTemplate'), pick(['name'])),
  handleSubmit: (values, { props: { onSubmitWorkflowTemplate } }) =>
    onSubmitWorkflowTemplate(values)
})(WorkflowPage)

export { EnhancedWorkflowPage as WorkflowPage }
