export const TableHeaders = Object.freeze({
  ADVISER: 'Adv.',
  CLIENT: 'Client',
  POLICY: 'Policy',
  POLICY_NUMBER: 'Policy Number',
  PROVIDER: 'Provider',
  FREQUENCY: 'Frequency',
  DUE_DATE: 'Due date',
  PAID: 'Paid',
  RECEIVED: 'Received'
})

export const TableHeadersCaptions = [
  { caption: TableHeaders.ADVISER },
  { caption: TableHeaders.CLIENT },
  { caption: TableHeaders.POLICY },
  { caption: TableHeaders.POLICY_NUMBER },
  { caption: TableHeaders.PROVIDER },
  { caption: TableHeaders.FREQUENCY },
  { caption: TableHeaders.DUE_DATE },
  { caption: TableHeaders.PAID },
  { caption: TableHeaders.RECEIVED }
]
