import { useQuery } from '@apollo/client'

import cashCalcConnectionGql from '../../../../graphql/queries/dataImport/cashCalcConnection.graphql'

const withCashCalcConnection = () => WrappedComponent => props => {
  const { loading, error, data, refetch } = useQuery(cashCalcConnectionGql, {
    fetchPolicy: 'cache-and-network'
  })

  const cashCalcConnection = data?.dataImportCashCalcConnection
  const isCashCalcConnected = !!cashCalcConnection
  const isCashCalcConnectionActive = cashCalcConnection?.isActive

  return (
    <WrappedComponent
      isCashCalcConnected={isCashCalcConnected}
      isCashCalcConnectionActive={isCashCalcConnectionActive}
      cashCalcConnectionLoading={loading}
      cashCalcConnectionError={error}
      refetchCashCalcConnection={refetch}
      {...props}
    />
  )
}

export { withCashCalcConnection }
