import T from 'prop-types'

import { Table } from '../../../misc'
import { IncomeTable } from '../IncomeTable'
import { PendingIncomeTableRow } from './PendingIncomeTableRow'
import { TableHeadersCaptions } from './constants'

import './PendingIncomeTable.less'

const PendingIncomeTable = ({ incomeRecords, onToggleReconciled, mutationLoading }) => (
  <IncomeTable className='pending-income-table'>
    <Table.Header>
      <Table.Row>
        {Object.values(TableHeadersCaptions).map((item) => (
          <Table.HeaderCell key={item.caption}>
            {item.caption}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
    <Table.Body className='income-table__body pending-income-table__body'>
      {incomeRecords.map((incomeRecord) => (
        <PendingIncomeTableRow
          key={incomeRecord.id}
          incomeRecord={incomeRecord}
          onToggleReconciled={onToggleReconciled}
          mutationLoading={mutationLoading}
        />
      ))}
    </Table.Body>
  </IncomeTable>
)

PendingIncomeTable.propTypes = {
  /**
   * Array of income records.
   */
  incomeRecords: T.arrayOf(T.object.isRequired).isRequired,
  /**
   * Function that toggles income reconciliation. Takes two arguments: `(incomeId, actualAmount)`.
   */
  onToggleReconciled: T.func.isRequired
}

export { PendingIncomeTable }
