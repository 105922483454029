import PropTypes from 'prop-types'
import classnames from 'classnames'

import { AuditLogEntryForm } from '../AuditLogEntryForm'
import { AuditLogEntryEmail } from '../AuditLogEntryEmail'
import { Modal } from '../../misc'

import './AuditLogEntryModal.less'

const ActivityLogLayout = ({
  auditLogEntry,
  refetchLogEntries,
  trigger,
  isOpen,
  close,
  onDownloadFile,
  className
}) => {
  const isGeneratedByEmail = auditLogEntry?.emailMessageId
  const emailMessage = auditLogEntry?.emailMessage

  const title = auditLogEntry && auditLogEntry.id
    ? 'Edit Log Entry'
    : 'Log Activity'

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      trigger={trigger}
      className={classnames(`${className}`, {
        [`${className}--extended`]: isGeneratedByEmail
      })}
    >
      <div className={`${className}__row`}>
        <div className={`${className}__row__audit-log`}>
          <Modal.Heading>{title}</Modal.Heading>
          <AuditLogEntryForm
            auditLogEntry={auditLogEntry}
            handleSave={close}
            refetchLogEntries={refetchLogEntries}
            isGeneratedByEmail={isGeneratedByEmail}
          />
        </div>
        {
          isGeneratedByEmail
            ? <AuditLogEntryEmail {...{
              className: `${className}__row__email`,
              onDownloadFile,
              id: auditLogEntry?.id,
              ...emailMessage
            }}
              />
            : null
        }
      </div>
    </Modal>
  )
}

ActivityLogLayout.propTypes = {
  auditLogEntry: PropTypes.object,
  trigger: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

export { ActivityLogLayout }
