import { SidePanel } from '../../misc'
import { TaskActionForm } from './TaskActionForm'
import { NewWorkflowActionButton } from '../NewWorkflowActionButton'

const TaskActionSidePanelTrigger = ({ children, ...props }) => (
  <SidePanel.Trigger
    as={NewWorkflowActionButton}
    title='Client task'
    sidePanelContent={TaskActionForm}
    sidePanelContentProps={props}
  >
    {children}
  </SidePanel.Trigger>
)

export { TaskActionSidePanelTrigger }
