import { useTasksModal } from '../../../hooks'
import { TaskDetailsModal } from '../TaskDetailsModal'

export const TaskModalButton = ({ children, className }) => {
  const taskModalProps = useTasksModal()
  const { openNewTaskModal } = taskModalProps

  return (
    <>
      <div
        onClick={openNewTaskModal}
        className={className}
      >
        {children}
      </div>

      <TaskDetailsModal {...taskModalProps} />
    </>
  )
}
