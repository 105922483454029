import { useCallback } from 'react'
import { withFormik } from 'formik'
import T from 'prop-types'
import { isEmpty } from 'ramda'
import constants from '@elenfs/elen-constants'

import { praemiumSchema } from '../../../schemas/dataImport/praemiumSchema'
import { Form, FormErrorMessages } from '../../form'
import { InputField } from '../../form/fields'
import { Button } from '../../misc'
import { buildInitialValues } from '../../../helpers/form'
import { parseGraphqlErrors } from '../../../helpers/error'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

const { DataImportTypes } = constants.dataImport

const PraemiumImportForm = ({
  isActive,
  loading,
  errors: validationErrors,
  submitForm,
  validateForm
}) => {
  const buttonText = isActive ? 'Disconnect from Morningstar' : 'Connect to Morningstar'

  const validateAndSubmit = useCallback(
    async (fn) => {
      const errors = await validateForm()

      if (!isEmpty(errors)) return

      const result = await submitForm()
      if (!result?.graphQLErrors) { fn && fn() }
    },
    [validateForm, submitForm, validationErrors]
  )

  const onSubmit = useCallback(
    () => validateAndSubmit(),
    [validateAndSubmit]
  )

  return (
    <Form>
      <Form.Group>
        <InputField
          name='firmRefNo'
          label='Folder name'
          inputProps={{
            disabled: isActive
          }}
        />

        <InputField
          name='username'
          label='Username'
          inputProps={{
            disabled: isActive
          }}
        />

        <InputField
          name='password'
          label='Password'
          inputProps={{
            type: 'password',
            disabled: isActive
          }}
        />
      </Form.Group>

      <FormErrorMessages validationErrors={validationErrors} />

      <Form.ButtonGroup>
        <Button
          color={Button.Colors.BLUE}
          disabled={loading}
          onClick={onSubmit}
        >
          {buttonText}
        </Button>
      </Form.ButtonGroup>
    </Form>
  )
}

const PraemiumImportFormEnhanced = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: praemiumSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ firmRefNo, username, password }) =>
    buildInitialValues(praemiumSchema, { firmRefNo, username, password }),
  handleSubmit: async (values, { props: { mutate }, setErrors }) => {
    const platform = DataImportPlatformLabels[DataImportTypes.PRAEMIUM]
    praemiumSchema.cast(values)

    try {
      const result = await mutate({ variables: { input: { ...values } } })
      return result
    } catch (error) {
      return setErrors(
        parseGraphqlErrors(
          error,
          error?.message || `Failed to connect to ${platform}`
        )
      )
    }
  }
})(PraemiumImportForm)

PraemiumImportForm.propTypes = {
  isActive: T.bool,
  loading: T.bool,
  data: T.object
}

export { PraemiumImportFormEnhanced as PraemiumImportForm }
