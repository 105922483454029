export const calcBarsWidth = (
  pendingAmount,
  receivedAmount,
  pendingChartBar,
  receivedChartBar
) => {
  let largerBar = pendingChartBar
  let smallerBar = receivedChartBar
  let largerAmount = pendingAmount
  let smallerAmount = receivedAmount

  if (pendingAmount < receivedAmount) {
    largerBar = receivedChartBar
    smallerBar = pendingChartBar
    largerAmount = receivedAmount
    smallerAmount = pendingAmount
  }

  const areEqual = pendingAmount === receivedAmount

  const areBothZero = areEqual && !largerAmount
  if (!areBothZero) {
    const percentage = areEqual
      ? 100
      : +(100 / +(largerAmount / smallerAmount).toFixed(2)).toFixed(2)

    const container = largerBar.parentElement
    const label = container.children[0]
    const amount = container.children[2]
    const siblingsWidth = amount.clientWidth + label.clientWidth + 17
    largerBar.style.setProperty('width', '100%')
    smallerBar.style.setProperty(
      'width',
      `calc((100% - ${siblingsWidth}px) * ${percentage}/100)`
    )
  } else {
    largerBar.style.setProperty('width', '0')
    smallerBar.style.setProperty('width', '0')
  }
}
