import { useState, useEffect, useCallback } from 'react'

import appConfig from '../../../appConfig'
import { COOKIE_CONSENT_KEY, COOKIES_LIST, COOKIE_TYPES, OAUTH } from './const'

export const useCookieConsent = (isOnInit = false) => {
  const [cookiesList, setCookiesList] = useState(COOKIES_LIST)
  const [isOpen, setIsOpen] = useState(false)
  const isOauth = window.location.hostname.split('.')[0] === OAUTH

  const onSubmit = useCallback(values => {
    window.localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(values))
    window[`ga-disable-${appConfig.GA_TRACKING_ID}`] = !values[COOKIE_TYPES.ANALYTICAL_PERFORMANCE]
    setCookiesList(current => {
      for (const [name, checked] of Object.entries(values)) {
        const cookie = current.find(cookie => cookie.name === name)
        if (cookie) cookie.checked = checked
      }
      return [...current]
    })
  }, [])

  const close = useCallback(() => setIsOpen(false), [])

  useEffect(() => {
    const currentCookiesLS = JSON.parse(
      window.localStorage.getItem(COOKIE_CONSENT_KEY)
    )

    if (isOnInit && !currentCookiesLS && !isOauth) setIsOpen(true)

    setCookiesList(current => {
      for (const [key, value] of Object.entries(currentCookiesLS || {})) {
        const cookie = current.find(({ name }) => name === key) || {}
        cookie.checked = value
      }
      return [...current]
    })
  }, [])

  return { onSubmit, cookiesList, close, isOpen, blockEscapeKey: isOnInit }
}
