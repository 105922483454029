import T from 'prop-types'

import ImportPlatformLayout from '../ImportPlatformLayout'
import { P1ImportForm } from '../P1ImportForm'
import { P1Guide, P1ImportGuide } from '../P1Guide'
import { ImportFooterConnection, ImportGuide } from '../ImportFooter'
import { Card } from './../../misc'
import AppConfig from '../../../appConfig'

const P1ImportPage = ({ p1Data }) => {
  const pageTitle = 'P1'
  return (
    <ImportPlatformLayout pageTitle={pageTitle}>
      <ImportPlatformLayout.LeftColumn>
        <Card.Group>
          <Card>
            <Card.Heading>P1 import data</Card.Heading>
            <P1Guide />
            <P1ImportForm {...p1Data} />
          </Card>
          <Card>
            <ImportFooterConnection
              pageTitle={pageTitle}
              link='/secure/admin/p1/logs'
            />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.LeftColumn>

      <ImportPlatformLayout.RightColumn>
        <Card.Group>
          <Card>
            <Card.Heading>Import data</Card.Heading>
            <P1ImportGuide />
          </Card>
          <Card>
            <ImportGuide link={AppConfig.P1_IMPORT_STEP_BY_STEP_LINK} />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.RightColumn>
    </ImportPlatformLayout>
  )
}

P1ImportPage.propTypes = {
  p1Data: T.shape({
    login: T.string,
    id: T.string,
    isActive: T.bool,
    type: T.string
  })
}

export { P1ImportPage }
