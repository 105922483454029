import constants from '@elenfs/elen-constants'

import { SingleImportLogLayout } from '../SingleImportLogLayout'

const { DataImportTypes } = constants.dataImport

export const TransactSingleLogPage = () => {
  const link = '/secure/admin/transact/logs'

  return (
    <SingleImportLogLayout
      link={link}
      dataImportType={DataImportTypes.TRANSACT}
    />
  )
}
