import T from 'prop-types'
import constants, {
  CompanyUserRoles,
  ActivityLogEntryTypes,
  WorkflowActionTypes,
  WorkflowActionStatuses
} from '@elenfs/elen-constants'

const { Currencies } = constants.misc

export const typeOrNull = (type) => T.oneOfType([T.oneOf([null]), type.isRequired])

export const optionValueType = () => T.oneOfType([T.bool, T.number, T.string, T.object])

export const optionsType = (valueType = optionValueType()) =>
  T.arrayOf(
    T.shape({
      label: T.node.isRequired,
      value: typeOrNull(valueType),
      isFixed: T.bool
    }).isRequired
  )

export const moneyType = () => T.shape({
  amount: T.number.isRequired,
  currency: T.oneOf(Object.values(Currencies)).isRequired
})

export const userType = () => T.shape({
  id: T.string.isRequired,
  fullName: T.string.isRequired,
  firstName: T.string.isRequired,
  lastName: T.string.isRequired,
  email: T.string
  // TODO: gradually expand with more fields as this is used in more places
})

export const companyClient = () => T.shape({
  id: T.string.isRequired,
  fullName: T.string.isRequired
})

export const companyClients = () => T.arrayOf(companyClient())

export const componentType = () => T.oneOfType([T.func, T.string])

export const actionType = () =>
  T.shape({
    taskAssigneeTeamRole: T.oneOf([...Object.values(CompanyUserRoles), '']),
    taskDescription: T.string,
    taskDueDate: T.oneOfType([T.number, T.string]),
    taskSummary: T.string,
    taskType: T.oneOf(Object.values(ActivityLogEntryTypes)),
    type: T.oneOf(Object.values(WorkflowActionTypes)),
    status: T.oneOf(Object.values(WorkflowActionStatuses))
  })
