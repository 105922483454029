import PropTypes from 'prop-types'
import classnames from 'classnames'

import DropdownIcon from '../../icons/Dropdown'

const DropdownMenuTrigger = ({
  children,
  className,
  icon: IconComponent,
  ...rest
}) => (
  <div
    className={classnames('dropdown-menu__trigger', className)}
    {...rest}
  >
    <span>{children}</span>
    <IconComponent className='dropdown-menu__trigger__icon' />
  </div>
)

DropdownMenuTrigger.defaultProps = {
  icon: DropdownIcon
}

DropdownMenuTrigger.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.func
}

export default DropdownMenuTrigger
