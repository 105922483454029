import PlusIcon from '../../icons/Plus'
import { Modal, Button } from '../../misc'
import ImportPlatformSelectionModal from '../ImportPlatformSelectionModal'

import './AddImportPlatformButton.less'

const AddImportPlatformButton = () => (
  <Modal.Trigger
    as={Button}
    color={Button.Colors.BLUE}
    modal={ImportPlatformSelectionModal}
  >
    <PlusIcon />
    <span>Add source</span>
  </Modal.Trigger>
)

export { AddImportPlatformButton }
