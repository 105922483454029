import T from 'prop-types'

import { PlusBigIcon } from '../../icons'

import './NewWorkflowActionButton.less'

const NewWorkflowActionButton = ({ children, onClick }) => (
  <div className='new-workflow-action-button' onClick={onClick}>
    <div className='new-workflow-action-button__icon-container'>
      <PlusBigIcon />
    </div>
    <span className='new-workflow-action-button__label'>{children}</span>
  </div>
)

NewWorkflowActionButton.propTypes = {
  onClick: T.func.isRequired
}

export { NewWorkflowActionButton }
