import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import './startup'
import App from './App'

import * as serviceWorker from './serviceWorker'

import './styles/index.less'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

serviceWorker.register()
