import companyDetailsSchema from './companyDetailsSchema'
import profitAndLossSchema from './profitAndLossSchema'
import clientAssetsAndLiabilitiesSchema from '../client/clientAssetsAndLiabilitiesSchema'
import clientFinancialAdvisersSchema from '../client/clientFinancialAdvisersSchema'
import clientObjectivesAndActionsSchema from '../client/clientObjectivesAndActionsSchema'

const corporateClientValidationSchema = companyDetailsSchema
  .concat(profitAndLossSchema)
  .concat(clientAssetsAndLiabilitiesSchema)
  .concat(clientFinancialAdvisersSchema)
  .concat(clientObjectivesAndActionsSchema)

export default corporateClientValidationSchema
