import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { ClientComplianceForm } from './ClientComplianceForm'
import { ErrorPage, ErrorMessage } from '../../misc'
import Spinner from '../../misc/Spinner'
import clientDetailsGql from '../../../graphql/queries/client/clientDetails.graphql'
import serviceLevelsGql from '../../../graphql/queries/serviceLevel/serviceLevels.graphql'
import updateIndividualClientGql from '../../../graphql/mutations/client/updateIndividualClient.graphql'
import updateCorporateClientGql from '../../../graphql/mutations/client/updateCorporateClient.graphql'
import { isCorporateClient } from '../../../helpers/client'
import { ColourTypesCodes } from '../../../constants/serviceLevels'

const useUpdateIndividualClientDetails = () => useMutation(updateIndividualClientGql)
const useUpdateCorporateClientDetails = () => useMutation(updateCorporateClientGql)

const ClientComplianceFormContainer = (props) => {
  const { clientId } = useParams()

  const { loading, error, data } = useQuery(clientDetailsGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  const { data: serviceLevels } = useQuery(serviceLevelsGql, {
    fetchPolicy: 'cache-and-network'
  })

  const client = data?.client

  const mutation = isCorporateClient(client)
    ? useUpdateCorporateClientDetails
    : useUpdateIndividualClientDetails
  const [mutate, mutationState] = mutation()

  const onSubmit = useCallback(
    (input) => (
      mutate(input)
    ), [mutate]
  )

  const serviceLevel = serviceLevels && serviceLevels.serviceLevels

  const BuildServiceLevelsColourOptions = (values) => {
    const options = values && Object.values(values).map((value) => ({ value: value.id, label: value.name, color: ColourTypesCodes[value.colour] }))
    return options
  }

  const serviceLevelsOptions = BuildServiceLevelsColourOptions(serviceLevel) || []

  if (loading && !data) return <Spinner />

  if (error) {
    return (
      <ErrorPage {...error}>
        <ErrorMessage text='Failed to retrieve client compliance form data' />
      </ErrorPage>
    )
  }

  return (
    <ClientComplianceForm
      onSubmit={onSubmit}
      client={client}
      clientId={clientId}
      loading={mutationState.loading}
      serviceLevelsOptions={serviceLevelsOptions}
      {...props}
    />
  )
}

export { ClientComplianceFormContainer }
