import Header from '../../nav/Header'
import { HeaderLogo } from '../../nav/HeaderLogo'

const LoggedOutLayout = ({ children }) => (
  <>
    <Header>
      <HeaderLogo />
    </Header>

    {children}
  </>
)

export default LoggedOutLayout
