import fileIcons from '../components/icons/File'

export const formatFileSize = (fileSizeB) => {
  return _formatFileSize(fileSizeB)
}

const _formatFileSize = (fileSize, depth = 1) => {
  if (fileSize < denominator) {
    let roundedFloatString = fileSize.toFixed(decimalPoints)
    const roundedFloat = parseFloat(roundedFloatString)
    const integerValue = Math.round(roundedFloat)
    if (Math.abs(roundedFloat - integerValue) <= integerEpsilon) {
      roundedFloatString = integerValue.toString()
    }
    return `${roundedFloatString}${depthUnitMap[depth]}`
  }

  return _formatFileSize(fileSize / denominator, depth + 1)
}

const decimalPoints = 1

const integerEpsilon = 0.2

const denominator = 1024

const depthUnitMap = {
  1: 'B',
  2: 'KB',
  3: 'MB',
  4: 'GB',
  5: 'TB'
}

export const getFileIcon = (extension) => fileIcons[extension] || fileIcons.other
