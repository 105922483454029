import PropTypes from 'prop-types'
import classnames from 'classnames'

import Heading from '../Heading'

const ModalHeading = ({ className, children }) => (
  <Heading as='h3' className={classnames('modal__heading', className)}>
    {children}
  </Heading>
)

ModalHeading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export { ModalHeading }
