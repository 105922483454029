import { useCallback } from 'react'
import T from 'prop-types'

const FileInputIcon = ({
  disabled,
  multiple,
  onChange,
  icon: IconComponent,
  className
}) => {
  const handleChange = useCallback(
    e => {
      onChange(e.target.files)
    },
    [onChange]
  )

  const handleClick = useCallback(e => {
    // Makes possible to select the same file more than once
    e.target.value = null
  }, [])

  return (
    <div className={className}>
      <label htmlFor='file-input'>
        <IconComponent />
      </label>
      <input
        type='file'
        id='file-input'
        multiple={multiple}
        disabled={disabled}
        className='hidden'
        onClick={handleClick}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  )
}

FileInputIcon.defaultProps = {
  multiple: false
}

FileInputIcon.propTypes = {
  children: T.node,
  multiple: T.bool,
  onChange: T.func.isRequired
}

export { FileInputIcon }
