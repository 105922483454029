import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Modal, Button } from '../../misc'
import { DropdownSelection } from '../../misc/DropdownSelection'
import { useOnChangeCallback } from '../../form/inputs/hooks'

import './ImportPlatformSelectionModal.less'

const importPlatforms = [
  {
    label: 'Transact',
    value: '/secure/admin/transact'
  },
  {
    label: 'CSV file',
    value: '/secure/admin/csv-import'
  },
  {
    label: 'CashCalc',
    value: '/secure/admin/cashcalc'
  },
  {
    label: 'P1',
    value: '/secure/admin/p1'
  },
  {
    label: 'Morningstar Wealth Platform',
    value: '/secure/admin/morningstar'
  }
]

const ImportPlatformSelectionModal = ({ isOpen, close, trigger }) => {
  const [platformPageUrl, setPlatformPageUrl] = useState(importPlatforms[0].value)

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      trigger={trigger}
      className='import-platform-selection-modal'
    >
      <Modal.Heading>Choose a source</Modal.Heading>
      <DropdownSelection
        className='import-platform-selection-modal__selection'
        selected={platformPageUrl}
        options={importPlatforms}
        onChange={useOnChangeCallback(setPlatformPageUrl, (event) => event && event.value)}
        inputProps={{ isSearchable: false }}
      />
      <Button
        as={Link}
        color={Button.Colors.BLUE}
        to={platformPageUrl || '#'}
        disabled={!platformPageUrl}
      >
        Continue
      </Button>
    </Modal>
  )
}

ImportPlatformSelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired
}

export default ImportPlatformSelectionModal
