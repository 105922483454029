import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import appConfig from '../../../appConfig'
import { withCashCalcConnection } from '../../hoc/container'

import { CashCalcImportPage } from './CashCalcImportPage'

import connectCashCalcGql from '../../../graphql/mutations/dataImport/connectCashCalc.graphql'
import disconnectCashCalcGql from '../../../graphql/mutations/dataImport/disconnectCashCalc.graphql'

const { CASH_CALC_CLIENT_ID, CASH_CALC_REDIRECT_URI } = appConfig

const clientId = CASH_CALC_CLIENT_ID
const redirectURI = CASH_CALC_REDIRECT_URI

const onCashCalcLogin = () => {
  const responseType = 'code'
  const scope = encodeURIComponent('client-finances client-info')
  const state = encodeURIComponent(
    window.btoa(JSON.stringify({ redirectURL: window.location.href }))
  )
  window.location.assign(
    `https://cashcalc.co.uk/oauth/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectURI}&scope=${scope}&state=${state}`
  )
}

const CashCalcImportPageContainer = props => {
  const {
    isCashCalcConnected: isConnected,
    isCashCalcConnectionActive: isConnectionActive,
    cashCalcConnectionLoading,
    cashCalcConnectionError,
    refetchCashCalcConnection
  } = props

  const location = useLocation()

  const [
    connectCashCalc,
    { loading: connectLoading, error: connectError }
  ] = useMutation(connectCashCalcGql, { errorPolicy: 'all', onCompleted: refetchCashCalcConnection })

  const [
    disconnectCashCalc,
    { loading: disconnectLoading, error: disconnectError }
  ] = useMutation(disconnectCashCalcGql, { onCompleted: refetchCashCalcConnection })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get('code')

    if (code && !isConnected) {
      connectCashCalc({ variables: { input: { code } } })
    }
  }, [isConnected, connectCashCalc, location])

  const pageProps = {
    loading: cashCalcConnectionLoading || connectLoading || disconnectLoading,
    error: cashCalcConnectionError || connectError || disconnectError,
    onCashCalcLogin,
    disconnectCashCalc,
    isConnectionActive
  }

  return <CashCalcImportPage {...pageProps} />
}

const EnhancedCashCalcImportPageContainer = withCashCalcConnection()(CashCalcImportPageContainer)

export { EnhancedCashCalcImportPageContainer as CashCalcImportPageContainer }
