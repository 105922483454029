const DisabledCheckedIcon = (props) => (
  <svg viewBox='0 0 24 24' width={20} height={20} {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx='12' cy='12' r='9.5' fill='#1F2027' fillOpacity='.04' stroke='#DFE3E9' />
      <circle cx='12' cy='12' r='6' fill='#BABCC7' />
    </g>
  </svg>
)

export default DisabledCheckedIcon
