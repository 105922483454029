import { useState } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import './ReverseScrollingThread.less'

/**
 * In order that component to work:
 * Parent: element need to be with fixed height
 * Children: all items that has to be scrolled (not wrapped!)
 */

const ReverseScrollingThread = ({ children }) => {
  const [isTopShadowVisible, setIsTopShadowVisible] = useState(true)
  const [isBottomShadowVisible, setIsBottomShadowVisible] = useState(false)

  const handleScroll = (e) => {
    const scrollFarFromBottom = e.target.scrollTop < -50
    const scrollOnEndTop = -(e.target.scrollTop - e.target.clientHeight - 1).toFixed() - e.target.scrollHeight >= 0

    if (scrollOnEndTop) {
      setIsTopShadowVisible(false)
    } else {
      setIsTopShadowVisible(true)
    }

    if (scrollFarFromBottom) {
      setIsBottomShadowVisible(true)
    } else {
      setIsBottomShadowVisible(false)
    }
  }

  return (
    <div className='thread__wrapper'>
      {isTopShadowVisible && <div className='thread__wrapper__top-shadow' />}
      {isBottomShadowVisible && <div className='thread__wrapper__bottom-shadow' />}
      <div className='thread__wrapper__panel'>
        <div
          onScroll={handleScroll}
          className={classnames(
            'thread__wrapper__panel__holder'
          )}
        >
          <div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

ReverseScrollingThread.propTypes = {
  /**
   * Children of the Thread element. For example: messages
   */
  children: T.node.isRequired
}

export { ReverseScrollingThread }
