import SuccessIcon from '../../icons/Success'
import './SuccessMessage.less'

const SuccessMessage = ({ text }) => (
  <div className='success-message'>
    <SuccessIcon color='#39b54a' />
    {text}
  </div>
)

export default SuccessMessage
