import { useEffect, useCallback } from 'react'
import T from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import constants from '@elenfs/elen-constants'

import RecoverPasswordForm from './RecoverPasswordForm'

import useFormState from '../../../hooks/useFormState'
import { resetPassword } from '../../../auth'

const { UserTypes } = constants.user

const RecoverPasswordFormContainer = (props) => {
  const { userType } = props
  const { token } = useParams()
  const navigate = useNavigate()
  const [formState, setFormState] = useFormState()
  const { loading, error, success } = formState

  const handleRecoverPassword = useCallback(({ password, verifyPassword }) => {
    setFormState({ ...formState, loading: true })

    resetPassword(token, password, verifyPassword, userType)
      .then(() => setFormState({ ...formState, success: true, loading: false }))
      .catch(error => setFormState({ ...formState, error, loading: false }))
  }, [setFormState, formState, resetPassword])

  useEffect(() => {
    if (!token) {
      navigate(userType === UserTypes.CLIENT ? '/clients' : '/')
    }
  }, [navigate, token, userType])

  useEffect(() => {
    if (success) {
      const successRedirectionPath = userType === UserTypes.CLIENT
        ? '/clients/reset-password/success'
        : '/reset-password/success'
      navigate(successRedirectionPath)
    }
  }, [navigate, success, userType])

  return (
    <RecoverPasswordForm
      {...{
        loading,
        error,
        onSubmit: handleRecoverPassword
      }}
    />
  )
}

RecoverPasswordFormContainer.defaultProps = {
  userType: UserTypes.COMPANY_USER
}

RecoverPasswordFormContainer.propTypes = {
  userType: T.oneOf(Object.values(UserTypes))
}

export default RecoverPasswordFormContainer
