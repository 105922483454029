import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import T from 'prop-types'
import { compose, isEmpty } from 'ramda'
import { connect, withFormik } from 'formik'

import constants from '@elenfs/elen-constants'

import {
  Button,
  SuccessMessage,
  LoadingMessage,
  RouteLeavingGuard,
  Tooltip,
  PageContent
} from '../../misc'
import { FormErrorMessages } from '../../form'
import { RemovePolicyButton } from './RemovePolicyButton'

import { buildInitialValues } from '../../../helpers/form'
import { parseGraphqlErrors } from '../../../helpers/error'
import { Form } from '../../form/Form'

import './PolicyForm.less'

const DeletionTooltip = props => (
  <Tooltip.Info className='policy-form__deletion-tooltip' {...props} />
)

const {
  DEBTS,
  INVESTMENT,
  MORTGAGES,
  PENSIONS,
  PROTECTION
} = constants.policy.common.PolicyCategories

const policyTypeFieldNames = {
  [DEBTS]: 'debtType',
  [INVESTMENT]: 'investmentType',
  [MORTGAGES]: 'mortgageType',
  [PENSIONS]: 'pensionType',
  [PROTECTION]: 'protectionType'
}

const PolicyForm = props => {
  const {
    policy,
    hasReconciledCharges,
    loading,
    data,
    PolicyDetailsForm,
    errors: validationErrors,
    resetForm,
    formik: { dirty, submitForm, validateForm, values, setFieldError },
    currentClient,
    companyClients,
    isSubmitting
  } = props

  const navigate = useNavigate()
  const { clientId } = useParams()

  const { status, number, holder, category } = values
  const typeFieldName = policyTypeFieldNames[category]
  useEffect(() => setFieldError('status', ''), [status])
  useEffect(() => setFieldError(typeFieldName, ''), [values[typeFieldName]])
  useEffect(() => setFieldError('number', ''), [number])
  useEffect(() => setFieldError('holder', ''), [holder])

  const success = !loading && data
  const isEdit = policy && policy.id
  const redirectHandler = useCallback(
    policyID => {
      const policiesLink = `/secure/clients/${clientId}/policies`
      const policyDetailsLink = `${policiesLink}/${policyID}/details`
      navigate(policyID ? policyDetailsLink : policiesLink, { replace: true })
    },
    [navigate]
  )

  const validateAndSubmit = useCallback(
    async fn => {
      const errors = await validateForm()
      if (!isEmpty(errors)) {
        return
      }

      const result = await submitForm()

      if (!result.graphQLErrors) {
        const resultPolicyKey = result?.data && Object.keys(result.data)[0]
        const policy = resultPolicyKey && result.data[resultPolicyKey]
        // resetForm to clear dirty flag, and not execute Confirmation Modal
        await resetForm()
        fn && fn(policy?.id)
      }
    },
    [validateForm, submitForm, resetForm]
  )

  const saveAndRedirect = useCallback(
    () => validateAndSubmit(redirectHandler),
    [validateAndSubmit, redirectHandler]
  )

  const saveAndReset = useCallback(() => validateAndSubmit(), [
    validateAndSubmit
  ])

  const isRouteGuarded = useMemo(
    () => !isSubmitting && dirty, [dirty, isSubmitting])

  return (
    <div>
      <PageContent withMaxWidth>
        <Form
          error={!isEmpty(validationErrors) ? validationErrors : undefined}
          className='policy-form'
        >
          <PolicyDetailsForm
            policy={policy}
            currentClient={currentClient}
            companyClients={companyClients}
          />

          <RouteLeavingGuard enabled={isRouteGuarded} />

          {loading && <LoadingMessage />}

          <FormErrorMessages
            validationErrors={validationErrors}
          />

          {!loading && success &&
            <SuccessMessage text='Successfully saved' />}

        </Form>
        <Form.ButtonGroup>
          <Button color={Button.Colors.BLUE} onClick={saveAndRedirect}>
            Save
          </Button>

          {!(isEdit) && (
            <Button color={Button.Colors.GREEN} onClick={saveAndReset}>
              Save and New
            </Button>)}

          {isEdit && (
            <Tooltip.Trigger
              as={RemovePolicyButton}
              policy={policy}
              policyId={policy.id}
              disabled={hasReconciledCharges}
              tooltipId='deletion-disabled'
              tooltip={DeletionTooltip}
              tooltipProps={{
                events: hasReconciledCharges
                  ? { hover: true, click: true }
                  : false,
                text: 'There are reconciled payments associated with this policy. Deletion is not possible.'
              }}
            />
          )}

          {dirty && (
            <Button color={Button.Colors.GREY} onClick={resetForm}>
              Cancel
            </Button>
          )}
        </Form.ButtonGroup>
      </PageContent>
    </div>
  )
}

PolicyForm.propTypes = {
  formik: T.shape({
    values: T.object.isRequired
  }).isRequired
}

const PolicyFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ({ policySchema }) => policySchema,

    mapPropsToValues: ({ policy, policySchema, currentClient }) => {
      // sort holders, current one should be always first one
      const sortedHolders = currentClient && [currentClient.id].concat(
        policy.currentHoldersIds.filter(
          holder => !(holder === currentClient.id)
        )
      )

      return buildInitialValues(policySchema, {
        ...policy,
        currentHoldersIds: sortedHolders
      })
    },

    handleSubmit: async (values, { props, setErrors }) => {
      const { policy, mutate, policySchema } = props
      values = policySchema.cast(values)

      if (policy && policy.id) {
        values = { ...values, id: policy.id }
      }

      delete values.holder

      const result = await mutate({ variables: { input: values } })

      if (result.graphQLErrors) {
        setErrors(
          parseGraphqlErrors(result, 'There was an error saving the policy')
        )
      }

      return result
    }
  }),
  connect
)(PolicyForm)

export default PolicyFormEnhanced
