import PropTypes from 'prop-types'

const Nav = ({ className, items, itemComponent: Item }) => {
  if (!items || !items.length) {
    return null
  }

  return (
    <nav className={className}>
      {items.map(({ path, ...item }) => (
        <Item
          key={path}
          path={path}
          {...item}
        />
      ))}
    </nav>
  )
}

Nav.propTypes = {
  className: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired
    })
  ),
  itemComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ])
}

export default Nav
