const ShapeHorizontal = props => (
  <svg width={507} height={220} viewBox='0 0 507 220' {...props}>
    <g stroke='currentColor' fill='none' fillRule='evenodd'>
      <path d='M8.953 258c-7.184-22.782-12.428-47.608-2.382-69.56 13.16-28.758 49.68-44.863 85.052-48.553 35.371-3.69 70.986 2.409 106.51 4.755 35.524 2.347 73.57.316 102.85-17.316 40.607-24.454 53.935-72.106 90.575-100.902C422.11 2.413 468.23-5.505 507 6.607' />
      <path d='M35.528 258c-6.8-21.54-11.764-45.015-2.255-65.769 12.46-27.193 47.029-42.42 80.513-45.91 33.484-3.49 67.2 2.277 100.827 4.497 33.628 2.218 69.646.298 97.361-16.373 38.442-23.122 51.058-68.179 85.743-95.407C426.64 16.336 470.298 8.85 507 20.301' />
      <path d='M62.105 258c-6.419-20.212-11.103-42.236-2.129-61.709 11.757-25.514 44.377-39.802 75.975-43.076 31.597-3.274 63.411 2.137 95.144 4.218 31.733 2.082 65.718.281 91.873-15.361 36.274-21.695 48.18-63.97 80.91-89.517C431.17 31.253 472.369 24.23 507 34.975' />
      <path d='M89.664 258c-6.02-19.058-10.414-39.828-1.997-58.191 11.03-24.06 41.63-37.532 71.268-40.62 29.64-3.087 59.485 2.016 89.251 3.979 29.767 1.963 61.648.264 86.182-14.487 34.027-20.457 45.194-60.322 75.898-84.412C435.868 44.18 474.513 37.559 507 47.69' />
      <path d='M116.24 258c-5.638-17.907-9.75-37.42-1.87-54.673 10.327-22.604 38.978-35.262 66.73-38.163 27.753-2.9 55.697 1.893 83.567 3.738 27.872 1.844 57.722.248 80.695-13.61 31.859-19.222 42.315-56.676 71.064-79.31C440.396 57.11 476.582 50.889 507 60.408' />
    </g>
  </svg>
)

export { ShapeHorizontal }
