import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'

import { ChevronLeftIcon, CloseModal } from '../../icons'

import './GoBackHeader.less'

const GoBackHeader = ({ title, to, className }) => {
  const navigate = useNavigate()

  const onBackClick = useCallback(() => {
    if (to) {
      navigate(to)
    } else {
      navigate(-1)
    }
  }, [to])

  return (
    <div className={classnames('go-back-header', className)}>
      <div className='go-back-header__action' onClick={onBackClick}>
        <ChevronLeftIcon />
        <h2 className='go-back-header__action__title'>{title}</h2>
      </div>
      <CloseModal onClick={onBackClick} />
    </div>
  )
}

export { GoBackHeader }
