import { useCallback, useState } from 'react'
import { withFormik } from 'formik'
import T from 'prop-types'

import { Form, FormErrorMessages } from '../../form'
import { FileInput } from '../../form/inputs/FileInput'
import { Button, LoadingMessage } from '../../misc'

import { parseGraphqlErrors } from '../../../helpers/error'

const fineInputName = 'file'

const CSVImportForm = ({ onSubmit, resetForm, setErrors, loading, errors: validationErrors }) => {
  const [file, setFile] = useState()

  const onChange = useCallback(file => setFile(file))

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      try {
        await onSubmit(file)
        resetForm()
        setFile(null)
      } catch (err) {
        setErrors(parseGraphqlErrors(err, 'You need to fix the file and upload it again.'))
      }
    },
    [onSubmit, setErrors, resetForm, file]
  )

  return (
    <Form className='csv-import-card__form' onSubmit={handleSubmit}>
      <FileInput name={fineInputName} label='CSV file' onChange={onChange} value={file?.name || ''} accept='.csv' />
      {loading && <LoadingMessage text='Uploading' />}
      <FormErrorMessages
        validationErrors={validationErrors}
      />
      <Button
        color={Button.Colors.BLUE}
        type='submit'
        className='csv-import-card__form__submit-btn'
        disabled={!file}
      >
        Import data
      </Button>
    </Form>
  )
}

CSVImportForm.propTypes = {
  onSubmit: T.func.isRequired
}

const EnhancedCSVImportForm = withFormik({})(CSVImportForm)

export { EnhancedCSVImportForm as CSVImportForm }
