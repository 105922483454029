import PropTypes from 'prop-types'
import classnames from 'classnames'

import './FormField.less'

const FormField = ({ children, className, error, ...rest }) => (
  <div
    className={classnames('form-new__field', {
      'form-new__field--error': error,
      [className]: className
    })}
    {...rest}
  >
    {children}
  </div>
)

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  error: PropTypes.string
}

export { FormField }
