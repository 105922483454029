import T from 'prop-types'

import Heading from '../../../misc/Heading'

import './TokenError.less'

export const TokenError = ({ message }) => (
  <div className='token-error'>
    <Heading as='h2'>
      {message}
    </Heading>
  </div>
)

TokenError.defaultProps = {
  message: 'Link expired or already used, please contact your financial adviser'
}

TokenError.propTypes = {
  message: T.string
}
