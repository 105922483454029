import { DashboardCardTitle } from '../DashboardCardTitle'
import { DashboardTabsCard } from '../DashboardTabsCard'

import './AssetsUnderManagementCard.less'

const AssetsUnderManagementCard = ({ tabs }) => (
  <div className='aum-dashboard-card'>
    <DashboardCardTitle> Assets under management </DashboardCardTitle>
    <DashboardTabsCard tabs={tabs} />
  </div>
)

export { AssetsUnderManagementCard }
