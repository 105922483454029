import { Button } from '../../misc'
import { PlusIcon } from '../../icons'
import { EmptyWorkflowsPage } from '../EmptyWorkflowsPage'
import { WorkflowTemplatesTable } from '../WorkflowTemplatesTable'

import './WorkflowsPage.less'

const WorkflowsPage = props => {
  const { workflowTemplatesTableProps, onNewWorkflowButtonClick } = props
  const { workflowTemplates } = workflowTemplatesTableProps
  const isEmpty = !(workflowTemplates && workflowTemplates.length)

  return (
    <div className='workflows-page'>
      <div>
        {!isEmpty && (
          <>
            <Button
              color={Button.Colors.BLUE}
              onClick={onNewWorkflowButtonClick}
              className='workflows-page__new-workflow-button'
            >
              <PlusIcon /> <span>New Workflow </span>
            </Button>

            <WorkflowTemplatesTable {...workflowTemplatesTableProps} />
          </>
        )}
        <EmptyWorkflowsPage hidden={!isEmpty} />
      </div>
    </div>
  )
}

export { WorkflowsPage }
