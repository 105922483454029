import { formatMoney } from './../../../helpers/policy'
import { formatDate } from './../../../helpers/date'

// ASSETS TABLE
const AssetsTableHeaders = Object.freeze({
  OWNER: 'Owner',
  ASSET_DESCRIPTION: 'Asset description',
  VALUE: 'Value'
})

const assetsTableHeadersCaptions = [
  { caption: AssetsTableHeaders.OWNER },
  { caption: AssetsTableHeaders.ASSET_DESCRIPTION },
  { caption: AssetsTableHeaders.VALUE }
]

const assetsTableRowCells = [
  { propPath: ['owner'], dataLabel: AssetsTableHeaders.OWNER },
  {
    propPath: ['description'],
    dataLabel: AssetsTableHeaders.ASSET_DESCRIPTION
  },
  {
    propPath: ['value'],
    formatFunc: formatMoney,
    dataLabel: AssetsTableHeaders.VALUE
  }
]

const assetsTableRowTotalCells = [
  { emptyCell: true },
  {
    propPath: ['value'],
    dataLabel: AssetsTableHeaders.VALUE
  }
]

export const assetsCardTableProps = {
  tableHeadersCaptions: assetsTableHeadersCaptions,
  tableRowCells: assetsTableRowCells,
  tableRowTotalCells: assetsTableRowTotalCells
}

// LIABILITIES TABLE
const LiabilitiesTableHeaders = Object.freeze({
  OWNER: 'Owner',
  LIABILITY_TYPE: 'Liability type',
  OUTSTANDING_VALUE: 'Outstanding value',
  MONTHLY_COST: 'Monthly cost',
  START_DATE: 'Start date',
  END_DATE: 'End date'
})

const liabilitiesTableHeadersCaptions = [
  { caption: LiabilitiesTableHeaders.OWNER },
  { caption: LiabilitiesTableHeaders.LIABILITY_TYPE },
  { caption: LiabilitiesTableHeaders.OUTSTANDING_VALUE },
  { caption: LiabilitiesTableHeaders.MONTHLY_COST },
  { caption: LiabilitiesTableHeaders.START_DATE },
  { caption: LiabilitiesTableHeaders.END_DATE }
]

const liabilitiesTableRowCells = [
  { propPath: ['owner'], dataLabel: LiabilitiesTableHeaders.OWNER },
  {
    propPath: ['liabilityType'],
    dataLabel: LiabilitiesTableHeaders.LIABILITY_TYPE
  },
  {
    propPath: ['outstandingValue'],
    formatFunc: formatMoney,
    dataLabel: LiabilitiesTableHeaders.OUTSTANDING_VALUE
  },
  {
    propPath: ['monthlyCost'],
    formatFunc: formatMoney,
    dataLabel: LiabilitiesTableHeaders.MONTHLY_COST
  },
  {
    propPath: ['startDate'],
    formatFunc: formatDate,
    dataLabel: LiabilitiesTableHeaders.START_DATE
  },
  {
    propPath: ['endDate'],
    formatFunc: formatDate,
    dataLabel: LiabilitiesTableHeaders.END_DATE
  }
]

const liabilitiesTableRowTotalCells = [
  { emptyCell: true },
  {
    propPath: ['outstandingValue'],
    dataLabel: 'Total outstanding:'
  },
  {
    propPath: ['monthlyCost'],
    dataLabel: 'Total monthly:'
  },
  { emptyCell: true },
  { emptyCell: true }
]

export const liabilitiesCardTableProps = {
  tableHeadersCaptions: liabilitiesTableHeadersCaptions,
  tableRowCells: liabilitiesTableRowCells,
  tableRowTotalCells: liabilitiesTableRowTotalCells
}
