import { useNavigate } from 'react-router-dom'

import { Tabs } from '../../misc'
import {
  ReportIcon,
  UsersIcon,
  CakeIcon,
  WithdrawalIcon,
  ContributionIcon,
  PoliciesIcon,
  ChartIcon,
  ReviewIcon
} from '../../icons'

import { ReportCategoryLabels } from './const'

import './ReportsPage.less'

const { REGULATORY, CLIENT_MANAGEMENT, SALES } = ReportCategoryLabels

const ReportsPage = () => {
  const navigate = useNavigate()

  const urlNBR = 'nbr'
  const urlClientList = 'client-list'
  const urlClientBirthdays = 'client-birthdays'
  const urlWithdraw = 'withdraw'
  const urlContribution = 'contribution'
  const urlAUM = 'assets-under-management'
  const urlMaturingPolicies = 'maturing-policies'
  const urlUpcomingReviews = 'upcoming-reviews'

  const list = [
    {
      category: REGULATORY,
      title: 'New Business Report (NBR)',
      text: 'Your companies latest business register, with definable dates.',
      Icon: ReportIcon,
      onClick: () => navigate(urlNBR)
    },
    {
      category: CLIENT_MANAGEMENT,
      title: 'Client List',
      text: 'Easily see and export your client personal and contact details.',
      Icon: UsersIcon,
      onClick: () => navigate(urlClientList)
    },
    {
      category: CLIENT_MANAGEMENT,
      title: 'Client Birthdays',
      text: 'Upcoming client birthdays displaying their contact details.',
      Icon: CakeIcon,
      onClick: () => navigate(urlClientBirthdays)
    },
    {
      category: CLIENT_MANAGEMENT,
      title: 'Policy Withdrawals',
      text: 'Details policy withdrawals within a given period.',
      Icon: WithdrawalIcon,
      onClick: () => navigate(urlWithdraw)
    },
    {
      category: CLIENT_MANAGEMENT,
      title: 'Policy Contributions',
      text: 'Details policy contributions within a given period.',
      Icon: ContributionIcon,
      onClick: () => navigate(urlContribution)
    },
    {
      category: SALES,
      title: 'Maturing Policies',
      text: 'Details your maturing policies within a given period.',
      Icon: PoliciesIcon,
      onClick: () => navigate(urlMaturingPolicies)
    },
    {
      category: CLIENT_MANAGEMENT,
      title: 'Assets Under Management',
      text:
        'Details your Investment and Pension policy assets by provider, adviser and policy type.',
      Icon: ChartIcon,
      onClick: () => navigate(urlAUM)
    },
    {
      category: CLIENT_MANAGEMENT,
      title: 'Upcoming reviews',
      text: 'Upcoming review dates within a given period.',
      Icon: ReviewIcon,
      onClick: () => navigate(urlUpcomingReviews)
    }
  ]

  return (
    <div className='reports'>
      <Tabs list={list} allCategoriesLabel='All Reports' />
    </div>
  )
}

export { ReportsPage }
