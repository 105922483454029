import { Routes, Route, Navigate } from 'react-router-dom'

import ClientAssetsAndLiabilitiesForm from '../../client/ClientAssetsAndLiabilitiesForm'
import ClientFinancialAdvisersForm from '../../client/ClientFinancialAdvisersForm'
import ClientObjectivesAndActionsForm from '../../client/ClientObjectivesAndActionsForm'

import { CompanyDetailsForm, ProfitAndLossForm } from '../'

const CorporateClientDetailsFormSwitch = ({
  match,
  setValidationSchema,
  financialAdviserProps,
  isClientPortal
}) => {
  const {
    financialAdviserOptions,
    financialAdvisersError
  } = financialAdviserProps

  return (
    <Routes>
      <Route
        path='company-details'
        element={
          <CompanyDetailsForm
            setValidationSchema={setValidationSchema}
            nextLink='profit-and-loss'
            isClientPortal={isClientPortal}
          />
        }
      />

      <Route
        path='profit-and-loss'
        element={
          <ProfitAndLossForm
            setValidationSchema={setValidationSchema}
            nextLink='assets-and-liabilities'
            prevLink='company-details'
          />
        }
      />

      <Route
        path='assets-and-liabilities'
        element={
          <ClientAssetsAndLiabilitiesForm
            setValidationSchema={setValidationSchema}
            nextLink='objectives-and-actions'
            prevLink='income-and-expenditure'
          />
        }
      />

      <Route
        path='objectives-and-actions'
        element={
          <ClientObjectivesAndActionsForm
            setValidationSchema={setValidationSchema}
            nextLink='financial-advisers'
            prevLink='assets-and-liabilities'
          />
        }
      />

      <Route
        path='financial-advisers'
        element={
          <ClientFinancialAdvisersForm
            financialAdviserOptions={financialAdviserOptions}
            financialAdvisersError={financialAdvisersError}
            setValidationSchema={setValidationSchema}
            prevLink='objectives-and-actions'
            isClientPortal={isClientPortal}
          />
        }
      />
      <Route index element={<Navigate replace to='company-details' />} />
    </Routes>
  )
}

export { CorporateClientDetailsFormSwitch }
