import { useCallback, useState } from 'react'
import T from 'prop-types'

import { Modal, Button, LoadingMessage, ErrorMessage } from '..'

import './ConfirmationModal.less'

const ConfirmationModal = (props) => {
  const {
    isOpen,
    close,
    confirmationCallback,
    awaitCallback,
    loadingMessage,
    errorMessage: errorMessageFromProps,
    heading,
    text,
    yesButtonText,
    noButtonText,
    trigger,
    ...restProps
  } = props

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(errorMessageFromProps)
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setError(false)
    setLoading(false)
    close()
  }

  const handleCallback = async (accepted) => {
    if (awaitCallback) {
      setLoading(true)
      try {
        await confirmationCallback(accepted)
        closeModal()
      } catch (err) {
        setError(true)
        setErrorMessage(err.message)
        console.error(`[ConfirmationModal.js] - handleCallback - ${err.message}`)
      }
      setLoading(false)
    } else {
      closeModal()
      return confirmationCallback(accepted)
    }
  }

  const confirm = useCallback(() => {
    return handleCallback(true)
  })

  const deny = useCallback(() => {
    return handleCallback(false)
  })

  return (
    <Modal
      className='simple-confirmation-modal'
      contentClassName='simple-confirmation-modal__modal-content'
      isOpen={isOpen}
      close={closeModal}
      trigger={trigger}
      {...restProps}
    >
      <div className='simple-confirmation-modal__content'>
        <Modal.Heading className='simple-confirmation-modal__content__header'>{heading}</Modal.Heading>
        <div className='simple-confirmation-modal__content__text'>
          {text}
        </div>
        <div className='simple-confirmation-modal__content__messages'>
          {loading && <LoadingMessage text={loadingMessage} />}
          {!loading && error && <ErrorMessage text={errorMessage} />}
        </div>
        <div className='simple-confirmation-modal__content__buttons'>
          <Button
            className='simple-confirmation-modal__content__buttons__button'
            color={Button.Colors.GREY}
            onClick={deny}
          >
            {noButtonText}
          </Button>
          <Button
            className='simple-confirmation-modal__content__buttons__button'
            color={Button.Colors.BLUE}
            onClick={confirm}
            disabled={loading}
          >
            {yesButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  isOpen: T.bool,
  close: T.func.isRequired,
  confirmationCallback: T.func.isRequired,
  awaitCallback: T.bool,
  loadingMessage: T.string,
  errorMessage: T.string,
  heading: T.string.isRequired,
  text: T.oneOfType([T.string, T.object]),
  yesButtonText: T.string,
  noButtonText: T.string,
  trigger: T.node
}

ConfirmationModal.defaultProps = {
  yesButtonText: 'Yes',
  noButtonText: 'No',
  awaitCallback: false,
  loadingMessage: 'Saving...',
  errorMessage: 'Something went wrong. Please try again'
}

export default ConfirmationModal
