import constants from '@elenfs/elen-constants'
import { isLogEntryOfType, isWarningLogEntry, isErrorLogEntry } from '../../../../helpers/dataImport'

const {
  DataImportJobStatuses: {
    SUCCESS
  },
  DataImportProcessingEvents: {
    CLIENT_CREATED,
    POLICY_CREATED,
    POLICY_UPDATED,
    POLICY_TRANSACTION_CREATED
  }
} = constants.dataImport

export const getJobStats = ({ job, logs }) => {
  const stats = {
    additions: 0,
    changes: 0,
    warnings: 0,
    errors: 0
  }

  if (job?.status !== SUCCESS && (!logs?.length)) {
    stats.errors += 1
    return stats
  }

  const allLogEntries = logs?.reduce((acc, curr) => {
    return [...acc, ...curr.logEntries]
  }, [])

  allLogEntries?.length && allLogEntries.forEach((entry) => {
    if (isLogEntryOfType([CLIENT_CREATED, POLICY_CREATED, POLICY_TRANSACTION_CREATED], entry)) {
      stats.additions += 1
    } else if (isLogEntryOfType(POLICY_UPDATED, entry)) {
      stats.changes += 1
    } else if (isWarningLogEntry(entry)) {
      stats.warnings += 1
    } else if (isErrorLogEntry(entry)) {
      stats.errors += 1
    }
  })

  return stats
}

export const getJobInfo = ({ job, stats }) => {
  const isSuccess = job?.status === SUCCESS
  const isPartiallyFailed = !isSuccess && (stats?.additions || stats?.changes)

  let indicatorColor = ''
  let statusText = ''

  if (isSuccess) {
    indicatorColor = 'green'
    statusText = 'Import successful'
  } else if (isPartiallyFailed) {
    indicatorColor = 'yellow'
    statusText = 'Import partially failed'
  } else {
    indicatorColor = 'red'
    statusText = 'Import failed'
  }

  return { indicatorColor, statusText }
}
