import { useEffect } from 'react'
import { withFormik, connect } from 'formik'
import { compose } from 'ramda'
import moment from 'moment'
import T from 'prop-types'

import { optionsType } from '../../../../../helpers/propTypes'
import { SelectField, DateField } from '../../../../form/fields'
import { Button, DownloadReportBtn } from '../../../../misc'

import './PolicyValuationFilter.less'

const PolicyValuationFilter = ({
  filterDateRangeOptions,
  filterDateRanges,
  values,
  downloadReportProps,
  formik: {
    submitForm
  }
}) => {
  const { dateRange } = values
  const isShow = dateRange === filterDateRanges?.CUSTOM

  useEffect(() => {
    if (values?.dateRange) submitForm()
  }, [values, submitForm])

  if (!filterDateRangeOptions) {
    return null
  }

  return (
    <div className='policy-valuation__filter'>
      <form>
        <div className='policy-valuation__filter__selector-wrapper'>
          <SelectField
            name='dateRange'
            options={filterDateRangeOptions}
            inputProps={{ isClearable: false }}
          />
          <DownloadReportBtn {...{
            noLabel: true,
            color: Button.Colors.BLUE_TEXT,
            ...downloadReportProps
          }}
          />
        </div>
        {isShow && (
          <div className='policy-valuation__filter__custom-fields'>
            <DateField
              name='fromDate'
              inputProps={{ placeholder: 'From date' }}
            />

            <DateField
              name='toDate'
              inputProps={{ placeholder: 'To date' }}
            />
          </div>
        )}
      </form>
    </div>
  )
}

const EnhancedPolicyValuationFilter = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues || {},
    handleSubmit: async (values, { props }) => {
      const {
        policyId,
        onFilter,
        filterDateRanges,
        getFromToDateByDateRange,
        filterDateRangeOptions
      } = props
      const withDateFilter = !!filterDateRangeOptions?.length
      const filter = { policyId, ...values }

      if (withDateFilter) {
        const isCustomDateRange = values.dateRange === filterDateRanges?.CUSTOM

        if (!isCustomDateRange) {
          const { fromDate, toDate } = getFromToDateByDateRange(
            filter.dateRange
          )
          filter.fromDate = fromDate
          filter.toDate = toDate
        }

        if (filter.fromDate && filter.toDate) {
          filter.fromDate = moment(filter.fromDate)
            .startOf('day')
            .toDate()
          filter.toDate = moment(filter.toDate)
            .endOf('day')
            .toDate()
        }

        delete filter.dateRange
      }

      await onFilter(filter)
    }
  }),
  connect
)(PolicyValuationFilter)

PolicyValuationFilter.propTypes = {
  filterDateRangeOptions: optionsType(),
  filterDateRanges: T.object
}

export { EnhancedPolicyValuationFilter as PolicyValuationFilter }
