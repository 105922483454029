import classNames from 'classnames'
import T from 'prop-types'

import './DashboardCardTitle.less'

const DashboardCardTitle = props => {
  const { className, children, ActionComponent, actionComponentProps } = props

  return (
    <div className={classNames('dashboard-card-title', className)}>
      <span className='dashboard-card-title__title'>{children}</span>
      <span className='dashboard-card-title__action'>
        {ActionComponent && <ActionComponent {...actionComponentProps} />}
      </span>
    </div>
  )
}

DashboardCardTitle.propTypes = {
  ActionComponent: T.elementType,
  actionComponentProps: T.object
}

export { DashboardCardTitle }
