import { useCallback, useEffect } from 'react'

import { MoneyField } from './MoneyField'
import { connectFieldToFormik } from '../ConnectFieldToFormik'
import { useCurrentUser } from '../../../../hooks'

const MoneyFieldWrapper = ({ name, value, handleChange, ...restProps }) => {
  const { currency } = useCurrentUser()

  const amount = value ? value.amount : ''
  const amountFormatted = typeof amount === 'number' ? amount.toFixed(2) : amount

  useEffect(() => {
    if (value) {
      value.currency = currency
    }
  }, [value, currency])

  return (
    <MoneyField
      name={`${name}.amount`}
      value={amountFormatted}
      handleChange={useCallback((amount) => handleChange({ ...value, amount }), [handleChange, value])}
      {...restProps}
    />
  )
}

export const MoneyFieldFormikContainer = connectFieldToFormik(MoneyFieldWrapper)
