import { NoItems, Heading } from '../../../misc'

const NoUsers = props => (
  <NoItems>
    <Heading as='h3'>
      There are no Users yet.<br />
      Please use the <b>New User</b> button to add a new entry.
    </Heading>
  </NoItems>
)

export default NoUsers
