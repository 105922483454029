import { useState, useCallback, useMemo, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { CompanyUserPermissions } from '@elenfs/elen-constants'
import appConfig from '../../../appConfig'

import { HeaderLogo } from '../HeaderLogo'
import MenuIcon from '../../icons/Menu'
import DropdownIcon from '../../icons/Dropdown'
import SideMenuNav from './SideMenuNav'
import { PageDivider } from '../../misc/PageDivider'
import AppVersion from '../../misc/AppVersion'
import { doesCurrentUserHavePermission } from '../../hoc/container/WithPermissionCheck'
import { CookieConsentModal } from './../../misc/CookieConsentModal'
import { logout } from '../../../auth'
import { pathMatchCurrentLocation } from '../../../helpers/path'

import './MobileSideMenu.less'

const { LEGAL_DOCUMENTS } = appConfig

const getItems = (currentUser) => (
  [
    { label: 'Dashboard', path: 'dashboard' },
    { label: 'Clients', path: 'clients' },
    doesCurrentUserHavePermission(currentUser, CompanyUserPermissions.INCOME_ALL) &&
    { label: 'Income', path: 'income' },
    doesCurrentUserHavePermission(currentUser, CompanyUserPermissions.REPORTS_ALL) &&
    { label: 'Reports', path: 'reports' },
    { label: 'Tasks', path: 'tasks' },
    { label: 'Workflows', path: 'workflows' },
    { label: 'Admin', path: 'admin' }
  ].filter(Boolean)
)

const submenus = {
  // submenus goes in here which is used as e.g "clients: ClientsSubmenu"
}

const renderSubmenu = ({ submenuKey, label }, closeSubmenuCallback) => {
  const Submenu = submenus[submenuKey]

  return (
    <div className='mobile-side-menu__submenu'>
      <div className='mobile-side-menu__submenu__header' onClick={closeSubmenuCallback}>
        <DropdownIcon className='mobile-side-menu__submenu__header__icon' />
        <span>{label}</span>
      </div>
      <div className='mobile-side-menu__submenu__content'>
        <Submenu />
      </div>
    </div>
  )
}

const MobileSideMenu = ({ currentUser, logoutRedirect }) => {
  const [expanded, setExpanded] = useState(false)
  const [submenuItem, setSubmenuItem] = useState(null)

  const toggleExpanded = () => setExpanded(!expanded)

  const closeSubmenu = () => setSubmenuItem(null)

  const closeMenu = () => {
    closeSubmenu()
    setExpanded(false)
  }

  const { pathname } = useLocation()
  const navigate = useNavigate()

  // When we click on an item that takes us somewhere,
  // this callback will be called
  // and the menu will be closed.
  useEffect(() => {
    closeMenu()
  }, [pathname])

  const handleLogout = () => {
    logout()
    navigate(logoutRedirect)
  }

  const onItemClick = (event, item) => {
    if (item.submenuKey) {
      // Prevent the item from navigating
      // because we need to open the submenu.
      event.preventDefault()
      setSubmenuItem(item)
    }
  }

  const onCustomerSupportClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()

    const customerSupportPagePath = '/secure/customer-support'
    const isCustomerSupportPagePath = pathMatchCurrentLocation({
      pathToCheck: customerSupportPagePath,
      pathname
    })

    if (!isCustomerSupportPagePath) {
      navigate(customerSupportPagePath)
    } else {
      toggleExpanded()
    }
  }, [navigate, toggleExpanded])

  const accountItems = useMemo(
    () => [{ label: 'Customer support', path: 'customer-support', onClick: onCustomerSupportClick }],
    [onCustomerSupportClick]
  )

  return (
    <>
      <HeaderLogo
        className='mobile-side-menu__header-icons'
        additionalIcon={() => (
          <MenuIcon
            className='mobile-side-menu__header-icons__menu'
            onClick={toggleExpanded}
          />
        )}
      />
      <div
        className={classnames('mobile-side-menu__overlay', {
          'mobile-side-menu__overlay--expanded': expanded
        })}
        onClick={closeMenu}
      >
        <div
          className={classnames('mobile-side-menu', {
            'mobile-side-menu--expanded': expanded
          })}
          onClick={(event) => event.stopPropagation()}
        >
          {submenuItem
            ? (
                renderSubmenu(submenuItem, closeSubmenu)
              )
            : (
              <>
                <HeaderLogo onClick={closeMenu} className='mobile-side-menu__logo' />
                <SideMenuNav items={getItems(currentUser)} onItemClick={onItemClick} />

                <PageDivider />
                <SideMenuNav items={accountItems} onItemClick={onItemClick} />

                <span className='mobile-side-menu__nav__item mobile-side-menu__nav__item--log-out' onClick={handleLogout}>Log out</span>

                <CookieConsentModal.Trigger className='mobile-side-menu__nav__link'> Cookie settings </CookieConsentModal.Trigger>

                <a
                  target='_blank'
                  href={LEGAL_DOCUMENTS}
                  className='mobile-side-menu__nav__link' rel='noreferrer'
                >
                  Legal documents
                </a>

                <span className='mobile-side-menu__nav__item mobile-side-menu__nav__item--app-version'>{AppVersion.getAppVersion()}</span>
              </>
              )}
        </div>
      </div>
    </>
  )
}

export default MobileSideMenu
