import { useState, useCallback, useEffect } from 'react'
import { isEmpty } from 'ramda'

import { updateTasksRank } from './../components/tasks/TasksPage/helpers'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const useSortDND = ({ data = [], onSortDND }) => {
  const [sortedData, setSortedData] = useState(data)

  const onDragEnd = useCallback(result => {
    if (!result.destination) {
      return null
    }
    if (result.destination.index === result.source.index) {
      return null
    }

    setSortedData(current => {
      let orderedData = reorder(
        current,
        result.source.index,
        result.destination.index
      )

      if (onSortDND) {
        orderedData = updateTasksRank(orderedData, current)
        onSortDND(orderedData)
      }

      return orderedData
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      setSortedData(data)
    }
  }, [data])

  return { sortedData, onDragEnd }
}
