import classnames from 'classnames'

import './ClientFormPageWrapper.less'

const ClientFormPageWrapper = ({ children, className }) => (
  <div
    className={classnames('client-form-page-wrapper', {
      className
    })}
  >
    <div className='client-form-page-wrapper__content'>
      {children}
    </div>
  </div>
)

export { ClientFormPageWrapper }
