import PropTypes from 'prop-types'

import PageContent from '../../misc/PageContent'
import AuditLogEntriesActivityList from '../../auditLogEntry/AuditLogEntriesActivityList'
import AuditLogEntriesPageEmpty from './AuditLogEntriesPageEmpty'
import LogActivityButton from './../LogActivityButton/LogActivityButton'

import './AuditLogEntriesPage.less'

const AuditLogEntriesPage = ({ auditLogEntries, clientId, refetch }) => (
  <>
    <PageContent>
      {!!auditLogEntries.length && (
        <div className='audit-log-entries-page'>
          <div className='audit-log-entries-page-tab-form-wrapper'>
            <a className='regular-text audit-log-entries-page__form-tab active-tab audit-log-entry-page-block-1'>
              {/* Log event */}
            </a>
            <div className='audit-log-entry-page-block-2'>
              <a className='regular-text audit-log-entries-page__form-tab audit-log-entry-page-block-2__1'>
                {/* Filter list */}
              </a>
              <LogActivityButton
                showPlusIconOnMobile
                responsive
                clientId={clientId}
                className='audit-log-entries-page__log-activity-button'
              />
            </div>
          </div>
          <div className='audit-log-content'>
            <AuditLogEntriesActivityList
              auditLogEntries={auditLogEntries}
              clientId={clientId}
              refetch={refetch}
            />
          </div>
        </div>
      )}
      <AuditLogEntriesPageEmpty
        clientId={clientId}
        hidden={!!auditLogEntries.length}
      />
    </PageContent>
  </>
)

AuditLogEntriesPage.propTypes = {
  client: PropTypes.object.isRequired
}

export { AuditLogEntriesPage }
