import T from 'prop-types'
import classnames from 'classnames'

import { Tooltip } from '../Tooltip'

import './InfoTooltip.less'

/**
 * Tooltip used for displaying messages
 * such as `This entity can't be deleted`
 */
export const InfoTooltip = ({ text, className, ...restProps }) => (
  <Tooltip className={classnames('info-tooltip', className)} {...restProps}>
    {text}
  </Tooltip>
)

InfoTooltip.defaultProps = {
  delay: { hide: 1000 }
}

InfoTooltip.propTypes = {
  /**
   * Text that should be shown in the tooltip.
   */
  text: T.string.isRequired,
  /**
   * Custom class name for the tooltip.
   */
  className: T.string,
  /**
   * Delay for showing/hiding the tooltip.
   */
  delay: T.shape({
    show: T.number,
    hide: T.number
  })
}
