import { pick } from 'ramda'
import moment from 'moment'

import {
  FilterDateRangeLabels,
  FilterDateRanges
} from './../../../../constants/filterDate'
import { buildSelectOptionsWithNoSort } from './../../../form/fields/SelectField/helpers'

const filterDateRangeKeys = [
  FilterDateRanges.LAST_MONTH,
  FilterDateRanges.THIS_YEAR_TO_DATE,
  FilterDateRanges.LAST_HALF_YEAR,
  FilterDateRanges.LAST_YEAR,
  FilterDateRanges.LAST_THREE_YEARS,
  FilterDateRanges.LAST_FIVE_YEARS,
  FilterDateRanges.LAST_TEN_YEARS,
  FilterDateRanges.ALL_TIME,
  FilterDateRanges.CUSTOM
]

export const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)
const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)

export const setStartOfEndOfDay = ({ fromDate, toDate }) => ({
  fromDate: moment(fromDate)
    .startOf('day')
    .toDate(),
  toDate: moment(toDate)
    .endOf('day')
    .toDate()
})
