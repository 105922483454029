import T from 'prop-types'

import ImportError from './ImportError'
import { buildLogErrorDescription } from '../../../../helpers/dataImport'

const ErrorHeading = props => {
  const {
    clientFirstName,
    clientLastName,
    policyNumber,
    isContribution
  } = props

  return (
    <>
      <b>
        {clientFirstName} {clientLastName} - policy number {policyNumber} -
        Failed to create {isContribution ? 'contribution' : 'withdrawal'}
      </b>
    </>
  )
}

const ErrorDescription = ({ transaction, isInternalError }) => {
  if (isInternalError) return 'Internal failure'
  return buildLogErrorDescription(transaction.fields || [])
}

const TransactionsError = props => {
  const {
    clientFirstName,
    clientLastName,
    transactions,
    isContribution,
    isInternalError
  } = props

  return (
    <>
      {transactions.map((transaction, i) => (
        <ImportError
          key={i}
          heading={
            <ErrorHeading
              {...{
                clientFirstName,
                clientLastName,
                isContribution,
                policyNumber: transaction.policyNumber
              }}
            />
          }
          description={
            <ErrorDescription {...{ transaction, isInternalError }} />
          }
        />
      ))}
    </>
  )
}

TransactionsError.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  isContribution: T.bool,
  isInternalError: T.bool,
  transactions: T.array.isRequired
}

export { TransactionsError }
