import { PadlockIcon } from '../../icons'
import { WrapperScreen } from '../../misc'

const PaywallPage = () => (
  <WrapperScreen className='paywall-page'>
    <>
      <PadlockIcon className='content__icon' />
      <p className='content__text'>
        Your company account has been suspended.<br />
        Please contact your company administrator for further details.
      </p>
    </>
  </WrapperScreen>
)

export { PaywallPage }
