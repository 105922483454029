const PolicyIcon = props => (
  <svg width={42} height={42} viewBox='0 0 42 42' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h42v42H0z' />
      <path
        d='M33.059 5.25a3.696 3.696 0 013.691 3.691v1.846h-5.537V33.06a3.696 3.696 0 01-3.692 3.691H8.941a3.696 3.696 0 01-3.691-3.691v-1.846h5.599V9.864a4.62 4.62 0 014.614-4.614zM23.83 33.059H7.096c0 1.017.828 1.845 1.845 1.845h15.386a3.667 3.667 0 01-.497-1.845zm6.034-25.963H15.463a2.772 2.772 0 00-2.769 2.768v21.349h12.982v1.846c0 1.017.828 1.845 1.845 1.845a1.848 1.848 0 001.846-1.845V8.94c0-.672.182-1.302.497-1.845zM25.95 27.75v1.8h-9v-1.8h9zm0-3.6v1.8h-9v-1.8h9zm-3.6-14.4c0 .492.404.892.9.892h2.7v4.038a7.34 7.34 0 01-4.098 6.57l-.402.2-.402-.2a7.34 7.34 0 01-4.098-6.57v-4.038h2.7c.496 0 .9-.4.9-.892zm-.9 1.993a2.703 2.703 0 01-1.8.683h-.9v2.254a5.518 5.518 0 002.7 4.755 5.518 5.518 0 002.7-4.755v-2.254h-.9c-.69 0-1.322-.259-1.8-.683zm11.609-4.647a1.848 1.848 0 00-1.846 1.845h3.691a1.848 1.848 0 00-1.845-1.845z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { PolicyIcon }
