import T from 'prop-types'

import { Button, Modal } from '../../misc'
import { PlusIcon } from '../../icons'
import { PolicyTransactionDetailsModal } from './PolicyTransactionDetailsModal'
import { WITHDRAWAL, CONTRIBUTION } from '../../../constants/policy'
import { useParams } from 'react-router-dom'

const labels = {
  [WITHDRAWAL]: 'New Withdrawal',
  [CONTRIBUTION]: 'New Contribution'
}

const NewPolicyTransactionButton = ({ policyTransactionType }) => {
  const { policyId } = useParams()

  return (
    <Modal.Trigger
      as={Button}
      color={Button.Colors.BLUE}
      modal={PolicyTransactionDetailsModal}
      modalProps={{ policyTransactionType, policyId }}
    >
      <PlusIcon />
      <span>{labels[policyTransactionType]}</span>
    </Modal.Trigger>
  )
}

NewPolicyTransactionButton.propTypes = {
  policyTransactionType: T.oneOf([CONTRIBUTION, WITHDRAWAL]).isRequired
}

export { NewPolicyTransactionButton }
