import constants from '@elenfs/elen-constants'

import { ImportLogLayout } from '../ImportLogLayout'
import { gqlQueryField } from '../ImportLog'
import dataImportJobsGql from '../../../graphql/queries/dataImport/dataImportJobs.graphql'

const { DataImportTypes } = constants.dataImport

const P1LogPage = (props) => {
  return (
    <ImportLogLayout
      pageTitle='P1'
      dataImportType={DataImportTypes.P1}
      gqlQuery={dataImportJobsGql}
      gqlQueryField={gqlQueryField}
      link='/secure/admin/p1'
      {...props}
    />
  )
}

export default P1LogPage
