import PropTypes from 'prop-types'
import moment from 'moment'
import { DataImportTypes, DataImportPlatforms } from '@elenfs/elen-constants'

import Table from '../../misc/Table'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

const { TRANSACT, CSV_FILE, CASH_CALC, P1, PRAEMIUM } = DataImportTypes

const platformLinks = Object.freeze({
  [TRANSACT]: '/secure/admin/transact',
  [CSV_FILE]: '/secure/admin/csv-import',
  [CASH_CALC]: '/secure/admin/cashcalc',
  [P1]: '/secure/admin/p1',
  [PRAEMIUM]: '/secure/admin/morningstar'
})

const formatLatestUpdateDate = date =>
  date ? moment(date).format('D MMM YYYY - HH:mm') : ''

const getUpdateStatus = ({ type, isConnected }) => {
  if (!(type in DataImportPlatforms)) return ''
  return isConnected === false ? 'Disconnected' : 'Connected'
}

const getFileShareValue = dataImportType => {
  switch (dataImportType) {
    case TRANSACT:
      return 'Auto sync'
    case CASH_CALC:
      return 'Manual import'
    case CSV_FILE:
      return 'CSV import'
    case P1:
      return 'Auto sync'
    case PRAEMIUM:
      return 'Auto sync'
    default:
      return ''
  }
}

const ImportSourceRow = ({ type, latestUpdate, isConnected, tableHeaders }) => {
  const link = platformLinks[type]

  return (
    <Table.Row>
      <Table.Cell to={link} data-label={tableHeaders.SOURCE} bold>
        {DataImportPlatformLabels[type]}
      </Table.Cell>
      <Table.Cell to={link} data-label={tableHeaders.FILE_SHARE}>
        {getFileShareValue(type)}
      </Table.Cell>
      <Table.Cell to={link} data-label={tableHeaders.LAST_UPDATED}>
        {formatLatestUpdateDate(latestUpdate)}
      </Table.Cell>
      <Table.Cell to={link} data-label={tableHeaders.UPDATES}>
        {getUpdateStatus({ type, isConnected })}
      </Table.Cell>
    </Table.Row>
  )
}

ImportSourceRow.propTypes = {
  type: PropTypes.oneOf(Object.values(DataImportTypes)).isRequired,
  latestUpdate: PropTypes.string
}

export { ImportSourceRow }
