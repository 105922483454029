"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskTypes = exports.TaskStatuses = exports.ACTIVE_TASK_STATUSES = void 0;
const TaskTypes = exports.TaskTypes = Object.freeze({
  EMAIL: 'EMAIL',
  MEETING: 'MEETING',
  PHONE_CALL: 'PHONE_CALL',
  POST: 'POST',
  WORK_ON_FILE: 'WORK_ON_FILE'
});
const TaskStatuses = exports.TaskStatuses = Object.freeze({
  TO_DO: 'TO_DO',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
});
const ACTIVE_TASK_STATUSES = exports.ACTIVE_TASK_STATUSES = [TaskStatuses.TO_DO, TaskStatuses.IN_PROGRESS];