import classnames from 'classnames'
import { last } from 'ramda'

import { Modal } from '../../../misc'
import { PreviewModal } from '../../PreviewModal'

import { formatDate } from '../../../../helpers/date'
import { formatFileSize, getFileIcon } from '../../../../helpers/clientDocument'
import { isFilePdf } from './../../../../helpers/document'

const DocumentDetails = props => {
  const { fileDownloadUrl, getDownloadLink, clientDocument } = props
  const { fileName, uploadedAt, createdBy, fileSize, id } = clientDocument

  const FileIcon = getFileIcon(last(fileName.split('.')))

  return (
    <div className='client-document-row__name-cell__content'>
      <FileIcon className='client-document-row__name-cell__content__file-icon' />
      <div className='client-document-row__name-cell__content__file-details'>
        <div
          className={classnames(
            'client-document-row__name-cell__content__file-details__name',
            isFilePdf(clientDocument) &&
              'client-document-row__name-cell__content__file-details__name--action'
          )}
        >
          {isFilePdf(clientDocument)
            ? (
              <Modal.Trigger
                as='span'
                modal={PreviewModal}
                modalProps={{ fileDownloadUrl, getDownloadLink, id, fileName }}
              >
                {fileName}
              </Modal.Trigger>
              )
            : (
                fileName
              )}
        </div>
        <div className='client-document-row__name-cell__content__file-details__history'>
          <span>{formatDate(uploadedAt, 'DD MMM YYYY - HH:mm')}</span>
          <span>{createdBy.fullName}</span>
          <span>{formatFileSize(fileSize)}</span>
        </div>
      </div>
    </div>
  )
}

export { DocumentDetails }
