import * as yup from 'yup'
import { TaskStatuses, TaskTypes } from '@elenfs/elen-constants'

import { stringSchema, enumSchema } from '../helpers'

const taskListFilterSchema = yup.object().shape({
  status: yup.array().of(enumSchema(Object.values(TaskStatuses)).label('Status')),

  clientId: stringSchema().label('Client'),

  assignedTo: stringSchema()
    .label('Assignee'),

  type: enumSchema(Object.values(TaskTypes))
    .label('Activity type')
})

export { taskListFilterSchema }
