import { Spinner } from '../../misc'
import { TaskDetailsModal } from '../TaskDetailsModal'
import { TasksTable } from '../TasksTable'

const ListViewPage = props => {
  const {
    filterProps,
    tableProps,
    taskModalProps,
    openNewTaskModal,
    clientId
  } = props

  return (
    <>
      <TaskDetailsModal {...{ ...taskModalProps, clientId }} />
      {
        tableProps?.loadingTasks
          ? (
            <Spinner />
            )
          : (
            <TasksTable {...{
              openNewTaskModal,
              filterProps,
              ...tableProps
            }}
            />
            )
      }
    </>
  )
}

export { ListViewPage }
