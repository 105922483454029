import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { ErrorPage } from '../../misc'
import ClientDetailsFormPage from './ClientDetailsFormPage'
import Spinner from '../../misc/Spinner'
import ErrorMessage from '../../misc/ErrorMessage'
import clientDetailsGql from '../../../graphql/queries/client/clientDetails.graphql'
import { removeTypenameProperties } from '../../../helpers/document'
import { isCorporateClient } from '../../../helpers/client'

const ClientDetailsFormPageError = () => <ErrorMessage text='Failed to retrieve client details' />

const ClientDetailsFormPageContainer = (props) => {
  const { client: clientFromProps } = props
  const { clientId } = useParams()

  if (!clientId) {
    return (
      <ClientDetailsFormPage {...{
        isCorporate: isCorporateClient(clientFromProps),
        ...props
      }}
      />
    )
  }

  const { loading, error, data } = useQuery(clientDetailsGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  const client = data?.client

  if (loading && !data) return <Spinner />
  if (error) {
    return (
      <ErrorPage {...error}>
        <ClientDetailsFormPageError />
      </ErrorPage>
    )
  }

  return (
    <ClientDetailsFormPage {...{
      isCorporate: isCorporateClient(client),
      client: removeTypenameProperties(client),
      ...props
    }}
    />
  )
}

export { ClientDetailsFormPageContainer }
