import PropTypes from 'prop-types'

import SectionNav from '../../nav/SectionNav'
import {
  onlyAccountOwnerAccess,
  onlyAccountOwnerOrAdminAccess
} from '../../hoc/container/WithPermissionCheck'

const getItems = (currentUser) => (
  [
    onlyAccountOwnerAccess(currentUser) && { label: 'Account Overview', path: 'account-overview' },
    onlyAccountOwnerOrAdminAccess(currentUser) && { label: 'Manage Users', path: 'users' },
    { label: 'Teams', path: 'teams' },
    { label: 'Data Import', path: 'import' },
    { label: 'Email', path: 'email' },
    onlyAccountOwnerOrAdminAccess(currentUser) && { label: 'Branding', path: 'branding' },
    onlyAccountOwnerOrAdminAccess(currentUser) && { label: 'Service Levels', path: 'service-levels' }
  ].filter(Boolean)
)

const AdminNav = ({ currentUser, ...props }) => (
  <SectionNav items={getItems(currentUser)} {...props} />
)

AdminNav.propTypes = {
  currentUser: PropTypes.object.isRequired
}

export { AdminNav }
