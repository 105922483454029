export const COOKIE_TYPES = {
  STRICTLY_NECESSARY: 'strictlyNecessary',
  ANALYTICAL_PERFORMANCE: 'analyticalPerformance',
  FUNCTIONALITY: 'functionality'
}

export const COOKIE_CONSENT_KEY = '__elen_cookie_consent'

export const OAUTH = 'oauth2'

export const COOKIES_LIST = [
  {
    type: 'Strictly necessary cookies',
    description:
      'These are cookies that are required for the operation of our platform. They include, for example, cookies that enable you to log into secure areas of our platform or make use of e-billing services.',
    disabled: true,
    checked: true,
    name: COOKIE_TYPES.STRICTLY_NECESSARY
  },
  {
    type: 'Analytical / Performance cookies',
    description:
      'They allow us to recognize and count the number of platform users and to see how platform users move around our platform when they are using it. This helps us to improve the way our platform works, for example, by ensuring that platform users are finding what they are looking for easily.',
    disabled: false,
    checked: true,
    name: COOKIE_TYPES.ANALYTICAL_PERFORMANCE
  },
  {
    type: 'Functionality cookies',
    description:
      'These are used to recognize you when you return to our platform. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).',
    disabled: false,
    checked: true,
    name: COOKIE_TYPES.FUNCTIONALITY
  }
]
