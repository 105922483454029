import T from 'prop-types'
import classnames from 'classnames'

const ModalStickyFooterIndicators = ({ className, children }) => (
  <div className={classnames(
    'modal__sticky-layout__content__footer__indicators-wrapper',
    className
  )}
  >
    {children}
  </div>
)

ModalStickyFooterIndicators.propTypes = {
  children: T.node.isRequired,
  className: T.string
}

export { ModalStickyFooterIndicators }
