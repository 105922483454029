import T from 'prop-types'

import { ConfirmationModal, Button } from '../../../../misc'

const RemovePolicyTransactionButton = ({
  removeTransactionCallback,
  policyTransactionType,
  className
}) => (
  <ConfirmationModal.Trigger
    as={Button}
    color={Button.Colors.RED_TEXT}
    className={className}
    modalProps={{
      confirmationCallback: removeTransactionCallback,
      heading: `Delete ${policyTransactionType}`,
      text: `Are you sure you want to delete ${policyTransactionType}?`,
      yesButtonText: 'Delete',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
  >
    Delete
  </ConfirmationModal.Trigger>
)

RemovePolicyTransactionButton.propTypes = {
  removeTransactionCallback: T.func.isRequired
}

export { RemovePolicyTransactionButton }
