import T from 'prop-types'
import { isEmpty } from 'ramda'

import { Table } from '../../misc'

import { ChargesHeader } from './ChargesHeader'
import { TableResponsiveWrapper } from '../../misc/TableResponsiveWrapper'
import { sortListByProp } from '../../../helpers/sort'
import { ChargesTableRow } from './ChargesTableRow'
import { ChargesTableHeader } from './ChargesTableHeader'

const ChargesTable = ({ charges, chargeType, isPolicyCharges }) => (
  <div>
    <ChargesHeader chargeType={chargeType} isPolicyCharges={isPolicyCharges} />
    {
      !isEmpty(charges) &&
        <TableResponsiveWrapper>
          <Table>
            <ChargesTableHeader chargeType={chargeType} isPolicyCharges={isPolicyCharges} />
            <Table.Body>
              {sortListByProp(charges, 'startDate', 'DESC').map((charge) => (
                <ChargesTableRow
                  key={charge.id}
                  charge={charge}
                  chargeType={chargeType}
                  isPolicyCharges={isPolicyCharges}
                />
              ))}
            </Table.Body>
          </Table>
        </TableResponsiveWrapper>
    }
  </div>
)

ChargesTable.propTypes = {
  charges: T.array.isRequired,
  chargeType: T.string.isRequired
}

export { ChargesTable }
