import { FieldArray, connect } from 'formik'
import {
  ClientExpenseFrequencies,
  ClientExpenseFrequencyLabels,
  ClientExpensePriorities,
  ClientExpensePriorityLabels
} from '@elenfs/elen-constants'

import AddNewFormRowButton from '../../../misc/AddNewFormRowButton'
import ExpenditureFormInputRow from './ExpenditureFormInputRow'
import { FieldArrayItem } from '../../../form'
import { TextAreaField } from '../../../form/fields'

import { buildSelectOptions } from './../../../form/fields/SelectField/helpers'

const frequencyOptions = buildSelectOptions(
  ClientExpenseFrequencies,
  ClientExpenseFrequencyLabels
)
const priorityOptions = buildSelectOptions(
  ClientExpensePriorities,
  ClientExpensePriorityLabels
)

const buildExpenditureRowInput = () => ({
  expense: '',
  amount: { amount: '' },
  frequency: ClientExpenseFrequencies.MONTHLY,
  priority: ClientExpensePriorities.ESSENTIAL
})

const ClientExpenditureForm = ({ formik }) => {
  const {
    values: { expenses = [] }
  } = formik

  return (
    <div className='client-expenditure-form'>
      <FieldArray
        name='expenses'
        validateOnChange={false}
        render={arrayHelpers => (
          <div>
            {expenses.map((expense, index) => (
              <FieldArrayItem
                key={index}
                index={index}
                label='Expense'
                onRemove={() => arrayHelpers.remove(index)}
              >
                <ExpenditureFormInputRow
                  index={index}
                  expense={expense}
                  frequencyOptions={frequencyOptions}
                  priorityOptions={priorityOptions}
                />
              </FieldArrayItem>
            ))}
            <AddNewFormRowButton
              label='add expense'
              onClick={() => arrayHelpers.push(buildExpenditureRowInput())}
            />
          </div>
        )}
      />
      <TextAreaField
        name='expenditureNotesAndComments'
        label='Additional notes and comments'
        inputProps={{ rows: 3 }}
      />
    </div>
  )
}

export default connect(ClientExpenditureForm)
