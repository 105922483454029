import { propEq } from 'ramda'
import T from 'prop-types'
import { CompanyUserRoles } from '@elenfs/elen-constants'

import { InitialsAvatar } from '../../misc'
import { PencilIcon } from './../../icons'
import { TeamModalTrigger } from '../TeamModal'

import './TeamCard.less'

const {
  COMPLIANCE,
  OFFICE_ADMINISTRATOR,
  PARAPLANNER,
  ADVISER
} = CompanyUserRoles

const getUserByRole = (roles, role) => roles.find(propEq('role', role))?.user

const TeamCard = ({ team, currentUser }) => {
  const { roles } = team

  const userFA = getUserByRole(roles, ADVISER)
  const complianceFullName = getUserByRole(roles, COMPLIANCE)?.fullName
  const paraplannerFullName = getUserByRole(roles, PARAPLANNER)?.fullName
  const administratorFullName = getUserByRole(roles, OFFICE_ADMINISTRATOR)
    ?.fullName

  const canEdit = currentUser.isAdmin || userFA.id === currentUser.id

  return (
    <div className='team-card'>
      <div className='team-card__header'>
        <InitialsAvatar size={InitialsAvatar.SIZES.LG} user={userFA} />

        <div className='team-card__header__name-and-role'>
          <span> {userFA.fullName} </span>
          <span> Financial Adviser </span>
        </div>

        <div className='team-card__header__edit'>
          {canEdit && (
            <TeamModalTrigger as={PencilIcon} modalProps={{ team, userFA }} />
          )}
        </div>
      </div>

      <div className='team-card__roles'>
        <div className='team-card__roles__role'>
          <span>Compliance: </span>
          <span>{complianceFullName}</span>
        </div>

        <div className='team-card__roles__role'>
          <span>Paraplanner: </span>
          <span>{paraplannerFullName}</span>
        </div>

        <div className='team-card__roles__role'>
          <span>Administrator: </span>
          <span>{administratorFullName}</span>
        </div>
      </div>
    </div>
  )
}

TeamCard.defaultProps = {
  team: { roles: [] }
}

TeamCard.propTypes = {
  team: T.shape({
    id: T.string.isRequired,
    roles: T.arrayOf(
      T.shape({
        role: T.string,
        userId: T.string,
        user: T.shape({
          fullName: T.string,
          type: T.string,
          id: T.string,
          firstName: T.string,
          lastName: T.string
        })
      })
    )
  }).isRequired
}

export { TeamCard }
