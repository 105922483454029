import { MenuDotsIcon } from '../../icons'
import { DropdownMenu } from './../../misc'

const navigateToUrl = url => window.open(url, '_blank')

const MobileActionsDots = ({ className, url, onZoomIn, onZoomOut }) => (
  <DropdownMenu
    className={className}
    direction={DropdownMenu.Directions.RIGHT}
    trigger={<MenuDotsIcon />}
  >
    <DropdownMenu.List>
      {url && (
        <DropdownMenu.Item onClick={() => navigateToUrl(url)}>
          Print
        </DropdownMenu.Item>
      )}
      <DropdownMenu.Item onClick={onZoomIn}>Zoom In</DropdownMenu.Item>
      <DropdownMenu.Item onClick={onZoomOut}>Zoom Out</DropdownMenu.Item>
    </DropdownMenu.List>
  </DropdownMenu>
)

export { MobileActionsDots }
