import classnames from 'classnames'
import { components } from 'react-select'

import { UserIcon } from '../../../icons'

import './DropdownCustom.less'

const iconClass = 'dropdown-selection__control__unassigned-icon-wrapper'

const ControlWithUserIcon = ({
  children,
  className,
  cx,
  getStyles,
  getValue,
  innerProps,
  selectProps,
  ...rest
}) => (
  <components.Control
    {...{
      cx,
      getStyles,
      getValue,
      innerProps,
      css: getStyles('control', rest),
      className: classnames(
        'dropdown-selection__control--with-icon',
        cx(
          {
            option: true
          },
          className
        )
      ),
      ...rest
    }}
  >
    <div
      className={classnames(iconClass, {
        [`${iconClass}--active`]: !!getValue()?.[0]?.[selectProps?.asyncProps?.valueProp || 'value']
      })}
    >
      <UserIcon />
    </div>
    {children}
  </components.Control>
)

export { ControlWithUserIcon }
