const Launcher = (props) => (
  <svg width={1024} height={1024} viewBox='0 0 1024 1024' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h1024v1024H0z' />
      <path d='M0 0h1024v1024H0z' />
      <path
        d='M404.5 212h273c32.309 0 58.5 26.863 58.5 60s-26.191 60-58.5 60h-273c-32.309 0-58.5-26.863-58.5-60s26.191-60 58.5-60z'
        fill='#F86666'
      />
      <path
        d='M254.5 452h273c32.309 0 58.5 26.863 58.5 60s-26.191 60-58.5 60h-273c-32.309 0-58.5-26.863-58.5-60s26.191-60 58.5-60z'
        fill='#4D70FF'
        fillRule='nonzero'
      />
      <path
        d='M404.5 692h273c32.309 0 58.5 26.863 58.5 60s-26.191 60-58.5 60h-273c-32.309 0-58.5-26.863-58.5-60s26.191-60 58.5-60z'
        fill='#FFCF63'
      />
    </g>
  </svg>
)

export { Launcher }
