import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { Heading } from '../../../misc'
import { ClientDetailsPage } from '../../ClientDetailsPage'
import { ClientPortalSectionNav } from '../ClientPortalSectionNav'
import { ClientPortalMessagesPage } from '../../ClientMessagesPage'
import { ClientPortalMessageInput } from '../../ClientMessagesPage/MessagesComponents'
import { pathMatchCurrentLocation } from '../../../../helpers/path'
import { ClientDocumentsRouteSwitch } from '../../ClientDocumentsRouteSwitch'
import { ClientDashboardPage } from '../../../dashboard/ClientDashboardPage'
import { ClientDetailsFormPage } from '../../ClientDetailsFormPage'
import { PoliciesPage } from '../../../policy/PoliciesPage'

import './ClientPortalOverview.less'

const isClientPortal = true

const ClientPortalOverview = props => {
  const { client, refetchClientDetails } = props
  const { pathname } = useLocation()

  const messagesPath = '/clients/secure/overview/messages'
  const policiesInfoPath = '/clients/secure/overview/policies/:policyId/*'
  const editFactFindPath = '/clients/secure/overview/fact-find/edit/*'
  const isInMessagesPath = !!pathMatchCurrentLocation({ pathToCheck: messagesPath, pathname })
  const isInPoliciesInfoPath = !!pathMatchCurrentLocation({ pathToCheck: policiesInfoPath, pathname })
  const isInEditFactFindPath = !!pathMatchCurrentLocation({ pathToCheck: editFactFindPath, pathname })

  return (
    <>
      <div
        className={classnames(
          'client-portal-overview',
          isInMessagesPath && 'client-portal-overview--full-width'
        )}
      >
        {!isInEditFactFindPath && !isInPoliciesInfoPath && (
          <div className='client-portal-overview__header'>
            <Heading as='h2' bold={false}>
              {client.fullName}
            </Heading>
            <ClientPortalSectionNav />
          </div>
        )}
        <div className='client-portal-overview__content'>
          <div>
            <Routes>
              <Route
                path='dashboard'
                element={<ClientDashboardPage {... { client, ...props }} />}
              />
              {client?.isFactFindEditable &&
                <Route
                  path='fact-find/edit/*'
                  element={<ClientDetailsFormPage {... {
                    client,
                    isClientPortal
                  }}
                           />}
                />}
              <Route
                path='fact-find'
                element={(
                  <ClientDetailsPage
                    {...{
                      client,
                      isClientPortal,
                      canEdit: client?.isFactFindEditable,
                      loadClient: true,
                      refetchClientDetails,
                      ...props
                    }}
                  />
                )}
              />
              <Route
                path='policies/*'
                element={(
                  <PoliciesPage {...{
                    isClientPortal,
                    clientId: client.id,
                    ...props
                  }}
                  />
                )}
              />
              <Route
                path='documents'
                element={(
                  <ClientDocumentsRouteSwitch
                    {...{ isClientPortal, clientId: client.id, ...props }}
                  />
                )}
              />
              <Route
                path='messages'
                element={(
                  <ClientPortalMessagesPage
                    {...{ ...props, clientId: client.id, isClientUser: true }}
                  />
                )}
              />
              <Route path='/' element={<Navigate replace to='dashboard' />} />
            </Routes>
          </div>
        </div>
      </div>
      {isInMessagesPath && (
        <ClientPortalMessageInput
          clientId={client.id}
          isClientPortal
          isClientUser
          className='client-portal-message-input'
        />
      )}
    </>
  )
}

export { ClientPortalOverview }
