export const Background = Object.freeze({
  DARK: 'dark-bg',
  YELLOW: 'yellow-bg'
})

export const CloseIconColor = Object.freeze({
  BLUE: 'blue'
})

export const Size = Object.freeze({
  SMALL: 'small'
})

export const TextAlign = Object.freeze({
  CENTER: 'text-center'
})
