import { useCallback, useState } from 'react'
import { useMutation, useApolloClient, useQuery } from '@apollo/client'

import updateCompanyLogoGql from '../../../graphql/mutations/company/updateCompanyLogo.graphql'
import removeCompanyLogoGql from '../../../graphql/mutations/company/removeCompanyLogo.graphql'
import companyLogoGql from '../../../graphql/queries/user/companyLogo.graphql'

import { withCurrentUser } from '../../hoc/container'
import { BrandingForm } from './BrandingForm'
import appConfig from '../../../appConfig'

const { BRANDING_KNOWLEDGE_BASE } = appConfig

const generateRequestOptions = (body) => ({
  method: 'POST',
  body
})

const BrandingFormContainer = ({ currentUser }) => {
  const [logoInfo, setLogoInfo] = useState(null)
  const [file, setFile] = useState()
  const [fetchLoading, setFetchLoading] = useState(false)
  const { cache } = useApolloClient()

  const { data, loading } = useQuery(companyLogoGql, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5 * 1000 // 5 seconds
  })

  const logoUrl = data?.companyLogo?.logoUrl
  const logoFileSlug = data?.companyLogo?.logoFileSlug

  const [updateCompanyLogo, { error: fileCreateError }] = useMutation(updateCompanyLogoGql, {
    onCompleted: ({ updateCompanyLogo }) => setLogoInfo({ ...updateCompanyLogo })
  })

  const [removeCompanyLogo, { loading: fileRemoveLoading }] = useMutation(removeCompanyLogoGql, {
    onCompleted: ({ removeCompanyLogo }) => {
      const isSuccessful = removeCompanyLogo.success
      if (isSuccessful) {
        cache.writeQuery({
          query: companyLogoGql,
          data: {
            companyLogo: null
          }
        })
      }
    }
  })

  const onBrandingSubmit = useCallback(async () => {
    if (logoInfo && file) {
      const { url, fields } = logoInfo
      const form = new window.FormData()
      Object.keys(fields).forEach(key => form.append(key, fields[key]))
      form.append('file', file)

      setFetchLoading(true)
      const response = await window.fetch(url, generateRequestOptions(form))

      if (response.ok) {
        if (logoUrl) {
          cache.writeQuery({
            query: companyLogoGql,
            data: {
              companyLogo: {
                logoUrl,
                logoFileSlug
              }
            }
          })
        }
        setFetchLoading(false)
      }
    }
  }, [logoInfo, file])

  const onChooseFile = useCallback(async (file) => {
    if (!file || !currentUser) {
      return
    }

    await updateCompanyLogo({
      variables: {
        input: {
          companyId: currentUser?.company.id,
          fileName: file.name,
          fileSize: file.size,
          fileMimeType: file.type
        }
      }
    })

    setFile(file)
  }, [logoInfo, currentUser])

  const onRemoveFile = useCallback(async () => {
    setLogoInfo(null)
    await removeCompanyLogo({
      variables: { companyId: currentUser?.company.id }
    })
  }, [currentUser])

  const logoProps = {
    onChooseFile,
    onRemoveFile,
    description: <p>
      This is your main logo which will replace Elen’s logo. For best
      results use a high quality image. You can find more
      information in our <a href={BRANDING_KNOWLEDGE_BASE} target='_blank' rel='noreferrer'>knowledge base</a>.
    </p>, // eslint-disable-line
    recommendedSize: ['420px', '120px'],
    title: 'Logo Image',
    logo: data?.companyLogo
  }

  return (
    <BrandingForm {...{
      disableSubmit: !logoInfo || loading || fetchLoading,
      onBrandingSubmit,
      logoProps,
      fileCreateError,
      fileRemoveLoading
    }}
    />
  )
}

const BrandingFormContainerWithCurrUser = withCurrentUser()(
  BrandingFormContainer
)

export { BrandingFormContainerWithCurrUser as BrandingFormContainer }
