import { useMutation } from '@apollo/client'

import TransactImportForm from './TransactImportForm'
import useMutationState from '../../../hooks/useMutationState'
import connectTransactGql
  from '../../../graphql/mutations/dataImport/connectTransact.graphql'
import disconnectTransactGql
  from '../../../graphql/mutations/dataImport/disconnectTransact.graphql'
import transactConnectionGql
  from '../../../graphql/queries/dataImport/transactConnection.graphql'

const updateCachedTransactData = (cache, { data }) => {
  const dataToWrite = ('connectTransact' in data)
    ? data.connectTransact
    : data.disconnectTransact

  try {
    cache.writeQuery({
      query: transactConnectionGql,
      data: { dataImportTransactConnection: dataToWrite }
    })
  } catch (err) {}
}

const TransactImportFormContainer = ({ accessCode, pin, ...props }) => {
  const mutationGql = (accessCode && pin)
    ? disconnectTransactGql
    : connectTransactGql

  const [mutationFn] = useMutation(mutationGql, {
    update: updateCachedTransactData
  })

  // TODO: use mutation state provided by useMutation
  const [mutate, state] = useMutationState(mutationFn)

  return (
    <TransactImportForm
      mutate={mutate}
      accessCode={accessCode}
      pin={pin}
      {...state}
      {...props}
    />
  )
}

export default TransactImportFormContainer
