import { Route, Routes, Navigate } from 'react-router-dom'

import { AdminChildSections } from '../AdminChildSections'
import TransactImportPage from '../../import/TransactImportPage'
import { CSVImportPage } from '../../import/CSVImportPage'
import { CashCalcImportPage } from '../../import/CashCalcImportPage'
import { P1ImportPage } from '../../import/P1ImportPage'
import { PraemiumImportPage } from '../../import/PraemiumImportPage'
import TransactLogPage from '../../import/TransactLogPage'
import CSVLogPage from '../../import/CSVLogPage'
import CashCalcLogPage from '../../import/CashCalcLogPage'
import P1LogPage from '../../import/P1LogPage'
import PraemiumLogPage from '../../import/PraemiumLogPage'
import { TransactSingleLogPage } from '../../import/TransactSingleLogPage'
import { CSVSingleLogPage } from '../../import/CSVSingleLogPage'
import { P1SingleLogPage } from '../../import/P1SingleLogPage'
import { CashCalcSingleLogPage } from '../../import/CashCalcSingleLogPage'
import { PraemiumSingleLogPage } from '../../import/PraemiumSingleLogPage'

import './AdminSection.less'

const AdminSection = () => (
  <div className='admin-section'>
    <Routes>
      <Route index path='sections/*' element={<AdminChildSections />} />
      <Route path='transact' element={<TransactImportPage />} />
      <Route path='transact/logs' element={<TransactLogPage />} />
      <Route path='transact/logs/:jobId' element={<TransactSingleLogPage />} />
      <Route path='csv-import' element={<CSVImportPage />} />
      <Route path='csv-import/logs' element={<CSVLogPage />} />
      <Route path='csv-import/logs/:jobId' element={<CSVSingleLogPage />} />
      <Route path='cashcalc' element={<CashCalcImportPage />} />
      <Route path='cashcalc/logs' element={<CashCalcLogPage />} />
      <Route path='cashcalc/logs/:jobId' element={<CashCalcSingleLogPage />} />
      <Route path='p1' element={<P1ImportPage />} />
      <Route path='p1/logs' element={<P1LogPage />} />
      <Route path='p1/logs/:jobId' element={<P1SingleLogPage />} />
      <Route path='morningstar' element={<PraemiumImportPage />} />
      <Route path='morningstar/logs' element={<PraemiumLogPage />} />
      <Route path='morningstar/logs/:jobId' element={<PraemiumSingleLogPage />} />
      <Route path='/' element={<Navigate replace to='sections' />} />
    </Routes>
  </div>
)

export { AdminSection }
