import T from 'prop-types'

import { Table } from '../../../misc'
import { getTransactionHeaders } from '../../../../helpers/policy'

const PolicyTransactionsTableHeader = ({ policyTransactionType }) => {
  return (
    <Table.Header>
      <Table.Row>
        {Object.values(getTransactionHeaders(policyTransactionType)).map((item) => (
          <Table.HeaderCell key={item}>
            {item}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  )
}

PolicyTransactionsTableHeader.propTypes = {
  policyTransactionType: T.string.isRequired
}

export { PolicyTransactionsTableHeader }
