import { useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'

import { FilterIncomeEntriesForm } from '../../FilterIncomeEntriesForm'
import Spinner from './../../../misc/Spinner/Spinner'
import { ReceivedIncomeTable } from './ReceivedIncomeTable'
import { ReceivedIncomeEmpty } from '../../ReceivedIncome/ReceivedIncomeEmpty'
import { DownloadReportMenu } from '../../DownloadReportMenu'
import { Pagination } from '../../../misc'

import { formatMoney } from './../../../../helpers/policy'

import unreconcileGql from '../../../../graphql/mutations/income/unreconcile.graphql'

const ReceivedIncomeTableContainer = props => {
  const {
    loading,
    incomeRecords,
    incomeType,
    onFilter,
    currentFilter,
    called,
    incomeTotal,
    incomeTotalCalled,
    incomeTotalLoading,
    pageCount,
    handlePaginationChange,
    ...otherProps
  } = props

  const [unreconcile, { loading: unreconcileLoading }] = useMutation(
    unreconcileGql
  )

  const handleUnreconcile = useCallback(
    incomeRecord =>
      unreconcile({
        variables: { input: { id: incomeRecord.id } },
        optimisticResponse: {
          unreconcileIncomeRecord: {
            ...incomeRecord,
            actualAmount: null
          }
        }
      }),
    []
  )

  const amount = useMemo(() => formatMoney(incomeTotal), [incomeTotal])

  const hasIncomeRecords = useMemo(
    () => incomeRecords && incomeRecords.length > 0,
    [incomeRecords]
  )

  return (
    <div className='received-income__wrapper'>
      <div className='received-income__sticky-content'>
        <FilterIncomeEntriesForm {...{ summary: amount, onSubmit: onFilter }} />

        <div className='received-income__dl-btn-container'>
          <DownloadReportMenu
            values={currentFilter}
            disabled={loading || !hasIncomeRecords}
            incomeType={incomeType}
          />
        </div>
      </div>

      <div className='received-income__content'>
        {loading && <Spinner />}

        {!loading && hasIncomeRecords && (
          <ReceivedIncomeTable
            {...{
              incomeRecords,
              ...otherProps,
              handleUnreconcile,
              unreconcileLoading
            }}
          />
        )}

        {!loading && !hasIncomeRecords && called && incomeTotalCalled && (
          <ReceivedIncomeEmpty text='There are no results for this search' />
        )}
      </div>

      {!incomeTotalLoading && !loading && hasIncomeRecords && pageCount > 1 && (
        <div className='received-income__pagination'>
          <Pagination pageCount={pageCount} onPageChange={handlePaginationChange} />
        </div>
      )}
    </div>
  )
}

export { ReceivedIncomeTableContainer }
