import { useEffect } from 'react'
import { isEmpty } from 'ramda'

import { Tooltip } from '../Tooltip'

import { Positions } from './../constants'

import './FromToTooltip.less'

const List = ({ list, label }) => (
  <div className='from-to-tooltip__row'>
    <div className='from-to-tooltip__row__label'> {label} </div>
    <div className='from-to-tooltip__row__list'>
      {(list && !isEmpty(list)) && list.map(({ email, name }, i) => (
        <div key={email + label + i} className='from-to-tooltip__row__list__item'>
          {name &&
            <div className='from-to-tooltip__row__list__item__name'>
              {name}
            </div>}
          <div className='from-to-tooltip__row__list__item__email'>
            {email}
            {i !== list.length - 1 && <span>,</span>}
          </div>
        </div>
      ))}
    </div>
  </div>
)

const ToolTipContent = ({
  setIsTooltipShown,
  sender,
  recipient,
  cc,
  bcc
}) => {
  useEffect(() => {
    setIsTooltipShown(true)
    return () => setIsTooltipShown(false)
  }, [])

  return (
    <>
      <List list={sender ? [sender] : null} label='From:' />
      {!isEmpty(recipient) ? <List list={recipient} label='To:' /> : null}
      {!isEmpty(cc) ? <List list={cc} label='Cc:' /> : null}
      {!isEmpty(bcc) ? <List list={bcc} label='Bcc:' /> : null}
    </>
  )
}

const FromToTooltip = props => {
  const { sender, recipient, cc, bcc, setIsTooltipShown, ...rest } = props

  return (
    <Tooltip className='from-to-tooltip' {...rest}>
      <ToolTipContent {...{ setIsTooltipShown, sender, recipient, cc, bcc }} />
    </Tooltip>
  )
}

FromToTooltip.defaultProps = {
  delay: { hide: 0, show: 0 },
  events: { click: true, hover: false },
  position: Positions.BOTTOM
}

export { FromToTooltip }
