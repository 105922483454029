import T from 'prop-types'

import { userType } from './../../../../helpers/propTypes'

import { Tooltip, ConfirmationModal, Button } from '../../../misc'

import './ClientActions.less'

const ConfirmModalText = clientName => (
  <div>
    <p>Are you sure you want to permanently delete {clientName}?</p>
    <p>All client's data will be irreversible deleted including:</p>
    <ul>
      <li>All client details including relationships with other clients</li>
      <li>Policies</li>
      <li>Documents</li>
      <li>
        All messages sent to and from the client (including message attachments)
      </li>
      <li>Fees / commissions </li>
      <li>Activity log entries </li>
      <li>Tasks</li>
    </ul>
  </div>
)

const ClientActions = props => {
  const { client, canClientBeRemoved, removeClient, isAdmin } = props

  const removeClientTooltipText = isAdmin
    ? 'This client has policies or fee/commissions. Deletion is not possible.'
    : 'This action requires admin permissions.'

  const confirmModalProps = {
    className: 'client-actions__confirm-delete-modal',
    heading: 'Delete a client',
    text: ConfirmModalText(client.fullName),
    awaitCallback: true,
    confirmationCallback: accepted =>
      accepted && removeClient({ variables: { id: client.id } })
  }

  return (
    <div className='client-actions'>
      {!canClientBeRemoved
        ? (
          <Tooltip.Trigger
            className='client-actions__tooltip-trigger'
            as={Button}
            tooltipId={`remove-client-tooltip-${client?.id}`}
            tooltip={Tooltip.Info}
            tooltipProps={{
              text: removeClientTooltipText,
              className: 'client-actions__tooltip'
            }}
          >
            Delete client
          </Tooltip.Trigger>
          )
        : (
          <ConfirmationModal.Trigger as={Button} modalProps={confirmModalProps}>
            Delete client
          </ConfirmationModal.Trigger>
          )}
    </div>
  )
}

ClientActions.propTypes = {
  client: userType().isRequired,
  canClientBeRemoved: T.bool.isRequired,
  removeClient: T.func.isRequired,
  isAdmin: T.bool.isRequired
}

export { ClientActions }
