import GroupFormFields from '../../misc/GroupedFormField'
import { DateField, MoneyField } from '../../form/fields'

const ProfitAndLossFormRow = ({ index }) => (
  <div className='profit-and-loss-form__row'>
    <GroupFormFields>
      <DateField
        required
        label='Year end date'
        name={`profitAndLoss.${index}.yearEndDate`}
        inputProps={{ placeholder: 'dd/mm/yy' }}
      />

      <MoneyField
        required
        label='Profit/Loss'
        name={`profitAndLoss.${index}.amount`}
        inputProps={{ placeholder: '0.00' }}
        positiveOnly={false}
      />
    </GroupFormFields>
  </div>
)

export { ProfitAndLossFormRow }
