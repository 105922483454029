import T from 'prop-types'
import classnames from 'classnames'

const FormButtonGroup = (props) => {
  const { children, className, ...restProps } = props

  return (
    <div className={classnames('form-new__button-group', className)} {...restProps}>
      {children}
    </div>
  )
}

FormButtonGroup.propTypes = {
  children: T.node.isRequired,
  className: T.string
}

export { FormButtonGroup }
