export const TableHeaders = Object.freeze({
  CLIENT_NAME: 'Client name',
  ADVISER: 'Adviser',
  NEW_CLIENT_IN_THIS_PERIOD: 'New client in this period',
  POLICY_NUMBER: 'Policy number',
  POLICY_TYPE: 'Policy type',
  PROVIDER: 'Provider',
  APPLICATION_DATE: 'Application date',
  START_DATE: 'Start date',
  REPLACEMENT_BUSINESS: 'Replacement business',
  LUMP_SUM_BENEFIT_ON_CRITICAL_ILLNESS: 'Lump sum benefit on critical illness',
  LUMP_SUM_BENEFIT_ON_DEATH: 'Lump sum benefit of death',
  CONTRIBUTION_DATE: 'Contribution date',
  CONTRIBUTION_METHOD: 'Contribution method',
  CONTRIBUTION_FREQUENCY: 'Contribution frequency',
  TOP_UP: 'Top up',
  CONTRIBUTION_AMOUNT: 'Contribution amount',
  FEE_COMMISSION_DATE: 'Fee/Commission date',
  DATE_RECONCILED: 'Date reconciled',
  FEE_COMMISSION_FREQUENCY: 'Fee/Commission frequency',
  FEE_OR_COMMISSION: 'Fee or Commission',
  ADVICE_TYPE: 'Advice type',
  ADVICE_ONLY: 'Advice only',
  FEE_COMMISSION_FROM_CLIENT: 'Fee/Commission from client',
  FEE_COMMISSION_FROM_PROVIDER: 'Fee/Commission from provider',
  FEE_COMMISSION_FROM_PLATFORM: 'Fee/Commission from platform'
})
