import T from 'prop-types'

const ClientErrorHeading = ({ clientFirstName, clientLastName, rowNumber }) => {
  const firstPart = (clientFirstName || clientLastName)
    ? `${clientFirstName} ${clientLastName}`
    : rowNumber ? `Row ${rowNumber}` : ''

  return (
    <>
      {firstPart && (
        <>
          <b>{firstPart}</b>
          &nbsp;-&nbsp;
        </>
      )}
      Failed to create client
    </>
  )
}

ClientErrorHeading.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string
}

export default ClientErrorHeading
