import { AddImportPlatformButton } from '../AddImportPlatformButton'
import { EmptyPage } from '../../misc'

const ImportPageEmpty = () => (
  <EmptyPage
    text='There are no databases currently imported'
    renderButton={() => <AddImportPlatformButton />}
  />
)

export { ImportPageEmpty }
