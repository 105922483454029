import {
  GoBackHeader,
  EmptyPage,
  ErrorMessage,
  Spinner,
  DownloadReportBtn,
  Filter
} from '../../../misc'
import { ReportTable } from '../../ReportTable'

import './UpcomingReviewsPage.less'
import './UpcomingReviewsTable.less'

const UpcomingReviewsPage = props => {
  const {
    filterProps,
    tableProps,
    loading,
    error,
    called,
    downloadBtnProps
  } = props

  const isEmptyRows = !tableProps?.rows?.length

  return (
    <div className='upcoming-reviews-report'>
      <GoBackHeader title='Upcoming reviews' />

      <Filter loading={loading} {...filterProps} />

      {loading && <Spinner />}

      {isEmptyRows && called && !loading && !error && (
        <EmptyPage text='No records found' hidden={false} />
      )}

      {error && <ErrorMessage text='Failed to load records' />}

      {!isEmptyRows && (
        <>
          <div className='upcoming-reviews-report__btn-container'>
            <DownloadReportBtn
              className='dl-report-btn'
              reportName='upcoming-reviews'
              {...downloadBtnProps}
            />
          </div>

          <div className='table-wrap'>
            <ReportTable {...tableProps} />
          </div>
        </>
      )}
    </div>
  )
}

export { UpcomingReviewsPage }
