import { useEffect } from 'react'

import { SelectField } from '../../form/fields'
import { withFormik } from 'formik'

const IncomeCardFilter = ({ filterOptions, values, handleSubmit }) => {
  useEffect(() => {
    if (values?.dateRange) handleSubmit()
  }, [values])

  return (
    <div className='income-card__body__filter'>
      <form>
        <SelectField options={filterOptions} name='dateRange' />
      </form>
    </div>
  )
}

const EnhancedIncomeCardFilter = withFormik({
  mapPropsToValues: ({ filterOptions }) => ({
    dateRange: filterOptions[0].value
  }),
  handleSubmit: (values, { props: { onFilter } }) => onFilter(values)
})(IncomeCardFilter)
export { EnhancedIncomeCardFilter as IncomeCardFilter }
