import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import AddDocumentsButton from './AddDocumentsButton'
import fileUploadManager from '../../../helpers/fileUploadManager'
import useMutationState from '../../../hooks/useMutationState'
import createDocumentsGql from
  '../../../graphql/mutations/clientDocument/createDocuments.graphql'

const parseFileList = (fileList) => {
  const result = []

  for (let i = 0; i < fileList.length; i++) {
    const currFile = fileList.item(i)

    result.push({
      name: currFile.name,
      size: currFile.size,
      // Indexes will allow to easily identify how the upload URLs
      // and documents received from the API correspond with the files.
      index: i
    })
  }

  return result
}

const AddDocumentsButtonContainer = ({ clientId, policyId, ...props }) => {
  const [mutationFn] = useMutation(createDocumentsGql)
  // TODO: use mutation state provided by useMutation
  const [mutate, { isMutationLoading }] = useMutationState(mutationFn)

  const onFilesSelected = useCallback(
    async (fileList) => {
      if (!fileList.length) return

      const { data } = await mutate({
        variables: {
          input: {
            clientId,
            policyId,
            files: parseFileList(fileList)
          }
        }
      })

      if (data && data.createClientDocuments.length > 0) {
        data.createClientDocuments.forEach(({ uploadUrl, index }) => {
          fileUploadManager.upload(uploadUrl, fileList.item(index))
        })
      }
    },
    [clientId]
  )

  return (
    <AddDocumentsButton
      loading={isMutationLoading}
      onFilesSelected={onFilesSelected}
      {...props}
    />
  )
}

AddDocumentsButtonContainer.propTypes = {
  clientId: PropTypes.string.isRequired
}

export default AddDocumentsButtonContainer
