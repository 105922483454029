import T from 'prop-types'
import classnames from 'classnames'

import { Modal } from '../../misc'
import { RecipientModal } from './RecipientModal'

import { userType } from '../../../helpers/propTypes'

import './RecipientModal.less'

const RecipientModalTrigger = ({ modalProps, children, disabled }) => (
  <Modal.Trigger
    as='span'
    modal={RecipientModal}
    modalProps={modalProps}
    className={classnames({ 'recipient-modal-trigger--disabled': disabled })}
  >
    {children}
  </Modal.Trigger>
)

RecipientModalTrigger.propTypes = {
  modalProps: T.shape({
    customOptionsWithUser: T.arrayOf(
      T.shape({
        label: T.string,
        value: T.string,
        isCurrentUser: T.bool,
        isCurrentFA: T.bool,
        showDivider: T.bool,
        user: userType()
      })
    ).isRequired,
    client: T.shape({
      fullName: T.string,
      id: T.string
    }),
    onNextClick: T.func
  })
}

export { RecipientModalTrigger }
