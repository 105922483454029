import PropTypes from 'prop-types'

import { ImportLogHeader } from './ImportLogHeader'
import { ImportLog } from '../ImportLog'

import './ImportLogLayout.less'

export const ImportLogLayout = (props) => {
  const { pageTitle, link } = props

  return (
    <div className='import-log-layout'>
      <div className='import-log-layout__container'>
        <ImportLogHeader pageTitle={pageTitle} link={link} />

        <ImportLog {...props} />
      </div>
    </div>
  )
}

ImportLogLayout.propTypes = {
  gqlQuery: PropTypes.object.isRequired,
  gqlQueryField: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  dataImportType: PropTypes.string.isRequired
}
