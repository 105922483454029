import { useState } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import './ToggleButton.less'

const ToggleButton = ({
  isInitiallyActive,
  onChange,
  disabled,
  text,
  className
}) => {
  const [isActive, setIsActive] = useState(isInitiallyActive)

  const toggle = () => {
    setIsActive(!isActive)
    onChange(!isActive)
  }

  return (
    <div
      onClick={!disabled ? toggle : undefined}
      className={classnames('toggle-button', className, {
        'toggle-button--disabled': disabled
      })}
    >
      <div
        className={classnames('toggle-button__toggler', {
          'toggle-button__toggler--active': isActive
        })}
      />
      <span className='toggle-button__text'>{text}</span>
    </div>
  )
}

ToggleButton.propTypes = {
  onChange: T.func.isRequired,
  isInitiallyActive: T.bool
}

export { ToggleButton }
