import { Card, Summary } from '../../misc'
import { CheckIcon, CloseXIcon } from '../../icons'

import './ClientEmploymentCard.less'

const ClientEmploymentCard = props => {
  const {
    canEdit,
    id,
    summaries,
    ukDomiciled,
    ukResidentForTax,
    niNumber
  } = props
  const baseUrl = 'edit/employment'

  return (
    <Card id={id} className='client-employment-card'>
      <Card.Header
        allowToNavigate={canEdit}
        baseUrl={baseUrl}
      >
        Employment
      </Card.Header>

      <div className='client-employment-card__box'>
        <div className='client-employment-card__box__item'>
          <span>UK domiciled:</span>
          <span>
            {ukDomiciled
              ? (
                <CheckIcon className='check-icon' />
                )
              : (
                <CloseXIcon />
                )}
          </span>
        </div>

        <div className='client-employment-card__box__item'>
          <span>UK resident for tax:</span>
          <span>
            {ukResidentForTax
              ? (
                <CheckIcon className='check-icon' />
                )
              : (
                <CloseXIcon />
                )}
          </span>
        </div>

        <div className='client-employment-card__box__item'>
          <span>NI number:</span>
          <span> {niNumber} </span>
        </div>
      </div>

      {summaries.map((summary, i) => (
        <Summary data={summary} key={i} />
      ))}
    </Card>
  )
}

export { ClientEmploymentCard }
