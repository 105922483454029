import classnames from 'classnames'

import { TextAreaField } from '../../form/fields'

import './TextAreaExpandable.less'

const componentClass = 'text-area-expandable'

const TextAreaExpandable = ({
  name,
  isEditing,
  className,
  inputProps,
  handleOnBlur,
  handleOnFocus
}) => (
  <TextAreaField
    name={name}
    className={classnames(
      componentClass,
      isEditing && `${componentClass}--expanded`,
      isEditing && `${componentClass}--read-only`,
      className
    )}
    inputProps={{
      placeholder: inputProps?.placeholder || 'Type something ...',
      rows: inputProps?.rows || 1,
      maxLength: inputProps?.maxLength || 5000,
      onFocus: handleOnFocus,
      onBlur: handleOnBlur,
      ...inputProps
    }}
  />
)

export { TextAreaExpandable }
