import { compose } from 'ramda'

import { BrandingPage } from './BrandingPage'
import { withCurrentUser, withCompanyAdmin } from '../../hoc/container'

import './BrandingPage.less'

const BrandingPageContainer = (props) => <BrandingPage {...props} />

const BrandingPageWithCompanyAdmin = compose(
  withCompanyAdmin,
  withCurrentUser({ fetchPolicy: 'cache-only' })
)(BrandingPageContainer)

export { BrandingPageWithCompanyAdmin as BrandingPageContainer }
