import * as yup from 'yup'

import {
  dateSchema,
  endDateSchema,
  booleanSchema,
  stringSchema
} from '../helpers'

export default yup.object().shape({
  clientSince: dateSchema()
    .label('Client since'),

  clientUntil: endDateSchema('clientSince', 'Client Until date must be after Client Since date')
    .label('Client until'),

  nextReviewAt: dateSchema()
    .label('Next review date'),

  termsOfBusinessSignedAt: dateSchema()
    .label('Last terms of business signed'),

  feeAgreementSignedAt: dateSchema()
    .label('Last fee agreement signed'),

  vulnerable: booleanSchema()
    .label('Vulnerable')
    .default(false)
    .required(),

  serviceLevelId: stringSchema()
    .label('Service level')
})
