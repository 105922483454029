"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PlanStatuses = exports.InvestmentTypesLabels = exports.InvestmentTypes = exports.HeldInTrustValues = exports.Guarantees = exports.DeathBenefits = void 0;
const DeathBenefits = exports.DeathBenefits = Object.freeze({
  RETURN_OF_CONTRIBUTIONS: 'RETURN_OF_CONTRIBUTIONS',
  RETURN_OF_CONTRIBUTIONS_WITH_INTEREST: 'RETURN_OF_CONTRIBUTIONS_WITH_INTEREST',
  RETURN_OF_FUND: 'RETURN_OF_FUND',
  RETURN_OF_FUND_PLUS_LIFE_COVER: 'RETURN_OF_FUND_PLUS_LIFE_COVER'
});
const Guarantees = exports.Guarantees = Object.freeze({
  GUARANTEED_MVA_FREE_DATE: 'GUARANTEED_MVA_FREE_DATE',
  GUARANTEED_BONUS_RATE: 'GUARANTEED_BONUS_RATE',
  GUARANTEED_FUTURE_FUND_VALUE: 'GUARANTEED_FUTURE_FUND_VALUE',
  GUARANTEED_GROWTH_RATE: 'GUARANTEED_GROWTH_RATE',
  GUARANTEED_INCOME_BASE: 'GUARANTEED_INCOME_BASE',
  OTHER_GUARANTEE: 'OTHER_GUARANTEE'
});
const HeldInTrustValues = exports.HeldInTrustValues = Object.freeze({
  NOT_IN_TRUST: 'NOT_IN_TRUST',
  YES: 'YES',
  YES_BARE_TRUST: 'YES_BARE_TRUST',
  YES_DISCRETIONARY_TRUST: 'YES_DISCRETIONARY_TRUST'
});
const InvestmentTypes = exports.InvestmentTypes = Object.freeze({
  CASH_ACCOUNT: 'CASH_ACCOUNT',
  CASH_ISA: 'CASH_ISA',
  CHILD_TRUST_FUND: 'CHILD_TRUST_FUND',
  CURRENT_ACCOUNT: 'CURRENT_ACCOUNT',
  DEPOSIT_ACCOUNT: 'DEPOSIT_ACCOUNT',
  DIRECT_SHARES: 'DIRECT_SHARES',
  DIRECT_SHARES_ITS: 'DIRECT_SHARES_ITS',
  DISCOUNTED_GIFT_TRUST: 'DISCOUNTED_GIFT_TRUST',
  ENDOWMENT: 'ENDOWMENT',
  ENTERPRISE_INVESTMENT_SCHEME: 'ENTERPRISE_INVESTMENT_SCHEME',
  EXCHANGE_TRADED_FUNDS: 'EXCHANGE_TRADED_FUNDS',
  GENERAL_INVESTMENT_ACCOUNT: 'GENERAL_INVESTMENT_ACCOUNT',
  JUNIOR_ISA: 'JUNIOR_ISA',
  LIFETIME_ISA: 'LIFETIME_ISA',
  LOW_COST_ENDOWMENT: 'LOW_COST_ENDOWMENT',
  NATIONAL_SAVINGS: 'NATIONAL_SAVINGS',
  NMPI: 'NMPI',
  OEIC: 'OEIC',
  OFFSHORE_INVESTMENT_BOND: 'OFFSHORE_INVESTMENT_BOND',
  ONSHORE_INVESTMENT_BOND: 'ONSHORE_INVESTMENT_BOND',
  OTHER: 'OTHER',
  PACKAGED_IHT_SOLUTION: 'PACKAGED_IHT_SOLUTION',
  PREMIUM_BONDS: 'PREMIUM_BONDS',
  SEIS: 'SEIS',
  SHARE_INCENTIVE_PLAN: 'SHARE_INCENTIVE_PLAN',
  SHARE_SAVE_SCHEME: 'SHARE_SAVE_SCHEME',
  STOCKS_AND_SHARES_ISA: 'STOCKS_AND_SHARES_ISA',
  TRUSTEE_INVESTMENT_PLAN: 'TRUSTEE_INVESTMENT_PLAN',
  UCIS: 'UCIS',
  UNIT_TRUST: 'UNIT_TRUST',
  UNKNOWN_CASH_CALC: 'UNKNOWN_CASH_CALC',
  VENTURE_CAPITAL_TRUST: 'VENTURE_CAPITAL_TRUST'
});
const PlanStatuses = exports.PlanStatuses = Object.freeze({
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  NTU: 'NTU',
  LIVE: 'LIVE',
  SURRENDERED: 'SURRENDERED',
  TRANSFERRED_OUT: 'TRANSFERRED_OUT'
});
const InvestmentTypesLabels = exports.InvestmentTypesLabels = Object.freeze({
  [InvestmentTypes.CASH_ACCOUNT]: 'Cash Account',
  [InvestmentTypes.CASH_ISA]: 'Cash ISA',
  [InvestmentTypes.CHILD_TRUST_FUND]: 'Child Trust Fund',
  [InvestmentTypes.CURRENT_ACCOUNT]: 'Current Account',
  [InvestmentTypes.DEPOSIT_ACCOUNT]: 'Deposit Account',
  [InvestmentTypes.DIRECT_SHARES]: 'Direct Shares',
  [InvestmentTypes.DIRECT_SHARES_ITS]: 'Direct Shares - ITS',
  [InvestmentTypes.DISCOUNTED_GIFT_TRUST]: 'Discounted Gift Trust',
  [InvestmentTypes.ENDOWMENT]: 'Endowment',
  [InvestmentTypes.ENTERPRISE_INVESTMENT_SCHEME]: 'Enterprise Investment Scheme',
  [InvestmentTypes.EXCHANGE_TRADED_FUNDS]: 'Exchange Traded Funds',
  [InvestmentTypes.GENERAL_INVESTMENT_ACCOUNT]: 'General Investment Account',
  [InvestmentTypes.JUNIOR_ISA]: 'Junior ISA',
  [InvestmentTypes.LIFETIME_ISA]: 'Lifetime ISA',
  [InvestmentTypes.LOW_COST_ENDOWMENT]: 'Low Cost Endowment',
  [InvestmentTypes.NATIONAL_SAVINGS]: 'National Savings',
  [InvestmentTypes.NMPI]: 'NMPI',
  [InvestmentTypes.OEIC]: 'OEIC',
  [InvestmentTypes.OFFSHORE_INVESTMENT_BOND]: 'Offshore Investment Bond',
  [InvestmentTypes.ONSHORE_INVESTMENT_BOND]: 'Onshore Investment Bond',
  [InvestmentTypes.OTHER]: 'Other',
  [InvestmentTypes.PACKAGED_IHT_SOLUTION]: 'Packaged IHT solution',
  [InvestmentTypes.PREMIUM_BONDS]: 'Premium Bonds',
  [InvestmentTypes.SEIS]: 'SEIS',
  [InvestmentTypes.SHARE_INCENTIVE_PLAN]: 'Share Incentive Plan',
  [InvestmentTypes.SHARE_SAVE_SCHEME]: 'Share Save Scheme',
  [InvestmentTypes.STOCKS_AND_SHARES_ISA]: 'Stocks & Shares ISA',
  [InvestmentTypes.TRUSTEE_INVESTMENT_PLAN]: 'Trustee Investment Plan',
  [InvestmentTypes.UCIS]: 'UCIS',
  [InvestmentTypes.UNIT_TRUST]: 'Unit Trust',
  [InvestmentTypes.UNKNOWN_CASH_CALC]: 'Unknown (CashCalc)',
  [InvestmentTypes.VENTURE_CAPITAL_TRUST]: 'Venture Capital Trust'
});
var _default = exports.default = {
  DeathBenefits,
  Guarantees,
  HeldInTrustValues,
  InvestmentTypes,
  PlanStatuses,
  InvestmentTypesLabels
};