import T from 'prop-types'

import { Modal } from '..'
import { ConfirmPasswordModal } from './ConfirmPasswordModal'

const ConfirmPasswordModalTrigger = props => (
  <Modal.Trigger {...props} modal={ConfirmPasswordModal} />
)

ConfirmPasswordModalTrigger.propTypes = {
  modalProps: T.shape({
    onSubmit: T.func.isRequired,
    loading: T.bool,
    error: T.error,
    heading: T.string,
    onClose: T.func,
    description: T.node
  }).isRequired,
  as: T.oneOfType([T.func, T.string]),
  children: T.node
}

export { ConfirmPasswordModalTrigger }
