import { compose } from 'ramda'
import { useQuery } from '@apollo/client'

import Users from './Users'
import { Spinner, ErrorMessage } from '../../../misc'
import { withCurrentUser, withCompanyAdmin } from '../../../hoc/container'
import { splitToActiveAndInactiveByStatus } from '../../../../helpers/users'

import usersGql from '../../../../graphql/queries/user/users.graphql'

const UsersError = () => (
  <ErrorMessage text='Failed to retrieve users' />
)

const UsersGraphqlContainer = (props) => {
  const { loading, error, data } = useQuery(usersGql, {
    options: { fetchPolicy: 'cache-and-network' }
  })

  if (loading && !data) {
    return <Spinner />
  }

  if (error) {
    return <UsersError />
  }

  const { users, subscriptionSeats } = data

  const usersProps = {
    users,
    ...splitToActiveAndInactiveByStatus(users)
  }

  const seatsCount = subscriptionSeats ? subscriptionSeats?.seats : 0

  return <Users seatsCount={seatsCount} usersProps={usersProps} {...props} />
}

const UsersContainer = compose(
  withCompanyAdmin,
  withCurrentUser({ fetchPolicy: 'cache-only' })
)(UsersGraphqlContainer)

export { UsersContainer }
