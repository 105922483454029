import T from 'prop-types'
import classnames from 'classnames'

import { ReactHint } from './ReactHint'
import { Positions } from './constants'

/**
 * Extensible tooltip.
 * It will render anything passed to it as children.
 */
export const Tooltip = props => {
  const { children, idAttribute, className, onRenderFunc, ...rest } = props

  return (
    <ReactHint
      attribute={idAttribute}
      events={{
        hover: true
      }}
      className={classnames(className, 'react-hint')} // preserve the original class name
      onRenderContent={(target, content) =>
        onRenderFunc ? onRenderFunc({ children }) : children}
      {...rest}
    />
  )
}

Tooltip.defaultProps = {
  autoPosition: true
}

Tooltip.propTypes = {
  /**
   * Content of the tooltip.
   */
  children: T.node.isRequired,
  /**
   * The attribute that `react-hint` uses to identify the triggers for this tooltip.
   */
  idAttribute: T.string.isRequired,
  /**
   * Custom classname for the tooltip component.
   */
  className: T.string,
  /**
   * Position of the tooltip relative to the trigger.
   */
  position: T.oneOf(Object.values(Positions)),
  /**
   * Determine position of the tooltip automatically.
   */
  autoPosition: T.bool
}
