const ContributionIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M4.929 21A1.929 1.929 0 013 19.071V16.5c0-1.065.863-1.929 1.929-1.929h.642V4.93C5.571 3.863 6.435 3 7.5 3h9c1.065 0 1.929.863 1.929 1.929v9.642h.642c1.066 0 1.929.864 1.929 1.929v2.571A1.929 1.929 0 0119.071 21zm1.928-3.857h10.286V8.09a4.5 4.5 0 01-3.806-3.805h-2.674A4.5 4.5 0 016.857 8.09v9.052zm10.286-10.35V4.929a.643.643 0 00-.643-.643h-1.864a3.214 3.214 0 002.507 2.507zm-10.286 0a3.214 3.214 0 002.507-2.507H7.5a.643.643 0 00-.643.643zm4.882 8.223a.528.528 0 01-.196-.196l-1.69-2.958a.264.264 0 01.229-.395h1.391V8.302a.528.528 0 111.055 0v3.165h1.392a.264.264 0 01.23.395l-1.691 2.958a.528.528 0 01-.72.196zm-6.81 4.698H19.07a.643.643 0 00.643-.643V16.5a.643.643 0 00-.643-.643h-.642v1.929a.643.643 0 01-.643.643H6.214a.643.643 0 01-.643-.643v-1.929H4.93a.643.643 0 00-.643.643v2.571c0 .355.288.643.643.643z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { ContributionIcon }
