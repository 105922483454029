import { useQuery } from '@apollo/client'

import { Spinner, ErrorMessage } from '../../misc'
import { P1ImportPage } from './P1ImportPage'
import p1ConnectionGql
  from '../../../graphql/queries/dataImport/p1Connection.graphql'

const P1ImportPageContainer = props => {
  const { loading, error, data } = useQuery(p1ConnectionGql, {
    fetchPolicy: 'cache-and-network'
  })

  const initialLoading = loading && !data

  if (initialLoading) return <Spinner />
  if (error) return <ErrorMessage text='Failed to get P1 data' />

  const p1Data = { ...data?.dataImportP1Connection }

  return (
    <P1ImportPage {...{ p1Data, ...props }} />
  )
}

export { P1ImportPageContainer }
