const ShapeVertical = props => (
  <svg width={192} height={230} viewBox='0 0 192 230' {...props}>
    <g stroke='currentColor' fill='none' fillRule='evenodd'>
      <path d='M194.304-2.305c-16.915 6.38-23.408 26.844-22.106 47.203 1.303 20.356 8.877 41.28 14.134 61.987 5.257 20.708 8.095 42.677.206 59.098-14.035 29.217-54.096 29.302-84.822 39.45C55.224 220.788 25.16 266.45 25.86 320.645' />
      <path d='M179.304-13.306c-16.915 6.38-23.407 26.846-22.106 47.204 1.303 20.357 8.877 41.28 14.134 61.987 5.256 20.707 8.094 42.676.206 59.097-14.035 29.216-54.096 29.301-84.823 39.45-46.49 15.355-76.555 61.018-75.854 115.213' />
      <path d='M168.304-19.305c-16.915 6.38-23.408 26.844-22.106 47.203 1.304 20.357 8.877 41.28 14.134 61.987 5.257 20.708 8.095 42.677.206 59.098-14.035 29.217-54.096 29.302-84.822 39.45C29.224 203.788-.84 249.45-.14 303.645' />
      <path d='M155.304-31.305c-16.915 6.38-23.408 26.844-22.106 47.203 1.303 20.356 8.876 41.28 14.134 61.988 5.257 20.707 8.095 42.676.206 59.096-14.034 29.217-54.095 29.302-84.823 39.45-46.49 15.355-76.554 61.019-75.854 115.213' />
    </g>
  </svg>
)

export { ShapeVertical }
