import T from 'prop-types'

import { ModalPortal } from './ModalPortal'
import { ModalCard } from './ModalCard'
import { ModalContent } from './ModalContent'

import './Modal.less'

const ModalTopBanner = ({ renderTopBanner }) => renderTopBanner
  ? (
    <div className='modal__top-banner'>
      {renderTopBanner()}
    </div>
    )
  : null

const Modal = ({
  children,
  className,
  contentClassName,
  isOpen,
  close,
  trigger,
  renderTopBanner,
  blockEscapeKey,
  fullScreen
}) => (
  <>
    {trigger}
    {isOpen && (
      <ModalPortal close={close} blockEscapeKey={blockEscapeKey}>
        <ModalCard className={className} fullScreen={fullScreen}>
          <ModalTopBanner
            renderTopBanner={renderTopBanner}
          />
          <ModalContent close={close} className={contentClassName}>
            {children}
          </ModalContent>
        </ModalCard>
      </ModalPortal>
    )}
  </>
)

Modal.defaultProps = {
  isOpen: false
}

Modal.propTypes = {
  /**
   * What should be rendered inside the modal.
   */
  children: T.node.isRequired,
  /**
   * Classname for the modal content.
   */
  contentClassName: T.string,
  /**
   * Classname for the modal.
   */
  className: T.string,
  /**
   * Whether the modal is rendered or not.
   */
  isOpen: T.bool,
  /**
   * Callback to close the modal.
   */
  close: T.func.isRequired,
  /**
   * Trigger for the modal.
   */
  trigger: T.node,
  /**
   * Render prop function for the top banner.
   */
  renderTopBanner: T.func
}

export { Modal }
