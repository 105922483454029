import { Routes, Route, Navigate } from 'react-router-dom'

import { PolicyTransactionsTab } from '../PolicyTransactionsTab'
import { PolicyValuation } from '../PolicyValuation'

const ClientPolicySwitch = ({
  policy,
  search,
  isInCreatePath,
  isClientPolicy,
  showValuationRoute,
  PolicyCategoryDetails
}) => {
  return (
    <Routes>
      <Route
        path='details'
        element={PolicyCategoryDetails && <PolicyCategoryDetails
          policy={policy}
          readOnly={isClientPolicy}
          headingText='Details'
                                          />}
      />
      {showValuationRoute && (
        <Route
          path='valuation'
          element={<PolicyValuation
            policy={policy}
            readOnly={isClientPolicy}
                   />}
        />
      )}
      <Route
        path='contributions-and-withdrawals'
        element={<PolicyTransactionsTab
          policy={policy}
          isClientPolicy={isClientPolicy}
                 />}
      />
      {/* // TODO: this is a hack too... routes should be changed */}
      <Route
        path='/' element={!isInCreatePath && <Navigate
          replace to={{
            pathname: 'details',
            search
          }}
                                             />}
      />
    </Routes>
  )
}

export { ClientPolicySwitch }
