import { Route, Routes, Navigate } from 'react-router-dom'

import { ClientCompliancePage } from '../ClientCompliancePage'

const ClientComplianceSection = () => (
  <>
    <Routes>
      <Route path='details' element={<ClientCompliancePage />} />
      <Route path='/' element={<Navigate replace to='details' />} />
    </Routes>
  </>
)

export { ClientComplianceSection }
