import * as yup from 'yup'
import { values } from 'ramda'
import { CompanyLegalStatuses } from '@elenfs/elen-constants'

import dateSchema from '../helpers/dateSchema'
import stringSchema from '../helpers/stringSchema'
import numberSchema from '../helpers/numberSchema'
import enumSchema from '../helpers/enumSchema'

export default yup.object().shape({
  name: stringSchema()
    .label('Company details section: Name of business')
    .required(),

  tradingName: stringSchema()
    .label('Trading as'),

  legalEntityIdentifier: stringSchema()
    .label('Legal entity identifier (LEI)'),

  leiExpiryDate: dateSchema()
    .label('LEI expiry date'),

  legalStatus: enumSchema(values(CompanyLegalStatuses))
    .label('Legal status'),

  natureOfBusiness: stringSchema()
    .label('Nature of business'),

  fullTimeEmployees: numberSchema()
    .label('Full time employees'),

  partTimeEmployees: numberSchema()
    .label('Part time employees'),

  dateFounded: dateSchema()
    .label('Date founded'),

  address1: stringSchema()
    .label('Address 1'),

  address2: stringSchema()
    .label('Address 2'),

  city: stringSchema()
    .label('City'),

  postCode: stringSchema()
    .label('Post Code'),

  firstName: stringSchema()
    .label('Company details section: First name')
    .required(),

  lastName: stringSchema()
    .label('Company details section: Last name')
    .required(),

  email: stringSchema()
    .email()
    .label('Email'),

  mobile: stringSchema()
    .label('Mobile'),

  landlineTel: stringSchema()
    .label('Landline')
})
