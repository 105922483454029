import { ReceivedIncomeTable } from '../IncomeTables/ReceivedIncomeTable'
import PageContent from '../../misc/PageContent'

import './ReceivedIncome.less'

const ReceivedIncome = props => (
  <div className='received-income'>
    <PageContent>
      <ReceivedIncomeTable {...props} />
    </PageContent>
  </div>
)

export { ReceivedIncome }
