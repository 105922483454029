import { useState, useCallback, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { useInterval, useGetCurrentPaginationNumber } from '../../../hooks'
import { ImportLog } from './ImportLog'
import { paginationLimit } from '../ImportLog'
import { Spinner, ErrorMessage } from '../../misc'
import { ImportLogEmpty } from './ImportLogEmpty'

const POLL_INTERVAL = 10 * 1000 // 10 seconds

// Instead of the "pollInterval" option we use the "fetchMore", because
// the "pollInterval" does not allow to change query variables.
// With "fetchMore" we can set different value for the "from" variable for
// each query and get only latest updates (if any) instead of all log data.

const ImportLogContainer = (props) => {
  const { urlPageNumber } = useGetCurrentPaginationNumber()

  const { gqlQuery, gqlQueryField, dataImportType, link } = props

  const [from, setFromDate] = useState(new Date())
  const [dataImportJobs, setDataImportJobs] = useState([])
  const [paginationData, setPaginationData] = useState({
    page: 0,
    perPage: 1,
    count: null
  })

  useEffect(() => {
    return () => {
      setDataImportJobs([])
    }
  }, [])

  const limit = paginationLimit

  const handleOnCompleteCallback = (data) => {
    const { result, ...rest } = data?.[gqlQueryField]
    setDataImportJobs(result)
    setPaginationData((prevState) => ({
      ...prevState, ...rest
    }))
  }

  const { error, loading, data, fetchMore } = useQuery(gqlQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      source: dataImportType,
      limit,
      offset: Math.ceil(Math.abs(limit * urlPageNumber)) || 0
    },
    onCompleted: (data) => handleOnCompleteCallback(data)
  })

  const initialLoading = loading && !data

  // 'from' is defaulted to undefined because it is used to refetch data at intervals, but not needed during pagination change
  const fetchMoreData = ({ from = undefined, page }) => {
    window.history.replaceState({ page }, '', `${link}/logs?page=${page + 1}`)

    const offset = Math.ceil(Math.abs(limit * page))
    fetchMore({
      variables: { from, source: dataImportType, limit, offset },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.[gqlQueryField]?.result?.length) return prev
        return { ...prev, ...fetchMoreResult }
      }
    })
  }

  const handlePaginationChange = useCallback(
    (page) => fetchMoreData({ page }),
    [fetchMore]
  )

  useInterval(
    () => {
      fetchMoreData({ from, page: paginationData?.page })
      setFromDate(new Date())
    },
    // Set interval for fetching updates only after
    // successful initial fetch
    (initialLoading || error) ? null : POLL_INTERVAL
  )

  if (initialLoading) {
    return <Spinner />
  }

  if (error) {
    return <ErrorMessage text='Failed to get data import log' />
  }

  const isEmpty = !(dataImportJobs && dataImportJobs.length)

  const pageCount = Math.ceil(paginationData?.count / limit)

  return (
    <>
      {!isEmpty && <ImportLog
        dataImportJobs={dataImportJobs}
        pageCount={pageCount}
        handlePaginationChange={handlePaginationChange}
        page={paginationData?.page}
        {...props}
                   />}
      <ImportLogEmpty {...{ hidden: !isEmpty }} />
    </>
  )
}

export { ImportLogContainer }
