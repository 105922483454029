import T from 'prop-types'

import { ConfirmationModal, Button } from '../../misc'

const RemoveAuditLogEntryButton = ({
  removeAuditLogEntryCallback,
  className,
  Component,
  setErrors
}) => (
  <ConfirmationModal.Trigger
    as={Component}
    color={Button.Colors.RED_TEXT}
    className={className}
    modalProps={{
      setErrors,
      confirmationCallback: removeAuditLogEntryCallback,
      heading: 'Delete activity log',
      text: 'Are you sure you want to delete this activity log?',
      yesButtonText: 'Delete',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
  >
    Delete activity
  </ConfirmationModal.Trigger>
)

RemoveAuditLogEntryButton.defaultProps = {
  Component: Button
}

RemoveAuditLogEntryButton.propTypes = {
  removeAuditLogEntryCallback: T.func.isRequired,
  Component: T.elementType.isRequired,
  className: T.string
}

export { RemoveAuditLogEntryButton }
