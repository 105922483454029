import { useState } from 'react'
import { ClientDetailsBaseFormEnhancedWithCurrUser } from './ClientDetailsBaseForm'

const ClientDetailsBaseFormContainer = props => {
  const [validationSchema, setValidationSchema] = useState({})

  return (
    <ClientDetailsBaseFormEnhancedWithCurrUser {...{
      validationSchema,
      setValidationSchema,
      ...props
    }}
    />
  )
}

export { ClientDetailsBaseFormContainer }
