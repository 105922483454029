import { PolicyDetails, AmountCard, DetailsCard } from '../../PolicyDetails'
import { PolicyNoteCard } from '../../PolicyNoteCard'

import { formatMoney } from '../../../../helpers/policy'
import { formatDate } from '../../../../helpers/date'
import {
  BooleanLabels,
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from '../../../../helpers/enumLabels'
import {
  PensionTypesLabels,
  PlanStatusesLabels,
  ProtectionsLabels,
  GuaranteesLabels,
  DeathBenefitsLabels,
  PCLSEntitlementsLabels
} from '../../../../helpers/enumLabels/pensionPolicy'
import { resolvePolicyHoldersData } from '../helpers'

const PensionPolicyDetails = ({ policy, headingText, readOnly }) => {
  const currentValueCardData = [
    { label: 'Valuation Date', value: formatDate(policy.valuationDate) }
  ]
  const cardData = [
    { label: 'Transfer date', value: formatDate(policy.transferDate) }
  ]

  const data = [
    [
      {
        label: 'Replacement business',
        value: policy?.isReplacementBusiness ? 'Yes' : 'No'
      },
      { label: 'Category', value: PolicyCategoriesLabels[policy.category] },
      { label: 'Policy type', value: PensionTypesLabels[policy.pensionType] },
      { label: 'Policy number', value: policy.number },
      ...resolvePolicyHoldersData(policy)(policy?.currentHolders, readOnly),
      { label: 'Plan status', value: PlanStatusesLabels[policy.planStatus] },
      { label: 'Provider', value: PolicyProvidersLabels[policy.provider] }
    ],
    [
      { label: 'Application date', value: formatDate(policy.applicationDate) },
      { label: 'Start date', value: formatDate(policy.startDate) },
      { label: 'Maturity date', value: formatDate(policy.maturityDate) }
    ],
    [
      { label: 'Waiver', value: BooleanLabels[policy.waiver] },
      { label: 'Selected retirement age', value: policy.selectedRetirementAge },
      {
        label: 'PCLS Entitlement',
        value: PCLSEntitlementsLabels[policy.pclsEntitlement]
      },
      {
        label: 'Death benefit',
        value: DeathBenefitsLabels[policy.deathBenefit]
      },
      {
        label: 'Additional life cover',
        value: formatMoney(policy.additionalLifeCover)
      },
      { label: 'Earmarking', value: BooleanLabels[policy.earmarking] },
      { label: 'Protection', value: ProtectionsLabels[policy.protection] },
      { label: 'Guarantees', value: GuaranteesLabels[policy.guarantees] }
    ]
  ]

  return (
    <PolicyDetails>
      <div>
        <DetailsCard {...{ headingText, data, readOnly }} />
      </div>
      <div>
        <AmountCard
          headingText='Current value'
          headingValue={formatMoney(policy.currentValue)}
          cardData={currentValueCardData}
        />
        <AmountCard
          headingText='Transfer Value'
          headingValue={formatMoney(policy.transferValue)}
          cardData={cardData}
        />

        {!readOnly && <PolicyNoteCard note={policy?.note} policyId={policy?.id} />}
      </div>
    </PolicyDetails>
  )
}

export default PensionPolicyDetails
