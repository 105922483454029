import Card from '../../misc/Card'
import { ClientCardTable } from '../../client/ClientCardTable'
import { profitAndLossCardTableProps } from './const'
import { buildCompanyDetails } from './helpers'
import { CompanyDetailsCard } from '../CompanyDetailsCard'

const CorporateClientDetailsPageCards = ({ client, canEdit, baseUrl }) => {
  return (
    <>
      <CompanyDetailsCard
        canEdit={canEdit}
        clientId={client.id}
        id='company-details'
        baseUrl={baseUrl}
        {...buildCompanyDetails(client)}
      />

      <Card className='client-details-page__client-details__profit-loss'>
        <Card.Header allowToNavigate={canEdit} id='profit-and-loss' baseUrl={baseUrl}>
          Profit and loss
        </Card.Header>

        <ClientCardTable
          data={client.profitAndLoss}
          {...profitAndLossCardTableProps}
          className='client-details-page__client-details__profit-loss-table'
        />
      </Card>
    </>
  )
}

export { CorporateClientDetailsPageCards }
