import classnames from 'classnames'
import T from 'prop-types'

import { ShapeVerticalStripesIcon } from '../../icons'

import './DashboardBoxCardCount.less'

const DashboardBoxCardCount = ({
  Icon,
  title,
  count,
  className,
  navPathOnClick,
  navigate
}) => (
  <div
    className={classnames('dashboard-card-box-count', className, {
      'dashboard-card-box-count--cursor-pointer': navPathOnClick
    })}
    onClick={() => navPathOnClick && navigate(navPathOnClick)}
  >
    <ShapeVerticalStripesIcon className='dashboard-card-box-count__shape' />

    <div className='dashboard-card-box-count__icon'>
      <Icon />
    </div>
    <div className='dashboard-card-box-count__title'>{title}</div>
    <div className='dashboard-card-box-count__count'>{count}</div>
  </div>
)

DashboardBoxCardCount.propTypes = {
  Icon: T.elementType,
  title: T.string,
  count: T.number,
  navPathOnClick: T.string
}

export { DashboardBoxCardCount }
