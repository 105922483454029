import { Route, Routes, Navigate } from 'react-router-dom'

import { withCurrentUser } from '../../hoc/container'
import { Users } from '../Users/Users'
import { AdminNav } from '../AdminNav'
import { ImportPage } from '../../import/ImportPage'
import { AccountOverviewPage } from '../AccountOverview'
import {
  onlyAccountOwnerOrAdminAccess
} from '../../hoc/container/WithPermissionCheck'
import { TeamsPage } from '../../teams/TeamsPage'
import { BrandingPage } from '../../branding/BrandingPage'
import { ServiceLevels } from '../ServiceLevels/ServiceLevelsPage'
import { EmailPage } from '../EmailIntegration'

import './AdminChildSections.less'

const AdminChildSections = ({ match, currentUser }) => {
  const redirectTo = onlyAccountOwnerOrAdminAccess(currentUser) ? 'users' : 'import'

  return (
    <div className='admin-child-sections'>
      <AdminNav currentUser={currentUser} />
      <div className='admin-child-sections__section-content'>
        <div>
          <Routes>
            <Route path='users' element={<Users />} />
            <Route path='import' element={<ImportPage />} />
            <Route path='account-overview' element={<AccountOverviewPage />} />
            <Route path='teams' element={<TeamsPage />} />
            <Route path='email' element={<EmailPage />} />
            <Route path='branding' element={<BrandingPage />} />
            <Route path='service-levels' element={<ServiceLevels />} />
            <Route path='/' element={<Navigate replace to={redirectTo} />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

const AdminChildSectionsEnhanced = withCurrentUser(
  { fetchPolicy: 'cache-only' }
)(AdminChildSections)

export { AdminChildSectionsEnhanced as AdminChildSections }
