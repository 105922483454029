import { SidePanel } from '../../misc'
import { TaskActionForm } from './TaskActionForm'

const TaskActionSidePanel = props => {
  const { sidePanelContentProps, showSidePanel, closePanel } = props

  return (
    <SidePanel
      title='Client task'
      sidePanelContent={TaskActionForm}
      sidePanelContentProps={sidePanelContentProps}
      showPanel={showSidePanel}
      closePanel={closePanel}
    />
  )
}

export { TaskActionSidePanel }
