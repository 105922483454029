import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import ImportError from './ImportError'
import ClientErrorHeading from './ClientErrorHeading'

import { buildLogErrorDescription } from '../../../../helpers/dataImport'

const { FieldErrorCodes } = constants.error
const { DataImportTypes } = constants.dataImport

const { MISSING_VALUE } = FieldErrorCodes
const { TRANSACT, CASH_CALC, P1, PRAEMIUM } = DataImportTypes

const findField = (errorCode, path, fields) =>
  fields.find(field => field.errorCode === errorCode && field.path === path)

const InvalidClientError = ({
  clientFirstName,
  clientLastName,
  fields,
  dataImportType
}) => {
  const heading = (
    <ClientErrorHeading
      clientFirstName={clientFirstName}
      clientLastName={clientLastName}
    />
  )

  let description = ''

  // It is expected that NI number might be missing in the data returned
  // from Transact. That's why we show a special error message in such case.
  // Missing or invalid values for other client/policy fields mean that
  // something is very wrong on the Transact side and we just show a generic
  // "Transact server error" message.
  if (dataImportType === TRANSACT) {
    if (findField(MISSING_VALUE, 'niNumber', fields)) {
      description = 'Missing NI number'
    } else {
      description = 'Transact Server Error'
    }
  }

  if (dataImportType === CASH_CALC) {
    description = buildLogErrorDescription(fields)
    if (!description) description = 'CashCalc Server Error'
  }

  if (dataImportType === PRAEMIUM || dataImportType === P1) {
    description = buildLogErrorDescription(fields)
  }

  return <ImportError heading={heading} description={description} />
}

InvalidClientError.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  fields: T.array.isRequired,
  dataImportType: T.oneOf(Object.values(DataImportTypes)).isRequired
}

export default InvalidClientError
