import T from 'prop-types'
import classnames from 'classnames'

const ModalStickyBody = ({ className, children }) => (
  <div className={classnames('modal__sticky-layout__content__body', className)}>
    {children}
  </div>
)

ModalStickyBody.propTypes = {
  children: T.node.isRequired,
  className: T.string
}

export { ModalStickyBody }
