import { PageContent } from '../../misc'
import { BrandingForm } from '../BrandingForm'

import './BrandingPage.less'

const BrandingPage = () => (
  <PageContent className='branding'>
    <BrandingForm />
  </PageContent>
)

export { BrandingPage }
