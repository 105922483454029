import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import T from 'prop-types'

import { useSignatureModal } from './useSignatureModal'
import DocumentsPage from './DocumentsPage'
import Spinner from '../../misc/Spinner'
import ErrorMessage from '../../misc/ErrorMessage'

import clientDocumentsGql from '../../../graphql/queries/clientDocument/clientDocuments.graphql'
import { withClientUser } from '../../hoc/container'

const POLL_INTERVAL = 8 * 1000 // 8 seconds

const DocumentsErrorMessage = () => (
  <ErrorMessage text='Failed to retrieve client documents' />
)

const DocumentsPageContainer = props => {
  const { clientId, isClientPortal, policyId, ...otherProps } = props

  const { docId, eSignatureId } = useParams()

  const { loading, error, data, refetch } = useQuery(clientDocumentsGql, {
    variables: {
      clientId,
      policyId
    },
    fetchPolicy: 'cache-and-network',
    // avoid flickering caused by refetching the original query
    nextFetchPolicy: 'cache-first',
    pollInterval: POLL_INTERVAL
  })

  const clientDocuments = data?.clientDocuments || []

  const {
    signatureModalProps,
    closeSignatureModal,
    loading: requestESignatureLoading,
    onRequestSignatureDocument,
    onClickToSign,
    isSignatureModalOpen
  } = useSignatureModal({
    refetch,
    isClientPortal,
    clientId,
    docId,
    eSignatureId
  })

  if (!data && (loading || requestESignatureLoading)) {
    return <Spinner />
  }

  if (error) {
    return <DocumentsErrorMessage />
  }

  return (
    <DocumentsPage
      {...{
        clientDocuments,
        clientId,
        isClientPortal,
        policyId,
        onRequestSignatureDocument,
        signatureModalProps,
        closeSignatureModal,
        refetchDocuments: refetch,
        onClickToSign,
        isSignatureModalOpen,
        ...otherProps
      }}
    />
  )
}

DocumentsPageContainer.propTypes = {
  clientId: T.string.isRequired,
  isClientPortal: T.bool
}

DocumentsPageContainer.defaultProps = {
  isClientPortal: false
}

const EnhancedDocumentsPageContainer = withClientUser()(DocumentsPageContainer)

export default EnhancedDocumentsPageContainer
