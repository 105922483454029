import constants from '@elenfs/elen-constants'

import CompanyUserDetailsGql from '../../../graphql/queries/client/clientDetailsByInvitationToken.graphql'
import companyUserInvitation from '../../../graphql/mutations/user/createClientUser.graphql'
import companyUserSetupPasswordSchema from '../../../schemas/client/clientSignupSchema'
import InvitationSignupPage from '../../misc/InvitationSignupPage'

const { UserTypes } = constants.user

const ClientSignupPage = () => {
  return (
    <InvitationSignupPage
      query={CompanyUserDetailsGql}
      queryResultPath='clientDetailsByInvitationToken'
      formMutation={companyUserInvitation}
      formRedirectUrl='/clients'
      formValidationSchema={companyUserSetupPasswordSchema}
      formLoginType={UserTypes.CLIENT}
    />
  )
}

export default ClientSignupPage
