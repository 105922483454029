import { useState, useEffect, Fragment, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

/*
 TODO: There's still an issue -
 if the item that's at the bottom of the viewport is visible together with another item,
 its id never becomes the anchor part of the url.
 Maybe it's possible to solve with some hooks, or using onWheel callback
 (so that top items are prioritized if you're scrolling up,
 and bottom items are prioritized if you're scrolling down)
*/
const updateAnchor = ({
  ids = [],
  threshold = [0, 0.3, 0.9],
  onAnchorChangeCb = null
}) => {
  const intersectionMap = ids.reduce((acc, id) => {
    acc[id] = {
      target: null,
      intersectionRatio: 0
    }
    return acc
  }, {})

  let anchorId

  const determineCurrentAnchor = () => {
    let newAnchorId
    if (anchorId) {
      newAnchorId = anchorId
      for (const id of ids) {
        const { target: newAnchorTarget, intersectionRatio: newAnchorIntersectionRatio } = intersectionMap[newAnchorId]
        const { target: currentItemTarget, intersectionRatio: currItemIntersectionRatio } = intersectionMap[id]
        if (
          // when you scroll so that the current anchor element gets hidden
          (!newAnchorIntersectionRatio && currItemIntersectionRatio) ||
          // If the anchor element is still visible,
          // but another element that's above it is visible too,
          // we select the element that's closer to the top of the document
          (newAnchorIntersectionRatio && currItemIntersectionRatio && newAnchorTarget.offsetTop > currentItemTarget.offsetTop)
        ) {
          newAnchorId = id
        }
      }
    } else {
      newAnchorId = ids[0]
    }

    if (newAnchorId !== anchorId) {
      anchorId = newAnchorId

      if (onAnchorChangedCallback) {
        onAnchorChangedCallback(anchorId)
      }
    }
  }

  let onAnchorChangedCallback = onAnchorChangeCb

  const intersectionCallback = (entries) => {
    entries.forEach((entry) => {
      const { intersectionRatio, target } = entry
      intersectionMap[target.id] = {
        target,
        intersectionRatio
      }
    })

    determineCurrentAnchor()
  }

  // eslint-disable-next-line no-undef
  const observer = new IntersectionObserver(intersectionCallback, {
    threshold
  })

  return (props) => {
    const { children } = props
    const navigate = useNavigate()

    const [isObserving, setIsObserving] = useState(false)

    const onAnchorChanged = useCallback((id) => {
      navigate(`#${id}`)
    })

    useEffect(() => {
      if (!onAnchorChangedCallback) {
        onAnchorChangedCallback = onAnchorChanged
      }
    }, [onAnchorChanged])

    useEffect(() => {
      if (!isObserving) {
        ids.forEach((id) => {
          const elem = document.getElementById(id)
          if (elem) {
            observer.observe(elem)
          } else {
            console.warn(`[WithUpdatingAnchorLink]: Element with id: ${id} not found`)
          }
        })
        setIsObserving(true)
      }
    }, [isObserving])

    return (
      <>
        {children}
      </>
    )
  }
}

export default updateAnchor
