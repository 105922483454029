export const findSelectedOption = (options, value, valueProp) => {
  if ([null, undefined]?.includes(value)) return
  return options?.find(opt => valueProp ? opt[valueProp] : opt.value === value)
}

export const findSelectedOptions = (options, values, valueProp) => (
  ([null, undefined]?.includes(values))
    ? undefined
    : values?.map((value) => findSelectedOption(options, value, valueProp))
)

export const findGroupedSelectedOption = (groups, value, valueProp) => (
  groups?.map(({ options }) => findSelectedOption(options, value, valueProp))
)

export const getFixedOptions = (options) => options
  ? options.filter(option => option.isFixed)
  : []
