import { useState } from 'react'
import T from 'prop-types'
import classnames from 'classnames'
import { map, ifElse } from 'ramda'

import { TabCard } from './TabCard'

import {
  buildCategories,
  filterListByCategory,
  setActive,
  isCategory
} from './helpers'

import './Tabs.less'

const Tabs = ({ list, className, allCategoriesLabel }) => {
  const [categories, setCategories] = useState(
    buildCategories(list, allCategoriesLabel)
  )

  const onFilter = category =>
    setCategories(
      map(ifElse(isCategory(category), setActive(true), setActive(false)))
    )

  return (
    <div className={classnames('tabs', className)}>
      <div className='tabs__categories'>
        {categories.map(({ category, isActive }) => (
          <div
            key={category}
            onClick={() => onFilter(category)}
            className={classnames('tabs__categories__category', {
              'tabs__categories__category--active': isActive
            })}
          >
            {category}
          </div>
        ))}
      </div>

      <div className='tabs__tab'>
        {filterListByCategory(list, categories, allCategoriesLabel).map(
          (item, i) => (
            <TabCard {...item} key={i} />
          )
        )}
      </div>
    </div>
  )
}

Tabs.defaultProps = {
  allCategoriesLabel: 'All'
}

Tabs.propTypes = {
  list: T.arrayOf(
    T.shape({
      category: T.string.isRequired,
      Icon: T.elementType.isRequired,
      title: T.string.isRequired,
      onClick: T.func.isRequired,
      text: T.string.isRequired
    })
  ),
  allCategoriesLabel: T.string
}
export { Tabs }
