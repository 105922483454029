import classnames from 'classnames'

import { Table, TableResponsiveWrapper } from '../../../misc'

import './IncomeTable.less'

const IncomeTable = ({ className, children, ...restProps }) => (
  <TableResponsiveWrapper>
    <Table fixedLayout className={classnames('income-table', className)} {...restProps}>
      {children}
    </Table>
  </TableResponsiveWrapper>
)

export { IncomeTable }
