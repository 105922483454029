import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import constants from '@elenfs/elen-constants'

import { P1ImportForm } from './P1ImportForm'
import { Toastr } from '../../misc'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

import connectP1Gql from '../../../graphql/mutations/dataImport/connectP1.graphql'
import disconnectP1Gql from '../../../graphql/mutations/dataImport/disconnectP1.graphql'
import p1ConnectionGql from '../../../graphql/queries/dataImport/p1Connection.graphql'

const { DataImportTypes } = constants.dataImport

const connectToP1 = (platform) => useMutation(connectP1Gql, {
  update: (cache, { data }) => {
    try {
      const { connectP1 } = data

      cache.writeQuery({
        query: p1ConnectionGql,
        data: { dataImportP1Connection: connectP1 }
      })

      toast(<Toastr
        type='success'
        title={`Syncing with ${platform} in progress`}
        content='This page will be updated once done'
            />)
    } catch (error) {
      toast(<Toastr
        type='error'
        title={`Failed to connected to ${platform}`}
        content='Please try again later!'
            />)
      throw error
    }
  }
})

const disconnectP1 = (platform) => useMutation(disconnectP1Gql, {
  update: (cache, { data }) => {
    try {
      const { disconnectP1 } = data

      if (disconnectP1?.success) {
        cache.writeQuery({
          query: p1ConnectionGql,
          data: { dataImportP1Connection: null }
        })

        toast(<Toastr
          type='success'
          title={`Successfully disconnected from ${platform}`}
              />)
      }
    } catch (error) {
      toast(<Toastr
        type='error'
        title={`Failed to disconnect from ${platform}`}
        content='Please try again later!'
            />)
      throw error
    }
  }
})

const P1ImportFormContainer = ({ login, isActive, type, ...restProps }) => {
  const platform = DataImportPlatformLabels[DataImportTypes.P1]
  const isLoggedIn = !!(login && isActive)
  const password = isLoggedIn ? 'fake-pass' : null
  let useMutationResult

  if (!isLoggedIn) {
    useMutationResult = connectToP1(platform)
  } else {
    useMutationResult = disconnectP1(platform)
  }

  const [mutate, mutationState] = useMutationResult

  return (
    <P1ImportForm {...{
      isLoggedIn,
      mutate,
      loading: mutationState.loading,
      login,
      password,
      ...restProps
    }}
    />
  )
}

export { P1ImportFormContainer }
