export { default as ChevronLeftIcon } from './ChevronLeft'
export { default as ChevronRightIcon } from './ChevronRight'
export { default as UserDark } from './UserDark'
export { default as Trash } from './Trash'
export { default as PencilIcon } from './Pencil'
export { default as Close } from './Close'

// TODO: use consistent icon naming.
// Not 'Close', but 'CloseIcon' etc.
export { default as TimeIcon } from './Time'
export { default as CloseModal } from './CloseModal'
export { default as PlusIcon } from './Plus'
export { PlusBigIcon } from './PlusBig'
export { default as SuccessIcon } from './Success'
export { CloseIconBig } from './CloseIconBig'
export { PercentIcon } from './Percent'
export { default as CalendarIcon } from './Calendar'
export { CheckboxIcon } from './Checkbox'
export { RadioIcon } from './Radio'
export { default as CloseModalIcon } from './CloseModal'
export { PadlockIcon } from './Padlock'
export { default as DownloadIcon } from './Download'
export { default as CommentIcon } from './Comment'
export { default as ExclamationErrorIcon } from './ExclamationError'
export { default as RetryIcon } from './Retry'
export { default as EnvelopeIcon } from './Envelope'
export { default as DropdownIcon } from './Dropdown'
export { default as MenuDotsIcon } from './MenuDots'
export { default as CloseXIcon } from './CloseX'
export { default as MenuIcon } from './Menu'
export { default as LogoIcon } from './Logo'
export { Lock as LockIcon } from './Lock'
export { BranchArrows as BranchArrowsIcon } from './BranchArrows'
export { File as FileIcon } from './FileIcon'
export { Users as UsersIcon } from './Users'
export { default as UserIcon } from './User'
export { default as PhoneIcon } from './Phone'
export { PostBoxIcon } from './PostBox'
export { MobilePhoneIcon } from './MobilePhone'
export { Check as CheckIcon } from './Check'
export { Launcher as LauncherIcon } from './Launcher'
export { PrintIcon } from './Print'
export { ReportIcon } from './Report'
export { WithdrawalIcon } from './WithDrawal'
export { ContributionIcon } from './Contribution'
export { ChartIcon } from './Chart'
export { PoliciesIcon } from './Policies'
export { CakeIcon } from './Cake'
export { DocumentNotFoundIcon } from './DocumentNotFound'
export { ShapeHorizontal as ShapeHorizontalIcon } from './ShapeHorizontal'
export { ShapeVertical as ShapeVerticalIcon } from './ShapeVertical'
export { default as StaplerIcon } from './Stapler'
export { TasksIcon } from './Tasks'
export { ShapeVerticalStripesIcon } from './ShapeVerticalStripes'
export { FlagIcon } from './FlagIcon'
export { SpinnerIcon } from './SpinnerIcon'
export { ShapeHorizontal2 } from './ShapeHorizontal2'
export { DocumentIcon } from './DocumentIcon'
export { PolicyIcon } from './PolicyIcon'
export { ReviewIcon } from './ReviewIcon'
export { GmailIcon } from './GmailIcon'
export { OutlookIcon } from './OutlookIcon'
export { TimeRoundedIcon } from './TimeRoundedIcon'
export { default as HomeIcon } from './Home'
export { PinIcon } from './PinIcon'
export { SuitCaseIcon } from './SuitCaseIcon'
export { HouseIcon } from './HouseIcon'
export { WorkflowIcon } from './WorkflowIcon'
export { InfoIcon } from './InfoIcon'
export { AccountIcon } from './Account'
export { IncreaseIcon } from './Increase'
export { DecreaseIcon } from './Decrease'
export { Phone2Icon } from './Phone2'
export { SaveIcon } from './SaveIcon'
export { SendIcon } from './SendIcon'
export { CogWheelIcon } from './CogWheel'
export { AccountEditIcon } from './AccountEdit'
export { SearchIcon } from './SearchIcon'
export { AccountPlus } from './AccountPlus'
export { ListFull } from './ListFull'
export { Board } from './Board'
