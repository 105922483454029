import { useState, useEffect } from 'react'

import { CloseModalIcon } from './../../icons'
import { SidePanelPortal } from './SidePanelPortal'

import './SidePanel.less'

const SidePanel = props => {
  const {
    showPanel,
    title,
    closePanel,
    sidePanelContent: SidePanelContent,
    sidePanelContentProps
  } = props

  const [show, setShow] = useState(showPanel)

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Escape') {
        closePanel()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  /* Animation in and out
  We use timeout here to make sure the animating out is done before removing the html elements
  The animation time is set to 300 ms in the less file as well
  If a change in the transition is made, make sure to change it in both files */
  useEffect(() => {
    if (showPanel) {
      setShow(true)
    } else {
      setTimeout(() => setShow(false), 300)
    }
  }, [showPanel])

  return (
    <>
      {show && (
        <SidePanelPortal showPanel={showPanel}>
          <div className='side-panel-wrapper__backdrop' />

          <div className='side-panel-wrapper__side_panel'>
            <div className='side-panel-wrapper__side_panel__header'>
              {title}
              <CloseModalIcon onClick={closePanel} />
            </div>
            <div className='side-panel-wrapper__side_panel__content'>
              <SidePanelContent
                {...sidePanelContentProps}
                closePanel={closePanel}
              />
            </div>
          </div>
        </SidePanelPortal>
      )}
    </>
  )
}

export { SidePanel }
