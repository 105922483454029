import { clone, tryCatch, filter, identity, isEmpty } from 'ramda'
import { TaskStatuses } from '@elenfs/elen-constants'

import taskGql from '../graphql/queries/tasks/task.graphql'
import tasksGql from '../graphql/queries/tasks/tasks.graphql'
import clientTasksGql from '../graphql/queries/tasks/clientTasks.graphql'

const filterOutId = (list = [], id) => list.filter(item => item.id !== id)
const findById = (list = [], id) => list.find(item => item.id === id)

const isTaskMatchingFilter = (task, variables) => {
  const { clientId, assignedTo, status, type } = variables
  const taskClientId = task?.client?.id
  const taskAssignedTo = task?.assignedTo?.id

  const clientMatch = clientId ? taskClientId === clientId : true
  const assignedToMatch = assignedTo ? taskAssignedTo === assignedTo : true
  const typeMatch = type ? task.type === type : true
  const statusMatch = status
    ? isEmpty(status)
      ? true
      : status.includes(task.status)
    : true

  return clientMatch && assignedToMatch && typeMatch && statusMatch
}

const updateTasks = (tasks, task, variables, isUpdate) => {
  let updatedTasks = [...tasks]
  let isFiltering = false

  if (variables) {
    variables = filter(identity, variables)

    isFiltering = Object.keys(variables).length
  }

  const isTaskCompleted = task.status === TaskStatuses.COMPLETED
  const isCompletedFilter = variables?.status?.includes(TaskStatuses.COMPLETED)

  const removeTaskIfCompleted = isTaskCompleted && !isCompletedFilter

  if (!isUpdate && (!isFiltering || isTaskMatchingFilter(task, variables))) {
    updatedTasks.push(task)
  }

  if (isUpdate && (!isTaskMatchingFilter(task, variables) || removeTaskIfCompleted)) {
    updatedTasks = filterOutId(tasks, task.id)
  }

  return updatedTasks
}

const resetTasksCache = cache => {
  cache.evict({
    id: 'ROOT_QUERY',
    field: 'tasks'
  })
  cache.gc()
}

const readQuery = ({ cache, gql, variables }) =>
  cache.readQuery({ query: gql, variables }) || {}

const writeQuery = ({ cache, gql, variables, key, value }) =>
  cache.writeQuery({
    query: gql,
    variables,
    data: { [key]: value }
  })

export const tryCatchTasksCacheUpdate = func => {
  tryCatch(func, error =>
    console.log('Error occurred while updating Tasks cache', error)
  )()
}

export const handleTaskCacheOnRemove = (cache, id) => {
  const variables = { id }
  const gql = taskGql
  const { task } = readQuery({ cache, gql, variables })

  if (task) {
    writeQuery({ cache, gql, variables, key: 'task', value: null })

    if (task?.subtasks?.length) {
      task.subtasks.forEach(({ id }) => {
        const variables = { id }
        const { task: subtask } = readQuery({ cache, gql, variables })

        if (subtask) {
          writeQuery({ cache, gql, variables, key: 'task', value: null })
        }
      })
    }
  }
}

export const handleTasksCacheOnRemoveTask = (cache, id, variables) => {
  let clientId
  const gql = tasksGql
  const { tasks } = readQuery({ cache, gql, variables })

  if (tasks) {
    clientId = findById(tasks, id)?.client?.id
    resetTasksCache(cache)
    writeQuery({
      cache,
      gql,
      variables,
      key: 'tasks',
      value: filterOutId(tasks, id)
    })
  }

  return clientId
}

export const handleClientTasksOnRemoveTask = (cache, id, clientId) => {
  const variables = { clientId }
  const gql = clientTasksGql
  const { clientTasks } = readQuery({ cache, gql, variables })

  if (clientTasks) {
    writeQuery({
      cache,
      gql,
      variables,
      key: 'clientTasks',
      value: filterOutId(clientTasks, id)
    })
  }
}

export const updateTasksCacheOnCreateTask = (cache, newTask, variables = []) => {
  const gql = tasksGql
  const { tasks } = readQuery({ cache, gql, variables })

  if (tasks) {
    const updatedTasks = updateTasks(tasks, newTask, variables)

    resetTasksCache(cache)
    writeQuery({
      cache,
      gql,
      key: 'tasks',
      variables,
      value: updatedTasks
    })
  }
}

export const updateClientTasksCacheOnCreateTask = (cache, newTask) => {
  const gql = clientTasksGql
  const variables = { clientId: newTask?.client?.id }
  const { clientTasks } = readQuery({ cache, gql, variables })

  if (clientTasks) {
    resetTasksCache(cache)
    writeQuery({
      cache,
      gql,
      variables,
      key: 'clientTasks',
      value: [...clientTasks, newTask]
    })
  }
}

export const updateTasksCacheOnCreateSubtask = (cache, newSubtask, variables) => {
  const gql = tasksGql
  const { parentId } = newSubtask || {}
  const { tasks } = readQuery({ cache, gql, variables })

  if (tasks) {
    const copiedTasks = clone(tasks)
    const task = findById(copiedTasks, parentId)
    task.subtasks.push(newSubtask)
    writeQuery({ cache, gql, key: 'tasks', value: copiedTasks })
  }
}

export const updateClientTasksCacheOnCreateSubtask = (cache, newSubtask) => {
  const gql = clientTasksGql
  const variables = { clientId: newSubtask?.parentTask?.client?.id }
  const { parentId } = newSubtask || {}
  const { clientTasks } = readQuery({ cache, gql, variables })

  if (clientTasks) {
    const copiedClientTasks = clone(clientTasks)
    const clientTask = findById(copiedClientTasks, parentId)

    const subAlreadyExists = !!findById(clientTask?.subtasks, newSubtask.id)
    if (!subAlreadyExists && clientTask) clientTask.subtasks.push(newSubtask)

    writeQuery({
      cache,
      gql,
      variables,
      key: 'clientTasks',
      value: copiedClientTasks
    })
  }
}

export const updateTasksCacheOnRemoveSubtask = (cache, id, parentId, variables) => {
  const gql = tasksGql
  const { tasks } = readQuery({ cache, gql, variables })

  if (tasks) {
    const copiedTasks = clone(tasks)
    const task = findById(copiedTasks, parentId)
    task.subtasks = filterOutId(task.subtasks, id)

    writeQuery({
      cache,
      gql,
      key: 'tasks',
      value: copiedTasks
    })
  }
}

export const updateClientTasksCacheOnRemoveSubtask = (
  cache,
  id,
  parentId,
  clientId
) => {
  const gql = clientTasksGql
  const variables = { clientId }

  const { clientTasks } = readQuery({ cache, gql, variables })

  if (clientTasks) {
    const copiedClientTasks = clone(clientTasks)
    const clientTask = findById(copiedClientTasks, parentId)
    clientTask.subtasks = filterOutId(clientTask.subtasks, id)

    writeQuery({
      cache,
      gql,
      variables,
      key: 'clientTasks',
      value: copiedClientTasks
    })
  }
}

export const updateTasksCacheOnUpdateTask = (cache, updateTask, variables = []) => {
  const gql = tasksGql
  const { tasks } = readQuery({ cache, gql, variables })

  if (tasks) {
    const updatedTasks = updateTasks(tasks, updateTask, variables, true)

    resetTasksCache(cache)
    writeQuery({
      cache,
      gql,
      key: 'tasks',
      variables,
      value: updatedTasks
    })
  }
}

export const updateClientTasksCacheOnUpdateTask = (cache, updateTask) => {
  const isCompleted = updateTask?.status === TaskStatuses.COMPLETED

  const gql = clientTasksGql
  const clientId = updateTask?.client?.id
  const variables = { clientId }
  const { clientTasks } = readQuery({ cache, gql, variables })

  if (clientTasks) {
    const clientTask = !!findById(clientTasks, updateTask.id)
    if (clientTask && !isCompleted) {
      return null
    }

    const value = isCompleted
      ? filterOutId(clientTasks, updateTask?.id)
      : [...clientTasks, updateTask]

    writeQuery({
      cache,
      gql,
      variables,
      key: 'clientTasks',
      value
    })
  }
}

export const updateClientTasksCacheOnOrder = (cache, orderedTasks) => {
  const gql = clientTasksGql
  const clientId = orderedTasks[0]?.client?.id
  const variables = { clientId }
  const { clientTasks } = readQuery({ cache, gql, variables })

  if (clientTasks) {
    writeQuery({
      cache,
      gql,
      variables,
      key: 'clientTasks',
      value: orderedTasks
    })
  }
}
