import { useState, useCallback, useEffect } from 'react'
import { clone, isEmpty } from 'ramda'

import { sortData } from '../../../helpers/sort'
import { useLocalStorageUserSettings } from '../../../hooks'

export const useTableSort = ({ tableHeaders = [], data = [], persistKeyLS }) => {
  const { userSettingData, persistUserSettingsData } = useLocalStorageUserSettings(
    persistKeyLS
  )
  let initialTableHeaders = clone(tableHeaders)

  if (persistKeyLS) {
    if (userSettingData && !isEmpty(userSettingData)) {
      initialTableHeaders = userSettingData
    }
  }

  const [sortedData, setSortedData] = useState([])
  const [headersState, setHeadersState] = useState(initialTableHeaders)

  const onResetSort = useCallback(
    e => {
      e.stopPropagation()
      setHeadersState(current =>
        current.map(header => ({
          ...header,
          isActive: false,
          isAscending: true
        }))
      )

      if (persistKeyLS) {
        persistUserSettingsData([])
      }

      setSortedData(data)
    },
    [data]
  )

  const onSort = useCallback(label => {
    setHeadersState(current => {
      const selectedHeader = current.find(header => header.label === label)

      if (selectedHeader.isActive) {
        selectedHeader.isAscending = !selectedHeader.isAscending
      }
      selectedHeader.isActive = true

      setSortedData(current =>
        sortData(
          current,
          selectedHeader.propPath,
          selectedHeader.secondaryPropPath,
          selectedHeader.isAscending
        )
      )

      current = current.map(header => ({
        ...header,
        isActive: false,
        isAscending: true
      }))
      const selectedHeaderIndex = current.findIndex(
        header => header.label === label
      )
      current[selectedHeaderIndex] = selectedHeader

      if (persistKeyLS) {
        persistUserSettingsData([...current])
      }

      return [...current]
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      const activeHeader = headersState.find(({ isActive }) => isActive)

      if (activeHeader) {
        setSortedData(sortData(
          data,
          activeHeader.propPath,
          activeHeader.secondaryPropPath,
          activeHeader.isAscending
        ))
      } else {
        setSortedData(data)
      }
    }
  }, [data])

  if (isEmpty(data)) {
    return { sortedData: data, headersState, onSort, onResetSort }
  }

  return { sortedData, headersState, onSort, onResetSort }
}
