import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { withClientUser } from './../../../hoc/container'

import { Card, Button, Toastr } from '../../../misc'
import inviteClientGql from '../../../../graphql/mutations/client/inviteClient.graphql'

import './ClientPortalAccess.less'

const ClientPortalAccess = (props) => {
  const { clientEmail, clientId, inviteClient, isClientUser } = props

  const descriptionText = isClientUser
    ? 'Client registered successfully.'
    : clientEmail
      ? 'Invite will be sent to the user’s registered email address.'
      : 'You need to provide an email for this client first.'

  return (
    <Card>
      <div className='client-portal-access text-content'>
        <div className='client-notes-card-content__header'>
          <Card.Heading as='h3'>
            Client portal access
          </Card.Heading>
        </div>

        <div className='client-portal-access__description'>
          By logging into the client portal, clients can see their personal data, documents and policy information. This
          invitation will allow them to create their account.
        </div>

        <Button
          disabled={!clientEmail || isClientUser}
          type='button'
          color={Button.Colors.BLUE}
          onClick={async () => {
            await inviteClient(clientId)

            toast(
              <Toastr type='success' title='Client successfully invited' content='Email has been sent to the client' />
            )
          }}
        >
          Send invite
        </Button>

        <div className='client-portal-access__button-description text-description text-small'>
          {descriptionText}
        </div>
      </div>
    </Card>
  )
}

ClientPortalAccess.propTypes = {
  clientEmail: PropTypes.string,
  clientId: PropTypes.string
}

const ClientPortalAccessContainer = (props) => {
  const [inviteClientMutation] = useMutation(inviteClientGql)
  const inviteClient = (clientId) => inviteClientMutation({ variables: { clientId, origin: window.location.origin } })

  return <ClientPortalAccess {...props} inviteClient={inviteClient} />
}

export default withClientUser()(ClientPortalAccessContainer)
