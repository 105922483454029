import PropTypes from 'prop-types'

import Button from '../Button'
import { CloseModalIcon } from '../../icons'

const ModalCloseButton = ({ onClick }) => (
  <Button
    className='modal__close-button'
    onClick={onClick}
  >
    <CloseModalIcon />
  </Button>
)

ModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export { ModalCloseButton }
