import T from 'prop-types'

import GroupFormFields from '../../misc/GroupedFormField'
import { SelectField } from '../../form/fields'
import { optionsType } from '../../../helpers/propTypes'

const ClientRelationshipsFormInputRow = ({
  index,
  companyClientOptions,
  relationshipOptions,
  disabled,
  relationships
}) => (
  <div className='relationship-input-row'>
    <GroupFormFields>
      <SelectField
        name={`relationships.${index}.relatedClientId`}
        label='Name'
        optionsToResolve={[...relationships.map(item => item?.relatedClientId)].filter(Boolean)}
        required
        inputProps={{
          async: true,
          isDisabled: disabled,
          asyncProps: {
            labelProp: 'fullName',
            valueProp: 'id'
          }
        }}
      />

      <SelectField
        name={`relationships.${index}.type`}
        label='Relationship type'
        options={relationshipOptions}
        required
        inputProps={{ isDisabled: disabled }}
      />

    </GroupFormFields>
  </div>
)

ClientRelationshipsFormInputRow.propTypes = {
  index: T.number.isRequired,
  companyClientOptions: optionsType().isRequired,
  relationshipOptions: optionsType().isRequired
}

export default ClientRelationshipsFormInputRow
