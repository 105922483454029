import { SendMessageModalForm } from './SendMessageModalForm'
import { Modal } from '..'

import './SendMessageModal.less'

const SendMessageModal = ({ formProps, ...otherProps }) => (
  <Modal className='messages-modal' {...otherProps}>
    <Modal.Heading>New Message</Modal.Heading>
    <SendMessageModalForm {...formProps} close={otherProps.close} />
  </Modal>
)

export { SendMessageModal }
