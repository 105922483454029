import moment from 'moment'
import {
  SubscriptionStatuses,
  ACTIVE_SUBSCRIPTION_STATUSES
} from '@elenfs/elen-constants'
import { formatMoney } from './policy'
import {
  LicenseTypeLabels,
  SubscriptionIntervalsLabels
} from './../components/admin/AccountOverview/SubscriptionPlan/const'
import { CurrenciesLabels } from './enumLabels/policy'

export const getTrialDaysRemaining = ({
  status,
  trialStartedAt,
  trialPeriodDays
}) => {
  if (status !== SubscriptionStatuses.TRIALING) {
    return 0
  }

  const startedAt = moment(trialStartedAt)
  const diffDays = moment().diff(startedAt, 'days')
  const daysRemaining = trialPeriodDays - diffDays

  return daysRemaining > 0 ? daysRemaining : 0
}

export const getBillingDateAfterTrial = ({
  status,
  trialStartedAt,
  trialPeriodDays
}) => {
  if (status !== SubscriptionStatuses.TRIALING) {
    return undefined
  }

  return moment(trialStartedAt)
    .add(trialPeriodDays, 'days')
    .format('DD MMM YYYY')
}

export const isActiveSubscription = status =>
  ACTIVE_SUBSCRIPTION_STATUSES.includes(status)

export const buildSubscriptionOptions = ({ prices }) =>
  prices.map(({ id, interval, intervalCount, price }) => {
    const multipleIntervals = intervalCount > 1
    const intervalText = `per ${
      multipleIntervals ? '' + intervalCount + ' ' : ''
    }${SubscriptionIntervalsLabels[interval]}${multipleIntervals ? 's' : ''}`

    const moneyText = formatMoney(price)

    return {
      label: `${moneyText} ${intervalText}`,
      value: id
    }
  })

export const buildSubscriptionCard = ({ prices }) =>
  prices.map(({ id, interval, price }) => {
    const billingText = `Billed every ${interval.toLowerCase()}.`

    return {
      id,
      intervalText: LicenseTypeLabels[interval],
      moneySign: CurrenciesLabels[price.currency],
      moneyValue: price.amount,
      billingText
    }
  })
