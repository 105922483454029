import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { ClientDetailsBasePage } from './ClientDetailsBasePage'
import { withClientUser } from './../../hoc/container'
import { Toastr } from '../../misc'

import toggleClientFactFindGql from '../../../graphql/mutations/client/toggleClientFactFind.graphql'
import requestClientFactFindEditGql from '../../../graphql/mutations/client/requestClientFactFindEdit.graphql'
import submitClientFactFindGql from '../../../graphql/mutations/client/submitClientFactFind.graphql'

const ClientDetailsBasePageContainer = props => {
  const {
    client,
    isClientUser,
    isUnActiveClientLoading,
    isUnActiveClientError,
    refetchClientDetails
  } = props
  const [toggleClientFactFindMutation] = useMutation(toggleClientFactFindGql, {
    variables: {
      id: client?.id
    },
    onCompleted: () => refetchClientDetails()
  })

  const [requestClientFactFindEditMutation] = useMutation(requestClientFactFindEditGql, {
    variables: {
      id: client?.id
    },
    onCompleted: () => {
      toast(<Toastr
        type={Toastr.Types.SUCCESS}
        title='Request to edit fact find sent to client'
            />)
    }
  })

  const [submitClientFactFindMutation] = useMutation(submitClientFactFindGql, {
    variables: {
      id: client?.id
    },
    onCompleted: () => {
      toast(<Toastr
        type={Toastr.Types.SUCCESS}
        title='Fact find submitted to financial adviser'
            />)
      refetchClientDetails()
    }
  })

  const submitClientFactFindMutationCallback = useCallback(
    async (confirmationFlag) => {
      if (confirmationFlag) {
        await submitClientFactFindMutation()
      }
    }, [submitClientFactFindMutation]
  )

  const clientUserProps = {
    isClientUser,
    loading: isUnActiveClientLoading,
    error: isUnActiveClientError
  }

  return (
    <ClientDetailsBasePage {...{
      toggleClientFactFindMutation,
      requestClientFactFindEditMutation,
      submitClientFactFindMutationCallback,
      clientUserProps,
      isFactFindEditable: client?.isFactFindEditable,
      ...props
    }}
    />
  )
}

const ClientDetailsBasePageContainerEnhanced = withClientUser()(ClientDetailsBasePageContainer)

export { ClientDetailsBasePageContainerEnhanced as ClientDetailsBasePageContainer }
