import T from 'prop-types'

export const Label = ({ children, ...rest }) => (
  <label {...rest}>
    {children}
  </label>
)

Label.propTypes = {
  className: T.string,
  children: T.node
}
