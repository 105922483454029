import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

export const useBlocker = (blocker, enabled = true) => {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!enabled) {
      return
    }

    const unblock = navigator?.block((action) => {
      const autoUnblockingTx = {
        ...action,
        retry () {
          unblock()
          action.retry()
        }
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, enabled])
}
