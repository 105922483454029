import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import dateSchema from '../helpers/dateSchema'
import enumSchema from '../helpers/enumSchema'
import stringSchema from '../helpers/stringSchema'

const { ClientTitles, MaritalStatus, Gender } = constants.client

export default yup.object().shape({
  title: enumSchema(values(ClientTitles))
    .label('Title'),

  firstName: stringSchema()
    .label('Personal details section: First name')
    .required(),

  middleName: stringSchema()
    .label('Middle name'),

  lastName: stringSchema()
    .label('Personal details section: Last name')
    .required(),

  knownAs: stringSchema()
    .label('Known as'),

  gender: enumSchema(values(Gender))
    .label('Gender'),

  maritalStatus: enumSchema(values(MaritalStatus))
    .label('Marital status'),

  address1: stringSchema()
    .label('Address 1'),

  address2: stringSchema()
    .label('Address 2'),

  city: stringSchema()
    .label('City'),

  postCode: stringSchema()
    .label('Post Code'),

  homeTel: stringSchema()
    .label('Home Tel'),

  workTel: stringSchema()
    .label('Work Tel'),

  mobile: stringSchema()
    .label('Mobile'),

  email: stringSchema()
    .label('Personal details section: Email')
    .email(),

  dateOfBirth: dateSchema()
    .label('Date of birth'),

  placeOfBirth: stringSchema()
    .label('Place of birth')
})
