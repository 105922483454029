import './TaskKanbanColumnHeader.less'

import { Heading, Text, Button } from '../../../misc'
import { PlusBigIcon } from '../../../icons'

const TaskKanbanColumnHeader = ({
  title,
  tasksCount,
  onCreateColumnTask
}) => {
  return (
    <div className='kanban-board__column__header'>
      <div className='kanban-board__column__header__info'>
        <Heading>{title}</Heading>
        <Text>|</Text>
        <Text>{tasksCount || 0} tasks</Text>
      </div>

      {title?.toLowerCase() !== 'completed' && (
        <div className='kanban-board__column__header__action'>
          <Button
            className='kanban-board__column__header__action--create-new'
            onClick={onCreateColumnTask}
          >
            <PlusBigIcon />
          </Button>
        </div>
      )}
    </div>
  )
}

export { TaskKanbanColumnHeader }
