import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { WaitingScreen } from '../WaitingScreen'

const EmailIntegrationWaitingPage = ({ platform }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const stateParam = searchParams.get('state')
  const codeParam = searchParams.get('code')

  useEffect(() => {
    if (stateParam && codeParam) {
      const { redirectURL } = JSON.parse(
        window.atob(decodeURIComponent(stateParam))
      )
      return window.location.assign(`${redirectURL}?code=${codeParam}&platform=${platform}`)
    } else {
      navigate('/')
    }
  }, [location])

  return <WaitingScreen />
}

export { EmailIntegrationWaitingPage }
