import Table from './Table'
import TableHeader from './TableHeader'
import TableHeaderCell from './TableHeaderCell'
import TableBody from './TableBody'
import TableRow from './TableRow'
import TableCell from './TableCell'
import TableActions from './TableActions'

Table.Header = TableHeader
Table.HeaderCell = TableHeaderCell
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell
Table.Actions = TableActions

export default Table
