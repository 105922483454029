const CloseIcon = ({ fill = '#E2E7EE', backgroundFill = '#fff', ...props }) => {
  return (
    <svg width='29px' height='29px' viewBox='0 0 29 29' {...props}>
      <g fill='none' fillRule='evenodd'>
        <circle cx='14.5' cy='14.5' r='14.5' fill={fill} />
        <circle cx='14.5' cy='14.5' r='11.5' fill={backgroundFill} />
        <path
          fill={fill}
          transform='rotate(45 14.5 14.5)'
          d='M14.9797685 6.96c.5552577 0 1.005383.45131445 1.005383 1.00111949L15.985 13.243l5.0538805.0003333c.552903 0 1.0011195.4527555 1.0011195 1.0053831v.7310521c0 .5552577-.4513144 1.005383-1.0011195 1.005383L15.985 15.985l.0001515 5.0538805c0 .552903-.4527555 1.0011195-1.005383 1.0011195h-.7310521c-.5552577 0-1.0053831-.4513144-1.0053831-1.0011195L13.243 15.985l-5.28188051.0001515c-.55290303 0-1.00111949-.4527555-1.00111949-1.005383v-.7310521c0-.5552577.45131445-1.0053831 1.00111949-1.0053831L13.243 13.243l.0003333-5.28188051c0-.55290303.4527555-1.00111949 1.0053831-1.00111949h.7310521z'
        />
      </g>
    </svg>
  )
}

export default CloseIcon
