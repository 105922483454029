import constants from '@elenfs/elen-constants'

import { Form } from '../../../form'
import { PageDivider } from '../../../misc'
import { FormGroup } from '../../../form/Form/FormGroup'
import { PolicyHoldersSection } from '../PolicyHoldersSection'
import { RadioGroupField, InputField, SelectField } from '../../../form/fields'

import { buildBooleanSelectBoxOptions } from '../../../../helpers/form'
import { buildSelectOptions } from './../../../form/fields/SelectField/helpers'
import {
  PolicyProvidersLabels,
  PolicyStatusLabels,
  PolicyCategoriesLabels
} from '../../../../helpers/enumLabels/policy'

const {
  PolicyProviders,
  PolicyStatuses,
  PolicyCategories
} = constants.policy.common

const SharedPolicyFormFields = props => {
  const {
    currentClient,
    policyTypeLabel,
    policyTypeName,
    policyTypeOptions,
    planStatusOptions
  } = props

  return (
    <>
      <div className='policy-type-form__row policy-type-form__row--flex'>
        <RadioGroupField
          inputId='policy-type-form-is-new-radio-input-is-new'
          options={buildSelectOptions(
            PolicyStatuses,
            PolicyStatusLabels
          ).reverse()}
          name='status'
          groupLabel='New policy'
          isTextButton
          required
        />

        <Form.Divider orientation={PageDivider.Orientations.VERTICAL} />

        <RadioGroupField
          inputId='policy-type-form-is-replacement-radio-input-is-risk'
          options={buildBooleanSelectBoxOptions()}
          name='isHighRisk'
          groupLabel='Pre-check / high risk'
          isTextButton
          required
        />

        <Form.Divider orientation={PageDivider.Orientations.VERTICAL} />

        <RadioGroupField
          inputId='policy-type-form-is-replacement-radio-input-is-replace'
          options={buildBooleanSelectBoxOptions()}
          name='isReplacementBusiness'
          groupLabel='Replacement business'
          isTextButton
          required
        />
      </div>

      <FormGroup label='Details'>
        <div className='policy-type-form__row policy-type-form__row--4'>
          <SelectField
            name='category'
            label='Category'
            options={buildSelectOptions(
              PolicyCategories,
              PolicyCategoriesLabels
            )}
            inputProps={{ isDisabled: true }}
          />

          <SelectField
            clearable
            name={policyTypeName}
            label={policyTypeLabel}
            options={policyTypeOptions}
            required
          />

          <InputField name='number' label='Policy number' />
        </div>

        <div className='policy-type-form__row policy-type-form__row--3-1'>
          <InputField name='description' label='Description' />
        </div>

        <div className='policy-type-form__row policy-type-form__row--3-1'>
          <div className='policy-type-form__row policy-type-form__row--2'>
            <SelectField
              clearable
              name='planStatus'
              label='Plan status'
              options={planStatusOptions}
            />

            <SelectField
              clearable
              name='provider'
              label='Provider'
              options={buildSelectOptions(
                PolicyProviders,
                PolicyProvidersLabels
              )}
            />
          </div>
        </div>

        <Form.Divider className='policy-type-form__divider' />

        <div className='policy-type-form__row policy-type-form__row--3-1'>
          <div className='policy-type-form__row policy-type-form__row--2'>
            <PolicyHoldersSection {...{ currentClient }} />
          </div>
        </div>
      </FormGroup>
    </>
  )
}

export { SharedPolicyFormFields }
