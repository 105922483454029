const TasksIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M7.776 16.194a.743.743 0 011.071.04.782.782 0 01.044 1.072l-.044.046-2.985 2.955a.75.75 0 01-.535.24.675.675 0 01-.536-.24L3.222 18.63a.865.865 0 010-1.158.743.743 0 011.072.04l1.033 1.118zm12.683 1.819c.436 0 .789.34.789.76s-.353.76-.789.76h-9.067c-.436 0-.789-.34-.789-.76s.353-.76.789-.76zM7.776 10.112a.743.743 0 011.071.04.782.782 0 010 1.118l-2.985 2.955a.75.75 0 01-.535.24.675.675 0 01-.536-.24l-1.569-1.678a.865.865 0 010-1.158.743.743 0 011.072.04l1.033 1.118zm12.683 1.818c.436 0 .789.34.789.76s-.353.76-.789.76h-9.067c-.436 0-.789-.34-.789-.76s.353-.76.789-.76zM8.317 3.313a.76.76 0 01.764.754v4.068a.72.72 0 01-.764.753H3.766A.723.723 0 013 8.135V4.067a.76.76 0 01.765-.754zM7.552 4.82H4.53V7.38h3.02V4.82zm12.907 1.028c.436 0 .789.34.789.76s-.353.76-.789.76h-9.067c-.436 0-.789-.34-.789-.76s.353-.76.789-.76z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { TasksIcon }
