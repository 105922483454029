import PropTypes from 'prop-types'
import { compose, ascend, descend, prop, sort, groupBy } from 'ramda'
import moment from 'moment'

import AuditLogEntryItem from '../../auditLogEntry/AuditLogEntryItem'
import NoAuditLogEntries from '../NoAuditLogEntries'
import { withNoItems } from '../../hoc/pure'
import { Modal } from '../../misc'
import { AuditLogEntryModal } from './../AuditLogEntryModal'

import './AuditLogEntriesActivityList.less'

const AuditLogEntriesActivityList = ({ auditLogEntries, refetch }) => {
  const sortByDate = (isAscending = false) => (isAscending ? ascend : descend)(prop('date'))
  const groupByMonth = groupBy(item =>
    moment(item.date).format('YYYY-MM-01')
  )
  const groupedEntries = groupByMonth(sort(sortByDate(), auditLogEntries))

  return (
    <div className='audit-log-entries-activity'>
      {Object.entries(groupedEntries).map((item) => (
        <div className='audit-log-entries-activity-separator' key={item[0]}>
          <div className='audit-log-entry-activity-month audit-log-entry-page-block-1'>
            <div className='month-text'>
              <span>{`${moment(item[0]).format('MMMM')} ${moment(item[0]).format('YYYY')}`}</span>
            </div>
          </div>
          <div className='audit-log-entries-activity-items-container'>
            {item[1].map((entry) => (
              <div className='audit-log-entries-activity-item-holder audit-log-entry-page-block-2' key={entry.id}>
                <div className='regular-text audit-log-entry-page-block-2__1 audit-log-entry-activity-time'>
                  <span className='audit-log-entry-activity-time-date'>{moment(entry.date).format('DD MMM YYYY')}</span>
                  <br />
                  <span className='audit-log-entry-activity-time__separator'> - </span>
                  <span className='audit-log-entry-activity-time-clock'>{moment(entry.date).format('HH:mm')}</span>
                </div>
                <Modal.Trigger
                  as={AuditLogEntryItem}
                  auditLogEntry={entry}
                  showExpanded
                  modal={AuditLogEntryModal}
                  modalProps={{ auditLogEntry: entry, refetchLogEntries: refetch }}
                  className='audit-log-entry-page-block-2__2'
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

AuditLogEntriesActivityList.propTypes = {
  auditLogEntries: PropTypes.arrayOf(PropTypes.object).isRequired
}

const AuditLogEntriesActivityListEnhanced = compose(
  withNoItems(('auditLogEntries'), NoAuditLogEntries)
)(AuditLogEntriesActivityList)

export default AuditLogEntriesActivityListEnhanced
