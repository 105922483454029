import { Modal } from './Modal'
import { MODAL_CONTAINER_ID } from './constants'

import { ModalHeading } from './ModalHeading'
import { ModalTrigger } from './ModalTrigger'

Modal.Heading = ModalHeading
Modal.Trigger = ModalTrigger

export { MODAL_CONTAINER_ID, Modal }
