import { TaskCommentForm } from '../TaskCommentForm'
import { PageDivider, Heading } from '../../misc'

import './TaskCommentList.less'

const TaskCommentList = ({
  taskComments,
  currentUser,
  taskId
}) => {
  const isEmpty = !(taskComments && taskComments.length)

  return (
    <div className='task-comment-list'>
      <Heading as='h3' className='task-comment-list__title'>Comments</Heading>
      {!isEmpty && taskComments.map(comment => {
        return (
          <div key={comment?.id}>
            <TaskCommentForm {...{
              comment,
              taskId,
              currentUser
            }}
            />
            <PageDivider />
          </div>
        )
      })}

      <TaskCommentForm {...{
        className: 'create-new-comment',
        key: 'new-comment',
        currentUser,
        placeholder: 'Add a new comment...',
        taskId
      }}
      />
    </div>
  )
}

export { TaskCommentList }
