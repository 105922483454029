import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import T from 'prop-types'

import ConnectToPolicy from './ConnectToPolicy'
import { getPolicyTypeByCategory } from '../../../../helpers/policy'
import updateClientDocumentGql from '../../../../graphql/mutations/clientDocument/updateClientDocument.graphql'
import clientDocumentsGql from '../../../../graphql/queries/clientDocument/clientDocuments.graphql'

const ConnectToPolicyContainer = ({
  clientDocument,
  policies,
  policyId,
  ...restProps
}) => {
  const policyOptions = []

  if (policies && policies.length > 0) {
    policies.forEach(item => {
      const policyType = getPolicyTypeByCategory(item)
      policyOptions.push({
        label: `${policyType} ${item.number ? `- ${item.number}` : ''}`,
        value: item.id
      })
    })
  }

  const [connectToPolicyMutation] = useMutation(updateClientDocumentGql, {
    update: (cache, { data }) => {
      try {
        const { updateClientDocument: document } = data
        const { clientDocuments, ...restCache } = cache.readQuery({ query: clientDocumentsGql })

        const shouldRemoveFromDocumentsListPolicyList = policyId

        cache.writeQuery({
          query: clientDocumentsGql,
          data: {
            ...restCache,
            clientDocuments: shouldRemoveFromDocumentsListPolicyList
              ? clientDocuments.filter(doc => doc.id !== document.id)
              : clientDocuments
          }
        })
      } catch (err) {
        console.log('err', err)
      }
    }
  })

  const connectToPolicy = useCallback(
    async (policyOption) => {
      const { error } = await connectToPolicyMutation({
        variables: {
          input: {
            id: clientDocument.id,
            policyId: policyOption ? policyOption.value : null
          }
        }
      })

      if (error) {
        throw error
      }
    }, [connectToPolicyMutation, policies]
  )

  return (
    <ConnectToPolicy
      connectToPolicy={connectToPolicy}
      policyOptions={policyOptions}
      clientDocument={clientDocument}
      {...restProps}
    />
  )
}

ConnectToPolicyContainer.propTypes = {
  clientDocument: T.object.isRequired,
  policies: T.array,
  className: T.string
}

export default ConnectToPolicyContainer
