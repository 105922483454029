
import { useState, useCallback, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { isEmpty } from 'ramda'

import { DropdownSelectionWithAsync } from './DropdownSelectionWithAsync'

import searchClientsGql from '../../../../graphql/queries/client/searchClientsByKeyword.graphql'
import clientListForClientPortalByIdsGql from '../../../../graphql/queries/client/clientListForClientPortalByIds.graphql'

// ? Enable commented lins if desire pulling more results
// ? on scroll, un-commend: pull-more-on-scroll

const DropdownSelectionWithAsyncContainer = ({
  onChange,
  inputProps,
  options,
  optionsToResolve,
  ...props
}) => {
  const [searchInput, setSearchInput] = useState(null)
  const [selectedValues, setSelectedValues] = useState([])
  const [responseExist, setResponseExist] = useState(null)

  const query = inputProps?.asyncProps?.query
  const resolveQuery = inputProps?.asyncProps?.resolveQuery
  const clientPortalUser = inputProps?.asyncProps?.clientPortalUser
  const queryLimit = inputProps?.asyncProps?.queryLimit

  const [searchClientsByKeyword] = useLazyQuery(
    query || searchClientsGql, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    })

  const [resolveClientsByIds] = useLazyQuery(
    resolveQuery || clientListForClientPortalByIdsGql, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    })

  const handleResolveIdsToOptions = useCallback(async (ids) => {
    if (ids && !isEmpty(ids)) {
      const response = await resolveClientsByIds({
        variables: {
          ids: ids.filter(item => item !== '' && item != null)
        }
      })
      const result = response?.data?.clientsByIds
      setSelectedValues(result)
    }
  }, [resolveClientsByIds])

  useEffect(() => {
    if (optionsToResolve) {
      handleResolveIdsToOptions(optionsToResolve)
    }
  }, [optionsToResolve, handleResolveIdsToOptions])

  const asyncOptions = async (search, loadedOptions) => {
    let response = null
    if (search) {
      response = await searchClientsByKeyword({
        variables: {
          searchInput: search,
          limit: queryLimit || 10,
          hasActiveClientPortal: clientPortalUser
          // offset: loadedOptions?.length // ? pull-more-on-scroll
        }
      })
    }

    const paginatedData = response?.data?.clients
    setResponseExist(response?.data?.clients?.result)

    // ? pull-more-on-scroll
    // const hasMore = (paginatedData?.perPage * paginatedData?.page) < paginatedData?.count

    return {
      options: paginatedData.result || [],
      hasMore: false
      // hasMore // ? pull-more-on-scroll
    }
  }

  const handleAsyncChange = useCallback((value) => {
    onChange(value)
    setSearchInput(value)
  }, [searchInput, setSearchInput, onChange])

  return (
    <DropdownSelectionWithAsync {...{
      options,
      value: searchInput,
      loadOptions: asyncOptions,
      handleChange: handleAsyncChange,
      inputProps,
      selectedValues,
      responseExist,
      ...props
    }}
    />
  )
}

export { DropdownSelectionWithAsyncContainer }
