import { Form } from './Form'
import { FormGroup } from './FormGroup'
import { FormButtonGroup } from './FormButtonGroup'
import { FormField } from './FormField'
import { FormSection } from './FormSection'
import { FormSectionHeader } from './FormSectionHeader'
import { FormSectionInputsBlock } from './FormSectionInputsBlock'
import { FormInfoSection } from './FormInfoSection'
import { FormInfoSectionHeader } from './FormInfoSectionHeader'
import { FormInfoSectionContent } from './FormInfoSectionContent'
import { FormDivider } from './FormDivider'

Form.ButtonGroup = FormButtonGroup
Form.Group = FormGroup
Form.Field = FormField
Form.Section = FormSection
Form.SectionHeader = FormSectionHeader
Form.SectionInputsBlock = FormSectionInputsBlock
Form.InfoSection = FormInfoSection
Form.InfoSectionHeader = FormInfoSectionHeader
Form.InfoSectionContent = FormInfoSectionContent
Form.Divider = FormDivider

export { Form }
