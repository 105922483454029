import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'formik'

import FormFieldsCard from '../../form/FormFieldsCard'
import { PageDivider } from '../../misc/PageDivider'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import Card from '../../misc/Card'
import AssetsForm from './AssetsForm'
import LiabilitiesForm from './LiabilitiesForm'
import clientAssetsAndLiabilitiesSchema from '../../../schemas/client/clientAssetsAndLiabilitiesSchema'
import { validateAndNavigate } from '../../../helpers/client'

import './ClientAssetsAndLiabilitiesForm.less'

const ClientAssetsAndLiabilitiesForm = ({ setValidationSchema, nextLink, prevLink, formik }) => {
  const navigate = useNavigate()
  const { validateForm } = formik

  const handleNavButtonClick = useCallback((isNext) => {
    validateAndNavigate({
      setSchema: setValidationSchema,
      schema: clientAssetsAndLiabilitiesSchema,
      validate: validateForm,
      nextLocation: isNext ? nextLink : prevLink,
      navigate
    })
  }, [navigate, nextLink, prevLink, validateForm, clientAssetsAndLiabilitiesSchema, setValidationSchema])

  return (
    <Card className='assets-and-liabilities-form'>
      <FormFieldsCard title='Assets'>
        <AssetsForm />
      </FormFieldsCard>

      <PageDivider />

      <FormFieldsCard title='Liabilities'>
        <LiabilitiesForm />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}

export default connect(ClientAssetsAndLiabilitiesForm)
