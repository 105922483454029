import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import T from 'prop-types'
import { ESignatureUserStatuses } from '@elenfs/elen-constants'

import removeDocumentGql from '../../../../graphql/mutations/clientDocument/removeClientDocument.graphql'
import clientDocumentsGql from '../../../../graphql/queries/clientDocument/clientDocuments.graphql'
import RemoveDocumentButton from './RemoveDocumentButton'

const { PENDING, WAITING } = ESignatureUserStatuses

const RemoveDocumentButtonContainer = (props) => {
  const { clientDocument } = props

  const [removeDocument] = useMutation(removeDocumentGql, {
    variables: {
      id: clientDocument.id
    },
    update: (cache, { data: { removeClientDocument: { id } } }) => {
      const documents = cache.readQuery({
        query: clientDocumentsGql
      }).clientDocuments
      const newDocuments = documents.filter((clientDocument) => clientDocument.id !== id)
      cache.writeQuery({
        query: clientDocumentsGql,
        data: {
          clientDocuments: newDocuments
        }
      })
    }
  })

  const removeDocModalCallback = useCallback(
    (accepted) => {
      if (accepted) {
        return removeDocument()
      }
    },
    [removeDocument]
  )

  const disabled = [PENDING, WAITING].includes(clientDocument?.eSignature?.status)

  return <RemoveDocumentButton removeDocModalCallback={removeDocModalCallback} clientDocument={clientDocument} disabled={disabled} />
}

RemoveDocumentButtonContainer.propTypes = {
  clientDocument: T.object.isRequired
}

export default RemoveDocumentButtonContainer
