const MenuDots = props => (
  <svg viewBox='0 0 24 24' height={24} width={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M12.008 16.659a2.31 2.31 0 012.307 2.308 2.31 2.31 0 01-2.307 2.307A2.31 2.31 0 019.7 18.967a2.31 2.31 0 012.308-2.308zm0-6.923a2.31 2.31 0 012.307 2.308 2.31 2.31 0 01-2.307 2.307A2.31 2.31 0 019.7 12.044a2.31 2.31 0 012.308-2.308zm0-6.923a2.31 2.31 0 012.307 2.308 2.31 2.31 0 01-2.307 2.307A2.31 2.31 0 019.7 5.121a2.31 2.31 0 012.308-2.308z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export default MenuDots
