import File from './File'

const doc = (props) => <File textRectFill='#4D70FF' text='DOC' {...props} />
const docx = (props) => <File textRectFill='#4D70FF' text='DOCX' {...props} />

const xls = (props) => <File textRectFill='#21C056' text='XLS' {...props} />
const xlsx = (props) => <File textRectFill='#21C056' text='XLSX' {...props} />

const pdf = (props) => <File textRectFill='#FF5151' text='PDF' {...props} />

const other = (props) => <File textRectFill='#828593' text='FILE' {...props} />

// Just keep adding icons here as needed,
// with keys being the appropriate file extensions.
// Eliminates the need for another map between extensions and icon components.
export default {
  doc,
  docx,
  xls,
  xlsx,
  pdf,
  other
}
