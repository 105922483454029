import T from 'prop-types'
import classnames from 'classnames'

import { RadioIcon } from '../../icons'

import './Radio.less'

const Radio = ({
  inputId,
  checked,
  defaultChecked,
  name,
  onChange,
  readOnly,
  disabled,
  className,
  onClick,
  ...restProps
}) => (
  <div className={classnames('styled-radio', className)} {...restProps}>
    <input
      id={inputId}
      type='radio'
      className='styled-radio__input'
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      name={name}
      onClick={onClick}
    />
    <label htmlFor={inputId}>
      <span>
        <RadioIcon.Checked className='styled-radio__checked-icon' />
        <RadioIcon.Unchecked className='styled-radio__unchecked-icon' />
        <RadioIcon.DisabledChecked className='styled-radio__disabled-checked-icon' />
        <RadioIcon.DisabledUnchecked className='styled-radio__disabled-unchecked-icon' />
      </span>
    </label>
  </div>
)

Radio.propTypes = {
  /**
   * Id for the input.
   */
  inputId: T.string.isRequired,
  /**
   * Is the radio checked or not. Should be used for controlled inputs.
   */
  checked: T.bool,
  /**
   * On change handler for the input.
   */
  onChange: T.func,
  /**
   * On change handler for the input.
   */
  onClick: T.func,
  /**
   * Is the radio disabled.
   */
  disabled: T.bool,
  /**
   * Wrapper div classname.
   */
  className: T.string,
  /**
   * Name for the input. Needed for radio groups.
   */
  name: T.string
}

export { Radio }
