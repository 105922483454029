import { useCallback } from 'react'
import T from 'prop-types'
import { isEmpty, compose } from 'ramda'
import { withFormik, connect } from 'formik'

import constants from '@elenfs/elen-constants'

import { Button, LoadingMessage } from '../../../../misc'
import { FormErrorMessages } from '../../../../form'
import { RemovePolicyTransactionButton } from '../RemovePolicyTransactionButton'

import { buildInitialValues } from '../../../../../helpers/form'
import { contributionSchema, withdrawalSchema } from '../../../../../schemas/policy/policyTransactions'
import { CONTRIBUTION, WITHDRAWAL } from '../../../../../constants/policy'
import { isFrequencySingle, isContribution } from '../../../../../helpers/policy'
import { parseGraphqlErrors } from '../../../../../helpers/error'

import { Form } from '../../../../form/Form'
import { SelectField, MoneyField, DateField } from '../../../../form/fields'
import { buildSelectOptions } from '../../../../form/fields/SelectField'

import './PolicyTransactionDetailsForm.less'

const {
  ContributionFrequencies,
  Contributors,
  ContributionMethods,
  PolicyHolders,
  WithdrawalMethods,
  WithdrawalFrequencies
} = constants.policy.common

const {
  PolicyHoldersLabels,
  ContributionMethodsLabels,
  WithdrawalMethodsLabels,
  ContributorsLabels,
  ContributionFrequenciesLabels,
  WithdrawalFrequenciesLabels
} = constants.policy.common

const PolicyTransactionDetailsForm = (props) => {
  const {
    onDone,
    mutationState,
    errors: validationErrors,
    policyTransaction,
    policyTransactionType,
    values: { frequency, id },
    formik: { resetForm, submitForm, validateForm, setErrors },
    policyId,
    isTransactionUsedByCharge
  } = props

  const showOnlyStartDate = isFrequencySingle(frequency)
  const isEdit = policyTransaction && policyTransaction.id
  const showActionButtons = !isTransactionUsedByCharge

  const validateAndSubmit = useCallback(
    async (fn) => {
      const errors = await validateForm()
      if (!isEmpty(errors)) { return }

      const result = await submitForm()

      if (!result.graphQLErrors) { fn && fn() }
    },
    [validateForm, submitForm, validationErrors]
  )

  const onDoneClick = useCallback(
    () => validateAndSubmit(onDone),
    [validateAndSubmit, onDone])

  const onSaveAndAddNewClick = useCallback(
    () => validateAndSubmit(resetForm),
    [validateAndSubmit, resetForm])

  return (
    <Form className='policy-transaction-details-form'>
      <div className='policy-transaction-details-form__inputs'>
        {isContribution(policyTransactionType)
          ? (
            <SelectField
              name='contributor'
              label='Contributor'
              options={buildSelectOptions(Contributors, ContributorsLabels)}
              inputProps={{ isDisabled: isTransactionUsedByCharge }}
            />
            )
          : (
            <SelectField
              name='paidTo'
              label='Paid to'
              options={buildSelectOptions(PolicyHolders, PolicyHoldersLabels)}
              inputProps={{ isDisabled: isTransactionUsedByCharge }}
            />
            )}

        <SelectField
          name='method'
          label='Method'
          options={
            isContribution(policyTransactionType)
              ? (
                  buildSelectOptions(ContributionMethods, ContributionMethodsLabels)
                )
              : (
                  buildSelectOptions(WithdrawalMethods, WithdrawalMethodsLabels)
                )
          }
          inputProps={{ isDisabled: isTransactionUsedByCharge }}
        />

        <MoneyField
          name='amount'
          label='Amount'
          inputProps={{ disabled: isTransactionUsedByCharge }}
        />

        <SelectField
          name='frequency'
          label='Frequency'
          options={
            isContribution(policyTransactionType)
              ? (
                  buildSelectOptions(ContributionFrequencies, ContributionFrequenciesLabels)
                )
              : (
                  buildSelectOptions(WithdrawalFrequencies, WithdrawalFrequenciesLabels)
                )
          }
          inputProps={{ isDisabled: isTransactionUsedByCharge }}
        />

        <DateField
          name='startDate'
          label={!showOnlyStartDate ? 'Start date' : 'Date'}
          inputProps={{ disabled: isTransactionUsedByCharge }}
        />

        {
          !showOnlyStartDate &&
            <DateField
              name='endDate'
              label='End date'
              inputProps={{ disabled: isTransactionUsedByCharge }}
            />
        }

      </div>

      {mutationState.loading && <LoadingMessage />}

      <FormErrorMessages validationErrors={validationErrors} />

      <Form.ButtonGroup>
        {
          showActionButtons &&
            <Button type='button' color={Button.Colors.BLUE} onClick={onDoneClick}>
              Done
            </Button>
        }
        {!isEdit && (
          <Button type='button' color={Button.Colors.GREEN} onClick={onSaveAndAddNewClick}>
            Save and add new
          </Button>
        )}

        {(isEdit && showActionButtons) && (
          <RemovePolicyTransactionButton
            {...{
              closeTransactionModal: onDone,
              transactionId: id,
              policyId,
              setErrors,
              policyTransactionType,
              className: 'policy-transaction-details-form__delete-button'
            }}
          />
        )}
      </Form.ButtonGroup>
    </Form>
  )
}

const PolicyTransactionDetailsFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ({ policyTransactionType }) =>
      isContribution(policyTransactionType) ? contributionSchema : withdrawalSchema,
    mapPropsToValues: ({ policyTransaction, policyTransactionType, policyId }) =>
      buildInitialValues(
        isContribution(policyTransactionType)
          ? contributionSchema
          : withdrawalSchema,
        {
          ...(policyTransaction || {}),
          policyId
        }),
    handleSubmit: async (values, formik) => {
      const { props, setErrors } = formik
      const { mutate, policyTransaction, policyTransactionType, policyId } = props

      values = (
        isContribution(policyTransactionType)
          ? contributionSchema
          : withdrawalSchema).cast(values, { stripUnknown: true }
      )

      // make sure to unset end date
      if (!isFrequencySingle(values.frequency) && !values.endDate) {
        values.endDate = null
      }

      if (policyTransaction && policyTransaction.id) {
        values = {
          ...values,
          id: policyTransaction.id,
          policyId: policyTransaction.policyId
        }
      }

      values = { ...values, policyId }

      try {
        const result = await mutate({ variables: { input: values } })
        return result
      } catch (error) {
        setErrors(parseGraphqlErrors(
          error,
          `There was an error saving the ${policyTransactionType}, please try again later`))
        return error
      }
    }
  }),
  connect
)(PolicyTransactionDetailsForm)

PolicyTransactionDetailsFormEnhanced.propTypes = {
  policyId: T.string.isRequired,
  /**
   * Contribution or withdrawal.
   */
  policyTransactionType: T.oneOf([CONTRIBUTION, WITHDRAWAL]).isRequired,
  /**
   * Transaction object. (Contribution or withdrawal)
   */
  policyTransaction: T.object,
  /**
   * Callback to execute when form submits.
   */
  onDone: T.func.isRequired,
  /**
   * Is any mutation loading.
   * Eg mutation for saving or for deleting the transaction.
   */
  mutationState: T.shape({
    loading: T.bool.isRequired
  }).isRequired
}

export { PolicyTransactionDetailsFormEnhanced as PolicyTransactionDetailsForm }
