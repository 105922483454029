import T from 'prop-types'
import { ActivityLogEntryTypes, TaskStatuses } from '@elenfs/elen-constants'

import { userType } from './../../../helpers/propTypes'

const taskBasePropTypes = () =>
  T.shape({
    type: T.oneOf(Object.values(ActivityLogEntryTypes)),
    summary: T.string.isRequired,
    client: userType(),
    dueDate: T.string.isRequired,
    assignee: userType(),
    status: T.oneOf(Object.values(TaskStatuses)).isRequired,
    id: T.string.isRequired
  }).isRequired

export const taskPropType = () =>
  T.shape({ ...taskBasePropTypes(), subTasks: T.arrayOf(taskBasePropTypes()) })
