import { Button, PageDivider, WrapperScreen } from '../../misc'
import { CheckIcon, LauncherIcon } from '../../icons'

import './SubscribePage.less'

const SubscribePage = ({ planOptions, handleSelectPlan, loading, data }) => (
  <WrapperScreen className='subscription'>
    <>
      <div className='subscription__content__header'>
        <LauncherIcon className='subscription__content__header__logo' />
        <h2 className='subscription__content__header__title'>Choose your plan</h2>
        <h3 className='subscription__content__header__subtitle'>Start using Elen today.</h3>
      </div>
      <div className='subscription__content__benefits'>
        <ul className='subscription__content__benefits__list'>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>Client messaging</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>Access to all app features</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>Regulatory reporting</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>Client portal</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>Document and policy sharing</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>CSV import</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>Transact Integration</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>Tasks and Workflows</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>CashCalc Integration</span>
          </li>
          <li className='subscription__content__benefits__list__item'>
            <CheckIcon className='check-icon' />
            <span>E-signatures</span>
          </li>
        </ul>
      </div>
      {
        (planOptions && planOptions?.length)
          ? (
            <div className='card__list'>
              {planOptions.map(option => (
                <div
                  className='card'
                  key={option.id}
                  onClick={() => (!loading && !data) && handleSelectPlan(option.id)}
                >
                  <div className='card__info'>
                    <span className='card__info__title'>{option.intervalText}</span>
                    <div className='card__info__price'>
                      <span className='card__info__price--sign'>{option.moneySign}</span>
                      <span className='card__info__price--value'>{option.moneyValue}</span>
                      <span className='card__info__price--vat'> + VAT </span>
                    </div>
                    <span className='card__info__licence'>per licence</span>
                    <PageDivider className='card__info__divider' />
                    <span className='card__info__billing'>{option.billingText}</span>
                  </div>
                  <Button
                    className='card__action'
                    disabled={loading}
                    color={Button.Colors.TRANSPARENT}
                  >
                    Subscribe
                  </Button>
                </div>
              ))}
            </div>
            )
          : null
      }
    </>

    <PageDivider className='subscription__divider' />

    <div className='subscription__footer'>
      <p>
        Choose the plan which suits you and then you’ll be taken to our payment provider to enter your payment details.
      </p>
      <p>You won’t be charged until the end of the trial period and you can cancel at any time.</p>
    </div>
  </WrapperScreen>
)

export { SubscribePage }
