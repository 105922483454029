"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ParsingFailureKinds = exports.InvalidFieldErrors = exports.GeneralDataImportErrors = exports.EntityImportResult = exports.DataImportTypes = exports.DataImportTransactErrors = exports.DataImportProcessingEvents = exports.DataImportPlatforms = exports.DataImportJobStatuses = exports.DataImportJobErrors = exports.DataImportFileTypes = exports.DataImportCashCalcErrors = exports.DataImportCSVFileErrors = void 0;
var _error = require("./error");
const DataImportPlatforms = exports.DataImportPlatforms = Object.freeze({
  TRANSACT: 'TRANSACT',
  CASH_CALC: 'CASH_CALC',
  P1: 'P1',
  PRAEMIUM: 'PRAEMIUM'
});
const DataImportFileTypes = exports.DataImportFileTypes = Object.freeze({
  CSV_FILE: 'CSV_FILE',
  JSON_FILE: 'JSON_FILE'
});
const DataImportTypes = exports.DataImportTypes = Object.freeze({
  ...DataImportPlatforms,
  ...DataImportFileTypes
});
const DataImportJobStatuses = exports.DataImportJobStatuses = Object.freeze({
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
});
const DataImportJobErrors = exports.DataImportJobErrors = Object.freeze({
  // Network connection with a data import platform could not be established
  NETWORK_FAILURE: 'NETWORK_FAILURE',
  // Data import platform could not return data due to some issues
  // (e.g. invalid credentials, invalid permissions, etc.)
  PLATFORM_FAILURE: 'PLATFORM_FAILURE',
  // Failed to parse or save data in DB
  PROCESSING_FAILURE: 'PROCESSING_FAILURE',
  // Something unexpectedly went wrong on our side
  INTERNAL_FAILURE: 'INTERNAL_FAILURE'
});
const DataImportTransactErrors = exports.DataImportTransactErrors = Object.freeze({
  // Provided access code and pin were not valid
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  // Account locked out due to multiple failed login attempts
  LOCKED_OUT: 'LOCKED_OUT',
  // Account was not of type `Adviser` or `Adviser Group`
  WRONG_USER_TYPE: 'WRONG_USER_TYPE',
  // Daily maximum of requests for an account was exceeded
  MAX_REQUEST_EXCEEDED: 'MAX_REQUEST_EXCEEDED'
});
const GeneralDataImportErrors = exports.GeneralDataImportErrors = Object.freeze({
  // Provided login and pass were not valid
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS'
});
const DataImportCSVFileErrors = exports.DataImportCSVFileErrors = Object.freeze({
  // Provided CSV file is not correct
  INVALID_CSV: 'INVALID_CSV'
});
const DataImportCashCalcErrors = exports.DataImportCashCalcErrors = Object.freeze({
  // OAuth2 refresh token expired
  AUTH_EXPIRED: 'AUTH_EXPIRED'
});
const DataImportProcessingEvents = exports.DataImportProcessingEvents = Object.freeze({
  // Client created
  CLIENT_CREATED: 'CLIENT_CREATED',
  // Policy created
  POLICY_CREATED: 'POLICY_CREATED',
  // Policy updated
  POLICY_UPDATED: 'POLICY_UPDATED',
  // Policy transaction created
  POLICY_TRANSACTION_CREATED: 'POLICY_TRANSACTION_CREATED',
  // Policy could not be updated because it does not exist in our DB
  // This is relevant for integrations where we cannot create policies
  // but instead rely on them being created manually by the users
  WARNING_NO_POLICY_TO_UPDATE: 'WARNING_NO_POLICY_TO_UPDATE',
  // Data could not be parsed
  ERROR_PARSING_FAILURE: 'ERROR_PARSING_FAILURE',
  // Client could not be saved in DB because it had invalid fields
  ERROR_CLIENT_INVALID: 'ERROR_CLIENT_INVALID',
  // Policy could not be saved in DB because it had invalid fields
  ERROR_POLICY_INVALID: 'ERROR_POLICY_INVALID',
  // Contribution/withdrawal could not be saved in DB because it had invalid fields
  ERROR_POLICY_TRANSACTION_INVALID: 'ERROR_POLICY_TRANSACTION_INVALID',
  // Client could not be saved in DB because of
  // some unexpected issue of our side
  ERROR_CLIENT_INTERNAL_FAILURE: 'ERROR_CLIENT_INTERNAL_FAILURE',
  // Policy could not be saved in DB because of
  // some unexpected issue of our side
  ERROR_POLICY_INTERNAL_FAILURE: 'ERROR_POLICY_INTERNAL_FAILURE',
  // Contribution/withdrawal could not be saved in DB because of
  // some unexpected issue of our side
  ERROR_POLICY_TRANSACTION_INTERNAL_FAILURE: 'ERROR_POLICY_TRANSACTION_INTERNAL_FAILURE'
});
const EntityImportResult = exports.EntityImportResult = Object.freeze({
  ENTITY_CREATED: 'ENTITY_CREATED',
  ENTITY_UPDATED: 'ENTITY_UPDATED',
  NO_CHANGE: 'NO_CHANGE'
});
const ParsingFailureKinds = exports.ParsingFailureKinds = Object.freeze({
  MISSING_FIELDS: 'MISSING_FIELDS',
  INVALID_FIELDS: 'INVALID_FIELDS',
  INTERNAL_FAILURE: 'INTERNAL_FAILURE'
});
const InvalidFieldErrors = exports.InvalidFieldErrors = Object.freeze({
  ..._error.FieldErrorCodes,
  DUPLICATED_VALUE: 'DUPLICATED_VALUE'
});
var _default = exports.default = {
  DataImportTypes,
  DataImportJobStatuses,
  DataImportJobErrors,
  DataImportTransactErrors,
  DataImportProcessingEvents,
  EntityImportResult,
  DataImportCSVFileErrors,
  ParsingFailureKinds,
  InvalidFieldErrors,
  DataImportCashCalcErrors,
  GeneralDataImportErrors
};