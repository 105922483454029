import { useCallback, useMemo } from 'react'
import T from 'prop-types'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import classnames from 'classnames'

import { Input } from '../Input'
import { withIcon } from '../WithIcon'
import { CalendarIcon, TimeIcon, TimeRoundedIcon } from '../../../icons'
import { RoundIcon } from '../../../misc'

import 'flatpickr/dist/flatpickr.min.css'
import './DateInput.less'

const InputWithIcon = withIcon(Input)

const iconLayout = isTime => props => (
  <RoundIcon {...props}>
    {isTime ? <TimeRoundedIcon /> : <CalendarIcon />}
  </RoundIcon>
)

const InputWithCalendarIcon = (props) => (
  <InputWithIcon icon={CalendarIcon} iconPosition={withIcon.IconPositions.RIGHT} {...props} />
)

const InputWithTimeIcon = (props) => (
  <InputWithIcon icon={TimeIcon} iconPosition={withIcon.IconPositions.RIGHT} {...props} />
)

const InputIconLayout = (props) => (
  <InputWithIcon icon={iconLayout(false)} iconPosition={withIcon.IconPositions.LEFT} {...props} />
)

const TimeInputIconLayout = (props) => (
  <InputWithIcon icon={iconLayout(true)} iconPosition={withIcon.IconPositions.LEFT} {...props} />
)

const dateStringLengthFormatMap = Object.freeze({
  6: 'DDMMYY',
  8: 'DDMMYYYY',
  '8/': 'DD/MM/YY',
  '10/': 'DD/MM/YYYY'
})

const getDateFormat = (dateString) => {
  const charCount = dateString ? dateString.length : 0
  const hasSlashes = dateString ? dateString.includes('/') : false

  const formatKey = String(charCount) + (hasSlashes ? '/' : '')

  return dateStringLengthFormatMap[formatKey]
}

const timeInputFlatpickrOptions = {
  altInput: false,
  allowInput: false,
  enableTime: true,
  noCalendar: true,
  dateFormat: 'H:i',
  time_24hr: true,
  disableMobile: true,
  static: true
}

// Behaviour of this input depends on how moment.js parses dates.
// That's configured in startup/moment.js

/**
 * Input that uses flatpickr dropdown to select date.
 * Also parses some date formats configured in startup/moment.js
 */
const DateInput = (props) => {
  const {
    flatpickrOptions,
    handleChange,
    className,
    inputWrapperClassName,
    value,
    inputProps,
    isTimeInput,
    withIconLayout,
    ...restProps
  } = props
  const hasValue = value && value !== ('' || undefined || null)

  const _handleChange = useCallback((dates) => handleChange(dates[0] || ''), [handleChange])

  const _handleReset = useCallback(() => handleChange(''), [handleChange])

  const _handleParseDate = useCallback(
    (dateInput) => {
      const dateFormat = getDateFormat(dateInput)
      const maxDate = flatpickrOptions?.maxDate
      const date = moment(dateInput, dateFormat).isValid() ? moment(dateInput, dateFormat) : moment()
      return maxDate && moment(maxDate).isValid() && date.isAfter(maxDate) ? maxDate : date.toDate()
    },
    [flatpickrOptions, moment]
  )

  const InputComponent = isTimeInput
    ? withIconLayout
      ? TimeInputIconLayout
      : InputWithTimeIcon
    : withIconLayout
      ? InputIconLayout
      : InputWithCalendarIcon

  const defaultValue = useMemo(() => (
    !isTimeInput && value
      ? (typeof value === 'string' ? value : toString(value))
      : undefined
  ))

  return (
    <Flatpickr
      value={value}
      defaultValue={defaultValue}
      onChange={_handleChange}
      // I think that this code is here to prevent
      // setting the same value twice in a row
      // (eg updating the form state even though the date didn't really change).
      // It's older code, so I will leave it here for now.
      onClose={(selectedDates, dateStr, instance) => {
        if (
          instance.config.allowInput &&
          dateStr &&
          selectedDates &&
          selectedDates[0] !== dateStr
        ) {
          instance.setDate(dateStr, false)
        }
      }}
      // This is to make sure that the calendar closes after typing in a date manually.
      // It shouldn't close when typing in time.
      onValueUpdate={(_selectedDates, _dateStr, instance) => {
        if (!isTimeInput) {
          instance.close()
        }
      }}
      options={{
        wrap: true,
        dateFormat: 'j M Y',
        allowInput: true,
        parseDate: _handleParseDate,
        ...{ ...(isTimeInput ? timeInputFlatpickrOptions : {}) },
        ...flatpickrOptions
      }}
    >
      <InputComponent
        data-input
        inputWrapperClassName={classnames('date-input-wrapper', {
          'date-input-wrapper--with-time-icon': isTimeInput,
          'date-input-wrapper--icon-layout': withIconLayout,
          [inputWrapperClassName]: inputWrapperClassName
        })}
        className={className}
        handleReset={_handleReset}
        showClearIndicator={hasValue}
        {...inputProps}
        {...restProps}
      />
    </Flatpickr>
  )
}

DateInput.defaultProps = {
  inputProps: {
    isClearable: false
  }
}

DateInput.propTypes = {
  /**
  Directly passed to flatpickr to customize its behavour.
  */
  flatpickrOptions: T.object,
  /**
  Called when date value gets changed.
  */
  handleChange: T.func,
  /**
   * Input classname.
   */
  className: T.string,
  /**
   * Classname for input and icon wrapper.
   */
  inputWrapperClassName: T.string,
  /**
   * Date string value for controlled input.
   * Should use one of the following formats:
      DDMMYY
      DDMMYYYY
      DD/MM/YY
      DD/MM/YYYY,
    or a standard datetime format.
    Can also be a Date object.
   */
  value: T.oneOfType([T.string, T.object]),
  /**
   * Whether the input is a date or a time input.
   */
  isTimeInput: T.bool,
  /**
   * Whether the input has Icon layout or not.
   */
  withIconLayout: T.bool
}

export { DateInput }
