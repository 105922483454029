"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RecurrentFrequencies = exports.CurrenciesLabels = exports.Currencies = void 0;
const Currencies = exports.Currencies = Object.freeze({
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD',
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYN: 'BYN',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  FJD: 'FJD',
  FKP: 'FKP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRU: 'MRU',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  STN: 'STN',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TVD: 'TVD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XCD: 'XCD',
  XDR: 'XDR',
  XOF: 'XOF',
  XPF: 'XPF',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWD: 'ZWD'
});
const CurrenciesLabels = exports.CurrenciesLabels = Object.freeze({
  [Currencies.AED]: 'United Arab Emirates Dirham',
  [Currencies.AFN]: 'Afghanistan Afghani',
  [Currencies.ALL]: 'Albania Lek',
  [Currencies.AMD]: 'Armenia Dram',
  [Currencies.ANG]: 'Netherlands Antilles Guilder',
  [Currencies.AOA]: 'Angola Kwanza',
  [Currencies.ARS]: 'Argentina Peso',
  [Currencies.AUD]: 'Australia Dollar',
  [Currencies.AWG]: 'Aruba Guilder',
  [Currencies.AZN]: 'Azerbaijan Manat',
  [Currencies.BAM]: 'Bosnia and Herzegovina Convertible Mark',
  [Currencies.BBD]: 'Barbados Dollar',
  [Currencies.BDT]: 'Bangladesh Taka',
  [Currencies.BGN]: 'Bulgaria Lev',
  [Currencies.BHD]: 'Bahrain Dinar',
  [Currencies.BIF]: 'Burundi Franc',
  [Currencies.BMD]: 'Bermuda Dollar',
  [Currencies.BND]: 'Brunei Darussalam Dollar',
  [Currencies.BOB]: 'Bolivia Bolíviano',
  [Currencies.BRL]: 'Brazil Real',
  [Currencies.BSD]: 'Bahamas Dollar',
  [Currencies.BTN]: 'Bhutan Ngultrum',
  [Currencies.BWP]: 'Botswana Pula',
  [Currencies.BYN]: 'Belarus Ruble',
  [Currencies.BZD]: 'Belize Dollar',
  [Currencies.CAD]: 'Canada Dollar',
  [Currencies.CDF]: 'Congo/Kinshasa Franc',
  [Currencies.CHF]: 'Switzerland Franc',
  [Currencies.CLP]: 'Chile Peso',
  [Currencies.CNY]: 'China Yuan Renminbi',
  [Currencies.COP]: 'Colombia Peso',
  [Currencies.CRC]: 'Costa Rica Colon',
  [Currencies.CUC]: 'Cuba Convertible Peso',
  [Currencies.CUP]: 'Cuba Peso',
  [Currencies.CVE]: 'Cape Verde Escudo',
  [Currencies.CZK]: 'Czech Republic Koruna',
  [Currencies.DJF]: 'Djibouti Franc',
  [Currencies.DKK]: 'Denmark Krone',
  [Currencies.DOP]: 'Dominican Republic Peso',
  [Currencies.DZD]: 'Algeria Dinar',
  [Currencies.EGP]: 'Egypt Pound',
  [Currencies.ERN]: 'Eritrea Nakfa',
  [Currencies.ETB]: 'Ethiopia Birr',
  [Currencies.EUR]: 'Euro Member Countries',
  [Currencies.FJD]: 'Fiji Dollar',
  [Currencies.FKP]: 'Falkland Islands (Malvinas) Pound',
  [Currencies.GBP]: 'United Kingdom Pound',
  [Currencies.GEL]: 'Georgia Lari',
  [Currencies.GGP]: 'Guernsey Pound',
  [Currencies.GHS]: 'Ghana Cedi',
  [Currencies.GIP]: 'Gibraltar Pound',
  [Currencies.GMD]: 'Gambia Dalasi',
  [Currencies.GNF]: 'Guinea Franc',
  [Currencies.GTQ]: 'Guatemala Quetzal',
  [Currencies.GYD]: 'Guyana Dollar',
  [Currencies.HKD]: 'Hong Kong Dollar',
  [Currencies.HNL]: 'Honduras Lempira',
  [Currencies.HRK]: 'Croatia Kuna',
  [Currencies.HTG]: 'Haiti Gourde',
  [Currencies.HUF]: 'Hungary Forint',
  [Currencies.IDR]: 'Indonesia Rupiah',
  [Currencies.ILS]: 'Israel Shekel',
  [Currencies.IMP]: 'Isle of Man Pound',
  [Currencies.INR]: 'India Rupee',
  [Currencies.IQD]: 'Iraq Dinar',
  [Currencies.IRR]: 'Iran Rial',
  [Currencies.ISK]: 'Iceland Krona',
  [Currencies.JEP]: 'Jersey Pound',
  [Currencies.JMD]: 'Jamaica Dollar',
  [Currencies.JOD]: 'Jordan Dinar',
  [Currencies.JPY]: 'Japan Yen',
  [Currencies.KES]: 'Kenya Shilling',
  [Currencies.KGS]: 'Kyrgyzstan Som',
  [Currencies.KHR]: 'Cambodia Riel',
  [Currencies.KMF]: 'Comorian Franc',
  [Currencies.KPW]: 'Korea (North) Won',
  [Currencies.KRW]: 'Korea (South) Won',
  [Currencies.KWD]: 'Kuwait Dinar',
  [Currencies.KYD]: 'Cayman Islands Dollar',
  [Currencies.KZT]: 'Kazakhstan Tenge',
  [Currencies.LAK]: 'Laos Kip',
  [Currencies.LBP]: 'Lebanon Pound',
  [Currencies.LKR]: 'Sri Lanka Rupee',
  [Currencies.LRD]: 'Liberia Dollar',
  [Currencies.LSL]: 'Lesotho Loti',
  [Currencies.LYD]: 'Libya Dinar',
  [Currencies.MAD]: 'Morocco Dirham',
  [Currencies.MDL]: 'Moldova Leu',
  [Currencies.MGA]: 'Madagascar Ariary',
  [Currencies.MKD]: 'Macedonia Denar',
  [Currencies.MMK]: 'Myanmar (Burma) Kyat',
  [Currencies.MNT]: 'Mongolia Tughrik',
  [Currencies.MOP]: 'Macau Pataca',
  [Currencies.MRU]: 'Mauritania Ouguiya',
  [Currencies.MUR]: 'Mauritius Rupee',
  [Currencies.MVR]: 'Maldives (Maldive Islands) Rufiyaa',
  [Currencies.MWK]: 'Malawi Kwacha',
  [Currencies.MXN]: 'Mexico Peso',
  [Currencies.MYR]: 'Malaysia Ringgit',
  [Currencies.MZN]: 'Mozambique Metical',
  [Currencies.NAD]: 'Namibia Dollar',
  [Currencies.NGN]: 'Nigeria Naira',
  [Currencies.NIO]: 'Nicaragua Cordoba',
  [Currencies.NOK]: 'Norway Krone',
  [Currencies.NPR]: 'Nepal Rupee',
  [Currencies.NZD]: 'New Zealand Dollar',
  [Currencies.OMR]: 'Oman Rial',
  [Currencies.PAB]: 'Panama Balboa',
  [Currencies.PEN]: 'Peru Sol',
  [Currencies.PGK]: 'Papua New Guinea Kina',
  [Currencies.PHP]: 'Philippines Peso',
  [Currencies.PKR]: 'Pakistan Rupee',
  [Currencies.PLN]: 'Poland Zloty',
  [Currencies.PYG]: 'Paraguay Guarani',
  [Currencies.QAR]: 'Qatar Riyal',
  [Currencies.RON]: 'Romania Leu',
  [Currencies.RSD]: 'Serbia Dinar',
  [Currencies.RUB]: 'Russia Ruble',
  [Currencies.RWF]: 'Rwanda Franc',
  [Currencies.SAR]: 'Saudi Arabia Riyal',
  [Currencies.SBD]: 'Solomon Islands Dollar',
  [Currencies.SCR]: 'Seychelles Rupee',
  [Currencies.SDG]: 'Sudan Pound',
  [Currencies.SEK]: 'Sweden Krona',
  [Currencies.SGD]: 'Singapore Dollar',
  [Currencies.SHP]: 'Saint Helena Pound',
  [Currencies.SLL]: 'Sierra Leone Leone',
  [Currencies.SOS]: 'Somalia Shilling',
  [Currencies.SRD]: 'Suriname Dollar',
  [Currencies.STN]: 'São Tomé and Príncipe Dobra',
  [Currencies.SVC]: 'El Salvador Colon',
  [Currencies.SYP]: 'Syria Pound',
  [Currencies.SZL]: 'eSwatini Lilangeni',
  [Currencies.THB]: 'Thailand Baht',
  [Currencies.TJS]: 'Tajikistan Somoni',
  [Currencies.TMT]: 'Turkmenistan Manat',
  [Currencies.TND]: 'Tunisia Dinar',
  [Currencies.TOP]: "Tonga Pa'anga",
  [Currencies.TRY]: 'Turkey Lira',
  [Currencies.TTD]: 'Trinidad and Tobago Dollar',
  [Currencies.TVD]: 'Tuvalu Dollar',
  [Currencies.TWD]: 'Taiwan New Dollar',
  [Currencies.TZS]: 'Tanzania Shilling',
  [Currencies.UAH]: 'Ukraine Hryvnia',
  [Currencies.UGX]: 'Uganda Shilling',
  [Currencies.USD]: 'United States Dollar',
  [Currencies.UYU]: 'Uruguay Peso',
  [Currencies.UZS]: 'Uzbekistan Som',
  [Currencies.VEF]: 'Venezuela Bolívar',
  [Currencies.VND]: 'Viet Nam Dong',
  [Currencies.VUV]: 'Vanuatu Vatu',
  [Currencies.WST]: 'Samoa Tala',
  [Currencies.XAF]: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC',
  [Currencies.XCD]: 'East Caribbean Dollar',
  [Currencies.XDR]: 'International Monetary Fund (IMF) Special Drawing Rights',
  [Currencies.XOF]: 'Communauté Financière Africaine (BCEAO) Franc',
  [Currencies.XPF]: 'Comptoirs Français du Pacifique (CFP) Franc',
  [Currencies.YER]: 'Yemen Rial',
  [Currencies.ZAR]: 'South Africa Rand',
  [Currencies.ZMW]: 'Zambia Kwacha',
  [Currencies.ZWD]: 'Zimbabwe Dollar'
});
const RecurrentFrequencies = exports.RecurrentFrequencies = Object.freeze({
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  HALF_YEARLY: 'HALF_YEARLY',
  ANNUALLY: 'ANNUALLY'
});
var _default = exports.default = {
  Currencies,
  CurrenciesLabels,
  RecurrentFrequencies
};