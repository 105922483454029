"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActivityLogEntryTypes = void 0;
var _task = require("./task");
const ActivityLogEntryTypes = exports.ActivityLogEntryTypes = Object.freeze({
  ..._task.TaskTypes,
  AUDIT_TRAIL: 'AUDIT_TRAIL'
});