import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import ImportError from './ImportError'

const { DataImportCSVFileErrors } = constants.dataImport

const {
  INVALID_CSV
} = DataImportCSVFileErrors

const CSVJobFailure = ({ job }) => {
  let heading = <b>Import failed</b>
  let description

  switch (job.platformFailureType) {
    case INVALID_CSV:
      heading = 'File has incorrect format (missing columns)'
      description = 'File is of incorrect format. Columns are missing or incorrectly labelled.'
      break
    default:
      description = 'File Import error'
  }

  return (
    <ImportError
      heading={heading}
      description={description}
    />
  )
}

CSVJobFailure.propTypes = {
  job: T.shape({
    platformFailureType: T.oneOf(
      Object.values(DataImportCSVFileErrors)
    )
  }).isRequired
}

export default CSVJobFailure
