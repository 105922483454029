import { useQuery } from '@apollo/client'
import cashCalcConnectionGql from '../graphql/queries/dataImport/cashCalcConnection.graphql'

export const useCashCalc = () => {
  const { data } = useQuery(cashCalcConnectionGql, {
    fetchPolicy: 'cache-and-network'
  })

  const cashCalcConnection = data?.dataImportCashCalcConnection
  const isCashCalcConnected = !!cashCalcConnection
  const isCashCalcConnectionActive = cashCalcConnection?.isActive

  return { isCashCalcConnectionActive, isCashCalcConnected }
}
