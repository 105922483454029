import classnames from 'classnames'

import Heading from '../../misc/Heading'

import './FormFieldsCard.less'

const FormFieldsCard = ({ title, children, className, ...restProps }) => (
  <div className={classnames('form-fields-card', className)} {...restProps}>
    <Heading as='h3'>{title}</Heading>
    {children}
  </div>
)

export default FormFieldsCard
