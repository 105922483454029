import { saveAs } from 'file-saver'

import AppConfig from '../../../appConfig'

import { getAuthToken } from '../../../auth'

const handleBlobWithFileSaver = (blob, fileName) =>
  blob && saveAs(blob, fileName)

const generateRequestOptions = token => ({
  method: 'GET',
  cache: 'no-cache',
  headers: {
    Authorization: `Bearer ${token}`
  }
})

export const onDownloadReport = async ({ filter, reportName }) => {
  const url = new URL(`${AppConfig.API_BASE_URL}/export/${reportName}`)
  const token = getAuthToken()

  // construct queryParams in the URL itself
  Object.keys(filter || []).forEach(key =>
    url.searchParams.append(key, filter[key])
  )
  try {
    // call to API with constructed URL
    const res = await window.fetch(url, generateRequestOptions(token))
    // get the blob response
    const blob = await res.blob()
    // get Headers
    const resHeaders = res.headers.get('Content-Disposition')
    // extract filename
    const fileName = resHeaders.match(/filename="(.*?)"/i)[1]

    // download the file
    handleBlobWithFileSaver(blob, fileName)
  } catch (error) {
    console.log({ error })
  }
}
