import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useBlocker } from './useBlocker'

const useCallbackPrompt = (enabled, shouldBlockNavigation, message) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [showPrompt, setShowPrompt] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = useCallback(() => {
    setShowPrompt(false)
  }, [])

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // in if condition we are checking next location and current location are equals or not

      if (!confirmedNavigation) {
        if (shouldBlockNavigation && shouldBlockNavigation(nextLocation?.location?.pathname)) {
          setShowPrompt(false)
          setConfirmedNavigation(true)
        } else if (nextLocation?.location?.pathname !== pathname) {
          setShowPrompt(true)
        }
        setLastLocation(nextLocation)
        return false
      }

      return true
    },
    [confirmedNavigation, showPrompt, lastLocation, shouldBlockNavigation]
  )

  const confirmNavigation = useCallback(
    (accepted) => {
      setShowPrompt(false)
      setConfirmedNavigation(accepted)
    }, [
      showPrompt,
      setShowPrompt,
      confirmedNavigation,
      setConfirmedNavigation
    ]
  )

  useEffect(
    () => {
      if (confirmedNavigation && lastLocation) {
        navigate(lastLocation?.location?.pathname)
        // Clean-up state on confirmed navigation
        setConfirmedNavigation(false)
      }
    }, [lastLocation, confirmedNavigation]
  )

  useBlocker(handleBlockedNavigation, enabled)

  return [showPrompt, confirmNavigation, closeModal]
}

export { useCallbackPrompt }
