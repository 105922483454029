import PropTypes from 'prop-types'

import { Toastr } from '../../misc/Toastr'
import { DataImportPlatformLabels } from '../../../helpers/enumLabels/dataImport'

const ImportSyncSuccessToast = ({ dataImportType }) => {
  const platformName = DataImportPlatformLabels[dataImportType]

  return (
    <Toastr
      type='success'
      title={`Syncing with ${platformName} in progress`}
      content='This page will be updated once done'
    />
  )
}

ImportSyncSuccessToast.propTypes = {
  dataImportType: PropTypes.string.isRequired
}

export default ImportSyncSuccessToast
