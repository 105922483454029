import { values } from 'ramda'
import * as yup from 'yup'
import { ActivityLogEntryTypes } from '@elenfs/elen-constants'

import dateSchema from '../helpers/dateSchema'
import enumSchema from '../helpers/enumSchema'
import stringSchema from '../helpers/stringSchema'

export default yup
  .object()
  .shape({
    clientId: stringSchema()
      .label('Client')
      .required(),

    date: dateSchema()
      .label('Date')
      .required(),

    time: dateSchema()
      .label('Time')
      .required(),

    type: enumSchema(values(ActivityLogEntryTypes))
      .label('Type')
      .default(ActivityLogEntryTypes.WORK_ON_FILE)
      .required(),

    summary: stringSchema()
      .label('Summary')
      .required(),

    notes: stringSchema().label('Notes')
  })
  .noUnknown(true)
