import { useState } from 'react'

import { DownloadReportBtn } from './DownloadReportBtn'

import { onDownloadReport } from './helpers'

const DownloadReportBtnContainer = ({ currentFilter, reportName, ...restProps }) => {
  const [loading, setLoading] = useState(false)

  const onClickFunc = async () => {
    setLoading(true)
    await onDownloadReport({
      filter: currentFilter,
      reportName
    })
    setLoading(false)
  }

  return <DownloadReportBtn {...{ disabled: loading, onClickFunc, ...restProps }} />
}

export { DownloadReportBtnContainer }
