import T from 'prop-types'

import { DropdownSelection } from '../../../misc/DropdownSelection'

const ConnectToPolicy = ({
  clientDocument,
  policyOptions,
  connectToPolicy
}) => {
  return (
    <DropdownSelection
      placeholder='Link to policy'
      selected={clientDocument.policyId}
      options={policyOptions}
      onChange={connectToPolicy}
      isClearable
    />
  )
}

ConnectToPolicy.propTypes = {
  clientDocument: T.object.isRequired,
  policyOptions: T.array.isRequired,
  connectToPolicy: T.func.isRequired
}

export default ConnectToPolicy
