import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import clientUserByIdGql from '../../../../graphql/queries/client/clientUserById.graphql'

const withClientUser = (
  { fetchPolicy = 'cache-and-network' } = {}
) => (WrappedComponent) => (props) => {
  const params = useParams()
  const clientId = params?.clientId || props?.client?.id
  let foundData = null

  const { loading, data, error } = useQuery(clientUserByIdGql, {
    variables: { clientId },
    fetchPolicy,
    nextFetchPolicy: 'cache-first'
  })

  if (loading && error) {
    foundData = false
  } else if (data) {
    foundData = data
  }

  return (
    <WrappedComponent
      isUnActiveClientLoading={loading}
      isUnActiveClientError={error}
      isClientUser={!!foundData}
      {...props}
    />
  )
}

export { withClientUser }
