import T from 'prop-types'
import classnames from 'classnames'

import { Radio } from '../../../../misc/Radio'
import { optionValueType } from '../../../../../helpers/propTypes'

import './Option.less'

const Option = ({ className, label, name, inputId, isTextButton, ...restProps }) => (
  <div
    className={classnames('radio-group__option', className, {
      'radio-group__option--text-button': isTextButton,
      'radio-group__option--text-button--checked': isTextButton && restProps.checked,
      'radio-group__option--text-button--error': isTextButton && restProps.error
    })}
  >
    <Radio
      className='radio-group__option__radio'
      inputId={inputId}
      name={name}
      {...restProps}
    />

    {label && (
      <label className='radio-group__option__label' htmlFor={inputId}>
        {label}
      </label>
    )}
  </div>
)

Option.propTypes = {
  inputId: T.string.isRequired,
  className: T.string,
  label: T.node,
  value: optionValueType(),
  name: T.string.isRequired,
  isTextButton: T.bool
}

export { Option }
