import T from 'prop-types'

import ImportError from './ImportError'
import ClientErrorHeading from './ClientErrorHeading'

const ClientInternalError = ({ clientFirstName, clientLastName }) => (
  <ImportError
    heading={(
      <ClientErrorHeading
        clientFirstName={clientFirstName}
        clientLastName={clientLastName}
      />
    )}
    description='Internal Server Error'
  />
)

ClientInternalError.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string
}

export default ClientInternalError
