const CloseModalIcon = (props) => (
  <svg viewBox='0 0 512 512' height='22px' width='22px' {...props}>
    <path
      fill='currentColor'
      d='M32.3033582-.0006698l479.6945187 479.6945187-32.3010351 32.3010351L.0023231 32.3003653z'
    />
    <path
      fill='currentColor'
      d='M479.6947489-.0013769l32.3010351 32.3010351L32.3012653 511.9941769.0002302 479.6931418z'
    />
  </svg>
)

export default CloseModalIcon
