import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Card.less'

const Card = ({ children, className, id }) => (
  <div className={classnames('card-new', className)} id={id}>
    {children}
  </div>
)

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Card
