import T from 'prop-types'
import pluralize from 'pluralize'

import ImportInfo from './ImportInfo'
import {
  getPolicyFieldLabel,
  formatPolicyFieldValue
} from '../../../../helpers/dataImport'

const PolicyUpdateInfo = ({ policyNumber, fields }) => (
  <>
    <span>{policyNumber}</span>

    {fields.map(({ path, oldValue, newValue }) => (
      /* eslint-disable */
      <span key={path} data-pull-right>
        {getPolicyFieldLabel(path)}
        :&nbsp;
        {formatPolicyFieldValue(path, oldValue)}
        &nbsp;->&nbsp;
        {formatPolicyFieldValue(path, newValue)}
      </span>
      /* eslint-enable */
    ))}
  </>
)

const PoliciesUpdated = ({ clientFirstName, clientLastName, policies }) => {
  const heading = (
    <>
      <b>
        {policies.length} {pluralize('policy', policies.length)} updated
      </b>
      {clientFirstName && (
        <>
          &nbsp;-&nbsp;
          {clientFirstName}&nbsp;
          {clientLastName}
        </>
      )}
    </>
  )

  const description = policies.map(policy => (
    <PolicyUpdateInfo key={policy.policyNumber} {...policy} />
  ))

  return (
    <ImportInfo
      heading={heading}
      description={description}
    />
  )
}

PoliciesUpdated.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  policies: T.arrayOf(T.shape({
    policyNumber: T.string,
    fields: T.arrayOf(T.shape({
      path: T.string.isRequired,
      oldValue: T.any,
      newValue: T.any
    })).isRequired
  })).isRequired
}

export default PoliciesUpdated
