import T from 'prop-types'

import { Table } from '../../../misc'
import { ReceivedIncomeTableRow } from './ReceivedIncomeTableRow'
import { TableHeadersCaptions } from './constants'
import { IncomeTable } from '../IncomeTable'

import './ReceivedIncomeTable.less'

const ReceivedIncomeTable = ({ incomeRecords, handleUnreconcile, unreconcileLoading }) => (
  <IncomeTable className='received-income-table'>
    <Table.Header>
      <Table.Row>
        {Object.values(TableHeadersCaptions).map((item) => (
          <Table.HeaderCell key={item.caption}>
            {item.caption}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
    <Table.Body className='income-table__body received-income-table__body'>
      {incomeRecords.map((incomeRecord) => (
        <ReceivedIncomeTableRow
          key={incomeRecord.id}
          {...{
            key: incomeRecord.id,
            incomeRecord,
            handleUnreconcile,
            unreconcileLoading
          }}
        />
      ))}
    </Table.Body>
  </IncomeTable>
)

ReceivedIncomeTable.propTypes = {
  /**
   * Array of income records.
   */
  incomeRecords: T.arrayOf(T.object.isRequired).isRequired,
  /**
   * Un-reconcile payment function
   */
  handleUnreconcile: T.func.isRequired
}

export { ReceivedIncomeTable }
