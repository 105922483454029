import T from 'prop-types'

import { Input } from '../../inputs'
import { BaseField } from '../BaseField'

const InputField = ({ inputProps, value, handleChange, error, label, name, inputId, required, className }) => (
  <BaseField {...{ error, label, required, className }}>
    <Input id={`${name}-${inputId}`} value={value || ''} handleChange={handleChange} {...inputProps} />
  </BaseField>
)

InputField.defaultProps = {
  inputId: ''
}

InputField.propTypes = {
  /**
   * Value to be passed to the Input.
   */
  value: T.oneOfType([T.string, T.number]),
  /**
   * Props to be passed to the input.
   */
  inputProps: T.object,
  /**
   * Pass this prop to make sure no duplicates if iterating.
   */
  inputId: T.string,
  /**
   * Function that receives new values from the Input.
   */
  handleChange: T.func,
  /**
   * Error from Formik.
   */
  error: T.string,
  /**
   * Label to be displayed in the field.
   */
  label: T.string
}

export { InputField }
