import { sortBy, prop } from 'ramda'

export const buildSelectOptions = (values, labels) =>
  sortBy(
    prop('label'),
    Object.values(values).map((value) => ({ value, label: labels[value] }))
  )

export const buildSelectOptionsWithNoSort = (values, labels) =>
  Object.values(values).map((value) => ({ value, label: labels[value] }))
