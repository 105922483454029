import { useQuery } from '@apollo/client'

import { ClientListReportPage } from './ClientListReportPage'
import clientReportGql from '../../../../graphql/queries/reports/clientReport.graphql'

const ClientListReportPageContainer = () => {
  const { data, loading, error } = useQuery(clientReportGql)
  const initialLoading = loading && !data

  const rows = data?.clientsReport?.rows || []

  return <ClientListReportPage {...{ rows, loading: initialLoading, error }} />
}

export { ClientListReportPageContainer }
