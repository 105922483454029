import PropTypes from 'prop-types'
import { descend, prop, sortWith } from 'ramda'

import AuditLogEntryEditableItem from '../AuditLogEntryEditableItem'
import NoAuditLogEntries from '../NoAuditLogEntries'
import { withNoItems } from '../../hoc/pure'

import './AuditLogEntriesList.less'

const sortAuditLogEntries = sortWith([descend(prop('date'))])

const AuditLogEntriesList = ({ limit = 5, auditLogEntries, refetch }) => (
  <div className='audit-log-entries'>
    {sortAuditLogEntries(auditLogEntries).slice(0, limit).map((entry) => (
      <AuditLogEntryEditableItem key={entry.id} auditLogEntry={entry} refetch={refetch} />
    ))}
  </div>
)

AuditLogEntriesList.propTypes = {
  auditLogEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  limit: PropTypes.number
}

const AuditLogEntriesListEnhanced = withNoItems('auditLogEntries', NoAuditLogEntries)(
  AuditLogEntriesList
)

export default AuditLogEntriesListEnhanced
