import { VALID_FORMATS } from './const'
import {
  invoker,
  pipe,
  path,
  head,
  ifElse,
  propEq,
  split,
  last,
  prop,
  curry,
  tap,
  toLower,
  __,
  includes
} from 'ramda'

export const extractFile = ifElse(
  propEq('type', 'drop'),
  pipe(path(['dataTransfer', 'items']), head, invoker(0, 'getAsFile')),
  pipe(path(['target', 'files']), head)
)

const createImageSrc = file => URL.createObjectURL(file)

const getFormat = pipe(prop('name'), split('.'), last, toLower)

export const isValidFormat = pipe(getFormat, includes(__, VALID_FORMATS))

export const handleValidFormat = curry((onChooseFile, setImageSrc, file) =>
  pipe(tap(onChooseFile), createImageSrc, setImageSrc)(file)
)
