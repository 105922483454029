import { prop, path } from 'ramda'

import { Link } from 'react-router-dom'

import { TableHeaders } from './const'

const buildFirstNameCell = row => (
  <Link to={`/secure/clients/${row.id}/summary`}>
    <span className='hide-on-mobile'>{row.fullName}</span>
  </Link>
)

export const rowSchema = [
  {
    dataLabel: TableHeaders.CLIENT_NAME,
    buildData: buildFirstNameCell
  },
  {
    dataLabel: TableHeaders.KNOWN_AS,
    buildData: prop('knownAs')
  },
  {
    dataLabel: TableHeaders.CURRENT_ADVISER,
    buildData: path(['financialAdviser', 'fullName'])
  },
  {
    dataLabel: TableHeaders.ADDRESS_LINE_1,
    buildData: prop('address1')
  },
  {
    dataLabel: TableHeaders.ADDRESS_LINE_2,
    buildData: prop('address2')
  },
  {
    dataLabel: TableHeaders.CITY,
    buildData: prop('city')
  },
  {
    dataLabel: TableHeaders.POST_CODE,
    buildData: prop('postCode')
  },
  {
    dataLabel: TableHeaders.EMAIL,
    buildData: row => <a href={`mailto: ${row.email}`}>{row.email}</a>
  },
  {
    dataLabel: TableHeaders.MOBILE_PHONE,
    buildData: row => <a href={`tel: ${row.mobile}`}>{row.mobile}</a>
  },
  {
    dataLabel: TableHeaders.HOME_PHONE,
    buildData: row => <a href={`tel: ${row.homeTel}`}>{row.homeTel}</a>
  },
  {
    dataLabel: TableHeaders.WORK_PHONE,
    buildData: row => <a href={`tel: ${row.workTel}`}>{row.workTel}</a>
  },
  {
    dataLabel: TableHeaders.LANDLINE,
    buildData: row => <a href={`tel: ${row.landlineTel}`}>{row.landlineTel}</a>
  },
  {
    dataLabel: TableHeaders.VULNERABLE,
    buildData: row => row.vulnerable ? 'Yes' : 'No'
  }
]
