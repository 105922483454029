import classnames from 'classnames'

import { Nav } from '../../nav/Nav'
import { TasksNavItem } from './TasksNavItem'

import './TasksNav.less'

const TasksNav = ({ className, ...props }) => (
  <Nav
    className={classnames('tasks-nav', {
      [className]: className
    })}
    itemComponent={TasksNavItem}
    {...props}
  />
)

export { TasksNav }
