import { Fragment, useMemo } from 'react'
import T from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { SuccessIcon } from '../../icons'
import { Button } from '../../misc'

import './userFormSuccess.less'

const UserFormSuccess = (props) => {
  const { text, doneBtnHandler } = props
  const navigate = useNavigate()

  const onDoneClick = useMemo(
    () => doneBtnHandler && typeof doneBtnHandler === 'function'
      ? doneBtnHandler
      : () => navigate('/'),
    [doneBtnHandler, navigate]
  )

  return (
    <>
      <SuccessIcon className='user-form-success-icon' />
      <p className='user-form-success-text'>
        {text}
      </p>
      <Button
        type='submit'
        onClick={onDoneClick}
        color={Button.Colors.BLUE}
        className='user-form-success__submit-btn'
      >
        Done
      </Button>
    </>
  )
}

UserFormSuccess.propTypes = {
  text: T.string.isRequired,
  doneBtnHandler: T.func
}

export default UserFormSuccess
