import { useMutation } from '@apollo/client'

const PolicyFormContainer = ({
  createPolicyGql,
  updatePolicyGql
}) => WrappedForm => props => {
  const isUpdate = props?.policy && props.policy.id

  let useMutationResult
  if (!isUpdate) {
    useMutationResult = useMutation(createPolicyGql)
  } else {
    useMutationResult = useMutation(updatePolicyGql)
  }

  const [mutate, mutationState] = useMutationResult

  return (
    <WrappedForm {...{
      createPolicyGql,
      updatePolicyGql,
      mutate,
      mutationState,
      ...props
    }}
    />
  )
}

export default PolicyFormContainer
