import * as yup from 'yup'
import stringSchema from '../helpers/stringSchema'

export default yup.object().shape({
  accessCode: stringSchema()
    .label('Access Code')
    .required(),

  pin: stringSchema()
    .label('Pin')
    .required()
}).noUnknown(true)
