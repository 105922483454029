import { useMemo, useCallback, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'

import { SignaturesDashboardCard } from './SignaturesDashboardCard'
import { useSignatureModal } from '../../clientDocument/DocumentsPage/useSignatureModal'
import { PreviewModal } from '../../clientDocument/PreviewModal'

import pendingESignatureDocumentsGql from '../../../graphql/queries/clientDocument/pendingESignatureDocuments.graphql'
import downloadLinkGql from '../../../graphql/queries/clientDocument/clientDocumentDownloadLink.graphql'

const SignaturesDashboardCardContainer = ({ isClientPortal }) => {
  const { data, refetch } = useQuery(pendingESignatureDocumentsGql, {
    fetchPolicy: 'cache-and-network'
  })

  const [downLoadFile, { loading: downloadLoading }] = useLazyQuery(
    downloadLinkGql,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ clientDocument: { downloadUrl } }) =>
        window.location.assign(downloadUrl)
    }
  )

  const onDownloadDocument = useCallback(
    documentId => downLoadFile({ variables: { documentId } }),
    []
  )

  const [docId, setDocId] = useState(null)
  const {
    signatureModalProps,
    closeSignatureModal,
    onClickToSign,
    loading: signatureModalLoading,
    isSignatureModalOpen
  } = useSignatureModal({
    refetch,
    isClientPortal,
    dontNavigateOnCloseModal: true,
    docId,
    onCloseModal: () => setDocId(null)
  })

  const showSignatureModal = useMemo(
    () =>
      isSignatureModalOpen && (
        !!signatureModalProps?.fileDownloadUrl ||
        signatureModalProps?.documentUnavailable
      ), [signatureModalProps]
  )

  const listProps = useMemo(
    () => ({
      documents: data?.pendingESignatureDocuments || [],
      onClickToSign,
      loading: signatureModalLoading || downloadLoading,
      isClientPortal,
      onDownloadDocument,
      onNameClick: setDocId
    }),

    [
      data,
      onClickToSign,
      signatureModalLoading,
      isClientPortal,
      downloadLoading
    ]
  )

  return (
    <>
      <PreviewModal
        isOpen={showSignatureModal}
        close={closeSignatureModal}
        {...signatureModalProps}
      />

      <SignaturesDashboardCard listProps={listProps} />
    </>
  )
}

export { SignaturesDashboardCardContainer }
