import classnames from 'classnames'
import T from 'prop-types'

import { RemoveConnectionButton } from '../RemoveConnectionButton'
import { Heading } from './../../../misc'

import './EmailIntegrationStatus.less'

const EmailIntegrationStatus = props => {
  const {
    email,
    Icon,
    onConnect,
    errorMessage,
    isExpired,
    loading,
    platform,
    removeProps
  } = props

  return (
    <div className='email-integration-status'>
      <Heading className='email-integration-status__header' as='h3'>Email status</Heading>
      <div className='email-integration-status__body'>
        <div className='email-integration-status__body__details'>
          <div className='email-integration-status__body__details__icon-wrapper'>
            <Icon />
          </div>
          <div className='email-integration-status__body__details__email'>{email}</div>
          <div
            className={classnames('email-integration-status__body__details__status', {
              'email-integration-status__body__details__status--active': isExpired
            })}
          >
            {isExpired ? 'Connected' : 'Disconnected'}
          </div>
          {
            (!isExpired && errorMessage)
              ? (
                <div className='email-integration-status__body__details__error-message'>
                  {errorMessage}
                </div>
                )
              : null
          }
        </div>
        <div
          className={classnames('email-integration-status__body__actions', {
            'email-integration-status__body__actions--loading': loading
          })}
        >
          <div
            className='email-integration-status__body__actions__toggle'
            onClick={() => onConnect(platform)}
          >
            {isExpired ? null : 'Connect'}
          </div>

          <RemoveConnectionButton
            className='email-integration-status__body__actions__delete-icon'
            platform={platform}
            {...removeProps}
          />
        </div>
      </div>
    </div>
  )
}

EmailIntegrationStatus.propTypes = {
  Icon: T.elementType.isRequired,
  onConnect: T.func.isRequired,
  loading: T.bool.isRequired,
  isConnected: T.bool.isRequired,
  errorMessage: T.string
}

export { EmailIntegrationStatus }
