import T from 'prop-types'
import classnames from 'classnames'
import { isEmpty } from 'ramda'
import { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'

import { optionValueType } from '../../../../helpers/propTypes'

import '../DropdownSelection.less'

export const DropdownSelectionWithAsync = ({
  key,
  name,
  value,
  inputId,
  className,
  inputProps,
  selectProps,
  onChange,
  isMulti,
  isGrouped,
  loadOptions,
  handleChange,
  selectedValues,
  resultOptions,
  responseExist,
  ...restProps
}) => {
  const { selected } = restProps

  const { isClearable, asyncProps } = inputProps
  const { Option: CustomOption, Control: CustomControl } = selectProps

  const selectedOption = !isEmpty(selectedValues) && !value
    ? selectedValues?.find(item =>
      item?.id === selected
    )
    : selected ? value : null

  const customStyles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base
    }
  }

  const customComponents = {
    Option: CustomOption || components.Option,
    Control: CustomControl || components.Control
  }

  return (
    <AsyncPaginate
      name={name}
      inputId={`${name}-${inputId}`}
      key={key || `${name}__${selected}`} // ? Force re-render when reset from Formik
      classNamePrefix='dropdown-selection'
      className={classnames('dropdown-selection', className)}
      isClearable={isClearable}
      value={selectedOption}
      loadOptions={loadOptions}
      onChange={handleChange}
      getOptionLabel={(e) => e[asyncProps?.labelProp || 'fullName']}
      getOptionValue={(e) => e[asyncProps?.valueProp || 'id']}
      debounceTimeout={asyncProps?.debounceTimeout || 600}
      noOptionsMessage={() => !responseExist
        ? 'Search client name'
        : 'No results found'}
      isMulti={isMulti}
      styles={customStyles}
      components={customComponents}
      theme={theme => ({
        ...theme,
        borderRadius: '3px'
      })}
      {...inputProps}
    />
  )
}

DropdownSelectionWithAsync.defaultProps = {
  inputProps: {
    isClearable: true
  },
  selectProps: {},
  isMulti: false,
  isGrouped: false,
  inputId: ''
}

DropdownSelectionWithAsync.propTypes = {
  name: T.string.isRequired,
  inputId: T.string.isRequired,
  className: T.string,
  inputProps: T.object,
  selectProps: T.object,
  loadOptions: T.func.isRequired,
  placeholder: T.string,
  selected: T.oneOfType([optionValueType(), T.array]),
  debounceTimeout: T.number
}
