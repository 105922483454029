import { withFormik } from 'formik'
import { CompanyUserRoles } from '@elenfs/elen-constants'
import { reject, propEq } from 'ramda'

import { Form } from '../../../form'
import { SelectField } from '../../../form/fields'
import { Button } from '../../../misc'

import './TeamModalForm.less'

const {
  COMPLIANCE,
  OFFICE_ADMINISTRATOR,
  PARAPLANNER,
  ADVISER
} = CompanyUserRoles

const TeamModalForm = props => {
  const { handleSubmit, companyUserOptions, loading } = props

  return (
    <Form onSubmit={handleSubmit} className='team-modal-form'>
      <SelectField
        options={companyUserOptions}
        name={COMPLIANCE}
        label='Compliance'
        inputProps={{ isClearable: true }}
      />

      <SelectField
        options={companyUserOptions}
        name={PARAPLANNER}
        label='Paraplanner'
        inputProps={{ isClearable: true }}
      />

      <SelectField
        options={companyUserOptions}
        name={OFFICE_ADMINISTRATOR}
        label='Administrator'
        inputProps={{ isClearable: true }}
      />

      <Button color={Button.Colors.BLUE} type='submit' disabled={loading}>
        Save
      </Button>
    </Form>
  )
}

const EnhancedTeamModalForm = withFormik({
  mapPropsToValues: ({ team }) => {
    const filteredRoles = reject(propEq('role', ADVISER))(team.roles)
    const initialValues = {}
    filteredRoles.forEach(({ role, userId }) => {
      initialValues[role] = userId
    })
    return initialValues
  },
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(TeamModalForm)

export { EnhancedTeamModalForm as TeamModalForm }
