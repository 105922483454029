import { PendingIncomeTable } from '../IncomeTables/PendingIncomeTable'
import PageContent from '../../misc/PageContent'

import './PendingIncome.less'

const PendingIncome = props => (
  <div className='pending-income'>
    <PageContent>
      <PendingIncomeTable {...props} />
    </PageContent>
  </div>
)

export { PendingIncome }
