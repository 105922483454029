const DownloadIcon = (props) => (
  <svg viewBox='0 0 24 24' width={24} height={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M20 19c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H4c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h16zM12 3c.5522847 0 1 .44771525 1 1v6h2.6384102c.2761423 0 .5.2238576.5.5 0 .0870135-.0227077.1725206-.0658785.2480695l-3.2042886 5.607505c-.2740099.4795174-.8848646.6461142-1.364382.3721042-.1550313-.0885893-.2835149-.2170729-.3721042-.3721042l-3.20428863-5.607505c-.13700499-.2397588-.05370662-.5451861.1860521-.6821911C8.18906922 10.0227077 8.27457637 10 8.36158984 10H11V4c0-.55228475.4477153-1 1-1z'
      />
    </g>
  </svg>
)

export default DownloadIcon
