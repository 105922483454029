import { PageContent } from '../../misc'

import './PolicyDetails.less'

const PolicyDetails = ({ children }) => (
  <PageContent>
    <div className='policy-details'>{children}</div>
  </PageContent>
)

export default PolicyDetails
