import { Button } from './../../misc'
import { DownloadIcon } from './../../icons'

const DownloadReportBtn = ({ color, disabled, onClickFunc, noLabel }) => (
  <Button {...{
    color,
    disabled,
    onClick: onClickFunc,
    className: 'dl-report-btn'
  }}
  >
    <DownloadIcon />
    {!noLabel && <span>Download</span>}
  </Button>
)

DownloadReportBtn.defaultProps = {
  color: Button.Colors.BLUE,
  noLabel: false
}

export { DownloadReportBtn }
