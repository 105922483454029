import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import DropdownIcon from '../../../icons/Dropdown'

const SideMenuNavItem = ({ path, item, onItemClick }) => {
  const { label, submenuKey, onClick } = item

  const onClickCallback = useMemo(
    () => onClick || onItemClick || (() => {}),
    [onClick, onItemClick]
  )

  return (
    <NavLink
      className='mobile-side-menu__nav__item'
      to={path}
      onClick={(event) => onClickCallback(event, item)}
    >
      <span>{label}</span>
      {submenuKey && <DropdownIcon className='nav-item__arrow-icon' />}
    </NavLink>
  )
}

export default SideMenuNavItem
