import { useEffect } from 'react'
import { withFormik, connect } from 'formik'
import { compose } from 'ramda'
import T from 'prop-types'
import classnames from 'classnames'

import { Button } from '..'
import { StaplerIcon } from '../../icons'
import { SelectField, TextAreaField, InputField } from '../../form/fields'
import { FileInputIcon } from '../../form/inputs/FileInputIcon'
import { AttachmentList } from '../../client/ClientMessagesPage/MessagesComponents/MessageInput/AttachmentList'

const FileInputIconComponent = () => (
  <div className='messages-modal__form__file-input'>
    <StaplerIcon /> attach document
  </div>
)

const SendMessageModalForm = props => {
  const {
    attachments,
    onRemoveAttachment,
    onFilesSelect,
    handleSubmit,
    values: { clientId, message, clientName },
    messageSubmitLoading,
    setClientId,
    isClientPortal
  } = props

  useEffect(() => {
    setClientId(clientId)
  }, [setClientId, clientId])

  const disableBtn = !(clientId && message) || messageSubmitLoading

  return (
    <form className='messages-modal__form' onSubmit={handleSubmit}>
      {!isClientPortal && !clientName && (
        <SelectField
          name='clientId'
          label='Client'
          inputProps={{
            async: true,
            isDisabled: messageSubmitLoading,
            asyncProps: {
              labelProp: 'fullName',
              valueProp: 'id',
              clientPortalUser: true
            }
          }}
        />
      )}

      {!isClientPortal && clientName && (
        <InputField
          name='clientName'
          label='Client'
          inputProps={{ disabled: true }}
        />
      )}

      <TextAreaField
        label='Message'
        name='message'
        inputProps={{ disabled: messageSubmitLoading }}
      />

      <div
        className={classnames('messages-modal__form__attachment-list', {
          disabled__attachments: messageSubmitLoading
        })}
      >
        <AttachmentList
          attachments={attachments}
          onRemoveAttachment={onRemoveAttachment}
        />
      </div>

      <FileInputIcon
        icon={FileInputIconComponent}
        multiple
        onChange={onFilesSelect}
        disabled={messageSubmitLoading}
      />

      <Button color={Button.Colors.BLUE} type='submit' disabled={disableBtn}>
        Send message
      </Button>
    </form>
  )
}

SendMessageModalForm.defaultProps = {
  attachments: []
}

SendMessageModalForm.propTypes = {
  onFilesSelect: T.func.isRequired,
  onRemoveAttachment: T.func.isRequired,
  attachments: T.arrayOf(
    T.shape({
      fileName: T.string.isRequired,
      isLoading: T.bool,
      isError: T.bool,
      file: T.instanceOf(window.File).isRequired,
      id: T.string,
      uploadUrl: T.string
    })
  ).isRequired,
  close: T.func.isRequired,
  onMessageSubmit: T.func.isRequired,
  messageSubmitLoading: T.bool.isRequired,
  isClientPortal: T.bool
}

const EnhancedSendMessageModalForm = compose(
  withFormik({
    mapPropsToValues: ({ clientId, clientName }) => ({ clientId, clientName }),
    handleSubmit: async (
      values,
      { props: { onMessageSubmit, close }, resetForm, setErrors }
    ) => {
      try {
        await onMessageSubmit(values?.message)
        resetForm()
        close()
      } catch (error) {
        setErrors({ mutationError: 'Something went wrong.' })
      }
    }
  }),
  connect
)(SendMessageModalForm)

export { EnhancedSendMessageModalForm as SendMessageModalForm }
