import { Modal } from '../../../misc'
import { PolicyValuationDetailsForm } from './PolicyValuationDetailsForm'

const PolicyValuationDetailsModal = props => {
  const { close, policyValuation } = props

  return (
    <Modal {...{
      className: 'policy-valuation-modal',
      contentClassName: 'policy-valuation-modal__modal-content',
      ...props
    }}
    >
      <Modal.Heading>
        {`${policyValuation && policyValuation.id ? 'Edit' : 'New'} valuation`}
      </Modal.Heading>

      <PolicyValuationDetailsForm {...{
        onDone: close,
        policyValuation
      }}
      />
    </Modal>
  )
}

export { PolicyValuationDetailsModal }
