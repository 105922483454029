import { useEffect, useState, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { filter, identity } from 'ramda'

import { PolicyValuationChart } from './PolicyValuationChart'
import {
  filterDateRangeOptions,
  filterDateRanges
} from './helpers'
import { getFromToDateByDateRange } from './../../../../helpers/filterDate'
import { sortListByProp } from '../../../../helpers/sort'
import policyValuationsGql from '../../../../graphql/queries/policy/policyValuations.graphql'

const PolicyValuationChartContainer = props => {
  const { policyId } = props
  const [currentFilter, setCurrentFilter] = useState({ policyId })

  const [getValuationsData, { data }] = useLazyQuery(policyValuationsGql, {
    variables: {
      policyId
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (currentFilter) {
      getValuationsData({ variables: { ...currentFilter } })
    }
  }, [currentFilter, getValuationsData])

  const filterProps = useMemo(
    () => ({
      policyId,
      currentFilter,
      onFilter: setCurrentFilter,
      filterDateRangeOptions,
      filterDateRanges,
      getFromToDateByDateRange,
      initialValues: {
        dateRange: filterDateRanges.ALL_TIME
      }
    }), []
  )

  const policyValuations = data?.policyValuations || []
  const isEmpty = !policyValuations.length
  const sortedPolicyValuations = sortListByProp(policyValuations, 'date', 'ASC')

  const lineChartProps = {
    isEmpty,
    chartData: sortedPolicyValuations
  }

  const downloadReportProps = {
    currentFilter: filter(identity, currentFilter),
    reportName: 'policy-valuations'
  }

  return (
    <PolicyValuationChart {...{
      filterProps,
      lineChartProps,
      downloadReportProps,
      ...props
    }}
    />
  )
}

export { PolicyValuationChartContainer }
