import { ClientTasksTable } from '../TasksTable/ClientTasksTable'
import { TaskDetailsModal } from '../TaskDetailsModal'
import { Spinner } from '../../misc'

const ClientTasksPage = props => {
  const {
    tableProps,
    taskModalProps,
    clientId,
    openNewTaskModal
  } = props

  return (
    <div className='tasks-section--client'>
      <TaskDetailsModal {...{ ...taskModalProps, openNewTaskModal, clientId }} />

      {
        tableProps?.loadingTasks
          ? (
            <Spinner />
            )
          : (
            <ClientTasksTable {...props} />
            )
      }
    </div>
  )
}

export { ClientTasksPage }
