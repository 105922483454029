import { Modal } from '../../misc'
import { PreviewerPDF } from '../PreviewerPDF'

const PreviewModal = props => {
  const {
    id,
    fileName,
    url,
    close,
    onRequestSignature,
    onSignDocument,
    isClientPortal,
    documentUnavailable,
    ...restProps
  } = props

  return (
    <Modal
      fullScreen
      close={close}
      blockEscapeKey={!!(onRequestSignature || onSignDocument)}
      {...restProps}
    >
      <PreviewerPDF
        file={{
          fileName,
          id,
          downloadUrl: url
        }}
        onCancel={close}
        onRequestSignature={onRequestSignature}
        onSignDocument={onSignDocument}
        isClientPortal={isClientPortal}
        documentUnavailable={documentUnavailable}
      />
    </Modal>
  )
}

export { PreviewModal }
