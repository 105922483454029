import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Text } from '../Text'

const CardText = ({ children, className, large, ...props }) => (
  <Text
    as='span'
    className={classnames('card-new__text', className)}
    {...props}
  >
    {children}
  </Text>
)

CardText.defaultProps = {
  large: false
}

CardText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  large: PropTypes.bool
}

export { CardText }
