import PropTypes from 'prop-types'

import FileInputButton from '../../form/inputs/FileInputButton'
import PlusIcon from '../../icons/Plus'

const AddDocumentsButton = ({
  loading,
  onFilesSelected,
  ...props
}) => (
  <FileInputButton
    multiple
    disabled={loading}
    onChange={onFilesSelected}
    responsive
    className='client-documents-page__info__upload-button'
    {...props}
  >
    <PlusIcon />
    <span>New Document</span>
  </FileInputButton>
)

AddDocumentsButton.defaultProps = {
  loading: false
}

AddDocumentsButton.propTypes = {
  loading: PropTypes.bool,
  onFilesSelected: PropTypes.func.isRequired
}

export default AddDocumentsButton
