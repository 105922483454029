const AccountPlus = props => (
  <svg width={16} height={16} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path
        d='M12 14.493a3.333 3.333 0 0 1 3.33 3.167l.003.167v1.666a.833.833 0 0 1-1.66.098l-.006-.098v-1.666c0-.879-.68-1.599-1.543-1.662L12 16.16H5.333c-.878 0-1.598.68-1.662 1.542l-.004.125v1.666a.833.833 0 0 1-1.661.098L2 19.493v-1.666a3.333 3.333 0 0 1 3.167-3.33l.166-.004H12ZM8.667 5.327a4.167 4.167 0 1 1 0 8.333 4.167 4.167 0 0 1 0-8.333Zm0 1.666a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm10-3.333c.46 0 .833.373.833.833V6.16l1.667.001a.833.833 0 1 1 0 1.667H19.5v1.666a.833.833 0 1 1-1.667 0V7.827h-1.666a.833.833 0 1 1 0-1.667h1.666V4.493c0-.46.373-.833.834-.833Z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { AccountPlus }
