import classnames from 'classnames'
import { path } from 'ramda'

import { formatMoney } from '../../../helpers/policy'

export const renderLabel = (formatFunc, propPath, record, index) => {
  const value = path(propPath, record)
  return formatFunc ? formatFunc(value, index) : value
}

export const calcTotal = (propPath, records) => {
  const mapRecords = records.map(path(propPath))
  const { currency } = mapRecords[0] || {}
  const total = mapRecords.reduce((acc, item) => acc + item?.amount, 0)

  return formatMoney({ amount: total, currency })
}

export const formatIcon = (IconComponent, record) => {
  let color = 'green'
  let orientation = 'up'

  if (record?.amount?.amount < 0) {
    color = 'red'
    orientation = 'down'
  }

  return (
    <div
      className={classnames('profit-loss-icon-wrapper',
        `profit-loss-icon-wrapper--${orientation}`,
        `profit-loss-icon-wrapper--${color}`
      )}
    >
      <IconComponent />
    </div>
  )
}
