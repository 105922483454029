import T from 'prop-types'

import { Modal, WarningBanner } from '../../../misc'
import { PolicyTransactionDetailsForm } from './PolicyTransactionDetailsForm'
import { WITHDRAWAL, CONTRIBUTION } from '../../../../constants/policy'
import { isContribution } from '../../../../helpers/policy'

import './PolicyTransactionDetailsModal.less'

const renderChargeBanner = (policyTransactionType) => (
  <WarningBanner>
    {
      isContribution(policyTransactionType)
        ? 'This contribution is accounted for in fees or commissions for your company. Its details cannot be edited further.'
        : 'This withdrawal is accounted for in fees or commissions for your company. Its details cannot be edited further.'
    }
  </WarningBanner>
)

const PolicyTransactionDetailsModal = (props) => {
  const {
    close,
    policyTransaction,
    policyTransactionType,
    policyId
  } = props

  const isTransactionUsedByCharge = policyTransaction?.isUsedByCharge

  return (
    <Modal
      contentClassName='policy-transaction-details-modal-content'
      renderTopBanner={
        isTransactionUsedByCharge
          ? () => renderChargeBanner(policyTransactionType)
          : null
      }
      {...props}
    >
      <Modal.Heading>
        {`${policyTransaction && policyTransaction.id ? 'Edit' : 'New'} ${policyTransactionType}`}
      </Modal.Heading>

      <PolicyTransactionDetailsForm
        {...{
          onDone: close,
          policyTransaction,
          policyTransactionType,
          policyId,
          isTransactionUsedByCharge
        }}
      />
    </Modal>
  )
}

PolicyTransactionDetailsModal.propTypes = {
  close: T.func.isRequired,
  policyTransaction: T.object,
  policyTransactionType: T.oneOf([CONTRIBUTION, WITHDRAWAL]).isRequired,
  policyId: T.string.isRequired
}

export { PolicyTransactionDetailsModal }
