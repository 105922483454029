import { PolicyDetails, AmountCard, DetailsCard } from '../../PolicyDetails'
import { PolicyNoteCard } from '../../PolicyNoteCard'

import { formatDate } from '../../../../helpers/date'
import { formatMoney } from '../../../../helpers/policy'
import {
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from '../../../../helpers/enumLabels/policy'
import {
  DeathBenefitsLabels,
  GuaranteesLabels,
  HeldInTrustLabels,
  InvestmentTypesLabels,
  PlanStatusesLabels
} from '../../../../helpers/enumLabels/investmentPolicy'
import { resolvePolicyHoldersData } from '../helpers'

const InvestmentPolicyDetails = ({ policy, headingText, readOnly }) => {
  const currentValueCardData = [
    { label: 'Valuation Date', value: formatDate(policy.valuationDate) }
  ]
  const cardData = [
    { label: 'Surrender date', value: formatDate(policy.surrenderDate) }
  ]

  const data = [
    [
      {
        label: 'Replacement business',
        value: policy?.isReplacementBusiness ? 'Yes' : 'No'
      },
      { label: 'Category', value: PolicyCategoriesLabels[policy.category] },
      {
        label: 'Policy type',
        value: InvestmentTypesLabels[policy.investmentType]
      },
      { label: 'Policy number', value: policy.number },
      ...resolvePolicyHoldersData(policy)(policy?.currentHolders, readOnly),
      { label: 'Plan status', value: PlanStatusesLabels[policy.planStatus] },
      { label: 'Provider', value: PolicyProvidersLabels[policy.provider] }
    ],
    [
      { label: 'Application date', value: formatDate(policy.applicationDate) },
      { label: 'Start date', value: formatDate(policy.startDate) },
      { label: 'Maturity Date', value: formatDate(policy.maturityDate) }
    ],
    [
      {
        label: 'Death benefit',
        value: DeathBenefitsLabels[policy.deathBenefit]
      },
      {
        label: 'Additional life cover',
        value: formatMoney(policy.additionalLifeCover)
      },
      { label: 'Held in trust', value: HeldInTrustLabels[policy.heldInTrust] },
      { label: 'Guarantees', value: GuaranteesLabels[policy.guarantees] }
    ]
  ]

  return (
    <PolicyDetails>
      <div>
        <DetailsCard {...{ headingText, data, readOnly }} />
      </div>
      <div>
        <AmountCard
          headingText='Current value'
          headingValue={formatMoney(policy.currentValue)}
          cardData={currentValueCardData}
        />
        <AmountCard
          headingText='Surrender value'
          headingValue={formatMoney(policy.surrenderValue)}
          cardData={cardData}
        />

        {!readOnly && <PolicyNoteCard note={policy?.note} policyId={policy?.id} />}
      </div>
    </PolicyDetails>
  )
}

export default InvestmentPolicyDetails
