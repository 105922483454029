import { useCallback } from 'react'

export const resolvePolicyHoldersData = policy => {
  if (!policy) {
    return
  }

  return useCallback(
    (currentHolders = [], readOnly) =>
      currentHolders.map(({ id, fullName, relationship }, index) => {
        const label = !index ? 'Policy holders' : ''
        const value = fullName
        const link = `/secure/clients/${id}`
        const extraTextWithDash = relationship
        const holder = !index
          ? { label, value }
          : { label, value, extraTextWithDash, ...!readOnly && { link } }
        return holder
      }),
    [policy]
  )
}
