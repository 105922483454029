import { useEffect } from 'react'

import { ClientBirthdayLegend } from '../ClientBirthdayLegend'
import { ReportTable } from '../../ReportTable'
import {
  GoBackHeader,
  EmptyPage,
  ErrorMessage,
  Spinner,
  Filter,
  DownloadReportBtn
} from '../../../misc'

import './ClientBirthdayReportPage.less'
import './ClientBirthdayReportTable.less'
import { getRowColorClass } from '../helpers'

const ClientBirthdayReportPage = props => {
  const {
    filterProps,
    tableProps,
    loading,
    error,
    called,
    downloadBtnProps
  } = props

  const { rows } = tableProps

  // Apply row colors
  useEffect(() => {
    if (rows.length) {
      const trows = [...document.querySelectorAll('tbody tr')]
      rows.forEach(({ nextBirthday }, i) => {
        const el = trows[i]
        const classToAdd = getRowColorClass(nextBirthday)
        if (el && classToAdd) el.classList.add(classToAdd)
      })
    }
  }, [rows])

  return (
    <div className='client-birthday-report'>
      <GoBackHeader title='Birthdays' />

      <Filter loading={loading} {...filterProps} />

      {loading && <Spinner />}

      {!rows.length && called && !loading && !error && (
        <EmptyPage text='No records found' hidden={false} />
      )}

      {error && <ErrorMessage text='Failed to load records' />}

      {!!rows.length && (
        <>
          <div className='client-birthday-report__dl-btn'>
            <DownloadReportBtn {...downloadBtnProps} />
          </div>

          <div className='table-wrap'>
            <ClientBirthdayLegend />
            <ReportTable {...tableProps} />
          </div>
        </>
      )}
    </div>
  )
}

export { ClientBirthdayReportPage }
