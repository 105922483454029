import T from 'prop-types'

import Table from '../../misc/Table'
import DocumentRow from '../DocumentRow'

import './DocumentsTable.less'

const DocumentsTable = (props) => {
  const {
    clientDocuments,
    isClientPortal,
    policies,
    policyId,
    onRequestSignatureDocument,
    refetchDocuments,
    onClickToSign,
    isClientUser
  } = props

  return (
    <Table className='client-documents-table'>
      <colgroup>
        <col className='client-documents-table__columns__name' />
        <col className='client-documents-table__columns__policy' />
        <col className='client-documents-table__columns__visibility' />
        <col className='client-documents-table__columns__signing-status' />
        <col className='client-documents-table__columns__manage' />
      </colgroup>

      <Table.Header className='client-documents-table__header'>
        <Table.Row>
          <Table.HeaderCell>File name</Table.HeaderCell>
          <Table.HeaderCell>{!isClientPortal && 'Related Policy'}</Table.HeaderCell>
          <Table.HeaderCell
            className='documents-header-cell__visible'
          >
            {!isClientPortal && 'Visible to client'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            Signing status
          </Table.HeaderCell>

          <Table.HeaderCell> </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {clientDocuments.map((clientDocument) => (
          <DocumentRow
            key={clientDocument.id}
            {...{
              key: clientDocument.id,
              clientDocument,
              isClientPortal,
              policies,
              policyId,
              onRequestSignatureDocument,
              refetchDocuments,
              onClickToSign,
              isClientUser
            }}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

DocumentsTable.propTypes = {
  clientDocuments: T.arrayOf(T.object).isRequired,
  isClientPortal: T.bool.isRequired
}

export default DocumentsTable
