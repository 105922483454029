import { useCallback } from 'react'
import { isEmpty, compose } from 'ramda'
import { withFormik, connect } from 'formik'
import T from 'prop-types'

import { ColourTypes, ColourTypesCodes, ColourTypesLabels } from '../../../../constants/serviceLevels'
import { Form, FormErrorMessages } from '../../../form'
import { InputField, TextAreaField, SelectField } from '../../../form/fields'
import { Button, LoadingMessage } from '../../../misc'
import { ControlWithColors, OptionWithColors } from '../../../misc/DropdownSelection/DropdownCustom'
import { serviceLevelsSchema } from '../../../../schemas/serviceLevels/serviceLevelsSchema'

import { buildInitialValues } from '../../../../helpers/form'
import { parseGraphqlErrors } from '../../../../helpers/error'
import { RemoveServiceLevelsButton } from '../RemoveServiceLevelsButton'

import './ServiceLevelsForm.less'

const BuildServiceLevelsColourOptions = (values, labels) => {
  const options = Object.values(values).map((value) => ({ value, label: labels[value], color: ColourTypesCodes[value]?.toLowerCase() }))
  return options
}

const serviceLevelsColourOptions = BuildServiceLevelsColourOptions(ColourTypes, ColourTypesLabels)

const ServiceLevelsForm = props => {
  const {
    serviceLevel,
    formik: {
      errors,
      submitForm,
      resetForm,
      validateForm,
      setErrors
    },
    loading,
    onDone
  } = props

  const validateAndSubmit = useCallback(async () => {
    try {
      const errors = await validateForm()
      if (Object.keys(errors).length) {
        return false
      }

      await submitForm()
      return true
    } catch (e) {
      return false
    }
  }, [submitForm])

  const onDoneClick = useCallback(async () => {
    if (await validateAndSubmit()) {
      onDone()
    }
  }, [validateAndSubmit])

  const onSaveAndAddNewClick = useCallback(async () => {
    if (await validateAndSubmit()) {
      resetForm()
    }
  }, [validateAndSubmit])

  return (
    <Form className='service-levels-details-form'>
      <InputField name='name' label='Name' required />
      <TextAreaField name='description' label='Description' />

      <SelectField
        name='colour'
        label='Colour'
        required
        options={serviceLevelsColourOptions}
        inputProps={{ isClearable: false }}
        selectProps={{
          Control: ControlWithColors,
          Option: OptionWithColors
        }}
      />

      {loading && <LoadingMessage />}
      {!isEmpty(errors) && <FormErrorMessages validationErrors={errors} />}
      <Form.ButtonGroup>
        <Button color={Button.Colors.BLUE} onClick={onDoneClick}>
          Save
        </Button>

        {!serviceLevel && (
          <Button color={Button.Colors.GREEN} onClick={onSaveAndAddNewClick}>
            Save and add new
          </Button>
        )}

        {serviceLevel && <RemoveServiceLevelsButton
          {...{
            closeModal: onDone,
            serviceLevelId: serviceLevel.id,
            setErrors
          }}
                         />}
      </Form.ButtonGroup>
    </Form>
  )
}

const ServiceLevelsFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: serviceLevelsSchema,
    mapPropsToValues: ({ serviceLevel = {} }) => buildInitialValues(serviceLevelsSchema, {
      ...serviceLevel
    }),
    handleSubmit: async (values, { props, setErrors }) => {
      const {
        mutate,
        serviceLevel
      } = props
      values = serviceLevelsSchema.cast(values, { stripUnknown: true })

      if (serviceLevel && serviceLevel.id) {
        values = {
          ...values,
          id: serviceLevel.id
        }
      }

      try {
        const result = await mutate({ variables: { input: { ...values } } })
        return result
      } catch (err) {
        setErrors(
          parseGraphqlErrors(
            err,
            'There was an error saving the service level, please try again later'
          )
        )
        throw err
      }
    }
  }),
  connect
)(ServiceLevelsForm)

ServiceLevelsFormEnhanced.propTypes = {
  /**
   * Is any mutation loading. Eg mutation for saving or for deleting the charge.
   */
  loading: T.bool,
  /**
   * Callback to execute when form submits.
   */
  onDone: T.func.isRequired,
  /**
   * Callback to mutate (submit) the form.
   */
  mutate: T.func.isRequired,
  /**
   * Callback to delete the charge.
   */
  onDelete: T.func,
  /**
   * Charge that's being edited.
   */
  charge: T.object
}

export { ServiceLevelsFormEnhanced as ServiceLevelsForm }
