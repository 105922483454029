import classnames from 'classnames'
import T from 'prop-types'

import './HTMLPreview.less'

const renderHtmlBody = (body) => `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Email preview</title>
</head>
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #BFC5CF;
    background-color: transparent;
  }
  ::-webkit-scrollbar:horizontal {
    background-color: transparent;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: #e2e7ee;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7.5px;
    background-color: #BFC5CF;
  }
}
</style>
<body>
  ${body}
</body>
</html>
`

const HTMLPreview = ({ body = '', className }) => {
  return (
    <iframe
      srcDoc={renderHtmlBody(body)}
      className={classnames('html-preview', className)}
    />
  )
}

HTMLPreview.propTypes = {
  body: T.string.isRequired,
  classnames: T.string
}

export { HTMLPreview }
