import { useEffect, useRef } from 'react'
import classnames from 'classnames'

import { formatMoney } from '../../../../helpers/policy'
import { calcBarsWidth } from './helpers'

import './IncomeChart.less'

const IncomeChart = ({ income, loading }) => {
  const { expected: pending, received } = income
  const barsRef = useRef({})

  useEffect(() => {
    const pendingChartBar = barsRef.current.pending
    const receivedChartBar = barsRef.current.received

    calcBarsWidth(
      pending?.amount,
      received?.amount,
      pendingChartBar,
      receivedChartBar
    )
  }, [pending, received])

  return (
    <div className='chart-container'>
      <div className='chart-container__chart'>
        <div className='chart-container__chart__label'>Pending</div>
        <div
          className={classnames('chart-container__chart__bar', {
            'bar-loading': loading
          })}
          ref={ref => (barsRef.current.pending = ref)}
        />
        <div className='chart-container__chart__amount'>
          {formatMoney(pending)}
        </div>
      </div>

      <div className='chart-container__chart'>
        <div className='chart-container__chart__label'>Received</div>
        <div
          className={classnames('chart-container__chart__bar', 'green-bg', {
            'bar-loading': loading
          })}
          ref={ref => (barsRef.current.received = ref)}
        />

        <div className='chart-container__chart__amount'>
          {formatMoney(received)}
        </div>
      </div>
    </div>
  )
}

export { IncomeChart }
