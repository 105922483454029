const Users = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M11.805 13.36a3.162 3.162 0 013.152 3.168v1.728a.862.862 0 01-.86.863.862.862 0 01-.859-.863v-1.728c0-.794-.643-1.44-1.433-1.44H5.502c-.79 0-1.433.646-1.433 1.44v1.728a.862.862 0 01-.86.863.862.862 0 01-.859-.863v-1.728a3.162 3.162 0 013.152-3.168h6.303zm6.706-1.089c1.732 0 3.139 1.44 3.139 3.21v.584a.866.866 0 01-.856.875.866.866 0 01-.856-.875v-.584c0-.804-.64-1.459-1.427-1.459h-2.853a.866.866 0 01-.856-.876c0-.483.383-.875.856-.875h2.853zM8.731 4.8a3.428 3.428 0 013.425 3.424 3.428 3.428 0 01-3.425 3.424 3.428 3.428 0 01-3.424-3.424A3.428 3.428 0 018.731 4.8zm8.405 1.712c1.288 0 2.335 1.012 2.335 2.257s-1.047 2.257-2.335 2.257c-1.287 0-2.334-1.012-2.334-2.257s1.047-2.257 2.334-2.257zm-8.405 0c-.943 0-1.712.768-1.712 1.712s.769 1.712 1.712 1.712c.944 0 1.713-.768 1.713-1.712S9.675 6.512 8.73 6.512zm8.405 1.693a.574.574 0 00-.583.564c0 .311.261.564.583.564a.574.574 0 00.584-.564.574.574 0 00-.584-.564z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { Users }
