import T from 'prop-types'

import { formatMoney } from '../../../../helpers/policy'
import Card from '../../../misc/Card'
import { useCurrentUser } from '../../../../hooks'

const PolicyTransactionsTotals = ({
  total,
  contributions,
  transfersIn,
  withdrawals,
  transfersOut
}) => {
  const { currency } = useCurrentUser()

  return (
    <Card className='transactions-total'>
      <Card.Group className='card-new-group--inline card-new-group--heading'>
        <Card.Heading>
          Net contributions
        </Card.Heading>
        <Card.Heading>
          {formatMoney({ currency, amount: total })}
        </Card.Heading>
      </Card.Group>

      <Card.Group className='card-new-group--inline'>
        <Card.Text className='card-new__text--faded'>
          Contributions
        </Card.Text>
        <Card.Text>
          {formatMoney({ currency, amount: contributions })}
        </Card.Text>
      </Card.Group>

      <Card.Group className='card-new-group--inline'>
        <Card.Text className='card-new__text--faded'>
          Transfers in
        </Card.Text>
        <Card.Text>
          {formatMoney({ currency, amount: transfersIn })}
        </Card.Text>
      </Card.Group>

      <Card.Group className='card-new-group--inline'>
        <Card.Text className='card-new__text--faded'>
          Withdrawals
        </Card.Text>
        <Card.Text>
          {formatMoney({ currency, amount: withdrawals })}
        </Card.Text>
      </Card.Group>

      <Card.Group className='card-new-group--inline'>
        <Card.Text className='card-new__text--faded'>
          Transfers out
        </Card.Text>
        <Card.Text>
          {formatMoney({ currency, amount: transfersOut })}
        </Card.Text>
      </Card.Group>
    </Card>
  )
}

PolicyTransactionsTotals.propTypes = {
  className: T.string,
  total: T.number.isRequired,
  contributions: T.number.isRequired,
  transfersIn: T.number.isRequired,
  withdrawals: T.number.isRequired,
  transfersOut: T.number.isRequired
}

export { PolicyTransactionsTotals }
