import { useCallback, useState } from 'react'
import T from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { pathOr } from 'ramda'

import { CustomerSupportSection } from './CustomerSupportSection'
import { withCurrentUser } from '../../hoc/container'
import ticketReasonsQuery from '../../../graphql/queries/customerSupport/ticketReasons.graphql'
import createTicketMutation from '../../../graphql/mutations/customerSupport/createSupportTicket.graphql'

const CustomerSupportSectionContainer = (props) => {
  const { currentUser } = props
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const [isSuccessModalOpen, setSuccessModalState] = useState(false)
  const [createTicket, { loading: mutationLoading }] = useMutation(createTicketMutation)
  const { data = {} } = useQuery(ticketReasonsQuery, { fetchPolicy: 'cache-and-network' })

  const closeSuccessModal = useCallback(() => {
    setSuccessModalState(false)
    goBack()
  }, [setSuccessModalState, goBack])

  const onTicketSubmit = useCallback(async (formData) => {
    const { message, reason, subject } = formData
    const { data, error } = await createTicket({
      variables: {
        input: {
          reason, subject, message
        }
      }
    })

    const operationSuccess = pathOr(false, ['createSupportTicket', 'success'], data)

    if (!error && operationSuccess) {
      setSuccessModalState(true)
    }
  }, [createTicket, setSuccessModalState])

  return (
    <CustomerSupportSection {...{
      goBack,
      ticketReasons: data.ticketReasonsList,
      onTicketSubmit,
      isSuccessModalOpen,
      closeSuccessModal,
      currentUser,
      mutationLoading
    }}
    />
  )
}

CustomerSupportSectionContainer.propTypes = {
  currentUser: T.object
}

export default withCurrentUser()(CustomerSupportSectionContainer)
