import { useEffect } from 'react'

import { PreviewModal } from './PreviewModal'

const PreviewModalContainer = props => {
  const { fileDownloadUrl, getDownloadLink, isOpen, ...restProps } = props

  useEffect(() => {
    if (isOpen && getDownloadLink) getDownloadLink()
  }, [isOpen, getDownloadLink])

  return <PreviewModal isOpen={isOpen} url={fileDownloadUrl} {...restProps} />
}

export { PreviewModalContainer }
