import T from 'prop-types'

import { CustomerSupportHeader } from './CustomerSupportHeader'
import { CustomerSupportForm } from './CustomerSupportForm'
import { CustomerSupportInfo } from './CustomerSupportInfo'
import { SupportRequestSuccess } from '../SupportRequestSuccess'
import { FAQCard } from './FAQCard'
import { Modal } from '../../misc'

import './customerSupportSection.less'

export const CustomerSupportSection = ({
  goBack,
  ticketReasons,
  onTicketSubmit,
  isSuccessModalOpen,
  closeSuccessModal,
  currentUser,
  mutationLoading
}) => (
  <div className='customer-support-section'>
    <div className='customer-support-section__main-container'>
      <CustomerSupportHeader onExClick={goBack} />
      <div className='customer-support-section__form-info-faq-container'>
        <div className='form-and-info-container'>
          <CustomerSupportForm
            {...{
              ticketReasons,
              onTicketSubmit,
              onCancelClick: goBack,
              currentUser,
              mutationLoading
            }}
          />
          <CustomerSupportInfo />
        </div>
        <FAQCard />
      </div>
    </div>
    <Modal
      isOpen={isSuccessModalOpen}
      close={closeSuccessModal}
      trigger={<> </>}
    >
      <SupportRequestSuccess />
    </Modal>
  </div>
)

CustomerSupportSection.propTypes = {
  goBack: T.func.isRequired,
  ticketReasons: T.arrayOf(T.shape({ label: T.string, value: T.string })),
  onTicketSubmit: T.func.isRequired,
  isSuccessModalOpen: T.bool.isRequired,
  closeSuccessModal: T.func.isRequired,
  currentUser: T.object
}
