export const FilterDateRanges = Object.freeze({
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  THIS_MONTH: 'THIS_MONTH',
  THIS_MONTH_TO_DATE: 'THIS_MONTH_TO_DATE',
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_QUARTER: 'LAST_QUARTER',
  THIS_YEAR_TO_DATE: 'THIS_YEAR_TO_DATE',
  THIS_QUARTER_TO_DATE: 'THIS_QUARTER_TO_DATE',
  LAST_HALF_YEAR: 'LAST_HALF_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  CURRENT_YEAR: 'CURRENT_YEAR',
  CUSTOM: 'CUSTOM',
  NEXT_MONTH: 'NEXT_MONTH',
  NEXT_QUARTER: 'NEXT_QUARTER',
  NEXT_WEEK: 'NEXT_WEEK',
  FROM_TODAY_ONWARD: 'FROM_TODAY_ONWARD',
  THIS_WEEK: 'THIS_WEEK',
  LAST_THREE_YEARS: 'LAST_THREE_YEARS',
  LAST_FIVE_YEARS: 'LAST_FIVE_YEARS',
  LAST_TEN_YEARS: 'LAST_TEN_YEARS',
  ALL_TIME: 'ALL_TIME'
})

export const FilterDateRangeLabels = Object.freeze({
  [FilterDateRanges.LAST_WEEK]: 'Last week',
  [FilterDateRanges.LAST_MONTH]: 'Last month',
  [FilterDateRanges.THIS_MONTH_TO_DATE]: 'This month to date',
  [FilterDateRanges.CURRENT_MONTH]: 'This month',
  [FilterDateRanges.LAST_QUARTER]: 'Last quarter',
  [FilterDateRanges.THIS_YEAR_TO_DATE]: 'This year to date',
  [FilterDateRanges.THIS_QUARTER_TO_DATE]: 'This quarter to date',
  [FilterDateRanges.LAST_HALF_YEAR]: 'Last half year',
  [FilterDateRanges.LAST_YEAR]: 'Last year',
  [FilterDateRanges.CURRENT_YEAR]: 'Current year',
  [FilterDateRanges.CUSTOM]: 'Custom',
  [FilterDateRanges.NEXT_MONTH]: 'Next month',
  [FilterDateRanges.NEXT_QUARTER]: 'Next quarter',
  [FilterDateRanges.NEXT_WEEK]: 'Next week',
  [FilterDateRanges.FROM_TODAY_ONWARD]: 'From today onward',
  [FilterDateRanges.THIS_WEEK]: 'This week',
  [FilterDateRanges.THIS_MONTH]: 'This month',
  [FilterDateRanges.LAST_THREE_YEARS]: 'Last three years',
  [FilterDateRanges.LAST_FIVE_YEARS]: 'Last five years',
  [FilterDateRanges.LAST_TEN_YEARS]: 'Last ten years',
  [FilterDateRanges.ALL_TIME]: 'All time'
})
