import { useState, useCallback, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTableSort } from './../../../misc/Table/useSortTable'

import { SelectField } from '../../../form/fields'
import { AssetsUnderManagementPage } from './AssetsUnderManagementPage'
import { withFinancialAdvisers } from '../../../hoc/container'

import { formatMoney } from '../../../../helpers/policy'
import {
  policyTypeOptions as policyCategoryOptions,
  providerOptions
} from '../../../../helpers/selectOptions'
import {
  onFilterValuesChange,
  filterPolicyCategoryOptions,
  sortableTableHeaders,
  rowSchema
} from '../helpers'

import AUMReportGql from '../../../../graphql/queries/reports/AUMReport.graphql'

const AssetsUnderManagementPageContainer = props => {
  const { financialAdviserOptions } = props
  const [policySubcategoryOptions, setPolicySubcategoryOptions] = useState([])
  const [currentFilter, setCurrentFilter] = useState({})

  const [getAUMReport, { data, loading, error, called }] = useLazyQuery(
    AUMReportGql,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  const initialLoading = loading && !data

  const records = data?.AUMReport?.rows || []
  const total = data?.AUMReport?.total

  const { sortedData, headersState, onSort, onResetSort } = useTableSort({
    tableHeaders: sortableTableHeaders,
    data: records
  })

  const onFilter = useCallback(
    filter => {
      getAUMReport({ variables: { filter } })
      setCurrentFilter(filter)
    },
    [getAUMReport, setCurrentFilter]
  )

  useEffect(() => {
    onFilter({})
  }, [])

  const filterProps = {
    onSubmit: onFilter,
    onValuesChange: values =>
      onFilterValuesChange(values, setPolicySubcategoryOptions),
    inputsArr: [
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'adviserId',
          options: financialAdviserOptions,
          inputProps: { placeholder: 'Current adviser', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'category',
          options: filterPolicyCategoryOptions(policyCategoryOptions),
          inputProps: { placeholder: 'Product', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'subCategory',
          options: policySubcategoryOptions,
          inputProps: { placeholder: 'Policy type', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'provider',
          options: providerOptions,
          inputProps: { placeholder: 'Provider', isClearable: true }
        }
      }
    ],
    summary: !!total?.amount && formatMoney(total)
  }

  const downloadBtnProps = {
    currentFilter,
    reportName: 'policies/aum'
  }

  const tableProps = {
    rows: sortedData,
    rowSchema,
    sortableTableHeaders: headersState,
    className: 'aum-report-table',
    onSort,
    onResetSort
  }

  return (
    <AssetsUnderManagementPage
      {...{
        filterProps,
        tableProps,
        records,
        loading: initialLoading,
        error,
        called,
        downloadBtnProps
      }}
    />
  )
}

const EnhancedAssetsUnderManagementPageContainer = withFinancialAdvisers()(
  AssetsUnderManagementPageContainer
)

export { EnhancedAssetsUnderManagementPageContainer as AssetsUnderManagementPageContainer }
