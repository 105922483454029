import './CSVGuide.less'

export const CSVGuide = () => (
  <div className='csv-guide'>
    <p>
      After you have populated the template you can start importing your clients and policies:
    </p>
    <ul>
      <li>
        Click Browse file and select your saved CSV containing your client data in the pop-up window, then click Open
      </li>
      <li>
        Click Import data
      </li>
      <li>
        You should then see a popup in the top-right of the screen: “Syncing with CSV in progress”
      </li>
      <li>
        Once the sync is completed, if there are no errors,all  clients and policies will be created in the system
      </li>
    </ul>
  </div>
)
