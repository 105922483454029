import { useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CompanyUserRoles } from '@elenfs/elen-constants'
import { propEq } from 'ramda'

import { TeamModalForm } from './TeamModalForm'

import companyUsersGql from '../../../../graphql/queries/user/companyUsers.graphql'
import updateTeamGql from '../../../../graphql/mutations/teams/updateTeam.graphql'

const { ADVISER } = CompanyUserRoles

const TeamModalFormContainer = ({ isOpen, close, team }) => {
  const [fetchUsers, { data, loading: queryLoading }] = useLazyQuery(
    companyUsersGql,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const [updateTeam, { loading: mutationLoading }] = useMutation(updateTeamGql)

  useEffect(() => {
    if (isOpen) fetchUsers()
  }, [fetchUsers, isOpen])

  const companyUsers = data?.users || []
  const companyUserOptions = companyUsers.map(({ id, fullName }) => ({
    label: fullName,
    value: id
  }))

  const onSubmit = useCallback(async values => {
    const roles = []

    const adviserRole = team.roles.find(propEq('role', ADVISER))
    if (adviserRole) {
      roles.push({ role: ADVISER, userId: adviserRole.userId })
    }

    for (const [role, userId] of Object.entries(values)) {
      if (userId) {
        roles.push({ role, userId })
      }
    }
    const input = { id: team.id, roles }
    await updateTeam({ variables: { input } })
    close()
  }, [team])

  return (
    <TeamModalForm
      {...{
        companyUserOptions,
        loading: mutationLoading || queryLoading,
        onSubmit,
        team
      }}
    />
  )
}

export { TeamModalFormContainer }
