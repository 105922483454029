import { compose } from 'ramda'

import { buildCustomOptionsWithUser } from './helpers'
import {
  withCurrentUser,
  withCurrentClient,
  withCompanyUsers
} from './../../hoc/container'

import { RecipientModalTrigger } from './RecipientModalTrigger'

const RecipientModalTriggerContainer = props => {
  const {
    currentClient,
    currentUser,
    companyUsers,
    clientDocument,
    onRequestSignatureDocument,
    disabled,
    children
  } = props

  const { id, fileName } = clientDocument || {}

  const customOptionsWithUser = buildCustomOptionsWithUser({
    companyUsers,
    currentUser,
    currentClient
  })

  const onNextClick = ({ recipient }) =>
    onRequestSignatureDocument({ id, fileName, recipient })

  const modalProps = {
    onNextClick,
    customOptionsWithUser,
    client: currentClient
  }

  return (
    <RecipientModalTrigger disabled={disabled} modalProps={modalProps}>
      {children}
    </RecipientModalTrigger>
  )
}

const EnhancedRecipientModalTriggerContainer = compose(
  withCurrentClient(),
  withCurrentUser(),
  withCompanyUsers()
)(RecipientModalTriggerContainer)

export { EnhancedRecipientModalTriggerContainer as RecipientModalTriggerContainer }
