import { useCallback, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { CompanyUserRoles } from '@elenfs/elen-constants'

import { useLocalStorageUserSettings, localStorageUserSettingsKeys, useCashCalc, useGetCurrentPaginationNumber } from '../../../hooks'
import { ClientsListTable } from '../ClientsListTable'
import { Pagination } from '../../misc'
import { withCurrentUser } from '../../hoc/container'
import { paginationLimit } from '../constants'

import searchClientsGql from '../../../graphql/queries/client/searchClientsByKeyword.graphql'

import './ClientList.less'

const ClientList = ({ currentUser }) => {
  const { urlPageNumber } = useGetCurrentPaginationNumber()

  const currentUserId = currentUser?.id
  const isCurrentUserFA = currentUser?.role === CompanyUserRoles.ADVISER

  const { isCashCalcConnectionActive } = useCashCalc()

  const [searchInput, setSearchInput] = useState('')
  const [clients, setClients] = useState([])
  const { userSettingData, persistUserSettingsData } = useLocalStorageUserSettings(
    localStorageUserSettingsKeys.SHOW_MY_CLIENTS_ONLY
  )

  const [paginationData, setPaginationData] = useState({
    page: 0,
    perPage: 1,
    count: null
  })

  const financialAdviserId = userSettingData ? currentUserId : undefined
  const limit = paginationLimit
  const paginationVariable = {
    limit,
    offset: Math.ceil(Math.abs(limit * urlPageNumber)) || 0,
    financialAdviserId
  }

  const handleOnCompleteCallback = (data) => {
    const { result, ...rest } = data?.clients
    setClients(data?.clients?.result)
    setPaginationData((prevState) => ({
      ...prevState, ...rest
    }))
  }

  const pageCount = Math.ceil(paginationData?.count / limit)

  const { loading: clientLoading, fetchMore, refetch } = useQuery(searchClientsGql, {
    variables: paginationVariable,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => handleOnCompleteCallback(data)
  })

  const [searchClientsByKeyword, { loading: searchLoading }] = useLazyQuery(searchClientsGql, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => handleOnCompleteCallback(data)
  })

  const refetchClients = () => refetch(paginationVariable)

  const loading = clientLoading || searchLoading

  const handleClientSearch = useCallback(
    (searchInput) => {
      setSearchInput(searchInput)
      searchClientsByKeyword({
        variables: {
          searchInput: searchInput !== '' ? searchInput : undefined,
          ...paginationVariable
        }
      })
    },
    [searchInput, userSettingData]
  )

  const handlePaginationChange = useCallback(
    (page) => {
      window.history.replaceState(null, '', `/secure/clients?page=${page + 1}`)

      const offset = Math.ceil(Math.abs(limit * page))
      fetchMore({
        variables: {
          searchInput: searchInput !== '' ? searchInput : undefined,
          offset,
          limit,
          financialAdviserId
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.clients) return prev
          return { ...prev, ...fetchMoreResult }
        }
      })
    },
    [fetchMore, searchInput]
  )

  return (
    <div className='client-list'>
      <ClientsListTable
        clients={clients}
        loading={loading}
        persistUserSettingsData={persistUserSettingsData}
        userSettingData={userSettingData}
        isCurrentUserFA={isCurrentUserFA}
        handleClientSearch={handleClientSearch}
        refetchClients={refetchClients}
        isCashCalcConnectionActive={isCashCalcConnectionActive}
      />

      {!loading && !!clients?.length && pageCount > 1 && (
        <div className='client-list__pagination'>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePaginationChange}
            forcePage={urlPageNumber}
          />
        </div>
      )}
    </div>
  )
}

const ClientListContainer = withCurrentUser()(ClientList)

export { ClientListContainer as ClientList }
