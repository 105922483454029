import { pick, prop, path } from 'ramda'

import { Link } from 'react-router-dom'

import constants from '@elenfs/elen-constants'

import { formatDate } from './../../../helpers/date'
import { formatMoney } from '../../../helpers/policy'
import { TableHeaders } from './const'
import {
  FilterDateRanges,
  FilterDateRangeLabels
} from '../../../constants/filterDate'
import { buildSelectOptionsWithNoSort } from '../../form/fields/SelectField'

const {
  PolicyHoldersLabels,
  ContributionMethodsLabels,
  WithdrawalMethodsLabels,
  ContributorsLabels,
  ContributionFrequenciesLabels,
  WithdrawalFrequenciesLabels
} = constants.policy.common

export const buildTableHeaders = isContribution => {
  const headers = { ...TableHeaders }
  if (isContribution) delete headers.PAID_TO
  if (!isContribution) delete headers.PAID_BY
  return headers
}

export const buildRowSchema = (isContribution, tableHeaders) => {
  const methodLabel = isContribution
    ? ContributionMethodsLabels
    : WithdrawalMethodsLabels
  const frequencyLabel = isContribution
    ? ContributionFrequenciesLabels
    : WithdrawalFrequenciesLabels

  const paidByToLabel = isContribution
    ? ContributorsLabels
    : PolicyHoldersLabels

  const buildPolicyNumber = row => {
    const policyId = path(['policy', 'id'], row)
    const policyNumber = path(['policy', 'number'], row)
    const currentHolders = path(['policy', 'currentHolders'], row)

    const clientId = currentHolders[0]?.id

    const to = `/secure/clients/${clientId}/policies/${policyId}/details`

    return <Link to={to}> {policyNumber} </Link>
  }

  return [
    {
      dataLabel: tableHeaders.POLICY_NUMBER,
      buildData: row => buildPolicyNumber(row)
    },
    {
      dataLabel: tableHeaders.PAID_BY || tableHeaders.PAID_TO,
      buildData: row =>
        paidByToLabel[prop(isContribution ? 'contributor' : 'paidTo', row)]
    },
    {
      dataLabel: tableHeaders.METHOD,
      buildData: row => methodLabel[prop('method', row)]
    },
    {
      dataLabel: tableHeaders.DATE,
      buildData: row => formatDate(prop('startDate', row))
    },
    {
      dataLabel: tableHeaders.FREQUENCY,
      buildData: row => frequencyLabel[prop('frequency', row)]
    },
    {
      dataLabel: tableHeaders.AMOUNT,
      buildData: row => formatMoney(prop('amount', row))
    },
    {
      dataLabel: tableHeaders.POLICY_HOLDERS,
      buildData: row => (
        <>
          {row?.policy?.currentHolders?.map(({ fullName }, i) => (
            <div key={i}> {fullName} </div>
          ))}
        </>
      )
    }
  ]
}

const filterDateRangeKeys = [
  FilterDateRanges.LAST_WEEK,
  FilterDateRanges.LAST_MONTH,
  FilterDateRanges.THIS_MONTH_TO_DATE,
  FilterDateRanges.LAST_QUARTER,
  FilterDateRanges.THIS_QUARTER_TO_DATE,
  FilterDateRanges.THIS_YEAR_TO_DATE,
  FilterDateRanges.LAST_HALF_YEAR,
  FilterDateRanges.LAST_YEAR,
  FilterDateRanges.CUSTOM
]

export const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)

const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)
