import T from 'prop-types'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import constants from '@elenfs/elen-constants'

import ClientSignupPage from '../../client/ClientSignupPage'
import { ClientSecureSection } from '../../client/ClientSecureSection'
import LoginPage from '../../user/LoginPage'
import { NotFoundPage } from '../'
import ForgotPasswordPage from '../../user/ForgotPasswordPage'
import RecoverPasswordSuccessPage from '../../user/RecoverPasswordSuccessPage'
import RecoverPasswordPage from '../../user/RecoverPasswordPage'

const { UserTypes } = constants.user

const ClientSection = props => {
  const { currentUser } = props

  return (
    <>
      <Helmet>
        <link rel='manifest' href='/clients-manifest.json' />
      </Helmet>
      <Routes>
        <Route
          path='secure/*'
          element={<ClientSecureSection {...props} />}
        />
        <Route
          path='login'
          element={<LoginPage userType={UserTypes.CLIENT} />}
        />
        <Route
          path='signup/:invitationToken'
          element={<ClientSignupPage {...props} />}
        />
        <Route
          path='forgot-password'
          element={currentUser ? <Navigate to='/' /> : <ForgotPasswordPage {...{ userType: UserTypes.CLIENT }} />}
        />
        <Route
          path='reset-password/success'
          element={<RecoverPasswordSuccessPage {...{ userType: UserTypes.CLIENT }} />}
        />
        <Route
          path='reset-password/:token'
          element={<RecoverPasswordPage {...{ userType: UserTypes.CLIENT }} />}
        />
        <Route path='*' element={<NotFoundPage />} />
        <Route path='/' element={<Navigate replace to='secure/overview' />} />
      </Routes>
    </>
  )
}

ClientSection.propTypes = {
  currentUser: T.object
}

export default ClientSection
