import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Colors } from './constants'

import './Button.less'

/**
 * Button component. Renders a button by default,
 * but it can be configured to render whatever element is passed to it through
 * `as` prop.
 */
const Button = ({
  as: Component,
  children,
  className,
  color,
  responsive,
  ...rest
}) => (
  <Component
    className={classnames('button-new', {
      [`button-new--${color}`]: color,
      'button-new--responsive': responsive,
      [className]: className
    })}
    {...rest}
  >
    {children}
  </Component>
)

Button.defaultProps = {
  type: 'button',
  as: 'button'
}

Button.propTypes = {
  /**
   * Button type.
   */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**
   * A component that should be rendered as a 'button'
   * (ie, should look like one).
   * For example, a dropdown.
   */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /**
   * Children of the button element. For example, an icon, or some text.
   */
  children: PropTypes.node.isRequired,
  /**
   * Custom classname for the button element.
   */
  className: PropTypes.string,
  /**
   * Disables/enables the button.
   */
  disabled: PropTypes.bool,
  /**
   * Color styles for the button.
   */
  color: PropTypes.oneOf(Object.values(Colors)),
  /**
   * onClick handler.
   */
  onClick: PropTypes.func,
  /**
   * Is the button responsive or not.
   */
  responsive: PropTypes.bool
}

export default Button
