import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ImportLogTable } from '../ImportLogLayout'

const ImportLog = ({ className, dataImportJobs, ...props }) => (
  <div className={classnames('import-log', className)}>
    <ImportLogTable
      dataImportJobs={dataImportJobs}
      {...props}
    />
  </div>
)

ImportLog.propTypes = {
  className: PropTypes.string,
  dataImportJobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      failureType: PropTypes.string,
      completedAt: PropTypes.string
    })
  )
}

export { ImportLog }
