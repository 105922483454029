import { useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { isEmpty } from 'ramda'
import { withCurrentUser } from './../../../hoc/container'

import { ClientActions } from './ClientActions'
import { Toastr } from './../../../misc'

import canClientBeRemovedGql from '../../../../graphql/queries/client/canClientBeRemoved.graphql'
import clientsListGql from '../../../../graphql/queries/client/clientsList.graphql'
import removeClientGql from '../../../../graphql/mutations/client/removeClient.graphql'
import tasksGql from '../../../../graphql/queries/tasks/tasks.graphql'

const updateCache = (cache, clientId) => {
  if (cache.data.data.ROOT_QUERY.clients) {
    const { clients } = cache.readQuery({ query: clientsListGql })

    cache.writeQuery({
      query: clientsListGql,
      data: {
        clients: clients?.result?.filter(({ id }) => id !== clientId)
      }
    })
  }

  if (cache.data.data.ROOT_QUERY.tasks) {
    const { tasks } = cache.readQuery({ query: tasksGql })

    cache.writeQuery({
      query: tasksGql,
      data: {
        tasks: tasks.filter(({ client }) => client?.id !== clientId)
      }
    })
  }
}

const ClientActionsContainer = ({ client, currentUser }) => {
  const navigate = useNavigate()

  const [canClientBeRemoved, { data }] = useLazyQuery(canClientBeRemovedGql, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (client && !isEmpty(client)) {
      canClientBeRemoved({ variables: { id: client.id } })
    }
  }, [client])

  const [removeClient] = useMutation(removeClientGql, {
    update: cache => updateCache(cache, client.id),
    onCompleted: () => {
      navigate('/secure/clients')
      toast(
        <Toastr type='success' title={`Client ${client.fullName} deleted.`} />
      )
    }
  })

  return (
    <ClientActions
      {...{
        client,
        isAdmin: !!currentUser?.isAdmin,
        canClientBeRemoved: !!data?.canClientBeRemoved,
        removeClient
      }}
    />
  )
}

const EnhancedClientActionsContainer = withCurrentUser()(ClientActionsContainer)
export { EnhancedClientActionsContainer as ClientActionsContainer }
