import constants from '@elenfs/elen-constants'

import { SingleImportLogLayout } from '../SingleImportLogLayout'

const { DataImportTypes } = constants.dataImport

export const CSVSingleLogPage = () => {
  const link = '/secure/admin/csv-import/logs'

  return (
    <SingleImportLogLayout
      link={link}
      dataImportType={DataImportTypes.CSV_FILE}
    />
  )
}
