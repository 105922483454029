// import { PageDivider } from '../../misc'

import './P1Guide.less'

const P1Guide = () => (
  <div className='p1-guide'>
    <p>
      In order to use this integration, it is required that you authorize Elen to access your P1 data. Use the form below to provide connection details.
    </p>

    {/* // TODO when support page is done (P1_STEP_BY_STEP_GUIDE) <PageDivider />

    <div className='p1-guide__external-info'>
      See our
      {' '}
      <a href={P1_STEP_BY_STEP_GUIDE} target='_blank' className='p1-guide__external-info__link'>
        step-by-step
      </a>
      {' '}
      guide for detailed instructions.
    </div> */}
  </div>
)

const P1ImportGuide = () => {
  return (
    <div className='p1-guide'>
      <p>
        After you have successfully connected, Elen we will do the daily import of your clients, policies, and valuations data. If you want an immediate sync, please use the Manual Sync button.
      </p>
    </div>
  )
}

export { P1Guide, P1ImportGuide }
