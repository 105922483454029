import { useMutation } from '@apollo/client'

import { ClientDetailsBaseForm } from '../../client/ClientDetailsBaseForm'
import clientsListGql from '../../../graphql/queries/client/searchClientsByKeyword.graphql'
import createCorporateClientGql from '../../../graphql/mutations/client/createCorporateClient.graphql'
import updateCorporateClientGql from '../../../graphql/mutations/client/updateCorporateClient.graphql'
import updateCorporateClientFactFindGql from '../../../graphql/mutations/client/updateCorporateClientFactFind.graphql'
import { withFinancialAdvisers } from '../../hoc/container'
import { paginationLimit } from '../../client/constants'

const createCorporateClient = () => useMutation(
  createCorporateClientGql, {
    update: (cache, { data: { createCorporateClient } }) => {
      try {
        const { clients } = cache.readQuery({
          query: clientsListGql,
          variables: { limit: paginationLimit, offset: 0 }
        })
        const { result, ...restCache } = clients
        cache.writeQuery({
          query: clientsListGql,
          data: {
            clients: {
              ...restCache,
              result: clients?.result?.concat([createCorporateClient])
            }
          }
        })
      } catch (error) {
        // Throw error here
      }
    }
  }
)

const CorporateClientDetailsFormContainer = props => {
  const { client, isClientPortal } = props
  const isUpdate = client && client.id

  let useMutationResult
  if (!isUpdate) {
    useMutationResult = createCorporateClient()
  } else {
    if (isClientPortal) {
      useMutationResult = useMutation(updateCorporateClientFactFindGql)
    } else {
      useMutationResult = useMutation(updateCorporateClientGql)
    }
  }

  const [mutate, mutationState] = useMutationResult

  return (
    <ClientDetailsBaseForm {...{
      mutate,
      mutationState,
      ...props
    }}
    />
  )
}

const EnhancedCorporateClientDetailsFormContainer = withFinancialAdvisers()(
  CorporateClientDetailsFormContainer
)

export { EnhancedCorporateClientDetailsFormContainer as CorporateClientDetailsFormContainer }
