"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyUserPermissions = exports.ClientReadAccessScopes = void 0;
const CompanyUserPermissions = exports.CompanyUserPermissions = Object.freeze({
  CLIENTS_ALL: 'CLIENTS:ALL',
  CLIENTS_OF_ADVISERS: 'CLIENTS:OF_ADVISERS',
  INCOME_ALL: 'INCOME:ALL',
  REPORTS_ALL: 'REPORTS:ALL'
});
const ClientReadAccessScopes = exports.ClientReadAccessScopes = Object.freeze({
  FULL: 'FULL',
  INCOME_FIELDS: 'INCOME_FIELDS',
  DEFAULT_FIELDS: 'DEFAULT_FIELDS'
});