import T from 'prop-types'
import pluralize from 'pluralize'

const PoliciesErrorHeading = ({
  clientFirstName,
  clientLastName,
  numOfPolicies
}) => (
  <>
    {clientFirstName && (
      <>
        <b>{clientFirstName} {clientLastName}</b>
        &nbsp;-&nbsp;
      </>
    )}
    Failed to import/sync {numOfPolicies}&nbsp;
    {pluralize('policy', numOfPolicies)}
  </>
)

PoliciesErrorHeading.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  numOfPolicies: T.number.isRequired
}

export default PoliciesErrorHeading
