import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import enumSchema from '../helpers/enumSchema'
import stringSchema from '../helpers/stringSchema'
import booleanSchema from '../helpers/booleanSchema'
import moneySchema from '../helpers/moneySchema'
import numberSchema from '../helpers/numberSchema'

const { EmploymentStatus, ClientTaxRates } = constants.client

export default yup.object().shape({
  ukDomiciled: booleanSchema()
    .label('UK domiciled'),

  ukResidentForTax: booleanSchema()
    .label('UK resident for tax'),

  niNumber: stringSchema()
    .max(50)
    .matches(/^\S{1,}$/, 'Employment section: Invalid NI number. NI number example: AB123456C')
    .label('NI number'),

  employmentStatus: enumSchema(values(EmploymentStatus))
    .label('Employment status'),

  occupation: stringSchema()
    .max(50)
    .label('Occupaton'),

  employer: stringSchema()
    .max(50)
    .label('Employer'),

  annualGrossSalary: moneySchema({
    amountLabel: 'Annual gross salary',
    currencyLabel: 'Annual gross salary currency'
  }),

  timeEmployed: stringSchema()
    .label('Time employed'),

  highestTaxRatePaid: enumSchema(values(ClientTaxRates))
    .label('Highest rate of tax paid'),

  desiredRetirementAge: numberSchema()
    .max(99, 'Maximum age is 99')
    .label('Desired retierment age'),

  childBenefitReduced: booleanSchema()
    .label('Child benefit reduced'),

  employmentNotesAndComments: stringSchema()
    .label('Additional notes and comments')
})
