const HomeIcon = (props) => (
  <svg width='1em' height='1em' viewBox='0 0 12 12' color='#7F8FA4' {...props}>
    <defs>
      <path
        id='home'
        d='M14 4.317v6.826a.857.857 0 0 1-.857.857H10.57a1.114 1.114 0 0 1-.857-.857V8.585c.137-.833-.514-.853-.857-.853H7.143c-.343 0-1.01.02-.857.853v2.558a1.157 1.157 0 0 1-.857.857H2.857A.857.857 0 0 1 2 11.143V4.317L7.143.054a6.83 6.83 0 0 1 1.714 0L14 4.317z'
      />
    </defs>
    <use
      fill='currentColor'
      fillRule='evenodd'
      transform='translate(-2)'
      xlinkHref='#home'
    />
  </svg>
)

export default HomeIcon
