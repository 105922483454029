import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import ImportError from './ImportError'
import CSVJobFailure from './CSVJobFailure'
import { PlatformFailure } from './PlatformFailure'

const {
  DataImportTypes,
  DataImportJobErrors
} = constants.dataImport

const { TRANSACT, CSV_FILE, CASH_CALC } = DataImportTypes

const {
  NETWORK_FAILURE,
  PLATFORM_FAILURE,
  PROCESSING_FAILURE,
  INTERNAL_FAILURE
} = DataImportJobErrors

const ImportJobFailure = ({ job, dataImportType }) => {
  let heading = <b>Import failed</b>
  let description

  switch (job.failureType) {
    case NETWORK_FAILURE:
      if (dataImportType === TRANSACT) {
        heading = <><b>Import failed</b>&nbsp;-&nbsp;Network issue</>
        description = 'Elen was unable to establish connection to Transact server'
      }
      if (dataImportType === CASH_CALC) {
        heading = <><b>Import failed</b>&nbsp;-&nbsp;Network issue</>
        description = 'Elen was unable to establish connection to CashCalc server.'
      }
      break
    case PLATFORM_FAILURE:
      return <PlatformFailure job={job} />
    case PROCESSING_FAILURE:
      if (dataImportType === TRANSACT) {
        description = 'Transact server error'
      }
      if (dataImportType === CSV_FILE) {
        return <CSVJobFailure job={job} />
      }
      break
    case INTERNAL_FAILURE:
      description = 'Internal server error'
      break
    default:
      description = 'Unknown error'
  }

  return (
    <ImportError
      heading={heading}
      description={description}
    />
  )
}

ImportJobFailure.propTypes = {
  job: T.shape({
    failureType: T.oneOf(Object.values(DataImportJobErrors)).isRequired
  }).isRequired,
  dataImportType: T.oneOf(Object.values(DataImportTypes)).isRequired
}

export default ImportJobFailure
