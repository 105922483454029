import { TaskStatuses } from '@elenfs/elen-constants'
import { map, pick } from 'ramda'

import FileUploadManager from '../../../../lib/FileUploadManager'

const getMainTaskUnsavedChanges = props => {
  const { mainTaskChanges, isSubtask } = props
  const unsavedChanges = {}

  if (!isSubtask) {
    const propertiesToMap = [
      'summary',
      'description',
      'assignedTo',
      'clientId',
      'status',
      'dueDate',
      'type'
    ]

    propertiesToMap.forEach(
      prop =>
        mainTaskChanges[prop] && (unsavedChanges[prop] = mainTaskChanges[prop])
    )
  }

  return unsavedChanges
}

export const getInitialValues = props => {
  const { mainTaskChanges, newTask, isSubtask, task, clientId } = props
  const unsavedChanges = getMainTaskUnsavedChanges({
    isSubtask,
    mainTaskChanges
  })

  return {
    ...task,
    assignedTo: task?.assignedTo?.id,
    clientId:
      (task?.parentTask ? task?.parentTask?.client?.id : task?.client?.id) ||
      mainTaskChanges?.clientId ||
      newTask?.clientId ||
      clientId,
    ...unsavedChanges
  }
}

export const getFormState = props => {
  const { values, task, isSubtask, newTask, onRemoveTask } = props

  const isCompletedStatus = values?.status === TaskStatuses.COMPLETED

  const isChangedToCompleted =
    isCompletedStatus && task.status !== TaskStatuses.COMPLETED

  const isConfirmChangeStatus =
    isChangedToCompleted &&
    task?.subtasks?.find(({ status }) => status !== TaskStatuses.COMPLETED)

  const clientOptionsDisabled = isSubtask || (!newTask && task.client)

  const canSaveAndNew = newTask && !isSubtask

  const canDeleteTask = task && !newTask && onRemoveTask

  return {
    isCompletedStatus,
    isChangedToCompleted,
    isConfirmChangeStatus,
    clientOptionsDisabled,
    canSaveAndNew,
    canDeleteTask
  }
}

const attachmentManager = new FileUploadManager(window.fetch.bind(window))

export const uploadFile = async (uploadUrl, file) => {
  await attachmentManager.upload(uploadUrl, file)
}

export const normalizeFiles = map(pick(['fileId', 'fileName']))

export const parseFilesToCreate = (fileList) => {
  const result = []

  for (let i = 0; i < fileList.length; i++) {
    const currFile = fileList[i]

    result.push({
      name: currFile.name,
      size: currFile.size,
      // Indexes will allow to easily identify how the upload URLs
      // and documents received from the API correspond with the files.
      index: i
    })
  }

  return result
}
