import clientEmploymentSchema from './clientEmploymentSchema'
import clientPersonalDetailsSchema from './clientPersonalDetailsSchema'
import clientAssetsAndLiabilitiesSchema from './clientAssetsAndLiabilitiesSchema'
import clientFinancialAdvisersSchema from './clientFinancialAdvisersSchema'
import clientObjectivesAndActionsSchema from './clientObjectivesAndActionsSchema'
import clientIncomeAndExpenditureSchema from './incomeAndExpenditureSchema'
import clientRelationshipsSchema from './clientRelationshipsSchema'
import clientDependantsSchema from './clientDependantsSchema'

const clientValidationSchema = clientPersonalDetailsSchema
  .concat(clientObjectivesAndActionsSchema)
  .concat(clientEmploymentSchema)
  .concat(clientIncomeAndExpenditureSchema)
  .concat(clientAssetsAndLiabilitiesSchema)
  .concat(clientFinancialAdvisersSchema)
  .concat(clientRelationshipsSchema)
  .concat(clientDependantsSchema)

export default clientValidationSchema
