import { pick, prop, pipe } from 'ramda'
import moment from 'moment'
import { Link } from 'react-router-dom'

import {
  FilterDateRanges,
  FilterDateRangeLabels
} from './../../../constants/filterDate'
import { buildSelectOptionsWithNoSort } from '../../form/fields/SelectField'
import { formatDate, calculateDiff } from './../../../helpers/date'
import { TableHeaders } from './const'

export const getRowColorClass = nextBirthday => {
  const daysTillNextBirthday = calculateDiff(moment(), nextBirthday, 'days')
  if (daysTillNextBirthday < 7) return 'red-bg-row'
  if (daysTillNextBirthday < 14) return 'blue-bg-row'
  if (daysTillNextBirthday < 30) return 'green-bg-row'
  return ''
}

const filterDateRangeKeys = [
  FilterDateRanges.THIS_WEEK,
  FilterDateRanges.NEXT_WEEK,
  FilterDateRanges.CURRENT_MONTH,
  FilterDateRanges.NEXT_MONTH,
  FilterDateRanges.CURRENT_YEAR,
  FilterDateRanges.FROM_TODAY_ONWARD
]

export const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)

const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)

const buildFirstName = pipe(pick(['id', 'firstName']), ({ id, firstName }) => (
  <Link to={`/secure/clients/${id}/summary`}>{firstName}</Link>
))

export const rowSchema = [
  {
    dataLabel: TableHeaders.BIRTHDAY,
    buildData: pipe(prop('nextBirthday'), formatDate)
  },
  {
    dataLabel: TableHeaders.TURNS,
    buildData: prop('turns')
  },
  {
    dataLabel: TableHeaders.FIRST_NAME,
    buildData: buildFirstName
  },
  {
    dataLabel: TableHeaders.MIDDLE_NAME,
    buildData: prop('middleName')
  },
  {
    dataLabel: TableHeaders.LAST_NAME,
    buildData: prop('lastName')
  },
  {
    dataLabel: TableHeaders.KNOWN_AS,
    buildData: prop('knownAs')
  },
  {
    dataLabel: TableHeaders.DATE_OF_BIRTH,
    buildData: pipe(prop('dateOfBirth'), formatDate)
  },
  {
    dataLabel: TableHeaders.ADDRESS_LINE_1,
    buildData: prop('address1')
  },
  {
    dataLabel: TableHeaders.ADDRESS_LINE_2,
    buildData: prop('address2')
  },
  {
    dataLabel: TableHeaders.CITY,
    buildData: prop('city')
  },
  {
    dataLabel: TableHeaders.POST_CODE,
    buildData: prop('postCode')
  },
  {
    dataLabel: TableHeaders.EMAIL,
    buildData: ({ email }) => <a href={`mailto: ${email}`}>{email}</a>
  },
  {
    dataLabel: TableHeaders.MOBILE_PHONE,
    buildData: ({ mobile }) => <a href={`tel: ${mobile}`}>{mobile}</a>
  },
  {
    dataLabel: TableHeaders.HOME_PHONE,
    buildData: ({ homeTel }) => <a href={`tel: ${homeTel}`}>{homeTel}</a>
  },
  {
    dataLabel: TableHeaders.WORK_PHONE,
    buildData: ({ workTel }) => <a href={`tel: ${workTel}`}>{workTel}</a>
  }
]
