import constants from '@elenfs/elen-constants'

import { Modal, WarningBanner } from '../../misc'
import { ChargeDetailsForm } from './ChargeDetailsForm'

import './ChargeDetailsModal.less'

const { RecurrentFrequencies } = constants.misc
const isNotRecurrentFrequency = frequency =>
  !Object.values(RecurrentFrequencies).includes(frequency)

const renderChargeBanner = () => (
  <WarningBanner>
    This fee, or parts of this fee, have already been reconciled. Some details cannot be edited further.
  </WarningBanner>
)

const ChargeDetailsModal = (props) => {
  const {
    close,
    charge,
    chargeType,
    policyId,
    createFee,
    createCommission,
    useRemoveCharge,
    isPolicyCharges
  } = props

  const isChargeReconciled = charge && charge.latestReconciledIncomeRecord
  const isRecurrentCharge = !(charge && isNotRecurrentFrequency(charge.frequency))
  const latestDueDate = isChargeReconciled && charge.latestReconciledIncomeRecord.dueDate

  return (
    <Modal
      className='charge-details__modal'
      renderTopBanner={isChargeReconciled ? renderChargeBanner : null}
      {...props}
    >
      <Modal.Heading>
        {`${charge && charge.id ? 'Edit' : 'New'} ${chargeType}`}
      </Modal.Heading>

      <ChargeDetailsForm
        {...{
          onDone: close,
          charge,
          chargeType,
          policyId,
          isChargeReconciled,
          isRecurrentCharge,
          latestDueDate,
          createFee,
          createCommission,
          useRemoveCharge,
          isPolicyCharges
        }}
      />
    </Modal>
  )
}

export { ChargeDetailsModal }
