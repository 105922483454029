import { useMemo, Fragment } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { Checkbox, InitialsAvatar, Table } from '../../../misc'
import { formatDate } from '../../../../helpers/date'
import { formatMoney } from '../../../../helpers/policy'
import {
  PolicyProvidersLabels,
  PolicyCategoriesLabels
} from '../../../../helpers/enumLabels/policy'
import { ChargeFrequenciesLabels } from '../../../../helpers/enumLabels/charge'
import { getInitialsFromUser } from '../../../../helpers/users'
import { TableHeaders } from './constants'
import BadgeTooltipList from '../BadgeTooltipList/BadgeTooltipList'

const rowCellNameClass = 'received-income-table__row__name-cell'
const rowCellPolicyNumberClass = 'received-income-table__row__policy-number-cell'

const ReceivedIncomeTableRow = ({ incomeRecord, handleUnreconcile, unreconcileLoading }) => {
  const {
    id,
    dueDate,
    reconciledAt,
    actualAmount,
    reconciledBy,
    charge,
    policyHolders,
    client
  } = incomeRecord

  const {
    adviser,
    frequency,
    policy
  } = charge || {}

  const {
    number: policyNumber = '',
    category: policyCategory,
    provider: policyProvider
  } = policy || {}

  const clients = policy ? policyHolders : [client]

  const clientFullName = clients && clients[0]?.fullName

  const listFromPolicyHolders = useMemo(
    () => clients?.map(({ fullName }, index) => <Fragment key={index}>{fullName}</Fragment>),
    [clients]
  )

  const showBadge = listFromPolicyHolders?.length > 1
  const badgeText = `+${listFromPolicyHolders?.length < 101 ? listFromPolicyHolders?.length - 1 : 99}`

  const onUnreconciled = async () => {
    try {
      await handleUnreconcile(incomeRecord)
    } catch (err) {
      console.error('[ReceivedIncomeTableRow] - Error submitting the form', err)
    }
  }

  return (
    <Table.Row>
      <Table.Cell data-label={TableHeaders.ADVISER} className='received-income-table__body__adviser-cell' short actionCell>
        {adviser && <InitialsAvatar user={adviser} />}
      </Table.Cell>
      <Table.Cell
        data-label={TableHeaders.CLIENT}
        className={classnames(
          rowCellNameClass,
          showBadge && `${rowCellNameClass}--with-badge`
        )}
      >
        <span className='received-income-table__row__name-cell__full-name'>
          {clientFullName}
        </span>
        {showBadge && (
          <BadgeTooltipList
            id={id}
            list={listFromPolicyHolders}
            title='Policy holders'
          >
            {badgeText}
          </BadgeTooltipList>
        )}
      </Table.Cell>
      <Table.Cell data-label={TableHeaders.POLICY}>{PolicyCategoriesLabels[policyCategory]}</Table.Cell>
      <Table.Cell data-label={TableHeaders.POLICY_NUMBER} className={rowCellPolicyNumberClass}>
        {policyNumber ? policyNumber.toUpperCase() : ''}
      </Table.Cell>
      <Table.Cell data-label={TableHeaders.PROVIDER}>{PolicyProvidersLabels[policyProvider]}</Table.Cell>
      <Table.Cell data-label={TableHeaders.FREQUENCY}>{ChargeFrequenciesLabels[frequency]}</Table.Cell>
      <Table.Cell data-label={TableHeaders.DUE_DATE}>{formatDate(dueDate)}</Table.Cell>
      <Table.Cell bold data-label={TableHeaders.PAID}>
        {formatMoney(actualAmount)}
      </Table.Cell>
      <Table.Cell data-label={TableHeaders.RECEIVED} className='received-income-table__body__received-cell' short actionCell>
        <Checkbox
          inputId={`checkbox_${id}`}
          checked={!!reconciledAt}
          onChange={onUnreconciled}
          disabled={!reconciledAt || unreconcileLoading}
        />
        {
          reconciledAt &&
            <span>
              &nbsp;&nbsp;
              {formatDate(reconciledAt)}
              &nbsp;-&nbsp;
              {getInitialsFromUser(reconciledBy).join('')}
            </span>
        }
      </Table.Cell>
    </Table.Row>
  )
}

ReceivedIncomeTableRow.propTypes = {
  incomeRecord: T.object.isRequired,
  handleUnreconcile: T.func.isRequired
}

export { ReceivedIncomeTableRow }
