export const TableHeaders = Object.freeze({
  POLICY_NUMBER: 'Policy number',
  PRODUCT: 'Product',
  POLICY_TYPE: 'Policy type',
  PROVIDER: 'Provider',
  CURRENT_VALUE: 'Current value',
  MATURITY_DATE: 'Maturity date',
  CURRENT_ADVISER: 'Current adviser',
  POLICY_HOLDERS: 'Policy Holders'
})
