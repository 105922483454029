import { Modal } from '../../../misc'
import { ServiceLevelsForm } from '../ServiceLevelsForm'

const ServiceLevelsModal = (props) => {
  const {
    close,
    serviceLevel
    // createServiceLevel,
    // removeServiceLevel
  } = props

  return (
    <Modal {...props}>
      <Modal.Heading>
        {`${serviceLevel && serviceLevel.id ? 'Edit' : 'New'} service level`}
      </Modal.Heading>

      <ServiceLevelsForm {...{
        onDone: close,
        serviceLevel
        // createServiceLevel,
        // removeServiceLevel
      }}
      />
    </Modal>
  )
}

export { ServiceLevelsModal }
