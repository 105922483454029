import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Link } from 'react-router-dom'

const SummaryCell = ({ children, link, disabled, extraTextWithDash, dangerText }) => (
  <div className={classnames('summary__cell', disabled && 'summary__cell--disabled', dangerText && 'summary__cell--danger-text')}>
    {link ? <Link to={link} className='summary__cell__link'> {children} </Link> : children}
    {extraTextWithDash && ` - ${extraTextWithDash}`}
  </div>
)

SummaryCell.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  extraTextWithDash: PropTypes.string,
  dangerText: PropTypes.bool
}

export default SummaryCell
