import { useQuery } from '@apollo/client'
import { ClientTypes } from '@elenfs/elen-constants'

import { ClientRelationshipsForm } from './ClientRelationshipsForm'
import { ClientPortalRelationshipForm } from './ClientPortalRelationshipForm'
import clientsListGql from '../../../graphql/queries/client/clientsList.graphql'

const ClientRelationshipsFormContainer = ({ clientId, ...otherProps }) => {
  const { isClientPortal, client } = otherProps
  const { data } = useQuery(clientsListGql)
  const clients = data?.clients?.result

  const companyClients = clients
    ? clients?.filter(
      ({ id, type }) => id !== clientId && type === ClientTypes.INDIVIDUAL
    )
    : []

  if (isClientPortal) {
    return (
      <ClientPortalRelationshipForm {...{
        client,
        ...otherProps
      }}
      />
    )
  }

  return <ClientRelationshipsForm {... { companyClients, ...otherProps }} />
}

export { ClientRelationshipsFormContainer }
