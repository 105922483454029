import PolicyDetails from './MortgagePolicyDetails'
import PolicyDetailsForm from './MortgagePolicyDetailsForm'
import PolicyForm from '../../PolicyForm'
import mortgagePolicySchema from '../../../../schemas/policy/mortgagePolicySchema'

const MortgagePolicyForm = props => (
  <PolicyForm {...{
    PolicyDetails,
    PolicyDetailsForm,
    policySchema: mortgagePolicySchema,
    ...props
  }}
  />
)

export default MortgagePolicyForm
