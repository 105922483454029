import T from 'prop-types'

import { EmptyPage } from '../../misc'
import { NewPolicyValuationButton } from './NewPolicyValuationButton'

const PolicyValuationEmpty = ({ hidden, policyId, readOnly }) => (
  <EmptyPage
    text={`No valuation currently added${!readOnly ? ', add your first one' : ''}`}
    className='policy-valuation__empty-content'
    renderButton={() => (
      <NewPolicyValuationButton {...{ policyId }} />
    )}
    hidden={hidden}
    readOnly={readOnly}
  />
)

PolicyValuationEmpty.propTypes = {
  hidden: T.bool.isRequired
}

export { PolicyValuationEmpty }
