import classnames from 'classnames'

import ErrorIcon from '../../icons/Error'
import './ErrorMessage.less'

const ErrorMessage = ({ className, text }) => (
  <div className={classnames('error-message', className)}>
    <ErrorIcon />
    {text}
  </div>
)

export default ErrorMessage
