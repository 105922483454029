import { useEffect, useMemo } from 'react'
import T from 'prop-types'
import { compose, is } from 'ramda'
import { CompanyUserPermissions, CompanyUserRoles } from '@elenfs/elen-constants'

import { Form } from '../../../../form/Form'
import { withFormik, connect } from 'formik'
import { PercentField, InputField, SelectField, CheckboxField } from '../../../../form/fields'
import { buildSelectOptions } from '../../../../form/fields/SelectField'
import FormErrorMessages from '../../../../form/FormErrorMessages'
import LoadingMessage from '../../../../misc/LoadingMessage'
import Button from '../../../../misc/Button'
import { buildInitialValues } from '../../../../../helpers/form'
import { userSchema } from '../../../../../schemas/users/userSchema'
import { UserRolesLabels } from '../../../../../helpers/enumLabels/user'
import { parseGraphqlErrors } from '../../../../../helpers/error'

import './UserDetailsForm.less'

const MINIMUM_ACCESS_ERROR = 'minimumAccess'

const UserDetailsForm = (props) => {
  const {
    user,
    currentUser,
    loading,
    values: { role, permissions, isAdmin, isAccountOwner, email },
    errors: validationErrors,
    handleSubmit,
    companyFinancialAdvisers,
    companyFinancialAdvisersWithOwnClients,
    formik: { setFieldValue, setErrors }
  } = props

  const buttonLabel = useMemo(() => {
    if (!user || user.email !== email) {
      return 'Send invite'
    }
    return 'Save'
  }, [user, email])

  const disableAccountOwner =
    !currentUser?.isAccountOwner ||
    (currentUser?.isAccountOwner && user?.id === currentUser?.id)

  useEffect(
    () => {
      if (isAccountOwner) {
        setFieldValue('isAdmin', true)
      }
    }, [isAccountOwner, isAdmin]
  )

  useEffect(
    () => {
      if (isAdmin) {
        setFieldValue('permissions', {
          ...permissions,
          allClients: true,
          reports: true,
          income: true
        })
      }
    }, [isAdmin]
  )

  useEffect(() => {
    const minAccessError = validationErrors[MINIMUM_ACCESS_ERROR]
    if (minAccessError) {
      const { allClients, reports, income, adviserIds } = permissions
      const hasAdvisers = adviserIds.find(id => !!id)
      const isFA = CompanyUserRoles.ADVISER === role
      const shouldRemoveError = isAdmin || reports || income || allClients || isFA || hasAdvisers
      if (shouldRemoveError) {
        setErrors({ [MINIMUM_ACCESS_ERROR]: null })
      }
    }
  }, [isAdmin, permissions, role, validationErrors])

  return (
    <Form
      className='user-details-form'
      onSubmit={handleSubmit}
    >
      <Form.Group className='user-details-form__misc-fields'>
        <InputField
          name='firstName'
          label='First name'
        />

        <InputField
          name='lastName'
          label='Last name'
        />

        <SelectField
          name='role'
          label='Job role'
          options={buildSelectOptions(CompanyUserRoles, UserRolesLabels)}
          inputOptions={{
            isClearable: false
          }}
        />

        <InputField name='email' label='Email' />

        {
          (role && is(Object, role)
            ? role.value === CompanyUserRoles.ADVISER
            : role === CompanyUserRoles.ADVISER) &&
              <>
                <PercentField name='defaultRate' label='Default rate (%)' />
                <PercentField name='alternativeRate' label='Alternative rate (%)' />
              </>
        }

        <CheckboxField
          name='isAdmin'
          label='Administrator'
          inputId='is-admin-checkbox'
          inputProps={{ disabled: isAccountOwner }}
        />

        <CheckboxField
          name='isAccountOwner'
          label='Account Owner'
          inputId='is-account-owner-checkbox'
          inputProps={{ disabled: disableAccountOwner }}
        />

      </Form.Group>

      <Form.Divider className='user-details-form__divider' />

      <Form.Group className='user-details-form__permission-fields'>
        <CheckboxField.Group label='Permissions' className='user-details-form__permission-fields__checkboxes'>
          <CheckboxField
            name='permissions.allClients'
            label='All clients'
            inputId='permissions-all-clients-checkbox'
            inputProps={{
              disabled: !!isAdmin
            }}
          />
          <CheckboxField
            name='permissions.income'
            label='Income'
            inputId='permissions-income-checkbox'
            inputProps={{
              disabled: !!isAdmin
            }}
          />
          <CheckboxField
            name='permissions.reports'
            label='Reports'
            inputId='permissions-reports-checkbox'
            inputProps={{
              disabled: !!isAdmin
            }}
          />
        </CheckboxField.Group>

        {!isAdmin && !permissions?.allClients &&
          <SelectField
            name='permissions.adviserIds'
            label="Grant access to the following advisers' clients"
            isMulti
            options={role === CompanyUserRoles.ADVISER ? companyFinancialAdvisersWithOwnClients : companyFinancialAdvisers}
          />}
      </Form.Group>

      <FormErrorMessages
        validationErrors={validationErrors}
      />

      {loading && <LoadingMessage
        text='Saving...'
                  />}

      <Form.ButtonGroup>
        <Button type='submit' color={Button.Colors.BLUE}>
          {buttonLabel}
        </Button>
      </Form.ButtonGroup>
    </Form>
  )
}

const UserDetailsFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: userSchema,

    mapPropsToValues: ({ user }) => {
      const values = buildInitialValues(userSchema, { ...user, permissions: undefined })

      if (!user || !user.permissions) {
        return values
      }

      const { CLIENTS_ALL, INCOME_ALL, REPORTS_ALL, CLIENTS_OF_ADVISERS } = CompanyUserPermissions
      // adapt permissions for the form
      for (const { name, adviserIds } of user.permissions) {
        if (name === CLIENTS_ALL) {
          values.permissions.allClients = true
        } else if (name === REPORTS_ALL) {
          values.permissions.reports = true
        } else if (name === INCOME_ALL) {
          values.permissions.income = true
        } else if (name === CLIENTS_OF_ADVISERS) {
          // push Own clients (Empty string) if role is Financial Adviser
          if (user.role === CompanyUserRoles.ADVISER) {
            values.permissions.adviserIds.push('')
          }
          // push rest selected user option
          values.permissions.adviserIds.push(...adviserIds)
        }
      }

      return values
    },

    handleSubmit: async (values, { props, setErrors }) => {
      const { permissions: valuePermissions, role, isAdmin } = values
      const { allClients, reports, income, adviserIds } = valuePermissions

      // validate that non-FA users have minimum permissions
      const hasAdvisers = adviserIds.find(id => !!id)
      const isFA = CompanyUserRoles.ADVISER === role
      const shouldSetError = !(isAdmin || reports || income || allClients || isFA || hasAdvisers)
      if (shouldSetError) {
        return setErrors({
          [MINIMUM_ACCESS_ERROR]: 'Permissions not set. Please select minimum access for this user'
        })
      }

      const { onSubmit, onDone, user, currentUser } = props

      values = { ...userSchema.cast(values, { stripUnknown: true }) }

      if ([null, undefined].includes(values.defaultRate)) {
        delete values.defaultRate
      }

      if (
        values.role !== CompanyUserRoles.ADVISER &&
        [null, undefined].includes(values.alternativeRate)
      ) {
        delete values.alternativeRate
      }

      if (user && user.id) {
        values = { ...values, id: user.id }
      }

      const { permissions } = values
      delete values.permissions

      if (!values.isAdmin) {
        const adaptedPermissions = []
        // adapt permissions to the shape expected by Graphql
        const { CLIENTS_ALL, INCOME_ALL, REPORTS_ALL, CLIENTS_OF_ADVISERS } = CompanyUserPermissions
        if (permissions.reports) {
          adaptedPermissions.push({ name: REPORTS_ALL })
        }
        if (permissions.income) {
          adaptedPermissions.push({ name: INCOME_ALL })
        }

        if (permissions.allClients) {
          adaptedPermissions.push({ name: CLIENTS_ALL })
        } else {
          const adviserIds = permissions.adviserIds.filter(adviserId => !!adviserId)
          // sending an empty array isn't supported by the BE
          if (adviserIds.length) {
            adaptedPermissions.push({
              name: CLIENTS_OF_ADVISERS,
              // Filter out the empty adviser id (Own clients option)
              // that was added in buildAdviserOptions/mapPropsToValues.
              adviserIds
            })
          }
        }
        values.permissions = adaptedPermissions
      }

      if (!currentUser.isAccountOwner) {
        delete values.isAccountOwner
      }

      const userUpdated = user?.id
      const emailChanged = userUpdated && values.email !== user?.email

      try {
        const res = await onSubmit(values)
        onDone({ userUpdated, emailChanged })
        return res
      } catch (err) {
        setErrors(parseGraphqlErrors(
          err,
          `There was an error ${userUpdated ? 'editing' : 'inviting'} the user`,
          { email: `User with this email already exists in ${currentUser.company.name}` }
        ))
        return err
      }
    }
  }),
  connect
)(UserDetailsForm)

UserDetailsFormEnhanced.propTypes = {
  /**
   * User that's being edited.
   */
  user: T.object,
  /**
   * User that's currently logged in.
   */
  currentUser: T.object.isRequired,
  /**
   * Callback for e.g. closing modal. Will be passed an object containing two flags: { userUpdated, emailChanged }.
   */
  onDone: T.func.isRequired,
  /**
   *  Callback to be called on form submit, will be passed values object.
   */
  onSubmit: T.func.isRequired,
  /**
   * Is onSubmit executing.
   */
  loading: T.bool,
  /**
   * Financial advisers from the company.
   */
  companyFinancialAdvisers: T.arrayOf(T.shape({
    id: T.string.isRequired,
    key: T.string.isRequired,
    label: T.string.isRequired,
    value: T.string.isRequired
  })).isRequired,
  companyFinancialAdvisersWithOwnClients: T.arrayOf(T.shape({
    label: T.string.isRequired,
    value: T.string.isRequired,
    isFixed: T.bool
  })).isRequired
}

export { UserDetailsFormEnhanced as UserDetailsForm }
