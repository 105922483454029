import { PlusIcon } from '../../icons'
import { EmptyPage, Button } from '../../misc'
import { Link } from 'react-router-dom'

const EmptyWorkflowsPage = ({ hidden }) => (
  <EmptyPage
    text='No workflows currently available, create your first one'
    renderButton={() => (
      <Button
        color={Button.Colors.BLUE}
        as={Link}
        to='/secure/workflows/create'
      >
        <PlusIcon /> <span> New Workflow </span>
      </Button>
    )}
    hidden={hidden}
  />
)

export { EmptyWorkflowsPage }
