import DocumentsTable from '../DocumentsTable'
import AddDocumentsButton from '../AddDocumentsButton'
import PageContent from '../../misc/PageContent'
import DocumentsPageEmpty from './DocumentsPageEmpty'
import { PreviewModal } from '../PreviewModal'

import './DocumentsPage.less'

const DocumentsPage = props => {
  const {
    clientDocuments,
    clientId,
    isClientPortal,
    policyId,
    onRequestSignatureDocument,
    signatureModalProps,
    closeSignatureModal,
    refetchDocuments,
    onClickToSign,
    isSignatureModalOpen,
    ...otherProps
  } = props

  const isEmpty = !(clientDocuments && clientDocuments.length)

  const showSignatureModal =
    isSignatureModalOpen && (
      !!signatureModalProps?.fileDownloadUrl ||
      signatureModalProps?.documentUnavailable
    )

  return (
    <PageContent>
      {!isEmpty
        ? (
          <div className='client-documents-page'>
            <div className='client-documents-page__info'>
              <AddDocumentsButton {...{ policyId, clientId }} />
            </div>
            <div className='client-documents-page__content'>
              <DocumentsTable
                {...{
                  clientDocuments,
                  isClientPortal,
                  policyId,
                  onRequestSignatureDocument,
                  refetchDocuments,
                  onClickToSign,
                  ...otherProps
                }}
              />
            </div>
          </div>
          )
        : (
          <DocumentsPageEmpty {...{ policyId, clientId }} />
          )}

      <PreviewModal
        isOpen={showSignatureModal}
        close={closeSignatureModal}
        {...signatureModalProps}
      />
    </PageContent>
  )
}

export default DocumentsPage
