const DropdownIcon = (props) => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path
        fill='currentColor'
        d='M2.543 4.903a1 1 0 011.32-.083l.094.083L8 8.945l4.043-4.042a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-4.566 4.566a1.26 1.26 0 01-1.68.092l-.102-.092-4.566-4.566a1 1 0 010-1.414z'
      />
    </g>
  </svg>
)

export default DropdownIcon
