import { FieldArray, connect } from 'formik'

import AddNewFormRowButton from '../../../misc/AddNewFormRowButton'
import AssetsFormInputRow from './AssetsFormInputRow'
import { TextAreaField } from '../../../form/fields'
import { FieldArrayItem } from '../../../form'

const buildAssetsRowInput = () => ({
  owner: '',
  description: '',
  value: { amount: '' }
})

const AssetsForm = ({ formik }) => {
  const {
    values: { assets = [] }
  } = formik
  return (
    <div>
      <FieldArray
        name='assets'
        validateOnChange={false}
        render={arrayHelpers => (
          <div>
            {assets.map((asset, index) => (
              <FieldArrayItem
                key={index}
                index={index}
                label='Asset'
                onRemove={() => arrayHelpers.remove(index)}
              >
                <AssetsFormInputRow index={index} />
              </FieldArrayItem>
            ))}
            <AddNewFormRowButton
              label='add asset'
              onClick={() => arrayHelpers.push(buildAssetsRowInput())}
            />
          </div>
        )}
      />
      <TextAreaField
        name='assetsNotesAndComments'
        label='Additional notes and comments'
        inputProps={{
          rows: 3
        }}
      />
    </div>
  )
}

export default connect(AssetsForm)
