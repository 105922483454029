import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { WaitingScreen } from '../WaitingScreen'

const OAUTH = 'oauth2'

const CashCalcWaitingPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const isOauth = window.location.hostname.split('.')[0] === OAUTH
    const searchParams = new URLSearchParams(location.search)
    const stateParam = searchParams.get('state')
    const codeParam = searchParams.get('code')

    if (isOauth && stateParam && codeParam) {
      const { redirectURL } = JSON.parse(
        window.atob(decodeURIComponent(stateParam))
      )
      if (redirectURL) {
        window.location.assign(`${redirectURL}?code=${codeParam}`)
      }
    } else {
      navigate('/')
    }
  }, [location])

  return <WaitingScreen />
}

export { CashCalcWaitingPage }
