import T from 'prop-types'

import { MenuDotsIcon } from '../../../../../icons'
import { Modal, Button, DropdownMenu } from '../../../../../misc'
import { RemovePolicyValuationButton } from '../../../RemovePolicyValuationButton'

const ActionList = ({
  PolicyValuationDetailsModal,
  policyValuation,
  policyId,
  className
}) => (
  <DropdownMenu
    direction={DropdownMenu.Directions.RIGHT}
    fullWidth={false}
    trigger={<MenuDotsIcon color={Button.Colors.BLUE} />}
    className={className}
  >
    <DropdownMenu.List>
      <Modal.Trigger
        as={DropdownMenu.Item}
        modal={PolicyValuationDetailsModal}
        modalProps={{ policyValuation, policyId }}
      >
        Edit
      </Modal.Trigger>

      <RemovePolicyValuationButton {...{
        valuationId: policyValuation.id,
        policyId,
        Component: DropdownMenu.Item
      }}
      />
    </DropdownMenu.List>
  </DropdownMenu>
)

ActionList.propTypes = {
  PolicyValuationDetailsModal: T.elementType.isRequired,
  policyValuation: T.object.isRequired,
  policyId: T.string.isRequired
}

export { ActionList }
