import classnames from 'classnames'

import { Modal, Button } from '../../../misc'
import { UserDetailsModal } from '../UserDetailsModal'
import { PlusIcon } from '../../../icons'

const NewUserButton = (props) => {
  const { className, ...restProps } = props

  return (
    <Modal.Trigger
      as={Button}
      color={Button.Colors.BLUE}
      modal={UserDetailsModal}
      responsive
      className={classnames(className)}
      {...restProps}
    >
      <PlusIcon />
      <span>New User</span>
    </Modal.Trigger>
  )
}

export { NewUserButton }
