import T from 'prop-types'

import { Trash } from '../../icons'
import { ConfirmationModal, Button } from '../../misc'

const RemoveTaskCommentButton = ({
  removeCommentCallback,
  className,
  Component
}) => (
  <ConfirmationModal.Trigger
    as={Component}
    color={Button.Colors.BLUE}
    className={className}
    modalProps={{
      confirmationCallback: removeCommentCallback,
      awaitCallback: true,
      heading: 'Delete comment',
      text: 'Are you sure you want to delete this comment?'
    }}
  />
)

RemoveTaskCommentButton.defaultProps = {
  Component: Trash
}

RemoveTaskCommentButton.propTypes = {
  removeCommentCallback: T.func.isRequired,
  Component: T.elementType.isRequired,
  className: T.string
}

export { RemoveTaskCommentButton }
