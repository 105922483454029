import T from 'prop-types'
import { compose } from 'ramda'
import { withFormik, connect } from 'formik'

import { Form } from '../../form'
import { InputField } from '../../form/fields'
import FormErrorMessages from '../../form/FormErrorMessages'
import { Button, LoadingMessage } from '../../misc'
import { buildInitialValues } from '../../../helpers/form'
import resetPasswordSchema from '../../../schemas/users/resetPasswordSchema'

const RecoverPasswordForm = ({
  loading,
  error: serverError,
  errors: validationErrors,
  handleSubmit
}) => (
  <Form className='recover-password-form' onSubmit={handleSubmit}>
    <Form.Section>
      <InputField
        name='password'
        label='Password'
        inputProps={{
          type: 'password'
        }}
      />

      <InputField
        name='verifyPassword'
        label='Verify new password'
        inputProps={{
          type: 'password'
        }}
      />
    </Form.Section>

    {loading && <LoadingMessage text='Loading...' />}

    {
      !loading && (
        <FormErrorMessages
          serverError={serverError}
          serverErrorMessage={serverError.message}
          validationErrors={validationErrors}
        />
      )
    }

    <Button
      type='submit'
      color={Button.Colors.BLUE}
      className='login-form__login-button'
      disabled={loading}
    >
      Change password
    </Button>
  </Form>
)

RecoverPasswordForm.defaultProps = {
  loading: false,
  error: ''
}

RecoverPasswordForm.propTypes = {
  loading: T.bool,
  error: T.string,
  onSubmit: T.func.isRequired
}

const RecoverPasswordFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: resetPasswordSchema,
    mapPropsToValues: () => {
      const values = buildInitialValues(resetPasswordSchema)
      return values
    },
    handleSubmit: async (values, { props: { onSubmit } }) => {
      await onSubmit(values)
    }
  }),
  connect
)(RecoverPasswordForm)

export default RecoverPasswordFormEnhanced
