import T from 'prop-types'
import moment from 'moment'

import { Badge } from '../../misc'
import { MessageTypes } from '../../../constants/message'

const MessagesNotificationList = ({ unreadMessageThreadsList, navigateOnMsgClick }) => {
  if (!unreadMessageThreadsList.length) {
    return (
      <div className='user-messages-menu-notifications__content__no-messages'>
        No new messages
      </div>
    )
  }

  return (
    <div className='user-messages-menu-notifications__content__list'>
      {unreadMessageThreadsList.map(
        ({ count, createdAt, client, clientId, type }, index) => (
          <div
            className='user-messages-menu-notifications__content__list__item'
            onClick={() => navigateOnMsgClick(clientId)}
            key={index}
          >
            <div>
              <div className='user-messages-menu-notifications__content__list__item__name'>
                {type === MessageTypes.SYSTEM ? 'System message' : client?.fullName}
              </div>
              <div className='user-messages-menu-notifications__content__list__item__date'>
                {moment(createdAt).format('D MMM YYYY - HH:mm')}
              </div>
            </div>
            <Badge
              color='blue'
              className='user-messages-menu-notifications__content__list__item__badge'
            >
              {count}
            </Badge>
          </div>
        )
      )}
    </div>
  )
}

MessagesNotificationList.defaultProps = {
  unreadMessageThreadsList: [],
  navigateOnMsgClick: () => {}
}

MessagesNotificationList.propTypes = {
  unreadMessageThreadsList: T.arrayOf(
    T.shape({
      count: T.number.isRequired,
      clientId: T.string.isRequired,
      client: T.shape({ fullName: T.string.isRequired }),
      createdAt: T.string.isRequired
    })
  ),
  navigateOnMsgClick: T.func.isRequired
}

export { MessagesNotificationList }
