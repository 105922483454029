import T from 'prop-types'
import { Route, Routes } from 'react-router-dom'

import { ClientSection } from '../ClientSection'
import {
  ClientDetailsFormPage,
  CorporateClientDetailsFormPage,
  IndividualClientDetailsFormPage
} from '../ClientDetailsFormPage'
import { ClientList } from '../ClientList'

import { ClientComplianceForm } from '../ClientComplianceForm'
import { WorkflowInstancePage } from '../../workflows/WorkflowInstancePage'

import './ClientsSection.less'

const ClientsSection = () => (
  <div className='clients-section'>
    <div>
      <div className='clients-section__content'>
        <Routes>
          <Route index element={<ClientList />} />
          <Route path='create/corporate/*' element={<CorporateClientDetailsFormPage />} />
          <Route path='create/individual/*' element={<IndividualClientDetailsFormPage />} />
          <Route path=':clientId/compliance/edit' element={<ClientComplianceForm />} />
          <Route path=':clientId/workflow-instance/:workflowInstanceId' element={<WorkflowInstancePage />} />
          <Route path=':clientId/*' element={<ClientSection />} />
          <Route path=':clientId/fact-find/edit/*' element={<ClientDetailsFormPage />} />
        </Routes>
      </div>
    </div>
  </div>
)

ClientsSection.propTypes = {
  clients: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      firstName: T.string,
      lastName: T.string
    })
  )
}

export { ClientsSection }
