import { Fragment } from 'react'
import toRegex from 'to-regex'
import { flatten, pipe, prop, split, map, any } from 'ramda'

import { formatDate } from './../../../helpers/date'

// CashCalc helpers
export const buildCashCalcAccountOptions = cashCalcClientAccounts =>
  cashCalcClientAccounts.map(({ id, clients }) => ({
    value: id,
    label: (
      <div className='account-clients'>
        {clients.map(({ name, dob }, i) => (
          <Fragment key={i}>
            {!!i && <div className='account-clients__separator' />}
            <div>
              {name} - <span className='dob'>{formatDate(dob)}</span>
            </div>
          </Fragment>
        ))}
      </div>
    )
  }))

const buildNamesArr = pipe(map(pipe(prop('name'), split(' '))), flatten)

export const filterAccounts = (searchText = '', clientAccounts) => {
  const searchWords = searchText.split(/\s+/)
  const regExp = toRegex(searchWords, { contains: true, flags: 'i' })
  const testRegX = str => regExp.test(str)

  return clientAccounts.filter(
    pipe(prop('clients'), buildNamesArr, any(testRegX))
  )
}
