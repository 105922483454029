export const COLORS = [
  'cornflower-blue',
  'dodger-blue',
  'persian-blue'
]

export const CLIENT_COLOR = 'golden-tainoi'

export const SIZES = Object.freeze({
  LG: 'large',
  XLG: 'extra-large'
})

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
