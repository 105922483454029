import { CompanyUserPermissions } from '@elenfs/elen-constants'

import MainNavItem from './MainNavItem'
import { Nav } from '../Nav'
import { doesCurrentUserHavePermission } from '../../hoc/container/WithPermissionCheck'

import './MainNav.less'

const getItems = (currentUser) => (
  [
    { label: 'Dashboard', path: 'dashboard' },
    { label: 'Clients', path: 'clients' },
    doesCurrentUserHavePermission(currentUser, CompanyUserPermissions.INCOME_ALL) &&
    { label: 'Income', path: 'income' },
    doesCurrentUserHavePermission(currentUser, CompanyUserPermissions.REPORTS_ALL) &&
    { label: 'Reports', path: 'reports' },
    { label: 'Tasks', path: 'tasks' },
    { label: 'Workflows', path: 'workflows' },
    { label: 'Admin', path: 'admin' }
  ].filter(Boolean)
)

const MainNav = ({ currentUser, ...props }) => (
  <Nav
    className='main-nav'
    items={getItems(currentUser)}
    itemComponent={MainNavItem}
    {...props}
  />
)

export { MainNav }
