const TimeIcon = (props) => (
  <svg width='1em' height='1em' viewBox='0 0 10 10' {...props}>
    <defs>
      <path
        id='time'
        d='M5 0c.9 0 1.734.225 2.5.675A5.052 5.052 0 0 1 9.325 2.5c.45.766.675 1.6.675 2.5 0 .9-.225 1.734-.675 2.5A5.052 5.052 0 0 1 7.5 9.325 4.85 4.85 0 0 1 5 10a4.85 4.85 0 0 1-2.5-.675A5.052 5.052 0 0 1 .675 7.5 4.847 4.847 0 0 1 0 5c0-.9.225-1.734.675-2.5A5.052 5.052 0 0 1 2.5.675 4.85 4.85 0 0 1 5 0zm-.464 5.685l1.714 1.25c.054.04.114.054.181.04a.258.258 0 0 0 .162-.1l.363-.524a.25.25 0 0 0 .05-.182.22.22 0 0 0-.09-.16L5.564 5.04V2.177a.233.233 0 0 0-.071-.171.233.233 0 0 0-.171-.07h-.646a.233.233 0 0 0-.171.07.233.233 0 0 0-.07.171v3.307c0 .08.033.148.1.201z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='time-mask' fill='#fff'>
        <use xlinkHref='#time' />
      </mask>
      <g fill='currentColor' mask='url(#time-mask)'>
        <path d='M0 0h10v10H0z' />
      </g>
    </g>
  </svg>
)

export default TimeIcon
