import T from 'prop-types'

import { EmptyPage } from '../../../misc'
import { NewServiceLevelButton } from '../NewServiceLevelButton'

const ServiceLevelsEmptyPage = ({ hidden }) => (
  <EmptyPage
    text='No service levels available, add new one'
    className='service_levels__empty-content'
    renderButton={() => <NewServiceLevelButton />}
    hidden={hidden}
  />
)

ServiceLevelsEmptyPage.propTypes = {
  hidden: T.bool.isRequired
}

export { ServiceLevelsEmptyPage }
