import { useState } from 'react'

import { SidePanel } from '.'

const SidePanelTrigger = props => {
  const {
    as: Trigger,
    children,
    sidePanelContent,
    sidePanelContentProps,
    title,
    ...otherProps
  } = props

  const [showPanel, setShowPanel] = useState(false)

  return (
    <>
      <Trigger {...otherProps} onClick={() => setShowPanel(true)}>
        {children}
      </Trigger>

      <SidePanel
        {...{
          showPanel,
          closePanel: () => setShowPanel(false),
          sidePanelContent,
          sidePanelContentProps,
          title
        }}
      />
    </>
  )
}

export { SidePanelTrigger }
