/**
 * This module is meant for HOCs which communicate with the backend,
 * eg using GraphqQL.
 */

export { withCurrentUser } from './WithCurrentUser'
export { withCompanyAdmin } from './WithCompanyAdmin'
export { withCurrentClient } from './WithCurrentClient'
export { withAuthorization } from './WithAuthorization'
export { withFinancialAdvisers } from './WithFinancialAdvisers'
export { withRelatedClients } from './WithRelatedClients'
export { withUnreadMessagesSubscription } from './WithUnreadMessagesSubscription'
export { withNewMessagesSubscription } from './WithNewMessagesSubscription'
export { withClientUser } from './WithClientUser'
export { withAccountOwner } from './WithAccountOwner'
export { withPaymentProvider } from './WithPaymentProvider'
export { withCompanyUsers } from './WithCompanyUsers'
export { withCashCalcConnection } from './WithCashCalcConnection'
export { withIntegrationConnection } from './WithIntegrationConnection'
export { withTeams } from './WithTeams'
