import { UserAgreementTypes } from '@elenfs/elen-constants'

const {
  ELEN_SAAS_AGREEMENT,
  DATA_PROCESSING_AGREEMENT,
  ACCEPTABLE_USE_POLICY,
  PRIVACY_POLICY,
  ELEN_TERMS_OF_SERVICE
} = UserAgreementTypes

export const CLIENT_AGREEMENTS_ARR = [
  ELEN_TERMS_OF_SERVICE,
  ACCEPTABLE_USE_POLICY,
  PRIVACY_POLICY
]

export const USER_AGREEMENTS_ARR = [
  ELEN_SAAS_AGREEMENT,
  DATA_PROCESSING_AGREEMENT,
  ACCEPTABLE_USE_POLICY,
  PRIVACY_POLICY
]
