
import PolicyDetails from './DebtPolicyDetails'
import PolicyDetailsForm from './DebtPolicyDetailsForm'
import PolicyForm from '../../PolicyForm'
import debtPolicySchema from '../../../../schemas/policy/debtPolicySchema'

const DebtPolicyForm = props => (
  <PolicyForm {...{
    PolicyDetails,
    PolicyDetailsForm,
    policySchema: debtPolicySchema,
    ...props
  }}
  />
)

export default DebtPolicyForm
