import constants from '@elenfs/elen-constants'
import { pick, prop } from 'ramda'

import { Link } from 'react-router-dom'

import { TableHeaders } from './const'
import {
  buildSelectOptions,
  buildSelectOptionsWithNoSort
} from './../../form/fields/SelectField/helpers'
import { DebtTypesLabels } from '../../../helpers/enumLabels/debtPolicy'
import { InvestmentTypesLabels } from '../../../helpers/enumLabels/investmentPolicy'
import { MortgageTypesLabels } from '../../../helpers/enumLabels/mortgagePolicy'
import { PensionTypesLabels } from '../../../helpers/enumLabels/pensionPolicy'
import { ProtectionTypesLabels } from '../../../helpers/enumLabels/protectionPolicy'
import { formatMoney } from '../../../helpers/policy'
import { formatDate } from './../../../helpers/date'
import {
  FilterDateRangeLabels,
  FilterDateRanges
} from './../../../constants/filterDate'
import {
  AllPolicyTypesLabels,
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from './../../../helpers/enumLabels/policy'

const { policy } = constants
const { DebtTypes } = policy.debtPolicy
const { InvestmentTypes } = policy.investmentPolicy
const { MortgageTypes } = policy.mortgagePolicy
const { PensionTypes } = policy.pensionPolicy
const { ProtectionTypes } = policy.protectionPolicy
const { PolicyCategories } = policy.common
const { DEBTS, INVESTMENT, MORTGAGES, PENSIONS, PROTECTION } = PolicyCategories

const debtSubcategoryOptions = buildSelectOptions(DebtTypes, DebtTypesLabels)
const investmentTypeOptions = buildSelectOptions(
  InvestmentTypes,
  InvestmentTypesLabels
)
const mortgageTypeOptions = buildSelectOptions(
  MortgageTypes,
  MortgageTypesLabels
)
const pensionTypeOptions = buildSelectOptions(PensionTypes, PensionTypesLabels)
const protectionTypeOptions = buildSelectOptions(
  ProtectionTypes,
  ProtectionTypesLabels
)

const buildCurrentHolders = ({ currentHolders }) =>
  currentHolders?.map(({ id, fullName }) => <div key={id}>{fullName}</div>)

const buildPolicyNumber = ({ number, currentHolders, id }) => {
  const clientId = currentHolders[0]?.id

  const to = `/secure/clients/${clientId}/policies/${id}/details`

  return <Link to={to}> {number} </Link>
}

const buildFAs = ({ currentHolders }) =>
  currentHolders.map(
    ({ financialAdviser: fa }, i) => fa && <div key={i}> {fa.fullName}</div>
  )

export const buildRowSchema = () => [
  {
    dataLabel: TableHeaders.POLICY_NUMBER,
    buildData: row => buildPolicyNumber(row)
  },
  {
    dataLabel: TableHeaders.PRODUCT,
    buildData: row => PolicyCategoriesLabels[prop('category', row)]
  },
  {
    dataLabel: TableHeaders.POLICY_TYPE,
    buildData: row => AllPolicyTypesLabels[prop('subCategory', row)]
  },
  {
    dataLabel: TableHeaders.PROVIDER,
    buildData: row => PolicyProvidersLabels[prop('provider', row)]
  },
  {
    dataLabel: TableHeaders.CURRENT_VALUE,
    buildData: row => formatMoney(prop('currentValue', row))
  },
  {
    dataLabel: TableHeaders.MATURITY_DATE,
    buildData: row => formatDate(prop('maturityDate', row))
  },
  {
    dataLabel: TableHeaders.CURRENT_ADVISER,
    buildData: row => buildFAs(row)
  },
  {
    dataLabel: TableHeaders.POLICY_HOLDERS,
    buildData: row => buildCurrentHolders(row)
  }
]

export const onFilterValuesChange = (
  { category },
  setPolicySubcategoryOptions
) => {
  switch (category) {
    case DEBTS:
      setPolicySubcategoryOptions(debtSubcategoryOptions)
      break
    case INVESTMENT:
      setPolicySubcategoryOptions(investmentTypeOptions)
      break
    case MORTGAGES:
      setPolicySubcategoryOptions(mortgageTypeOptions)
      break
    case PENSIONS:
      setPolicySubcategoryOptions(pensionTypeOptions)
      break
    case PROTECTION:
      setPolicySubcategoryOptions(protectionTypeOptions)
      break
    default:
      setPolicySubcategoryOptions([])
      break
  }
}

const filterDateRangeKeys = [
  FilterDateRanges.LAST_WEEK,
  FilterDateRanges.NEXT_WEEK,
  FilterDateRanges.LAST_MONTH,
  FilterDateRanges.NEXT_MONTH,
  FilterDateRanges.THIS_MONTH_TO_DATE,
  FilterDateRanges.LAST_QUARTER,
  FilterDateRanges.NEXT_QUARTER,
  FilterDateRanges.THIS_QUARTER_TO_DATE,
  FilterDateRanges.LAST_HALF_YEAR,
  FilterDateRanges.THIS_YEAR_TO_DATE,
  FilterDateRanges.LAST_YEAR,
  FilterDateRanges.CUSTOM
]

export const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)

const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)

export const tableHeaderLabels = Object.values(TableHeaders)
