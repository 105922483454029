import moment from 'moment'

import { FilterDateRanges } from './../constants/filterDate'

const {
  LAST_WEEK,
  LAST_MONTH,
  CURRENT_MONTH,
  LAST_QUARTER,
  LAST_HALF_YEAR,
  LAST_YEAR,
  NEXT_MONTH,
  NEXT_QUARTER,
  NEXT_WEEK,
  CURRENT_YEAR,
  FROM_TODAY_ONWARD,
  LAST_THREE_YEARS,
  LAST_FIVE_YEARS,
  LAST_TEN_YEARS,
  ALL_TIME,
  THIS_WEEK,
  THIS_MONTH,
  THIS_MONTH_TO_DATE,
  THIS_YEAR_TO_DATE,
  THIS_QUARTER_TO_DATE
} = FilterDateRanges

export const getFromToDateByDateRange = dateRange => {
  let fromDate = moment().toDate()
  let toDate = moment().toDate()

  switch (FilterDateRanges[dateRange]) {
    case THIS_WEEK:
      toDate = moment().endOf('isoWeek')
      break

    case THIS_MONTH:
      fromDate = moment().startOf('month')
      toDate = moment().endOf('month')
      break

    case FROM_TODAY_ONWARD:
      toDate = moment().add(1, 'year')
      break

    case CURRENT_YEAR:
      toDate = moment().endOf('year')
      break

    case CURRENT_MONTH:
      toDate = moment().endOf('month')
      break

    case NEXT_WEEK:
      fromDate = moment()
        .add(1, 'weeks')
        .startOf('isoWeek')
      toDate = moment()
        .add(1, 'weeks')
        .endOf('isoWeek')
      break

    case NEXT_MONTH:
      fromDate = moment()
        .add(1, 'month')
        .startOf('month')
      toDate = moment()
        .add(1, 'month')
        .endOf('month')
      break

    case NEXT_QUARTER:
      fromDate = moment()
        .add(1, 'quarter')
        .startOf('quarter')
      toDate = moment()
        .add(1, 'quarter')
        .endOf('quarter')
      break

    case THIS_MONTH_TO_DATE:
      fromDate = moment().startOf('month')
      break

    case THIS_YEAR_TO_DATE:
      fromDate = moment().startOf('year')
      break

    case THIS_QUARTER_TO_DATE:
      fromDate = moment().startOf('quarter')
      break

    case LAST_WEEK:
      fromDate = moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
      toDate = moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
      break

    case LAST_MONTH:
      fromDate = moment()
        .subtract(1, 'month')
        .startOf('month')
      toDate = moment()
        .subtract(1, 'month')
        .endOf('month')
      break

    case LAST_YEAR:
      fromDate = moment()
        .subtract(1, 'year')
        .startOf('year')
      toDate = moment()
        .subtract(1, 'year')
        .endOf('year')
      break

    case LAST_QUARTER:
      fromDate = moment()
        .subtract(1, 'quarter')
        .startOf('quarter')
      toDate = moment()
        .subtract(1, 'quarter')
        .endOf('quarter')
      break

    case LAST_HALF_YEAR:
      fromDate = moment()
        .subtract(3, 'quarter')
        .startOf('quarter')
      toDate = moment()
        .subtract(2, 'quarter')
        .endOf('quarter')
      break

    case LAST_THREE_YEARS:
      fromDate = moment()
        .subtract(3, 'year')
        .startOf('year')
      toDate = moment()
        .startOf('year')
      break

    case LAST_FIVE_YEARS:
      fromDate = moment()
        .subtract(5, 'year')
        .startOf('year')
      toDate = moment()
        .startOf('year')
      break

    case LAST_TEN_YEARS:
      fromDate = moment()
        .subtract(10, 'year')
        .startOf('year')
      toDate = moment()
        .startOf('year')
      break

    case ALL_TIME:
      fromDate = undefined
      toDate = undefined
      break
  }

  return { fromDate, toDate }
}
