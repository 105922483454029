const PostBoxIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M21.219 8.011c.431 0 .781-.35.781-.781V4.261a.781.781 0 00-.781-.781h-6.25a.781.781 0 00-.781.781V9.73H7.39A5.397 5.397 0 002 15.12v4.61c0 .432.35.781.781.781H21.22c.431 0 .781-.35.781-.781v-4.61a5.397 5.397 0 00-5.39-5.39h-.86V8.011h5.469zM15.75 5.043h4.688v1.406H15.75V5.043zm-4.531 13.906H3.563V15.12a3.832 3.832 0 013.828-3.829 3.832 3.832 0 013.828 3.829v3.828zm5.39-7.657a3.832 3.832 0 013.828 3.829v3.828h-7.656V15.12c0-1.496-.612-2.851-1.599-3.829h3.005v3.829a.781.781 0 101.563 0v-3.829h.86z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { PostBoxIcon }
