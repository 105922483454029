import T from 'prop-types'

import { Message } from './MessagesComponents/Message'
import { ReverseScrollingThread } from '../../misc'
import { ClientMessagesEmpty } from './ClientMessagesEmpty'

import './ClientMessagesPage.less'

const ClientMessagesPage = ({
  messages,
  currentUser,
  isEmpty,
  isClientUser,
  onDownloadFile
}) => (
  <>
    {!isEmpty
      ? (
        <div className='client-messages-page'>
          <ReverseScrollingThread>
            {messages.map(message => (
              <Message
                key={message.id}
                {...{
                  key: message.id,
                  currentUser,
                  onDownloadFile,
                  ...message
                }}
              />
            ))}
          </ReverseScrollingThread>
        </div>
        )
      : (
        <ClientMessagesEmpty isClientUser={isClientUser} />
        )}
  </>
)

ClientMessagesPage.defaultProps = {
  messages: []
}

ClientMessagesPage.propTypes = {
  messages: T.array.isRequired,
  onDownloadFile: T.func.isRequired
}

export { ClientMessagesPage }
