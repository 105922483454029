import T from 'prop-types'
import classnames from 'classnames'

import './TableResponsiveActionHeader.less'

const TableResponsiveActionHeader = ({ children, className }) => (
  <div
    className={classnames(
      'table-responsive__action-header',
      className
    )}
  >
    {children}
  </div>
)

TableResponsiveActionHeader.propTypes = {
  /**
   * children prop
   */
  children: T.node.isRequired,
  /**
   * Input classname.
   */
  className: T.string
}

export { TableResponsiveActionHeader }
