import T from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { Table, Modal } from '../../../misc'
import { SendMessageModal } from '../../../misc/SendMessageModal'

import { EnvelopeIcon } from '../../../icons'
import { ClientsListTableHeader } from '../ClientsListTableHeader'
import { ClientsListAction } from '../ClientsListAction'

import './ClientsListTableRow.less'

const MessageModalTrigger = ({ clientId, clientName }) => (
  <Modal.Trigger
    as='span'
    modal={SendMessageModal}
    modalProps={{ clientId, clientName }}
  >
    <EnvelopeIcon className='clients-list__table__row__envelope' />
  </Modal.Trigger>
)

const ClientsListTableRow = ({ className, client }) => {
  const navigate = useNavigate()
  const { fullName, email, mobile, financialAdviser, id, clientPortalUser } = client

  const onTableClick = () => navigate(`/secure/clients/${id}/summary`)

  return (
    <Table.Row className={className}>
      <Table.Cell data-label={ClientsListTableHeader.NAME} className='clients-list__table__row__cell' onClick={onTableClick}>
        <span className='clients-list__table__row__cell__name'>
          {fullName}
        </span>
      </Table.Cell>

      <Table.Cell data-label={ClientsListTableHeader.EMAIL} className='clients-list__table__row__cell clients-list__table__row__emailMobile'>
        <a href={`mailto:${email}`} className='clients-list__table__row__cell__email'>
          {email}
        </a>
      </Table.Cell>

      <Table.Cell data-label={ClientsListTableHeader.MOBILE} className='clients-list__table__row__cell clients-list__table__row__emailMobile'>
        <a href={`tel:${mobile}`} className='clients-list__table__row__cell__email'>
          {mobile}
        </a>
      </Table.Cell>

      <Table.Cell
        data-label={ClientsListTableHeader.CURRENT_ADVISER} className='clients-list__table__row__cell clients-list__table__row__emailMobile' onClick={onTableClick}
      >
        <span className='clients-list__table__row__cell__financialAdviser'>
          {financialAdviser?.fullName}
        </span>
      </Table.Cell>

      <Table.Cell data-label={ClientsListTableHeader.CLIENT_ACTIONS} className='clients-list__table__row__cell' onClick={!clientPortalUser?.id ? onTableClick : undefined}>
        <span className='clients-list__table__row__cell__desktopMessageTrigger'>
          {!!clientPortalUser?.id && <MessageModalTrigger clientId={id} clientName={fullName} />}
        </span>
      </Table.Cell>

      <Table.Cell data-label={ClientsListTableHeader.MOBILE_ACTIONS} className='clients-list__table__row__cell__mobileAction'>
        <ClientsListAction props={client} />
      </Table.Cell>
    </Table.Row>
  )
}

ClientsListTableRow.propTypes = {
  client: T.object.isRequired
}

export { ClientsListTableRow }
