import classnames from 'classnames'
import T from 'prop-types'

import { AccountDetails } from '../AccountDetails/'
import { SubscriptionPlan } from '../SubscriptionPlan'

import { moneyType } from '../../../../helpers/propTypes'

import './AccountOverviewPage.less'

const AccountOverviewPage = props => {
  const {
    className,
    accountDetailsData,
    subscriptionPlanData,
    // onDeleteAccount,
    onCancelSubscription,
    onUpdateSeats,
    updateSeatsLoading,
    updateSeatsError,
    activeUsers,
    onGetCustomerPortalUrl,
    customerPortalProps
  } = props

  // TODO Uncomment when implement delete account option
  // const confirmDeleteAccount = useCallback(
  //   isTrue => isTrue && onDeleteAccount(),
  //   onDeleteAccount
  // )

  return (
    <div className={classnames('account-overview-page', className)}>
      <AccountDetails {...accountDetailsData} />
      <SubscriptionPlan
        {...{
          ...subscriptionPlanData,
          onCancelSubscription,
          onUpdateSeats,
          updateSeatsLoading,
          updateSeatsError,
          activeUsers,
          onGetCustomerPortalUrl,
          customerPortalProps
        }}
      />

      {/* // TODO Uncomment when implement delete account option
      <PageDivider />

      <ConfirmationModal.Trigger
        as={Button}
        modalProps={{
          confirmationCallback: confirmDeleteAccount,
          heading: 'Delete account',
          text: 'Are you sure you want to delete your account?',
          yesButtonText: 'Delete',
          noButtonText: 'Go back',
          awaitCallback: true
        }}
      >
        Delete Account
      </ConfirmationModal.Trigger>

      <div className='account-overview-page__delete-account-hint'>
        Please note: Once you delete your account, your subscription will be
        cancelled. If you change your mind, you will be able to reactivate your
        account within 30 days by logging in as usual.
      </div> */}
    </div>
  )
}

AccountOverviewPage.propTypes = {
  accountDetailsData: T.shape({
    accountOwners: T.arrayOf(T.string.isRequired).isRequired,
    companyName: T.string.isRequired,
    companySubdomain: T.string.isRequired
  }).isRequired,
  subscriptionPlanData: T.shape({
    status: T.string.isRequired,
    costPerLicense: T.shape({
      licenseType: T.string.isRequired,
      licenseFee: moneyType().isRequired
    }),
    totalLicenses: T.number.isRequired,
    totalFee: moneyType().isRequired,
    trialDaysRemaining: T.number,
    currentPeriodStart: T.string,
    currentPeriodEnd: T.string,
    billingaDateAfterTrial: T.string
  }),
  onDeleteAccount: T.func.isRequired,
  onCancelSubscription: T.func.isRequired,
  onUpdateSeats: T.func.isRequired,
  updateSeatsLoading: T.bool,
  updateSeatsError: T.string,
  activeUsers: T.number.isRequired
}

export { AccountOverviewPage }
