import classnames from 'classnames'

const GroupedFormFields = ({ children, vertical, className }) => (
  <div
    className={classnames(
      'grouped-field' + (vertical ? '--vertical' : ''),
      className
    )}
  >
    {children}
  </div>
)

export default GroupedFormFields
