import LogActivityButton from '../../../auditLogEntry/LogActivityButton'
import AuditLogEntriesList from '../../../auditLogEntry/AuditLogEntriesList'
import Heading from '../../../misc/Heading'

import './ClientAuditLogCard.less'

const ClientAuditLogCard = ({ clientId, auditLogEntries, refetch }) => (
  <div className='audit-log-card'>
    <div className='audit-log-card__header'>
      <Heading as='h3'>Latest Activity</Heading>
      <LogActivityButton clientId={clientId} responsive showPlusIconOnMobile />
    </div>
    <div className='audit-log-card__body'>
      <AuditLogEntriesList auditLogEntries={auditLogEntries} refetch={refetch} />
    </div>
  </div>
)

export default ClientAuditLogCard
