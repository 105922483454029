import { useEffect } from 'react'
import T from 'prop-types'
import { compose } from 'ramda'
import classnames from 'classnames'
import { withFormik, connect } from 'formik'

import { Card, Button } from '..'
import { Form, FormErrorMessages } from '../../form'
import { PencilIcon } from '../../icons'
import { TextAreaField } from '../../form/fields'
import { formatDate } from './../../../helpers/date'

import './NoteCard.less'

const NoteCard = props => {
  const {
    className,
    placeholder,
    handleSubmit,
    note,
    setErrors,
    errors,
    mutationError,
    mutationLoading,
    setIsEdit,
    isEdit
  } = props

  const { content, updatedByInfo, updatedAt } = note || {}

  useEffect(() => {
    setErrors({ mutationError: mutationError ? 'Something went wrong' : '' })
  }, [mutationError])

  return (
    <Card className={classnames('note-card', className)}>
      <div className='note-card__header'>
        <Card.Heading as='h3'>Notes</Card.Heading>
        {!!content && (
          <div
            className={classnames('note-card__header__description', {
              'note-card__header__description--hidden': isEdit
            })}
          >
            <span>{formatDate(updatedAt)}</span>
            <span> | </span>
            <span> {updatedByInfo?.fullName} </span>
          </div>
        )}

        <PencilIcon
          className={classnames('note-card__header__icon', {
            'note-card__header__icon--hidden': isEdit
          })}
          onClick={() => setIsEdit(true)}
        />
      </div>

      <div
        className={classnames('note-card__details', {
          'note-card__details--hidden': isEdit
        })}
        onClick={() => setIsEdit(true)}
      >
        {content || placeholder}
      </div>

      <Form
        className={classnames('note-card__form', {
          'note-card__form--hidden': !isEdit
        })}
        onSubmit={handleSubmit}
      >
        <TextAreaField
          name='content'
          inputProps={{
            maxLength: 400,
            rows: 4,
            placeholder
          }}
        />

        <div className='note-card__form__actions'>
          <FormErrorMessages validationErrors={errors} />

          <Button
            color={Button.Colors.BLUE}
            type='submit'
            disabled={mutationLoading}
          >
            Done
          </Button>
          <Button
            color={Button.Colors.GREY}
            onClick={() => setIsEdit(false)}
            disabled={mutationLoading}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Card>
  )
}

NoteCard.defaultProps = {
  placeholder: 'Enter note details here',
  note: {}
}

NoteCard.propTypes = {
  onSubmit: T.func.isRequired,
  isEdit: T.bool.isRequired,
  setIsEdit: T.func.isRequired,
  note: T.shape({
    details: T.string,
    updatedBy: T.string,
    updatedByInfo: T.shape({ fullName: T.string }),
    updatedAt: T.string
  }),
  placeholder: T.string,
  mutationLoading: T.bool,
  mutationError: T.object
}

const EnhancedNoteCard = compose(
  withFormik({
    mapPropsToValues: ({ note }) => ({ content: note?.content }),
    handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
  }),
  connect
)(NoteCard)

export { EnhancedNoteCard as NoteCard }
