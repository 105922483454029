import { withFormik } from 'formik'
import { compose } from 'ramda'
import { SelectField } from '../../form/fields'
import { ControlWithInitials } from '../DropdownSelection/DropdownCustom'

export const TaskAssignee = ({ options, ...props }) => {
  return (
    <SelectField
      isGrouped
      options={options}
      name='assignedTo'
      inputProps={{ isClearable: false, isSearchable: false }}
      selectProps={{ Control: ControlWithInitials }}
      className='dropdown-selection__control--with-icon__dropdown-no-placeholder'
      {...props}
    />
  )
}

export const TaskAssigneeStory = compose(withFormik({}))(TaskAssignee)
