import {
  MobilePhoneIcon,
  EnvelopeIcon,
  PinIcon,
  Phone2Icon,
  AccountIcon
} from '../../icons'
import { formatDate } from '../../../helpers/date'

export const buildCompanyDetails = client => ({
  companyName: client.name,
  dateFounded: formatDate(client.dateFounded),
  companySummaries: [
    { label: 'Trading as:', value: client.tradingName },
    { label: 'LEI:', value: client.legalEntityIdentifier },
    { label: 'LEI expiry date:', value: formatDate(client.leiExpiryDate) },
    { label: 'Legal status:', value: client.legalStatus },
    { label: 'Nature of business:', value: client.natureOfBusiness },
    { label: 'Full time employees:', value: client.fullTimeEmployees },
    { label: 'Part time employees:', value: client.partTimeEmployees }
  ],
  contactsSummaries: [
    {
      label: <PinIcon />,
      value: <>
        <div className='company-details-card__low-line-height'>
          {client.address1}
        </div>
        <div className='company-details-card__low-line-height'>
          {client.address2}
        </div>
        <div className='company-details-card__low-line-height'>
          {client.city}
        </div>
        <div className='company-details-card__low-line-height'>
          {client.postCode}
        </div>
      </> // eslint-disable-line
    },
    { label: <AccountIcon />, value: client.name },
    { label: <EnvelopeIcon />, value: client.email },
    { label: <MobilePhoneIcon />, value: client.mobile },
    { label: <Phone2Icon />, value: client.landlineTel }
  ]
})
