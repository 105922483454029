import * as yup from 'yup'
import { stringSchema, passwordSchema } from '../helpers'

export default yup.object().shape({
  password: passwordSchema()
    .label('Password')
    .required('Password is required'),

  verifyPassword: stringSchema()
    .label('Confirm Password')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required')

}).noUnknown(true)
