import { isEmpty } from 'ramda'

import { ServiceLevelsHeader } from './ServiceLevelsHeader'

import { TableResponsiveWrapper, Table } from '../../../misc'
import { TableHeaders } from './constants'
import { ServiceLevelsTableRow } from './ServiceLevelsTableRow/ServiceLevelsTableRow'

import './ServiceLevelsTable.less'

const ServiceLevelsTable = ({ serviceLevels }) => {
  return (
    <div className='service-levels__table'>
      <ServiceLevelsHeader />

      {!isEmpty(serviceLevels) && (
        <TableResponsiveWrapper>
          <Table>
            <colgroup>
              <col className='service-levels__table__columns__color' />
              <col className='service-levels__table__columns__name' />
              <col className='service-levels__table__columns__description' />
              <col className='service-levels__table__columns__actions' />
            </colgroup>

            <Table.Header>
              <Table.Row>
                {Object.values(TableHeaders).map((item, index) => (
                  <Table.HeaderCell key={index}>
                    {item}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {serviceLevels.map((levels) => (
                <ServiceLevelsTableRow
                  serviceLevels={levels} className='service-levels__table__row'
                  key={levels?.id}
                />
              ))}
            </Table.Body>
          </Table>
        </TableResponsiveWrapper>
      )}
    </div>
  )
}

export { ServiceLevelsTable }
