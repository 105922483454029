import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { Badge } from './../../misc'

const badgeStyle = {
  marginLeft: '10px'
}

const NavItem = ({
  className,
  label,
  path,
  Icon,
  isMessage,
  clientTotalUnreadMessages
}) => (
  <NavLink to={path} className={className}>
    <span>
      {Icon ? <Icon /> : null}
      {label}
      {isMessage && !!clientTotalUnreadMessages && (
        <Badge color={Badge.Colors.BLUE} style={badgeStyle}>
          {clientTotalUnreadMessages}
        </Badge>
      )}
    </span>
  </NavLink>
)

NavItem.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isMessage: PropTypes.bool,
  clientTotalUnreadMessages: PropTypes.number
}

export default NavItem
