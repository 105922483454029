import { zipWith } from 'ramda'

export const buildLegend = chartData => {
  const { data, backgroundColor } = chartData.datasets[0]
  const zippedDataAndColor = zipWith(
    (amount, color) => ({ amount, color }),
    data,
    backgroundColor
  )

  return zipWith(
    (label, amountAndColor) => ({ label, ...amountAndColor }),
    chartData.labels,
    zippedDataAndColor
  )
}

export const getTotal = chartData => {
  const { data } = chartData.datasets[0]
  return data.reduce((acc, t) => acc + t, 0)
}
