import { useMemo } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { getColorForUser, getInitialsString } from './helpers'
import { SIZES } from './constants'

import './InitialsAvatar.less'

/**
 * Component that renders initials with pseudo-random background color.
 * Color selection is deterministic and depends on initials' position in the alphabet.
 */
const InitialsAvatar = ({ user, size, className, IconAvatar }) => {
  if (!user && !IconAvatar) {
    return null
  }

  const initials = useMemo(() => user && getInitialsString(user), [user])
  const color = useMemo(() => getColorForUser(user), [user])

  return (
    <div className={classnames(
      'initials-avatar', {
        [`initials-avatar--${color}`]: color,
        [`initials-avatar--${size}`]: size,
        'initials-avatar--icon-avatar': IconAvatar
      }, className)}
    >
      {
        IconAvatar
          ? <IconAvatar />
          : <span>{initials}</span>
      }
    </div>
  )
}

InitialsAvatar.propTypes = {
  /**
   * User for whom to render initials.
   */
  user: T.shape({
    id: T.string.isRequired,
    firstName: T.string,
    lastName: T.string,
    nameOfBusiness: T.string,
    fullName: T.string.isRequired,
    type: T.string
  }),
  IconAvatar: T.elementType,
  size: T.oneOf(Object.values(SIZES))
}

export { InitialsAvatar }
