import { useQuery } from '@apollo/client'

import userDetailsGql from '../../../../../graphql/queries/user/userDetails.graphql'
import { ErrorMessage } from '../../../../misc'
import { NoteDetails } from './NoteDetails'

const NoteDetailsContainer = ({ note, ...props }) => {
  const { error, data } = useQuery(userDetailsGql, {
    variables: {
      id: note.updatedBy
    },
    fetchPolicy: 'cache-and-network'
  })

  if (error) {
    return <ErrorMessage text='Failed to retrieve note details' />
  }

  return (
    <NoteDetails {...{
      note,
      noteUpdatedBy: data?.companyUser?.fullName,
      ...props
    }}
    />
  )
}

export { NoteDetailsContainer }
