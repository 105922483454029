import T from 'prop-types'

import { ConfirmationModal, Button } from '../../../misc'

const RemoveServiceLevelsButton = ({
  removeServiceLevelCallback,
  Component
}) => {
  return (
    <ConfirmationModal.Trigger
      as={Component}
      color={Button.Colors.RED_TEXT}
      className='service-levels-details-form__delete-button'
      modalProps={{
        confirmationCallback: removeServiceLevelCallback,
        heading: 'Delete service level',
        text: 'Are you sure you want to delete this service level? If you continue, the service level will be removed from all clients assigned to it.'
      }}
    >
      Delete
    </ConfirmationModal.Trigger>
  )
}

RemoveServiceLevelsButton.defaultProps = {
  Component: Button
}

RemoveServiceLevelsButton.propTypes = {
  removeServiceLevelCallback: T.func.isRequired,
  Component: T.elementType.isRequired,
  className: T.string
}

export { RemoveServiceLevelsButton }
