const WithdrawalIcon = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M19.071 3C20.137 3 21 3.863 21 4.929V7.5a1.929 1.929 0 01-1.929 1.929h-.642v9.642A1.929 1.929 0 0116.5 21h-9a1.929 1.929 0 01-1.929-1.929V9.43H4.93A1.929 1.929 0 013 7.5V4.929C3 3.863 3.863 3 4.929 3zm-1.928 3.857H6.857v9.052a4.5 4.5 0 013.806 3.805h2.674a4.5 4.5 0 013.806-3.805V6.857zM6.857 17.207v1.864c0 .355.288.643.643.643h1.864a3.214 3.214 0 00-2.507-2.507zm10.286 0a3.214 3.214 0 00-2.507 2.507H16.5a.643.643 0 00.643-.643zm-5.144-8.293c.291 0 .528.236.528.528v3.165h1.391a.264.264 0 01.23.394l-1.69 2.958a.528.528 0 01-.917 0l-1.69-2.958a.264.264 0 01.229-.394h1.392V9.442c0-.292.236-.528.527-.528zm7.072-4.628H4.93a.643.643 0 00-.643.643V7.5c0 .355.288.643.643.643h.642V6.214c0-.355.288-.643.643-.643h11.572c.355 0 .643.288.643.643v1.929h.642a.643.643 0 00.643-.643V4.929a.643.643 0 00-.643-.643z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { WithdrawalIcon }
