import { useCallback } from 'react'

const extractValueFromEvent = event => event.currentTarget.value

export const useOnChangeCallback = (handleChange, extractValue, regex) => {
  const extractValueFn = extractValue || extractValueFromEvent

  return useCallback(
    event => {
      const value = extractValueFn(event)

      if (value && regex) {
        if (regex.test(value)) handleChange(value)
      } else {
        handleChange(value)
      }
    },
    [handleChange, extractValue]
  )
}
