import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { Card } from '../../misc'
import { PencilIcon } from '../../icons'

const CardHeader = ({ children, className, baseUrl, id, allowToNavigate }) => {
  let url = baseUrl

  if (id) { url += id }

  return (
    <div className={classnames('card-new__header', className)}>
      <Card.Heading id={id}>
        {children}
      </Card.Heading>
      {(allowToNavigate && url) && (
        <Link to={url}>
          <PencilIcon className='icon' />
        </Link>
      )}
    </div>
  )
}

CardHeader.defaultProps = {
  allowToNavigate: true
}

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export { CardHeader }
