import { useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import { AuditLogEntryModal } from './AuditLogEntryModal'

import activityLogEntryEmailAttachmentGql from '../../../graphql/queries/activityLogEntry/activityLogEntryEmailAttachment.graphql'

const AuditLogEntryModalContainer = props => {
  const [downLoadFile] = useLazyQuery(activityLogEntryEmailAttachmentGql, {
    onCompleted: ({ activityLogEntryEmailAttachmentUrl }) => window.location.assign(activityLogEntryEmailAttachmentUrl)
  })

  const onDownloadFile = useCallback((id, attachmentId) =>
    downLoadFile({ variables: { id, attachmentId } })
  )

  return (
    <AuditLogEntryModal {...{
      onDownloadFile,
      ...props
    }}
    />
  )
}

export { AuditLogEntryModalContainer }
