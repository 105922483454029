import T from 'prop-types'

const FileIcon = ({ textRectFill, text, ...props }) => (
  <svg viewBox='0 0 32 36' width={32} height={36} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FFF'
        stroke='#E0E1E8'
        strokeWidth='1.5'
        d='M19.7573593 2.75H7.5c-.20710678 0-.39460678.0839466-.53033009.21966991C6.83394661 3.10539322 6.75 3.29289322 6.75 3.5v29c0 .2071068.08394661.3946068.21966991.5303301C7.10539322 33.1660534 7.29289322 33.25 7.5 33.25h21c.2071068 0 .3946068-.0839466.5303301-.2196699C29.1660534 32.8946068 29.25 32.7071068 29.25 32.5V12.2426407c0-.59673712-.2370529-1.16903342-.6590097-1.59099027l-7.2426407-7.24264069C20.9263927 2.98705289 20.3540964 2.75 19.7573593 2.75z'
      />
      <g transform='translate(2 16)'>
        <rect width='22' height='12' fill={textRectFill} rx='1.5' />
        <text fill='#FFF' fontFamily='Source Sans Pro' fontSize='10px' fontWeight='700'>
          <tspan x='2' y='9.5'>
            {text}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)

FileIcon.defaultProps = {
  textRectFill: 'none',
  text: 'FILE'
}

FileIcon.propTypes = {
  textRectFill: T.string,
  text: T.string
}

export default FileIcon
