import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import queryRelatedClients from '../../../../graphql/queries/client/relatedClients.graphql'

const withRelatedClients = (
  { fetchPolicy = 'cache-and-network', nextFetchPolicy = 'cache-first' } = {}
) => (WrappedComponent) => (props) => {
  const { clientId } = useParams()

  const { data, error, loading } = useQuery(queryRelatedClients, {
    variables: { clientId },
    fetchPolicy,
    nextFetchPolicy
  })
  /**
   * Ignore errors, just pass null or undefined companyClients prop.
   * Redirecting, logging out etc will be handled somewhere else.
   * (eg in the WithAuthorization HOC)
  **/
  const relatedClients = data && data.relatedClients

  return (
    <WrappedComponent
      isRelatedClientsLoading={loading}
      relatedClientsError={error}
      relatedClients={relatedClients}
      {...props}
    />
  )
}

export { withRelatedClients }
