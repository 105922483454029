import T from 'prop-types'
import { Fragment } from 'react'

import { Tooltip } from '../../misc'

const FromToTooltip = props => {
  const {
    children,
    setIsTooltipShown,
    sender,
    recipient,
    cc,
    bcc
  } = props

  return (
    <Tooltip.Trigger
      as='div'
      tooltip={Tooltip.FromToTooltip}
      tooltipId='audit-log-entry-email-modal-tool-tip'
      tooltipProps={{
        setIsTooltipShown,
        sender,
        recipient,
        cc,
        bcc
      }}
      className='audit-log-entry-email__header__from-to__to'
    >
      {recipient.slice(0, 2).map(({ name, email }, i) => {
        const nameText = name || email || ''
        const commaText = !i && i + 1 !== recipient.length && ', '

        return (
          <Fragment key={i}>
            {nameText} {commaText}
          </Fragment>
        )
      })}
      {children}
    </Tooltip.Trigger>
  )
}

FromToTooltip.propTypes = {
  setIsTooltipShown: T.func
}

export { FromToTooltip }
