import T from 'prop-types'
import classnames from 'classnames'

import { ModalCloseButton } from './ModalCloseButton'

const ModalContent = ({ children, close, className, ...restProps }) => (
  <div className={classnames('modal__content', className)} {...restProps}>
    <ModalCloseButton onClick={close} />
    {children}
  </div>
)

ModalContent.propTypes = {
  children: T.node.isRequired,
  close: T.func.isRequired,
  className: T.string
}

export { ModalContent }
