import './ClientBirthdayLegend.less'

const ClientBirthdayLegend = () => {
  return (
    <div className='client-birthday-legend'>
      <div> <div /> In next 7 days</div>
      <div> <div /> In next 14 days</div>
      <div> <div /> Within a month</div>
    </div>
  )
}

export { ClientBirthdayLegend }
