import * as yup from 'yup'
import { not, prop, pipe } from 'ramda'

import dateSchema from '../helpers/dateSchema'
import moneySchema from '../helpers/moneySchema'
import stringSchema from '../helpers/stringSchema'

yup.addMethod(yup.array, 'uniqueProfitAndLossDate', function (message) {
  return this.test('uniqueProfitAndLossDate', message, function (list) {
    const onlyYearEndDateList = list
      .map(prop('yearEndDate'))
      .map(year => new Date(year)?.getFullYear())

    if (onlyYearEndDateList.find(pipe(Boolean, not))) {
      return true
    }

    return list.length === new Set(onlyYearEndDateList).size
  })
})

export default yup.object().shape({
  profitAndLoss: yup
    .array()
    .of(
      yup.object().shape({
        id: stringSchema(),
        yearEndDate: dateSchema()
          .label('Profit and loss section: Year end date')
          .required(),
        amount: moneySchema({
          min: -10000000
        })
          .label('Profit and loss section: Profit/Loss')
          .required()
      })
    )
    .default([])
    .uniqueProfitAndLossDate(
      'Profit & loss section: Multiple records added for the same year'
    )
})
