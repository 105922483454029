import { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Card from '../../misc/Card'
import PageContent from '../../misc/PageContent'
import { Button } from '../../misc'
import { SaveIcon } from '../../icons'
import { updateAnchor } from '../../hoc/pure'
import { ClientDetailsBasePageCards } from './ClientDetailsBasePageCards'

import './ClientDetailsBasePage.less'

const ClientDetailsBasePage = props => {
  const {
    client,
    canEdit,
    baseUrl,
    anchorSpecificIds,
    isClientPortal,
    mutationLoading,
    createFactFindDocument,
    ClientSpecificDetailsCards,
    ClientDetailsSpecificSideMenu,
    clientUserProps,
    toggleClientFactFindMutation,
    requestClientFactFindEditMutation,
    submitClientFactFindMutationCallback,
    isFactFindEditable
  } = props

  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const onAnchorChangeCb = useCallback((anchor) => {
    navigate(`${pathname}#${anchor}`)
  }, [navigate, pathname])

  const UpdateAnchor = useMemo(() => updateAnchor({
    ids: [
      ...anchorSpecificIds,
      'income-and-expenditure',
      'assets-and-liabilities',
      'objectives-and-actions',
      'financial-advisers'
    ],
    onAnchorChangeCb
  }), [onAnchorChangeCb])

  const saveToDocumentsButton = useMemo(
    () => (
      <>
        {!isClientPortal && (
          <Button
            onClick={createFactFindDocument}
            color={Button.Colors.BLUE_TEXT}
            disabled={mutationLoading}
            className='save-fact-find-to-documents'
          >
            <SaveIcon />
            <span className='save-fact-find-to-documents__short-text'>Save version</span>
            <span className='save-fact-find-to-documents__long-text'>Save version to Documents</span>
          </Button>
        )}
      </>
    ),
    [isClientPortal, createFactFindDocument, mutationLoading]
  )

  return (
    <UpdateAnchor>
      <PageContent withMaxWidthXXXL>
        <div className='client-details-page'>
          <ClientDetailsSpecificSideMenu
            isHash
            className='client-details-page__side-menu'
            saveToDocumentsButton={saveToDocumentsButton}
            clientUserProps={clientUserProps}
            toggleClientFactFindMutation={toggleClientFactFindMutation}
            requestClientFactFindEditMutation={requestClientFactFindEditMutation}
            submitClientFactFindMutationCallback={submitClientFactFindMutationCallback}
            isClientPortal={isClientPortal}
            isFactFindEditable={isFactFindEditable}
          />

          <Card.Group className='client-details-page__client-details'>
            <ClientSpecificDetailsCards {...{
              client,
              canEdit,
              baseUrl,
              isClientPortal,
              clientUserProps
            }}
            />

            <ClientDetailsBasePageCards {...{
              client,
              canEdit,
              baseUrl,
              isClientPortal,
              clientUserProps
            }}
            />
          </Card.Group>
        </div>
      </PageContent>
    </UpdateAnchor>
  )
}

export { ClientDetailsBasePage }
