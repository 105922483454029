export const emptyStringToNull = (currentValue, originalValue) =>
  originalValue === '' ? null : originalValue

export const toISOString = (value) => {
  if (value === null) return value
  return value instanceof Date ? value.toISOString() : value
}

export const toNumber = (value) => {
  if (value === null) return value
  if (isNaN(value) || isNaN(parseFloat(value))) {
    return null
  }
  return isFinite(value) ? Number(value) : value
}
