import { useQuery } from '@apollo/client'

import { ReviewsAndBirthdaysDashboardCard } from './ReviewsAndBirthdaysDashboardCard'
import { ClientListTab } from './ClientListTab'

import { fromToDateFilter, mapClientList } from './helpers'

import clientBirthdaysReportGql from '../../../graphql/queries/reports/clientBirthdaysReport.graphql'
import clientsNextReviewGql from '../../../graphql/queries/reports/clientsNextReview.graphql'

const { fromDate, toDate } = fromToDateFilter

const ReviewsAndBirthdaysDashboardCardContainer = () => {
  const { data: clientBirthdaysReportData } = useQuery(
    clientBirthdaysReportGql,
    {
      variables: { filter: fromToDateFilter },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  const { data: clientsNextReviewData } = useQuery(clientsNextReviewGql, {
    variables: { fromDate, toDate },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const clientBirthdayList =
    clientBirthdaysReportData?.clientBirthdaysReport?.rows || []
  const clientNextReviewList = clientsNextReviewData?.clientsNextReview || []

  const tabs = [
    {
      label: 'Reviews',
      TabComponent: (
        <ClientListTab
          clientList={mapClientList(clientNextReviewList, 'nextReviewAt')}
          hint='Reviews in the next 30 days'
          emptyListText='No scheduled reviews.'
        />
      )
    },
    {
      label: 'Birthdays',
      TabComponent: (
        <ClientListTab
          clientList={mapClientList(clientBirthdayList, 'nextBirthday')}
          hint='Birthdays in the next 30 days'
          emptyListText='No birthdays in the next 30 days.'
        />
      )
    }
  ]

  return <ReviewsAndBirthdaysDashboardCard tabs={tabs} />
}

export { ReviewsAndBirthdaysDashboardCardContainer }
