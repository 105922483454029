import { useEffect, useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { AuditLogEntryForm } from './AuditLogEntryForm'
import activityLogEntriesGql from '../../../graphql/queries/activityLogEntry/activityLogEntries.graphql'
import createActivityLogEntryGql from '../../../graphql/mutations/activityLogEntry/createActivityLogEntry.graphql'
import updateActivityLogEntryGql from '../../../graphql/mutations/activityLogEntry/updateActivityLogEntry.graphql'
import removeActivityLogEntryGql from '../../../graphql/mutations/activityLogEntry/removeActivityLogEntry.graphql'
import { parseGraphqlErrors } from '../../../helpers/error'
import { withCurrentUser } from '../../hoc/container'

const createAuditLogEntry = () => useMutation(createActivityLogEntryGql, {
  update: (cache, { data: { createActivityLogEntry } }) => {
    try {
      const clientId = createActivityLogEntry.client.id
      const { activityLogEntries } = cache.readQuery({
        query: activityLogEntriesGql,
        variables: { clientId }
      })
      cache.writeQuery({
        query: activityLogEntriesGql,
        variables: { clientId },
        data: {
          activityLogEntries: activityLogEntries.concat([createActivityLogEntry])
        }
      })
    } catch (error) {
      // pass
    }
  }
})

const AuditLogEntryFormContainer = props => {
  const {
    auditLogEntry,
    refetchLogEntries,
    handleSave
  } = props

  const auditLogEntryId = auditLogEntry?.id
  const isUpdate = auditLogEntry && auditLogEntryId
  const client = auditLogEntry.client
  const clientId = client ? client.id : auditLogEntry.clientId

  const formattedAuditLogEntry = {
    clientId,
    refetchLogEntries,
    ...auditLogEntry
  }

  let useMutationResult
  if (!isUpdate) {
    useMutationResult = createAuditLogEntry()
  } else {
    useMutationResult = useMutation(updateActivityLogEntryGql)
  }
  const [mutate, { loading }] = useMutationResult

  const [
    removeMutation,
    { called: removeCalled, loading: removeLoading, error: removeError }
  ] = useMutation(removeActivityLogEntryGql, {
    variables: {
      id: auditLogEntryId
    },
    onError: (error, setErrors) => {
      if (setErrors) {
        setErrors(parseGraphqlErrors(
          error,
          'There was an error deleting the activity log, please try again later'
        ))
      } else {
        throw new Error(error)
      }
    },
    update: (cache, operationResult) => {
      const { id: removedInstanceId } = operationResult?.data?.removeActivityLogEntry
      const { activityLogEntries } = cache.readQuery({
        query: activityLogEntriesGql,
        variables: { clientId }
      })

      const filterFn = ({ id }) => id !== removedInstanceId

      cache.writeQuery({
        query: activityLogEntriesGql,
        variables: { clientId },
        data: {
          activityLogEntries: activityLogEntries.filter(filterFn)
        }
      })
    }
  })

  useEffect(() => {
    if (removeCalled && !removeLoading && !removeError) {
      handleSave && handleSave()
    }
  }, [removeCalled, removeLoading, removeError, handleSave])

  const removeAuditLogEntryCallback = useCallback(
    async (removeConfirmationFlag) => {
      if (removeConfirmationFlag) {
        await removeMutation()
      }
    }, [removeMutation]
  )

  return (
    <AuditLogEntryForm {...{
      mutate,
      loading,
      handleSave,
      auditLogEntry: formattedAuditLogEntry,
      removeAuditLogEntryCallback,
      ...props
    }}
    />
  )
}

const EnhancedAuditLogEntryFormContainer = withCurrentUser({
  fetchPolicy: 'cache-only'
})(AuditLogEntryFormContainer)

export { EnhancedAuditLogEntryFormContainer as AuditLogEntryFormContainer }
