import { useCallback } from 'react'
import T from 'prop-types'
import { connect } from 'formik'

import { getPropByPath } from '../../../../helpers/misc'

// Connects fields to Formik and does other things related to Formik.
const connectFieldToFormik = (WrappedField) => {
  const ConnectFieldToFormik = ({ clearErrorOnChange, inputProps, ...restProps }) => {
    const {
      name,
      formik,
      isHandleOnSelect = false,
      handleOnSelect
    } = restProps
    const { values, errors, setFieldValue, setFieldError } = formik
    const value = getPropByPath(name, values)
    const error = getPropByPath(name, errors)

    const handleChange = useCallback(
      (newValue) => {
        // This should be the default behavior:
        //  1. form should be validated before submit
        //  2. field error should clear after changing its value
        // Unfortunately, formik can't be simply configured for such validation behaviour,
        // so this is the solution for that.
        if (clearErrorOnChange) {
          setFieldError(name, '')
        }
        setFieldValue(name, newValue)
        isHandleOnSelect && handleOnSelect(newValue)
      },
      [clearErrorOnChange, setFieldValue, setFieldError]
    )

    return (
      <WrappedField
        name={name}
        value={value}
        error={error}
        handleChange={handleChange}
        inputProps={inputProps}
        // If some subcomponent (eg WrappedField or underlying input)
        // need to implement some behaviour that relies on Formik
        // - they can do it using the formik prop passed here.
        {...restProps}
      />
    )
  }

  ConnectFieldToFormik.propTypes = {
    /**
     * Name of the field to be used by Formik.
     * Should be the same as the name used in yup validation schemas.
     */
    name: T.string.isRequired,
    /**
     * Should the validation error for the field be cleared
     * when the field's value is changed.
     */
    clearErrorOnChange: T.bool,
    /**
     * Props that should be passed by the WrappedField to the underlying input.
     */
    inputProps: T.object
  }

  ConnectFieldToFormik.defaultProps = {
    clearErrorOnChange: true,
    inputProps: {}
  }

  // connect gives us the 'formik' prop.
  return connect(ConnectFieldToFormik)
}

export { connectFieldToFormik }
