import Card from '../../misc/Card'
import Summary from '../../misc/Summary'
import { formatDate, calculateAge } from '../../../helpers/date'
import { PageDivider } from '../../misc/PageDivider'

const CorporateClientSummaryDetailsCard = ({ client }) => (
  <Card className='corporate-client-summary-details-card'>
    <div className='client-notes-card-content__header'>
      <Card.Heading as='h3'>Summary details</Card.Heading>
    </div>

    <Summary
      data={[
        { label: 'Name of business', value: client.name },
        { label: 'Trading as', value: client.tradingName },
        { label: 'Legal Entity Identifier (LEI)', value: client.legalEntityIdentifier },
        { label: 'LEI expiry date', value: formatDate(client.leiExpiryDate) },
        { label: 'Legal status', value: client.legalStatus },
        { label: 'Nature of business:', value: client.natureOfBusiness },
        { label: 'Full time employees:', value: client.fullTimeEmployees },
        { label: 'Part time employees:', value: client.partTimeEmployees },
        { label: 'Date founded:', value: formatDate(client.dateFounded) },
        { label: 'Years in business:', value: calculateAge(client.dateFounded) }
      ]}
    />

    <PageDivider />

    <Summary
      data={[
        { label: 'Address line 1', value: client.address1 },
        { label: 'Address line 2', value: client.address2 },
        { label: 'City', value: client.city },
        { label: 'Post Code', value: client.postCode }
      ]}
    />

    <Summary
      data={[
        { label: 'First Name', value: client.firstName },
        { label: 'Last Name', value: client.lastName },
        { label: 'Email', value: client.email },
        { label: 'Mobile', value: client.mobile },
        { label: 'Landline', value: client.landlineTel }
      ]}
    />

    <Summary
      className='line-margin'
      data={[
        {
          label: 'Financial Adviser',
          value: client.financialAdviser ? client.financialAdviser.fullName : ''
        }
      ]}
    />
  </Card>
)

export { CorporateClientSummaryDetailsCard }
