const DecreaseIcon = props => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path
        d='M8.868 11.106L13.5 3h-11l4.632 8.106a1 1 0 001.736 0z'
        fill='#000'
      />
    </g>
  </svg>
)

export { DecreaseIcon }
