import PropTypes from 'prop-types'
import classnames from 'classnames'

const DropdownMenuItem = ({ children, className, disabled, ...rest }) => (
  <li
    className={classnames('dropdown-menu__item', className, {
      'dropdown-menu__item--disabled': disabled
    })}
    {...rest}
  >
    {children}
  </li>
)

DropdownMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default DropdownMenuItem
