import constants from '@elenfs/elen-constants'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import policyChargesGql from '../../graphql/queries/charge/policyCharges.graphql'
import clientChargesGql from '../../graphql/queries/charge/clientCharges.graphql'

import { Charges } from './Charges'
import { Spinner, ErrorMessage } from '../misc'

const { ChargeTypes: { COMMISSION, FEE } } = constants.charge

const ChargesContainer = (props) => {
  const { clientId } = useParams()
  const { policy, isPolicyCharges } = props
  const propName = isPolicyCharges ? 'policyCharges' : 'clientCharges'
  const variableName = isPolicyCharges ? { policyId: policy?.id } : { clientId }

  const { loading, error, data } = useQuery(isPolicyCharges ? policyChargesGql : clientChargesGql, {
    variables: variableName,
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) {
    return <Spinner />
  }

  if (error) {
    return <ErrorMessage text='Failed to load fees and commissions' />
  }

  const policyCharges = (data && data[propName]) || []
  const commissions = policyCharges.filter(({ type }) => type === COMMISSION)
  const fees = policyCharges.filter(({ type }) => type === FEE)

  return (
    <Charges
      fees={fees}
      commissions={commissions}
      isPolicyCharges={isPolicyCharges}
    />
  )
}

export { ChargesContainer }
