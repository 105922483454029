import constants from '@elenfs/elen-constants'

const {
  AdviceTypes,
  SourceTypes,
  ChargeFrequencies,
  ChargeTypes
} = constants.charge

export const AdviceTypesLabels = Object.freeze({
  [AdviceTypes.INDEPENDENT]: 'Independent',
  [AdviceTypes.RESTRICTED]: 'Restricted'
})

export const SourceTypesLabels = Object.freeze({
  [SourceTypes.PLATFORM]: 'Platform',
  [SourceTypes.CLIENT]: 'Client',
  [SourceTypes.PROVIDER]: 'Provider'
})

export const ChargeFrequenciesLabels = Object.freeze({
  [ChargeFrequencies.INITIAL]: 'Initial',
  [ChargeFrequencies.SINGLE]: 'Single',
  [ChargeFrequencies.WEEKLY]: 'Weekly',
  [ChargeFrequencies.MONTHLY]: 'Monthly',
  [ChargeFrequencies.QUARTERLY]: 'Quarterly',
  [ChargeFrequencies.HALF_YEARLY]: 'Half-yearly',
  [ChargeFrequencies.ANNUALLY]: 'Annually',
  [ChargeFrequencies.AD_HOC]: 'Ad-Hoc'
})

export const FeesTableHeader = Object.freeze({
  TYPE: 'Type',
  RATE: 'Rate',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  AMOUNT: 'Amount',
  UNITS: 'No. of payments',
  TOTAL_AMOUNT: 'Total amount',
  RECONCILED: 'Reconciled',
  EMPTY: ''
})

export const ClientFeesTableHeader = Object.freeze({
  TYPE: 'Type',
  POLICY_TYPE: 'Policy type',
  POLICY_NUMBER: 'Policy number',
  RATE: 'Rate',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  AMOUNT: 'Amount',
  UNITS: 'No. of payments',
  TOTAL_AMOUNT: 'Total amount',
  RECONCILED: 'Reconciled',
  EMPTY: ''
})

export const CommissionsTableHeader = Object.freeze({
  TYPE: 'Type',
  RATE: 'rate',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  AMOUNT: 'Amount',
  UNITS: 'No. of payments',
  TOTAL_AMOUNT: 'Total amount',
  RECONCILED: 'Reconciled',
  EMPTY: ''
})

export const ClientCommissionsTableHeader = Object.freeze({
  TYPE: 'Type',
  POLICY_TYPE: 'Policy type',
  POLICY_NUMBER: 'Policy number',
  RATE: 'Rate',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  AMOUNT: 'Amount',
  UNITS: 'No. of payments',
  TOTAL_AMOUNT: 'Total amount',
  RECONCILED: 'Reconciled',
  EMPTY: ''
})

export const ChargeTypesLabels = Object.freeze({
  [ChargeTypes.FEE]: 'Fee',
  [ChargeTypes.COMMISSION]: 'Commission'
})
