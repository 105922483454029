const Lock = props => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M16 16V0H0v16z' />
      <path
        d='M7.999 1a4.027 4.027 0 014.025 4.02l-.001 1.352h.493a.82.82 0 01.815.712l.007.102v7a.819.819 0 01-.822.814H3.481a.819.819 0 01-.821-.814v-7c0-.449.368-.814.821-.814h.493V5.021a4.028 4.028 0 013.824-4.016zm4.353 6.349H3.646v6.674h8.706V7.35zm-4.435 2.767a.49.49 0 01.492.489v1.14a.49.49 0 01-.492.488.49.49 0 01-.493-.489v-1.14c0-.27.22-.488.493-.488zm.082-8.14a3.045 3.045 0 00-3.04 3.045v1.351h6.078V5.021a3.046 3.046 0 00-2.86-3.04z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { Lock }
