import { useMemo, useEffect, useCallback } from 'react'
import T from 'prop-types'
import { withFormik } from 'formik'
import { compose, pathOr } from 'ramda'

import constants from '@elenfs/elen-constants'
import {
  Form,
  FormErrorMessages
} from '../../form'
import { SelectField, InputField, TextAreaField } from '../../form/fields'
import { Button } from '../../misc'
import { customerSupportFormSchema } from '../../../schemas/customerSupport/customerSupportSchema'
import { UserRolesLabels, UserTypesLabels } from '../../../helpers/enumLabels/user'

const { UserTypes } = constants.user

const CustomerSupportForm = (props) => {
  const {
    handleSubmit,
    errors,
    ticketReasons,
    onCancelClick,
    currentUser,
    values: { reason, subject, message },
    setFieldError,
    mutationLoading
  } = props

  const userName = pathOr('', ['fullName'], currentUser)
  const userRole = pathOr('', ['role'], currentUser)
  const companyName = pathOr('', ['company', 'name'], currentUser)
  const userType = pathOr('', ['type'], currentUser)
  const userEmail = pathOr('', ['email'], currentUser)
  const isUserAdmin = pathOr(false, ['isAdmin'], currentUser)

  const handleValueChange = useCallback(
    (fieldName) => () => setFieldError(fieldName, ''),
    [setFieldError])

  useEffect(() => handleValueChange('reason'), [reason])
  useEffect(() => handleValueChange('subject'), [subject])
  useEffect(() => handleValueChange('message'), [message])

  const userTypeLabel = useMemo(() => {
    if (isUserAdmin) {
      return UserTypesLabels[UserTypes.ADMIN]
    }

    return userType ? UserTypesLabels[userType] : ''
  }, [userType, isUserAdmin])

  return (
    <Form className='customer-support-form' onSubmit={handleSubmit}>
      <SelectField
        name='reason'
        label='Reason for your enquiry'
        options={ticketReasons}
        className='reason-selection'
      />

      <Form.Field error={errors.subject}>
        <InputField
          name='subject'
          label='Subject'
        />
      </Form.Field>

      <Form.Field error={errors.message}>
        <TextAreaField
          name='message'
          label='Message'
          inputProps={{ rows: 5, maxLength: 5000 }}
        />
      </Form.Field>

      <div className='customer-support-form__error-messages'>
        <FormErrorMessages
          validationErrors={errors}
        />
      </div>

      <Form.InfoSection>
        <Form.InfoSectionHeader>
          From
        </Form.InfoSectionHeader>
        <Form.InfoSectionContent>
          <p>{userName}</p>
          <p>{userRole ? UserRolesLabels[userRole] : ''}</p>
          <p>{companyName}</p>
          <p>{userTypeLabel}</p>
        </Form.InfoSectionContent>
      </Form.InfoSection>

      <Form.InfoSection>
        <Form.InfoSectionHeader>
          Contact email
        </Form.InfoSectionHeader>
        <Form.InfoSectionContent>
          <p>{userEmail}</p>
        </Form.InfoSectionContent>
      </Form.InfoSection>

      <Form.ButtonGroup className='form-buttons-container'>
        <Button type='submit' color={Button.Colors.BLUE} disabled={mutationLoading}>
          Send
        </Button>
        <Button type='button' color={Button.Colors.GREY} onClick={onCancelClick}>
          Cancel
        </Button>
      </Form.ButtonGroup>

    </Form>
  )
}

CustomerSupportForm.defaultProps = {
  ticketReasons: []
}

CustomerSupportForm.propTypes = {
  handleSubmit: T.func.isRequired,
  errors: T.object.isRequired,
  ticketReasons: T.arrayOf(T.shape({ label: T.string, value: T.string })),
  mutationLoading: T.bool.isRequired,
  currentUser: T.object
}

const CustomerSupportFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: customerSupportFormSchema,
    handleSubmit: (values, { props: { onTicketSubmit } }) => onTicketSubmit(values)
  })
)(CustomerSupportForm)

CustomerSupportFormEnhanced.propTypes = {
  onTicketSubmit: T.func.isRequired,
  onCancelClick: T.func.isRequired
}

export {
  CustomerSupportFormEnhanced as CustomerSupportForm
}
