import { TableResponsiveActionHeader } from '../../../misc/StylePlaceholders'
import { NewServiceLevelButton } from '../NewServiceLevelButton'

const ServiceLevelsHeader = () => (
  <TableResponsiveActionHeader>
    <NewServiceLevelButton />
  </TableResponsiveActionHeader>
)

export { ServiceLevelsHeader }
