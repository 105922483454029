import { useQuery } from '@apollo/client'
import { path, pipe, map, prop, sum, propEq, find } from 'ramda'
import constants from '@elenfs/elen-constants'

import { formatDate } from './../../../helpers/date'
import { generateRandomHexColor } from './../../../helpers/color'

import { BASE_EXPENDITURE_COLORS_ARR, BASE_INCOME_COLORS_ARR } from './const'

import clientDashboardSummaryGql from '../../../graphql/queries/client/clientDashboardSummary.graphql'
import clientPoliciesCountGql from '../../../graphql/queries/client/clientPoliciesCount.graphql'
import clientDocumentsCountGql from '../../../graphql/queries/client/clientDocumentsCount.graphql'
import clientAUMTotalsGql from '../../../graphql/queries/client/clientAUMTotals.graphql'

const { INVESTMENT, PENSIONS } = path(
  ['policy', 'common', 'AUMCategories'],
  constants
)

const generateColorArr = (colorsArr, records) => {
  if (colorsArr.length >= records.length) {
    return colorsArr.slice(0, records.length)
  } else {
    const extraColorsToGenerate = records.length - colorsArr.length
    return [
      ...colorsArr,
      ...[...Array(extraColorsToGenerate).keys()].map(generateRandomHexColor)
    ]
  }
}

export const useClientDashboardGql = client => {
  const {
    data: clientDashboardSummary,
    loading: clientDashboardSummaryLoading
  } = useQuery(clientDashboardSummaryGql, {
    variables: { clientId: client.id },
    fetchPolicy: 'cache-and-network'
  })

  const { data: clientPoliciesCount } = useQuery(clientPoliciesCountGql, {
    variables: { clientId: client.id, isExpired: false },
    fetchPolicy: 'cache-and-network'
  })

  const { data: clientDocumentsCount } = useQuery(clientDocumentsCountGql, {
    variables: { clientId: client.id },
    fetchPolicy: 'cache-and-network'
  })

  const { data: clientAUMTotals } = useQuery(clientAUMTotalsGql, {
    variables: { clientId: client.id },
    fetchPolicy: 'cache-and-network'
  })

  const currentAdviserFullName =
    path(['client', 'financialAdviser', 'fullName'], clientDashboardSummary) ||
    'Not set'

  const nextReviewDate =
    formatDate(path(['client', 'nextReviewAt'], clientDashboardSummary)) ||
    'Not set'

  const monthlyIncomeLabels = pipe(map(prop('source')))(
    clientDashboardSummary?.client?.incomes || []
  )

  const monthlyIncomeData = pipe(map(path(['monthlyAmount', 'amount'])))(
    clientDashboardSummary?.client?.incomes || []
  )

  const incomeColors = generateColorArr(
    BASE_INCOME_COLORS_ARR,
    monthlyIncomeData
  )

  const monthlyExpenditureLabels = pipe(map(prop('expense')))(
    clientDashboardSummary?.client?.expenses || []
  )

  const monthlyExpenditureData = pipe(map(path(['monthlyAmount', 'amount'])))(
    clientDashboardSummary?.client?.expenses || []
  )

  const expenditureColors = generateColorArr(
    BASE_EXPENDITURE_COLORS_ARR,
    monthlyExpenditureData
  )

  const isAUMEmpty =
    pipe(
      map(path(['total', 'amount'])),
      sum
    )(clientAUMTotals?.clientAUMTotals || []) === 0

  const investmentTotalAmount = pipe(
    find(propEq('type', INVESTMENT)),
    path(['total', 'amount'])
  )(clientAUMTotals?.clientAUMTotals || [])

  const pensionTotalAmount = pipe(
    find(propEq('type', PENSIONS)),
    path(['total', 'amount'])
  )(clientAUMTotals?.clientAUMTotals || [])

  return {
    clientDashboardSummary,
    clientDashboardSummaryLoading,
    clientPoliciesCount,
    clientDocumentsCount,
    clientAUMTotals,
    currentAdviserFullName,
    nextReviewDate,
    monthlyIncomeLabels,
    monthlyIncomeData,
    monthlyExpenditureLabels,
    monthlyExpenditureData,
    isAUMEmpty,
    investmentTotalAmount,
    pensionTotalAmount,
    incomeColors,
    expenditureColors
  }
}
