import { useParams } from 'react-router-dom'
import T from 'prop-types'
import { is } from 'ramda'

import { Table, Modal, Checkbox } from '../../../misc'

import { formatDate } from '../../../../helpers/date'
import { formatMoney } from '../../../../helpers/policy'
import { getChargeHeaders } from '../../../../helpers/charge'
import { AdviceTypesLabels, PolicyCategoriesLabels } from '../../../../helpers/enumLabels'
import { PolicyChargeDetailsModal, ClientChargeDetailsModal } from '../../ChargeDetailsModal'
import { PencilIcon } from '../../../icons'

const EditButton = (props) => (
  <PencilIcon {...props} />
)

const ChargesTableRow = ({
  charge,
  chargeType,
  isPolicyCharges
}) => {
  const { policyId } = useParams()
  const {
    id,
    adviceType,
    adviserRate,
    endDate,
    startDate,
    units,
    totalAmount,
    calculatedAmount,
    latestReconciledIncomeRecord,
    policy
  } = charge

  return (
    <Table.Row>
      <Table.Cell data-label={getChargeHeaders(chargeType).TYPE}>
        {AdviceTypesLabels[adviceType]}
      </Table.Cell>
      {
        !isPolicyCharges &&
          <>
            <Table.Cell data-label={getChargeHeaders(chargeType).POLICY_TYPE}>
              {PolicyCategoriesLabels[policy?.category]}
            </Table.Cell>
            <Table.Cell data-label={getChargeHeaders(chargeType).POLICY_NUMBER}>
              {policy?.number}
            </Table.Cell>
          </>
      }
      <Table.Cell data-label={getChargeHeaders(chargeType).RATE}>
        {adviserRate}{is(Number, adviserRate) && '%'}
      </Table.Cell>
      <Table.Cell data-label={getChargeHeaders(chargeType).START_DATE}>
        <span>{formatDate(startDate)}</span>
      </Table.Cell>
      <Table.Cell data-label={getChargeHeaders(chargeType).END_DATE}>
        <span>{formatDate(endDate)}</span>
      </Table.Cell>
      <Table.Cell data-label={getChargeHeaders(chargeType).AMOUNT}>
        {formatMoney(calculatedAmount)}
      </Table.Cell>
      <Table.Cell data-label={getChargeHeaders(chargeType).UNITS}>
        {units}
      </Table.Cell>
      <Table.Cell data-label={getChargeHeaders(chargeType).TOTAL_AMOUNT}>
        {formatMoney(totalAmount)}
      </Table.Cell>
      <Table.Cell data-label={getChargeHeaders(chargeType).RECONCILED}>
        {
          latestReconciledIncomeRecord &&
            <Checkbox
              inputId={`checkbox_${id}`}
              checked={!!latestReconciledIncomeRecord}
              disabled
            />
        }
      </Table.Cell>
      <Table.Cell data-label={getChargeHeaders(chargeType).EMPTY}>
        <Table.Actions>
          <Modal.Trigger
            className='table-responsive__action--navigate'
            as={EditButton}
            modal={isPolicyCharges ? PolicyChargeDetailsModal : ClientChargeDetailsModal}
            modalProps={{ policyId, charge, chargeType, isPolicyCharges }}
          />
        </Table.Actions>
      </Table.Cell>
    </Table.Row>
  )
}

ChargesTableRow.propTypes = {
  charge: T.object.isRequired,
  chargeType: T.string.isRequired
}

export { ChargesTableRow }
