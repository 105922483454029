import { FieldArray, connect } from 'formik'
import {
  ClientIncomeFrequencies,
  ClientIncomeFrequencyLabels,
  ClientIncomeTypes,
  ClientIncomeTypeLabels
} from '@elenfs/elen-constants'

import AddNewFormRowButton from '../../../misc/AddNewFormRowButton'
import IncomeFormInputRow from './IncomeFormInputRow'
import { TextAreaField } from '../../../form/fields'
import { FieldArrayItem } from '../../../form'

import { buildSelectOptions } from './../../../form/fields/SelectField/helpers'

const frequencyOptions = buildSelectOptions(
  ClientIncomeFrequencies,
  ClientIncomeFrequencyLabels
)
const typeOptions = buildSelectOptions(
  ClientIncomeTypes,
  ClientIncomeTypeLabels
)

const buildIncomeRowInput = () => ({
  source: '',
  grossAmount: { amount: '' },
  netAmount: { amount: '' },
  frequency: ClientIncomeFrequencies.MONTHLY,
  type: ClientIncomeTypes.PAYE
})

const IncomeForm = ({ formik }) => {
  const {
    values: { incomes = [] }
  } = formik
  return (
    <div className='client-income-form'>
      <FieldArray
        name='incomes'
        validateOnChange={false}
        render={arrayHelpers => (
          <div>
            {incomes.map((income, index) => (
              <FieldArrayItem
                key={index}
                index={index}
                label='Income'
                onRemove={() => arrayHelpers.remove(index)}
              >
                <IncomeFormInputRow
                  index={index}
                  income={income}
                  frequencyOptions={frequencyOptions}
                  typeOptions={typeOptions}
                />
              </FieldArrayItem>
            ))}
            <AddNewFormRowButton
              label='add income'
              onClick={() => arrayHelpers.push(buildIncomeRowInput())}
            />
          </div>
        )}
      />
      <TextAreaField
        name='incomeNotesAndComments'
        label='Additional notes and comments'
        inputProps={{ rows: 3 }}
      />
    </div>
  )
}

export default connect(IncomeForm)
