import { useMutation } from '@apollo/client'

import updateUserGql from '../../../../graphql/mutations/user/updateUser.graphql'
import { UsersDisableButton } from './UsersDisableButton'
import { withCurrentUser } from '../../../hoc/container'

const UsersDisableButtonWithCurrUser = withCurrentUser()(UsersDisableButton)

const UsersDisableButtonContainer = (props) => {
  const { user, ...restProps } = props
  const { id, enabled } = user

  const [mutate] = useMutation(updateUserGql, {
    variables: {
      input: {
        id,
        enabled: !enabled
      }
    }
  })

  return <UsersDisableButtonWithCurrUser user={user} mutate={mutate} {...restProps} />
}

export { UsersDisableButtonContainer as UsersDisableButton }
