import T from 'prop-types'
import classnames from 'classnames'

import './TabCard.less'

const TabCard = props => {
  const { category, Icon, title, onClick, text, className } = props

  return (
    <div className={classnames('tab-card', className)} onClick={onClick}>
      <div className='tab-card__icon-container'>
        <Icon />
      </div>

      <div className='tab-card__text-container'>
        <div className='tab-card__text-container__title'>{title}</div>

        <div className='tab-card__text-container__text'> {text} </div>

        <div className='tab-card__text-container__category'> {category} </div>
      </div>
    </div>
  )
}

TabCard.propTypes = {
  category: T.string.isRequired,
  Icon: T.elementType.isRequired,
  title: T.string.isRequired,
  onClick: T.func.isRequired,
  text: T.string.isRequired
}

export { TabCard }
