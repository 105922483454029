import { useCallback } from 'react'
import PropTypes from 'prop-types'

import Button from '../../../misc/Button'

const FileInputButton = ({
  children,
  disabled,
  multiple,
  onChange,
  ...buttonProps
}) => {
  const handleChange = useCallback(
    (e) => { onChange(e.target.files) },
    [onChange]
  )

  const handleClick = useCallback(
    (e) => {
      // Makes possible to select the same file more than once
      e.target.value = null
    },
    []
  )

  return (
    <>
      <Button
        as='label'
        htmlFor='file-input'
        color={Button.Colors.BLUE}
        {...buttonProps}
      >
        {children}
      </Button>
      <input
        type='file'
        id='file-input'
        multiple={multiple}
        disabled={disabled}
        className='hidden'
        onClick={handleClick}
        onChange={handleChange}
      />
    </>
  )
}

FileInputButton.defaultProps = {
  multiple: false
}

FileInputButton.propTypes = {
  children: PropTypes.node,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default FileInputButton
