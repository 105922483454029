import { is } from 'ramda'
import * as yup from 'yup'
import isISODateString from 'validator/lib/isISO8601'

yup.addMethod(yup.string, 'isoDateString', function () {
  const name = 'is-iso-date-string'
  // eslint-disable-next-line no-template-curly-in-string
  const message = '${path} is not a valid ISO8601 date string'

  return this.test(name, message, function (value) {
    return is(String, value) ? isISODateString(value) : true
  })
})
