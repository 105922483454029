import PensionPolicyForm from './PensionPolicyForm'
import policyFormContainer from '../../PolicyFormContainer'
import createPensionPolicy from '../../../../graphql/mutations/policy/createPensionPolicy.graphql'
import updatePensionPolicy from '../../../../graphql/mutations/policy/updatePensionPolicy.graphql'

const PensionPolicyFormContainer = policyFormContainer({
  createPolicyGql: createPensionPolicy,
  updatePolicyGql: updatePensionPolicy
})(PensionPolicyForm)

export default PensionPolicyFormContainer
