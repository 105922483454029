import { useEffect } from 'react'
import { compose, pipe, map, sum, prop } from 'ramda'
import { useClientDashboardGql } from './useClientDashboardGql'
import {
  withCurrentUser,
  withUnreadMessagesSubscription
} from '../../hoc/container'

import { IncomeToolTip } from '../../misc'
import { ClientDashboardPage } from './ClientDashboardPage'
import {
  UserIcon,
  EnvelopeIcon,
  DocumentIcon,
  PolicyIcon,
  ReviewIcon
} from './../../icons'

const ClientDashboardPageContainer = props => {
  const { client, subscribeForUnreadMessages, unreadMessagesCount } = props

  const {
    clientDashboardSummaryLoading,
    clientPoliciesCount,
    clientDocumentsCount,
    currentAdviserFullName,
    nextReviewDate,
    monthlyIncomeLabels,
    monthlyIncomeData,
    monthlyExpenditureLabels,
    monthlyExpenditureData,
    isAUMEmpty,
    investmentTotalAmount,
    pensionTotalAmount,
    incomeColors,
    expenditureColors
  } = useClientDashboardGql(client)

  useEffect(() => {
    let unsubscribe
    if (subscribeForUnreadMessages) {
      unsubscribe = subscribeForUnreadMessages()
    }
    return () => unsubscribe && unsubscribe()
  }, [subscribeForUnreadMessages])

  const currentFAProps = {
    boxes: [{ description: currentAdviserFullName }],
    isColumn: false,
    Icon: UserIcon,
    iconText: 'Current Adviser'
  }

  const nextReviewProps = {
    boxes: [{ description: nextReviewDate }],
    isColumn: false,
    Icon: ReviewIcon,
    iconText: 'Next review date'
  }

  const messagesCountProps = {
    title: 'Unread messages',
    count: pipe(map(prop('count')), sum)(unreadMessagesCount) || 0,
    Icon: EnvelopeIcon,
    navPathOnClick: '/clients/secure/overview/messages'
  }

  const policiesCountProps = {
    title: 'Active Policies',
    count: prop('clientPoliciesCount', clientPoliciesCount) || 0,
    Icon: PolicyIcon
  }

  const documentsCountProps = {
    title: 'Documents',
    count: prop('clientDocumentsCount', clientDocumentsCount) || 0,
    Icon: DocumentIcon,
    navPathOnClick: '/clients/secure/overview/documents'
  }

  const monthlyIncomeProps = {
    title: 'Monthly income',
    isEmpty: clientDashboardSummaryLoading || sum(monthlyIncomeData) === 0,
    emptyText: 'No income added',
    titleAction: <IncomeToolTip />,
    data: {
      labels: monthlyIncomeLabels,
      datasets: [
        {
          data: monthlyIncomeData,
          backgroundColor: incomeColors
        }
      ]
    }
  }

  const monthlyExpenditureProps = {
    title: 'Monthly expenditure',
    isEmpty: clientDashboardSummaryLoading || sum(monthlyExpenditureData) === 0,
    emptyText: 'No expenditure added',
    data: {
      labels: monthlyExpenditureLabels,
      datasets: [
        {
          data: monthlyExpenditureData,
          backgroundColor: expenditureColors
        }
      ]
    }
  }

  const AUMProps = {
    title: 'Assets under management',
    isEmpty: isAUMEmpty,
    data: {
      labels: ['Investment', 'Pension'],
      datasets: [
        {
          data: [investmentTotalAmount, pensionTotalAmount],
          backgroundColor: ['#F7BF47', '#516173']
        }
      ]
    }
  }

  return (
    <ClientDashboardPage
      {...{
        client,
        currentFAProps,
        nextReviewProps,
        messagesCountProps,
        policiesCountProps,
        documentsCountProps,
        monthlyIncomeProps,
        monthlyExpenditureProps,
        AUMProps
      }}
    />
  )
}

const EnhancedClientDashboardPageContainer = compose(
  withCurrentUser(),
  withUnreadMessagesSubscription()
)(ClientDashboardPageContainer)

export { EnhancedClientDashboardPageContainer as ClientDashboardPageContainer }
