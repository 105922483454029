import { useEffect } from 'react'
import { compose } from 'ramda'
import { withFormik, connect } from 'formik'
import classnames from 'classnames'
import T from 'prop-types'

import { Form, FormErrorMessages } from '../../form'
import { InputField } from '../../form/fields'
import { Button, LoadingMessage } from '..'

import './InlineForm.less'

const InlineForm = props => {
  const {
    errors,
    mutationError,
    loading,
    handleSubmit,
    inputName,
    setErrors,
    onCancel,
    values,
    resetForm,
    dontShowLoading,
    className,
    placeholder,
    inputId,
    setIsCollapsed
  } = props

  useEffect(() => setErrors({ '': mutationError }), [mutationError])

  const handleCancel = () => {
    resetForm()
    onCancel()
  }

  const isError = Object.values(errors).some(err => !!err)

  const submitIfNotLoading = e => {
    e.preventDefault()
    if (!loading) handleSubmit()
  }

  return (
    <Form
      className={classnames('inline-form', className)}
      onSubmit={submitIfNotLoading}
    >
      <InputField
        name={inputName}
        inputId={inputId}
        inputProps={{
          placeholder,
          onFocus: () => setIsCollapsed && setIsCollapsed(false),
          onBlur: () => !values[inputName]?.length && setIsCollapsed && setIsCollapsed(true)
        }}
      />

      <div
        className={classnames('inline-form__buttons-and-msg-container', {
          'inline-form__buttons-and-msg-container--show-msg':
          isError || (loading && !dontShowLoading)
        })}
      >
        {loading && !dontShowLoading && <LoadingMessage text='Creating...' />}
        {isError && <FormErrorMessages validationErrors={errors} />}

        <div
          className={classnames('inline-form__buttons-container', {
            'inline-form__buttons-container--show': values[inputName]
          })}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={loading || !values[inputName]}
          >
            Save
          </Button>
        </div>
      </div>
    </Form>
  )
}

InlineForm.defaultProps = {
  onCancel: () => {},
  dontShowLoading: false
}

InlineForm.propTypes = {
  onSubmit: T.func.isRequired,
  inputName: T.string.isRequired,
  loading: T.bool.isRequired,
  mutationError: T.string.isRequired,
  dontShowLoading: T.bool,
  onCancel: T.func,
  inputId: T.string
}

const EnhancedInlineForm = compose(
  withFormik({
    validateOnChange: false,
    handleSubmit: async ({ inputName, [inputName]: inputValue }, { props: { onSubmit, additionalInitialValues }, resetForm }) => {
      await onSubmit({ [inputName]: inputValue, ...additionalInitialValues })
      resetForm()
    }
  }),
  connect
)(InlineForm)

export { EnhancedInlineForm as InlineForm }
