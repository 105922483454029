const ErrorIcon = (props) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' color='#A8AAB7' {...props}>
    <defs>
      <path
        id='error'
        d='M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm9.315 2.718a.993.993 0 0 0 1.403-1.403L9.41 8.007l1.308-1.309a.993.993 0 0 0-1.403-1.403L8.007 6.603 6.694 5.291a.991.991 0 1 0-1.403 1.403l1.312 1.312-1.312 1.313a.991.991 0 1 0 1.403 1.403L8.007 9.41l1.308 1.308z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='error-mask' fill='#fff'>
        <use xlinkHref='#error' />
      </mask>
      <g fill='currentColor' mask='url(#error-mask)'>
        <path d='M0 0h16v16H0z' />
      </g>
    </g>
  </svg>
)

export default ErrorIcon
