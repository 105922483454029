import { useState } from 'react'
import T from 'prop-types'

import { downloadIncomeReports } from '../downloadIncomeReports'
import { Button } from '../../misc'
import { DownloadIcon } from '../../icons'

export const DownloadReportMenuContainer = ({ values, disabled, incomeType, ...props }) => {
  const [loading, setLoading] = useState(false)
  // TODO: useLazyQuery etc
  // const onDownloadPDF = () => {}
  const onDownloadExcel = async () => {
    setLoading(true)
    await downloadIncomeReports(incomeType, values)
    setLoading(false)
  }

  return (
    <Button
      color={Button.Colors.BLUE}
      disabled={disabled || loading}
      onClick={onDownloadExcel}
    >
      <DownloadIcon />
      <span>Download</span>
    </Button>
    // TODO use DownloadReportMenu and remove above when PDF is ready
  )
}

DownloadReportMenuContainer.propTypes = {
  /**
   * Values from the form.
   */
  values: T.object,
  /**
   * Does the form have any values.
   */
  disabled: T.bool
}
