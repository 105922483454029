import constants from '@elenfs/elen-constants'
import { prop, reject } from 'ramda'

import { DebtTypesLabels } from './enumLabels/debtPolicy'
import { InvestmentTypesLabels } from './enumLabels/investmentPolicy'
import { MortgageTypesLabels } from './enumLabels/mortgagePolicy'
import { PensionTypesLabels } from './enumLabels/pensionPolicy'
import { ProtectionTypesLabels } from './enumLabels/protectionPolicy'
import {
  PolicyContributionsTableHeader,
  PolicyWithdrawalsTableHeader,
  PolicyTypeByCategory
} from './enumLabels/policy'
import { CONTRIBUTION } from '../constants/policy'

const {
  ContributionMethods,
  WithdrawalMethods
} = constants.policy.common
const { ContributionFrequencies, WithdrawalFrequencies } = constants.policy.common

const calcPaymentSum = (transactions) => transactions.reduce((acc, t) => acc + t.total, 0)

export const calcTotalContributions = (contributions, withdrawals) => {
  const contributionsSum = calcPaymentSum(
    contributions.filter(({ method }) => method === ContributionMethods.CONTRIBUTION))

  const transfersIn = calcPaymentSum(
    contributions.filter(({ method }) => method === ContributionMethods.TRANSFER_IN))

  const withdrawalsSum = calcPaymentSum(
    withdrawals.filter(({ method }) => method === WithdrawalMethods.WITHDRAWAL))

  const transfersOut = calcPaymentSum(
    withdrawals.filter(({ method }) => method === WithdrawalMethods.TRANSFER_OUT))

  return {
    contributions: contributionsSum,
    withdrawals: withdrawalsSum,
    transfersIn,
    transfersOut,
    total: (contributionsSum + transfersIn) - (withdrawalsSum + transfersOut)
  }
}

export const calcTotalCommissionsAndFees = (commissions, fees) => calcPaymentSum(commissions) + calcPaymentSum(fees)

export const roundFloat = (value) => Number.parseFloat(value).toFixed(2)

export const addCommaToMoney = (value) => roundFloat(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

export const formatMoney = (value) => value
  ? value.currency
    ? new Intl.NumberFormat('en-UK', { style: 'currency', currency: value.currency }).format(value.amount)
    : `${value.amount < 0 ? '-' : ''}${addCommaToMoney((value.amount && Math.abs(value.amount)) || 0)}`
  : ''

export const splitToActiveAndExpiredByEndDate = (list) => {
  const activeList = reject(prop('isExpired'))(list)
  const expiredList = list.filter(prop('isExpired'))
  return { activeList, expiredList }
}

export const getSubCategoryLabels = (category) => {
  const {
    PolicyCategories: {
      DEBTS,
      INVESTMENT,
      MORTGAGES,
      PENSIONS,
      PROTECTION
    }
  } = constants.policy.common

  switch (category) {
    case DEBTS:
      return DebtTypesLabels
    case INVESTMENT:
      return InvestmentTypesLabels
    case MORTGAGES:
      return MortgageTypesLabels
    case PENSIONS:
      return PensionTypesLabels
    case PROTECTION:
      return ProtectionTypesLabels
    default:
      return {}
  }
}

export const isFrequencySingle = (frequency) => (
  [
    ContributionFrequencies.INITIAL,
    ContributionFrequencies.SINGLE,
    WithdrawalFrequencies.SINGLE
  ].includes(frequency)
)

export const isContribution = (policyTransactionType) => policyTransactionType === CONTRIBUTION

export const getPolicyTypeByCategory = (policy) => {
  if (!policy) { return }

  const currentPolicyType = policy[PolicyTypeByCategory[policy.category]]
  const policyTypes = getSubCategoryLabels(policy.category)
  const policyType = policyTypes[currentPolicyType]

  return policyType
}

export const getTransactionHeaders = (policyTransactionType) => (
  isContribution(policyTransactionType)
    ? PolicyContributionsTableHeader
    : PolicyWithdrawalsTableHeader
)
