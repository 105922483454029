import { useCallback } from 'react'
import T from 'prop-types'
import classnames from 'classnames'
import Cleave from 'cleave.js/react'
import { isNil } from 'ramda'

import { CLASSNAME } from '../Input'
import { roundFloat } from '../../../../helpers/policy'

// This input should share styles with other inputs in the project.
import '../Input/Input.less'

const MoneyInput = (props) => {
  const { className, handleChange, value, positiveOnly, ...restProps } = props

  const checkValue = useCallback((value) => {
    return !isNil(value) && !isNaN(value)
  })

  const roundAndSetValue = useCallback(
    (newValue) => {
      const value = roundFloat(newValue)
      if (checkValue(value)) handleChange(value)
    },
    [checkValue, handleChange]
  )

  return (
    <Cleave
      className={classnames(CLASSNAME, 'money-input', className)}
      value={value}
      onBlur={useCallback(
        (e) => {
          roundAndSetValue(e.target.rawValue)
          // TODO: these will probably be needed when we get to `plumb` the component.
          // They were present in the old component.
          // e.preventDefault()
        },
        [roundAndSetValue]
      )}
      onPaste={useCallback(
        (e) => {
          roundAndSetValue(e.clipboardData.getData('Text'))
          // e.preventDefault()
        },
        [roundAndSetValue]
      )}
      onChange={useCallback(
        (e) => {
          const value = e.target.rawValue
          if (value === '' || checkValue(parseFloat(value))) handleChange(value)
          // e.preventDefault()
        },
        [checkValue, handleChange]
      )}
      options={{
        numeral: true,
        numeralPositiveOnly: positiveOnly,
        numeralDecimalScale: 2,
        delimiter: ',',
        numeralDecimalMark: '.',
        numeralThousandsGroupStyle: 'thousand'
      }}
      placeholder='0.00'
      {...restProps}
    />
  )
}

MoneyInput.propTypes = {
  /**
  Called when date value gets changed.
  */
  handleChange: T.func,
  /**
   * Input classname.
   */
  className: T.string,
  /**
   * Value for the controlled input.
   */
  value: T.oneOfType([T.string, T.number]),
  /**
   * Allow positive only values
   */
  positiveOnly: T.bool
}

MoneyInput.defaultProps = {
  positiveOnly: true
}

export { MoneyInput }
