import { useCallback } from 'react'
import T from 'prop-types'

import { P1DisconnectButton } from './P1DisconnectButton'

const P1DisconnectButtonContainer = ({ disconnectMutation, ...restProps }) => {
  const disconnectCallback = useCallback(
    async (confirmationFlag) => {
      if (confirmationFlag) {
        await disconnectMutation()
      }
    }, [disconnectMutation]
  )

  return (
    <P1DisconnectButton {...{
      disconnectCallback,
      ...restProps
    }}
    />
  )
}

P1DisconnectButtonContainer.propTypes = {
  disconnectMutation: T.func.isRequired
}

export { P1DisconnectButtonContainer }
