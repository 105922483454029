import T from 'prop-types'

import { EmptyPage, PageContent } from '../../misc'

const ClientMessagesEmpty = ({ isClientUser }) => {
  const emptyText = isClientUser
    ? 'No messages'
    : "This client doesn't have an account on the Client portal yet. Start by inviting them first."

  return (
    <PageContent>
      <EmptyPage text={emptyText} />
    </PageContent>
  )
}

ClientMessagesEmpty.propTypes = {
  /**
   * If client is accepted invitation and created account
   */
  isClientUser: T.bool
}

export { ClientMessagesEmpty }
