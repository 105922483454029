import { ALPHABET, COLORS, CLIENT_COLOR } from './constants'
import { getInitialsFromUser } from '../../../helpers/users'
import constants from '@elenfs/elen-constants'

const { UserTypes } = constants.user

const getPositionInAlphabet = (letter) => {
  const index = ALPHABET.indexOf(letter.toUpperCase())
  // return 1 in case that the initial isn't in the alphabet
  return index === -1 ? 1 : index + 1
}

const getColorForInitials = (initials) => {
  const positionsSum = getPositionInAlphabet(initials[0]) + getPositionInAlphabet(initials[1])
  const colorsLen = COLORS.length
  const modulo = positionsSum % colorsLen

  return COLORS[modulo]
}

export const getColorForUser = user => (
  user?.type === UserTypes.COMPANY_USER
    ? getColorForInitials(getInitialsFromUser(user))
    : CLIENT_COLOR
)

export const getInitialsString = (user) => {
  const initials = getInitialsFromUser(user)
  return `${initials[0] || ''}${initials[1] || ''}`
}
