import pluralize from 'pluralize'

import { PageContent } from './../../misc'
import { TeamCard } from '../TeamCard'

import './TeamsPage.less'

const TeamsPage = ({ teamsUserIsPartOfCount, teams, currentUser }) => (
  <PageContent className='teams-page'>
    <div className='teams-page__description'>
      <span>
        {teamsUserIsPartOfCount
          ? (
            <>
              You are part of {teamsUserIsPartOfCount}{' '}
              {pluralize('team', teamsUserIsPartOfCount)}
            </>
            )
          : (
            <>
              You’re not a member of any teams yet. Ask an adviser to add you to
              their team.
            </>
            )}
      </span>
      <span>Team functionality is needed when creating a new workflow.</span>
    </div>

    <div className='teams-page__container'>
      {teams.map(team => (
        <TeamCard key={team.id} {...{ key: team.id, team, currentUser }} />
      ))}
    </div>
  </PageContent>
)

export { TeamsPage }
