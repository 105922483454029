import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableBody = forwardRef(({ children, className, ...rest }, ref) => (
  <tbody
    className={classnames('table-new__body', className)}
    {...rest}
    ref={ref}
  >
    {children}
  </tbody>
))

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default TableBody
