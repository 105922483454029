import PolicyDetails from './ProtectionPolicyDetails'
import PolicyDetailsForm from './ProtectionPolicyDetailsForm'
import PolicyForm from '../../PolicyForm'
import protectionPolicySchema from '../../../../schemas/policy/protectionPolicySchema'

const ProtectionPolicyForm = props => (
  <PolicyForm {...{
    PolicyDetails,
    PolicyDetailsForm,
    policySchema: protectionPolicySchema,
    ...props
  }}
  />
)

export default ProtectionPolicyForm
