import { Fragment } from 'react'
import constants from '@elenfs/elen-constants'

import {
  InputField,
  DateField,
  MoneyField,
  RadioGroupField
} from '../../../form/fields'
import { SharedPolicyFormFields } from '../SharedPolicyFormFields'
import { buildSelectOptions } from '../../../form/fields/SelectField'

import {
  MortgageTypesLabels,
  RepaymentTypesLabels,
  RateTypesLabels,
  PlanStatusesLabels
} from '../../../../helpers/enumLabels/mortgagePolicy'
import { buildBooleanSelectBoxOptions } from '../../../../helpers/form'
import { FormGroup } from '../../../form/Form/FormGroup'

const { PlanStatuses } = constants.policy.mortgagePolicy
const {
  MortgageTypes,
  RepaymentTypes,
  RateTypes
} = constants.policy.mortgagePolicy

const MortgagePolicyDetailsForm = ({ currentClient, companyClients }) => (
  <>
    <div className='policy-type-form mortgage-policy-form'>
      <SharedPolicyFormFields
        {...{
          companyClients,
          currentClient,
          policyTypeLabel: 'Mortgage type',
          policyTypeName: 'mortgageType',
          policyTypeOptions: buildSelectOptions(
            MortgageTypes,
            MortgageTypesLabels
          ),
          planStatusOptions: buildSelectOptions(
            PlanStatuses,
            PlanStatusesLabels
          )
        }}
      />

      <FormGroup label='Property address'>
        <div className='policy-type-form__row policy-type-form__row policy-type-form__address-block'>
          <InputField
            name='propertyAddress1'
            label='Address line 1'
          />

          <InputField
            name='propertyAddress2'
            label='Address line 2'
          />
          <div className='policy-type-form__row policy-type-form__row--2'>
            <InputField
              name='propertyAddress3'
              label='City'
            />

            <InputField
              name='propertyAddress4'
              label='Post code'
            />
          </div>
        </div>
      </FormGroup>

      <FormGroup label='Dates'>
        <div className='policy-type-form__row policy-type-form__row--4'>
          <DateField
            name='applicationDate'
            label='Application date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='startDate'
            label='Start date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='endDate'
            label='End date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='valuationDate'
            label='Valuation date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>
      </FormGroup>

      <FormGroup label='Repayments and contributions'>
        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-repayment-type'
            options={buildSelectOptions(RepaymentTypes, RepaymentTypesLabels)}
            name='repaymentType'
            groupLabel='Repayment type'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-rate-type'
            options={buildSelectOptions(RateTypes, RateTypesLabels)}
            name='rateType'
            groupLabel='Rate type'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--4'>
          <InputField
            name='rate'
            label='Rate'
            className='policy-type-form__percent-input'
            inputProps={{ placeholder: 0 }}
          />

          <DateField
            name='endOfRatePeriod'
            label='End of rate period'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-exit-penalty-apply'
            options={buildBooleanSelectBoxOptions()}
            name='exitPenaltiesApply'
            groupLabel='Exit penalties apply'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--4'>
          <MoneyField name='amountOutstanding' label='Amount outstanding' />
        </div>
      </FormGroup>
    </div>
  </>
)

export default MortgagePolicyDetailsForm
