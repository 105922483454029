import T from 'prop-types'
import classnames from 'classnames'

import { Form } from '../../../Form'

import './CheckboxFieldGroup.less'

const CheckboxFieldGroup = ({ label, className, children }) => (
  <Form.Field className='checkbox-field-group'>
    {label && <label>{label}</label>}
    <div className={classnames(className, 'checkbox-field-group__checkboxes')}>
      {children}
    </div>
  </Form.Field>
)

CheckboxFieldGroup.propTypes = {
  label: T.string,
  children: T.node.isRequired,
  className: T.string
}

export { CheckboxFieldGroup }
