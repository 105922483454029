import * as yup from 'yup'
import { TaskStatuses, TaskTypes } from '@elenfs/elen-constants'

import { stringSchema, dateSchema, enumSchema, arrayOfSchema } from '../helpers'
import { taskAttachmentSchema } from './taskAttachmentSchema'

const taskSchema = yup.object().shape({
  status: enumSchema(Object.values(TaskStatuses))
    .label('Status')
    .default('TO_DO'),

  assignedTo: stringSchema()
    .label('Assignee')
    .default(''),

  dueDate: dateSchema().label('Due Date'),

  type: enumSchema(Object.values(TaskTypes))
    .label('Activity type')
    .default('WORK_ON_FILE'),

  clientId: stringSchema().label('Client'),

  summary: stringSchema()
    .label('Summary')
    .required(),

  description: stringSchema().label('Description'),

  attachments: arrayOfSchema(taskAttachmentSchema)
})

export { taskSchema }
