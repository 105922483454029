import constants, {
  CompanyUserRoles,
  ActivityLogEntryTypes,
  WorkflowActionStatuses
} from '@elenfs/elen-constants'
import T from 'prop-types'
import { is } from 'ramda'

import { formatDate, millisecondsToDays } from './../../../../helpers/date'
import { TaskTypeLabels } from './../../../../helpers/enumLabels'
import { userType } from './../../../../helpers/propTypes'
import { TaskStatusLabels } from './../../../../helpers/enumLabels/tasks'

const { UserRolesLabels } = constants.user

const TaskActionBody = ({ action }) => {
  const {
    taskAssigneeTeamRole,
    taskAssignee,
    taskDueDateAfterMs,
    taskType,
    taskDescription,
    status,
    taskDueDate
  } = action

  const assigneeLabel = status
    ? taskAssignee?.fullName
    : UserRolesLabels[taskAssigneeTeamRole]

  const dueDateLabel = status
    ? formatDate(taskDueDate)
    : is(Number, taskDueDateAfterMs)
      ? `${millisecondsToDays(taskDueDateAfterMs)} days later`
      : ''

  return (
    <div className='action-body'>
      {status && (
        <div className='action-body__row'>
          <span>Status:</span>
          <span> {TaskStatusLabels[status] || 'Not created yet'} </span>
        </div>
      )}

      <div className='action-body__row'>
        <span>Assignee:</span>
        <span> {assigneeLabel || 'Unassigned'} </span>
      </div>

      <div className='action-body__row'>
        <span>Due date:</span>
        <span>{dueDateLabel}</span>
      </div>

      <div className='action-body__row'>
        <span>Type:</span>
        <span> {TaskTypeLabels[taskType]} </span>
      </div>

      <div className='action-body__row'>
        <span>Description:</span>
        <span>{taskDescription}</span>
      </div>
    </div>
  )
}

TaskActionBody.propTypes = {
  taskAssigneeTeamRole: T.oneOf([...Object.values(CompanyUserRoles), '']),
  taskAssignee: userType(),
  taskDueDate: T.oneOfType([T.number, T.string]),
  taskType: T.oneOf(Object.values(ActivityLogEntryTypes)),
  taskDescription: T.string,
  status: T.arrayOf(Object.values(WorkflowActionStatuses)),
  taskDueDateAfterMs: T.oneOfType([T.number, T.string])
}

export { TaskActionBody }
