import { FieldArray, connect } from 'formik'

import AddNewFormRowButton from '../../../misc/AddNewFormRowButton'
import LiabilitiesFormInputRow from './LiabilitiesFormInputRow'
import { FieldArrayItem } from '../../../form'
import { TextAreaField } from '../../../form/fields'

const buildLiabilitiessRowInput = () => ({
  owner: '',
  liabilityType: '',
  outstandingValue: { amount: '' },
  monthlyCost: { amount: '' },
  startDate: '',
  endDate: ''
})

const LiabilitiesForm = ({ formik }) => {
  const {
    values: { liabilities = [] }
  } = formik
  return (
    <div>
      <FieldArray
        name='liabilities'
        validateOnChange={false}
        render={arrayHelpers => (
          <div>
            {liabilities.map((liability, index) => (
              <FieldArrayItem
                key={index}
                index={index}
                label='Liability'
                onRemove={() => arrayHelpers.remove(index)}
              >
                <LiabilitiesFormInputRow index={index} />
              </FieldArrayItem>
            ))}
            <AddNewFormRowButton
              label='add liability'
              onClick={() => arrayHelpers.push(buildLiabilitiessRowInput())}
            />
          </div>
        )}
      />
      <TextAreaField
        name='liabilitiesNotesAndComments'
        label='Additional notes and comments'
        inputProps={{
          rows: 3
        }}
      />
    </div>
  )
}

export default connect(LiabilitiesForm)
