import { Link } from 'react-router-dom'
import T from 'prop-types'

import './ImportFooter.less'

export const ImportFooterConnection = ({ pageTitle, link }) => (
  <div className='import-footer'>
    The logs related to your {pageTitle} connection are available on the <Link className='import-footer__link' to={link}>following page</Link>
  </div>
)

ImportFooterConnection.propTypes = {
  pageTitle: T.string.isRequired,
  link: T.string.isRequired
}
