import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import booleanSchema from '../helpers/booleanSchema'
import dateSchema from '../helpers/dateSchema'
import enumSchema from '../helpers/enumSchema'
import numberSchema from '../helpers/numberSchema'
import percentSchema from '../helpers/percentSchema'
import stringSchema from '../helpers/stringSchema'
import commonFields from './commonFields'
import moneySchema from '../helpers/moneySchema'

const {
  MortgageTypes,
  PlanStatuses,
  RepaymentTypes,
  RateTypes
} = constants.policy.mortgagePolicy

export default yup.object().shape({
  ...commonFields(),

  mortgageType: enumSchema(values(MortgageTypes))
    .label('Mortgage type')
    .required(),

  propertyAddress1: stringSchema()
    .label('Property address'),

  propertyAddress2: stringSchema()
    .label('Property address'),

  propertyAddress3: stringSchema()
    .label('Property address'),

  propertyAddress4: stringSchema()
    .label('Property address'),

  planStatus: enumSchema(values(PlanStatuses))
    .label('Plan status'),

  repaymentType: enumSchema(values(RepaymentTypes))
    .label('Repayment type'),

  rateType: enumSchema(values(RateTypes))
    .label('Rate type'),

  rate: percentSchema()
    .label('Rate'),

  endOfRatePeriod: dateSchema()
    .label('End of rate period'),

  mortgageTerm: numberSchema()
    .label('Rate term')
    .integer(),

  exitPenaltiesApply: booleanSchema()
    .label('Exit penalties apply'),

  startDate: dateSchema()
    .label('Start date'),

  endDate: dateSchema()
    .label('Maturity date'),

  amountOutstanding: moneySchema({
    amountLabel: 'Amount outstanding',
    currencyLabel: 'Amount outstanding currency'
  }),

  valuationDate: dateSchema()
    .label('Valuation date')
}).noUnknown(true)
