import { useCallback } from 'react'
import { connect } from 'formik'
import { useNavigate } from 'react-router-dom'
import constants from '@elenfs/elen-constants'

import FormFieldsCard from '../../form/FormFieldsCard'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import Card from '../../misc/Card'
import { buildBooleanSelectBoxOptions } from '../../../helpers/form'
import {
  EmploymentStatusLabels,
  ClientTaxRatesLabels
} from '../../../helpers/enumLabels/client'
import clientEmploymentSchema from '../../../schemas/client/clientEmploymentSchema'
import {
  SelectField,
  InputField,
  TextAreaField,
  MoneyField,
  RadioGroupField
} from '../../form/fields'
import { buildSelectOptions } from '../../form/fields/SelectField'
import { PageDivider } from '../../misc'
import { validateAndNavigate } from '../../../helpers/client'

import './ClientEmploymentDetailsForm.less'

const { EmploymentStatus, ClientTaxRates } = constants.client

const ClientEmploymentDetailsForm = ({
  setValidationSchema,
  nextLink,
  prevLink,
  formik
}) => {
  const navigate = useNavigate()
  const { validateForm } = formik

  const handleNavButtonClick = useCallback(
    isNext => {
      validateAndNavigate({
        setSchema: setValidationSchema,
        schema: clientEmploymentSchema,
        validate: validateForm,
        nextLocation: isNext ? nextLink : prevLink,
        navigate
      })
    },
    [
      navigate,
      validateForm,
      nextLink,
      prevLink,
      clientEmploymentSchema,
      setValidationSchema
    ]
  )

  return (
    <Card className='client-details-form__employment'>
      <FormFieldsCard title='Employment'>
        <div className='client-details-form__employment__row client-details-form__employment__row--radio'>
          <RadioGroupField
            inputId='client-details-form__employment__ukDomiciled'
            options={buildBooleanSelectBoxOptions()}
            name='ukDomiciled'
            groupLabel='UK domiciled'
            isTextButton
          />

          <div className='client-details-form__employment__row--radio__divider' />

          <RadioGroupField
            inputId='client-details-form__employment__ukResidentForTax'
            options={buildBooleanSelectBoxOptions()}
            name='ukResidentForTax'
            groupLabel='UK resident for tax'
            isTextButton
          />

          <div className='client-details-form__employment__row--radio__divider' />

          <InputField
            name='niNumber'
            label='NI number'
            inputProps={{
              maxLength: 50
            }}
          />
        </div>

        <PageDivider />

        <div className='client-details-form__employment__row'>
          <SelectField
            name='employmentStatus'
            label='Employment status'
            clearable={false}
            options={buildSelectOptions(
              EmploymentStatus,
              EmploymentStatusLabels
            )}
          />
        </div>

        <div className='client-details-form__employment__row'>
          <InputField
            name='occupation'
            label='Occupation'
            inputProps={{
              maxLength: 50
            }}
          />
          <InputField
            name='employer'
            label='Employer'
            inputProps={{
              maxLength: 50
            }}
          />

          <InputField
            name='timeEmployed'
            label='Time employed'
            inputProps={{
              maxLength: 50
            }}
          />
        </div>

        <div className='client-details-form__employment__row'>
          <MoneyField
            name='annualGrossSalary'
            label='Annual gross salary'
            inputProps={{
              placeholder: '0.00'
            }}
          />

          <SelectField
            name='highestTaxRatePaid'
            label='Highest rate of tax paid'
            className='label-no-wrap'
            options={buildSelectOptions(ClientTaxRates, ClientTaxRatesLabels)}
          />
        </div>

        <div className='client-details-form__employment__row'>
          <InputField
            name='desiredRetirementAge'
            label='Desired retirement age'
            inputProps={{
              maxLength: 2,
              onKeyPress: event => {
                const parsedKey = parseInt(event.key)
                const parsedValue = parseInt(event.target.value)

                if (
                  // don't allow 0's if they're the first digit
                  (isNaN(parsedValue) && !parsedKey) ||
                  isNaN(parsedKey)
                ) {
                  event.preventDefault()
                }
              }
            }}
          />
        </div>

        <div className='client-details-form__employment__row'>
          <RadioGroupField
            inputId='client-details-form__employment__childBenefitReduced'
            options={buildBooleanSelectBoxOptions()}
            name='childBenefitReduced'
            groupLabel='Child benefit reduced'
            isTextButton
          />
        </div>

        <TextAreaField
          name='employmentNotesAndComments'
          label='Additional notes and comments'
          inputProps={{ rows: 3 }}
        />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}

export default connect(ClientEmploymentDetailsForm)
