import { TasksNav } from './TasksNav'
import { ListFull, Board } from '../../icons'
import { Text } from '../../misc'

import './TasksNav.less'

const items = [
  { label: 'List', path: '../list-view', Icon: ListFull },
  { label: 'Board', path: '../kanban-view', Icon: Board }
]

const TasksChangeViewSwitch = props => {
  return (
    <div className='tasks-change-view-switch'>
      <Text className='tasks-change-view-switch__label'>Change view: </Text>
      <TasksNav className='tasks-change-view-switch__nav' items={items} {...props} />
    </div>
  )
}

export { TasksChangeViewSwitch }
