import T from 'prop-types'

import { Badge, Tooltip } from '../'
import { DataImportPlatformLabels, DataImportPlatformIntegrationBadgeLabels } from '../../../helpers/enumLabels/dataImport'

import './IntegrationBadge.less'

const IntegrationBadge = ({ labelType, integrations }) => {
  if (!Array.isArray(integrations) || !integrations.length) {
    return null
  }

  const [{ type }] = integrations
  const platformLabel = DataImportPlatformLabels[type]
  const platformBadgeLabel = DataImportPlatformIntegrationBadgeLabels[type]

  return (
    <Tooltip.Trigger
      className='integration-badge'
      as={Badge}
      tooltip={Tooltip.Info}
      tooltipId={`imported-from-${platformBadgeLabel?.toLowerCase()}-badge`}
      tooltipProps={{
        text: `${labelType} imported from ${platformLabel}`,
        position: 'bottom'
      }}
      color={Badge.Colors.GREY}
      textColor={Badge.TextColor.GREY}
      action
    >
      {platformBadgeLabel}
    </Tooltip.Trigger>
  )
}

IntegrationBadge.defaultProps = {
  type: 'Client'
}

IntegrationBadge.propTypes = {
  type: T.string,
  integrations: T.arrayOf(T.shape({
    type: T.string.isRequired
  }))
}

export { IntegrationBadge }
