import ProtectionPolicyForm from './ProtectionPolicyForm'
import policyFormContainer from '../../PolicyFormContainer'
import createProtectionPolicy from '../../../../graphql/mutations/policy/createProtectionPolicy.graphql'
import updateProtectionPolicy from '../../../../graphql/mutations/policy/updateProtectionPolicy.graphql'

const ProtectionPolicyFormContainer = policyFormContainer({
  createPolicyGql: createProtectionPolicy,
  updatePolicyGql: updateProtectionPolicy
})(ProtectionPolicyForm)

export default ProtectionPolicyFormContainer
