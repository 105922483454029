import { useQuery } from '@apollo/client'
import { prop } from 'ramda'

import { EnvelopeIcon } from '../../icons'
import { Modal } from '../../misc'
import { SendMessageModal } from '../../misc/SendMessageModal'
import { LastVisitedClientsCard } from './LastVisitedClientsCard'

import { mapToClientListProp } from './../DashboardClientsList/helpers'

import currentUserLastVisitedGql from '../../../graphql/queries/user/currentUserLastVisited.graphql'

const MessageModalTrigger = ({ clientId, clientName }) => (
  <Modal.Trigger
    as='span'
    modal={SendMessageModal}
    modalProps={{ clientId, clientName }}
  >
    <EnvelopeIcon />
  </Modal.Trigger>
)

const LastVisitedClientsCardContainer = () => {
  const { data } = useQuery(currentUserLastVisitedGql, {
    fetchPolicy: 'cache-and-network'
  })
  const { companyUserLastVisitedClients } = data || {}

  const clientList = companyUserLastVisitedClients
    ? mapToClientListProp(companyUserLastVisitedClients.map(prop('clientData')))
    : []

  return (
    <LastVisitedClientsCard
      clientList={clientList}
      ActionComponent={MessageModalTrigger}
    />
  )
}

export { LastVisitedClientsCardContainer }
