import { last } from 'ramda'
import { FILE_TYPES } from '../constants/file'

export const removeCommonFieldsAliases = doc => {
  if (!doc) {
    return
  }

  const aliasPrefix = `${doc.__typename}_`

  return Object.keys(doc).reduce((acc, key) => {
    const keyValue = doc[key]

    if (key.startsWith(aliasPrefix)) {
      key = key.replace(aliasPrefix, '')
    }

    return { ...acc, [key]: keyValue }
  }, {})
}

export const removeTypenameProperties = value => {
  if (Array.isArray(value)) {
    return value.map(removeTypenameProperties)
  } else if (value !== null && typeof value === 'object') {
    const newObject = {}
    for (const property in value) {
      if (property !== '__typename') {
        newObject[property] = removeTypenameProperties(value[property])
      }
    }
    return newObject
  } else {
    return value
  }
}

export const isFilePdf = (clientDocument = { fileName: '' }) =>
  last(clientDocument?.fileName.split('.')).toLowerCase() === FILE_TYPES.PDF
