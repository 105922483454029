const EnvelopeIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M20.5 5A1.5 1.5 0 0122 6.5v12a1.5 1.5 0 01-1.5 1.5h-17A1.5 1.5 0 012 18.5v-12A1.5 1.5 0 013.5 5h17zM20 7.645l-6.946 6.936-.114.103a1.5 1.5 0 01-2.006-.104L4 7.646 4 18h16V7.645zM17.815 7H6.181L12 12.816 17.816 7z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export default EnvelopeIcon
