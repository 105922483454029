export const EmailMessageParticipantTypes = Object.freeze({
  SENDER: 'SENDER',
  RECIPIENT: 'RECIPIENT',
  CC: 'CC',
  BCC: 'BCC'
})

export const EmailMessageAttachmentTypes = Object.freeze({
  ATTACHMENT: 'ATTACHMENT',
  INLINE: 'INLINE'
})
