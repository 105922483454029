import T from 'prop-types'
import { last } from 'ramda'

import { DownloadIcon } from '../../icons'

import { getFileIcon } from '../../../helpers/clientDocument'

const FileIcon = ({ name, className }) => {
  const Icon = getFileIcon(last(name.split('.')))
  return <Icon className={className} />
}

const EmailAttachments = ({ id, attachments, onDownloadFile }) => (
  <>
    {attachments.map(({ name, attachmentId }) => (
      <div
        className='audit-log-entry-email__body__attachments__item'
        key={attachmentId}
      >
        <FileIcon
          name={name}
          className='audit-log-entry-email__body__attachments__item__file-icon'
        />
        <div className='audit-log-entry-email__body__attachments__item__file-name'>
          {name}
        </div>
        <DownloadIcon
          onClick={() => onDownloadFile(id, attachmentId)}
          className='audit-log-entry-email__body__attachments__item__download-icon'
        />
      </div>
    ))}
  </>
)

EmailAttachments.defaultProps = {
  attachments: []
}

EmailAttachments.propTypes = {
  attachments: T.arrayOf(
    T.shape({
      type: T.string,
      name: T.string,
      attachmentId: T.string
    })
  )
}

export { EmailAttachments }
