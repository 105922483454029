import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { Spinner as SpinJS } from 'spin.js'

import 'spin.js/spin.css'

export default class Spinner extends Component {
  static propTypes = {
    config: PropTypes.object
  }

  static defaultProps = {
    config: {}
  }

  constructor (...args) {
    super(...args)
    this._spinJs = null
    this._spinJsEl = createRef()
  }

  componentDidMount () {
    const spinConfig = {
      lines: 10, // The number of lines to draw
      length: 28, // The length of each line
      width: 16, // The line thickness
      radius: 35, // The radius of the inner circle
      scale: 1, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      color: '#7f8fa4', // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      speed: 0.7, // Rounds per second
      rotate: 0, // The rotation offset
      animation: 'spinner-line-fade-default', // The CSS animation name for the lines
      direction: 1, // 1: clockwise, -1: counterclockwise
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      className: 'spinner', // The CSS class to assign to the spinner
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
      shadow: '0 0 1px transparent', // Box-shadow for the lines
      position: 'absolute', // Element positioning
      ...this.props.config
    }

    this._spinJs = new SpinJS(spinConfig)
    this._spinJs.spin(this._spinJsEl.current)
  }

  componentWillUnmount () {
    this._spinJs.stop()
  }

  render () {
    return (
      <span
        className='spinner-wrapper'
        ref={this._spinJsEl}
      />
    )
  }
}
