import classnames from 'classnames'

import { InitialsAvatar } from '../../../misc'

const OptionWithInitials = ({
  children,
  className,
  cx,
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  innerRef,
  innerProps,
  data,
  ...props
}) => (
  <div
    ref={innerRef}
    css={getStyles('option', props)}
    className={classnames(
      'dropdown-selection__option--with-initials',
      cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        className
      )
    )}
    {...innerProps}
  >
    <InitialsAvatar user={data?.user} />
    {children}

    {!!data.hint && (
      <div className='dropdown-selection__option--with-initials__hint-text'>
        <span> - </span>
        <span>{data.hint}</span>
      </div>
    )}
  </div>
)

export { OptionWithInitials }
