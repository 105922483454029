import { Table } from '../../misc'

const ReportTableRow = ({ row, rowSchema }) => (
  <Table.Row>
    {rowSchema.map(({ dataLabel, buildData }, i) => (
      <Table.Cell key={i} data-label={dataLabel}>
        {buildData(row)}
      </Table.Cell>
    ))}
  </Table.Row>
)

export { ReportTableRow }
