import constants from '@elenfs/elen-constants'

const {
  AssuredLives,
  CoverTypes,
  DeferredPeriods,
  OfferedTerms,
  PlanStatuses,
  Waivers,
  ProtectionTypesLabels
} = constants.policy.protectionPolicy

export const AssuredLivesLabels = Object.freeze({
  [AssuredLives.CLIENT]: 'Client',
  [AssuredLives.JOINT_LIFE_FIRST_DEATH]: 'Joint life first death',
  [AssuredLives.JOINT_LIFE_SECOND_DEATH]: 'Joint life second death',
  [AssuredLives.PARTNER]: 'Partner'
})

export const CoverTypesLabels = Object.freeze({
  [CoverTypes.BALANCED]: 'Balanced',
  [CoverTypes.MAXIMUM]: 'Maximum'
})

export const DeferredPeriodsLabels = Object.freeze({
  [DeferredPeriods.DAY_1_COVER]: 'Day 1 cover',
  [DeferredPeriods.WEEKS_1]: '1 week',
  [DeferredPeriods.WEEKS_2]: '2 weeks',
  [DeferredPeriods.WEEKS_4]: '4 weeks',
  [DeferredPeriods.WEEKS_6]: '6 weeks',
  [DeferredPeriods.WEEKS_13]: '13 weeks',
  [DeferredPeriods.WEEKS_26]: '26 weeks',
  [DeferredPeriods.WEEKS_36]: '36 weeks',
  [DeferredPeriods.WEEKS_52]: '52 weeks'
})

export const OfferedTermsLabels = Object.freeze({
  [OfferedTerms.RATED_TERMS]: 'Rated terms',
  [OfferedTerms.STANDARD_TERMS]: 'Standard terms',
  [OfferedTerms.STANDARD_TERMS_WITH_EXCLUSIONS]: 'Standard terms with exclusions'
})

export const PlanStatusesLabels = Object.freeze({
  [PlanStatuses.APPLICATION_SUBMITTED]: 'Application submitted',
  [PlanStatuses.CANCELLED]: 'Cancelled',
  [PlanStatuses.DECLINED]: 'Declined',
  [PlanStatuses.LAPSED]: 'Lapsed',
  [PlanStatuses.MATURED]: 'Matured',
  [PlanStatuses.NTU]: 'Not Taken Up',
  [PlanStatuses.OFFERED]: 'Offered',
  [PlanStatuses.ON_RISK]: 'On Risk',
  [PlanStatuses.SURRENDERED]: 'Surrendered'
})

export const WaiversLabels = Object.freeze({
  [Waivers.BOTH]: 'Both',
  [Waivers.CLIENT]: 'Client',
  [Waivers.PARTNER]: 'Partner',
  [Waivers.NO]: 'No'
})

export { ProtectionTypesLabels }
