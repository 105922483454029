import T from 'prop-types'
import { useLocation } from 'react-router-dom'
import { fromSignupValue, fromSignupKey } from '@elenfs/elen-constants'

import { SuccessIcon } from './../../icons'
import { Heading, PageDivider } from '../../misc'

import './UserFormPage.less'

const UserFormPage = props => {
  const { headerTitle, form: FormComponent, formProps } = props

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const from = params.get(fromSignupKey)

  const isSignUp = from === fromSignupValue && !location.pathname.includes('clients')

  return (
    <div className='user-form'>
      <div className='user-form-container'>
        {isSignUp
          ? (
            <div className='user-form__signup-message'>
              <SuccessIcon />
              <Heading as='h2'> Ok! You can now login to Elen </Heading>
              <p>
                You have successfully signed up.
                You can log in now and activate your trial period.
              </p>
              <PageDivider />
            </div>
            )
          : (
            <Heading as='h2'>
              {headerTitle}
            </Heading>
            )}
        <FormComponent {...formProps} />
      </div>
    </div>
  )
}

UserFormPage.defaultProps = {
  formProps: {}
}

UserFormPage.propTypes = {
  headerTitle: T.string.isRequired,
  form: T.oneOfType([T.node, T.func]).isRequired,
  formProps: T.object
}

export default UserFormPage
