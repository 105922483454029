import { Fragment } from 'react'
import T from 'prop-types'
import pluralize from 'pluralize'

import { Badge } from '../../misc'

const formatDesc = (num, entity) =>
  `${num} ${pluralize(entity, num)}`

const ImportJobStats = ({ additions, changes, warnings, errors }) => {
  if (!additions && !changes && !warnings && !errors) {
    return 'No updates found'
  }

  const additionsDesc = !!additions && formatDesc(additions, 'addition')
  const changesDesc = !!changes && formatDesc(changes, 'change')
  const warningsDesc = !!warnings && formatDesc(warnings, 'warning')

  const errorsDesc = !!errors && (
    <Badge color={Badge.Colors.RED}>
      {formatDesc(errors, 'error')}
    </Badge>
  )

  const descToRender = [
    additionsDesc,
    changesDesc,
    warningsDesc,
    errorsDesc
  ].filter(Boolean)

  return (
    <>
      {descToRender.map((part, i) => (
        <Fragment key={i}>
          {i > 0 && <>&nbsp;&nbsp;|&nbsp;&nbsp;</>}
          {part}
        </Fragment>
      ))}
    </>
  )
}

ImportJobStats.propTypes = {
  additions: T.number.isRequired,
  changes: T.number.isRequired,
  errors: T.number.isRequired
}

export default ImportJobStats
