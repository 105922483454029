import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import T from 'prop-types'

import constants from '@elenfs/elen-constants'

import { Table, Modal, Text } from '../../../../misc'
import {
  formatMoney,
  isFrequencySingle,
  isContribution as isTransactionContribution,
  getTransactionHeaders
} from '../../../../../helpers/policy'
import { formatDate } from '../../../../../helpers/date'
import { PolicyTransactionDetailsModal } from '../../PolicyTransactionDetailsModal'
import { PencilIcon } from '../../../../icons'

const {
  PolicyHoldersLabels,
  ContributionMethodsLabels,
  WithdrawalMethodsLabels,
  ContributorsLabels,
  ContributionFrequenciesLabels,
  WithdrawalFrequenciesLabels
} = constants.policy.common

const formatTransactionDate = (policyTransaction) => {
  const { frequency, startDate, endDate } = policyTransaction
  const isSingleDate = isFrequencySingle(frequency)

  return isSingleDate
    ? formatDate(startDate)
    : (
      <>
        {formatDate(startDate)}
        {
          endDate
            ? (
              <Text
                italic
                color={Text.Colors.GREY}
                style={{ margin: '0 10px' }}
              >
                until
              </Text>
              )
            : null
        }
        {formatDate(endDate)}
      </>
      )
}

const EditButton = (props) => (
  <PencilIcon {...props} />
)

const PolicyTransactionsTableRow = ({
  policyTransaction,
  policyTransactionType,
  readOnly
}) => {
  const { contributor, paidTo, method, frequency, amount, units } = policyTransaction

  const isContribution = isTransactionContribution(policyTransactionType)

  const { policyId } = useParams()

  return (
    <Table.Row>
      <Table.Cell
        data-label={
          isContribution
            ? getTransactionHeaders(policyTransactionType).CONTRIBUTOR
            : getTransactionHeaders(policyTransactionType).PAID_TO
        }
      >
        {isContribution ? ContributorsLabels[contributor] : PolicyHoldersLabels[paidTo]}
      </Table.Cell>

      <Table.Cell data-label={getTransactionHeaders(policyTransactionType).METHOD}>
        {isContribution ? ContributionMethodsLabels[method] : WithdrawalMethodsLabels[method]}
      </Table.Cell>

      <Table.Cell
        data-label={
          isContribution
            ? getTransactionHeaders(policyTransactionType).FREQUENCY
            : getTransactionHeaders(policyTransactionType).FREQUENCY
        }
      >
        {isContribution ? ContributionFrequenciesLabels[frequency] : WithdrawalFrequenciesLabels[frequency]}
      </Table.Cell>

      <Table.Cell data-label={getTransactionHeaders(policyTransactionType).DATE}>
        {formatTransactionDate(policyTransaction)}
      </Table.Cell>

      <Table.Cell data-label={getTransactionHeaders(policyTransactionType).AMOUNT}>
        {formatMoney(amount)}
      </Table.Cell>

      <Table.Cell data-label={getTransactionHeaders(policyTransactionType).UNITS}>
        {units}
      </Table.Cell>

      <Table.Cell data-label={getTransactionHeaders(policyTransactionType).TOTAL_AMOUNT}>
        {formatMoney({ ...amount, amount: units * amount.amount })}
      </Table.Cell>

      <Table.Cell data-label={getTransactionHeaders(policyTransactionType).EMPTY}>
        <Table.Actions>
          {
            !readOnly &&
              <Modal.Trigger
                className='table-responsive__action--navigate'
                as={EditButton}
                modal={PolicyTransactionDetailsModal}
                modalProps={{ policyTransaction, policyTransactionType, policyId }}
              />
          }
        </Table.Actions>
      </Table.Cell>
    </Table.Row>
  )
}

PolicyTransactionsTableRow.propTypes = {
  policyTransaction: T.object.isRequired,
  policyTransactionType: T.string.isRequired
}

export { PolicyTransactionsTableRow }
