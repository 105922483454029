import DebtPolicyForm from './DebtPolicyForm'
import policyFormContainer from '../../PolicyFormContainer'
import createDebtPolicyGql from '../../../../graphql/mutations/policy/createDebtPolicy.graphql'
import updateDebtPolicyGql from '../../../../graphql/mutations/policy/updateDebtPolicy.graphql'

const DebtPolicyFormContainer = policyFormContainer({
  createPolicyGql: createDebtPolicyGql,
  updatePolicyGql: updateDebtPolicyGql
})(DebtPolicyForm)

export default DebtPolicyFormContainer
