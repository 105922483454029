import { useCallback } from 'react'
import { compose } from 'ramda'
import { withFormik, connect } from 'formik'
import { ActivityLogEntryTypes } from '@elenfs/elen-constants'

import Button from '../../misc/Button'
import { ActivityLogEntryTypesLabels } from '../../../helpers/enumLabels/auditLogEntry'
import { buildInitialValues } from '../../../helpers/form'
import { combineDateAndTime } from '../../../helpers/date'
import FormErrorMessages from '../../form/FormErrorMessages'
import auditLogEntrySchema from '../../../schemas/auditLogEntry/auditLogEntrySchema'

import { Form } from '../../form/Form'
import { SelectField, TextAreaField, DateField, InputField } from '../../form/fields'
import LoadingMessage from '../../misc/LoadingMessage'
import { TaskModal } from './TaskModal'
import { ControlWithActivity, ControlWithUserIcon } from '../../misc/DropdownSelection/DropdownCustom'
import { buildSelectOptions } from '../../form/fields/SelectField'
import { parseGraphqlErrors } from '../../../helpers/error'
import { RemoveAuditLogEntryButton } from '../RemoveAuditLogEntryButton'

import './AuditLogEntryForm.less'

const { AUDIT_TRAIL, ...ActivityLogEntryCreateTypes } = ActivityLogEntryTypes

const AuditLogEntryForm = (props) => {
  const {
    loading,
    errors: validationErrors,
    resetForm,
    formik: { submitForm, validateForm, setErrors },
    handleSave,
    auditLogEntry,
    refetchLogEntries,
    isGeneratedByEmail,
    removeAuditLogEntryCallback,
    currentUser
  } = props
  const { id, taskId, client, clientId } = auditLogEntry
  const isEdit = !!id
  const canDelete = isEdit && currentUser?.isAdmin

  const validateAndSubmit = useCallback(
    async (fn) => {
      const errors = await validateForm()
      if (Object.keys(errors).length) {
        return
      }

      try {
        await submitForm()
        fn && fn()
      } catch (error) { }
    }, [validateForm, submitForm])

  const save = useCallback(
    () => validateAndSubmit(handleSave),
    [validateAndSubmit, handleSave]
  )

  const saveAndAddNew = useCallback(
    async () => {
      await validateAndSubmit(resetForm)
    }, [validateAndSubmit, resetForm])

  return (
    <>
      {
        taskId && <TaskModal taskId={taskId} refetchLogEntries={refetchLogEntries} />
      }

      {
        isGeneratedByEmail && (
          <div className='audit-log-entry-form__email-message'>
            This was automatically created by an email.
          </div>
        )
      }

      <Form className='audit-log-entry-form'>
        <Form.Group className='audit-log-entry-form__fields'>
          <SelectField
            name='clientId'
            optionsToResolve={[client?.id, clientId].filter(Boolean)}
            inputProps={{
              async: true,
              placeholder: '+ client',
              asyncProps: {
                labelProp: 'fullName',
                valueProp: 'id'
              }
            }}
            selectProps={{ Control: ControlWithUserIcon }}
          />

          <SelectField
            name='type'
            options={buildSelectOptions(ActivityLogEntryCreateTypes, ActivityLogEntryTypesLabels)}
            inputProps={{ isSearchable: false, placeholder: '+ activity type' }}
            selectProps={{ Control: ControlWithActivity }}
          />

          <DateField
            name='date'
            withIconLayout
            inputProps={{
              placeholder: '+ date'
            }}
            flatpickrOptions={{
              maxDate: new Date()
            }}
          />

          <DateField
            name='time'
            inputProps={{
              placeholder: '+ time'
            }}
            isTimeInput
            withIconLayout
          />

        </Form.Group>

        <Form.Divider />

        <InputField
          name='summary'
          label='Summary'
          inputProps={{ placeholder: 'Start typing...' }}
        />

        <TextAreaField
          name='notes'
          label='Notes'
          inputProps={{
            placeholder: 'Start typing...',
            rows: 4
          }}
        />

        <FormErrorMessages validationErrors={validationErrors} />

        {loading && <LoadingMessage text='Saving...' />}

        <Form.ButtonGroup className='audit-log-entry-form__buttons-group'>
          <div>
            <Button type='button' color={Button.Colors.BLUE} onClick={save}>
              Done
            </Button>

            {!isEdit && (
              <Button type='button' color={Button.Colors.GREEN} onClick={saveAndAddNew}>
                Save and Add New
              </Button>
            )}
          </div>

          <div>
            {canDelete &&
              <RemoveAuditLogEntryButton {...{
                removeAuditLogEntryCallback,
                setErrors
              }}
              />}
          </div>
        </Form.ButtonGroup>
      </Form>
    </>
  )
}

const AuditLogEntryFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: auditLogEntrySchema,

    mapPropsToValues: ({ auditLogEntry }) => {
      const now = new Date()
      const formData = { ...auditLogEntry }
      if (!formData || !formData.id) {
        formData.date = now
        formData.time = now
      } else {
        formData.clientId = auditLogEntry.client.id
        formData.time = auditLogEntry.date
      }
      return Object.assign(buildInitialValues(auditLogEntrySchema), formData)
    },

    handleSubmit: async (values, { props, setErrors }) => {
      const { mutate } = props
      values = auditLogEntrySchema.cast(values)
      const { date, time, ...restValues } = values
      values = {
        ...restValues,
        id: props.auditLogEntry.id,
        date: combineDateAndTime(date, time).toISOString()
      }

      if (!values.notes) delete values.notes

      const result = await mutate({ variables: { input: values } })

      if (result.graphQLErrors) {
        setErrors(parseGraphqlErrors(
          result,
          'There was an error saving the activity log entry'
        ))
        throw new Error('Failed request')
      }
    }
  }),
  connect
)(AuditLogEntryForm)

export { AuditLogEntryFormEnhanced as AuditLogEntryForm }
