import * as yup from 'yup'

import { stringSchema } from '../helpers'

const taskCommentSchema = yup.object().shape({
  textContent: stringSchema().label('Comment'),
  inputName: stringSchema().label('inputName')
})

export { taskCommentSchema }
