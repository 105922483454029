import classnames from 'classnames'
import T from 'prop-types'

import { CheckboxIcon } from '../../icons'

import './Checkbox.less'

const Checkbox = ({ inputId, checked, onChange, readOnly, disabled, className, onClick, ...restProps }) => (
  <div className={classnames('styled-checkbox', className)} {...restProps}>
    <input
      id={inputId}
      type='checkbox'
      className='styled-checkbox__input'
      checked={checked}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      onClick={onClick}
    />
    <label htmlFor={inputId}>
      <span>
        <CheckboxIcon.Checked className='styled-checkbox__checked-icon' />
        <CheckboxIcon.Unchecked className='styled-checkbox__unchecked-icon' />
        <CheckboxIcon.DisabledUnchecked className='styled-checkbox__disabled-unchecked-icon' />
        <CheckboxIcon.DisabledChecked className='styled-checkbox__disabled-checked-icon' />
      </span>
    </label>
  </div>
)

Checkbox.propTypes = {
  /**
   * Id for the input.
   */
  inputId: T.string.isRequired,
  /**
   * Is the checkbox checked or not.
   */
  checked: T.bool,
  /**
   * On change handler for the input.
   */
  onChange: T.func,
  /**
   * Is the checkbox read only.
   */
  readOnly: T.bool,
  /**
   * Is the checkbox disabled.
   */
  disabled: T.bool,
  /**
   * Wrapper div classname.
   */
  className: T.string,
  /**
   * On click handler for the input.
   */
  onClick: T.func
}

export { Checkbox }
