import { useState, useEffect, useCallback } from 'react'

const newTaskInitialValues = { subtasks: [] }

export const useTasksModal = ({ tasks = [] } = {}) => {
  const [selectedTask, setSelectedTask] = useState()
  const [prevTask, setPrevTask] = useState()
  const [newTask, setNewTask] = useState()
  const [selectedTaskIndex, setSelectedTaskIndex] = useState()

  const isModalOpen = !!(selectedTask || newTask)
  const isSubtask = !selectedTask?.subtasks

  const onTaskClick = useCallback((task, index) => {
    const isSubtask = !task?.subtasks
    if (!isSubtask) setPrevTask(task)

    setSelectedTaskIndex(index)
    setSelectedTask(task)
  }, [])

  const openNewTaskModal = useCallback(
    (input) => {
      setNewTask({ ...input, ...newTaskInitialValues })
      setSelectedTask({ ...input, ...newTaskInitialValues })
    }, [setNewTask, setSelectedTask, newTaskInitialValues])

  const onAddSubtaskToNewTask = useCallback(
    (subtask, values) => {
      const addSubtask = current => ({
        ...values,
        subtasks: [...current.subtasks, subtask]
      })
      setNewTask(addSubtask)
      setSelectedTask(addSubtask)
    }, [setNewTask, setSelectedTask])

  const onUpdateSubtaskOfNewTask = useCallback(
    (updatedSubtask) => {
      let updatedNewTask = {}
      setNewTask(current => {
        current.subtasks[selectedTaskIndex] = updatedSubtask
        updatedNewTask = { ...current }
        return updatedNewTask
      })
      setSelectedTask(updatedNewTask)
    }, [setNewTask, setSelectedTask, selectedTaskIndex])

  const onParentTaskClick = useCallback(
    taskId => {
      const task = tasks.find(({ id }) => taskId === id)
      if (task) {
        setSelectedTask(task)
        setPrevTask(task)
      }
    }, [setSelectedTask, setPrevTask, tasks])

  const onCloseModal = useCallback(({ isSaveAndNew } = {}) => {
    if (newTask) {
      if (isSubtask) {
        setSelectedTask(newTask)
      } else {
        setSelectedTask(null)
        setNewTask(null)
        if (isSaveAndNew) openNewTaskModal()
      }
    } else {
      if (prevTask) {
        setPrevTask(isSubtask ? prevTask : null)
        setSelectedTask(isSubtask ? prevTask : null)
      } else {
        setPrevTask(null)
        setSelectedTask(null)
      }
    }
  }, [newTask, isSubtask, prevTask])

  useEffect(() => {
    if (!newTask && selectedTask) {
      const task = tasks?.find(({ id }) => selectedTask.id === id)
      setSelectedTask(current => task || current)
    }
  }, [tasks, newTask, selectedTask, setSelectedTask])

  const onRemoveSubtaskOfNewTask = useCallback(
    () => {
      let updatedNewTask = {}
      setNewTask(current => {
        current.subtasks.splice(selectedTaskIndex, 1)
        updatedNewTask = { ...current }
        return updatedNewTask
      })
      setSelectedTask(updatedNewTask)
    }, [setNewTask, setSelectedTask, selectedTaskIndex])

  return {
    isOpen: isModalOpen,
    isModalOpen,
    newTask,
    task: selectedTask || newTask,
    onTaskClick,
    isSubtask,
    prevTask,
    close: onCloseModal,
    onAddSubtaskToNewTask,
    onUpdateSubtaskOfNewTask,
    onParentTaskClick,
    openNewTaskModal,
    onRemoveSubtaskOfNewTask
  }
}
