import { Routes, Route, Navigate } from 'react-router-dom'

import { PolicyTransactionsTab } from '../PolicyTransactionsTab'
import { Charges } from '../../charge'
import DocumentsPage from './../../clientDocument/DocumentsPage'
import { PolicyValuation } from '../PolicyValuation'

const PolicySwitch = ({
  policy,
  search,
  clientId,
  isInCreatePath,
  isClientPolicy,
  showValuationRoute,
  hasReconciledCharges,
  PolicyCategoryDetails,
  PolicyForm,
  currentClient
}) => {
  return (
    <Routes>
      {isInCreatePath && (
        <Route
          path='/'
          element={<PolicyForm
            clientId={clientId}
            policy={policy}
            currentClient={currentClient}
                   />}
        />
      )}
      <Route
        path='details/edit'
        element={PolicyForm && <PolicyForm
          policy={policy}
          currentClient={currentClient}
          hasReconciledCharges={hasReconciledCharges}
                               />}
      />
      <Route
        path='details'
        element={PolicyCategoryDetails && <PolicyCategoryDetails
          policy={policy}
          readOnly={isClientPolicy}
          headingText='Details'
                                          />}
      />
      {showValuationRoute && (
        <Route
          path='valuation'
          element={<PolicyValuation
            policy={policy}
            readOnly={isClientPolicy}
                   />}
        />
      )}
      <Route
        path='documents'
        element={<DocumentsPage {... {
          policyId: policy.id,
          isClientPortal: isClientPolicy,
          clientId
        }}
                 />}
      />
      <Route
        path='contributions-and-withdrawals'
        element={<PolicyTransactionsTab
          policy={policy}
          isClientPolicy={isClientPolicy}
                 />}
      />
      <Route
        path='fees-and-commissions'
        element={<Charges
          policy={policy}
          isPolicyCharges
                 />}
      />
      {/* // TODO: this is a hack too... routes should be changed */}
      <Route
        path='/' element={!isInCreatePath &&
          <Navigate
            replace to={{
              pathname: 'details',
              search
            }}
          />}
      />
    </Routes>
  )
}

export { PolicySwitch }
