import qs from 'query-string'

import constants from '@elenfs/elen-constants'

import { ClientRelationshipLabels } from './../../../helpers/enumLabels/client'
import {
  DebtPolicyForm,
  InvestmentPolicyForm,
  MortgagePolicyForm,
  PensionPolicyForm,
  ProtectionPolicyForm,
  // TODO: PolicyDetails components should be split into their own submodule,
  // separate from policy forms.
  DebtPolicyDetails,
  InvestmentPolicyDetails,
  MortgagePolicyDetails,
  PensionPolicyDetails,
  ProtectionPolicyDetails
} from '../PolicyForms'

const { PolicyCategories } = constants.policy.common

const formatPolicyHolders = ({ policy, currentClient }) => {
  const { currentHolders } = policy

  const currentClientRelationships = currentHolders &&
    currentHolders.find(({ id }) => id === currentClient?.id)?.relationships

  const holders = currentHolders && currentHolders.map(({ fullName, id }) => {
    const relationship = currentClientRelationships?.find(
      ({ relatedClientId }) => relatedClientId === id
    )

    return relationship
      ? { id, fullName, relationship: ClientRelationshipLabels[relationship.type] }
      : { id, fullName }
  })

  const sortedHolders = holders?.reduce((acc, holder) => {
    if (holder.id === currentClient?.id) {
      return [holder, ...acc]
    }
    return [...acc, holder]
  }, [])

  return { ...policy, currentHolders: sortedHolders }
}

export { formatPolicyHolders }

export const buildTabs = options => [
  {
    label: 'Policy Details',
    path: 'details'
  },
  options?.showValuationRoute && {
    label: 'Valuation',
    path: 'valuation'
  },
  !options?.isClientPolicy && {
    label: 'Policy Documents',
    path: 'documents'
  },
  {
    label: 'Contributions and Withdrawals',
    path: 'contributions-and-withdrawals'
  },
  !options?.isClientPolicy && {
    label: 'Fees and Commissions',
    path: 'fees-and-commissions'
  }
].filter(Boolean)

export const extractCategory = (searchStr) => {
  const { category } = qs.parse(searchStr)
  return category
}

export const PolicyDetails = Object.freeze({
  [PolicyCategories.DEBTS]: DebtPolicyDetails,
  [PolicyCategories.INVESTMENT]: InvestmentPolicyDetails,
  [PolicyCategories.MORTGAGES]: MortgagePolicyDetails,
  [PolicyCategories.PENSIONS]: PensionPolicyDetails,
  [PolicyCategories.PROTECTION]: ProtectionPolicyDetails
})

export const PolicyForms = Object.freeze({
  [PolicyCategories.DEBTS]: DebtPolicyForm,
  [PolicyCategories.INVESTMENT]: InvestmentPolicyForm,
  [PolicyCategories.MORTGAGES]: MortgagePolicyForm,
  [PolicyCategories.PENSIONS]: PensionPolicyForm,
  [PolicyCategories.PROTECTION]: ProtectionPolicyForm
})
