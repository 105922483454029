import T from 'prop-types'

import { WorkflowActionTypes } from '@elenfs/elen-constants'

import { TasksIcon } from '../../../icons'

const ActionTypeIcon = ({ actionType }) => {
  if (actionType === WorkflowActionTypes.TASK) return <TasksIcon />

  return <TasksIcon />
}

ActionTypeIcon.propTypes = {
  actionType: T.oneOf(Object.values(WorkflowActionTypes)).isRequired
}

export { ActionTypeIcon }
