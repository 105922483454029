import { useCallback } from 'react'
import T from 'prop-types'
import { withFormik } from 'formik'
import classnames from 'classnames'

import { Form } from '../../../../form/Form'
import { TextAreaField } from '../../../../form/fields'
import Stapler from './../../../../icons/Stapler'
import { withIcon } from '../../../../form/inputs/WithIcon'
import { AttachmentList } from './AttachmentList'
import { FormDivider } from './../../../../form/Form/FormDivider/FormDivider'
import { FileInputIcon } from '../../../../form/inputs/FileInputIcon'
import { Button, ErrorMessage } from './../../../../misc'

import './MessageInput.less'

const textareaFieldClass =
  'message-input__form__container__wrapper__textarea-field'

const MessageInput = ({
  toUserName,
  values,
  handleSubmit,
  attachments,
  onFilesSelect,
  isMessageSubmitting,
  isMessageError,
  isFilesCreateError,
  isClientUser,
  onRemoveAttachment,
  isCollapsed,
  setIsCollapsed,
  fileRemoveLoading,
  uploadFile,
  className
}) => {
  const { message } = values

  const uploadInProgress = attachments.some(({ isLoading }) => isLoading)
  const uploadInError = attachments.some(({ isError }) => isError)

  const attachmentsNotReady = uploadInProgress || uploadInError
  const disableButton =
    isMessageSubmitting || attachmentsNotReady || !(message && message.length)

  const isGeneralError = isFilesCreateError || isMessageError

  const FileUploadIcon = useCallback(
    (props) => (
      <FileInputIcon
        icon={Stapler}
        disabled={uploadInProgress}
        multiple
        onChange={onFilesSelect}
        {...props}
      />
    ),
    [onFilesSelect, uploadInProgress]
  )

  const onSubmit = async e => {
    e.preventDefault()
    await handleSubmit()
    setIsCollapsed(true)
  }

  return isClientUser
    ? (
      <>
        <div
          className={classnames(
            'message-input-dark-overlay',
            !isCollapsed && 'message-input-dark-overlay--shown'
          )}
          onClick={() => setIsCollapsed(true)}
        />
        <div
          className={classnames(
            'message-input',
            className,
            { 'message-input--opened': !isCollapsed }
          )}
        >
          <Form className='message-input__form' onSubmit={onSubmit}>
            <Form.Group className='message-input__form__container'>
              <div className='message-input__form__container--holder'>
                <div className='message-input__form__container__wrapper'>
                  <TextAreaField
                    name='message'
                    className={classnames(
                      textareaFieldClass,
                      !isCollapsed && `${textareaFieldClass}--expanded`
                    )}
                    inputProps={{
                      placeholder: toUserName
                        ? `Message ${toUserName} ...`
                        : 'Compose new message...',
                      rows: 1,
                      maxLength: 5000,
                      onFocus: () => setIsCollapsed(false),
                      onBlur: () => !message?.length && setIsCollapsed(true),
                      icon: FileUploadIcon,
                      iconPosition: withIcon.IconPositions.RIGHT
                    }}
                  />

                  <FormDivider className='message-input__form__container__wrapper__divider' />

                  <AttachmentList
                    attachments={attachments}
                    onRemoveAttachment={onRemoveAttachment}
                    fileRemoveLoading={fileRemoveLoading}
                    uploadFile={uploadFile}
                  />
                </div>
                <div className='message-input__form__container__button-and-error'>
                  <Button
                    type='submit'
                    color={Button.Colors.BLUE}
                    className={classnames(
                      'message-input__form__container__button-and-error__button',
                      isCollapsed &&
                      'message-input__form__container__button-and-error__button--hidden'
                    )}
                    disabled={disableButton}
                  >
                    Send
                  </Button>
                </div>
              </div>
              {isGeneralError && (
                <ErrorMessage
                  className='message-input__form__container__button-and-error__error'
                  text={`${isMessageError
                    ? 'Failed to send the message'
                    : 'Something went wrong'
                  }. Please, try again.`}
                />
              )}
            </Form.Group>
          </Form>
        </div>
      </>
      )
    : null
}

const EnhancedMessageInput = withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: async (
    { message },
    { props: { onMessageSubmit }, resetForm }
  ) => {
    try {
      await onMessageSubmit(message)
      resetForm()
    } catch (error) { }
  }
})(MessageInput)

EnhancedMessageInput.defaultProps = {
  attachments: [],
  disableAttachments: false
}

EnhancedMessageInput.propTypes = {
  /**
   * Name of the user to whom the message is sent.
   * E.g. 'John Johnson' or 'Company Name'
   */
  toUserName: T.string,
  /**
   * The list of attachments.
   */
  attachments: T.arrayOf(T.shape({ fileName: T.string.isRequired })),
  /**
   * Flag that signifies that some files are being uploaded.
   */
  isUploadingFiles: T.bool,
  /**
   * Callback called when a new document is attached.
   */
  onFilesSelect: T.func,
  /**
   * Callback to submit a message.
   */
  onMessageSubmit: T.func.isRequired,
  /**
   * Flag indicating that a message is submitting
   */
  isMessageSubmitting: T.bool.isRequired,
  /**
   * Flag indicating that there occurred an error while submitting the message.
   */
  isMessageError: T.bool.isRequired,
  /**
   * Flag indicating that there occurred an error while create files mutation.
   */
  isFilesCreateError: T.bool.isRequired,
  /**
   * Callback to upload file.
   */
  uploadFile: T.func.isRequired,
  /**
   * Callback to remove file.
   */
  onRemoveAttachment: T.func.isRequired
}

export { EnhancedMessageInput as MessageInput }
