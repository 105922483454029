import { useRef } from 'react'
import 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2'
import { CollapsableContainer } from '../../misc'
import { formatMoney } from './../../../helpers/policy'
import { buildLegend, getTotal } from './../../../helpers/charts'
import { options } from './helpers'
import { useCurrentUser } from '../../../hooks'

// Currently working with only one data set
const DoughnutTab = ({ data, isEmpty }) => {
  const ref = useRef()
  const { currency } = useCurrentUser()

  const legend = buildLegend(data)

  const total = formatMoney({ amount: isEmpty ? 0 : getTotal(data), currency })

  return (
    <div className='doughnut-tab'>
      <CollapsableContainer
        title='Products'
        summary={total}
        isClosedOnMobile
        isSmallBreakPoint
      >
        <div className='doughnut-tab__chart-container'>
          <div className='doughnut-tab__chart-container__total'>{total}</div>
          <Doughnut ref={ref} data={data} width={244} height={244} options={options} />
        </div>

        <div className='doughnut-tab__legend'>
          <h3> Products </h3>
          <div className='doughnut-tab__legend__items'>
            {legend.map(({ label, color, amount }) => (
              <div className='doughnut-tab__legend__items__item' key={label}>
                <div
                  className='doughnut-tab__legend__items__item__color'
                  style={{ backgroundColor: color }}
                />
                <div className='doughnut-tab__legend__items__item__label-and-money'>
                  <div className='doughnut-tab__legend__items__item__label-and-money__label'>
                    {label}
                  </div>
                  <div className='doughnut-tab__legend__items__item__label-and-money__money'>
                    {formatMoney({ amount, currency })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CollapsableContainer>
    </div>
  )
}

export { DoughnutTab }
