import { isEmpty } from 'ramda'

import { FormFieldsCard, FormErrorMessages } from '../../form'
import { Form } from '../../form/Form'
import { DateField, RadioGroupField, SelectField } from '../../form/fields'
import { LoadingMessage, Card } from '../../misc'
import { buildBooleanSelectBoxOptions } from '../../../helpers/form'
import { ControlWithColors, OptionWithColors } from '../../misc/DropdownSelection/DropdownCustom'

const ClientComplianceDetailsForm = ({ loading, errors, serviceLevelsOptions }) => {
  return (
    <Card>
      <FormFieldsCard title='Details' className='compliance-form__details'>
        <Form.Section>
          <Form.Section>
            <Form.SectionInputsBlock>
              <SelectField
                name='serviceLevelId'
                label='Service level'
                options={serviceLevelsOptions}
                inputProps={{ isClearable: true }}
                selectProps={{
                  Control: ControlWithColors,
                  Option: OptionWithColors
                }}
              />
            </Form.SectionInputsBlock>
          </Form.Section>
          <Form.SectionInputsBlock>
            <DateField
              name='clientSince'
              label='Client since'
            />
            <DateField
              name='clientUntil'
              label='Client until'
            />
          </Form.SectionInputsBlock>
        </Form.Section>
        <Form.Section>
          <Form.SectionInputsBlock>
            <DateField
              name='nextReviewAt'
              label='Next review date'
            />
          </Form.SectionInputsBlock>
        </Form.Section>
        <Form.Section>
          <Form.SectionInputsBlock>
            <DateField
              name='termsOfBusinessSignedAt'
              label='Last terms of business signed'
            />
            <DateField
              name='feeAgreementSignedAt'
              label='Last fee agreement signed'
            />
          </Form.SectionInputsBlock>
          <Form.SectionInputsBlock>
            <RadioGroupField
              isTextButton
              options={buildBooleanSelectBoxOptions()}
              name='vulnerable'
              groupLabel='Vulnerable'
              inputId='is-vulnerable-checkbox'
            />
          </Form.SectionInputsBlock>
        </Form.Section>

        {loading && <LoadingMessage />}
        {!isEmpty(errors) && <FormErrorMessages validationErrors={errors} />}
      </FormFieldsCard>
    </Card>
  )
}

ClientComplianceDetailsForm.defaultProps = {
  serviceLevelsOptions: []
}

export { ClientComplianceDetailsForm }
