import { matchPath } from 'react-router-dom'

export const pathMatchCurrentLocation = ({
  pathToCheck,
  pathname,
  end,
  caseSensitive
}) => {
  return matchPath(
    {
      path: pathToCheck,
      end: end || true, // Optional, `true` == pattern should match the entire URL pathname
      caseSensitive: caseSensitive || false // Optional, `true` == static parts of `path` should match case
    },
    pathname
  )
}
