import classnames from 'classnames'
import T from 'prop-types'

import './WorkflowInstancesProgress.less'

const WorkflowInstancesProgress = ({ workflowInstances, onInstanceClick }) => {
  const isEmpty = !workflowInstances.length

  return (
    <div
      className={classnames('workflow-instances-progress', {
        'workflow-instances-progress--empty': isEmpty
      })}
    >
      {isEmpty && (
        <div className='workflow-instances-progress--empty__text'>
          <i> No running workflows </i>
        </div>
      )}

      {workflowInstances.map(({ id, name, progress }) => (
        <div
          key={id}
          className='workflow-instances-progress__instance'
          onClick={() => onInstanceClick(id)}
        >
          <div className='workflow-instances-progress__instance__name'>
            {name}
          </div>
          <div className='workflow-instances-progress__instance__progress-container'>
            <div
              className='workflow-instances-progress__instance__progress-container__progress'
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

WorkflowInstancesProgress.defaultProps = {
  workflowInstances: [],
  onInstanceClick: () => {}
}

WorkflowInstancesProgress.propTypes = {
  workflowInstances: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      name: T.string.isRequired,
      progress: T.number.isRequired
    })
  ),
  onInstanceClick: T.func
}

export { WorkflowInstancesProgress }
