const BranchArrows = props => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M16 16V0H0v16z' />
      <path
        d='M13.309 12.954a.547.547 0 010 .774l-1.112 1.112a.547.547 0 01-.774 0l-1.08-1.08a.547.547 0 01.774-.774l.146.147v-.935a4.167 4.167 0 00-1.228-2.966L9 8.2A5.259 5.259 0 018 6.802c-.256.517-.594.99-1.002 1.397L5.964 9.233a4.167 4.167 0 00-1.228 2.965v.935l.146-.147a.547.547 0 01.774.774l-1.08 1.08a.547.547 0 01-.774 0L2.69 13.728a.547.547 0 01.774-.774l.178.179v-.934c0-1.403.557-2.748 1.549-3.74l1.033-1.033A4.167 4.167 0 007.453 4.46V1.547a.547.547 0 011.093 0V4.46a4.167 4.167 0 001.229 2.966l1.033 1.033a5.288 5.288 0 011.549 3.74v.934l.178-.179a.547.547 0 01.774 0z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { BranchArrows }
