import { join, pick, values, pipe, filter } from 'ramda'

import {
  EmploymentStatusLabels,
  ClientTaxRatesLabels,
  BooleanLabels,
  GenderLabels,
  MaritalStatusLabels,
  ClientRelationshipLabels
} from '../../../helpers/enumLabels'
import { formatMoney } from '../../../helpers/policy'
import { formatDate, calculateAge } from '../../../helpers/date'

import {
  MobilePhoneIcon,
  EnvelopeIcon,
  HouseIcon,
  PinIcon,
  SuitCaseIcon
} from '../../icons'

export const buildClientRelationships = ({ client, isClientPortal }) => {
  const { relationships } = client
  if (!relationships) return []

  return relationships.map(({ relatedClient, type, relatedClientId }) => {
    const relationship = {
      relation: ClientRelationshipLabels[type],
      fullName: relatedClient?.fullName
    }
    if (isClientPortal) {
      relationship.disabled = true
    } else {
      relationship.to = `/secure/clients/${relatedClientId}`
    }

    return relationship
  })
}

export const buildClientPersonalAndContacts = client => ({
  title: client.title,
  fullName: pipe(
    pick(['firstName', 'middleName', 'lastName']),
    values,
    filter(Boolean),
    join(' ')
  )(client),
  gender: GenderLabels[client.gender],
  personalSummaries: [
    { label: 'Known as:', value: client.knownAs },
    { label: 'Date of birth:', value: formatDate(client.dateOfBirth) },
    { label: 'Age:', value: calculateAge(client.dateOfBirth) },
    { label: 'Place of birth:', value: client.placeOfBirth },
    {
      label: 'Marital status:',
      value: MaritalStatusLabels[client.maritalStatus]
    }
  ],
  contactsSummaries: [
    {
      label: <PinIcon />,
      value: (
        <>
          <div className='client-personal-and-contacts-card__low-line-height'>
            {client.address1}
          </div>
          <div className='client-personal-and-contacts-card__low-line-height'>
            {client.address2}
          </div>
          <div className='client-personal-and-contacts-card__low-line-height'>
            {client.city}
          </div>
          <div className='client-personal-and-contacts-card__low-line-height'>
            {client.postCode}
          </div>
        </>
      )
    },
    { label: <EnvelopeIcon />, value: client.email },
    { label: <MobilePhoneIcon />, value: client.mobile },
    { label: <HouseIcon />, value: client.homeTel },
    { label: <SuitCaseIcon />, value: client.workTel }
  ]
})

export const buildClientEmployment = client => ({
  ukDomiciled: client.ukDomiciled,
  ukResidentForTax: client.ukResidentForTax,
  niNumber: client.niNumber,
  summaries: [
    [
      {
        label: 'Employment status',
        value: EmploymentStatusLabels[client.employmentStatus]
      },
      { label: 'Occupation', value: client.occupation },
      { label: 'Employer', value: client.employer },
      {
        label: 'Annual gross salary',
        value: formatMoney(client.annualGrossSalary)
      },
      { label: 'Time employed', value: client.timeEmployed },
      {
        label: 'Highest rate of tax paid',
        value: ClientTaxRatesLabels[client.highestTaxRatePaid]
      },
      { label: 'Desired retirement age', value: client.desiredRetirementAge },
      {
        label: 'Child benefit reduced',
        value: BooleanLabels[client.childBenefitReduced]
      }
    ],
    [{ label: 'Additional notes', value: client.employmentNotesAndComments }]
  ]
})
