import { GeneralErrorPage } from '../../misc'
import { DocumentNotFoundIcon } from '../../icons'

const DocumentUnavailable = () => {
  const text = `We are unable to find the document to sign. It’s possible that the
  document has been removed or no longer needs to be signed or the
  provided address is incorrect.`

  const title = 'Document Unavailable'

  return (
    <GeneralErrorPage text={text} icon={DocumentNotFoundIcon} title={title} />
  )
}

export { DocumentUnavailable }
