import { DashboardBoxesCard } from '../DashboardBoxesCard'
import { DashboardCardTitle } from '../DashboardCardTitle'

import './TasksDashboardCard.less'

const TasksDashboardCard = ({ cardProps, titleProps, onCardClick }) => (
  <div className='tasks-dashboard-card'>
    <DashboardCardTitle {...titleProps}> My Tasks </DashboardCardTitle>
    <DashboardBoxesCard {...cardProps} onCardClick={onCardClick} />
  </div>
)

export { TasksDashboardCard }
