const DisabledUncheckedIcon = (props) => (
  <svg viewBox='0 0 24 24' width={20} height={20} {...props}>
    <path
      fill='#1F2027'
      fillOpacity='.04'
      fillRule='evenodd'
      stroke='#DFE3E9'
      d='M19 2.5c.6903559 0 1.3153559.27982203 1.767767.73223305C21.220178 3.68464406 21.5 4.30964406 21.5 5h0v14c0 .6903559-.279822 1.3153559-.732233 1.767767C20.3153559 21.220178 19.6903559 21.5 19 21.5h0H5c-.69035594 0-1.31535594-.279822-1.76776695-.732233C2.77982203 20.3153559 2.5 19.6903559 2.5 19h0V5c0-.69035594.27982203-1.31535594.73223305-1.76776695C3.68464406 2.77982203 4.30964406 2.5 5 2.5h0z'
    />
  </svg>
)

export default DisabledUncheckedIcon
