import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import RecoverPasswordForm from '../RecoverPasswordForm'
import UserFormPage from '../UserFormPage'
import LoggedOutLayout from '../../misc/LoggedOutLayout'

const { UserTypes } = constants.user

const RecoverPasswordPage = ({ userType }) => (
  <LoggedOutLayout>
    <UserFormPage {...{
      headerTitle: 'Set new password',
      form: RecoverPasswordForm,
      formProps: { userType }
    }}
    />
  </LoggedOutLayout>
)

RecoverPasswordPage.defaultProps = {
  userType: UserTypes.COMPANY_USER
}

RecoverPasswordPage.propTypes = {
  userType: T.oneOf(Object.values(UserTypes))
}

export default RecoverPasswordPage
