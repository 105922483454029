import { Fragment } from 'react'
import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import ImportError from './ImportError'
import PoliciesErrorHeading from './PoliciesErrorHeading'
import { buildLogErrorDescription } from '../../../../helpers/dataImport'

const { DataImportTypes } = constants.dataImport
const { InvalidFieldErrors } = constants.dataImport
const { TRANSACT, CSV_FILE, CASH_CALC, P1, PRAEMIUM } = DataImportTypes
const { DUPLICATED_VALUE } = InvalidFieldErrors

const InvalidPoliciesError = ({
  clientFirstName,
  clientLastName,
  policies,
  dataImportType
}) => {
  const heading = (
    <PoliciesErrorHeading
      clientFirstName={clientFirstName}
      clientLastName={clientLastName}
      numOfPolicies={policies.length}
    />
  )

  let description = ''

  if (dataImportType === TRANSACT) {
    description = 'Transact Server Error'
  }

  if (dataImportType === CASH_CALC) {
    description = (
      <>
        {policies.map(({ fields }, i) => (
          <Fragment key={i}>
            {buildLogErrorDescription(fields)}
          </Fragment>
        ))}
      </>
    )
  }

  if (dataImportType === P1 || dataImportType === PRAEMIUM) {
    description = (
      <>
        {policies.map(({ fields }, i) => (
          <Fragment key={i}>
            {buildLogErrorDescription(fields)}
          </Fragment>
        ))}
      </>
    )
  }

  if (dataImportType === CSV_FILE) {
    policies.forEach(({ fields }) => {
      fields.forEach(field => {
        switch (field.errorCode) {
          case DUPLICATED_VALUE:
            description = 'Policy number is duplicated'
            break
          default:
            description = 'Policy import error'
            break
        }
      })
    })
  }

  return (
    <ImportError
      heading={heading}
      description={description}
    />
  )
}

InvalidPoliciesError.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  policies: T.array.isRequired,
  dataImportType: T.oneOf(Object.values(DataImportTypes)).isRequired
}

export default InvalidPoliciesError
