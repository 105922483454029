import { useEffect } from 'react'
import T from 'prop-types'

import { Modal } from '..'
import { DynamicForm } from './DynamicForm'

import './DynamicFormModal.less'

const DynamicFormModal = props => {
  const {
    heading,
    trigger,
    isOpen,
    close,
    onOpenModal,
    formProps,
    ...restProps
  } = props

  useEffect(() => {
    if (isOpen && onOpenModal) onOpenModal()
  }, [isOpen, onOpenModal])

  return (
    <Modal {...{ isOpen, close, trigger, ...restProps }}>
      <Modal.Heading>{heading}</Modal.Heading>

      <DynamicForm {...{ ...formProps, close }} />
    </Modal>
  )
}

DynamicFormModal.defaultProps = {
  heading: 'Modal Form'
}

DynamicFormModal.propTypes = {
  heading: T.string.isRequired,
  onOpenModal: T.func,
  formProps: T.shape({
    inputsArr: T.arrayOf(
      T.arrayOf(
        T.shape({
          InputComponent: T.elementType.isRequired,
          inputProps: T.object.isRequired
        }).isRequired
      )
    ).isRequired,
    onSubmit: T.func.isRequired,
    validationSchema: T.object.isRequired,
    submitButtonLabel: T.string,
    loading: T.bool,
    error: T.oneOfType([T.string, T.object]),
    buildInitialValues: T.func
  })
}

export { DynamicFormModal }
