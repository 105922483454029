import T from 'prop-types'
import classnames from 'classnames'

import './Text.less'

/**
 * Text component. Renders a span by default,
 * but it can be configured to render whatever element is passed to it through
 * `as` prop.
 */
const Text = ({ as: Component, children, className, bold, italic, color, ...props }) => (
  <Component
    className={classnames('text', {
      'text--bold': bold,
      'text--italic': italic,
      [`text--${color}`]: color,
      [className]: className
    })}
    {...props}
  >
    {children}
  </Component>
)

Text.defaultProps = {
  as: 'span',
  bold: false,
  italic: false
}

Text.propTypes = {
  /**
   * A component that should be rendered as a 'text'
   * (ie, should look like one).
   * For example, a span.
   */
  as: T.oneOfType([T.string, T.func]),
  /**
   * bold/normal font weight the text.
   */
  bold: T.bool,
  /**
   * italic/normal font style the text.
   */
  italic: T.bool,
  /**
   * Custom classname for the text element.
   */
  className: T.string
}

export { Text }
