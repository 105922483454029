const Phone2Icon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <title>Icon/24/phone2_24</title>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='m20.058 15.286-1.811-1.484c-1.18-.967-3.011-.876-4.088.201l-.071.072a.463.463 0 0 1-.653.001l-3.499-3.5a.46.46 0 0 1 0-.651l-.01.01.01-.01c1.07-1.081 1.173-2.922.235-4.107L8.702 3.96c-.944-1.194-2.585-1.286-3.66-.21l-.27.27C2.86 5.932 2.408 9.38 3.842 11.67c0 0 .844 1.958 3.693 4.808 2.848 2.849 4.805 3.693 4.805 3.693 2.312 1.4 5.735.985 7.646-.927l.257-.257c1.077-1.077.993-2.735-.186-3.701Zm-1.115 2.4-.257.257c-1.312 1.312-3.804 1.614-5.392.653l-.108-.065-.116-.05c-.142-.061-.5-.255-1.038-.617-.958-.642-2.034-1.526-3.195-2.688-1.162-1.162-2.045-2.238-2.688-3.196-.361-.54-.555-.896-.616-1.039l-.056-.128-.074-.12c-.965-1.542-.654-4.047.67-5.371l.27-.27c.298-.3.656-.279.916.05L8.728 6.96c.362.458.315 1.255-.103 1.673-.889.9-.885 2.35.01 3.245l3.5 3.5a2.303 2.303 0 0 0 3.254-.002l.072-.071c.408-.409 1.172-.446 1.62-.08l1.81 1.485c.339.277.359.669.052.976Z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { Phone2Icon }
