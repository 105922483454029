import T from 'prop-types'

const ImportWarning = ({ heading, description }) => (
  <div className='import-job-summary__log__warning'>
    <p className='import-job-summary__log__warning__heading'>
      <span data-exclamation-mark>!</span>
      {heading}
    </p>
    <p className='import-job-summary__log__warning__desc'>
      {description}
    </p>
  </div>
)

ImportWarning.propTypes = {
  heading: T.node.isRequired,
  description: T.node
}

export default ImportWarning
