import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { MODAL_CONTAINER_ID } from './constants'

const ModalPortal = ({ children, close, blockEscapeKey }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        close()
      }
    }

    !blockEscapeKey && document.addEventListener('keydown', handleKeyDown)
    return () => !blockEscapeKey && document.removeEventListener('keydown', handleKeyDown)
  }, [close])

  useEffect(() => {
    document.body.classList.add('dimmed')
    return () => document.body.classList.remove('dimmed')
  }, [])

  return ReactDOM.createPortal(
    <div className='modal-overlay'>
      {children}
    </div>,
    document.getElementById(MODAL_CONTAINER_ID)
  )
}

ModalPortal.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  blockEscapeKey: PropTypes.bool
}

export { ModalPortal }
