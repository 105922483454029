import { Fragment } from 'react'
import T from 'prop-types'

import { Table, TableResponsiveWrapper } from '../../misc'
import { ReportTableRow } from './ReportTableRow'

const ReportTable = props => {
  const {
    rows,
    tableHeaderLabels,
    sortableTableHeaders,
    rowSchema,
    className,
    onSort,
    onResetSort
  } = props

  return (
    <TableResponsiveWrapper className={className}>
      <Table>
        <Table.Header>
          <Table.Row>
            {tableHeaderLabels &&
              tableHeaderLabels?.map(label => (
                <Table.HeaderCell key={label}>{label}</Table.HeaderCell>
              ))}

            {sortableTableHeaders &&
              sortableTableHeaders?.map((header, i) => (
                <Fragment key={i}>
                  {!header?.propPath
                    ? (
                      <Table.HeaderCell> {header.label} </Table.HeaderCell>
                      )
                    : (
                      <Table.HeaderCell
                        {...{
                          header,
                          isSortable: true,
                          onSort,
                          onResetSort
                        }}
                      />
                      )}
                </Fragment>
              ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rows?.map((row, i) => (
            <ReportTableRow key={i} row={row} rowSchema={rowSchema} />
          ))}
        </Table.Body>
      </Table>
    </TableResponsiveWrapper>
  )
}

ReportTable.propTypes = {
  rows: T.array,
  tableHeaderLabels: T.array,
  sortableTableHeaders: T.arrayOf(
    T.shape({
      label: T.string,
      proPath: T.arrayOf(T.string),
      isActive: T.bool,
      isAscending: T.bool,
      secondaryPropPath: T.arrayOf(T.string)
    })
  ),
  onSort: T.func,
  onResetSort: T.func,
  rowSchema: T.arrayOf(T.shape({ dataLabel: T.string, buildData: T.func }))
}

export { ReportTable }
