import T from 'prop-types'

import ImportPlatformLayout from '../ImportPlatformLayout'
import { ImportFooterConnection, ImportGuide } from '../ImportFooter'
import { CashCalcGuide } from '../CashCalcGuide'
import { Button, Card } from './../../misc'
import AppConfig from '../../../appConfig'

import './CashCalcImportPage.less'

const CashCalcImportPage = props => {
  const pageTitle = 'CashCalc'

  const {
    onCashCalcLogin,
    isConnectionActive,
    loading,
    disconnectCashCalc
  } = props

  const buttonText = isConnectionActive
    ? 'Disconnect CashCalc'
    : 'Login to CashCalc'

  const onButtonClick = isConnectionActive
    ? disconnectCashCalc
    : onCashCalcLogin

  const buttonColor = isConnectionActive
    ? Button.Colors.GREY
    : Button.Colors.BLUE

  return (
    <ImportPlatformLayout pageTitle={pageTitle}>
      <ImportPlatformLayout.LeftColumn>
        <Card.Group>
          <Card>
            <Card.Heading large className='cashcalc-card__heading--large'>
              CashCalc
            </Card.Heading>
            {!isConnectionActive && (
              <div className='cashcalc-card__text'>
                In order to use this integration it is required that you authorise Elen to access your CashCalc data. Use the button below to log in and give Elen needed permissions.
              </div>
            )}

            <Button
              onClick={onButtonClick}
              color={buttonColor}
              disabled={loading}
            >
              {buttonText}
            </Button>
          </Card>
          <Card>
            <ImportFooterConnection
              pageTitle={pageTitle}
              link='/secure/admin/cashcalc/logs'
            />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.LeftColumn>

      <ImportPlatformLayout.RightColumn>
        <Card.Group>
          <Card>
            <Card.Heading>Import data</Card.Heading>
            <CashCalcGuide />
          </Card>
          <Card>
            <ImportGuide link={AppConfig.CASH_CALC_IMPORT_STEP_BY_STEP_LINK} />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.RightColumn>
    </ImportPlatformLayout>
  )
}

CashCalcImportPage.propTypes = {
  loading: T.bool,
  error: T.object,
  onCashCalcLogin: T.func,
  disconnectCashCalc: T.func,
  isConnectionActive: T.bool
}

export { CashCalcImportPage }
