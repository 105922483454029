import { useEffect } from 'react'
import { withFormik } from 'formik'
import { toast } from 'react-toastify'

import { Form, FormErrorMessages } from '../../../form'
import { InputField } from '../../../form/fields'
import { Button, LoadingMessage, Toastr } from '../../../misc'

const SeatsForm = props => {
  const {
    setValues,
    values,
    activeUsers,
    errors,
    setErrors,
    mutationLoading,
    mutationError,
    totalLicenses,
    handleSubmit
  } = props

  const addToseatsValue = () => setValues({ seats: values.seats + 1 })
  const subtractFromSeatsValue = () => setValues({ seats: values.seats - 1 })

  useEffect(() => {
    setErrors({ mutationError })
  }, [mutationError])

  const isError = !!Object.values(errors).filter(Boolean).length
  const disableBtn =
    values?.seats < activeUsers ||
    values?.seats === totalLicenses ||
    mutationLoading

  return (
    <Form className='seats-form' onSubmit={handleSubmit}>
      <Form.Group className='seats-form__seats-group'>
        <Button
          color={Button.Colors.GREEN}
          onClick={subtractFromSeatsValue}
          disabled={activeUsers === values.seats}
        >
          -
        </Button>

        <InputField name='seats' inputProps={{ disabled: true }} />

        <Button color={Button.Colors.GREEN} onClick={addToseatsValue}>
          +
        </Button>
      </Form.Group>

      <p className='seats-form__description'>
        This will update your <b> billing and subscription information. </b>
        <br />
        Minimum number of licenses you can set is determined by the number of
        currently active users.
        <br />
        <br />
        Currently: <b> {activeUsers} active users</b>
      </p>

      {mutationLoading && <LoadingMessage text='Updating ...' />}
      {isError && <FormErrorMessages validationErrors={errors} />}

      <Button color={Button.Colors.BLUE} type='submit' disabled={disableBtn}>
        Save
      </Button>
    </Form>
  )
}

const EnhancedSeatsForm = withFormik({
  mapPropsToValues: ({ totalLicenses }) => ({ seats: totalLicenses }),
  handleSubmit: async (
    values,
    { props: { onDone, onSubmit, totalLicenses } }
  ) => {
    const seatsUpdated = values.seats !== totalLicenses

    try {
      await onSubmit(values)
      if (seatsUpdated) {
        toast(<Toastr type='success' title='Subscription details updated' />)
      }
      onDone()
    } catch (error) {
      toast(<Toastr type='error' title='Something went wrong' />)
      onDone()
      return error
    }
  }
})(SeatsForm)

export { EnhancedSeatsForm as SeatsForm }
