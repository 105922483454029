import { useState } from 'react'

const useFormState = () => {
  const initialFormState = {
    loading: false,
    error: '',
    success: false
  }

  const [formState, setFormState] = useState(initialFormState)
  return [formState, setFormState]
}

export default useFormState
