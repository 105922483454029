"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {};
exports.default = void 0;
var _charge = _interopRequireWildcard(require("./charge"));
Object.keys(_charge).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _charge[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _charge[key];
    }
  });
});
var _client = _interopRequireWildcard(require("./client"));
Object.keys(_client).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _client[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _client[key];
    }
  });
});
var _error = _interopRequireWildcard(require("./error"));
Object.keys(_error).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _error[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _error[key];
    }
  });
});
var _dataImport = _interopRequireWildcard(require("./dataImport"));
Object.keys(_dataImport).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _dataImport[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _dataImport[key];
    }
  });
});
var _misc = _interopRequireWildcard(require("./misc"));
Object.keys(_misc).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _misc[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _misc[key];
    }
  });
});
var _policy = _interopRequireDefault(require("./policy"));
var _services = _interopRequireWildcard(require("./services"));
Object.keys(_services).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _services[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _services[key];
    }
  });
});
var _user = _interopRequireWildcard(require("./user"));
Object.keys(_user).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _user[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _user[key];
    }
  });
});
var _activityLog = require("./activityLog");
Object.keys(_activityLog).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _activityLog[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _activityLog[key];
    }
  });
});
var _corporateClient = require("./corporateClient");
Object.keys(_corporateClient).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _corporateClient[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _corporateClient[key];
    }
  });
});
var _permissions = require("./permissions");
Object.keys(_permissions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _permissions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _permissions[key];
    }
  });
});
var _subdomain = require("./subdomain");
Object.keys(_subdomain).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _subdomain[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _subdomain[key];
    }
  });
});
var _payment = require("./payment");
Object.keys(_payment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _payment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _payment[key];
    }
  });
});
var _signup = require("./signup");
Object.keys(_signup).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _signup[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _signup[key];
    }
  });
});
var _task = require("./task");
Object.keys(_task).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _task[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _task[key];
    }
  });
});
var _eSignature = require("./eSignature");
Object.keys(_eSignature).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _eSignature[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _eSignature[key];
    }
  });
});
var _workflow = require("./workflow");
Object.keys(_workflow).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _workflow[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _workflow[key];
    }
  });
});
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// TODO: remove when not used anywhere.
var _default = exports.default = {
  charge: _charge.default,
  client: _client.default,
  error: _error.default,
  dataImport: _dataImport.default,
  misc: _misc.default,
  policy: _policy.default,
  services: _services.default,
  user: _user.default
};