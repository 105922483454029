import * as yup from 'yup'

import { stringSchema } from '../helpers'

const taskAttachmentSchema = yup.object().shape({
  fileId: stringSchema(),
  fileName: stringSchema()
})

export { taskAttachmentSchema }
