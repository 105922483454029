import { useState, useCallback } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { HeaderPDF } from './HeaderPDF'
import { FooterPDF } from './FooterPDF'
import { Spinner } from '../../misc'
import { DocumentUnavailable } from '../DocumentUnavailable'
import { useScalePDF } from './useScalePDF'

import './PreviewerPDF.less'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PreviewerPDF = props => {
  const {
    file: { downloadUrl, fileName },
    onCancel,
    loading,
    error,
    onRequestSignature,
    onSignDocument,
    isClientPortal,
    documentUnavailable
  } = props

  const [numPages, setNumPages] = useState(0)
  const { onZoomIn, onZoomOut, scale } = useScalePDF()

  const onDocumentLoadSuccess = useCallback(({ numPages }) =>
    setNumPages(numPages)
  )

  const isPreview = !(onRequestSignature || onSignDocument)

  const cancelModalProps = !isPreview && {
    heading: 'Exit signature request',
    text: 'Are you sure you want to cancel the process of signature request?',
    confirmationCallback: isConfirm => isConfirm && onCancel()
  }

  return (
    <div
      className={classnames(
        'preview-pdf',
        isPreview && 'preview-pdf--is-preview',
        {
          'preview-pdf--is-preview': isPreview
        }
      )}
    >
      <HeaderPDF
        {...{
          fileName,
          downloadUrl,
          onZoomIn,
          onZoomOut,
          onCancel,
          cancelModalProps,
          documentUnavailable
        }}
      />

      <div
        className={classnames('preview-pdf__document-container', {
          'preview-pdf__document-container--is-doc-unavailable': documentUnavailable
        })}
      >
        {downloadUrl && (
          <Document
            file={downloadUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={() => <Spinner />}
            className='preview-pdf__document-container__document'
          >
            {numPages &&
              [...Array(numPages).keys()].map((_, index) => (
                <Page
                  pageNumber={index + 1}
                  className='preview-pdf__document-container__document__page'
                  scale={scale}
                  key={index}
                  loading={null}
                />
              ))}
          </Document>
        )}
        {documentUnavailable && <DocumentUnavailable />}
      </div>

      {!isPreview && (
        <FooterPDF
          {...{
            onSignDocument,
            onRequestSignature,
            loading,
            error,
            cancelModalProps,
            isClientPortal
          }}
        />
      )}
    </div>
  )
}

PreviewerPDF.propTypes = {
  onRequestSignature: T.func,
  onSignDocument: T.func,
  isClientPortal: T.bool,
  documentUnavailable: T.bool,
  file: T.shape({
    downloadUrl: T.string,
    fileName: T.string
  }),
  onCancel: T.func,
  loading: T.bool,
  error: T.shape({
    msg: T.string.isRequired,
    isRequestError: T.bool.isRequired
  })
}

export { PreviewerPDF }
