import ImportPlatformHeader from './ImportPlatformHeader'
import './ImportPlatformLayout.less'

const ImportPlatformLayout = ({ children, pageTitle }) => (
  <div className='import-platform-layout'>
    <div className='import-platform-layout__container'>
      <ImportPlatformHeader pageTitle={pageTitle} />
      <div className='import-platform-layout__content'>
        {children}
      </div>
    </div>
  </div>
)

export default ImportPlatformLayout
