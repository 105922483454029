import ReactDOM from 'react-dom'
import classNames from 'classnames'

import { SIDE_PANEL_CONTAINER_ID } from './const'

const SidePanelPortal = ({ children, showPanel }) =>
  ReactDOM.createPortal(
    <div
      className={classNames('side-panel-wrapper', {
        'side-panel-wrapper--open': showPanel
      })}
    >
      {children}
    </div>,
    document.getElementById(SIDE_PANEL_CONTAINER_ID)
  )

export { SidePanelPortal }
