import { useCallback } from 'react'
import { connect } from 'formik'
import { useNavigate } from 'react-router-dom'

import FormFieldsCard from '../../form/FormFieldsCard'
import { PageDivider } from '../../misc/PageDivider'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import Card from '../../misc/Card'
import IncomeForm from './IncomeForm'
import ExpenditureForm from './ExpenditureForm'
import clientIncomeAndExpenditureSchema from '../../../schemas/client/incomeAndExpenditureSchema'
import { validateAndNavigate } from '../../../helpers/client'

import './ClientIncomeAndExpenditureForm.less'

const ClientIncomeAndExpenditureForm = ({ setValidationSchema, nextLink, prevLink, formik }) => {
  const navigate = useNavigate()
  const { validateForm } = formik

  const handleNavButtonClick = useCallback((isNext) => {
    validateAndNavigate({
      setSchema: setValidationSchema,
      schema: clientIncomeAndExpenditureSchema,
      validate: validateForm,
      nextLocation: isNext ? nextLink : prevLink,
      navigate
    })
  }, [navigate, nextLink, prevLink, validateForm, clientIncomeAndExpenditureSchema, setValidationSchema])

  return (
    <Card className='client-income-and-expenditure-form'>
      <FormFieldsCard title='Income'>
        <IncomeForm />
      </FormFieldsCard>

      <PageDivider />

      <FormFieldsCard title='Expenditure'>
        <ExpenditureForm />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}

const EnhancedForm = connect(ClientIncomeAndExpenditureForm)

export default EnhancedForm
