import { useEffect } from 'react'
import { compose } from 'ramda'
import { useParams } from 'react-router-dom'

import { withUnreadMessagesSubscription } from '../../hoc/container'
import SectionNav from '../../nav/SectionNav'

const MESSAGES_LABEL = 'Messages'

const items = [
  { label: 'Overview', path: 'summary' },
  { label: 'Fact Find', path: 'fact-find' },
  { label: 'Compliance', path: 'compliance' },
  { label: 'Policies', path: 'policies' },
  { label: 'Documents', path: 'documents' },
  { label: 'Activity', path: 'audit-log' },
  { label: 'Tasks', path: 'tasks' },
  { label: MESSAGES_LABEL, path: 'messages' },
  { label: 'Fees and Commissions', path: 'fees-and-commissions' }
]

const ClientNav = ({ subscribeForUnreadMessages, unreadMessagesCount, ...props }) => {
  const { clientId } = useParams()

  useEffect(() => {
    let unsubscribe
    if (subscribeForUnreadMessages) {
      unsubscribe = subscribeForUnreadMessages()
    }
    return () => unsubscribe && unsubscribe()
  }, [subscribeForUnreadMessages])

  const clientTotalUnreadMessages = unreadMessagesCount.find(msg => msg.clientId === clientId)?.count || 0
  const messageItem = items.find(({ label }) => label === MESSAGES_LABEL)
  messageItem.clientTotalUnreadMessages = clientTotalUnreadMessages
  messageItem.isMessage = true

  return (
    <SectionNav {... { items, ...props }} />
  )
}

const EnhancedClientNav = compose(
  withUnreadMessagesSubscription()
)(ClientNav)

export { EnhancedClientNav as ClientNav }
