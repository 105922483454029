import { ClientDetailsBaseSideMenu } from '../../client/ClientDetailsBaseSideMenu'

const items = [
  { caption: 'Company details', to: 'company-details' },
  { caption: 'Profit And Loss', to: 'profit-and-loss' }
]

const CorporateClientDetailsSideMenu = (props) => (
  <ClientDetailsBaseSideMenu
    clientSpecificItems={items}
    {...props}
  />
)

export { CorporateClientDetailsSideMenu }
