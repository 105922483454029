const DocumentNotFoundIcon = props => (
  <svg
    width={164}
    height={164}
    viewBox='0 0 164 164'
    {...props}
  >
    <defs>
      <filter
        x='-17.6%'
        y='-594.1%'
        width='135.3%'
        height='1288.2%'
        filterUnits='objectBoundingBox'
        id='prefix__a'
      >
        <feGaussianBlur stdDeviation='6 0' in='SourceGraphic' />
      </filter>
      <filter
        x='-6.3%'
        y='-396.1%'
        width='112.6%'
        height='892.2%'
        filterUnits='objectBoundingBox'
        id='prefix__b'
      >
        <feGaussianBlur stdDeviation={2} in='SourceGraphic' />
      </filter>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M10 0h144v144H10z' />
      <g transform='translate(31 121)' fill='#000'>
        <rect
          opacity={0.056}
          filter='url(#prefix__a)'
          width={102}
          height={3.03}
          rx={1.5}
        />
        <rect
          opacity={0.228}
          x={9.089}
          y={0.757}
          width={83.822}
          height={1.515}
          rx={0.757}
        />
        <rect
          opacity={0.6}
          filter='url(#prefix__b)'
          x={4.04}
          y={0.757}
          width={94.931}
          height={1.515}
          rx={0.757}
        />
      </g>
      <g transform='translate(44.49 33.843)' fill='#97979B' fillRule='nonzero'>
        <circle cx={18} cy={2} r={2} />
        <circle cx={10} cy={2} r={2} />
        <circle cx={2} cy={2} r={2} />
      </g>
      <path
        d='M126.531 22H37.47A5.469 5.469 0 0032 27.469v89.062A5.469 5.469 0 0037.469 122h89.062a5.469 5.469 0 005.469-5.469V27.47A5.469 5.469 0 00126.531 22zM42.1 30h79.8c1.16 0 2.1 1.007 2.1 2.25V42H40v-9.75c0-1.243.94-2.25 2.1-2.25zm79.8 84H42.1c-1.16 0-2.1-.886-2.1-1.98V48h84v64.02c0 1.094-.94 1.98-2.1 1.98z'
        fill='#D1D1D8'
        fillRule='nonzero'
      />
      <path
        d='M82 62c10.493 0 19 8.507 19 19s-8.507 19-19 19-19-8.507-19-19 8.507-19 19-19zm0 4.75c-7.87 0-14.25 6.38-14.25 14.25S74.13 95.25 82 95.25 96.25 88.87 96.25 81 89.87 66.75 82 66.75zm6.963 7.079l.11.102a2 2 0 01.007 2.822l-4.25 4.25 4.25 4.248a1.99 1.99 0 01.096 2.712l-.103.11a2 2 0 01-2.822.007l-4.249-4.25-4.249 4.25a1.99 1.99 0 01-2.712.096l-.11-.103a2 2 0 01-.006-2.822l4.249-4.249-4.25-4.249a1.99 1.99 0 01.007-2.822 2 2 0 012.822-.006l4.25 4.249 4.248-4.25a1.99 1.99 0 012.712-.095z'
        fill='#EC2A2A'
      />
    </g>
  </svg>
)

export { DocumentNotFoundIcon }
