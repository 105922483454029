import { useMemo } from 'react'
import { compose, find, propEq, prop, pipe } from 'ramda'
import { withCurrentUser, withTeams } from '../../hoc/container'

import { Spinner } from '../../misc'
import { TeamsPage } from './TeamsPage'

const TeamsPageContainer = ({ teams, teamsLoading, currentUser }) => {
  const teamsUserIsPartOfCount = useMemo(
    () =>
      teams.filter(pipe(prop('roles'), find(propEq('userId', currentUser.id))))
        .length,
    [teams]
  )

  if (teamsLoading) return <Spinner />

  return <TeamsPage {...{ teams, currentUser, teamsUserIsPartOfCount }} />
}

const EnhancedTeamsPageContainer = compose(
  withCurrentUser(),
  withTeams()
)(TeamsPageContainer)

export { EnhancedTeamsPageContainer as TeamsPageContainer }
