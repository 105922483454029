import { pick } from 'ramda'
import moment from 'moment'

import {
  FilterDateRangeLabels,
  FilterDateRanges
} from './../../../constants/filterDate'
import { buildSelectOptionsWithNoSort } from './../../form/fields/SelectField/helpers'

const filterDateRangeKeys = [
  FilterDateRanges.LAST_WEEK,
  FilterDateRanges.NEXT_WEEK,
  FilterDateRanges.LAST_MONTH,
  FilterDateRanges.NEXT_MONTH,
  FilterDateRanges.THIS_MONTH_TO_DATE,
  FilterDateRanges.LAST_QUARTER,
  FilterDateRanges.NEXT_QUARTER,
  FilterDateRanges.THIS_QUARTER_TO_DATE,
  FilterDateRanges.LAST_HALF_YEAR,
  FilterDateRanges.THIS_YEAR_TO_DATE,
  FilterDateRanges.LAST_YEAR
]

const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)

const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)

export const setStartOfEndOfDay = ({ fromDate, toDate }) => ({
  fromDate: moment(fromDate)
    .startOf('day')
    .toDate(),
  toDate: moment(toDate)
    .endOf('day')
    .toDate()
})

export const emptyIncomeData = {
  expected: { amount: 0, currency: 'GBP' },
  received: { amount: 0, currency: 'GBP' }
}
