import { useCallback } from 'react'
import T from 'prop-types'

import { Trash } from '../../../icons'
import { SelectField } from '../../../form/fields'
import { companyClient } from '../../../../helpers/propTypes'
import { ConfirmationModal } from '../../../misc'

export const PolicyHoldersSectionInputRow = ({
  index,
  arrayHelpers,
  currentHoldersIds,
  policyHolder,
  currentClient
}) => {
  const confirmationCallback = useCallback(
    async (answer) => {
      if (answer === true) {
        arrayHelpers.remove(index)
      }
    }, [arrayHelpers, index]
  )

  const isNonRemovable = policyHolder === currentClient.id

  return (
    <div className='policy-holders__input-row'>
      <SelectField
        name={`currentHoldersIds.${index}`}
        label={isNonRemovable ? 'Policy holders' : null}
        optionsToResolve={currentHoldersIds}
        inputProps={{
          async: true,
          isClearable: true,
          isDisabled: isNonRemovable,
          asyncProps: {
            labelProp: 'fullName',
            valueProp: 'id'
          }
        }}
      />

      {!isNonRemovable && (
        <ConfirmationModal.Trigger
          as={Trash}
          className='policy-holders__input-row__trash'
          modalProps={{
            confirmationCallback,
            heading: 'Remove policy holder',
            text: <>
              <p>Are you sure you want to remove the following policy holder?</p>
              <p>{`
                This means that the policy will be no longer be available from their client profile,
                and it will no longer be available to that client via the client portal.
              `}
              </p>
            </>, // eslint-disable-line
            yesButtonText: 'Remove',
            noButtonText: 'Go back',
            awaitCallback: true
          }}
        />
      )}
    </div>
  )
}

PolicyHoldersSectionInputRow.propTypes = {
  index: T.number.isRequired,
  arrayHelpers: T.object.isRequired,
  currentHoldersIds: T.arrayOf(T.string.isRequired),
  policyHolder: T.string.isRequired,
  currentClient: companyClient().isRequired
}
