import { useMemo } from 'react'
import T from 'prop-types'

import { PolicyTransactionsTotals } from './PolicyTransactionsTotals'

import { calcTotalContributions } from '../../../../helpers/policy'

const PolicyTransactionsTotalsContainer = ({ contributions, withdrawals }) => {
  const dataPrepared = useMemo(
    () => calcTotalContributions(contributions, withdrawals),
    [contributions, withdrawals]
  )

  return (
    <PolicyTransactionsTotals {...dataPrepared} />
  )
}

PolicyTransactionsTotalsContainer.propTypes = {
  contributions: T.arrayOf(T.object),
  withdrawals: T.arrayOf(T.object)
}

PolicyTransactionsTotalsContainer.defaultProps = {
  contributions: [],
  withdrawals: []
}

export { PolicyTransactionsTotalsContainer }
