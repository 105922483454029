import { isEmpty } from 'ramda'

import { Table, TableResponsiveWrapper } from '../../../misc'
import { PolicyValuationsTableRow } from './PolicyValuationsTableRow'
import { PolicyValuationsHeader } from './PolicyValuationsHeader'
import { TableHeaders } from './constants'
import { LIMIT_NUMBERS_OF_VALUATIONS } from '../constants'

import './PolicyValuationTable.less'

const PolicyValuationTable = ({ policyValuations, policyId, readOnly }) => (
  <div className='policy-valuations__table'>
    <PolicyValuationsHeader policyId={policyId} readOnly={readOnly} />
    {!isEmpty(policyValuations) &&
      <TableResponsiveWrapper>
        <Table>
          <colgroup>
            <col className='policy-valuations__table__columns__date' />
            <col className='policy-valuations__table__columns__value' />
            <col className='policy-valuations__table__columns__info' />
            <col className='policy-valuations__table__columns__actions' />
          </colgroup>

          <Table.Header>
            <Table.Row>
              {Object.values(TableHeaders).map((item, index) => (
                <Table.HeaderCell key={index}>
                  {item}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {policyValuations.map((valuation, index) => {
              if (index !== (LIMIT_NUMBERS_OF_VALUATIONS - 1)) {
                return (
                  <PolicyValuationsTableRow
                    className='policy-valuations__table__row'
                    key={valuation?.id}
                    policyValuation={valuation}
                    previousValuation={policyValuations[index + 1]}
                    policyId={policyId}
                    readOnly={readOnly}
                  />
                )
              }

              return null
            })}
          </Table.Body>
        </Table>
      </TableResponsiveWrapper>}
  </div>
)

export { PolicyValuationTable }
