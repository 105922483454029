const SendIcon = props => (
  <svg width={24} height={24} {...props}>
    <title>Icon/24/Send_24</title>
    <path
      d='M20.096 3a.9.9 0 0 0-.28.048L3.618 8.443a.9.9 0 0 0-.12 1.66l6.931 3.468 3.47 6.93a.9.9 0 0 0 1.66-.12l5.396-16.198A.9.9 0 0 0 20.096 3Zm-1.422 2.326L14.535 17.75l-2.34-4.673 3.144-3.141a.9.9 0 0 0-.658-1.549.9.9 0 0 0-.619.273l-3.142 3.143-4.673-2.338 12.427-4.14Z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>
)

export { SendIcon }
