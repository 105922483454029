import { MenuDotsIcon } from '../../../icons'
import { DropdownMenu, Modal } from '../../../misc'
import { SendMessageModal } from '../../../misc/SendMessageModal'

const MessageModalTrigger = ({ clientId, clientName }) => (
  <Modal.Trigger
    as='span'
    modal={SendMessageModal}
    modalProps={{ clientId, clientName }}
  >
    Send message
  </Modal.Trigger>
)

export const ClientsListAction = ({ props }) => {
  const { email, mobile, clientPortalUser, id, fullName } = props

  return (
    <DropdownMenu
      className='action'
      direction={DropdownMenu.Directions.RIGHT}
      fullWidth={false}
      trigger={<MenuDotsIcon className='client-action' />}
    >
      <DropdownMenu.List>
        <DropdownMenu.Item>
          <a href={`mailto:${email}`} className='client-action-email'>
            Send email
          </a>
        </DropdownMenu.Item>

        <DropdownMenu.Item>
          <a href={`tel:${mobile}`} className='client-action-email'>
            Make phonecall
          </a>
        </DropdownMenu.Item>

        {!!clientPortalUser?.id &&
          <DropdownMenu.Item>
            <MessageModalTrigger clientId={id} clientName={fullName} />
          </DropdownMenu.Item>}
      </DropdownMenu.List>
    </DropdownMenu>
  )
}
