import ConfirmationModal from '../../../misc/ConfirmationModal'
import { Trash } from '../../../icons'
import { Button } from '../../../misc'
import {
  PolicyCategoriesLabels
} from '../../../../helpers/enumLabels/policy'

import './removePolicyButton.less'

const RemovePolicyButton = ({
  removePolicy,
  category,
  number,
  disabled,
  ...restProps
}) => (
  <ConfirmationModal.Trigger
    as={Button}
    className='remove-policy-button'
    disabled={disabled}
    modalProps={{
      confirmationCallback: removePolicy,
      heading: 'Delete policy',
      text: `
        Are you sure you want to delete the
        ${PolicyCategoriesLabels[category]}
        policy number ${number}?
      `,
      yesButtonText: 'Delete',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
    {...restProps}
  >
    <Trash />
  </ConfirmationModal.Trigger>
)

export default RemovePolicyButton
