import { useState, useEffect, useCallback } from 'react'

import {
  useTasksGql,
  useTasksModal,
  useLocalStorageUserSettings,
  localStorageUserSettingsKeys
} from '../../../hooks'
import { withCurrentUser } from './../../hoc/container'
import { ListViewPage } from './ListViewPage'

const ListViewPageContainer = ({ currentUser }) => {
  const { userSettingData, persistUserSettingsData } = useLocalStorageUserSettings(
    localStorageUserSettingsKeys.TASK_FILTER
  )

  const [queryFilters, setQueryFilters] = useState(userSettingData)

  const onFilter = (values) => {
    setQueryFilters(values)
    persistUserSettingsData(values)
  }

  const {
    tasks,
    loadingTasks,
    errorTasks,

    createTask,
    loadingOnCreateTask,
    errorOnCreateTask,

    createSubtask,
    loadingOnCreateSubtask,
    errorOnCreateSubtask
  } = useTasksGql({ queryFilters })

  const taskModalProps = useTasksModal({ tasks })

  const { onTaskClick, openNewTaskModal } = taskModalProps

  // Related with creation of new Subtask
  const [updatedTaskId, setUpdatedTaskId] = useState('')
  const [errorsOnCreateSubtask, setErrorsOnCreateSubtask] = useState({})

  const onCreateSubtask = useCallback(async ({ summary, taskId: parentId }) => {
    setUpdatedTaskId(parentId)
    await createSubtask({ variables: { input: { summary, parentId } } })
  }, [])

  useEffect(
    () =>
      setErrorsOnCreateSubtask(current => ({
        ...current,
        [updatedTaskId]: errorOnCreateSubtask?.message
      })),
    [errorOnCreateSubtask]
  )
  // >

  const onCreateTask = useCallback(
    input => createTask({ variables: { input: { ...input } } }),
    [createTask]
  )

  const filterProps = {
    onSubmit: onFilter,
    tasksFilter: userSettingData
  }

  const tableProps = {
    tasks,
    loadingTasks,
    errorTasks,
    loadingOnCreateTask,
    errorOnCreateTask: errorOnCreateTask?.message || '',
    onCreateTask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    onCreateSubtask,
    onTaskClick,
    currentUser
  }

  return (
    <ListViewPage {...{
      filterProps,
      tableProps,
      taskModalProps: { ...taskModalProps, queryFilters },
      openNewTaskModal,
      tasksFilter: userSettingData,
      saveTasksFilter: persistUserSettingsData
    }}
    />
  )
}

const ListViewPageContainerWithCurrentUser = withCurrentUser()(ListViewPageContainer)

export { ListViewPageContainerWithCurrentUser as ListViewPageContainer }
