import './CashCalcGuide.less'

export const CashCalcGuide = () => (
  <div className='cash-calc-guide'>
    <p>
      After you have succesfully authorised Elen you can start importing your clients:
    </p>
    <ul>
      <li>
        Click on your client tab, '+ New Client' button and click 'CashCalc in the popup menu
      </li>
      <li>
        You'll then be presented with a list of clients you can choose to import
      </li>
    </ul>
  </div>
)
