import PropTypes from 'prop-types'

import { Modal, ModalStickyLayout } from '../../misc'
import { AuditLogFooterInfo, differenceFromPrevNext } from './helpers'
import { AuditLogTable } from './AuditLogTable'

import './AuditLogEntryModal.less'

const AuditLogLayout = ({
  auditLogEntry,
  trigger,
  isOpen,
  close,
  className
}) => {
  const dataDiff = differenceFromPrevNext(
    auditLogEntry?.factFindAuditTrail?.previousValue,
    auditLogEntry?.factFindAuditTrail?.nextValue
  )

  const message = dataDiff
    ? 'There is no changes since last update.'
    : 'Client submitted their changes.'

  return (
    <ModalStickyLayout
      isOpen={isOpen}
      close={close}
      trigger={trigger}
      className={`${className}--audit-layout`}
    >
      <ModalStickyLayout.Header close={close}>
        <Modal.Heading>Audit log</Modal.Heading>
      </ModalStickyLayout.Header>

      <ModalStickyLayout.Body>
        <AuditLogTable data={dataDiff} message={message} />
      </ModalStickyLayout.Body>

      <ModalStickyLayout.Footer>
        <AuditLogFooterInfo {...{
          className: `${className}--audit-layout`,
          auditLogEntry
        }}
        />
      </ModalStickyLayout.Footer>
    </ModalStickyLayout>
  )
}

AuditLogLayout.propTypes = {
  auditLogEntry: PropTypes.object,
  trigger: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

export { AuditLogLayout }
