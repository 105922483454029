import { formatDate } from '../../../../../helpers/date'

const NoteDetails = ({ note, noteUpdatedBy }) => (
  !!noteUpdatedBy && (
    <>
      <span className='client-notes-description__author text-description'>
        {formatDate(note.updatedAt, null, 'DD MMM YYYY - HH:mm')}
      </span>
      <span className='client-notes-description__date text-description'>{noteUpdatedBy}</span>
    </>
  )
)

export { NoteDetails }
