import { formatMoney } from '../../../helpers/policy'

export const toMajorUnits = value => +(value / 100).toFixed(2)

export const formatCouponAsText = (coupon, loading, error) => {
  if (!coupon || error) {
    return ''
  }

  const { name, percent_off: percentOff, amount_off: amountOff } = coupon

  if (percentOff) {
    return `[ ${name} (${percentOff}% off) ]`
  }

  if (amountOff) {
    return `[ ${name} (${formatMoney({
      amount: toMajorUnits(amountOff),
      currency: coupon.currency.toUpperCase()
    })} off) ]`
  }
}
