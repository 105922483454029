const MenuIcon = (props) => (
  <svg viewBox='0 0 24 24' width={24} height={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M19 17c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H5c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h14zm-4-6c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H5c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h10zm4-6c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1H5c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1h14z'
      />
    </g>
  </svg>
)

export default MenuIcon
