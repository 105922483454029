import { TasksFilter } from './TasksFilter'

import {
  useQueryAndBuildList,
  buildAdviserOptions
} from './../../../helpers/charge'

import companyUsersGql from '../../../graphql/queries/user/companyUsers.graphql'

const TasksFilterContainer = ({ onSubmit, tasksFilter, disableStatus }) => {
  const { data: assigneeOptions } = useQueryAndBuildList({
    query: companyUsersGql,
    propName: 'users',
    buildList: buildAdviserOptions
  })

  return (
    <TasksFilter {...{
      assigneeOptions,
      onSubmit,
      tasksFilter,
      disableStatus
    }}
    />
  )
}

export { TasksFilterContainer }
