import T from 'prop-types'
import classnames from 'classnames'

export const FormInfoSection = ({ children, className }) => (
  <div
    className={classnames(
      'form-new__section',
      'form-info-section', {
        [className]: !!className
      })}
  >
    {children}
  </div>
)

FormInfoSection.propTtpes = {
  children: T.node,
  className: T.string
}
