import T from 'prop-types'
import pluralize from 'pluralize'

import ImportInfo from './ImportInfo'
import {
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from '../../../../helpers/enumLabels/policy'
import { getSubCategoryLabels } from '../../../../helpers/policy'

const PolicyCreationInfo = ({
  policyNumber,
  policyCategory,
  policySubCategory,
  policyProvider
}) => {
  const SubCategoryLabels = getSubCategoryLabels(policyCategory)

  return (
    <span>
      {[
        policyNumber,
        PolicyCategoriesLabels[policyCategory],
        SubCategoryLabels[policySubCategory],
        PolicyProvidersLabels[policyProvider]
      ]
        .filter(Boolean)
        .join(' - ')}
    </span>
  )
}

const PoliciesCreated = ({ clientFirstName, clientLastName, policies }) => {
  const heading = (
    <>
      <b>
        {policies.length} {pluralize('policy', policies.length)} added
      </b>
      &nbsp;-&nbsp;
      {clientFirstName}&nbsp;
      {clientLastName}
    </>
  )

  const description = policies.map((policy, i) => (
    <PolicyCreationInfo key={i} {...policy} />
  ))

  return (
    <ImportInfo
      heading={heading}
      description={description}
    />
  )
}

PoliciesCreated.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  policies: T.arrayOf(T.shape({
    policyNumber: T.string,
    policyCategory: T.string,
    policySubCategory: T.string,
    policyProvider: T.string
  })).isRequired
}

export default PoliciesCreated
