import { prop, pick } from 'ramda'
import constants from '@elenfs/elen-constants'

import { formatDate } from './../../../helpers/date'
import { formatMoney } from './../../../helpers/policy'
import { TableHeaders } from './const'
import { buildSelectOptionsWithNoSort } from '../../form/fields/SelectField'
import { FilterDateRanges, FilterDateRangeLabels } from '../../../constants/filterDate'
import {
  ChargeFrequenciesLabels,
  ChargeTypesLabels,
  AdviceTypesLabels
} from '../../../helpers/enumLabels'

const {
  PolicyProvidersLabels,
  PolicyCategoriesLabels,
  ContributionMethodsLabels,
  ContributionFrequenciesLabels
} = constants.policy.common

export const mapDataToTableRecords = data =>
  data.length
    ? data.map(row => {
      const { clients, financialAdvisers, policy, contribution, charge } = row

      const {
        number: policyNumber,
        category,
        provider,
        applicationDate,
        startDate,
        isReplacementBusiness,
        lumpSumBenefitOnCriticalIllness,
        lumpSumBenefitOnDeath
      } = policy || {}

      const {
        startDate: contributionDate,
        method,
        frequency: contributionFrequency,
        isTopUp,
        amount
      } = contribution || {}

      const {
        startDate: feeCommissionDate,
        reconciledAt: dateReconciled,
        frequency: feeCommissionFrequency,
        type: feeOrCommission,
        adviceType,
        isAdviceOnly,
        clientAmount,
        providerAmount,
        platformAmount
      } = charge || {}

      const clientName = clients.map(({ fullName }, i) => (
        <div key={i}>{fullName}</div>
      ))
      const adviser = financialAdvisers.map(({ fullName }, i) => (
        <div key={i}>{fullName}</div>
      ))
      const newClient = clients.some(({ isNew }) => isNew) ? 'Yes' : 'No'
      const replacementBusiness = isReplacementBusiness === undefined ? '' : isReplacementBusiness ? 'Yes' : 'No'
      const adviceOnly = isAdviceOnly === undefined ? '' : isAdviceOnly ? 'Yes' : 'No'
      const topUp = isTopUp === undefined ? '' : isTopUp ? 'Yes' : 'No'

      return {
        clientName,
        adviser,
        newClient,
        policyNumber,
        policyType: PolicyCategoriesLabels[category],
        provider: PolicyProvidersLabels[provider],
        applicationDate: formatDate(applicationDate),
        startDate: formatDate(startDate),
        replacementBusiness,
        lumpSumBenefitOnCriticalIllness: formatMoney(
          lumpSumBenefitOnCriticalIllness
        ),
        lumpSumBenefitOnDeath: formatMoney(lumpSumBenefitOnDeath),
        contributionDate: formatDate(contributionDate),
        contributionMethod: ContributionMethodsLabels[method],
        contributionFrequency:
          ContributionFrequenciesLabels[contributionFrequency],
        topUp,
        contributionAmount: formatMoney(amount),
        feeCommissionDate: formatDate(feeCommissionDate),
        dateReconciled: formatDate(dateReconciled),
        feeCommissionFrequency:
          ChargeFrequenciesLabels[feeCommissionFrequency],
        feeOrCommission: ChargeTypesLabels[feeOrCommission],
        adviceType: AdviceTypesLabels[adviceType],
        adviceOnly,
        feeCommissionFromClient: formatMoney(clientAmount),
        feeCommissionFromProvider: formatMoney(providerAmount),
        feeCommissionFromPlatform: formatMoney(platformAmount)
      }
    })
    : []

export const mapDataToSummaryCard = data => {
  const {
    fromDate,
    toDate,
    totalContributionAmount,
    totalClientAmount,
    totalPlatformAmount,
    totalProviderAmount
  } = data

  return {
    ...data,
    fromDate: formatDate(fromDate),
    toDate: formatDate(toDate),
    totalContributionAmount: formatMoney(totalContributionAmount),
    totalClientAmount: formatMoney(totalClientAmount),
    totalPlatformAmount: formatMoney(totalPlatformAmount),
    totalProviderAmount: formatMoney(totalProviderAmount)
  }
}

export const rowSchema = [
  { dataLabel: TableHeaders.CLIENT_NAME, buildData: prop('clientName') },
  { dataLabel: TableHeaders.ADVISER, buildData: prop('adviser') },
  {
    dataLabel: TableHeaders.NEW_CLIENT_IN_THIS_PERIOD,
    buildData: prop('newClient')
  },
  { dataLabel: TableHeaders.POLICY_NUMBER, buildData: prop('policyNumber') },
  { dataLabel: TableHeaders.POLICY_TYPE, buildData: prop('policyType') },
  { dataLabel: TableHeaders.PROVIDER, buildData: prop('provider') },
  {
    dataLabel: TableHeaders.APPLICATION_DATE,
    buildData: prop('applicationDate')
  },
  { dataLabel: TableHeaders.START_DATE, buildData: prop('startDate') },
  {
    dataLabel: TableHeaders.REPLACEMENT_BUSINESS,
    buildData: prop('replacementBusiness')
  },
  {
    dataLabel: TableHeaders.LUMP_SUM_BENEFIT_ON_CRITICAL_ILLNESS,
    buildData: prop('lumpSumBenefitOnCriticalIllness')
  },
  {
    dataLabel: TableHeaders.LUMP_SUM_BENEFIT_ON_DEATH,
    buildData: prop('lumpSumBenefitOnDeath')
  },
  {
    dataLabel: TableHeaders.CONTRIBUTION_DATE,
    buildData: prop('contributionDate')
  },
  {
    dataLabel: TableHeaders.CONTRIBUTION_METHOD,
    buildData: prop('contributionMethod')
  },
  {
    dataLabel: TableHeaders.CONTRIBUTION_FREQUENCY,
    buildData: prop('contributionFrequency')
  },
  { dataLabel: TableHeaders.TOP_UP, buildData: prop('topUp') },
  {
    dataLabel: TableHeaders.CONTRIBUTION_AMOUNT,
    buildData: prop('contributionAmount')
  },
  {
    dataLabel: TableHeaders.FEE_COMMISSION_DATE,
    buildData: prop('feeCommissionDate')
  },
  {
    dataLabel: TableHeaders.DATE_RECONCILED,
    buildData: prop('dateReconciled')
  },
  {
    dataLabel: TableHeaders.FEE_COMMISSION_FREQUENCY,
    buildData: prop('feeCommissionFrequency')
  },
  {
    dataLabel: TableHeaders.FEE_OR_COMMISSION,
    buildData: prop('feeOrCommission')
  },
  { dataLabel: TableHeaders.ADVICE_TYPE, buildData: prop('adviceType') },
  { dataLabel: TableHeaders.ADVICE_ONLY, buildData: prop('adviceOnly') },
  {
    dataLabel: TableHeaders.FEE_COMMISSION_FROM_CLIENT,
    buildData: prop('feeCommissionFromClient')
  },
  {
    dataLabel: TableHeaders.FEE_COMMISSION_FROM_PROVIDER,
    buildData: prop('feeCommissionFromProvider')
  },
  {
    dataLabel: TableHeaders.FEE_COMMISSION_FROM_PLATFORM,
    buildData: prop('feeCommissionFromPlatform')
  }
]

export const ReplacementBusinessOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

const filterDateRangeKeys = [
  FilterDateRanges.LAST_WEEK,
  FilterDateRanges.LAST_MONTH,
  FilterDateRanges.THIS_MONTH_TO_DATE,
  FilterDateRanges.LAST_QUARTER,
  FilterDateRanges.THIS_YEAR_TO_DATE,
  FilterDateRanges.THIS_QUARTER_TO_DATE,
  FilterDateRanges.LAST_HALF_YEAR,
  FilterDateRanges.LAST_YEAR,
  FilterDateRanges.CUSTOM
]

export const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)

const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)
