import PropTypes from 'prop-types'
import classnames from 'classnames'

import './HeaderLogo.less'

const HeaderLogo = ({ className, renderIcon }) => (
  <div
    aria-label='The Elen logo. Click here to go back to the homepage.'
    className={classnames('main-header__logo', className)}
  >
    {renderIcon()}
  </div>
)

HeaderLogo.propTypes = {
  className: PropTypes.string,
  renderIcon: PropTypes.func
}

export { HeaderLogo }
