
import { formatDate } from '../date'
import { PolicyProvidersLabels } from '../enumLabels'

const formatValuationDate = value => formatDate(value, 'D MMM YYYY')
const formatProvider = value => value ? PolicyProvidersLabels[value] : ''

const FieldFormatters = Object.freeze({
  valuationDate: formatValuationDate,
  provider: formatProvider
})

export const formatFieldValue = (path, value) => {
  const formatter = FieldFormatters[path]
  const isValueObject = typeof value === 'object' && value !== null

  return formatter
    ? formatter(value)
    : isValueObject
      ? value?.amount
      : value
}
