import { useQuery } from '@apollo/client'
import { DataImportTypes } from '@elenfs/elen-constants'

import TransactImportStats from './TransactImportStats'
import syncedPoliciesCountGql
  from '../../../graphql/queries/policy/syncedPoliciesCount.graphql'

const TransactImportStatsContainer = (props) => {
  const { loading, error, data } = useQuery(
    syncedPoliciesCountGql,
    {
      variables: { dataImportType: DataImportTypes.TRANSACT },
      fetchPolicy: 'cache-and-network'
    }
  )

  const initialLoading = loading && !data

  return (
    <TransactImportStats
      loading={initialLoading}
      error={error}
      stats={{ policiesCount: data?.syncedPoliciesCount }}
      {...props}
    />
  )
}

export default TransactImportStatsContainer
