import {
  Button,
  PageDivider,
  ToggleButton,
  Text,
  Tooltip,
  ConfirmationModal
} from './../../../misc'
import { SendIcon } from '../../../icons'

const ClientDetailsBaseSideActions = ({
  componentClassName,
  saveToDocumentsButton,
  loading,
  onSaveButtonClick,
  isNewClient,
  clientUserProps,
  toggleClientFactFindMutation,
  isClientPortal,
  isFactFindEditable,
  requestClientFactFindEditMutation,
  submitClientFactFindMutationCallback
}) => (
  <div className={`${componentClassName}__actions`}>
    {saveToDocumentsButton || (
      <Button
        color={Button.Colors.BLUE}
        onClick={onSaveButtonClick}
        disabled={loading || (isClientPortal && !isFactFindEditable)}
      >
        {isNewClient && !isClientPortal ? 'Save' : 'Save changes'}
      </Button>
    )}

    {
      isClientPortal && isFactFindEditable && saveToDocumentsButton &&
        <ConfirmationModal.Trigger
          as={Button}
          color={Button.Colors.BLUE}
          className='submit-to-adviser'
          modalProps={{
            confirmationCallback: submitClientFactFindMutationCallback,
            heading: 'Submit to adviser',
            text: 'You are about to submit changes to your adviser. You won’t be able to make any changes until the adviser gives you access again. Do you want to continue?',
            yesButtonText: 'Yes',
            noButtonText: 'No',
            awaitCallback: true
          }}
        >
          Submit to adviser
        </ConfirmationModal.Trigger>
    }

    {
      isClientPortal && !isFactFindEditable &&
        <Text
          italic
          className={`${componentClassName}__info-text`}
        >
          Your Fact Find is locked for editing.
        </Text>
    }

    {
      !isClientPortal &&
        <>
          <PageDivider />

          {
            clientUserProps?.isClientUser
              ? (
                <ToggleButton {...{
                  isInitiallyActive: isFactFindEditable,
                  onChange: toggleClientFactFindMutation,
                  disabled: !clientUserProps?.isClientUser || loading,
                  text: 'Unlock fact-find',
                  className: 'unlock-fact-find-toggler unlock-fact-find-toggler--button'
                }}
                />
                )
              : (
                <Tooltip.Trigger
                  className='unlock-fact-find-toggler'
                  as={Button}
                  tooltipId='unlock-fact-find-toggler'
                  tooltip={Tooltip.Info}
                  tooltipProps={{
                    text: 'Client needs to activate their client portal first.',
                    className: 'request-edit-by-client__tooltip'
                  }}
                  disabled={!clientUserProps?.isClientUser || loading || !isFactFindEditable}
                  color={Button.Colors.BLUE_TEXT}
                >
                  <ToggleButton {...{
                    isInitiallyActive: isFactFindEditable,
                    onChange: toggleClientFactFindMutation,
                    disabled: !clientUserProps?.isClientUser || loading,
                    text: 'Unlock fact-find',
                    className: 'unlock-fact-find-toggler unlock-fact-find-toggler--button'
                  }}
                  />
                </Tooltip.Trigger>
                )
          }

          {
            clientUserProps?.isClientUser
              ? (
                <Button
                  color={Button.Colors.BLUE_TEXT}
                  disabled={!clientUserProps?.isClientUser || loading || !isFactFindEditable}
                  className='request-edit-by-client'
                >
                  <SendIcon />
                  <span className='request-edit-by-client__short-text' onClick={isFactFindEditable ? requestClientFactFindEditMutation : null}>Request edit</span>
                  <span className='request-edit-by-client__long-text' onClick={isFactFindEditable ? requestClientFactFindEditMutation : null}>Request edit by client</span>
                </Button>
                )
              : (
                <Tooltip.Trigger
                  className='request-edit-by-client'
                  as={Button}
                  tooltipId='request-edit-by-client--action'
                  tooltip={Tooltip.Info}
                  tooltipProps={{
                    text: 'Client needs to activate their client portal first.',
                    className: 'request-edit-by-client__tooltip'
                  }}
                  disabled={!clientUserProps?.isClientUser || loading || !isFactFindEditable}
                  color={Button.Colors.BLUE_TEXT}
                >
                  <SendIcon />
                  <span className='request-edit-by-client__short-text' onClick={isFactFindEditable ? requestClientFactFindEditMutation : null}>Request edit</span>
                  <span className='request-edit-by-client__long-text' onClick={isFactFindEditable ? requestClientFactFindEditMutation : null}>Request edit by client</span>
                </Tooltip.Trigger>
                )
          }

          {
            clientUserProps && !clientUserProps?.isClientUser &&
              <Text
                italic
                className={`${componentClassName}__info-text`}
              >
                Client needs to activate their client portal first.
              </Text>
          }
        </>
    }
  </div>
)

export { ClientDetailsBaseSideActions }
