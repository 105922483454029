import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import Button from '../Button'
import ChevronLeftIcon from '../../icons/ChevronLeft'

import './BackButton.less'

const BackButton = ({ children, to }) => {
  const navigate = useNavigate()

  const onClick = useCallback(() => {
    if (to) {
      navigate(to)
    } else {
      navigate(-1)
    }
  }, [navigate, to])

  return (
    <Button className='back-button' onClick={onClick}>
      <ChevronLeftIcon />
      {children}
    </Button>
  )
}

BackButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string
}

export default BackButton
