import { useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import { NBRPage } from './NBRPage'
import { SelectField } from '../../../form/fields'

import { providerOptions } from './../../../../helpers/selectOptions'
import { withFinancialAdvisers } from './../../../hoc/container'
import { TableHeaders } from './../const'
import { getFromToDateByDateRange } from './../../../../helpers/filterDate'
import {
  mapDataToTableRecords,
  mapDataToSummaryCard,
  rowSchema,
  ReplacementBusinessOptions,
  filterDateRanges,
  filterDateRangeOptions
} from './../helpers'

import newBusinessReportGql from '../../../../graphql/queries/reports/newBusinessReport.graphql'

const NBRPageContainer = ({ financialAdviserOptions }) => {
  const [currentFilter, setCurrentFilter] = useState()

  const [getBusinessReport, { data, loading, error, called }] = useLazyQuery(
    newBusinessReportGql,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )
  const initialLoading = loading && !data

  const onFilter = useCallback(
    filter => {
      getBusinessReport({ variables: { filter } })
      setCurrentFilter(filter)
    },
    [getBusinessReport, setCurrentFilter]
  )

  const filterProps = {
    onSubmit: onFilter,
    getFromToDateByDateRange,
    filterDateRangeOptions,
    filterDateRanges,
    inputsArr: [
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'adviserId',
          options: financialAdviserOptions,
          inputProps: { placeholder: 'Adviser', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'provider',
          options: providerOptions,
          inputProps: { placeholder: 'Provider', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'isReplacementBusiness',
          options: ReplacementBusinessOptions,
          inputProps: { placeholder: 'Replacement business', isClearable: true }
        }
      }
    ],
    initialValues: {
      dateRange: filterDateRanges.THIS_MONTH_TO_DATE
    }
  }

  const downloadBtnProps = {
    currentFilter,
    reportName: 'nbr'
  }

  const rows = mapDataToTableRecords(data?.newBusinessReport?.rows || [])
  const summary = mapDataToSummaryCard(data?.newBusinessReport?.totals || {})

  const tableProps = {
    rows,
    tableHeaderLabels: Object.values(TableHeaders),
    rowSchema,
    className: 'table-nbr'
  }

  return (
    <NBRPage
      {...{
        filterProps,
        downloadBtnProps,
        loading: initialLoading,
        error,
        called,
        summary,
        tableProps
      }}
    />
  )
}

const EnhancedNBRPageContainer = withFinancialAdvisers()(NBRPageContainer)
export { EnhancedNBRPageContainer as NBRPageContainer }
