import { TableResponsiveActionHeader } from '../../../../misc'
import { NewPolicyValuationButton } from '../NewPolicyValuationButton'

const PolicyValuationsHeader = ({ readOnly, ...props }) => (
  <TableResponsiveActionHeader>
    {!readOnly && <NewPolicyValuationButton {...props} />}
  </TableResponsiveActionHeader>
)

export { PolicyValuationsHeader }
