const TrashIcon = (props) => (
  <svg viewBox='0 0 24 24' height={24} width={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M18 8c.5522847 0 1 .44771525 1 1v10.5c0 .8284271-.6715729 1.5-1.5 1.5h-11c-.82842712 0-1.5-.6715729-1.5-1.5V9c0-.55228475.44771525-1 1-1s1 .44771525 1 1v10h10V9c0-.55228475.4477153-1 1-1zm-8 1.5c.5522847 0 1 .44771525 1 1v5c0 .5522847-.4477153 1-1 1-.55228475 0-1-.4477153-1-1v-5c0-.55228475.44771525-1 1-1zm4 0c.5522847 0 1 .44771525 1 1v5c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-5c0-.55228475.4477153-1 1-1zM13 2c1.1045695 0 2 .8954305 2 2v1h4.5c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-15c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1H9V4c0-1.1045695.8954305-2 2-2h2zm0 2h-2v1h2V4z'
      />
    </g>
  </svg>
)

export default TrashIcon
