import { ActivityLogEntryTypes } from '@elenfs/elen-constants'

export const ActivityLogEntryTypesLabels = Object.freeze({
  [ActivityLogEntryTypes.EMAIL]: 'Email',
  [ActivityLogEntryTypes.MEETING]: 'Meeting',
  [ActivityLogEntryTypes.PHONE_CALL]: 'Phone Call',
  [ActivityLogEntryTypes.POST]: 'Post',
  [ActivityLogEntryTypes.WORK_ON_FILE]: 'Work on File',
  [ActivityLogEntryTypes.AUDIT_TRAIL]: 'Audit trail'
})
