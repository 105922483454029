import { useCallback } from 'react'
import T from 'prop-types'

import { Input } from '..'

const validateInput = string => string.match(/^([0-9][0-9]?(\.[0-9]?[0-9]?)?)$|^(100\.0?0?)$|^100$|^$/)

const PercentInput = ({ handleChange: propsHandleChange, ...restProps }) => {
  const handleChange = useCallback(
    newValue => validateInput(newValue) && propsHandleChange(newValue),
    [propsHandleChange, validateInput]
  )

  return <Input {...restProps} handleChange={handleChange} />
}

PercentInput.propTypes = {
  /**
   * Function that receives a new value when it gets changed.
   */
  handleChange: T.func,
  /**
   * Value for the controlled input.
   */
  value: T.oneOfType([T.string, T.number])
}

export { PercentInput }
