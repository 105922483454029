import Card from './Card'
import CardGroup from './CardGroup'
import CardHeading from './CardHeading'
import { CardText } from './CardText'
import { CardHeader } from './CardHeader'

Card.Group = CardGroup
Card.Heading = CardHeading
Card.Text = CardText
Card.Header = CardHeader

export default Card
