export const TableHeaders = Object.freeze({
  BIRTHDAY: 'Birthday',
  TURNS: 'Turns',
  FIRST_NAME: 'First Name',
  MIDDLE_NAME: 'Middle Name',
  LAST_NAME: 'Last Name',
  KNOWN_AS: 'Known as',
  DATE_OF_BIRTH: 'Date of birth',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  CITY: 'City',
  POST_CODE: 'Post code',
  EMAIL: 'Email',
  MOBILE_PHONE: 'Mobile',
  HOME_PHONE: 'Home',
  WORK_PHONE: 'Work'
})
