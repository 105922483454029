import classnames from 'classnames'

import './RoundIcon.less'

const RoundIcon = ({ children, className, ...rest }) => {
  return (
    <div
      className={classnames('round-icon__wrapper', className)}
      {...rest}
    >
      {children}
    </div>
  )
}

export { RoundIcon }
