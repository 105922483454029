import T from 'prop-types'
import classnames from 'classnames'

import { WorkflowAction } from '../WorkflowAction'
import { NewWorkflowActionButton } from '../NewWorkflowActionButton'
import { PlusIcon } from '../../icons'

import { actionType } from './../../../helpers/propTypes'

import './WorkflowDiagramList.less'

const toggleHoverClass = (e, isAdd) => {
  const action = isAdd ? 'add' : 'remove'
  const currentEl = e.currentTarget
  const elements = [e.currentTarget, currentEl.previousSibling]
  elements.forEach(el =>
    el?.classList[action]('workflow-diagram-list__item-wrapper--hovered')
  )
}

const WorkflowDiagramList = props => {
  const {
    actions,
    onDeleteAction,
    onSummaryClick,
    isWorkflowInstance,
    onAddNewAction
  } = props

  const buttonLabel = actions.length ? 'Add new task' : 'Add your first task'

  const onMouseEnter = e => {
    if (!isWorkflowInstance) {
      toggleHoverClass(e, true)
    }
  }

  const onMouseLeave = e => {
    if (!isWorkflowInstance) {
      toggleHoverClass(e, false)
    }
  }

  return (
    <div
      className={classnames('workflow-diagram-list', {
        'workflow-diagram-list--instance': isWorkflowInstance
      })}
    >
      {actions.map((action, i) => (
        <div
          key={i}
          className={classnames('workflow-diagram-list__item-wrapper', {
            'workflow-diagram-list__item-wrapper--no-separator':
              i + 1 === actions.length
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <WorkflowAction
            {...{ action, onDeleteAction, onSummaryClick, actionIndex: i }}
          />
          <div className='workflow-diagram-list__item-wrapper__separator'>
            <div
              className='workflow-diagram-list__item-wrapper__separator__circle'
              onClick={() => onAddNewAction(i + 1)}
            >
              <PlusIcon />
            </div>
          </div>
        </div>
      ))}

      {!isWorkflowInstance && (
        <>
          {
            actions.length > 0 &&
              <div className='workflow-diagram-list__item-wrapper__separator' />
          }
          <NewWorkflowActionButton onClick={onAddNewAction}>
            {buttonLabel}
          </NewWorkflowActionButton>
        </>
      )}
    </div>
  )
}

WorkflowDiagramList.propTypes = {
  actions: T.arrayOf(actionType()).isRequired,
  onDeleteAction: T.func,
  onSummaryClick: T.func,
  onAddNewAction: T.func,
  isWorkflowInstance: T.bool
}

export { WorkflowDiagramList }
