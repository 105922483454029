const PhoneIcon = (props) => (
  <svg width='1em' height='1em' viewBox='0 0 14 14' color='#A8AAB7' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M8.07 8.073c.365-.343.707-.697 1.027-1.062a8.06 8.06 0 0 0 .788-1.045c.206-.331.343-.628.411-.89.069-.264.023-.475-.137-.635a15.334 15.334 0 0 1-.617-.65 1.532 1.532 0 0 1-.36-.686c-.056-.251-.022-.548.104-.89.125-.343.393-.766.805-1.268.388-.48.753-.771 1.096-.874.343-.103.657-.097.942.017.286.114.554.303.805.565.252.263.491.509.72.737.251.251.377.662.377 1.233 0 .571-.149 1.24-.446 2.005-.297.765-.742 1.604-1.336 2.518-.594.913-1.359 1.839-2.295 2.775-.937.96-1.862 1.736-2.776 2.33-.913.594-1.758 1.033-2.535 1.319-.776.285-1.45.428-2.021.428s-.982-.126-1.234-.377a18.969 18.969 0 0 1-.72-.685 2.654 2.654 0 0 1-.564-.805c-.126-.286-.137-.6-.035-.942.103-.343.406-.72.908-1.131.503-.388.925-.65 1.268-.788.343-.137.64-.177.89-.12.252.057.475.183.669.377l.634.634c.16.16.376.205.65.137.275-.069.577-.211.908-.428a8.497 8.497 0 0 0 1.028-.806c.354-.32.703-.65 1.045-.993z'
    />
  </svg>
)

export default PhoneIcon
