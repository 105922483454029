import {
  GoBackHeader,
  EmptyPage,
  ErrorMessage,
  Spinner,
  Filter,
  DownloadReportBtn
} from '../../../misc'
import { ReportTable } from '../../ReportTable'

import './TransactionReportPage.less'

const TransactionReportPage = props => {
  const {
    filterProps,
    loading,
    error,
    called,
    isContribution,
    downloadBtnProps,
    tableProps
  } = props

  const { rows } = tableProps

  return (
    <div className='transaction-report'>
      <GoBackHeader
        title={`Client Policy ${
          isContribution ? 'Contributions' : 'Withdrawals'
        }`}
      />

      <Filter loading={loading} {...filterProps} />

      {loading && <Spinner />}

      {!rows.length && called && !loading && !error && (
        <EmptyPage text='No records found' hidden={false} />
      )}

      {error && <ErrorMessage text='Failed to load records' />}

      {!!rows.length && (
        <>
          <div className='transaction-report__btn-container'>
            <DownloadReportBtn {...downloadBtnProps} />
          </div>

          <div className='table-wrap'>
            <ReportTable {...tableProps} />
          </div>
        </>
      )}
    </div>
  )
}

export { TransactionReportPage }
