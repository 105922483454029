export const TableHeaders = Object.freeze({
  POLICY_NUMBER: 'Policy number',
  PAID_BY: 'Paid by',
  PAID_TO: 'Paid to',
  METHOD: 'Method',
  DATE: 'Date',
  FREQUENCY: 'Frequency',
  AMOUNT: 'Amount',
  POLICY_HOLDERS: 'Policy holders'
})
