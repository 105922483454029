import constants from '@elenfs/elen-constants'

const {
  AccountHolders,
  CICInPlaceValues,
  LifeCoverInPlaceValues,
  PlanStatuses,
  RateTypes,
  RepaymentTypes,
  MortgageTypesLabels
} = constants.policy.mortgagePolicy

export const AccountHoldersLabels = Object.freeze({
  [AccountHolders.CLIENT]: 'Client',
  [AccountHolders.JOINT]: 'Joint',
  [AccountHolders.PARTNER]: 'Partner'
})

export const CICInPlaceLabels = Object.freeze({
  [CICInPlaceValues.BOTH_LIVES_PARTIALLY_COVERED]: 'Both lives partially covered',
  [CICInPlaceValues.LIFE_1_ONLY]: 'Life 1 only',
  [CICInPlaceValues.LIFE_1_PARTIALLY_COVERED_ONLY]: 'Life 1 partially covered only',
  [CICInPlaceValues.LIFE_2_ONLY]: 'Life 2 only',
  [CICInPlaceValues.LIFE_2_PARTIALLY_COVERED_ONLY]: 'Life 2 partially covered only',
  [CICInPlaceValues.NO]: 'No',
  [CICInPlaceValues.YES]: 'Yes'
})

export const LifeCoverInPlaceLabels = Object.freeze({
  [LifeCoverInPlaceValues.BOTH_LIVES_PARTIALLY_COVERED]: 'Both lives partially covered',
  [LifeCoverInPlaceValues.LIFE_1_ONLY]: 'Life 1 only',
  [LifeCoverInPlaceValues.LIFE_1_PARTIALLY_COVERED_ONLY]: 'Life 1 partially covered only',
  [LifeCoverInPlaceValues.LIFE_2_ONLY]: 'Life 2 only',
  [LifeCoverInPlaceValues.LIFE_2_PARTIALLY_COVERED_ONLY]: 'Life 2 partially covered only',
  [LifeCoverInPlaceValues.NO]: 'No',
  [LifeCoverInPlaceValues.YES]: 'Yes'
})

export const PlanStatusesLabels = Object.freeze({
  [PlanStatuses.APPLICATION_SUBMITTED]: 'Application submitted',
  [PlanStatuses.CANCELLED]: 'Cancelled',
  [PlanStatuses.DECLINED]: 'Declined',
  [PlanStatuses.LAPSED]: 'Lapsed',
  [PlanStatuses.LIVE]: 'Live',
  [PlanStatuses.NTU]: 'Not Taken Up',
  [PlanStatuses.OFFERED]: 'Offered',
  [PlanStatuses.REPAID]: 'Repaid'
})

export const RateTypesLabels = Object.freeze({
  [RateTypes.FIXED]: 'Fixed',
  [RateTypes.OFFSET]: 'Offset',
  [RateTypes.TRACKER]: 'Tracker',
  [RateTypes.VARIABLE]: 'Variable'
})

export const RepaymentTypesLabels = Object.freeze({
  [RepaymentTypes.INTEREST_ONLY]: 'Interest',
  [RepaymentTypes.REPAYMENT]: 'Repayment',
  [RepaymentTypes.REPAYMENT_AND_INTEREST_ONLY]: 'Repayment & Interest'
})

export { MortgageTypesLabels }
