const CustomerSupportInfo = () => (
  <div className='customer-support-info'>
    <div>
      We aim to respond to your query via email within 24 hours. You will
      receive email confirmation of your message along with a reference number.
    </div>
  </div>
)

export { CustomerSupportInfo }
