import { useState, useCallback, useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import { Toastr } from '../../misc'

import createESignatureRequestGql from '../../../graphql/mutations/clientDocument/createESignatureRequest.graphql'
import signDocumentGql from '../../../graphql/mutations/clientDocument/signDocument.graphql'
import downloadLinkGql from '../../../graphql/queries/clientDocument/clientDocumentDownloadLink.graphql'
import clientDocumentByESignatureRequestIDGql from '../../../graphql/queries/clientDocument/clientDocumentByESignatureRequestID.graphql'

export const useSignatureModal = props => {
  const {
    refetch: refetchDocuments,
    isClientPortal,
    docId,
    eSignatureId,
    clientId,
    dontNavigateOnCloseModal,
    onCloseModal
  } = props

  const navigate = useNavigate()

  const [isRefetching, setIsRefetching] = useState(false)
  const [signatureModalProps, setSignatureModalProps] = useState(null)
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false)

  const closeSignatureModal = useCallback(() => {
    setIsSignatureModalOpen(false)
    setSignatureModalProps(null)
    if (docId || eSignatureId) {
      if (!dontNavigateOnCloseModal) {
        navigate(
          isClientPortal
            ? '/clients/secure/overview/documents'
            : `/secure/clients/${clientId}/documents`
        )
      } else {
        if (onCloseModal) {
          onCloseModal()
        }
      }
    }
  }, [clientId, docId, eSignatureId, dontNavigateOnCloseModal, isClientPortal, onCloseModal])

  const [requestESignature, { loading: requestSignatureLoading }] = useMutation(
    createESignatureRequestGql,
    {
      onCompleted: closeSignatureModal
    }
  )

  const onRequestSignature = async ({ documentId, signerUserId }) => {
    setIsRefetching(true)
    try {
      await requestESignature({
        variables: { input: { documentId, signerUserId } }
      })
      toast(<Toastr type={Toastr.Types.SUCCESS} title='Signing request sent' />)
    } catch (error) {
      toast(
        <Toastr
          type={Toastr.Types.ERROR}
          title='Signing request failed'
          content='An error appeared during the process.'
        />
      )
    }
    await refetchDocuments()
    setIsRefetching(false)
  }

  const [signDocument] = useMutation(signDocumentGql, {
    onCompleted: async () => {
      closeSignatureModal()
      toast(<Toastr type={Toastr.Types.SUCCESS} title='Signed successfully' />)
      setIsRefetching(true)
      await refetchDocuments()
      setIsRefetching(false)
    }
  })

  const [getDownloadLink, { loading: getDownloadLinkLoading }] = useLazyQuery(
    downloadLinkGql,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ clientDocument }) =>
        setSignatureModalProps(current => ({
          ...current,
          fileName: clientDocument?.fileName,
          fileDownloadUrl: clientDocument?.downloadUrl
        }))
    }
  )

  const [
    getClientDocumentByESignatureRequestID,
    { loading: getDocumentToSignLoading }
  ] = useLazyQuery(clientDocumentByESignatureRequestIDGql, {
    fetchPolicy: 'network-only',
    onCompleted: ({ clientDocumentByESignatureRequestID }) => {
      const { id, fileName, eSignature } = clientDocumentByESignatureRequestID
      const eSignatureRequestId = eSignature?.userSignatureRequest?.id

      getDownloadLink({ variables: { documentId: id } })

      setSignatureModalProps({
        fileName,
        isClientPortal,
        onSignDocument: async ({ password }) => {
          await signDocument({ variables: { password, eSignatureRequestId } })
        }
      })
    },
    onError: () => setSignatureModalProps({ documentUnavailable: true })
  })

  const onRequestSignatureDocument = useCallback(props => {
    const documentId = props.id
    const signerUserId = props.recipient

    getDownloadLink({ variables: { documentId } })

    setSignatureModalProps({
      ...props,
      onRequestSignature: () => onRequestSignature({ documentId, signerUserId })
    })
    setIsSignatureModalOpen(true)
  }, [])

  const onClickToSign = useCallback(clientDocument => {
    const eSignatureRequestId =
      clientDocument?.eSignature?.userSignatureRequest?.id

    getClientDocumentByESignatureRequestID({
      variables: { eSignatureRequestId }
    })

    setIsSignatureModalOpen(true)
  }, [])

  useEffect(() => {
    if (eSignatureId) {
      getClientDocumentByESignatureRequestID({
        variables: { eSignatureRequestId: eSignatureId }
      })
    }

    if (docId) getDownloadLink({ variables: { documentId: docId } })
  }, [docId, eSignatureId])

  const loading =
    isRefetching ||
    requestSignatureLoading ||
    getDownloadLinkLoading ||
    getDocumentToSignLoading

  return {
    signatureModalProps,
    closeSignatureModal,
    loading,
    onRequestSignatureDocument,
    onClickToSign,
    isSignatureModalOpen
  }
}
