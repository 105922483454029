import { Card, Summary } from './../../misc'
import { SuitCaseIcon } from '../../icons'

import './CompanyDetailsCard.less'

const CompanyDetailsCard = props => {
  const {
    id,
    baseUrl,
    canEdit,
    companyName,
    dateFounded,
    companySummaries,
    contactsSummaries
  } = props

  return (
    <Card className='company-details-card' id={id}>
      <div>
        <Card.Header allowToNavigate={canEdit} baseUrl={baseUrl}>
          Company details
        </Card.Header>

        <div className='company-details-card__name-and-avatar'>
          <div className='company-details-card__name-and-avatar__avatar-container'>
            <SuitCaseIcon />
          </div>

          <div className='company-details-card__name-and-avatar__name-container'>
            <h3>
              {companyName}
              <br />
              <span> Date founded: {dateFounded} </span>
            </h3>
          </div>
        </div>

        <Summary data={companySummaries} />
      </div>

      <div>
        <Card.Header
          allowToNavigate={canEdit}
          baseUrl={baseUrl}
        >
          Contacts
        </Card.Header>

        <Summary data={contactsSummaries} />
      </div>
    </Card>
  )
}

export { CompanyDetailsCard }
