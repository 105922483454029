import T from 'prop-types'
import classnames from 'classnames'

import { useOnChangeCallback } from '../hooks'
import { CLASSNAME } from './const'

import './Input.less'

const Input = ({ className, handleChange, regex, ...rest }) => (
  <input
    className={classnames(CLASSNAME, className)}
    onChange={useOnChangeCallback(handleChange, null, regex)}
    {...rest}
  />
)

Input.defaultProps = {
  type: 'text',
  handleChange: () => {}
}

Input.propTypes = {
  /**
   * Class name to be added to the input.
   */
  className: T.string,
  /**
   * Type to be passed to the input.
   */
  type: T.string,
  /**
   * Function that receives a new value when it gets changed.
   */
  handleChange: T.func,
  /**
   * Value to be passed to the input.
   */
  value: T.any
}

export { Input }
