import constants from '@elenfs/elen-constants'
import { prop, filter, propSatisfies } from 'ramda'

import { TableHeaders } from './const'
import { buildSelectOptions } from './../../form/fields/SelectField/helpers'
import { InvestmentTypesLabels } from '../../../helpers/enumLabels/investmentPolicy'
import { PensionTypesLabels } from '../../../helpers/enumLabels/pensionPolicy'
import { formatMoney } from '../../../helpers/policy'
import {
  AllPolicyTypesLabels,
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from './../../../helpers/enumLabels/policy'

const { policy } = constants
const { InvestmentTypes } = policy.investmentPolicy
const { PensionTypes } = policy.pensionPolicy
const { PolicyCategories } = policy.common
const { INVESTMENT, PENSIONS } = PolicyCategories

const investmentTypeOptions = buildSelectOptions(
  InvestmentTypes,
  InvestmentTypesLabels
)

const pensionTypeOptions = buildSelectOptions(PensionTypes, PensionTypesLabels)

const {
  PROVIDER,
  PRODUCT,
  POLICY_TYPE,
  CURRENT_ADVISER,
  VALUE,
  POLICY_HOLDERS
} = TableHeaders

export const filterPolicyCategoryOptions = filter(
  propSatisfies(v => [INVESTMENT, PENSIONS].includes(v), 'value')
)

const buildFAs = ({ currentHolders }) =>
  currentHolders.map(
    ({ financialAdviser: fa }, i) => (
      fa && <div key={i}> {fa.fullName}</div>
    )
  )

const buildHolders = currentHolders =>
  currentHolders?.map(({ fullName }, i) => (
    <div key={i}> {fullName} </div>
  ))

export const rowSchema = [
  {
    dataLabel: POLICY_HOLDERS,
    buildData: row => buildHolders(prop('currentHolders', row))
  },
  {
    dataLabel: PROVIDER,
    buildData: row => PolicyProvidersLabels[prop('provider', row)]
  },
  {
    dataLabel: PRODUCT,
    buildData: row => PolicyCategoriesLabels[prop('category', row)]
  },
  {
    dataLabel: POLICY_TYPE,
    buildData: row => AllPolicyTypesLabels[prop('subCategory', row)]
  },
  {
    dataLabel: CURRENT_ADVISER,
    buildData: row => buildFAs(row)
  },
  {
    dataLabel: VALUE,
    buildData: row => formatMoney(prop('currentValue', row))
  }
]

export const onFilterValuesChange = (
  { category },
  setPolicySubcategoryOptions
) => {
  switch (category) {
    case INVESTMENT:
      setPolicySubcategoryOptions(investmentTypeOptions)
      break
    case PENSIONS:
      setPolicySubcategoryOptions(pensionTypeOptions)
      break
    default:
      setPolicySubcategoryOptions([])
      break
  }
}

export const tableHeaderLabels = Object.values(TableHeaders)

export const sortableTableHeaders = Object.freeze([
  {
    label: POLICY_HOLDERS,
    propPath: ['currentHolders'],
    isActive: false,
    isAscending: true
  },
  {
    label: PROVIDER,
    propPath: ['provider'],
    isActive: false,
    isAscending: true
  },
  {
    label: PRODUCT,
    propPath: ['category'],
    isActive: false,
    isAscending: true
  },
  {
    label: POLICY_TYPE,
    propPath: ['subCategory'],
    isActive: false,
    isAscending: true
  },
  {
    label: CURRENT_ADVISER
  },
  {
    label: VALUE,
    propPath: ['currentValue', 'amount'],
    isActive: false,
    isAscending: true
  }
])
