const FlagIcon = props => (
  <svg {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M16 16V0H0v16z' />
      <path
        d='M13.21 2.246a4.478 4.478 0 00-.769-.334c-1.395-.477-2.94-.477-4.462.322-1.205.632-2.426.632-3.543.25a4.777 4.777 0 01-.27-.1v-.8a.583.583 0 00-1.166 0v12.833a.583.583 0 101.167 0v-4.95c1.367.442 2.87.422 4.354-.356 1.205-.633 2.426-.633 3.543-.251.275.094.468.185.56.238a.583.583 0 00.876-.504V2.75c0-.207-.11-.4-.29-.504zm-.877 5.474c-1.367-.44-2.87-.42-4.354.358-1.205.632-2.426.632-3.543.25a4.765 4.765 0 01-.27-.1V3.623c1.368.441 2.872.421 4.355-.357 1.205-.633 2.426-.633 3.542-.251.102.035.192.069.27.101V7.72z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { FlagIcon }
