import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { AssetsUnderManagementCard } from './AssetsUnderManagementCard'
import { DoughnutTab } from './DoughnutTab'

import { options, initialData, mapDataToState, filterTabs } from './helpers'

import AUMTotalsGql from '../../../graphql/queries/reports/AUMTotals.graphql'

const AssetsUnderManagementCardContainer = () => {
  const [myClientsData, setMyClientsData] = useState()
  const [myClientsEmpty, setMyClientsEmpty] = useState()

  const [allClientsData, setAllClientsData] = useState()
  const [allClientsEmpty, setAllClientsEmpty] = useState()

  const { data, loading } = useQuery(AUMTotalsGql, {
    fetchPolicy: 'cache-and-network'
  })

  const initialLoading = loading && !data

  const { allClients, myClients } = data?.AUMTotals || {}

  useEffect(() => {
    if (myClients) {
      mapDataToState(myClients, setMyClientsData, setMyClientsEmpty)
    }
    if (allClients) {
      mapDataToState(allClients, setAllClientsData, setAllClientsEmpty)
    }
  }, [allClients, myClients])

  const tabs = [
    {
      label: 'My clients',
      isMyClients: true,
      TabComponent: (
        <DoughnutTab
          data={myClientsData || initialData}
          options={options}
          isEmpty={initialLoading || myClientsEmpty}
        />
      )
    },
    {
      label: 'All clients',
      isAllClients: true,
      TabComponent: (
        <DoughnutTab
          data={allClientsData || initialData}
          options={options}
          isEmpty={initialLoading || allClientsEmpty}
        />
      )
    }
  ]

  return (
    <AssetsUnderManagementCard
      tabs={filterTabs(tabs, allClients, myClients, initialLoading)}
    />
  )
}

export { AssetsUnderManagementCardContainer }
