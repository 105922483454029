import * as yup from 'yup'

import { stringSchema, dateSchema, endDateSchema } from '../helpers'

export default yup.object().shape({
  dependants: yup.array().of(
    yup.object().shape({
      id: stringSchema(),
      firstName: stringSchema().label('Dependants section: First Name').required(),
      lastName: stringSchema().label('Dependants section: Last Name').required(),
      dateOfBirth: dateSchema().label('Dependants section: dateOfBirth'),
      relationship: stringSchema().label('Dependants section: Relationship'),
      dependantFrom: dateSchema().label('Dependants section: Dependant From'),
      dependantUntil: endDateSchema(
        'dependantFrom',
        'Dependant Until date, must be after Dependant From date'
      )
        .label('Dependants section: Dependant Until'),
      note: stringSchema().label('Dependants section: Additional notes and comments')
    })
  ).default([])
})
