import { useState, useEffect } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useStripe } from '@stripe/react-stripe-js'
import { SubscriptionStatuses } from '@elenfs/elen-constants'
import { useLocation } from 'react-router-dom'

import {
  getTrialDaysRemaining,
  getBillingDateAfterTrial,
  buildSubscriptionOptions,
  buildSubscriptionCard
} from '../../../../helpers/stripe'
import { splitToActiveAndInactiveByStatus } from './../../../../helpers/users'

import { LicenseTypeLabels } from './../../../admin/AccountOverview/SubscriptionPlan/const'

import usersGql from '../../../../graphql/queries/user/users.graphql'
import enabledUserCountGql from '../../../../graphql/queries/user/enabledUserCount.graphql'
import subscriptionStatusGql from '../../../../graphql/queries/stripeSubscriptions/subscriptionStatus.graphql'
import subscriptionProductGql from '../../../../graphql/queries/stripeSubscriptions/subscriptionProduct.graphql'
import hasActiveSubscriptionGql from '../../../../graphql/queries/stripeSubscriptions/hasActiveSubscription.graphql'
import createStripeSubscriptionGql from '../../../../graphql/mutations/stripeSubscriptions/createStripeSubscription.graphql'

const { TRIALING, PAYMENT_PAST_DUE, TRIALING_AWAITING_SUBSCRIPTION } = SubscriptionStatuses

const withPaymentProvider = () => (WrappedComponent) => (props) => {
  const stripe = useStripe()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const waiting = urlParams.get('waiting')

  const [trialDays, setTrialDays] = useState(0)
  const [showWarningBanner, setShowWarningBanner] = useState(false)

  const {
    data: hasActiveSubscriptionData,
    loading: hasActiveSubscriptionLoading
  } = useQuery(hasActiveSubscriptionGql, {
    fetchPolicy: 'network-only'
  })

  const {
    data: subscriptionStatusData
  } = useQuery(subscriptionStatusGql)

  const [getUsers, {
    data: usersData
  }] = useLazyQuery(usersGql)

  const [getSubscriptionProduct, {
    data: productData,
    loading: productLoading
  }] = useLazyQuery(subscriptionProductGql)

  const [getEnabledUserCount, {
    data: enabledUserCountData,
    loading: enabledUserCountLoading
  }] = useLazyQuery(enabledUserCountGql)

  const [createStripeSubscription, {
    data: createSubscriptionData,
    loading: createSubscriptionLoading,
    error: createSubscriptionError
  }] = useMutation(createStripeSubscriptionGql)

  const loading = hasActiveSubscriptionLoading || productLoading || enabledUserCountLoading

  const {
    trialPeriodDays,
    trialStartedAt,
    status,
    interval
  } = subscriptionStatusData?.activeSubscription || {}

  const subscriptionIntervalLabel = LicenseTypeLabels[interval]

  const { activeUserList } = splitToActiveAndInactiveByStatus(usersData?.users)

  const awaitingSubscriptionProps = {
    onSubmit: async ({ plan: priceId, seats: quantity }) => {
      const { data } = await createStripeSubscription({
        variables: {
          input: {
            returnToUrl: window.location.href,
            order: { priceId, quantity }
          }
        }
      })
      if (data?.createStripeSubscription?.stripeCheckoutSessionId) {
        stripe.redirectToCheckout({
          sessionId: data.createStripeSubscription.stripeCheckoutSessionId
        })
      }
    },
    mutationLoading: createSubscriptionLoading,
    mutationError: createSubscriptionError
      ? 'Something went wrong'
      : '',
    activeUsers: activeUserList?.length,
    planOptions: productData
      ? buildSubscriptionOptions(productData.subscriptionProduct)
      : []
  }

  const handleSelectPlan = async (priceId) => {
    const { data } = await createStripeSubscription({
      variables: {
        input: {
          returnToUrl: `${window.location.href}?waiting=true`,
          order: { priceId, quantity: enabledUserCountData ? enabledUserCountData.count : 1 }
        }
      }
    })

    if (data?.createStripeSubscription?.stripeCheckoutSessionId) {
      stripe.redirectToCheckout({
        sessionId: data.createStripeSubscription.stripeCheckoutSessionId
      })
    }
  }

  useEffect(() => {
    if (props?.currentUser?.isAccountOwner) {
      const showBanner = [
        TRIALING, PAYMENT_PAST_DUE,
        TRIALING_AWAITING_SUBSCRIPTION
      ].includes(status)

      getSubscriptionProduct()
      getEnabledUserCount()

      if (showBanner) {
        getUsers()
        setTrialDays(getTrialDaysRemaining({
          trialPeriodDays,
          trialStartedAt,
          status
        }))
      }
      setShowWarningBanner(showBanner)
    }
  }, [subscriptionStatusData])

  const SubscribePageProps = {
    ...{
      handleSelectPlan,
      planOptions: productData
        ? buildSubscriptionCard(productData.subscriptionProduct)
        : [],
      loading: createSubscriptionLoading,
      data: createSubscriptionData,
      currentUser: props?.currentUser
    }
  }

  const SecureSectionProps = {
    ...{
      ...props,
      showWarningBanner,
      trialDays,
      billingDateAfterTrial: getBillingDateAfterTrial({
        trialPeriodDays,
        trialStartedAt,
        status
      }),
      closeWarningBanner: () => setShowWarningBanner(false),
      subscriptionStatus: status,
      awaitingSubscriptionProps,
      subscriptionIntervalLabel
    }
  }

  return (
    <WrappedComponent {...{
      waiting,
      loading,
      error: createSubscriptionError,
      hasActiveSubscription: hasActiveSubscriptionData?.hasActiveSubscription,
      isAccountOwner: props?.currentUser?.isAccountOwner,
      SubscribePageProps,
      SecureSectionProps
    }}
    />
  )
}

export { withPaymentProvider }
