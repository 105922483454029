import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'

const FileUploadTrackerHeader = ({
  isCloseButtonVisible,
  onCloseButtonClick
}) => (
  <div className='file-upload-tracker__header'>
    <span>Document uploads</span>
    <Button
      className={classnames('file-upload-tracker__header__button', {
        hidden: !isCloseButtonVisible
      })}
      onClick={onCloseButtonClick}
    >
      Close
    </Button>
  </div>
)

FileUploadTrackerHeader.defaultProps = {
  isCloseButtonVisible: false
}

FileUploadTrackerHeader.propTypes = {
  isCloseButtonVisible: PropTypes.bool,
  onCloseButtonClick: PropTypes.func.isRequired
}

export default FileUploadTrackerHeader
