import { useEffect } from 'react'
import { compose } from 'ramda'

import { withUnreadMessagesSubscription, withCurrentUser } from '../../../hoc/container'

import SectionNav from '../../../nav/SectionNav'

const MESSAGES_LABEL = 'Messages'

const items = [
  { label: 'Dashboard', path: 'dashboard' },
  { label: 'Fact Find', path: 'fact-find' },
  { label: 'Policies', path: 'policies' },
  { label: 'Documents', path: 'documents' },
  { label: MESSAGES_LABEL, path: 'messages' }
]

const ClientPortalSectionNav = ({ subscribeForUnreadMessages, totalUnreadMessages, ...props }) => {
  useEffect(() => {
    let unsubscribe
    if (subscribeForUnreadMessages) {
      unsubscribe = subscribeForUnreadMessages()
    }
    return () => unsubscribe && unsubscribe()
  }, [subscribeForUnreadMessages])

  const messageItem = items.find(({ label }) => label === MESSAGES_LABEL)
  messageItem.clientTotalUnreadMessages = totalUnreadMessages
  messageItem.isMessage = true

  return <SectionNav items={items} {...props} />
}

const EnhancedClientPortalSectionNav = compose(
  withCurrentUser(),
  withUnreadMessagesSubscription()
)(ClientPortalSectionNav)

export { EnhancedClientPortalSectionNav as ClientPortalSectionNav }
