import { Modal } from '..'

import { ActivateSubscriptionForm } from './ActivateSubscriptionForm'

import './ActivateSubscriptionModal.less'

const ActivateSubscriptionModal = ({ trigger, isOpen, close, ...props }) => (
  <Modal {...{ isOpen, close, trigger }}>
    <Modal.Heading>Activate subscription</Modal.Heading>
    <ActivateSubscriptionForm {...{ ...props, onDone: close }} />
  </Modal>
)

export { ActivateSubscriptionModal }
