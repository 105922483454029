import { useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = window.requestAnimationFrame(() => setEnabled(true))

    return () => {
      window.cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}
