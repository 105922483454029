import { useMemo, useCallback } from 'react'
import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import useFormState from '../../../hooks/useFormState'
import ForgotPasswordForm from './ForgotPasswordForm'
import { forgotPassword } from '../../../auth'

import './forgotPasswordForm.less'

const { UserTypes } = constants.user

const ForgotPasswordFormContainer = (props) => {
  const { userType } = props
  const [formState, setFormState] = useFormState()

  const goBackLinkPath = useMemo(() => userType === UserTypes.CLIENT ? '/clients/login' : '/login', [userType])

  const handleForgotPassword = useCallback((email) => {
    setFormState({ ...formState, loading: true })

    forgotPassword(email, userType)
      .then(() => setFormState({ ...formState, success: true, loading: false }))
      .catch(error => setFormState({ ...formState, error: error.message, loading: false }))
  }, [userType, setFormState])

  const { loading, error, success } = formState

  return (
    <ForgotPasswordForm
      {...{
        loading,
        error,
        success,
        handleForgotPassword,
        goBackLinkPath
      }}
    />
  )
}

ForgotPasswordFormContainer.defaultProps = {
  userType: UserTypes.COMPANY_USER
}

ForgotPasswordFormContainer.propTypes = {
  userType: T.oneOf(Object.values(UserTypes))
}

export default ForgotPasswordFormContainer
