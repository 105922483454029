import { Fragment } from 'react'
import constants from '@elenfs/elen-constants'

import {
  InputField,
  SelectField,
  DateField,
  MoneyField,
  RadioGroupField
} from '../../../form/fields'
import { FormGroup } from '../../../form/Form/FormGroup'
import { SharedPolicyFormFields } from '../SharedPolicyFormFields'

import {
  DeathBenefitsLabels,
  GuaranteesLabels,
  PCLSEntitlementsLabels,
  PensionTypesLabels,
  ProtectionsLabels,
  PlanStatusesLabels
} from '../../../../helpers/enumLabels/pensionPolicy'
import { buildSelectOptions } from '../../../form/fields/SelectField'
import { buildBooleanSelectBoxOptions } from '../../../../helpers/form'

const { PlanStatuses } = constants.policy.pensionPolicy
const {
  DeathBenefits,
  Guarantees,
  PCLSEntitlements,
  PensionTypes,
  Protections
} = constants.policy.pensionPolicy

const PensionPolicyDetailsForm = ({ currentClient, companyClients }) => (
  <>
    <div className='policy-type-form pension-policy-form'>
      <SharedPolicyFormFields
        {...{
          companyClients,
          currentClient,
          policyTypeLabel: 'Pension type',
          policyTypeName: 'pensionType',
          policyTypeOptions: buildSelectOptions(
            PensionTypes,
            PensionTypesLabels
          ),
          planStatusOptions: buildSelectOptions(
            PlanStatuses,
            PlanStatusesLabels
          )
        }}
      />

      <FormGroup label='Dates'>
        <div className='policy-type-form__row policy-type-form__row--4'>
          <DateField
            name='applicationDate'
            label='Application date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='startDate'
            label='Start date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='maturityDate'
            label='Maturity date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>
      </FormGroup>

      <FormGroup label='Transfer value'>
        <div className='policy-type-form__row policy-type-form__row--4'>
          <MoneyField name='transferValue' label='Transfer value' />

          <DateField
            name='transferDate'
            label='Transfer Date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>
      </FormGroup>

      <FormGroup label='Other details'>
        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-waiver'
            options={buildBooleanSelectBoxOptions()}
            name='waiver'
            groupLabel='Waiver'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--1-3'>
          <InputField
            name='selectedRetirementAge'
            label='Retirement age'
            inputProps={{
              maxLength: 2,
              onKeyPress: event => {
                const parsedKey = parseInt(event.key)
                const parsedValue = parseInt(event.target.value)

                if (
                  // don't allow 0's if they're the first digit
                  (isNaN(parsedValue) && !parsedKey) ||
                  isNaN(parsedKey)
                ) {
                  event.preventDefault()
                }
              }
            }}
          />

          <div className='policy-type-form__row policy-type-form__row--2'>
            <SelectField
              name='deathBenefit'
              label='Death benefit'
              options={buildSelectOptions(DeathBenefits, DeathBenefitsLabels)}
            />

            <MoneyField
              name='additionalLifeCover'
              label='Additional life cover'
            />
          </div>
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-plc-entitlement'
            options={buildSelectOptions(
              PCLSEntitlements,
              PCLSEntitlementsLabels
            )}
            name='pclsEntitlement'
            groupLabel='PCLS Entitlement'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--3'>
          <SelectField
            name='protection'
            label='Protection'
            options={buildSelectOptions(Protections, ProtectionsLabels)}
          />

          <SelectField
            name='guarantees'
            label='Guarantees'
            options={buildSelectOptions(Guarantees, GuaranteesLabels)}
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-earmarking'
            options={buildBooleanSelectBoxOptions()}
            name='earmarking'
            groupLabel='Earmarking / Pension sharing order'
            isTextButton
          />
        </div>
      </FormGroup>
    </div>
  </>
)

export default PensionPolicyDetailsForm
