import { isEmpty } from 'ramda'
import classnames from 'classnames'

import {
  ClientIncomeFrequencyLabels,
  ClientIncomeTypeLabels,
  ClientExpensePriorityLabels
} from '@elenfs/elen-constants'
import {
  EmploymentStatusLabels,
  ClientTaxRatesLabels,
  BooleanLabels,
  GenderLabels,
  MaritalStatusLabels
} from '../../../helpers/enumLabels'

import { formatDateAndTime, formatDate } from './../../../helpers/date'
import { formatMoney } from './../../../helpers/policy'
import { formatBoolean } from './../../../helpers/misc'

export const AuditLogFooterInfo = ({ auditLogEntry, className }) => {
  if (!auditLogEntry) {
    return null
  }

  return (
    <div className={classnames(`${className}__footer`)}>
      <span>{auditLogEntry?.client?.fullName}</span>
      <span>Client</span>
      <span>{formatDateAndTime(auditLogEntry?.updatedAt)}</span>
    </div>
  )
}

const simpleCategoryMap = {
  // Personal
  title: 'personal',
  firstName: 'personal',
  middleName: 'personal',
  lastName: 'personal',
  gender: 'personal',
  knownAs: 'personal',
  dateOfBirth: 'personal',
  placeOfBirth: 'personal',
  maritalStatus: 'personal',
  address1: 'personal',
  address2: 'personal',
  city: 'personal',
  postCode: 'personal',
  mobile: 'personal',
  workTel: 'personal',
  homeTel: 'personal',

  // Employment
  ukDomiciled: 'employment',
  ukResidentForTax: 'employment',
  niNumber: 'employment',
  employmentStatus: 'employment',
  occupation: 'employment',
  employer: 'employment',
  timeEmployed: 'employment',
  annualGrossSalary: 'employment',
  partTimeEmployees: 'employment',
  highestTaxRatePaid: 'employment',
  desiredRetirementAge: 'employment',
  childBenefitReduced: 'employment',
  employmentNotesAndComments: 'employment',

  // Company details
  name: 'companyDetails',
  tradingName: 'companyDetails',
  legalEntityIdentifier: 'companyDetails',
  leiExpiryDate: 'companyDetails',
  legalStatus: 'companyDetails',
  natureOfBusiness: 'companyDetails',
  fullTimeEmployees: 'companyDetails',
  dateFounded: 'companyDetails',
  landlineTel: 'companyDetails',

  // Objectives and Actions
  objectives: 'objectives',
  actions: 'actions',

  // Income
  incomeNotesAndComments: 'incomes',
  totalMonthlyIncome: 'incomes',

  // Expenditure
  expenditureNotesAndComments: 'expenses',
  totalMonthlyExpense: 'expenses',

  // Assets
  assetsNotesAndComments: 'assets',

  // Liabilities
  liabilitiesNotesAndComments: 'liabilities'
}

const complexCategoryMap = {
  dependants: 'dependants',
  assets: 'assets',
  liabilities: 'liabilities',
  incomes: 'incomes',
  expenses: 'expenses',
  profitAndLoss: 'profitAndLoss'
}

const pairPrevNextDifferences = (prev, next) => {
  const changes = []

  if (prev && prev?.length) {
    prev.forEach(prevItem => {
      if (prevItem?.docId) {
        const changedItem = next.find(nextItem => nextItem?.docId === prevItem?.docId)
        if (changedItem) {
          changes.push({ prev: prevItem, next: changedItem })
        } else {
          changes.push({ prev: prevItem, next: null })
        }
      }
    })
  }

  if (next && next?.length) {
    next.forEach(nextItem => {
      if (!nextItem?.docId) {
        changes.push({ prev: null, next: nextItem })
      }
    })
  }

  return changes
}

export const differenceFromPrevNext = (prev, next) => {
  if (!prev || !next) {
    return
  }

  const factFindCategories = {
    personal: {},
    dependants: [],
    employment: {},
    companyDetails: {},
    profitAndLoss: [],
    objectives: {},
    actions: {},
    incomes: [],
    expenses: [],
    assets: [],
    liabilities: []
  }

  for (const item in prev) {
    if (!Array.isArray(prev[item]) && prev[item] !== next[item]) {
      factFindCategories[simpleCategoryMap[item]][item] = {
        prev: prev[item],
        next: next[item]
      }
    } else if (Array.isArray(prev[item]) && (!isEmpty(prev[item]) || !isEmpty(next[item]))) {
      const diffs = pairPrevNextDifferences(prev[item], next[item])
      factFindCategories[complexCategoryMap[item]].push(...diffs)
    }
  }

  return factFindCategories
}

export const factFindFieldLabels = {
  // Personal
  title: 'Title',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  gender: 'Gender',
  knownAs: 'Known as',
  dateOfBirth: 'Date of birth',
  placeOfBirth: 'Place of birth',
  maritalStatus: 'Marital status',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  postCode: 'Post code',
  mobile: 'Mobile',
  workTel: 'Work',
  homeTel: 'Home',

  // Dependants
  relationship: 'Relationship',
  dependantFrom: 'Dependant from',
  dependantUntil: 'Dependant until',
  note: 'Additional notes and comments',

  // Employment
  ukDomiciled: 'UK domiciled',
  ukResidentForTax: 'UK resident for tax',
  niNumber: 'NI number',
  employmentStatus: 'Employment status',
  occupation: 'Occupation',
  employer: 'Employer',
  timeEmployed: 'Time employed',
  annualGrossSalary: 'Annual gross salary',
  partTimeEmployees: 'Part time employees',
  highestTaxRatePaid: 'Highest rate of tax paid',
  desiredRetirementAge: 'Desired retirement age',
  childBenefitReduced: 'Child benefit reduced',
  employmentNotesAndComments: 'Employment notes and comments',

  // Company details
  name: 'Name',
  tradingName: 'Trading name',
  legalEntityIdentifier: 'Legal entity identifier',
  leiExpiryDate: 'Lei expiry date',
  legalStatus: 'Legal status',
  natureOfBusiness: 'Nature of business',
  fullTimeEmployees: 'Full time employees',
  dateFounded: 'Date founded',
  landlineTel: 'Landline',
  yearEndDate: 'Year and date',

  // Objectives and Actions
  objectives: 'Objectives',
  actions: 'Agreed actions',

  frequency: 'Frequency',
  // Income
  incomeNotesAndComments: 'Income notes and comments',
  totalMonthlyIncome: 'Total monthly income',
  source: 'Source',
  netAmount: 'Net',
  grossAmount: 'Gross',
  type: 'Type',

  // Expenditure
  expenditureNotesAndComments: 'Expenditure notes and comments',
  totalMonthlyExpense: 'Total monthly expense',
  expense: 'Expense',
  amount: 'Amount',
  priority: 'Priority',
  value: 'Value',

  owner: 'Owner',
  // Assets
  assetsNotesAndComments: 'Assets notes and comments',
  description: 'Asset description',

  // Liabilities
  liabilitiesNotesAndComments: 'Liabilities notes and comments',
  outstandingValue: 'Outstanding value',
  monthlyCost: 'Monthly cost',
  startDate: 'Start date',
  endDate: 'End date',
  liabilityType: 'Liability type'
}

export const factFindFieldFormat = {
  // Personal
  title: null,
  firstName: null,
  middleName: null,
  lastName: null,
  gender: v => GenderLabels[v],
  knownAs: null,
  dateOfBirth: formatDate,
  placeOfBirth: null,
  maritalStatus: v => MaritalStatusLabels[v],
  address1: null,
  address2: null,
  city: null,
  postCode: null,
  mobile: null,
  workTel: null,
  homeTel: null,

  relationship: null,
  dependantFrom: formatDate,
  dependantUntil: formatDate,
  note: null,

  // Employment
  ukDomiciled: formatBoolean,
  ukResidentForTax: formatBoolean,
  niNumber: null,
  employmentStatus: v => EmploymentStatusLabels[v],
  occupation: null,
  employer: null,
  timeEmployed: null,
  annualGrossSalary: formatMoney,
  partTimeEmployees: null,
  highestTaxRatePaid: v => ClientTaxRatesLabels[v],
  desiredRetirementAge: null,
  childBenefitReduced: v => BooleanLabels[v],
  employmentNotesAndComments: null,

  // Company details
  name: null,
  tradingName: null,
  legalEntityIdentifier: null,
  leiExpiryDate: formatDate,
  legalStatus: null,
  natureOfBusiness: null,
  fullTimeEmployees: null,
  dateFounded: formatDate,
  landlineTel: null,
  yearEndDate: formatDate,

  // Objectives and Actions
  objectives: null,
  actions: null,

  // Income
  incomeNotesAndComments: null,
  totalMonthlyIncome: null,
  source: null,
  netAmount: formatMoney,
  grossAmount: formatMoney,
  frequency: v => ClientIncomeFrequencyLabels[v],
  type: v => ClientIncomeTypeLabels[v],

  // Expenditure
  expenditureNotesAndComments: null,
  totalMonthlyExpense: null,
  expense: null,
  amount: formatMoney,
  priority: v => ClientExpensePriorityLabels[v],
  value: formatMoney,

  // Assets
  assetsNotesAndComments: null,
  owner: null,
  description: null,

  // Liabilities
  liabilitiesNotesAndComments: null,
  outstandingValue: formatMoney,
  monthlyCost: formatMoney,
  startDate: formatDate,
  endDate: formatDate,
  liabilityType: null
}

export const factFindCategoryLabels = {
  personal: 'Personal',
  dependants: 'Dependants',
  employment: 'Employment',
  companyDetails: 'Company details',
  profitAndLoss: 'Profit and Loss',
  objectives: 'Objectives',
  actions: 'Actions',
  incomes: 'Incomes',
  expenses: 'Expenses',
  assets: 'Assets',
  liabilities: 'Liabilities'
}
