import * as yup from 'yup'
import stringSchema from '../helpers/stringSchema'

const praemiumSchema = yup.object().shape({
  firmRefNo: stringSchema()
    .label('firmRefNo')
    .required(),

  username: stringSchema()
    .label('username')
    .required(),

  password: stringSchema()
    .label('password')
    .required()
})

export { praemiumSchema }
