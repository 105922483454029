import { useState } from 'react'
import classnames from 'classnames'
import T from 'prop-types'

import { Modal, HTMLPreview } from '../../misc'
import { FromToTooltip } from './FromToTooltip'
import { EmailAttachments } from './EmailAttachments'
import { ChevronLeftIcon, UserIcon } from '../../icons'
import { formatDate } from '../../../helpers/date'
import { resolveEmailParticipants } from './helpers'

import './AuditLogEntryEmail.less'

const AuditLogEntryEmail = props => {
  const {
    participants,
    subject,
    body,
    attachments,
    onDownloadFile,
    date,
    id,
    className
  } = props
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const { sender, recipient, cc, bcc } = resolveEmailParticipants(participants)
  const senderText = sender?.name || sender?.email || ''

  return (
    <div className={classnames('audit-log-entry-email', className)}>
      <Modal.Heading>{subject}</Modal.Heading>

      <div className='audit-log-entry-email__header'>
        <div className='audit-log-entry-email__header__icon-wrapper'>
          <UserIcon />
        </div>

        <div className='audit-log-entry-email__header__from-to'>
          <div className='audit-log-entry-email__header__from-to__from'>
            {senderText}
          </div>
          <FromToTooltip {...{
            setIsTooltipShown,
            sender,
            recipient,
            cc,
            bcc
          }}
          >
            <ChevronLeftIcon
              className={classnames(
                'audit-log-entry-email__header__from-to__toggler',
                {
                  'audit-log-entry-email__header__from-to__toggler--shown': isTooltipShown
                }
              )}
            />
          </FromToTooltip>
        </div>

        <div className='audit-log-entry-email__header__date'>
          {formatDate(date)} | {formatDate(date, null, 'H:mm')}
        </div>
      </div>

      {
        body
          ? <HTMLPreview
              body={body}
              className='audit-log-entry-email__body'
            />
          : <div className='audit-log-entry-email__body' />
      }
      {
        attachments
          ? (
            <div className='audit-log-entry-email__body__attachments'>
              <EmailAttachments
                id={id}
                attachments={attachments}
                onDownloadFile={onDownloadFile}
              />
            </div>
            )
          : null
      }
    </div>
  )
}

AuditLogEntryEmail.propTypes = {
  subject: T.string.isRequired,
  body: T.string.isRequired,
  attachments: T.arrayOf(
    T.shape({ fileName: T.string, id: T.string, onDownload: T.func })
  ),
  date: T.string
}

export { AuditLogEntryEmail }
