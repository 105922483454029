import { Draggable } from 'react-beautiful-dnd'

import { useMobileScreen } from '../../../../hooks'
import { TaskCard } from '../../../misc'

const TasksKanbanColumnContent = ({ tasks, ...props }) => {
  const { providedPlaceholder } = props
  const { isMobile } = useMobileScreen()

  return (
    <>
      {tasks?.map((task, index) => (
        <Draggable
          key={`${task.status}-${task.id}`}
          draggableId={task?.id?.toString()}
          index={index}
          isDragDisabled={isMobile}
        >
          {provided => (
            <TaskCard
              key={task.id}
              {...{
                task,
                props,
                draggableRef: provided.innerRef,
                draggableProps: {
                  ...provided.dragHandleProps,
                  ...provided.draggableProps
                }
              }
              }
            />
          )}
        </Draggable>
      ))}
      {providedPlaceholder}
    </>
  )
}

export { TasksKanbanColumnContent }
