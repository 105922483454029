import { useCallback } from 'react'
import T from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { UserDetailsForm } from './UserDetailsForm'
import inviteUserGql from '../../../../../graphql/mutations/user/inviteUser.graphql'
import updateUserGql from '../../../../../graphql/mutations/user/updateUser.graphql'
import usersGql from '../../../../../graphql/queries/user/users.graphql'
import { withCurrentUser } from '../../../../hoc/container'
import { Toastr } from '../../../../misc'
import { userType } from '../../../../../helpers/propTypes'
import financialAdvisersGql from '../../../../../graphql/queries/user/financialAdvisers.graphql'

const useInviteUserMutation = () =>
  useMutation(inviteUserGql, {
    update: (cache, { data: { inviteCompanyUser: newUser } }) => {
      try {
        const { users, subscriptionSeats } = cache.readQuery({
          query: usersGql
        })
        cache.writeQuery({
          query: usersGql,
          data: {
            users: users.concat([newUser]),
            subscriptionSeats
          }
        })
      } catch (e) {
        // TODO: report to the logging software
        console.error('Error updating cache in UserDetailsFormContainer', e.message)
      }
    }
  })

const useUpdateUserMutation = () => useMutation(updateUserGql)

const UserDetailsFormContainer = (props) => {
  const { clientId } = useParams()
  const { user, onDone, currentUser, ...restProps } = props
  const [mutate, mutationState] = user ? useUpdateUserMutation() : useInviteUserMutation()

  const {
    loading, error, data
  } = useQuery(
    financialAdvisersGql,
    { variables: { clientId }, fetchPolicy: 'cache-and-network' }
  )

  const foundData = (loading || error) ? null : data.users

  let adviserOptionsList = []
  const adviserOptionsListWithOwnClients = [
    {
      label: 'Own clients',
      value: '',
      isFixed: true
    }
  ]

  if (foundData) {
    adviserOptionsList = foundData.map(({ fullName, id }) => ({
      id,
      key: id,
      label: fullName,
      value: id
    }))
    if (user) {
      adviserOptionsListWithOwnClients.push(...adviserOptionsList.filter(item => !user.id.includes(item.key)))
    } else {
      adviserOptionsListWithOwnClients.push(...adviserOptionsList)
    }
  }

  const onSubmit = useCallback((values) => mutate({ variables: { input: values } }), [mutate])

  const onDoneCallback = useCallback(({ userUpdated, emailChanged }) => {
    onDone()

    if (!userUpdated) {
      toast(
        <Toastr type='success' title='New user successfully added' />
      )
    } else if (emailChanged) {
      toast(
        <Toastr type='success' title='User informed of email change' />
      )
    }
  }, [onDone])

  return (
    <UserDetailsForm
      user={user}
      currentUser={currentUser}
      onSubmit={onSubmit}
      onDone={onDoneCallback}
      loading={mutationState.loading}
      companyFinancialAdvisers={adviserOptionsList}
      companyFinancialAdvisersWithOwnClients={adviserOptionsListWithOwnClients}
      {...restProps}
    />
  )
}

UserDetailsFormContainer.propTypes = {
  /**
   * User that's being edited.
   */
  user: userType(),
  /**
   * onDone callback. It can close a modal, for example.
   */
  onDone: T.func.isRequired
}

const UserDetailsFormContainerWithCurrUser = withCurrentUser()(UserDetailsFormContainer)

export { UserDetailsFormContainerWithCurrUser as UserDetailsFormContainer }
