import { InputField, MoneyField, SelectField } from '../../../form/fields'

const ExpenditureFormInputRow = props => {
  const { index, frequencyOptions, priorityOptions } = props

  return (
    <div className='expense-input-row'>
      <div className='expense-input-row__money-row'>
        <InputField
          name={`expenses.${index}.expense`}
          label='Expense'
          required
          inputProps={{ maxLength: 50 }}
        />

        <MoneyField
          name={`expenses.${index}.amount`}
          label='Amount'
          required
          inputProps={{
            placeholder: '0.00'
          }}
        />
      </div>

      <div className='expense-input-row__options-row'>
        <SelectField
          name={`expenses.${index}.frequency`}
          label='Frequency'
          options={frequencyOptions}
        />

        <SelectField
          name={`expenses.${index}.priority`}
          label='Priority'
          options={priorityOptions}
        />
      </div>
    </div>
  )
}

export default ExpenditureFormInputRow
