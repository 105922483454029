import SectionNav from '../../nav/SectionNav'

const items = [
  { label: 'Pending', path: 'pending' },
  { label: 'Received', path: 'received' }
]

const IncomeNav = (props) => <SectionNav items={items} {...props} />

export { IncomeNav }
