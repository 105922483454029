import { useState, useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import T from 'prop-types'
import classnames from 'classnames'

import { PlusIcon, ChevronRightIcon, TasksIcon, AccountPlus } from '../../icons'
import { ImportClientModal, DropdownMenu, Button } from '../../misc'
import { withCashCalcConnection } from '../../hoc/container'
import LogActivityButton from '../../auditLogEntry/LogActivityButton'
import { useImportClientCashCalcModal } from '../../../hooks'
import { TaskModalButton } from '../../tasks/TaskModalButton'

import './GlobalCreateButton.less'

const windowEventsToListen = ['click', 'touchstart']

const ImportCashCalcClientModalTrigger = ({ refetchClients, className }) => (
  <ImportClientModal.Trigger
    className={className}
    as={DropdownMenu.Item}
    modalProps={{
      importClientModalHook: useImportClientCashCalcModal,
      refetch: refetchClients
    }}
  >
    Import from CashCalc
  </ImportClientModal.Trigger>
)

const GlobalCreateButton = ({
  refetchClients,
  isCashCalcConnectionActive,
  direction,
  buttonColor
}) => {
  const wrapperEl = useRef(null)
  const [isToggleOpen, setIsToggleOpen] = useState(false)
  const navigate = useNavigate()
  const navigateToNewIndividualClient = () => navigate('/secure/clients/create/individual')
  const navigateToNewCorporateClient = () => navigate('/secure/clients/create/corporate')
  const handleToggleAction = useCallback((e) => {
    e.stopPropagation()
    setIsToggleOpen(!isToggleOpen)
  }, [isToggleOpen])

  const handleWindowClick = useCallback(
    (e) => {
      const { current } = wrapperEl
      const { target } = e
      if ((target !== current) && !current.contains(target)) {
        setIsToggleOpen(false)
      }
    },
    [isToggleOpen]
  )

  useEffect(() => {
    windowEventsToListen.forEach(event =>
      window.addEventListener(event, handleWindowClick))

    return () => {
      windowEventsToListen.forEach(event =>
        window.removeEventListener(event, handleWindowClick))
    }
  }, [handleWindowClick])

  return (
    <DropdownMenu
      className='global-create-button'
      contentClassName='global-create-button__content'
      direction={DropdownMenu.Directions.RIGHT}
      trigger={
        <Button responsive color={buttonColor || Button.Colors.BLUE}>
          <PlusIcon width={24} height={24} />
          <span>Create</span>
        </Button>
      }
    >
      <div ref={wrapperEl}>
        <DropdownMenu.List className='global-create-button__list'>
          <DropdownMenu.Item className='global-create-button__list__item'>
            <LogActivityButton className={classnames(
              'global-create-button__list__item',
              'global-create-button__list__item__activity-log-button'
            )}
            >
              Activity Log
            </LogActivityButton>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            className='global-create-button__list__item'
          >
            <TaskModalButton
              className='global-create-button__list__item'
            >
              <TasksIcon /> New Task
            </TaskModalButton>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={handleToggleAction}
            className={classnames(
              'global-create-button__list__item',
              'global-create-button__list__item--toggler'
            )}
          >
            <AccountPlus /> Client <ChevronRightIcon className={classnames(
              'global-create-button__list__item__toggle-icon',
              isToggleOpen &&
              'global-create-button__list__item__toggle-icon--toggled'
            )}
                                   />
          </DropdownMenu.Item>
          <div className={classnames(
            'global-create-button__list__toggled-section',
            isToggleOpen && 'global-create-button__list__toggled-section--toggled'
          )}
          >
            <DropdownMenu.Item
              onClick={navigateToNewIndividualClient}
              className={classnames(
                'global-create-button__list__item',
                'global-create-button__list__item--no-icon-layout'
              )}
            >
              Individual
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={navigateToNewCorporateClient}
              className={classnames(
                'global-create-button__list__item',
                'global-create-button__list__item--no-icon-layout'
              )}
            >
              Corporate
            </DropdownMenu.Item>
            {isCashCalcConnectionActive && (
              <ImportCashCalcClientModalTrigger
                refetchClients={refetchClients}
                className={classnames(
                  'global-create-button__list__item',
                  'global-create-button__list__item--no-icon-layout'
                )}
              />
            )}
          </div>
        </DropdownMenu.List>

      </div>
    </DropdownMenu>
  )
}

GlobalCreateButton.defaultProps = {
  direction: DropdownMenu.Directions.RIGHT
}

GlobalCreateButton.propTypes = {
  refetchClients: T.func,
  isCashCalcConnectionActive: T.bool,
  direction: T.string
}

const EnhancedGlobalCreateButton = withCashCalcConnection()(
  GlobalCreateButton
)

export { EnhancedGlobalCreateButton as GlobalCreateButton }
