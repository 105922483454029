import T from 'prop-types'
import classnames from 'classnames'

import { ModalCloseButton } from '../ModalCloseButton'

const ModalStickyHeader = ({ className, children, close }) => {
  return (
    <div className={classnames('modal__sticky-layout__content__header', className)}>
      <ModalCloseButton onClick={close} />
      {children}
    </div>
  )
}

ModalStickyHeader.propTypes = {
  children: T.node.isRequired,
  close: T.func.isRequired,
  className: T.string
}

export { ModalStickyHeader }
