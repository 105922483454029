import { useTableSort } from '../../misc/Table/useSortTable'

import { Table, TableResponsiveWrapper } from '../../misc'
import { WorkflowTemplatesTableRow } from './WorkflowTemplatesTableRow'

import { TABLE_HEADERS } from './const'

import './WorkflowTemplatesTable.less'

const WorkflowTemplatesTable = props => {
  const { workflowTemplates, onNameClick, onDeleteTemplate } = props

  const { sortedData, headersState, onSort, onResetSort } = useTableSort({
    tableHeaders: TABLE_HEADERS,
    data: workflowTemplates
  })

  return (
    <TableResponsiveWrapper>
      <Table className='workflow-templates-table'>
        <Table.Header>
          <Table.Row>
            {headersState.map((header, i) => (
              <Table.HeaderCell
                key={i}
                {...{
                  key: i,
                  isSortable: true,
                  header,
                  onSort,
                  onResetSort
                }}
              />
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedData.map(workflowTemplate => (
            <WorkflowTemplatesTableRow
              key={workflowTemplate.id}
              {...{
                workflowTemplate,
                onNameClick,
                onDeleteTemplate
              }}
            />
          ))}
        </Table.Body>
      </Table>
    </TableResponsiveWrapper>
  )
}

export { WorkflowTemplatesTable }
