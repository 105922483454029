import { useCallback, useState } from 'react'
import { useMutation, useSubscription } from '@apollo/client'
import T from 'prop-types'

import { MessageInput } from './MessageInput'
import { withClientUser } from '../../../../hoc/container'
import { attachmentManager } from './attachmentUploadManager'
import { parseFilesToCreate } from '../../../../../helpers/file'

import {
  normalizeFiles,
  filteredDuplicatedFilelist,
  parseFilesToLocalState
} from './helpers'

import createMessageGql from '../../../../../graphql/mutations/message/createMessage.graphql'
import messagesGql from '../../../../../graphql/queries/messages/messages.graphql'
import createMessageAttachmentsGql from '../../../../../graphql/mutations/message/createMessageAttachments.graphql'
import removeMessageAttachmentGql from '../../../../../graphql/mutations/message/removeMessageAttachment.graphql'
import fileUploadSubscription from '../../../../../graphql/subscriptions/fileUploaded.graphql'

const MessageInputContainer = (props) => {
  const { clientId, toUserName, isClientUser, className } = props

  const [attachments, setAttachments] = useState([])
  const [isCollapsed, setIsCollapsed] = useState(true)

  const [createMessageMutate, { loading, error }] = useMutation(createMessageGql, {
    update: (cache, { data: { createMessage: newMessage } }) => {
      try {
        const { messages } = cache.readQuery({ query: messagesGql, variables: { clientId } })
        cache.writeQuery({
          query: messagesGql,
          data: { messages: [...messages, newMessage] }
        })
      } catch (error) {
        console.log('Error occured while updating messages cache on sending new message', error)
      }
    }
  })

  const [fileCreateMutation, { error: filesCreateError }] = useMutation(createMessageAttachmentsGql)
  const [fileRemoveMutation, { loading: fileRemoveLoading }] = useMutation(removeMessageAttachmentGql)

  useSubscription(fileUploadSubscription, {
    onData: ({ data }) => {
      if (data) {
        setAttachments(prev => {
          return prev.map(el => {
            const file = data.data.fileUploaded
            if (el.fileId === file.id) {
              return Object.assign(el, {
                isLoading: false,
                isUploading: false,
                isError: false
              })
            }
            return el
          })
        })
      }
    }
  })

  const onMessageSubmit = useCallback(
    async (body) => {
      await createMessageMutate({
        variables: { input: { body, clientId, attachments: normalizeFiles(attachments) } }
      })
      setAttachments([])
    }, [createMessageMutate, normalizeFiles, clientId, attachments]
  )

  const uploadFile = useCallback(async (uploadUrl, file) => {
    const setAttachmentUpload = (file, isStart = true) =>
      setAttachments(prev => {
        const attachment = prev.find(({ fileName }) => file.name === fileName)
        attachment.isLoading = isStart
        attachment.isError = !isStart
        attachment.isUploading = isStart
        return [...prev]
      })

    try {
      setAttachmentUpload(file)
      await attachmentManager.upload(uploadUrl, file)
    } catch (error) {
      if (String(error).includes('user aborted a request')) {
        return
      }
      setAttachmentUpload(file, false)
    }
  })

  const onFilesSelect = useCallback(
    async (fileList) => {
      if (!fileList.length) return

      let filesToUpload = []

      const filteredFiles = filteredDuplicatedFilelist(fileList, attachments)

      if (filteredFiles?.length) {
        try {
          const { data } = await fileCreateMutation({
            variables: {
              input: {
                clientId,
                files: parseFilesToCreate(filteredFiles)
              }
            }
          })

          filesToUpload = data.createMessageAttachments

          setAttachments(prev => [...prev, ...parseFilesToLocalState(filesToUpload, filteredFiles)])
        } catch (error) {
          return
        }
      }

      filesToUpload.forEach(async ({ uploadUrl, index }) => {
        await uploadFile(uploadUrl, filteredFiles[index])
      })
    }, [clientId, attachments, uploadFile]
  )

  const onRemoveAttachment = useCallback(async id => {
    setAttachments(prev => {
      const attachment = prev.find(attachment => attachment.id === id)
      if (attachment.isUploading) {
        attachmentManager.cancel()
      }
      attachment.isLoading = true
      attachment.isError = false
      return [...prev]
    })

    try {
      await fileRemoveMutation({ variables: { id } })
    } catch (error) {}

    setAttachments(prev => prev.filter(attachment => attachment.id !== id))
  })

  return (
    <MessageInput
      {...{
        clientId,
        toUserName,
        onMessageSubmit,
        onFilesSelect,
        isMessageSubmitting: loading,
        isMessageError: !!error,
        isFilesCreateError: !!filesCreateError,
        isClientUser,
        attachments,
        onRemoveAttachment,
        uploadFile,
        isCollapsed,
        setIsCollapsed,
        fileRemoveLoading,
        className
      }}
    />
  )
}
MessageInputContainer.defaultProps = {
  isClientPortal: false
}

MessageInputContainer.propTypes = {
  /**
   * Name of the user to whom the message is sent.
   * E.g. 'John Johnson'
   */
  toUserName: T.string,
  /**
   * Id of the client that the message is being sent to,
   * or who's sending the message.
   *
   * If it's the company employee who's sending the message,
   * the message will be sent to the client with the clientId.
   *
   * If it's the client who's sending the message,
   * then the message will be sent to the company.
   */
  clientId: T.string.isRequired
}

const MessageInputContainerWithUnActiveClient = withClientUser()(
  MessageInputContainer
)

export { MessageInputContainer as ClientPortalMessageInputContainer }
export { MessageInputContainerWithUnActiveClient as MessageInputContainer }
