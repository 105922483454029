import './TransactGuide.less'

export const TransactGuide = () => (
  <div className='transact-guide'>
    <p>
      Due to a change to which data Transact is giving us,
      we can no longer retrieve client nor policy information through
      Transact API. Only retrievable data is valuations for policies.
    </p>
    <p>
      In order to be able to map valuations against policies in Elen,
      it is required that you:
    </p>
    <ul>
      <li>Download your portfolio summary from Transact</li>
      <li>Create all clients and policies in Elen</li>
      <li>
        Assure policies are assigned correct policy number
        (knowns as "WrapperRef" in Transact Portfolio Summary)
      </li>
    </ul>
    <p>
      Our integration will then be able to retrieve daily valuations
      for all the policies you have on both systems.
    </p>
  </div>
)
