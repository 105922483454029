import { Fragment } from 'react'
import constants from '@elenfs/elen-constants'

import {
  InputField,
  DateField,
  MoneyField,
  RadioGroupField
} from '../../../form/fields'
import { FormGroup } from '../../../form/Form/FormGroup'
import { SharedPolicyFormFields } from '../SharedPolicyFormFields'

import {
  RateTypesLabels,
  RepaymentTypesLabels,
  DebtTypesLabels,
  PlanStatusesLabels
} from '../../../../helpers/enumLabels/debtPolicy'
import { buildSelectOptions } from '../../../form/fields/SelectField'
import { buildBooleanSelectBoxOptions } from '../../../../helpers/form'

const {
  RateTypes,
  RepaymentTypes,
  DebtTypes,
  PlanStatuses
} = constants.policy.debtPolicy

const DebtPolicyDetailsForm = ({ currentClient, companyClients }) => (
  <>
    <div className='policy-type-form debt-policy-form'>
      <SharedPolicyFormFields
        {...{
          companyClients,
          currentClient,
          policyTypeLabel: 'Policy type',
          policyTypeName: 'debtType',
          policyTypeOptions: buildSelectOptions(DebtTypes, DebtTypesLabels),
          planStatusOptions: buildSelectOptions(
            PlanStatuses,
            PlanStatusesLabels
          )
        }}
      />

      <FormGroup label='Dates'>
        <div className='policy-type-form__row policy-type-form__row--4'>
          <DateField
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
            name='applicationDate'
            label='Application date'
          />

          <DateField
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
            name='startDate'
            label='Start date'
          />

          <DateField
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
            name='endDate'
            label='End date'
          />

          <DateField
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
            name='valuationDate'
            label='Valuation date'
          />
        </div>
      </FormGroup>

      <FormGroup label='Repayments and contributions'>
        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-repayment-type'
            options={buildSelectOptions(RepaymentTypes, RepaymentTypesLabels)}
            name='repaymentType'
            groupLabel='Repayment type'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-rate-type'
            options={buildSelectOptions(RateTypes, RateTypesLabels)}
            name='rateType'
            groupLabel='Rate type'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--4'>
          <InputField
            name='rate'
            label='Rate'
            className='policy-type-form__percent-input'
            inputProps={{ placeholder: 0 }}
          />

          <DateField
            name='endOfRatePeriod'
            label='End of rate period'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-rate-type-penalties'
            options={buildBooleanSelectBoxOptions()}
            name='exitPenaltiesApply'
            groupLabel='Exit penalties apply'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--4'>
          <MoneyField name='amountOutstanding' label='Amount outstanding' />
        </div>
      </FormGroup>
    </div>
  </>
)

export default DebtPolicyDetailsForm
