import { extractValidationErrorMessages } from '../../../helpers/form'
import ErrorMessage from '../../misc/ErrorMessage'

const FormErrorMessages = ({
  serverError,
  serverErrorMessage = '',
  validationErrors = {}
}) => {
  let errorMessages = extractValidationErrorMessages(validationErrors)
  if (serverError) {
    errorMessages = [serverErrorMessage, ...errorMessages]
  }

  return (
    <div className='form-error-messages'>
      {errorMessages.map((msg, i) => msg
        ? (
          <ErrorMessage key={i} text={msg} />
          )
        : null)}
    </div>
  )
}

export default FormErrorMessages
