const ReviewIcon = props => (
  <svg width={42} height={43} viewBox='0 0 42 43' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 .889h42v42H0z' />
      <path
        d='M24.742 6.139l8.617 8.789v19.02c0 2.035-1.632 3.69-3.637 3.69H12.388c-2.006 0-3.638-1.655-3.638-3.69V9.83c0-2.035 1.632-3.691 3.638-3.691h12.354zm-1.75 2.46H12.388c-.669 0-1.213.553-1.213 1.231v24.117c0 .679.544 1.231 1.213 1.231H29.72c.67 0 1.213-.552 1.213-1.23V16.665H26.63c-2.006 0-3.637-1.656-3.637-3.691V8.6zM15.84 19.576a4.778 4.778 0 016.165-.87 4.816 4.816 0 011.496 6.499c.026.007.052.01.079.01a2.058 2.058 0 011.9.648c.329.334.665.661.994.996.515.525 1.054 1.03 1.534 1.585.572.728.573 1.751.001 2.48a2.04 2.04 0 01-3.143.137c-.728-.716-1.433-1.455-2.17-2.161a2.23 2.23 0 01-.765-2.126 4.778 4.778 0 01-6.148-.982 4.759 4.759 0 01.057-6.216zm8.02 7.481c-.043-.003-.095.109-.152.181.047.075.1.147.156.214.766.77 1.53 1.54 2.306 2.297a.372.372 0 00.345.065c.154-.116.053-.256-.06-.368-.761-.76-1.519-1.524-2.286-2.277a.518.518 0 00-.31-.112zm-2.377-6.391a2.915 2.915 0 00-3.171-.64 2.907 2.907 0 00-1.806 2.68 2.889 2.889 0 00.841 2.062 2.898 2.898 0 002.06.853 2.912 2.912 0 002.696-1.785 2.903 2.903 0 00-.62-3.17zm3.934-10.349v2.659c0 .678.544 1.23 1.212 1.23h2.6l-3.812-3.889z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { ReviewIcon }
