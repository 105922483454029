import { useCallback } from 'react'
import T from 'prop-types'

import { Trash } from '../../../icons'
import { ConfirmationModal } from '../../'

const RemoveAttachmentConfirmation = ({
  onRemoveAttachment,
  Component,
  className
}) => {
  const removeCommentCallback = useCallback(
    (removeConfirmationFlag) => {
      if (removeConfirmationFlag) {
        onRemoveAttachment()
      }
    }, [onRemoveAttachment]
  )

  return (
    <ConfirmationModal.Trigger
      as={Component}
      className={className}
      modalProps={{
        confirmationCallback: removeCommentCallback,
        awaitCallback: true,
        heading: 'Delete attachment',
        text: 'Are you sure you want to delete this attachment?'
      }}
    />
  )
}

RemoveAttachmentConfirmation.defaultProps = {
  Component: Trash
}

RemoveAttachmentConfirmation.propTypes = {
  onRemoveAttachment: T.func.isRequired,
  Component: T.elementType.isRequired,
  className: T.string
}

export { RemoveAttachmentConfirmation }
