import T from 'prop-types'

import ImportError from './ImportError'
import PoliciesErrorHeading from './PoliciesErrorHeading'

const PoliciesInternalError = ({
  clientFirstName,
  clientLastName,
  policies
}) => (
  <ImportError
    heading={(
      <PoliciesErrorHeading
        clientFirstName={clientFirstName}
        clientLastName={clientLastName}
        numOfPolicies={policies.length}
      />
    )}
    description='Internal Server Error'
  />
)

PoliciesInternalError.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  policies: T.array.isRequired
}

export default PoliciesInternalError
