import { useState, useEffect, useRef } from 'react'
import { withFormik, connect } from 'formik'
import { compose, pickBy, isNil } from 'ramda'
import moment from 'moment'
import T from 'prop-types'

import { Form } from '../../form'
import { SelectField, DateField } from '../../form/fields'
import { Button, CollapsableContainer } from '..'
import { optionsType } from '../../../helpers/propTypes'

import './Filter.less'

const Filter = props => {
  const {
    values,
    resetForm,
    handleSubmit,
    filterDateRangeOptions,
    filterDateRanges,
    inputsArr,
    summary,
    onValuesChange,
    loading
  } = props

  const withDateFilter = !!filterDateRangeOptions?.length

  const { dateRange, fromDate, toDate } = values

  const [toDateMinDate, setToDateMinDate] = useState(null)
  const [toDateMaxDate, setToDateMaxDate] = useState(null)

  const [fromDateMinDate, setFromDateMinDate] = useState(null)
  const [fromDateMaxDate, setFromDateMaxDate] = useState(null)

  const animationWrapperRef = useRef([])

  useEffect(() => {
    if (withDateFilter) {
      const isShow = dateRange === filterDateRanges.CUSTOM

      animationWrapperRef.current.forEach(el => {
        if (el) {
          const height = el.firstElementChild?.offsetHeight || 42
          el.style.height = (isShow ? height : 0) + 'px'
          el.style.opacity = isShow ? 1 : 0
          el.style.marginBottom = (isShow ? 16 : 0) + 'px'
        }
      })
    }
  }, [dateRange, withDateFilter])

  useEffect(() => {
    if (withDateFilter) {
      if (!fromDate) {
        setToDateMinDate(null)
        setToDateMaxDate(null)
      } else {
        setToDateMinDate(moment(fromDate).toISOString())
        setToDateMaxDate(
          moment(fromDate)
            .add(1, 'year')
            .subtract(1, 'day')
            .toISOString()
        )
      }
    }
  }, [fromDate, withDateFilter])

  useEffect(() => {
    if (withDateFilter) {
      if (!toDate) {
        setFromDateMinDate(null)
        setFromDateMaxDate(null)
      } else {
        setFromDateMinDate(
          moment(toDate)
            .subtract(1, 'year')
            .add(1, 'day')
            .toISOString()
        )
        setFromDateMaxDate(moment(toDate).toISOString())
      }
    }
  }, [toDate, withDateFilter])

  const isCustomDateRange = dateRange === filterDateRanges?.CUSTOM
  const disableBtn = withDateFilter
    ? !dateRange || (isCustomDateRange && !(fromDate && toDate))
    : false

  useEffect(() => {
    if (onValuesChange) onValuesChange(values)
  }, [values])

  return (
    <CollapsableContainer
      title='Filters'
      className='filter'
      listenToValues={[dateRange]}
      valuesTransition={300}
    >
      <Form onSubmit={handleSubmit}>
        {withDateFilter && (
          <Form.Group className='filter__form-group'>
            <SelectField
              name='dateRange'
              options={filterDateRangeOptions}
              inputProps={{ isClearable: false }}
            />

            <div
              className='filter__animation-wrapper'
              ref={ref => animationWrapperRef.current.push(ref)}
            >
              <DateField
                name='fromDate'
                inputProps={{ placeholder: 'From date' }}
                flatpickrOptions={{
                  minDate: fromDateMinDate,
                  maxDate: fromDateMaxDate
                }}
              />
            </div>

            <div
              className='filter__animation-wrapper'
              ref={ref => animationWrapperRef.current.push(ref)}
            >
              <DateField
                name='toDate'
                inputProps={{ placeholder: 'To date' }}
                flatpickrOptions={{
                  minDate: toDateMinDate,
                  maxDate: toDateMaxDate
                }}
              />
            </div>
          </Form.Group>
        )}

        <Form.Group className='filter__form-group'>
          {inputsArr?.map(({ InputComponent, name, inputProps }, i) => (
            <InputComponent key={i} {...{ name, ...inputProps }} />
          ))}

          <div className='filter__form-group__btn-summary-container'>
            <Button
              color={Button.Colors.BLUE}
              type='submit'
              disabled={loading || disableBtn}
            >
              Apply
            </Button>

            <Button onClick={resetForm}> Reset </Button>
            {summary && <span> {summary} </span>}
          </div>
        </Form.Group>
      </Form>
    </CollapsableContainer>
  )
}

const EnhancedFilter = compose(
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues || {},
    handleSubmit: async (values, { props }) => {
      const {
        onSubmit,
        filterDateRanges,
        getFromToDateByDateRange,
        filterDateRangeOptions
      } = props

      const withDateFilter = !!filterDateRangeOptions?.length
      const filter = { ...values }

      if (withDateFilter) {
        const isCustomDateRange = values.dateRange === filterDateRanges.CUSTOM

        if (!isCustomDateRange) {
          const { fromDate, toDate } = getFromToDateByDateRange(
            filter.dateRange
          )
          filter.fromDate = fromDate
          filter.toDate = toDate
        }

        filter.fromDate = moment(filter.fromDate)
          .startOf('day')
          .toDate()
        filter.toDate = moment(filter.toDate)
          .endOf('day')
          .toDate()

        delete filter.dateRange
      }

      const filteredOptions = filter && pickBy(v => !isNil(v), filter)
      await onSubmit(filteredOptions)
    }
  }),
  connect
)(Filter)

Filter.defaultProps = {
  initialValues: {}
}

Filter.propTypes = {
  onSubmit: T.func.isRequired,
  getFromToDateByDateRange: T.func,
  filterDateRangeOptions: optionsType(),
  filterDateRanges: T.object,
  inputsArr: T.arrayOf(
    T.shape({
      InputComponent: T.elementType,
      inputProps: T.object
    })
  ),
  initialValues: T.object,
  summary: T.node,
  onValuesChange: T.func
}

export { EnhancedFilter as Filter }
