import PropTypes from 'prop-types'
import classnames from 'classnames'

import Heading from '../Heading'

const CardHeading = ({ children, className, large, ...props }) => (
  <Heading
    as={large ? 'h2' : 'h3'}
    className={classnames('card-new__heading', className)}
    {...props}
  >
    {children}
  </Heading>
)

CardHeading.defaultProps = {
  large: false
}

CardHeading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  large: PropTypes.bool
}

export default CardHeading
