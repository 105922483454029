import * as yup from 'yup'

import stringSchema from '../helpers/stringSchema'

export default yup.object().shape({
  objectives: stringSchema()
    .label('Objectives'),

  actions: stringSchema()
    .label('Agreed actions')
})
