import React from 'react'
import { ToggleButton } from '../../../misc'

import './ToggleMyClients.less'

export const ToggleMyClients = ({ onChange, isInitiallyActive }) => {
  return (
    <div className='toggle-my-clients'>
      <span>My clients only</span>
      <ToggleButton {...{ isInitiallyActive, onChange }} />
    </div>
  )
}

ToggleMyClients.defaultProps = {
  onChange: () => {},
  isInitiallyActive: false
}
