import PolicyDetails from './InvestmentPolicyDetails'
import PolicyDetailsForm from './InvestmentPolicyDetailsForm'
import PolicyForm from '../../PolicyForm'
import investmentPolicySchema from '../../../../schemas/policy/investmentPolicySchema'

const InvestmentPolicyForm = props => (
  <PolicyForm {...{
    PolicyDetails,
    PolicyDetailsForm,
    policySchema: investmentPolicySchema,
    ...props
  }}
  />
)

export default InvestmentPolicyForm
