export const Colors = Object.freeze({
  RED: 'red',
  BLUE: 'blue',
  PICTON_BLUE: 'picton-blue',
  YELLOW: 'yellow',
  GREY: 'grey',
  GREEN: 'green'
})

export const Sizes = Object.freeze({
  SM: 'small'
})

export const TextColor = Object.freeze({
  GREY: 'text-grey'
})
