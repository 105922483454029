import { formatMoney } from './../../../helpers/policy'
import { formatDate } from './../../../helpers/date'
import { IncreaseIcon } from '../../icons'

const ProfitAndLossTableHeaders = Object.freeze({
  YEAR_AND_DATE: 'Year and date',
  PROFIT_OR_LOSS: 'Profit/Loss'
})

const profitAndLossTableHeadersCaptions = [
  { caption: ProfitAndLossTableHeaders.YEAR_AND_DATE },
  { caption: ProfitAndLossTableHeaders.PROFIT_OR_LOSS }
]

const profitAndLossTableRowCells = [
  {
    propPath: ['yearEndDate'],
    formatFunc: formatDate,
    dataLabel: ProfitAndLossTableHeaders.YEAR_AND_DATE
  },
  {
    propPath: ['amount'],
    formatFunc: formatMoney,
    dataLabel: ProfitAndLossTableHeaders.PROFIT_OR_LOSS,
    Icon: IncreaseIcon
  }
]

export const profitAndLossCardTableProps = {
  tableHeadersCaptions: profitAndLossTableHeadersCaptions,
  tableRowCells: profitAndLossTableRowCells
}
