import { useEffect } from 'react'
import T from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import constants from '@elenfs/elen-constants'

import { LoginForm } from './LoginForm'
import { login } from '../../../auth'
import { getCurrentUser } from '../../../graphql/helpers'
import useFormState from '../../../hooks/useFormState'

const { UserTypes } = constants.user

const LoginFormContainer = ({ userType }) => {
  const [formState, setFormState] = useFormState()
  const navigate = useNavigate()
  const { state } = useLocation()

  const handleLogin = async ({ email, password }) => {
    setFormState({ ...formState, loading: true })

    try {
      await login(email, password, userType)
      await getCurrentUser()
      setFormState({ ...formState, success: true, loading: false })
    } catch (error) {
      setFormState({ ...formState, error, loading: false })
    }
  }

  const { loading, error, success } = formState

  useEffect(() => {
    if (success) {
      const successLoginRedirectPath = userType === UserTypes.CLIENT ? '/clients' : '/'
      const prevPath = state?.prevPath
      navigate(prevPath || successLoginRedirectPath)
    }
  }, [success, userType])

  return (
    <LoginForm
      {...{
        userType,
        loading,
        error,
        onSubmit: handleLogin
      }}
    />
  )
}

LoginFormContainer.defaultProps = {
  userType: UserTypes.COMPANY_USER
}

LoginFormContainer.propsTypes = {
  userType: T.oneOf(Object.values(UserTypes))
}

export default LoginFormContainer
