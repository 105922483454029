import T from 'prop-types'
import { last } from 'ramda'
import { getFileIcon } from '../../../../../helpers/clientDocument'
import DownloadIcon from './../../../../icons/Download'

const FileIcon = ({ fileName }) => {
  const Icon = getFileIcon(last(fileName.split('.')))
  return <Icon />
}

const MessageDocumentsList = ({ attachments, onDownloadFile }) => (
  <>
    {attachments?.map(({ id, fileName }) => (
      <div className='single-message__content__file' key={id}>
        <div className='single-message__content__file__info'>
          <FileIcon fileName={fileName} className='single-message__content__file__info__file-icon' />
          <span>{fileName}</span>
        </div>
        <div className='single-message__content__file__download'>
          <DownloadIcon onClick={() => onDownloadFile(id)} />
        </div>
      </div>
    ))}
  </>
)
MessageDocumentsList.defaultProps = {
  attachments: []
}

MessageDocumentsList.propTypes = {
  attachments: T.arrayOf(T.shape({ fileName: T.string.isRequired, fileId: T.string.isRequired })).isRequired
}

export default MessageDocumentsList
