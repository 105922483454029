import ConfirmationModal from '../../../misc/ConfirmationModal'
import { DropdownMenu } from '../../../misc'

const DropdownMenuItem = (props) => (
  <DropdownMenu.Item {...props}>Delete</DropdownMenu.Item>
)

const RemoveDocumentButton = ({ removeDocModalCallback, clientDocument: { fileName }, disabled }) => (
  <ConfirmationModal.Trigger
    as={DropdownMenuItem}
    disabled={disabled}
    modalProps={{
      confirmationCallback: removeDocModalCallback,
      heading: 'Delete document',
      text: `Are you sure you want to delete ${fileName}?`,
      yesButtonText: 'Delete',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
  />
)

export default RemoveDocumentButton
