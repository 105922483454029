import { useCallback } from 'react'
import T from 'prop-types'

import { Modal } from '../../misc'
import { RecipientForm } from './RecipientForm'

import { userType } from '../../../helpers/propTypes'

const RecipientModal = props => {
  const {
    trigger,
    isOpen,
    close,
    client,
    customOptionsWithUser,
    onNextClick
  } = props

  const onSubmit = useCallback(values => {
    onNextClick(values)
    close()
  })

  return (
    <Modal {...{ isOpen, close, trigger }} className='request-signature-modal'>
      <Modal.Heading> Request signature </Modal.Heading>
      <RecipientForm {...{ customOptionsWithUser, client, onSubmit }} />
    </Modal>
  )
}

RecipientModal.propTypes = {
  customOptionsWithUser: T.arrayOf(
    T.shape({
      label: T.string,
      value: T.string,
      isCurrentUser: T.bool,
      isCurrentFA: T.bool,
      showDivider: T.bool,
      user: userType()
    })
  ),
  client: T.shape({
    fullName: T.string,
    id: T.string
  }),
  onNextClick: T.func,
  close: T.func,
  isOpen: T.bool
}

export { RecipientModal }
