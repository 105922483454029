import { TasksTable } from '../TasksTable/TasksTable'

import './ClientTasksTable.less'

const ClientTasksTable = props => {
  const {
    tableProps,
    openNewTaskModal,
    clientId
  } = props

  return (
    <TasksTable
      className='client-tasks-table'
      isClientTasks
      clientId={clientId}
      openNewTaskModal={openNewTaskModal}
      {...tableProps}
    />
  )
}

export { ClientTasksTable }
