import PropTypes from 'prop-types'

import Card from '../../misc/Card'
import ImportPlatformLayout from '../ImportPlatformLayout'
import TransactImportStats from '../TransactImportStats'
import TransactImportForm from '../TransactImportForm'
import { TransactGuide } from '../TransactGuide'
import { ImportFooterConnection, ImportGuide } from '../ImportFooter'
import AppConfig from '../../../appConfig'

const TransactImportPage = ({ transactData }) => {
  const { accessCode, pin } = transactData || {}
  const pageTitle = 'Transact'

  return (
    <ImportPlatformLayout pageTitle={pageTitle}>
      <ImportPlatformLayout.LeftColumn>
        <Card.Group>
          <Card>
            <Card.Heading large>{pageTitle}</Card.Heading>
            <TransactImportStats />
          </Card>
          <Card>
            <TransactImportForm
              accessCode={accessCode}
              pin={pin}
            />
          </Card>
          <Card>
            <ImportFooterConnection
              pageTitle={pageTitle}
              link='/secure/admin/transact/logs'
            />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.LeftColumn>

      <ImportPlatformLayout.RightColumn>
        <Card.Group>
          <Card>
            <Card.Heading>Import data</Card.Heading>
            <TransactGuide />
          </Card>
          <Card>
            <ImportGuide link={AppConfig.TRANSACT_IMPORT_STEP_BY_STEP_LINK} />
          </Card>
        </Card.Group>
      </ImportPlatformLayout.RightColumn>
    </ImportPlatformLayout>
  )
}

TransactImportPage.propTypes = {
  transactData: PropTypes.shape({
    accessCode: PropTypes.string,
    pin: PropTypes.string
  })
}

export default TransactImportPage
