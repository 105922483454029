import constants from '@elenfs/elen-constants'

import { ImportLogLayout } from '../ImportLogLayout'
import { gqlQueryField } from '../ImportLog'
import dataImportJobsGql from '../../../graphql/queries/dataImport/dataImportJobs.graphql'

const { DataImportTypes } = constants.dataImport

const CSVLogPage = (props) => {
  return (
    <ImportLogLayout
      pageTitle='CSV'
      dataImportType={DataImportTypes.CSV_FILE}
      gqlQuery={dataImportJobsGql}
      gqlQueryField={gqlQueryField}
      link='/secure/admin/csv-import'
      {...props}
    />
  )
}

export default CSVLogPage
