const ChevronLeftIcon = (props) => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <title>Icon/24/LeftChevron_24</title>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M15.727 20.957a1 1 0 00.083-1.32l-.083-.094L8.185 12l7.542-7.543a1 1 0 00.083-1.32l-.083-.094a1 1 0 00-1.32-.083l-.094.083-7.893 7.902a1.5 1.5 0 000 2.12l7.893 7.892a1 1 0 001.414 0z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export default ChevronLeftIcon
