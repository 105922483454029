import T from 'prop-types'
import classnames from 'classnames'

import { Orientations } from './const'

import './PageDivider.less'

const PageDivider = ({ className, orientation }) => (
  <div className={classnames('page-divider', `page-divider--${orientation}`, className)} />
)

PageDivider.defaultProps = {
  // TODO: there's a weird bug.
  // Something weird is going on here with JS interperter, transpiler, whatever.
  // Whatever expression you write that contains Orientations.SOMETHING
  // - it doesn't get evaluated.
  // It works with objects created using Object.freeze, and with regular objects.
  // I managed to reproduce it with defaultProps of Button too.
  // You can write something like this,
  // and it'll end up as a string containing that entire expression:
  //
  // orientation: "whatever string" + Orientations.HORIZONTAL + 'jfdkslfj' + `${Orientations.HORIZONTAL}`
  //
  // I'll investigate more later.

  // orientation: Orientations.HORIZONTAL
  orientation: 'horizontal'
}

PageDivider.propTypes = {
  /**
   * Classname for the page divider div.
   */
  className: T.string,
  /**
   * Orientation of the page divider.
   */
  orientation: T.oneOf(Object.values(Orientations))
}

export { PageDivider }
