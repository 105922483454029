import { useQuery } from '@apollo/client'

import Spinner from '../../misc/Spinner'
import ErrorMessage from '../../misc/ErrorMessage'
import { ImportPageEmpty } from './ImportPageEmpty'
import { ImportPage } from './ImportPage'
import dataImportSourcesGql
  from '../../../graphql/queries/dataImport/dataImportSources.graphql'

const ImportPageContainer = (props) => {
  const { loading, error, data } = useQuery(dataImportSourcesGql, {
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) return <Spinner />
  if (error) return <ErrorMessage text='Failed to get data import sources' />

  const { dataImportSources } = data
  if (!dataImportSources.length) {
    return <ImportPageEmpty />
  }

  return (
    <ImportPage
      dataImportSources={dataImportSources}
      {...props}
    />
  )
}

export { ImportPageContainer }
