import { forwardRef, useRef } from 'react'
import moment from 'moment'
import { TaskStatuses } from '@elenfs/elen-constants'

import { CalendarIcon, BranchArrowsIcon, LockIcon } from '../../icons'
import { TaskAssignee, TaskIcon } from '../../misc'
import { useOnClickOutside } from '../../../hooks'

import './TaskCard.less'

const { COMPLETED } = TaskStatuses

export const TaskCard = forwardRef((allProps, ref) => {
  const {
    task,
    props,
    assigneeOptions,
    draggableProps,
    onSelectAssignee,
    openAssignee,
    handleAssigneeMouseDown,
    setOpenAssignee
  } = allProps

  const {
    onTaskClick
  } = props

  const {
    summary,
    assignedTo,
    client,
    subtasks,
    currentUserId,
    dueDate,
    workflowInstance,
    type
  } = task

  const showPrivateIcon =
    !client && (!assignedTo || assignedTo?.id === currentUserId)

  const isValidDate = (date) => Boolean(Number(new Date(date)))

  const completedTasks = subtasks?.filter(task => task.status === COMPLETED)

  const mouseDownRef = useRef(null)
  useOnClickOutside(mouseDownRef, () => setOpenAssignee(false))

  return (
    <div className='task-card' ref={ref} {...draggableProps}>
      {isValidDate(dueDate) && (
        <div className='task-card__date'>
          <CalendarIcon />
          <span>{moment(dueDate).format('D MMM YYYY')}</span>
        </div>
      )}

      <div className='task-card__summary' onClick={() => onTaskClick(task)}>
        {summary}
      </div>

      <div className='task-card__workflow_and_client'>
        {workflowInstance?.name && (
          <div className='task-card__workflow_and_client__workflow'>
            <span>
              {workflowInstance?.name}
            </span>
          </div>
        )}

        {client && (
          <div className='task-card__workflow_and_client__client'>
            <span>
              {client?.fullName}
            </span>
          </div>
        )}
      </div>

      <div className='task-card__footer-icons'>
        <div className='task-card__footer-icons__document'>
          <div className='task-card__footer-icons__document__task-icon'>
            <TaskIcon type={type} isKanban />
          </div>

          {!!subtasks?.length && (
            <div className='task-card__footer-icons__document__sub-task'>
              <BranchArrowsIcon className='task-card__footer-icons__document__sub-task__icon' />
              <span>
                {completedTasks?.length} / {subtasks?.length}
              </span>
            </div>
          )}

          {showPrivateIcon && (
            <div className='task-card__footer-icons__document__secure'>
              <LockIcon className='task-card__footer-icons__document__secure__icon' />
            </div>
          )}
        </div>

        <div onMouseDown={handleAssigneeMouseDown} ref={mouseDownRef}>
          <TaskAssignee
            options={assigneeOptions}
            isHandleOnSelect
            handleOnSelect={onSelectAssignee}
            inputProps={{ menuIsOpen: openAssignee, isTaskIcon: true }}
          />
        </div>
      </div>
    </div>
  )
})
