import { Route, Routes, Navigate } from 'react-router-dom'

import ClientPersonalDetailsForm from '../ClientPersonalDetailsForm'
import ClientEmploymentDetailsForm from '../ClientEmploymentDetailsForm'
import ClientAssetsAndLiabilitiesForm from '../ClientAssetsAndLiabilitiesForm'
import ClientFinancialAdvisersForm from '../ClientFinancialAdvisersForm'
import ClientObjectivesAndActionsForm from '../ClientObjectivesAndActionsForm'
import ClientIncomeAndExpenditureForm from '../ClientIncomeAndExpenditureForm'

import { ClientRelationshipsForm } from '../ClientRelationshipsForm'
import { ClientDependantsForm } from '../ClientDependantsForm'

const IndividualClientDetailsFormSwitch = ({
  clientId,
  setValidationSchema,
  financialAdviserProps,
  isClientPortal,
  client
}) => {
  const {
    financialAdviserOptions,
    financialAdvisersError
  } = financialAdviserProps

  return (
    <Routes>
      <Route
        path='personal'
        element={<ClientPersonalDetailsForm {...{
          setValidationSchema,
          nextLink: 'relationships',
          isClientPortal
        }}
                 />}
      />

      <Route
        path='relationships'
        element={<ClientRelationshipsForm {... {
          setValidationSchema,
          clientId,
          nextLink: 'dependants',
          prevLink: 'personal',
          isClientPortal,
          client
        }}
                 />}
      />

      <Route
        path='dependants'
        element={<ClientDependantsForm {... {
          setValidationSchema,
          nextLink: 'employment',
          prevLink: 'relationships'
        }}
                 />}
      />

      <Route
        path='employment'
        element={<ClientEmploymentDetailsForm
          setValidationSchema={setValidationSchema}
          nextLink='income-and-expenditure'
          prevLink='dependants'
                 />}
      />

      <Route
        path='income-and-expenditure'
        element={
          <ClientIncomeAndExpenditureForm
            setValidationSchema={setValidationSchema}
            nextLink='assets-and-liabilities'
            prevLink='employment'
          />
        }
      />

      <Route
        path='assets-and-liabilities'
        element={<ClientAssetsAndLiabilitiesForm
          setValidationSchema={setValidationSchema}
          nextLink='objectives-and-actions'
          prevLink='income-and-expenditure'
                 />}
      />

      <Route
        path='objectives-and-actions'
        element={<ClientObjectivesAndActionsForm
          setValidationSchema={setValidationSchema}
          nextLink='financial-advisers'
          prevLink='assets-and-liabilities'
                 />}
      />

      <Route
        path='financial-advisers'
        element={<ClientFinancialAdvisersForm
          financialAdviserOptions={financialAdviserOptions}
          financialAdvisersError={financialAdvisersError}
          setValidationSchema={setValidationSchema}
          prevLink='objectives-and-actions'
          isClientPortal={isClientPortal}
                 />}
      />
      <Route path='/' element={<Navigate replace to='personal' />} />
    </Routes>
  )
}

export { IndividualClientDetailsFormSwitch }
