import T from 'prop-types'
import classnames from 'classnames'
import { FieldArray, connect } from 'formik'

import { FormArrayRow } from './FormArrayRow'
import AddNewFormRowButton from '../AddNewFormRowButton'

import { buildRow } from './helpers'

import './FormArrayFields.less'

const FormArrayFields = props => {
  const {
    name,
    className,
    addButtonLabel,
    rowInputs,
    formik,
    limitRows,
    ...otherProps
  } = props

  const valueList = formik?.values[name] || []
  const showAddBtn = () => limitRows !== valueList.length

  return (
    <div className={classnames('form-array-fields', className)}>
      <FieldArray
        validateOnChange={false}
        name={name}
        render={arrayHelpers => (
          <div className='form-array-fields__section'>
            {valueList.map((_, index) => (
              <FormArrayRow
                key={index}
                {...{
                  key: index,
                  index,
                  arrayHelpers,
                  rowInputs,
                  name,
                  valueList,
                  ...otherProps
                }}
              />
            ))}

            {showAddBtn() && (
              <AddNewFormRowButton
                label={addButtonLabel}
                onClick={() => arrayHelpers.push(buildRow(rowInputs))}
              />
            )}
          </div>
        )}
      />
    </div>
  )
}

FormArrayFields.defaultProps = {
  addButtonLabel: 'Add more',
  cantRemoveFirst: false,
  limitRows: -1,
  disableFirst: false,
  isUniqueSelectOptions: false
}

FormArrayFields.propTypes = {
  // The name of the formik value array.
  // Also used as prop path to access the value.
  name: T.string.isRequired,
  // The text for the add more rows button.
  addButtonLabel: T.string,
  // An array with all the inputs and their props for each row.
  // 'path' prop is required if we have multiple inputs in one row.
  rowInputs: T.arrayOf(
    T.shape({
      InputComponent: T.elementType.isRequired,
      inputProps: T.object,
      path: T.string
    })
  ).isRequired,
  // If u want first row to be non removable
  cantRemoveFirst: T.bool,
  // If u want to limit the rows
  limitRows: T.number,
  // Disable the first row
  disableFirst: T.bool,
  // Some inputs have different paths to access the 'disable prop'.
  // You can specify this path here if needed.
  disablePath: T.arrayOf(T.string),
  // If u have multiple inputs in one row but want to disable just selected.
  disableOnlyArr: T.arrayOf(T.string),
  // If u have a select input and want all the rows to be with unique values
  isUniqueSelectOptions: T.bool,
  // if u have multiple select inputs in one row and want to have unique value in each row.
  // You can specify the path to this prop here.
  //! Currently, it works if you need only one unique prop value in each row. !
  uniqueSelectOptionsPathArr: T.arrayOf(T.string)
}

const EnhancedFormArrayFields = connect(FormArrayFields)
export { EnhancedFormArrayFields as FormArrayFields }
