import { useState, useEffect, useCallback } from 'react'

import {
  useTasksGql,
  useTasksModal
} from '../../../hooks'
import { withCurrentUser } from './../../hoc/container'

import { ClientTasksPage } from './ClientTasksPage'

const ClientTaskPageContainer = ({ currentUser, clientId }) => {
  const {
    tasks,
    loadingTasks,
    errorTasks,

    createTask,
    loadingOnCreateTask,
    errorOnCreateTask,

    createSubtask,
    loadingOnCreateSubtask,
    errorOnCreateSubtask,

    updateClientTasksOrder
  } = useTasksGql({ clientId })

  const taskModalProps = useTasksModal({ tasks })

  const { onTaskClick, openNewTaskModal } = taskModalProps

  // Related with creation of new Subtask
  const [updatedTaskId, setUpdatedTaskId] = useState('')
  const [errorsOnCreateSubtask, setErrorsOnCreateSubtask] = useState({})

  const onCreateSubtask = useCallback(async ({ summary, taskId: parentId }) => {
    setUpdatedTaskId(parentId)
    await createSubtask({ variables: { input: { summary, parentId } } })
  }, [])

  useEffect(
    () =>
      setErrorsOnCreateSubtask(current => ({
        ...current,
        [updatedTaskId]: errorOnCreateSubtask?.message
      })),
    [errorOnCreateSubtask]
  )
  // >

  const onCreateTask = useCallback(
    input => createTask({ variables: { input: { ...input, clientId } } }),
    [createTask]
  )

  const onSortDND = useCallback(orderedTasks => {
    const order = orderedTasks.map(({ id, rank }) => ({ id, rank }))
    const input = { clientId, order }

    const mutationPayload = { variables: { input } }
    updateClientTasksOrder(mutationPayload, orderedTasks)
  }, [updateClientTasksOrder, clientId])

  const tableProps = {
    tasks,
    loadingTasks,
    errorTasks,
    loadingOnCreateTask,
    errorOnCreateTask: errorOnCreateTask?.message || '',
    onCreateTask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    onCreateSubtask,
    onTaskClick,
    currentUser,
    isDraggable: !!clientId,
    onSortDND
  }

  return (
    <ClientTasksPage {...{
      clientId,
      tableProps,
      taskModalProps,
      openNewTaskModal
    }}
    />
  )
}

const ClientTaskPageContainerWithCurrentUser = withCurrentUser()(ClientTaskPageContainer)

export { ClientTaskPageContainerWithCurrentUser as ClientTaskPageContainer }
