import { Route, Routes } from 'react-router-dom'

import { Policies } from '../Policies'
import { PolicyPage } from '../PolicyPage'

const PoliciesPage = ({ clientId, isClientPortal }) => (
  <Routes>
    <Route index element={<Policies {...{ clientId, isClientPortal }} />} />
    <Route path={':policyId/*'} element={<PolicyPage {...{ isClientPortal, clientId }} />} />
    {!isClientPortal && (
      <Route path='create' element={<PolicyPage />} />
    )}
  </Routes>
)

export { PoliciesPage }
