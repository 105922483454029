import { PolicyDetails, AmountCard, DetailsCard } from '../../PolicyDetails'
import { PolicyNoteCard } from '../../PolicyNoteCard'

import { formatMoney } from '../../../../helpers/policy'
import { formatDate } from '../../../../helpers/date'
import {
  BooleanLabels,
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from '../../../../helpers/enumLabels'
import {
  DebtTypesLabels,
  PlanStatusesLabels,
  RateTypesLabels,
  RepaymentTypesLabels
} from '../../../../helpers/enumLabels/debtPolicy'
import { resolvePolicyHoldersData } from '../helpers'

const DebtPolicyDetails = ({ policy, headingText, readOnly }) => {
  const cardData = [
    { label: 'Valuation date', value: formatDate(policy.valuationDate) }
  ]

  const data = [
    [
      {
        label: 'Replacement business',
        value: policy?.isReplacementBusiness ? 'Yes' : 'No'
      },
      { label: 'Category', value: PolicyCategoriesLabels[policy.category] },
      { label: 'Policy type', value: DebtTypesLabels[policy.debtType] },
      { label: 'Policy number', value: policy.number },
      ...resolvePolicyHoldersData(policy)(policy?.currentHolders, readOnly),
      { label: 'Plan status', value: PlanStatusesLabels[policy.planStatus] },
      { label: 'Provider', value: PolicyProvidersLabels[policy.provider] }
    ],
    [
      { label: 'Application date', value: formatDate(policy.applicationDate) },
      { label: 'Start date', value: formatDate(policy.startDate) },
      { label: 'End date', value: formatDate(policy.endDate) }
    ],
    [
      {
        label: 'Repayment type',
        value: RepaymentTypesLabels[policy.repaymentType]
      },
      { label: 'Rate type', value: RateTypesLabels[policy.rateType] },
      { label: 'Rate', value: policy.rate },
      { label: 'End of rate period', value: formatDate(policy.endOfRatePeriod) },
      {
        label: 'Exit penalities apply',
        value: BooleanLabels[policy.exitPenaltiesApply]
      }
    ]
  ]

  return (
    <PolicyDetails>
      <div>
        <DetailsCard {...{ headingText, data, readOnly }} />
      </div>
      <div>
        <AmountCard
          headingText='Amount outstanding'
          headingValue={formatMoney(policy.amountOutstanding)}
          cardData={cardData}
        />

        {!readOnly && <PolicyNoteCard note={policy?.note} policyId={policy?.id} />}
      </div>
    </PolicyDetails>
  )
}

export default DebtPolicyDetails
