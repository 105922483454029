import { useMutation } from '@apollo/client'

import { CancelSigningButton } from './CancelSigningButton'

import cancelESignatureRequestGql from '../../../../graphql/mutations/clientDocument/cancelESignatureRequest.graphql'

const CancelSigningButtonContainer = ({ clientDocument, refetchDocuments }) => {
  const [cancelESignature] = useMutation(cancelESignatureRequestGql, {
    variables: { documentId: clientDocument.id },
    onCompleted: refetchDocuments
  })

  const onCancelESignature = isConfirmed => isConfirmed && cancelESignature()

  return <CancelSigningButton {...{ onCancelESignature }} />
}

export { CancelSigningButtonContainer }
