import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import booleanSchema from '../helpers/booleanSchema'
import dateSchema from '../helpers/dateSchema'
import enumSchema from '../helpers/enumSchema'
import moneySchema from '../helpers/moneySchema'
import commonFields from './commonFields'

const {
  AssuredLives,
  CoverTypes,
  DeferredPeriods,
  OfferedTerms,
  PlanStatuses,
  ProtectionTypes,
  Waivers
} = constants.policy.protectionPolicy

export default yup.object().shape({
  ...commonFields(),

  protectionType: enumSchema(values(ProtectionTypes))
    .label('Protection type')
    .required(),

  livesAssured: enumSchema(values(AssuredLives))
    .label('Live(s) assured'),

  waiver: enumSchema(values(Waivers))
    .label('Waiver'),

  guaranteedInsurabilityOption: booleanSchema()
    .label('Guaranteed insurability option'),

  termsOffered: enumSchema(values(OfferedTerms))
    .label('Terms offered'),

  endDate: dateSchema()
    .label('Maturity date'),

  onRiskDate: dateSchema()
    .label('On risk date'),

  lumpSumBenefitOnCriticalIllness: moneySchema({
    amountLabel: 'Lump sum benefit on critical illness',
    currencyLabel: 'Lump sum benefit on critical illness Currency'
  }),

  lumpSumBenefitOnDeath: moneySchema({
    amountLabel: 'Lump sum benefit on death',
    currencyLabel: 'Lump sum benefit on death Currency'
  }),

  annualBenefit: moneySchema({
    amountLabel: 'Annual benefit (PHI)',
    currencyLabel: 'Annual benefit (PHI) Currency'
  }),

  termOfMaturityDate: dateSchema()
    .label('Maturity date'),

  deferredPeriod: enumSchema(values(DeferredPeriods))
    .label('Deferred period'),

  coverType: enumSchema(values(CoverTypes))
    .label('Cover type (WOL)'),

  planStatus: enumSchema(values(PlanStatuses))
    .label('Plan status'),

  currentValue: moneySchema({
    amountLabel: 'Current value',
    currencyLabel: 'Current value currency'
  }),

  surrenderValue: moneySchema({
    amountLabel: 'Surrender value',
    currencyLabel: 'Surrender value currency'
  }),

  valuationDate: dateSchema()
    .label('Valuation date'),

  regularPremiumForCover: moneySchema({
    amountLabel: 'Regular premium for cover value',
    currencyLabel: 'Regular premium for cover currency'
  })
}).noUnknown(true)
