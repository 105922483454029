import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { ErrorPage, Spinner } from '../../misc'
import { ClientSection } from './ClientSection'
import ErrorMessage from '../../misc/ErrorMessage'
import clientDetailsGql from '../../../graphql/queries/client/clientDetails.graphql'

const ClientDetailError = () => (
  <ErrorMessage text='Failed to retrieve client details' />
)

const ClientSectionContainer = () => {
  const { clientId } = useParams()

  const { loading, error, data } =
  useQuery(clientDetailsGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  if (loading && !data) {
    return <Spinner />
  }

  if (error) {
    return (
      <ErrorPage {...error}>
        <ClientDetailError />
      </ErrorPage>
    )
  }

  return <ClientSection client={data?.client} />
}

export { ClientSectionContainer }
