import PropTypes from 'prop-types'

import { EmptyPage } from '../../misc'

const ReceivedIncomeEmpty = ({ text }) => (
  <EmptyPage
    text={text}
    hidden={false}
  />
)

ReceivedIncomeEmpty.defaultProps = {
  text: 'No received income found!'
}

ReceivedIncomeEmpty.propTypes = {
  text: PropTypes.string.isRequired
}

export { ReceivedIncomeEmpty }
