import { ConfirmationModal, Checkbox } from '../../../misc'

const Trigger = ({ className, ...props }) => (
  <div className={className}>
    <Checkbox
      className={`${className}__checkbox`}
      {...props}
    />
    <span> visible</span>
  </div>
)

const VisibleForClientCheckbox = (props) => {
  const { className, clientDocument: { visibleForClient }, confirmationCallback, clientDocument, disabled } = props

  return (
    <ConfirmationModal.Trigger
      as={Trigger}
      inputId={clientDocument.id}
      checked={visibleForClient}
      readOnly
      disabled={disabled}
      className={className}
      modalProps={{
        confirmationCallback,
        heading: `Make document ${visibleForClient ? 'invisible' : 'visible'}`,
        text: `Are you sure you want to make this document ${visibleForClient
          ? 'invisible'
          : 'visible'} for the client?`,
        awaitCallback: true
      }}
    />
  )
}

export default VisibleForClientCheckbox
