import { useState, useEffect } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { Tooltip } from '../Tooltip'
import { List } from './List'
import Heading from './../../Heading/Heading'
import Button from '../../Button/'
import CloseModalIcon from '../../../icons/CloseModal'

import './ListTooltip.less'
/**
 * Tooltip used for displaying messages
 * such as `This entity can't be deleted`
 */
const tooltipProps = { events: { click: true, hover: false }, isList: true }

const OnRenderComponent = ({ children, setPersist }) => {
  useEffect(() => setPersist(true), [])
  return (
    <>
      <Button
        className='list-tooltip__close-button'
        onClick={() => setPersist(false)}
      >
        <CloseModalIcon />
      </Button>
      {children}
    </>
  )
}

export const ListTooltip = ({ title, list, className, ...restProps }) => {
  const [persist, setPersist] = useState(false)
  const onRenderFunc = ({ children }) => <OnRenderComponent {... { setPersist, children }} />
  return (
    <Tooltip
      className={classnames('list-tooltip', className)}
      {...{ ...tooltipProps, ...restProps, persist, onRenderFunc }}
    >
      <Heading as='h3' className='list-tooltip__heading'>
        {title}
      </Heading>
      <List list={list} />
    </Tooltip>
  )
}

ListTooltip.defaultProps = {
  delay: { show: 0, hide: 0 },
  title: 'Title',
  list: []
}

ListTooltip.propTypes = {
  /**
   * Text that should be shown in the tooltip.
   */
  title: T.string.isRequired,
  /**
   * Custom class name for the tooltip.
   */
  className: T.string,
  /**
   * Delay for showing/hiding the tooltip.
   */
  delay: T.shape({
    show: T.number,
    hide: T.number
  }),
  /**
   * List prop for List component.
   */
  list: T.arrayOf(T.node).isRequired
}
