import { useEffect, useCallback } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { DropdownMenu, Badge } from '../../misc'
import { EnvelopeIcon, DropdownIcon, CloseModalIcon } from '../../icons'
import { MessagesNotificationList } from './MessagesNotificationList'

import './UserMessagesNotificationsMenu.less'

const closeIconClass =
  'user-messages-menu-notifications__content__mobile-header__close-icon'

const UserMessagesNotificationsMenu = props => {
  const {
    unreadMessageThreadsList,
    navigateOnMsgClick,
    subscribeForUnreadMessages,
    totalUnreadMessages,
    isClientPortal,
    behaveAsModalOnMobile,
    navigateToMessagesTabClientPortal
  } = props

  const onNavigateToMessageTab = useCallback(
    () => navigateToMessagesTabClientPortal && isClientPortal && navigateToMessagesTabClientPortal(),
    [navigateToMessagesTabClientPortal]
  )

  useEffect(() => {
    let unsubscribe
    if (subscribeForUnreadMessages) {
      unsubscribe = subscribeForUnreadMessages()
    }
    return () => unsubscribe && unsubscribe()
  }, [subscribeForUnreadMessages, isClientPortal])

  return (
    <DropdownMenu
      className={classnames(
        'user-messages-menu-notifications',
        isClientPortal && 'user-messages-menu-notifications--client-portal'
      )}
      contentClassName='user-messages-menu-notifications__content'
      direction={DropdownMenu.Directions.RIGHT}
      behaveAsModalOnMobile={behaveAsModalOnMobile && !isClientPortal}
      closeIconClass={closeIconClass}
      trigger={
        <DropdownMenu.Trigger
          className='user-messages-menu-notifications__trigger'
          icon={DropdownIcon}
        >
          <div className='user-messages-menu-notifications__trigger__label' onClick={onNavigateToMessageTab}>
            <EnvelopeIcon className='user-messages-menu-notifications__trigger__label__envelope-icon' />
            {!!totalUnreadMessages && (
              <Badge
                color={Badge.Colors.BLUE}
                className='user-messages-menu-notifications__trigger__label__badge'
              >
                {totalUnreadMessages}
              </Badge>
            )}
          </div>
        </DropdownMenu.Trigger>
      }
    >
      <>
        {!isClientPortal && (
          <>
            <div className='user-messages-menu-notifications__content__mobile-header'>
              Messages
              <CloseModalIcon className={closeIconClass} />
            </div>
            <MessagesNotificationList
              {...{ unreadMessageThreadsList, navigateOnMsgClick }}
            />
          </>
        )}
      </>
    </DropdownMenu>
  )
}

UserMessagesNotificationsMenu.defaultProps = {
  unreadMessageThreadsList: [],
  navigateOnMsgClick: () => {}
}

UserMessagesNotificationsMenu.propTypes = {
  unreadMessageThreadsList: T.arrayOf(
    T.shape({
      count: T.number.isRequired,
      clientId: T.string.isRequired,
      client: T.shape({ fullName: T.string.isRequired }),
      createdAt: T.string.isRequired,
      body: T.string
    })
  ),
  navigateOnMsgClick: T.func.isRequired,
  subscribeForUnreadMessages: T.func,
  totalUnreadMessages: T.number,
  isClientPortal: T.bool,
  behaveAsModalOnMobile: T.bool,
  navigateToMessagesTabClientPortal: T.func
}

export { UserMessagesNotificationsMenu }
