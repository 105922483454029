const SuccessIcon = ({ fill = '#cfe6d0', backgroundFill = '#4fb23d', ...props }) => (
  <svg width='16px' height='16px' viewBox='0 0 16 16' {...props}>
    <path fill={backgroundFill} d='M2 4h11v10H5z' />
    <path
      fill={fill}
      d='M8 0a8 8 0 110 16A8 8 0 018 0zm-.56 11.01l4.07-4.07a.999.999 0 10-1.414-1.415L6.732 8.89l-.829-.829A1 1 0 004.49 9.475l1.536 1.535a.999.999 0 001.414 0z'
    />
  </svg>
)

export default SuccessIcon
