const PoliciesIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M11.042 3.178a.68.68 0 01.916 0c.029.025 2.913 2.606 6.36 2.606.377 0 .682.306.682.685v5.34c0 1.959-.467 3.32-1.78 5.447-1.31 2.128-4.38 3.475-5.722 3.744-1.475-.338-4.316-1.502-5.721-3.747C4.372 15.008 4 13.766 4 11.81V6.469c0-.379.305-.685.682-.685 3.462 0 6.331-2.58 6.36-2.606zm.458 1.394c-.954.743-3.29 2.326-6.136 2.554v4.684c0 3.674 2.572 6.87 6.136 7.65 3.564-.78 6.136-3.976 6.136-7.65V7.126c-2.847-.228-5.182-1.81-6.136-2.554zM11.5 7c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5zm0 1.429A3.575 3.575 0 007.929 12a3.575 3.575 0 003.571 3.571A3.575 3.575 0 0015.071 12 3.575 3.575 0 0011.5 8.429zm0 1.428c.362 0 .66.269.708.618l.006.096v.715h.715a.714.714 0 01.096 1.422l-.096.006H11.5a.714.714 0 01-.708-.617L10.786 12v-1.429c0-.394.32-.714.714-.714z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { PoliciesIcon }
