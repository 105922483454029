const CloseXIcon = (props) => (
  <svg
    width={24} height={24} viewBox='0 0 24 24' {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M18.364 5.636a.997.997 0 01.005 1.41L13.414 12l4.955 4.954c.36.36.386.928.073 1.322l-.078.088a.997.997 0 01-1.41.005L12 13.414 7.046 18.37a.993.993 0 01-1.322.073l-.088-.078a.997.997 0 01-.005-1.41L10.586 12 5.63 7.046a.994.994 0 01.005-1.41.997.997 0 011.41-.005L12 10.586l4.954-4.955a.993.993 0 011.322-.073l.088.078z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export default CloseXIcon
