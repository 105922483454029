import { useCallback, useEffect, useState } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { reject, propEq, isEmpty } from 'ramda'

import { withCurrentUser } from './../../../hoc/container'
import { TaskDetailsForm } from './TaskDetailsForm'
import { Toastr } from '../../../misc'
import { useTasksGql } from './../../../../hooks'
import { useWorkflowGql } from './../../../../hooks/useWorkflowGql'

import { buildAssigneeOptions } from '../../helpers'
import { mapFilesToAttachments, parseFilesToCreate, mapFile, parseFilesToLocalState } from '../../../../helpers/file'
import { normalizeFiles, uploadFile } from './helpers'

import taskAttachmentGql from '../../../../graphql/queries/tasks/taskAttachmentDownloadUrl.graphql'
import companyUsersGql from '../../../../graphql/queries/user/companyUsers.graphql'
import createTaskAttachmentsGql from '../../../../graphql/mutations/tasks/createTaskAttachments.graphql'

const TaskDetailsFormContainer = props => {
  const {
    task,
    onDone,
    currentUser,
    onTaskClick,
    newTask,
    onAddSubtaskToNewTask,
    isSubtask,
    onUpdateSubtaskOfNewTask,
    onParentTaskClick,
    refetchLogEntries,
    onRemoveSubtaskOfNewTask,
    clientId,
    queryFilters,
    isOpen,
    modalHeader
  } = props

  const isUpdate = !newTask
  const isWorkflow = task.workflowInstanceId

  const {
    createTask,
    loadingOnCreateTask,
    updateTask,
    loadingOnUpdateTask,

    createSubtask,
    loadingOnCreateSubtask,
    errorOnCreateSubtask,
    updateSubtask,
    loadingOnUpdateSubtask,

    removeTask,
    removeSubtask
  } = useTasksGql({ task, clientId, queryFilters })

  const { fetchWorkflowInstance, workflowInstanceData } = useWorkflowGql()

  const [fileCreateMutation] = useMutation(createTaskAttachmentsGql)

  const [mainTaskChanges, setMainTaskChanges] = useState({})
  const [attachments, setAttachments] = useState([])
  const [taskSubmitLoading, setTaskSubmitLoading] = useState(false)

  useEffect(() => {
    if (task?.attachments && !isEmpty(task.attachments)) {
      setAttachments([...task?.attachments])
    } else {
      setAttachments([])
    }
  }, [task?.attachments])

  const onFilesSelect = useCallback(
    fileList => {
      if (!fileList.length) {
        return null
      }
      setAttachments(mapFilesToAttachments(attachments, fileList))
    },
    [clientId, attachments]
  )

  const onRemoveAttachment = useCallback((id, fileName) => {
    setAttachments(reject(propEq('fileName', fileName)))
  }, [])

  const [downLoadFile] = useLazyQuery(taskAttachmentGql, {
    onCompleted: ({ taskAttachmentDownloadUrl }) => (
      window.location.assign(taskAttachmentDownloadUrl)
    )
  })

  const handleDownload = useCallback(fileId =>
    downLoadFile({ variables: { fileId } })
  )

  const createFileHandler = useCallback(async () => {
    if (attachments && attachments.length) {
      const filteredUploadedFiles = attachments.filter(item => !Object.prototype.hasOwnProperty.call(item, 'file'))
      const mappedFilesToCreate = mapFile(attachments)

      if (mappedFilesToCreate && mappedFilesToCreate.length) {
        setTaskSubmitLoading(true)
        try {
          const { data: { createTaskAttachments } } = await fileCreateMutation({
            variables: {
              input: {
                clientId,
                files: parseFilesToCreate(mappedFilesToCreate)
              }
            }
          })
          const filesToUpload = parseFilesToLocalState(
            createTaskAttachments,
            attachments
          )

          await Promise.all([
            ...filesToUpload?.map(({ uploadUrl, file }) => uploadFile(uploadUrl, file))
          ])

          setTaskSubmitLoading(false)
          return normalizeFiles([...filteredUploadedFiles, ...filesToUpload])
        } catch (error) {
          setTaskSubmitLoading(false)
          throw error
        }
      }
      return normalizeFiles(attachments)
    }
    return []
  }, [clientId, attachments])

  const taskLoading = loadingOnCreateTask || loadingOnUpdateTask

  const onUpdateTask = async input => {
    const mutateTask = isUpdate ? updateTask : createTask
    if (!isUpdate) {
      input.subtasks = input.subtasks.map(subtask => ({
        ...subtask,
        assignedTo: subtask?.assignedTo?.id,
        clientId: undefined,
        client: undefined
      }))
    }
    const fileUploadResults = await createFileHandler()

    await mutateTask({
      variables: {
        input: {
          ...input,
          attachments: fileUploadResults
        }
      }
    })
  }

  const onRemoveTask = useCallback(
    async (id, parentId) => {
      if (newTask && isSubtask) onRemoveSubtaskOfNewTask()
      if (!newTask) {
        if (!isSubtask) await removeTask({ variables: { id } })
        if (isSubtask) await removeSubtask({ variables: { id, parentId } })
      }
    },
    [newTask, isSubtask]
  )

  const { data: usersData } = useQuery(companyUsersGql)
  const assigneeOptions = buildAssigneeOptions({
    users: usersData?.users || [],
    currentUser
  })

  const mapValues = useCallback(
    values => {
      const getById = (id, arr) => arr?.find(item => item.id === id)

      if (values.assignedTo) {
        values.assignedTo = getById(values.assignedTo, usersData?.users)
      }

      if (values.clientId) {
        values.client = getById(values.clientId)
      }

      return values
    },
    [usersData]
  )

  const onDoneHandler = useCallback(
    ({ showToast, isSaveAndNew, isDelete }) => {
      if (showToast) {
        toast(<Toastr type='success' title='activity log has been created' />)
        if (refetchLogEntries) refetchLogEntries()
      }

      onDone({ isSaveAndNew, isDelete })
    },
    [onDone]
  )

  const onCreateSubtask = useCallback(
    async ({ summary, taskId: parentId }, values) => {
      if (newTask) {
        onAddSubtaskToNewTask({ summary }, mapValues(values))
      } else {
        setMainTaskChanges(values)
        await createSubtask({ variables: { input: { summary, parentId } } })
      }
    },
    [createSubtask, newTask]
  )

  const onUpdateSubtask = useCallback(
    async input => {
      if (newTask) {
        onUpdateSubtaskOfNewTask(mapValues(input))
        onDone()
      } else {
        const fileUploadResults = await createFileHandler()

        await updateSubtask({
          variables: {
            input: {
              ...input,
              attachments: fileUploadResults
            }
          }
        })
      }
    },
    [onUpdateSubtaskOfNewTask, onDone, updateSubtask, attachments]
  )

  const onTaskClickWrapper = useCallback((task, index, values) => {
    onTaskClick(task, index)
    setMainTaskChanges(values)
  }, [])

  useEffect(() => {
    if (isOpen && isWorkflow) {
      fetchWorkflowInstance({ variables: { id: task.workflowInstanceId } })
    }
  }, [isOpen, isWorkflow])

  return (
    <TaskDetailsForm
      {...{
        onDone: onDoneHandler,
        onSubmit: isSubtask ? onUpdateSubtask : onUpdateTask,
        loadingOnSubmit: loadingOnUpdateSubtask || taskLoading,
        onCreateSubtask,
        loadingOnCreateSubtask:
          loadingOnUpdateSubtask || taskLoading || loadingOnCreateSubtask,
        errorsOnCreateSubtask: errorOnCreateSubtask?.message || '',
        assigneeOptions,
        task,
        isSubtask,
        onTaskClick: onTaskClickWrapper,
        newTask,
        onParentTaskClick,
        onRemoveTask,
        isUpdate,
        clientId,
        mainTaskChanges,
        workflow: {
          id: task.workflowInstanceId,
          name: workflowInstanceData?.workflowInstance?.name
        },
        currentUser,
        modalHeader,
        attachments,
        onRemoveAttachment,
        onFilesSelect,
        taskSubmitLoading,
        handleDownload
      }}
    />
  )
}

const EnhancedTaskDetailsFormContainer = withCurrentUser()(
  TaskDetailsFormContainer
)
export { EnhancedTaskDetailsFormContainer as TaskDetailsFormContainer }
