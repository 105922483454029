import { useQuery } from '@apollo/client'

import teamsGql from '../../../../graphql/queries/teams/teams.graphql'

const withTeams = ({
  fetchPolicy = 'cache-and-network'
} = {}) => WrappedComponent => props => {
  const { data, error, loading } = useQuery(teamsGql, {
    fetchPolicy
  })

  const initialLoading = loading && !data

  const teams = data?.teams || []

  return (
    <WrappedComponent
      teamsLoading={initialLoading}
      teamsError={error}
      teams={teams}
      {...props}
    />
  )
}

export { withTeams }
