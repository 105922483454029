import { DashboardBoxesCard } from '../DashboardBoxesCard'
import { DashboardCardTitle } from '../DashboardCardTitle'

import './ClientDashboardCard.less'

const ClientsDashboardCard = ({ cardProps, titleProps, onCardClick }) => (
  <div className='clients-dashboard-card'>
    <DashboardCardTitle {...titleProps}> Clients </DashboardCardTitle>
    <DashboardBoxesCard onCardClick={onCardClick} {...cardProps} />
  </div>
)

export { ClientsDashboardCard }
