import classnames from 'classnames'

import './Logo.less'

const Logo = ({ as: Component, logoUrl, className, ...restProps }) => (
  <Component
    src={logoUrl}
    className={classnames('logo-image', { [`${className}__logo`]: className })}
    {...restProps}
  />
)

export { Logo }
