import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import Policies from './Policies'
import { Spinner, ErrorMessage } from '../../misc'
import { splitToActiveAndExpiredByEndDate } from '../../../helpers/policy'

import policiesTableGql from '../../../graphql/queries/policy/policiesTable.graphql'

const PoliciesContainer = props => {
  const { clientId: selectedClientId } = useParams()
  const { clientId: localClientId, isClientPortal } = props

  const clientId = selectedClientId || localClientId
  const { loading, error, data } = useQuery(policiesTableGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) return <Spinner />
  if (error) return <ErrorMessage text='Failed to retrieve client policies' />

  const { policies } = data
  const { activeList, expiredList } = splitToActiveAndExpiredByEndDate(policies)

  return (
    <Policies
      clientId={clientId}
      policies={policies}
      activePolicies={activeList}
      expiredPolicies={expiredList}
      readOnly={isClientPortal}
    />
  )
}

export { PoliciesContainer }
