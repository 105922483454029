import { useEffect, useReducer, useCallback } from 'react'
import classnames from 'classnames'

import FileUploadTrackerHeader from './FileUploadTrackerHeader'
import FileUploadTrackerStatus from './FileUploadTrackerStatus'
import fileUploadManager from '../../../helpers/fileUploadManager'

import './FileUploadTracker.less'

const {
  UPLOAD_START,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE
} = fileUploadManager.EVENTS

const ActionTypes = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  UPLOAD_STARTED: 'UPLOAD_STARTED',
  UPLOAD_SUCCEEDED: 'UPLOAD_SUCCEEDED',
  UPLOAD_FAILED: 'UPLOAD_FAILED'
}

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SHOW:
      return {
        ...state,
        isVisible: true
      }

    case ActionTypes.HIDE:
      return {
        ...state,
        isVisible: false,
        allUploadsCount: 0,
        succeededUploadsCount: 0,
        failedUploadsCount: 0
      }

    case ActionTypes.UPLOAD_STARTED:
      return {
        ...state,
        isVisible: true,
        allUploadsCount: state.allUploadsCount + 1
      }

    case ActionTypes.UPLOAD_SUCCEEDED:
      return {
        ...state,
        succeededUploadsCount: state.succeededUploadsCount + 1
      }

    case ActionTypes.UPLOAD_FAILED:
      return {
        ...state,
        failedUploadsCount: state.failedUploadsCount + 1
      }

    default:
      return state
  }
}

const FileUploadTracker = () => {
  const [state, dispatch] = useReducer(reducer, {
    isVisible: false,
    allUploadsCount: 0,
    succeededUploadsCount: 0,
    failedUploadsCount: 0
  })

  const {
    isVisible,
    allUploadsCount,
    succeededUploadsCount,
    failedUploadsCount
  } = state

  const completedUploadsCount = succeededUploadsCount + failedUploadsCount
  const isAllCompleted = allUploadsCount === completedUploadsCount

  const hide = useCallback(
    () => dispatch({ type: ActionTypes.HIDE }),
    []
  )

  useEffect(
    () => {
      const onUploadStart = () =>
        dispatch({ type: ActionTypes.UPLOAD_STARTED })

      const onUploadSuccess = () =>
        dispatch({ type: ActionTypes.UPLOAD_SUCCEEDED })

      const onUploadFailure = () =>
        dispatch({ type: ActionTypes.UPLOAD_FAILED })

      fileUploadManager.on(UPLOAD_START, onUploadStart)
      fileUploadManager.on(UPLOAD_SUCCESS, onUploadSuccess)
      fileUploadManager.on(UPLOAD_FAILURE, onUploadFailure)

      return () => {
        fileUploadManager.off(UPLOAD_START, onUploadStart)
        fileUploadManager.off(UPLOAD_SUCCESS, onUploadSuccess)
        fileUploadManager.off(UPLOAD_FAILURE, onUploadFailure)
      }
    },
    []
  )

  return (
    <div
      className={classnames('file-upload-tracker', {
        hidden: !isVisible
      })}
    >
      <FileUploadTrackerHeader
        isCloseButtonVisible={isAllCompleted}
        onCloseButtonClick={hide}
      />
      <FileUploadTrackerStatus
        allUploadsCount={allUploadsCount}
        succeededUploadsCount={succeededUploadsCount}
        failedUploadsCount={failedUploadsCount}
      />
    </div>
  )
}

export default FileUploadTracker
