import { PageContent } from './../../misc'
import { DashboardBoxesCard } from '../DashboardBoxesCard'
import { DashboardBoxCardCount } from '../DashboardBoxCardCount'
import { DoughnutCard } from '../DoughnutCard'
import { MessagesDashboardCard } from '../MessagesDashboardCard'
import { SignaturesDashboardCard } from '../SignaturesDashboardCard'
import { isCorporateClient } from '../../../helpers/client'

import './ClientDashboardPage.less'

const baseClass = 'client-dashboard-page'

const ClientDashboardPage = props => {
  const {
    client,
    currentFAProps,
    nextReviewProps,
    messagesCountProps,
    policiesCountProps,
    documentsCountProps,
    monthlyIncomeProps,
    monthlyExpenditureProps,
    AUMProps
  } = props

  return (
    <PageContent className={baseClass}>
      <div className={`${baseClass}__review-and-adviser`}>
        <DashboardBoxesCard {...nextReviewProps} />
        <DashboardBoxesCard {...currentFAProps} />
      </div>
      <div className={`${baseClass}__counts`}>
        <DashboardBoxCardCount {...messagesCountProps} />
        <DashboardBoxCardCount {...policiesCountProps} />
        <DashboardBoxCardCount {...documentsCountProps} />
      </div>

      <div>
        <MessagesDashboardCard isClientPortal client={client} />

        <SignaturesDashboardCard isClientPortal />
      </div>

      <div className={`${baseClass}__charts`}>
        {
          !isCorporateClient(client) &&
            <>
              <DoughnutCard {...monthlyIncomeProps} />
              <DoughnutCard {...monthlyExpenditureProps} />
            </>
        }
        <DoughnutCard {...AUMProps} />
      </div>
    </PageContent>
  )
}

export { ClientDashboardPage }
