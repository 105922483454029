import constants from '@elenfs/elen-constants'

const {
  Gender,
  MaritalStatus,
  EmploymentStatus,
  ClientTaxRates,
  ClientRelationshipTypes
} = constants.client

export const GenderLabels = Object.freeze({
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female'
})

export const MaritalStatusLabels = Object.freeze({
  [MaritalStatus.SINGLE]: 'Single',
  [MaritalStatus.MARRIED]: 'Married',
  [MaritalStatus.CIVIL_UNION]: 'Civil union',
  [MaritalStatus.DIVORCED]: 'Divorced',
  [MaritalStatus.COHABITING]: 'Cohabiting',
  [MaritalStatus.WIDOWED]: 'Widowed',
  [MaritalStatus.PARTNERED]: 'Partnered',
  [MaritalStatus.SEPARATED]: 'Separated'
})

export const EmploymentStatusLabels = Object.freeze({
  [EmploymentStatus.EMPLOYED]: 'Employed',
  [EmploymentStatus.SELF_EMPLOYED]: 'Self employed',
  [EmploymentStatus.UNEMPLOYED]: 'Unemployed',
  [EmploymentStatus.RETIRED]: 'Retired'
})

export const ClientTaxRatesLabels = Object.freeze({
  [ClientTaxRates.ADDITIONAL_RATE_TAXPAYER]: 'Additional rate taxpayer',
  [ClientTaxRates.BASIC_RATE_TAXPAYER]: 'Basic rate taxpayer',
  [ClientTaxRates.HIGHER_RATE_TAXPAYER]: 'Higher rate taxpayer',
  [ClientTaxRates.NON_TAXPAYER]: 'Non taxpayer'
})

export const ClientRelationshipLabels = Object.freeze({
  [ClientRelationshipTypes.SPOUSE]: 'Spouse',
  [ClientRelationshipTypes.PARTNER]: 'Partner',
  [ClientRelationshipTypes.BUSINESS_PARTNER]: 'Business Partner',
  [ClientRelationshipTypes.CO_TRUSTEE]: 'Co-Trustee',
  [ClientRelationshipTypes.PARENT]: 'Parent',
  [ClientRelationshipTypes.CHILD]: 'Child',
  [ClientRelationshipTypes.GRANDPARENT]: 'Grandparent',
  [ClientRelationshipTypes.GRANDCHILD]: 'Grandchild'
})
