import constants from '@elenfs/elen-constants'

const {
  DeathBenefits,
  Guarantees,
  HeldInTrustValues,
  PlanStatuses,
  InvestmentTypesLabels
} = constants.policy.investmentPolicy

export const DeathBenefitsLabels = Object.freeze({
  [DeathBenefits.RETURN_OF_CONTRIBUTIONS]: 'Return of contributions',
  [DeathBenefits.RETURN_OF_CONTRIBUTIONS_WITH_INTEREST]: 'Return of contributions with interest',
  [DeathBenefits.RETURN_OF_FUND]: 'Return of fund',
  [DeathBenefits.RETURN_OF_FUND_PLUS_LIFE_COVER]: 'Return of fund plus life cover'
})

export const GuaranteesLabels = Object.freeze({
  [Guarantees.GUARANTEED_MVA_FREE_DATE]: 'Guaranteed MVA-free date',
  [Guarantees.GUARANTEED_BONUS_RATE]: 'Guaranteed bonus rate',
  [Guarantees.GUARANTEED_FUTURE_FUND_VALUE]: 'Guaranteed future fund value',
  [Guarantees.GUARANTEED_GROWTH_RATE]: 'Guaranteed growth rate',
  [Guarantees.GUARANTEED_INCOME_BASE]: 'Guaranteed income base',
  [Guarantees.OTHER_GUARANTEE]: 'Other guarantee'
})

export const HeldInTrustLabels = Object.freeze({
  [HeldInTrustValues.NOT_IN_TRUST]: 'No',
  [HeldInTrustValues.YES]: 'Yes',
  [HeldInTrustValues.YES_BARE_TRUST]: 'Bare trust',
  [HeldInTrustValues.YES_DISCRETIONARY_TRUST]: 'Discretionary trust'
})

export const PlanStatusesLabels = Object.freeze({
  [PlanStatuses.APPLICATION_SUBMITTED]: 'Application submitted',
  [PlanStatuses.NTU]: 'Not Taken Up',
  [PlanStatuses.LIVE]: 'Live',
  [PlanStatuses.TRANSFERRED_OUT]: 'Transferred out',
  [PlanStatuses.SURRENDERED]: 'Surrendered'
})

export { InvestmentTypesLabels }
