import { useRef } from 'react'
import T from 'prop-types'
import classnames from 'classnames'
import 'chart.js/auto'
import 'chartjs-adapter-moment'

import { Line } from 'react-chartjs-2'
import { emptyData } from './helpers'

const LineChart = ({ className, options, data, isEmpty }) => {
  const ref = useRef()

  return (
    <div className={classnames('line-chart', className)}>
      <Line {...{
        ref,
        options,
        data: isEmpty ? emptyData : data
      }}
      />
    </div>
  )
}

LineChart.defaultProps = {
  isEmpty: false,
  emptyText: 'No data found'
}

LineChart.propTypes = {
  data: T.object,
  options: T.object,
  title: T.string,
  isEmpty: T.bool,
  emptyText: T.string
}

export { LineChart }
