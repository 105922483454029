import { Modal } from '../../misc'
import { TeamModalForm } from './TeamModalForm'

import './TeamModal.less'

const TeamModal = props => {
  const { isOpen, close, team, userFA, ...restProps } = props

  return (
    <Modal
      {...{
        className: 'team-modal',
        isOpen,
        close,
        ...restProps
      }}
    >
      <div className='team-modal__content'>
        <Modal.Heading className='team-modal__content__header'>
          {userFA.fullName}
          <span className='team-modal__content__header__role-hint'>
            Financial Adviser
          </span>
        </Modal.Heading>

        <TeamModalForm isOpen={isOpen} close={close} team={team} />
      </div>
    </Modal>
  )
}

export { TeamModal }
