import MortgagePolicyForm from './MortgagePolicyForm'
import policyFormContainer from '../../PolicyFormContainer'
import createMortgagePolicy from '../../../../graphql/mutations/policy/createMortgagePolicy.graphql'
import updateMortgagePolicy from '../../../../graphql/mutations/policy/updateMortgagePolicy.graphql'

const MortgagePolicyFormContainer = policyFormContainer({
  createPolicyGql: createMortgagePolicy,
  updatePolicyGql: updateMortgagePolicy
})(MortgagePolicyForm)

export default MortgagePolicyFormContainer
