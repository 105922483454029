import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { ClientSummaryPage } from './ClientSummaryPage'
import Spinner from '../../misc/Spinner'
import ErrorMessage from '../../misc/ErrorMessage'
import clientDetailsGql from '../../../graphql/queries/client/clientDetails.graphql'
import activityLogListGql from '../../../graphql/queries/client/activityLogList.graphql'
import { CorporateClientSummaryDetailsCard } from '../../corporateClient/CorporateClientSummaryDetailsCard'
import { IndividualClientSummaryDetailsCard } from '../IndividualClientSummaryDetailsCard'
import { isCorporateClient } from '../../../helpers/client'

const ClientSummaryPageError = () => (
  <ErrorMessage text='Failed to retrieve client summary' />
)

const ClientSummaryPageContainer = (props) => {
  const { clientId } = useParams()

  const { loading: clientLoading, error: clientError, data: clientData } = useQuery(clientDetailsGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  const { data: activityLogData, refetch } = useQuery(activityLogListGql, {
    variables: { clientId, limit: 5 },
    fetchPolicy: 'cache-and-network'
  })

  if (clientLoading && !clientData) {
    return <Spinner />
  }

  if (clientError) {
    return <ClientSummaryPageError />
  }

  return (
    <ClientSummaryPage {...{
      client: clientData?.client || {},
      refetch,
      auditLogEntries: activityLogData?.activityLogEntries || [],
      ClientSpecificDetailsCard: clientData?.client && isCorporateClient(clientData.client)
        ? CorporateClientSummaryDetailsCard
        : IndividualClientSummaryDetailsCard,
      ...props
    }}
    />
  )
}

export { ClientSummaryPageContainer }
