import T from 'prop-types'

import { PercentInput } from '../../inputs'
import { BaseField } from '../BaseField'

/**
 * Form field to be used where percentage inputs are needed.
 */
const PercentField = ({ value, handleChange, inputProps, error, label, name }) => (
  <BaseField error={error} label={label}>
    <PercentInput id={name} value={value} handleChange={handleChange} {...inputProps} />
  </BaseField>
)

PercentField.propTypes = {
  /**
   * Value to be passed to the PercentInput.
   */
  value: T.oneOfType([T.number, T.string]),
  /**
   * Props to be passed to the PercentInput.
   */
  inputProps: T.object,
  /**
   * Function that receives new values from the PercentInput.
   */
  handleChange: T.func,
  /**
   * Error from Formik.
   */
  error: T.string,
  /**
   * Label to be displayed in the field.
   */
  label: T.string
}

export { PercentField }
