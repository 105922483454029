const Retry = props => (
  <svg
    width={25}
    height={24}
    viewBox='0 0 25 24'
    {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M.042 0h24v24h-24z' />
      <path
        d='M13.559 4.74l.078.083c.32.38.272.946-.11 1.267l-1.44 1.206a6.113 6.113 0 011.53.209 6.052 6.052 0 013.7 2.841 6.063 6.063 0 01.609 4.629 6.063 6.063 0 01-2.841 3.704 6.052 6.052 0 01-4.625.61 6.102 6.102 0 01-4.393-7.109l.002-.01.003-.01a.357.357 0 01.438-.253l1.339.358c.18.049.292.227.258.41a3.988 3.988 0 00.547 2.852 3.981 3.981 0 002.35 1.735c2.128.57 4.325-.7 4.896-2.83A4.004 4.004 0 0011.864 9.4l1.244 1.482c.32.38.272.945-.112 1.267a.898.898 0 01-1.267-.11L8.39 8.06l.005-.003-.005-.008L12.37 4.71a.897.897 0 011.189.029z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export default Retry
