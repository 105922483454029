import ImportPlatformLayout from './ImportPlatformLayout'
import LeftColumn from './ImportPlatformLayoutLeftColumn'
import RightColumn from './ImportPlatformLayoutRightColumn'
import Header from './ImportPlatformHeader'

ImportPlatformLayout.LeftColumn = LeftColumn
ImportPlatformLayout.RightColumn = RightColumn
ImportPlatformLayout.Header = Header

export default ImportPlatformLayout
