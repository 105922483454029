import T from 'prop-types'
import classnames from 'classnames'

import { useOnChangeCallback } from '../../inputs/hooks'

import './TextArea.less'

const TextArea = ({
  className,
  handleChange,
  inputRef,
  handleEsc,
  handleOnLongPress,
  ...rest
}) => (
  <textarea
    ref={inputRef}
    onKeyDown={(event) => handleEsc && handleEsc(event)}
    className={classnames('text-area-new', className)}
    onChange={useOnChangeCallback(handleChange)}
    {...rest}
  />
)

TextArea.defaultProps = {
  type: 'text',
  handleChange: () => {},
  rows: 5
}

TextArea.propTypes = {
  /**
   * Class name to be added to the textarea.
   */
  className: T.string,
  /**
   * Type to be passed to the textarea.
   */
  type: T.string,
  /**
   * Function that receives a new value when it gets changed.
   */
  handleChange: T.func,
  /**
   * Value to be passed to the textarea.
   */
  value: T.any
}

export { TextArea }
