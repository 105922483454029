import { Modal } from '../../../misc'
import { SeatsForm } from './SeatsForm'

import './SeatsModal.less'

const SeatsModal = ({ trigger, isOpen, close, ...props }) => (
  <Modal {...{ isOpen, close, trigger }}>
    <Modal.Heading className='seats-modal__heading'>
      Update licenses
    </Modal.Heading>
    <SeatsForm {...{ ...props, onDone: close }} />
  </Modal>
)

export { SeatsModal }
