import { Route, Routes } from 'react-router-dom'

import { WorkflowPage } from '../WorkflowPage'
import { WorkflowsPage } from '../WorkflowsPage'

const WorkflowsSwitch = () => (
  <Routes>
    <Route index element={<WorkflowsPage />} />
    <Route path='create' element={<WorkflowPage />} />
    <Route path='edit/:workflowId' element={<WorkflowPage />} />
  </Routes>
)

export { WorkflowsSwitch }
