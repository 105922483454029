import classnames from 'classnames'
import T from 'prop-types'

import { CloseModalIcon } from './../../icons'

import { Background, Size, TextAlign, CloseIconColor } from './const'

import './WarningBanner.less'

const WarningBanner = props => {
  const {
    children,
    background,
    textAlign,
    size,
    closeIconColor,
    onClose,
    className
  } = props

  return (
    <div
      className={classnames(
        'warning-banner',
        className,
        {
          [`warning-banner--${background}`]: background,
          [`warning-banner--${size}`]: size
        }
      )}
    >
      <div
        className={classnames(
          'warning-banner__content',
          textAlign && `warning-banner__content--${textAlign}`
        )}
      >
        {children}
      </div>

      {onClose && (
        <CloseModalIcon
          className={classnames(
            'warning-banner__close-icon',
            closeIconColor && `warning-banner__close-icon--${closeIconColor}`
          )}
          onClick={onClose}
        />
      )}
    </div>
  )
}

WarningBanner.defaultProps = {
  background: Background.YELLOW
}

WarningBanner.propTypes = {
  /**
   * Text and icons that should be rendered inside the banner.
   */
  children: T.node.isRequired,
  background: T.oneOf(Object.values(Background)),
  textAlign: T.oneOf(Object.values(TextAlign)),
  size: T.oneOf(Object.values(Size)),
  closeIconColor: T.oneOf(Object.values(CloseIconColor))
}

export { WarningBanner }
