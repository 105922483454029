import PropTypes from 'prop-types'
import classnames from 'classnames'

import Dropdown from '../Dropdown'
import { DropdownMenuDirections } from './constants'

import './DropdownMenu.less'

const DropdownMenu = ({
  children,
  className,
  contentClassName,
  trigger,
  direction,
  behaveAsModalOnMobile,
  closeIconClass,
  fullWidth
}) => (
  <Dropdown
    {...{
      className: classnames(
        'dropdown-menu',
        `dropdown-menu--${direction}`,
        {
          'dropdown-menu--full-width': fullWidth
        },
        className
      ),
      trigger,
      closeIconClass,
      contentClassName,
      behaveAsModalOnMobile
    }}
  >
    {children}
  </Dropdown>
)

DropdownMenu.defaultProps = {
  direction: DropdownMenuDirections.LEFT,
  fullWidth: true
}

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  trigger: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(Object.values(DropdownMenuDirections)),
  closeIconClass: PropTypes.string,
  behaveAsModalOnMobile: PropTypes.bool,
  fullWidth: PropTypes.bool
}

export default DropdownMenu
