import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { buildAdviserOptions } from '../../../../helpers/charge'

import financialAdvisersGql from '../../../../graphql/queries/user/financialAdvisers.graphql'

const WithFinancialAdvisers = ({
  fetchPolicy = 'cache-and-network'
} = {}) => WrappedComponent => props => {
  const { loading, data, error } = useQuery(financialAdvisersGql, {
    variables: { clientId: props?.match?.params?.clientId },
    fetchPolicy
  })

  const financialAdvisers = data?.users || []

  const financialAdviserOptions = useMemo(
    () => buildAdviserOptions(financialAdvisers),
    [financialAdvisers]
  )

  const financialAdvisersProps = {
    financialAdvisers,
    financialAdviserOptions,
    financialAdvisersLoading: loading,
    financialAdvisersError: error
  }

  return <WrappedComponent {...{ ...financialAdvisersProps, ...props }} />
}

export { WithFinancialAdvisers }
