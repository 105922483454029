import { ClientDetailsBaseSideMenu } from '../ClientDetailsBaseSideMenu'

const items = [
  { caption: 'Personal', to: 'personal' },
  { caption: 'Relationships', to: 'relationships' },
  { caption: 'Dependants', to: 'dependants' },
  { caption: 'Employment', to: 'employment' },
  { caption: 'Income & Expenditure', to: 'income-and-expenditure' }
]

const IndividualClientDetailsSideMenu = (props) => (
  <ClientDetailsBaseSideMenu
    clientSpecificItems={items}
    {...props}
  />
)

export { IndividualClientDetailsSideMenu }
