import T from 'prop-types'

import { Table, IncreasedOrDecreasedRecord } from '../../../../misc'
import { IncreaseIcon } from '../../../../icons'

import { formatMoney } from '../../../../../helpers/policy'
import { formatDate } from '../../../../../helpers/date'
import { PolicyValuationDetailsModal } from '../../PolicyValuationDetailsModal'
import { TableHeaders } from '../constants'
import { ActionList } from './ActionList'

import './PolicyValuationsTableRow.less'

const PolicyValuationsTableRow = ({
  policyValuation,
  policyId,
  className,
  previousValuation,
  readOnly
}) => {
  const { date, value, updatedByUser, updatedAt } = policyValuation

  return (
    <Table.Row className={className}>
      <Table.Cell
        data-label={TableHeaders.VALUATION_DATE}
        className='policy-valuations__table__row__cell'
      >
        {formatDate(date)}
      </Table.Cell>
      <Table.Cell
        data-label={TableHeaders.VALUATION_VALUE}
        className='policy-valuations__table__row__cell'
      >
        {formatMoney(value)}
        <IncreasedOrDecreasedRecord
          icon={IncreaseIcon}
          currentRecord={value}
          previousRecord={previousValuation?.value}
        />
      </Table.Cell>
      <Table.Cell className='policy-valuations__table__row__cell'>
        {
          !readOnly &&
            <>
              {updatedByUser && (
                <span className='text-description'>
                  {updatedByUser.fullName}
                </span>
              )}
              {updatedAt && (
                <span className='text-description'>
                  {formatDate(updatedAt, null, 'DD MMM YYYY - HH:mm')}
                </span>
              )}
            </>
        }
      </Table.Cell>
      <Table.Cell actionCell className='policy-valuations__table__row__cell'>
        <Table.Actions>
          {
            !readOnly &&
              <ActionList {...{
                policyId,
                policyValuation,
                PolicyValuationDetailsModal,
                className: 'policy-valuations__table__row__cell__action-list'
              }}
              />
          }
        </Table.Actions>
      </Table.Cell>
    </Table.Row>
  )
}

PolicyValuationsTableRow.propTypes = {
  policyValuation: T.object.isRequired
}

export { PolicyValuationsTableRow }
