import PropTypes from 'prop-types'
import pluralize from 'pluralize'

const FileUploadTrackerStatus = ({
  allUploadsCount,
  succeededUploadsCount,
  failedUploadsCount
}) => {
  const completedUploadsCount = succeededUploadsCount + failedUploadsCount
  const isAllCompleted = allUploadsCount === completedUploadsCount
  const uploadError = failedUploadsCount && failedUploadsCount > 0

  const textInProgress = 'Upload in progress...'
  const textResult = `Uploaded ${succeededUploadsCount} of ${allUploadsCount} files${uploadError ? ', ' : ''}`
  const textWithError =
    uploadError
      ? `${failedUploadsCount} ${pluralize('error', failedUploadsCount)}`
      : ''

  return (
    <div className='file-upload-tracker__status'>
      <div className='file-upload-tracker__status__completion'>
        <div>
          {
            isAllCompleted
              ? (
                <>
                  <span>{textResult}</span>
                  <span className='file-upload-tracker__status__errors'>{textWithError}</span>
                </>
                )
              : <span>{textInProgress}</span>
          }
        </div>
        <span>
          {`${succeededUploadsCount}/${allUploadsCount}`}
        </span>
      </div>
    </div>
  )
}

FileUploadTrackerStatus.propTypes = {
  allUploadsCount: PropTypes.number.isRequired,
  succeededUploadsCount: PropTypes.number.isRequired,
  failedUploadsCount: PropTypes.number.isRequired
}

export default FileUploadTrackerStatus
