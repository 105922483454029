import T from 'prop-types'
import { useParams } from 'react-router-dom'

import { Button, Modal } from '../misc'
import { PlusIcon } from '../icons'
import { ClientChargeDetailsModal, PolicyChargeDetailsModal } from './ChargeDetailsModal'
import { FEE, COMMISSION } from '../../constants/charge'

const labels = {
  [FEE]: 'New Fee',
  [COMMISSION]: 'New Commission'
}

const NewChargeButton = ({ chargeType, isPolicyCharges }) => {
  const { policyId } = useParams()

  return (
    <Modal.Trigger
      as={Button}
      color={Button.Colors.BLUE}
      modal={isPolicyCharges ? PolicyChargeDetailsModal : ClientChargeDetailsModal}
      modalProps={{ policyId, chargeType, isPolicyCharges }}
    >
      <PlusIcon />
      <span>{labels[chargeType]}</span>
    </Modal.Trigger>
  )
}

NewChargeButton.propTypes = {
  chargeType: T.oneOf([FEE, COMMISSION]).isRequired
}

export { NewChargeButton }
