import { Fragment } from 'react'
import T from 'prop-types'

import { componentType } from '../../../helpers/propTypes'
import { Tooltip } from './Tooltip'

/**
 * Hovering this element will result in showing the passed `tooltip`.
 */
export const TooltipTrigger = ({
  as: TriggerComponent,
  tooltip: TooltipComponent,
  tooltipProps,
  children,
  tooltipId,
  ...rest
}) => {
  const idAttribute = `data-tooltip-${tooltipId}`

  return (
    <>
      <TooltipComponent
        idAttribute={idAttribute}
        {...tooltipProps}
      />
      <TriggerComponent
        {...{
          // `react-hint` relies on this property to locate the trigger for the Tooltip.
          [idAttribute]: true
        }}
        {...rest}
      >
        {children}
      </TriggerComponent>
    </>
  )
}

TooltipTrigger.defaultProps = {
  tooltipProps: {},
  tooltip: Tooltip.Info
}

TooltipTrigger.propTypes = {
  /**
   * Render the trigger as this component.
   */
  as: componentType().isRequired,
  /**
   * Tooltip component to be rendered.
   * Should be a component extending the `Tooltip` component.
   */
  tooltip: componentType().isRequired,
  /**
   * Props for the tooltip component.
   */
  tooltipProps: T.object,
  /**
   * Unique id for this tooltip.
   * Used for trigger identification by the library.
   */
  tooltipId: T.oneOfType([T.string, T.number]).isRequired,
  /**
   * Children of the trigger.
   */
  children: T.node
}
