import { UserIcon, FlagIcon, SpinnerIcon } from '../../icons'

import { formatDate } from './../../../helpers/date'

import './WorkflowClientInstanceHeader.less'

const WorkflowClientInstanceHeader = props => {
  const { startedOn, triggeredByName, status } = props

  return (
    <div className='workflow-client-instance-header'>
      <div className='workflow-client-instance-header__item'>
        <FlagIcon />
        <span className='workflow-client-instance-header__item__label'>
          Started on:
        </span>
        <span className='workflow-client-instance-header__item__value'>
          {formatDate(startedOn)}
        </span>
      </div>

      <div className='workflow-client-instance-header__divider' />

      <div className='workflow-client-instance-header__item'>
        <UserIcon />
        <span className='workflow-client-instance-header__item__label'>
          Triggered by:
        </span>
        <span className='workflow-client-instance-header__item__value'>
          {triggeredByName}
        </span>
      </div>

      <div className='workflow-client-instance-header__divider' />

      <div className='workflow-client-instance-header__item'>
        <SpinnerIcon />
        <span className='workflow-client-instance-header__item__label'>
          Status:
        </span>
        <span className='workflow-client-instance-header__item__value'>
          {status}
        </span>
      </div>
    </div>
  )
}

export { WorkflowClientInstanceHeader }
