import { useCallback } from 'react'
import T from 'prop-types'
import classnames from 'classnames'

import { optionsType, optionValueType } from '../../../../helpers/propTypes'
import { Option } from './Option'

import './RadioGroup.less'

const RadioGroup = ({
  handleChange,
  value,
  inputId,
  className,
  optionClassName,
  options,
  disabled,
  readOnly,
  name,
  isTextButton,
  error,
  groupLabel,
  required
}) => {
  const onChange = useCallback((index) => handleChange(options[index].value), [handleChange, options])
  // The input shouldn't be controlled if the value prop is undefined.
  const isChecked = (optionValue) => (value === undefined ? undefined : optionValue === value)
  // emulate being read only by manipulating onClick handler.
  const onClick = useCallback(readOnly ? (event) => event.preventDefault() : undefined, [readOnly])

  return (
    <div className={classnames('radio-group', className)} id={inputId}>
      {groupLabel && (
        <div
          className={classnames('radio-group__label', {
            'radio-group__label--error': !!error
          })}
        >
          {groupLabel}{required && '*'}
        </div>
      )}
      <div className='radio-group__options'>
        {options.map(({ value, label }, index) => {
          const id = `${inputId}-option-${index}`
          return (
            <Option
              key={id}
              inputId={id}
              className={optionClassName}
              label={label}
              value={value}
              onChange={() => onChange(index)}
              checked={isChecked(value)}
              name={name}
              disabled={disabled}
              onClick={onClick}
              isTextButton={isTextButton}
              error={error}
            />
          )
        })}
      </div>
    </div>
  )
}

RadioGroup.defaultProps = {
  handleChange: () => {}
}

RadioGroup.propTypes = {
  /**
   * Id for the RadioGroup.
   * Used as prefix for IDs for each option.
   */
  inputId: T.string.isRequired,
  /**
   * Classname for the radio group.
   */
  className: T.string,
  /**
   * Classname for options.
   */
  optionClassName: T.string,
  /**
   * Array of options for the input.
   * Each options should have label and value.
   * Example option: `{ label: 'something', value: 'something' }`
   */
  options: optionsType().isRequired,
  /**
   * Should the input be disabled or not.
   */
  disabled: T.bool,
  /**
   * Should the input be readOnly or not.
   */
  readOnly: T.bool,
  /**
   * Name for the radio button group.
   */
  name: T.string.isRequired,
  /**
   * Selected value.
   */
  value: optionValueType(),
  /**
   * Receives the new value when it's selected.
   */
  handleChange: T.func,
  /**
   * Display just the label as a button.
   */
  isTextButton: T.bool
}

export { RadioGroup }
