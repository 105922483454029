import PropTypes from 'prop-types'
import classnames from 'classnames'

const ModalCard = ({ className, children, fullScreen }) => (
  <div className={classnames('modal', {
    [className]: !!className,
    'modal--fullScreen': fullScreen
  })}
  >
    {children}
  </div>
)

ModalCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export { ModalCard }
