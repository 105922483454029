import { withFormik, connect } from 'formik'
import { compose } from 'ramda'
import T from 'prop-types'

import { Form } from '../../form'
import { SelectField, InputField } from '../../form/fields'
import { Button } from '../../misc'
import { OptionWithInitials } from '../../misc/DropdownSelection/DropdownCustom'

import { userType } from '../../../helpers/propTypes'

const RecipientForm = props => {
  const { customOptionsWithUser, client, handleSubmit } = props

  return (
    <Form>
      <InputField
        name='client'
        label='Recipient'
        value={client.fullName}
        inputProps={{ disabled: true }}
      />

      <SelectField
        name='recipient'
        label='Recipient (Optional)'
        options={customOptionsWithUser}
        selectProps={{ Option: OptionWithInitials }}
        isGrouped
        inputProps={{
          placeholder: 'Add recipient',
          isClearable: true
        }}
      />

      <Button
        color={Button.Colors.BLUE}
        onClick={handleSubmit}
        className='recipient-modal__button'
      >
        Next
      </Button>
    </Form>
  )
}

RecipientForm.propTypes = {
  customOptionsWithUser: T.arrayOf(
    T.shape({
      label: T.string,
      value: T.string,
      isCurrentUser: T.bool,
      isCurrentFA: T.bool,
      showDivider: T.bool,
      user: userType()
    })
  ),
  client: T.shape({
    fullName: T.string,
    clientId: T.string
  }),
  onSubmit: T.func
}

const EnhancedRecipientForm = compose(
  withFormik({
    handleSubmit: ({ recipient }, { props: { onSubmit } }) => {
      onSubmit({ recipient })
    }
  }),
  connect
)(RecipientForm)

export { EnhancedRecipientForm as RecipientForm }
