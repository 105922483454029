import constants from '@elenfs/elen-constants'

import { ImportLogLayout } from '../ImportLogLayout'
import { gqlQueryField } from '../ImportLog'
import dataImportJobsGql from '../../../graphql/queries/dataImport/dataImportJobs.graphql'

const { DataImportTypes } = constants.dataImport

const PraemiumLogPage = (props) => {
  return (
    <ImportLogLayout
      pageTitle='Morningstar Wealth Platform'
      dataImportType={DataImportTypes.PRAEMIUM}
      gqlQuery={dataImportJobsGql}
      gqlQueryField={gqlQueryField}
      link='/secure/admin/morningstar'
      {...props}
    />
  )
}

export default PraemiumLogPage
