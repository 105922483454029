import { useState, useMemo, memo, Fragment } from 'react'
import T from 'prop-types'
import { equals } from 'ramda'
import classnames from 'classnames'

import { Checkbox, InitialsAvatar, Table } from '../../../misc'
import { MoneyInput } from '../../../form/inputs'
import { ChargeFrequenciesLabels } from '../../../../helpers/enumLabels/charge'
import {
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from '../../../../helpers/enumLabels/policy'
import { formatDate } from '../../../../helpers/date'
import { formatMoney, roundFloat } from '../../../../helpers/policy'
import { TableHeaders } from './constants'
import BadgeTooltipList from '../BadgeTooltipList/BadgeTooltipList'

const rowCellNameClass = 'pending-income-table__row__name-cell'
const rowCellPolicyNumberClass = 'pending-income-table__row__policy-number-cell'

const PendingIncomeTableRow = ({ incomeRecord, onToggleReconciled, mutationLoading }) => {
  const {
    id,
    dueDate,
    actualAmount,
    charge,
    policyHolders,
    client
  } = incomeRecord

  const {
    calculatedAmount,
    adviser,
    frequency,
    policy
  } = charge || {}

  const {
    number: policyNumber = '',
    category: policyCategory,
    provider: policyProvider
  } = policy || {}

  const clients = policy ? policyHolders : [client]

  const [amount, setAmount] = useState(roundFloat(calculatedAmount.amount))
  const amountNum = +amount

  const onChange = async () => {
    try {
      await onToggleReconciled(incomeRecord, {
        amount: amountNum,
        currency: calculatedAmount.currency
      })
    } catch (err) {
      console.error('[PendingIncomeTableRow] - Error submitting the form', err)
    }
  }

  const clientFullName = clients && clients[0]?.fullName

  const listFromPolicyHolders = useMemo(
    () => clients?.map(({ fullName }, index) => <Fragment key={index}>{fullName}</Fragment>),
    [clients]
  )

  const showBadge = listFromPolicyHolders?.length > 1
  const badgeText = `+${listFromPolicyHolders?.length < 101 ? listFromPolicyHolders?.length - 1 : 99}`

  return (
    <Table.Row>
      <Table.Cell data-label={TableHeaders.ADVISER} short actionCell>
        {adviser && <InitialsAvatar user={adviser} />}
      </Table.Cell>
      <Table.Cell
        data-label={TableHeaders.CLIENT}
        className={classnames(
          rowCellNameClass,
          showBadge && `${rowCellNameClass}--with-badge`
        )}
      >
        <span className='pending-income-table__row__name-cell__full-name'>
          {clientFullName}
        </span>
        {showBadge && (
          <BadgeTooltipList
            id={id}
            list={listFromPolicyHolders}
            title='Policy holders'
          >
            {badgeText}
          </BadgeTooltipList>
        )}
      </Table.Cell>

      <Table.Cell data-label={TableHeaders.POLICY}>{PolicyCategoriesLabels[policyCategory]}</Table.Cell>
      <Table.Cell data-label={TableHeaders.POLICY_NUMBER} className={rowCellPolicyNumberClass}>
        {policyNumber ? policyNumber.toUpperCase() : ''}
      </Table.Cell>
      <Table.Cell data-label={TableHeaders.PROVIDER}>{PolicyProvidersLabels[policyProvider]}</Table.Cell>
      <Table.Cell data-label={TableHeaders.FREQUENCY}>{ChargeFrequenciesLabels[frequency]}</Table.Cell>
      <Table.Cell data-label={TableHeaders.DUE_DATE}>{formatDate(dueDate)}</Table.Cell>
      <Table.Cell bold data-label={TableHeaders.EXPECTED}>
        {formatMoney(calculatedAmount)}
      </Table.Cell>
      <Table.Cell short data-label={TableHeaders.ACTUAL} actionCell>
        <MoneyInput value={amount} handleChange={setAmount} disabled={!!actualAmount} positiveOnly={false} />
      </Table.Cell>
      <Table.Cell short data-label={TableHeaders.RECEIVED} actionCell>
        <Checkbox
          inputId={`checkbox_${id}`}
          checked={!!actualAmount}
          disabled={mutationLoading}
          onChange={onChange}
        />
      </Table.Cell>
    </Table.Row>
  )
}

// Make sure only one row is re-rendered after reconciliation/un-reconciliation.
const PendingIncomeTableRowEnhanced = memo(
  PendingIncomeTableRow,
  (prev, next) => equals(prev, next)
)

PendingIncomeTableRowEnhanced.propTypes = {
  incomeRecord: T.object.isRequired,
  onToggleReconciled: T.func.isRequired
}

export { PendingIncomeTableRowEnhanced as PendingIncomeTableRow }
