import DropdownMenu from './DropdownMenu'
import DropdownMenuTrigger from './DropdownMenuTrigger'
import DropdownMenuList from './DropdownMenuList'
import DropdownMenuItem from './DropdownMenuItem'
import { DropdownMenuDirections } from './constants'

DropdownMenu.Directions = DropdownMenuDirections

DropdownMenu.Trigger = DropdownMenuTrigger
DropdownMenu.List = DropdownMenuList
DropdownMenu.Item = DropdownMenuItem
DropdownMenu.Directions = DropdownMenuDirections

export { DropdownMenu }

export default DropdownMenu
