import PropTypes from 'prop-types'

import LogActivityButton from '../../LogActivityButton'
import { EmptyPage } from '../../../misc'

const AuditLogEntriesPageEmpty = ({ clientId, hidden }) => (
  <EmptyPage
    className='audit-log-entries-page--empty'
    text='No activity currently listed, log activity for this client'
    renderButton={() => (
      <LogActivityButton
        clientId={clientId}
      />
    )}
    hidden={hidden}
  />
)

AuditLogEntriesPageEmpty.propTypes = {
  clientId: PropTypes.string.isRequired,
  hidden: PropTypes.bool
}

export default AuditLogEntriesPageEmpty
