import { useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'ramda'

import { ChargeDetailsForm } from './ChargeDetailsForm'
import {
  isFee,
  useQueryAndBuildList,
  buildContributionAndWithdrawalOptions,
  buildAdviserOptions,
  buildPolicyOptions,
  buildAdviserRateOptions
} from '../../../../helpers/charge'

import updateFeeGql from '../../../../graphql/mutations/charge/policyCharges/updateFee.graphql'
import updateCommissionGql from '../../../../graphql/mutations/charge/policyCharges/updateCommission.graphql'
import policiesTableGql from '../../../../graphql/queries/policy/policiesTable.graphql'
import financialAdvisersGql from '../../../../graphql/queries/user/financialAdvisers.graphql'
import policyTransactionsGql from '../../../../graphql/queries/policy/policyTransactions.graphql'

const ChargeDetailsFormContainer = (props) => {
  const { clientId } = useParams()
  const { charge, policyId, chargeType, createFee, createCommission, useRemoveCharge, isPolicyCharges } = props

  const isUpdate = charge && charge.id

  const mutationHandler = ({ createFee, createCommission, chargeId, chargeType, mutationProp, isUpdate }) => {
    let useMutationResult
    let removeChargeMutationResult = []

    if (!isUpdate) {
      useMutationResult = (
        isFee(chargeType)
          ? createFee
          : createCommission)(mutationProp)
    } else {
      removeChargeMutationResult = useRemoveCharge(chargeId, mutationProp)
      useMutationResult = useMutation(
        isFee(chargeType)
          ? updateFeeGql
          : updateCommissionGql)
    }

    return { useMutationResult, removeChargeMutationResult }
  }

  const [selectedClientId, setSelectedClientId] = useState(clientId)
  const handleSetSelectedClientId = useCallback(
    (id) => {
      if (!id || isEmpty(id)) { setSelectedClientId(null) }
      setSelectedClientId(id)
    }, [setSelectedClientId]
  )

  const [selectedPolicyId, setSelectedPolicyId] = useState(policyId)
  const handleSetSelectedPolicyId = useCallback(
    (id) => {
      if (!id || isEmpty(id)) { setSelectedPolicyId(null) }
      setSelectedPolicyId(id)
    }, [setSelectedPolicyId]
  )

  const [policyTransactionId, setPolicyTransactionId] = useState()
  const setSelectedPolicyTransactionId = useCallback(
    (id) => {
      if (!id || isEmpty(id)) { setPolicyTransactionId(null) }
      setPolicyTransactionId(id)
    }
    , [setPolicyTransactionId]
  )

  const [selectedAdviser, setSelectedAdviser] = useState()
  const handleSetSelectedAdviserId = useCallback(
    (id) => {
      if (!id || isEmpty(id)) { return }
      setSelectedAdviser(id)
    }, [setSelectedAdviser]
  )

  const { useMutationResult, removeChargeMutationResult } = mutationHandler({
    createFee,
    createCommission,
    chargeId:
      charge?.id,
    chargeType,
    mutationProp: isPolicyCharges ? selectedPolicyId : selectedClientId,
    isUpdate
  })

  const [mutate, mutationState] = useMutationResult
  const [deleteCharge] = removeChargeMutationResult

  const { data: adviserOptions } = useQueryAndBuildList({
    query: financialAdvisersGql,
    queryVariable: { clientId: selectedClientId },
    propName: 'users',
    buildList: buildAdviserOptions
  })

  const {
    data: policyOptions,
    loading: policyOptionsLoading
  } = useQueryAndBuildList({
    query: policiesTableGql,
    queryVariable: { clientId: selectedClientId },
    propName: 'policies',
    buildList: buildPolicyOptions
  })

  const {
    data: contributionAndWithdrawalData,
    loading: contributionAndWithdrawalLoading
  } = useQueryAndBuildList({
    query: policyTransactionsGql,
    queryVariable: { policyId: selectedPolicyId },
    buildList: buildContributionAndWithdrawalOptions
  })

  let selectedPolicyTransaction
  if (policyTransactionId && (contributionAndWithdrawalData && contributionAndWithdrawalData.length > 0)) {
    selectedPolicyTransaction = contributionAndWithdrawalData.filter(
      item => item.value === policyTransactionId
    )[0]
  }

  let advisersRate
  let adviserRate
  if (selectedAdviser && (adviserOptions && adviserOptions.length > 0)) {
    advisersRate = buildAdviserRateOptions(selectedAdviser, adviserOptions)
    adviserRate = advisersRate[0].value
  }

  return (
    <ChargeDetailsForm
      onSubmit={mutate}
      onDelete={deleteCharge}
      mutationState={mutationState}
      loading={mutationState.loading}
      policyTransactionOptions={contributionAndWithdrawalData}
      setSelectedPolicyTransactionId={setSelectedPolicyTransactionId}
      handleSetSelectedPolicyId={handleSetSelectedPolicyId}
      handleSetSelectedClientId={handleSetSelectedClientId}
      handleSetSelectedAdviserId={handleSetSelectedAdviserId}
      selectedPolicyTransaction={selectedPolicyTransaction}
      clientId={selectedClientId}
      policyId={selectedPolicyId}
      adviserRate={adviserRate}
      policyTransactionId={policyTransactionId}
      policyOptions={policyOptions}
      adviserOptions={adviserOptions}
      advisersRate={advisersRate}
      policyOptionsLoading={policyOptionsLoading}
      contributionAndWithdrawalLoading={contributionAndWithdrawalLoading}
      {...props}
    />
  )
}

export { ChargeDetailsFormContainer }
