export const TABLE_HEADERS = Object.freeze([
  {
    label: 'Name',
    propPath: ['name'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['id']
  },
  {
    label: 'Created by',
    propPath: ['createdBy', 'fullName'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['id']
  },
  {
    label: 'Date modified',
    propPath: ['dateModified'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['id']
  },
  {
    isHidden: true
  }
])
