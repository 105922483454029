import { compose } from 'ramda'

import { NotAuthorizedPage } from '../../../authorization'
import { withCurrentUser } from '../WithCurrentUser'

const withCompanyAdmin = compose(
  withCurrentUser(),
  (WrappedComponent) => (props) =>
    props.currentUser && props.currentUser.isAdmin
      ? (
        <WrappedComponent {...props} />
        )
      : <NotAuthorizedPage />
)

export default withCompanyAdmin
