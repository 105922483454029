const SyncIcon = (props) => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path
        d='M14 7.5a1 1 0 011 1 4.5 4.5 0 01-4.288 4.495L10.5 13H7v1.857a.5.5 0 01-.03.17l-.036.078a.5.5 0 01-.6.224l-.082-.038-4.612-2.636a.75.75 0 01-.085-1.245l.085-.057 4.612-2.636a.5.5 0 01.74.345l.008.09V11h3.5A2.5 2.5 0 0013 8.5a1 1 0 011-1zM9.5.652a.5.5 0 01.248.065l4.612 2.636a.75.75 0 010 1.302L9.748 7.291A.5.5 0 019 6.857V5H5.5a2.5 2.5 0 00-2.495 2.336L3 7.5a1 1 0 11-2 0 4.5 4.5 0 014.288-4.495L5.5 3H9V1.152a.5.5 0 01.5-.5z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export default SyncIcon
