import { SubscriptionStatuses } from '@elenfs/elen-constants'

import { Link } from 'react-router-dom'
import { WarningBanner } from '..'
import { ActivateSubscriptionModalTrigger } from '../ActivateSubscriptionModal'

const { TRIALING_AWAITING_SUBSCRIPTION, PAYMENT_PAST_DUE, TRIALING } = SubscriptionStatuses

const PaymentPastDueMessage = () => (
  <>
    There was a problem with your <b>payment</b> |
    <Link to='/secure/admin/sections/account-overview'>
      {' '}
      Update your payment details{' '}
    </Link>
    to continue using Elen
  </>
)

const TrialPeriodMessage = ({ trialDays, billingDateAfterTrial, subscriptionIntervalLabel }) => (
  <>
    Your <b>free trial</b> is due to <b>expire in [{trialDays}] days</b> | Your
    first {subscriptionIntervalLabel} payment will be taken on <b>{billingDateAfterTrial}</b>
  </>
)

const AwaitingSubscriptionMessage = ({ awaitingSubscriptionProps }) => (
  <ActivateSubscriptionModalTrigger as='div' modalProps={awaitingSubscriptionProps} className='awaiting-subscription-banner'>
    Action required: Please, <u>activate</u> your subscription in order to continue to use Elen
  </ActivateSubscriptionModalTrigger>
)

const SubscriptionWarningBanner = props => {
  const {
    showWarningBanner,
    closeWarningBanner,
    trialDays,
    billingDateAfterTrial,
    awaitingSubscriptionProps,
    subscriptionStatus,
    subscriptionIntervalLabel
  } = props

  const isAwaitingSubscription = subscriptionStatus === TRIALING_AWAITING_SUBSCRIPTION
  const isPaymentPastDue = subscriptionStatus === PAYMENT_PAST_DUE
  const isTrial = subscriptionStatus === TRIALING

  return (
    <>
      {showWarningBanner && !isPaymentPastDue && (
        <WarningBanner
          textAlign={WarningBanner.TextAlign.CENTER}
          background={WarningBanner.Background.DARK}
          size={WarningBanner.Size.SMALL}
          onClose={isAwaitingSubscription ? null : closeWarningBanner}
          className='secure-section__warning-banner'
        >
          {isAwaitingSubscription && <AwaitingSubscriptionMessage awaitingSubscriptionProps={awaitingSubscriptionProps} />}
          {isTrial && <TrialPeriodMessage {...{ billingDateAfterTrial, trialDays, subscriptionIntervalLabel }} />}
          {isPaymentPastDue && <PaymentPastDueMessage />}
        </WarningBanner>
      )}
    </>
  )
}

export { SubscriptionWarningBanner }
