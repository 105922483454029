import { ClientsDashboardCard } from '../ClientsDashboardCard'
import { AssetsUnderManagementCard } from '../AssetsUnderManagementCard'
import { IncomeCard } from '../IncomeCard'
import { LastVisitedClientsCard } from '../LastVisitedClientsCard'
import { MessagesDashboardCard } from '../MessagesDashboardCard'
import { ReviewsAndBirthdaysDashboardCard } from '../ReviewsAndBirthdaysDashboardCard'
import { SignaturesDashboardCard } from '../SignaturesDashboardCard'
import { TasksDashboardCard } from '../TasksDashboardCard'

import './DashboardPage.less'

const DashboardPage = () => (
  <div className='dashboard-page'>
    <div>
      <div className='dashboard-page__container'>
        <div className='dashboard-page__container__left-column'>
          <div className='dashboard-page__container__left-column__row'>
            <ClientsDashboardCard />
            <TasksDashboardCard />
          </div>

          <MessagesDashboardCard />

          <SignaturesDashboardCard />

          <IncomeCard />
        </div>

        <div className='dashboard-page__container__right-column'>
          <AssetsUnderManagementCard />
          <ReviewsAndBirthdaysDashboardCard />
          <LastVisitedClientsCard />
        </div>
      </div>
    </div>
  </div>
)

export { DashboardPage }
