import * as yup from 'yup'

import stringSchema from '../helpers/stringSchema'

export default yup.object().shape({
  relationships: yup.array().of(
    yup.object().shape({
      type: stringSchema().label('Relationships section: Relationship type').required(),
      relatedClientId: stringSchema().label('Relationships section: Client name').required()
    })
  ).default([])
})
