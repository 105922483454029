import { Form } from '../../form'
import { UploadImageWithPreview, Button } from '../../misc'

import './BrandingForm.less'

const BrandingForm = ({
  onBrandingSubmit,
  disableSubmit,
  logoProps
}) => {
  const onSubmitHandler = async e => {
    e.preventDefault()
    await onBrandingSubmit()
  }

  return (
    <Form className='branding__form' onSubmit={onSubmitHandler}>
      <div className='branding__form__header'>
        <Button
          type='submit'
          color={Button.Colors.BLUE}
          className='branding__form__header__button'
          disabled={disableSubmit}
        >
          Save Configuration
        </Button>
      </div>

      <div className='branding__form__content'>
        <UploadImageWithPreview
          className='branding-logo'
          {...logoProps}
        />
      </div>
    </Form>
  )
}

export { BrandingForm }
