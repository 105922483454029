import { useCallback } from 'react'
import T from 'prop-types'
import moment from 'moment'
import { SubscriptionStatuses, INACTIVE_SUBSCRIPTION_STATUSES } from '@elenfs/elen-constants'

import { OverviewCard } from '../OverviewCard'
import { Button, ConfirmationModal } from '../../../misc'

import { moneyType } from '../../../../helpers/propTypes'
import { formatMoney } from './../../../../helpers/policy'
import { capitalizeFirstLetter } from './../../../../helpers/misc'
import { SeatsModalTrigger } from '../SeatsModal'

import { LicenseTypeLabels, SubscriptionStatusLabels } from './const'

import './SubscriptionPlan.less'

const formatDate = date => (date ? moment(date).format('DD MMM YYYY') : '')

const HeadingActionComponent = ({ confirmCancelSubscription }) => (
  <ConfirmationModal.Trigger
    as={Button}
    modalProps={{
      confirmationCallback: confirmCancelSubscription,
      heading: 'Cancel subscription',
      text: 'Are you sure you want to cancel your subscription?',
      yesButtonText: 'Confirm',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
  >
    Cancel subscription
  </ConfirmationModal.Trigger>
)

const TotalLicensesLabel = props => {
  const {
    totalLicenses,
    activeUsers,
    onUpdateSeats,
    updateSeatsLoading,
    updateSeatsError,
    hideActions
  } = props

  return (
    <div className='total-licenses-label'>
      {totalLicenses}
      {
        !hideActions &&
          <SeatsModalTrigger
            as={Button}
            disabled={updateSeatsLoading}
            modalProps={{
              totalLicenses,
              activeUsers,
              onSubmit: onUpdateSeats,
              mutationError: updateSeatsError,
              mutationLoading: updateSeatsLoading
            }}
          >
            Change
          </SeatsModalTrigger>
      }
    </div>
  )
}

const ManageBillingLabel = ({ value, action, loading, couponText }) => (
  <div className='manage-billing-label'>
    {`${formatMoney(value)} (VAT included)`}
    {' '}
    {`${couponText}`}
    <Button onClick={action} disabled={loading}>Manage billing</Button>
  </div>
)

const SubscriptionPlan = props => {
  const {
    status,
    couponText,
    costPerLicense,
    totalLicenses,
    totalFee,
    onCancelSubscription,
    currentPeriodStart,
    currentPeriodEnd,
    trialDaysRemaining,
    billingDateAfterTrial,
    activeUsers,
    onUpdateSeats,
    updateSeatsLoading,
    updateSeatsError,
    customerPortalProps
  } = props

  const {
    onGetCustomerPortalUrl,
    customerPortalUrlLoading,
    customerPortalUrlError
  } = customerPortalProps

  const hideActions =
    INACTIVE_SUBSCRIPTION_STATUSES.includes(status) ||
    status === SubscriptionStatuses.TRIALING_AWAITING_SUBSCRIPTION

  const daysRemaining = trialDaysRemaining
    ? ` | ${trialDaysRemaining} days remaining | ${billingDateAfterTrial}`
    : ''
  const statusValue = SubscriptionStatusLabels[status] + daysRemaining

  const summaries = [
    { label: 'Status', value: statusValue },
    {
      label: `${capitalizeFirstLetter(
        LicenseTypeLabels[costPerLicense.licenseType]
      )} cost per license`,
      value: `${formatMoney(costPerLicense.licenseFee)} + VAT`
    },
    {
      label: 'Total licenses',
      value: (
        <TotalLicensesLabel
          {...{
            activeUsers,
            totalLicenses,
            onUpdateSeats,
            updateSeatsLoading,
            updateSeatsError,
            hideActions,
            couponText
          }}
        />
      )
    },
    {
      label: `Total ${LicenseTypeLabels[costPerLicense.licenseType]} fee`,
      value: (
        <ManageBillingLabel
          action={onGetCustomerPortalUrl}
          value={totalFee}
          loading={customerPortalUrlLoading}
          error={customerPortalUrlError}
          couponText={couponText}
        />
      )
    },
    { label: 'Current period start', value: formatDate(currentPeriodStart) },
    { label: 'Current period end', value: formatDate(currentPeriodEnd) }
  ]

  const confirmCancelSubscription = useCallback(
    isTrue => isTrue && onCancelSubscription(),
    [onCancelSubscription]
  )

  return (
    <OverviewCard
      {...{
        heading: 'Subscription Plan',
        summaries,
        headingActionComponent: !hideActions && (
          <HeadingActionComponent
            confirmCancelSubscription={confirmCancelSubscription}
          />
        )
      }}
    />
  )
}

SubscriptionPlan.propTypes = {
  status: T.string.isRequired,
  costPerLicense: T.shape({
    licenseType: T.string.isRequired,
    licenseFee: moneyType().isRequired
  }),
  totalLicenses: T.number.isRequired,
  totalFee: moneyType().isRequired,
  onCancelSubscription: T.func.isRequired,
  trialDaysRemaining: T.number,
  currentPeriodStart: T.string,
  currentPeriodEnd: T.string,
  billingaDateAfterTrial: T.string,
  activeUsers: T.number.isRequired,
  onUpdateSeats: T.func.isRequired,
  updateSeatsLoading: T.bool,
  updateSeatsError: T.string
}

export { SubscriptionPlan }
