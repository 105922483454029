import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

const areChildrenEmpty = (children) => {
  return ['', null, undefined].includes(children)
}

const TableCell = ({
  children,
  className,
  bold,
  to,
  actionCell,
  centered,
  short,
  ...rest
}) => (
  <td
    className={classnames('table-new__cell', {
      'table-new__cell--bold': bold,
      'table-new__cell--link': to,
      'table-new__cell--text': !to,
      'table-new__cell--action': actionCell,
      'table-new__cell--centered': centered,
      'table-new__cell--short': short
    }, className)}
    {...rest}
  >
    {
      to
        ? (
          <Link className='table-new__cell__link' to={to}>
            {/* This allows Link to occupy all available height in case children are empty */}
            {areChildrenEmpty(children) ? (<>&nbsp;</>) : children}
          </Link>
          )
        : <span className='table-new__cell__content'>{children}</span>
    }
  </td>
)

TableCell.defaultProps = {
  bold: false
}

TableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  bold: PropTypes.bool,
  to: PropTypes.string
}

export default TableCell
