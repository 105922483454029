import T from 'prop-types'
import appConfig from '../../../appConfig'

import { Button, ConfirmationModal, ConfirmPasswordModal } from '../../misc'

import './PreviewerPDF.less'

const { CLIENT_TERMS_OF_SERVICE_LINK, TERMS_OF_SERVICE_LINK } = appConfig

const FooterPDF = props => {
  const {
    onSignDocument,
    onRequestSignature,
    cancelModalProps,
    isClientPortal
  } = props

  const description = (
    <div className='sign-document-btn__confirm-password-modal__description'>
      <b> Almost done </b>
      <p>
        I agree to be legally bound by this document and the
        <a
          href={
            isClientPortal
              ? CLIENT_TERMS_OF_SERVICE_LINK
              : TERMS_OF_SERVICE_LINK
          }
          target='_blank' rel='noreferrer'
        >
          {' '}
          Elen Terms of Service
        </a>
        . Please, confirm your password to sign this document.
      </p>
    </div>
  )

  return (
    <div className='preview-pdf__footer'>
      {onSignDocument
        ? (
          <ConfirmPasswordModal.Trigger
            as={Button}
            modalProps={{
              onSubmit: onSignDocument,
              heading: 'Sign the document',
              description
            }}
            color={Button.Colors.BLUE}
          >
            Sign the document
          </ConfirmPasswordModal.Trigger>
          )
        : (
          <Button color={Button.Colors.BLUE} onClick={onRequestSignature}>
            Request Signature
          </Button>
          )}

      <ConfirmationModal.Trigger
        as={Button}
        modalProps={cancelModalProps}
        color={Button.Colors.GREY}
      >
        Cancel
      </ConfirmationModal.Trigger>
    </div>
  )
}

FooterPDF.propTypes = {
  onCancel: T.func,
  onSignDocument: T.func,
  onRequestSignature: T.func,
  isClientPortal: T.bool,
  loading: T.bool,
  error: T.shape({
    msg: T.string.isRequired,
    isRequestError: T.bool.isRequired
  }),
  cancelModalProps: T.shape({
    heading: T.string.isRequired,
    text: T.string.isRequired,
    confirmationCallback: T.func
  })
}

export { FooterPDF }
