"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ServiceNames = void 0;
const ServiceNames = exports.ServiceNames = Object.freeze({
  DATA_IMPORT: 'data-importing-service',
  USER_MANAGEMENT: 'user-management-service',
  POLICY_MANAGEMENT: 'policy-service',
  CLIENT_MANAGEMENT: 'client-service',
  CLIENT_DOCUMENT_MANAGEMENT: 'client-document-service',
  EMAIL: 'email-service',
  INCOME: 'income-service',
  MESSAGE: 'message-service',
  FILE: 'file-service'
});
var _default = exports.default = {
  ServiceNames
};