"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StripeSubscriptionStatusesMap = exports.StripeSubscriptionIntervalsMap = exports.StripeInvoicePaymentStatusesMap = exports.StripeCurrenciesMap = exports.StripeCurrencies = void 0;
var _common = require("./common");
var _misc = require("../misc");
const StripeSubscriptionIntervalsMap = exports.StripeSubscriptionIntervalsMap = Object.freeze({
  day: _common.SubscriptionIntervals.DAY,
  month: _common.SubscriptionIntervals.MONTH,
  year: _common.SubscriptionIntervals.YEAR
});
const StripeCurrenciesMap = exports.StripeCurrenciesMap = Object.freeze({
  gbp: _misc.Currencies.GBP,
  usd: _misc.Currencies.USD,
  eur: _misc.Currencies.EUR
});
const StripeCurrencies = exports.StripeCurrencies = Object.freeze({
  USD: 'usd',
  EUR: 'eur',
  GBP: 'gbp'
});
const StripeSubscriptionStatusesMap = exports.StripeSubscriptionStatusesMap = Object.freeze({
  trialing: _common.SubscriptionStatuses.TRIALING,
  active: _common.SubscriptionStatuses.ACTIVE,
  past_due: _common.SubscriptionStatuses.PAYMENT_PAST_DUE,
  cancelled: _common.SubscriptionStatuses.INACTIVE,
  unpaid: _common.SubscriptionStatuses.UNPAID
});
const StripeInvoicePaymentStatusesMap = exports.StripeInvoicePaymentStatusesMap = Object.freeze({
  draft: _common.PaymentStatuses.PENDING,
  open: _common.PaymentStatuses.PENDING,
  paid: _common.PaymentStatuses.PAID,
  uncollectible: _common.PaymentStatuses.NOT_PAID
});