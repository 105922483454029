import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Form.less'

const Form = ({ children, className, ...rest }) => (
  <form className={classnames('form-new', className)} {...rest}>
    {children}
  </form>
)

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export { Form }
