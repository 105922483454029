import {
  GoBackHeader,
  EmptyPage,
  ErrorMessage,
  Spinner,
  Filter,
  DownloadReportBtn
} from '../../../misc'
import { ReportTable } from '../../ReportTable'

import './MaturingPoliciesPage.less'

const MaturingPoliciesPage = props => {
  const {
    filterProps,
    tableProps,
    loading,
    error,
    called,
    downloadBtnProps
  } = props

  const { rows } = tableProps

  return (
    <div className='maturing-policies-page'>
      <GoBackHeader title='Maturing Policies' />

      <Filter loading={loading} {...filterProps} />

      {loading && <Spinner />}

      {!rows.length && called && !loading && !error && (
        <EmptyPage text='No records found' hidden={false} />
      )}

      {error && <ErrorMessage text='Failed to load records' />}

      {!!rows.length && (
        <>
          <DownloadReportBtn className='dl-report-btn' {...downloadBtnProps} />

          <div className='table-wrap'>
            <ReportTable {...tableProps} />
          </div>
        </>
      )}
    </div>
  )
}

export { MaturingPoliciesPage }
