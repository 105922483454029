"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ESignatureUserStatuses = exports.ESignatureStatuses = exports.ESignatureStatusLabels = exports.ESignatureRequestStatuses = exports.ESignatureAuditTrailActionTypes = exports.ESignatureAuditTrailActionLabels = void 0;
const ESignatureStatuses = exports.ESignatureStatuses = Object.freeze({
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR'
});
const ESignatureUserStatuses = exports.ESignatureUserStatuses = Object.freeze({
  ...ESignatureStatuses,
  WAITING: 'WAITING'
});
const ESignatureStatusLabels = exports.ESignatureStatusLabels = Object.freeze({
  [ESignatureStatuses.PENDING]: 'Pending',
  [ESignatureStatuses.COMPLETED]: 'Completed'
});
const ESignatureRequestStatuses = exports.ESignatureRequestStatuses = Object.freeze({
  PENDING: 'PENDING',
  SIGNED: 'SIGNED'
});
const ESignatureAuditTrailActionTypes = exports.ESignatureAuditTrailActionTypes = Object.freeze({
  EMAIL_SENT: 'EMAIL_SENT',
  DOCUMENT_VIEWED: 'DOCUMENT_VIEWED',
  DOCUMENT_SIGNED: 'DOCUMENT_SIGNED',
  DOCUMENT_COMPLETED: 'DOCUMENT_COMPLETED'
});
const ESignatureAuditTrailActionLabels = exports.ESignatureAuditTrailActionLabels = Object.freeze({
  [ESignatureAuditTrailActionTypes.EMAIL_SENT]: 'Sent',
  [ESignatureAuditTrailActionTypes.DOCUMENT_VIEWED]: 'Viewed',
  [ESignatureAuditTrailActionTypes.DOCUMENT_SIGNED]: 'Signed',
  [ESignatureAuditTrailActionTypes.DOCUMENT_COMPLETED]: 'Completed'
});