"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.UserTypes = exports.UserAgreementTypes = exports.CompanyUserRoles = exports.CompanyUserRequiredAgreements = exports.CompanyOwnerRequiredAgreements = exports.ClientUserRequiredAgreements = void 0;
const UserTypes = exports.UserTypes = Object.freeze({
  COMPANY_USER: 'COMPANY_USER',
  ADMIN: 'ADMIN_USER',
  CLIENT: 'CLIENT_USER',
  SERVICE_USER: 'SERVICE_USER'
});
const CompanyUserRoles = exports.CompanyUserRoles = Object.freeze({
  ADVISER: 'ROLE_ADVISER',
  PARAPLANNER: 'ROLE_PARAPLANNER',
  COMPLIANCE: 'ROLE_COMPLIANCE',
  OFFICE_ADMINISTRATOR: 'ROLE_OFFICE_ADMINISTRATOR'
});
const CompanyUserAdminAccess = Object.freeze({
  ADMINISTRATOR: true,
  USER: false
});

// For purposes of the elen-fa app, this has been moved to 'src/helpers/enumLabels/user'.
// I left it here because of the elen-admin app.
const UserRolesLabels = Object.freeze({
  [CompanyUserRoles.ADVISER]: 'Financial Adviser',
  [CompanyUserRoles.PARAPLANNER]: 'Paraplanner',
  [CompanyUserRoles.COMPLIANCE]: 'Compliance',
  [CompanyUserRoles.OFFICE_ADMINISTRATOR]: 'Office Administrator'
});
const UserAgreementTypes = exports.UserAgreementTypes = Object.freeze({
  ELEN_SAAS_AGREEMENT: 'ELEN_SAAS_AGREEMENT',
  DATA_PROCESSING_AGREEMENT: 'DATA_PROCESSING_AGREEMENT',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  ACCEPTABLE_USE_POLICY: 'ACCEPTABLE_USE_POLICY',
  ELEN_TERMS_OF_SERVICE: 'ELEN_TERMS_OF_SERVICE'
});
const CompanyOwnerRequiredAgreements = exports.CompanyOwnerRequiredAgreements = [UserAgreementTypes.ELEN_SAAS_AGREEMENT, UserAgreementTypes.DATA_PROCESSING_AGREEMENT, UserAgreementTypes.PRIVACY_POLICY, UserAgreementTypes.ACCEPTABLE_USE_POLICY];
const CompanyUserRequiredAgreements = exports.CompanyUserRequiredAgreements = [UserAgreementTypes.ELEN_SAAS_AGREEMENT, UserAgreementTypes.DATA_PROCESSING_AGREEMENT, UserAgreementTypes.PRIVACY_POLICY, UserAgreementTypes.ACCEPTABLE_USE_POLICY];
const ClientUserRequiredAgreements = exports.ClientUserRequiredAgreements = [UserAgreementTypes.ELEN_TERMS_OF_SERVICE, UserAgreementTypes.ACCEPTABLE_USE_POLICY, UserAgreementTypes.PRIVACY_POLICY];
var _default = exports.default = {
  UserTypes,
  CompanyUserRoles,
  CompanyUserAdminAccess,
  UserRolesLabels,
  UserAgreementTypes,
  CompanyOwnerRequiredAgreements,
  CompanyUserRequiredAgreements
};