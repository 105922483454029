import { useEffect } from 'react'
import { withFormik } from 'formik'
import T from 'prop-types'

import { Form, FormErrorMessages } from '../../form'
import { InputField, SelectField } from '../../form/fields'
import { Button, LoadingMessage } from '..'

import { optionsType } from './../../../helpers/propTypes'

const totalFieldsCount = 2

const ActivateSubscriptionForm = props => {
  const {
    handleSubmit,
    activeUsers,
    planOptions,
    values,
    mutationLoading,
    mutationError,
    errors,
    setErrors
  } = props

  const { seats } = values

  const isSeatsError = seats < activeUsers
  const isError = !!Object.values(errors).filter(Boolean).length
  const disableBtn =
    Object.values(values).filter(Boolean).length < totalFieldsCount ||
    isSeatsError

  useEffect(() => {
    const seatsError = isSeatsError
      ? `Minimum number of users(licenses) you can set is ${activeUsers}`
      : ''
    setErrors({ mutationError, seatsError })
  }, [mutationError, seats])

  return (
    <Form onSubmit={handleSubmit} className='activate-subscription-form'>
      <SelectField
        options={planOptions}
        label='Subscription plan'
        name='plan'
        inputProps={{ isClearable: false }}
      />

      <InputField
        name='seats'
        label='Number of user licences'
        inputProps={{ regex: new RegExp(/^$|^[1-9]\d*$/) }} // eslint-disable-line
      />

      <p>
        Minimum number of seats you can set is determined by the number of
        currently active users.
        <br />
        <br />
        Currently: <b> {activeUsers} active users</b>
      </p>

      {mutationLoading && <LoadingMessage text='Please, wait ...' />}
      {isError && <FormErrorMessages validationErrors={errors} />}

      <Button
        type='submit'
        color={Button.Colors.BLUE}
        disabled={disableBtn || mutationLoading}
      >
        Continue
      </Button>
    </Form>
  )
}

ActivateSubscriptionForm.propTypes = {
  activeUsers: T.number.isRequired,
  onSubmit: T.func.isRequired,
  mutationLoading: T.bool.isRequired,
  mutationError: T.string.isRequired,
  planOptions: optionsType().isRequired
}

export const EnhancedActivateSubscriptionForm = withFormik({
  validateOnChange: false,
  mapPropsToValues: ({ activeUsers, planOptions }) => ({
    seats: activeUsers || 0,
    plan: planOptions[0]?.value
  }),
  handleSubmit: async (values, { props: { onSubmit, onDone } }) => {
    try {
      await onSubmit(values)
      onDone()
    } catch (error) {}
  }
})(ActivateSubscriptionForm)

export { EnhancedActivateSubscriptionForm as ActivateSubscriptionForm }
