import { useRef } from 'react'
import classnames from 'classnames'
import T from 'prop-types'
import 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2'

import { CollapsableContainer } from '../../misc'
import { useCurrentUser } from '../../../hooks'
import { options, emptyData } from './helpers'
import { getTotal, buildLegend } from '../../../helpers/charts'
import { formatMoney } from '../../../helpers/policy'

import './DoughnutCard.less'

const DoughnutCard = props => {
  const { currency } = useCurrentUser()
  const { className, title, titleAction, data, isEmpty, emptyText } = props

  const ref = useRef()
  const legend = buildLegend(data)
  const total = formatMoney({ amount: isEmpty ? 0 : getTotal(data), currency })

  return (
    <div className={classnames('doughnut-card', className)}>
      <CollapsableContainer
        title={title}
        summary={total}
        isClosedOnMobile
        isSmallBreakPoint
        autoMaxHeightOnInit
      >
        <div className='doughnut-card__header'>
          <h3 className='doughnut-card__header__title'>{title}</h3>
          {titleAction && titleAction}
        </div>

        <div className='doughnut-card__chart-container'>
          <div className='doughnut-card__chart-container__total'>{total}</div>
          <Doughnut
            ref={ref}
            data={isEmpty ? emptyData : data}
            width={244}
            height={244}
            options={options}
          />
        </div>

        <div className='doughnut-card__legend'>
          {!legend.length && <span> {emptyText} </span>}
          {legend.map(({ label, color, amount }) => (
            <div className='doughnut-card__legend__item' key={label}>
              <div
                className='doughnut-card__legend__item__color'
                style={{ backgroundColor: color }}
              />
              <div className='doughnut-card__legend__item__label'>{label}</div>
              <div className='doughnut-card__legend__item__money'>
                {formatMoney({ amount, currency })}
              </div>
            </div>
          ))}
        </div>
      </CollapsableContainer>
    </div>
  )
}

DoughnutCard.defaultProps = {
  emptyText: 'No data found'
}

DoughnutCard.propTypes = {
  data: T.object,
  title: T.string,
  titleAction: T.oneOfType([T.node, T.elementType]),
  isEmpty: T.bool,
  emptyText: T.string
}

export { DoughnutCard }
