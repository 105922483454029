import { Fragment } from 'react'
import constants from '@elenfs/elen-constants'

import {
  SelectField,
  DateField,
  MoneyField,
  RadioGroupField
} from '../../../form/fields'
import { FormGroup } from '../../../form/Form/FormGroup'
import { SharedPolicyFormFields } from '../SharedPolicyFormFields'

import {
  AssuredLivesLabels,
  CoverTypesLabels,
  DeferredPeriodsLabels,
  OfferedTermsLabels,
  ProtectionTypesLabels,
  WaiversLabels,
  PlanStatusesLabels
} from '../../../../helpers/enumLabels/protectionPolicy'
import { buildSelectOptions } from '../../../form/fields/SelectField'
import { buildBooleanSelectBoxOptions } from '../../../../helpers/form'

const { PlanStatuses } = constants.policy.protectionPolicy
const {
  AssuredLives,
  CoverTypes,
  DeferredPeriods,
  OfferedTerms,
  ProtectionTypes,
  Waivers
} = constants.policy.protectionPolicy

const ProtectionPolicyDetailsForm = ({ currentClient, companyClients }) => (
  <>
    <div className='policy-type-form protection-policy-form'>
      <SharedPolicyFormFields
        {...{
          companyClients,
          currentClient,
          policyTypeLabel: 'Policy type',
          policyTypeName: 'protectionType',
          policyTypeOptions: buildSelectOptions(
            ProtectionTypes,
            ProtectionTypesLabels
          ),
          planStatusOptions: buildSelectOptions(
            PlanStatuses,
            PlanStatusesLabels
          )
        }}
      />

      <FormGroup label='Other details'>
        <div className='policy-type-form__row policy-type-form__row--3'>
          <SelectField
            clearable
            name='livesAssured'
            label='Lives assured'
            options={buildSelectOptions(AssuredLives, AssuredLivesLabels)}
          />

          <SelectField
            clearable
            name='termsOffered'
            label='Terms offered'
            options={buildSelectOptions(OfferedTerms, OfferedTermsLabels)}
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-waiver'
            options={buildSelectOptions(Waivers, WaiversLabels)}
            name='waiver'
            groupLabel='Waiver'
            isTextButton
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-guaranteed-insurability'
            options={buildBooleanSelectBoxOptions()}
            name='guaranteedInsurabilityOption'
            groupLabel='Guaranteed insurability option'
            isTextButton
          />
        </div>
      </FormGroup>

      <FormGroup label='Valuation and Premiums'>
        <div className='policy-type-form__row policy-type-form__row--3'>
          <MoneyField name='currentValue' label='Current value' />

          <MoneyField name='surrenderValue' label='Surrender value' />

          <DateField
            name='valuationDate'
            label='Valuation date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

        </div>

        <div className='policy-type-form__row policy-type-form__row--3'>
          <MoneyField
            name='regularPremiumForCover'
            label='Regular premium for cover'
          />
        </div>
      </FormGroup>

      <FormGroup label='Dates'>
        <div className='policy-type-form__row policy-type-form__row--4'>
          <DateField
            name='applicationDate'
            label='Application date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='onRiskDate'
            label='On risk date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <DateField
            name='endDate'
            label='End date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />
        </div>
      </FormGroup>

      <FormGroup label='Benefits'>
        <div className='policy-type-form__row policy-type-form__row--3'>
          <MoneyField
            name='lumpSumBenefitOnCriticalIllness'
            label='Lump sum on critical illness'
          />

          <MoneyField
            name='lumpSumBenefitOnDeath'
            label='Lump sum on death'
          />

          <MoneyField name='annualBenefit' label='Annual benefit (PHI)' />
        </div>

        <div className='policy-type-form__row policy-type-form__row--4'>
          <DateField
            name='termOfMaturityDate'
            label='Term or maturity date'
            inputProps={{ placeholder: 'dd/mm/yyyy' }}
          />

          <SelectField
            clearable
            name='deferredPeriod'
            label='Deferred period'
            options={buildSelectOptions(DeferredPeriods, DeferredPeriodsLabels)}
          />
        </div>

        <div className='policy-type-form__row policy-type-form__row--flex'>
          <RadioGroupField
            inputId='policy-type-form-is-new-radio-input-cover-type'
            options={buildSelectOptions(CoverTypes, CoverTypesLabels)}
            name='coverType'
            groupLabel='Cover type (WOL)'
            isTextButton
          />
        </div>
      </FormGroup>
    </div>
  </>
)

export default ProtectionPolicyDetailsForm
