export const CloseIconBig = props => (
  <svg width='24px' height='24px' viewBox='0 0 24 24' {...props}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <rect id='24' x='0' y='0' width='24' height='24' />
      <path
        d='M18.363961,5.63603897 C18.7544853,6.02656326 18.7564433,6.65777029 18.3686296,7.04558399 L13.4142136,12 L18.3686296,16.954416 C18.7288651,17.3146515 18.7551415,17.882176 18.4422019,18.2762686 L18.363961,18.363961 C17.9734367,18.7544853 17.3422297,18.7564433 16.954416,18.3686296 L12,13.4142136 L7.04558399,18.3686296 C6.68534849,18.7288651 6.11782395,18.7551415 5.72373143,18.4422019 L5.63603897,18.363961 C5.24551468,17.9734367 5.24355673,17.3422297 5.63137043,16.954416 L10.5857864,12 L5.63137043,7.04558399 C5.2434245,6.65763806 5.24279201,6.02928592 5.63603897,5.63603897 C6.02656326,5.24551468 6.65777029,5.24355673 7.04558399,5.63137043 L12,10.5857864 L16.954416,5.63137043 C17.3146515,5.27113492 17.882176,5.24485846 18.2762686,5.55779814 L18.363961,5.63603897 Z'
        fill='currentColor'
      />
    </g>
  </svg>
)
