import { prop, propSatisfies } from 'ramda'

export const buildCustomOptionsWithUser = props => {
  const { companyUsers, currentUser, currentClient } = props

  const customOptionsWithUser = [
    { label: '', options: [] },
    { label: '', options: [] }
  ]

  const isCurrentUserClientFa =
    currentClient?.financialAdviserId === currentUser?.id

  customOptionsWithUser[0].options.push({
    label: currentUser.fullName,
    value: currentUser.id,
    user: currentUser
  })

  if (!isCurrentUserClientFa && currentClient?.financialAdviser) {
    customOptionsWithUser[0].options.push({
      label: currentClient?.financialAdviser?.fullName,
      value: currentClient?.financialAdviserId,
      user: currentClient?.financialAdviser,
      hint: 'current adviser'
    })
  }

  customOptionsWithUser[1].options = companyUsers
    .filter(prop('enabled'))
    .filter(
      propSatisfies(
        id => id !== currentUser.id && id !== currentClient?.financialAdviserId,
        'id'
      )
    )
    .map(user => ({
      label: user.fullName,
      value: user.id,
      user,
      hint: user.email
    }))

  return customOptionsWithUser
}
