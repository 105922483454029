import { useState, useCallback } from 'react'

import {
  MOBILE_WIDTH,
  DESKTOP_DEFAULT_SCALE,
  MOBILE_DEFAULT_SCALE,
  MIN_SCALE,
  SCALE_STEP
} from './const'

const initialScale = () =>
  window.innerWidth > MOBILE_WIDTH
    ? DESKTOP_DEFAULT_SCALE
    : MOBILE_DEFAULT_SCALE

export const useScalePDF = () => {
  const [scale, setScale] = useState(initialScale())

  const onZoomIn = useCallback(() => setScale(+(scale + SCALE_STEP).toFixed(2)))

  const onZoomOut = useCallback(() =>
    setScale(scale > MIN_SCALE ? +(scale - SCALE_STEP).toFixed(2) : MIN_SCALE)
  )

  return { onZoomIn, onZoomOut, scale }
}
