import { useCallback, useEffect } from 'react'
import T from 'prop-types'
import { useMutation } from '@apollo/client'

import { RemovePolicyValuationButton } from './RemovePolicyValuationButton'
import { parseGraphqlErrors } from '../../../../helpers/error'

import removePolicyValuationsGql
  from '../../../../graphql/mutations/policy/policyValuations/removePolicyValuations.graphql'
import policyValuationsGql from '../../../../graphql/queries/policy/policyValuations.graphql'
import { LIMIT_NUMBERS_OF_VALUATIONS } from '../constants'

const RemovePolicyValuationButtonContainer = props => {
  const {
    valuationId,
    closeModal,
    policyId,
    setErrors,
    ...restProps
  } = props

  const [removeMutation, { called, loading, error }] = useMutation(removePolicyValuationsGql, {
    variables: {
      input: {
        id: valuationId,
        policyId
      }
    },
    onError: (err) => {
      if (setErrors) {
        setErrors(parseGraphqlErrors(
          err,
          'There was an error deleting the valuation, please try again later'))
      } else {
        throw new Error(err)
      }
    },
    update: (cache, operationResult) => {
      const { id: removedInstanceId } = operationResult.data.removePolicyValuation
      const { policyValuations } = cache.readQuery({
        query: policyValuationsGql,
        variables: { policyId, limit: LIMIT_NUMBERS_OF_VALUATIONS }
      })
      const filterFn = ({ id }) => id !== removedInstanceId

      cache.writeQuery({
        query: policyValuationsGql,
        variables: { policyId, limit: LIMIT_NUMBERS_OF_VALUATIONS },
        data: {
          policyValuations: policyValuations.filter(filterFn)
        }
      })
    }
  })

  useEffect(() => {
    if (called && !loading && !error) {
      closeModal && closeModal()
    }
  }, [called, loading, error, closeModal])

  const removeValuationCallback = useCallback(
    async (removeConfirmationFlag) => {
      if (removeConfirmationFlag) {
        await removeMutation()
      }
    }, [removeMutation]
  )

  return (
    <RemovePolicyValuationButton {...{
      removeValuationCallback,
      ...restProps
    }}
    />
  )
}

RemovePolicyValuationButtonContainer.propTypes = {
  valuationId: T.string.isRequired,
  policyId: T.string.isRequired,
  closeModal: T.func,
  setErrors: T.func
}

export { RemovePolicyValuationButtonContainer }
