export const doesCurrentUserHavePermission = (currentUser, permissionName) => {
  if (!currentUser || !permissionName) return

  return (
    currentUser.isAdmin ||
    currentUser.isAccountOwner ||
    currentUser.permissions?.find(({ name }) => name === permissionName)
  )
}

export const onlyAccountOwnerAccess = (currentUser) => {
  if (!currentUser) return

  return (
    currentUser.isAccountOwner
  )
}

export const onlyAccountOwnerOrAdminAccess = (currentUser) => {
  if (!currentUser) return

  return (
    currentUser.isAccountOwner || currentUser.isAdmin
  )
}
