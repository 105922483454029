import T from 'prop-types'

import { Button, ConfirmationModal } from '../../../misc'

const ToggleUserEnabledButton = ({ enabled, ...props }) => (
  <Button color={Button.Colors.GREY} {...props}>
    {enabled ? 'Disable' : 'Enable'}
  </Button>
)

const UsersDisableButton = (props) => {
  const { user, canEdit, currentUser: { id: currUserId }, mutate, className } = props

  const { enabled, id: userId } = user
  const operation = enabled ? 'disable' : 'enable'

  const confirmationCallback = (answer) => {
    if (answer === true) {
      return mutate()
    }
  }

  return (
    <ConfirmationModal.Trigger
      as={ToggleUserEnabledButton}
      enabled={enabled}
      // disable the button so that a user can't disable themselves
      disabled={!canEdit || currUserId === userId}
      className={className}
      modalProps={{
        heading: `Confirm ${operation}`,
        text: `Are you sure that you want to ${operation} this user?`,
        confirmationCallback,
        awaitCallback: true
      }}
    />
  )
}

UsersDisableButton.propTypes = {
  user: T.shape({
    id: T.string.isRequired,
    enabled: T.bool.isRequired
  }).isRequired,
  currentUser: T.shape({
    id: T.string.isRequired
  }).isRequired,
  mutate: T.func.isRequired,
  className: T.string
}

export { UsersDisableButton }
