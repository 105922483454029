import T from 'prop-types'
import pluralize from 'pluralize'
import constants from '@elenfs/elen-constants'
import { prop, propEq, uniq, curry, pipe, map } from 'ramda'

import ImportInfo from './ImportInfo'

import { formatMoney } from '../../../../helpers/policy'
import { formatDate } from './../../../../helpers/date'
import { moneyType } from './../../../../helpers/propTypes'

const {
  ContributionFrequenciesLabels,
  WithdrawalFrequenciesLabels
} = constants.policy.common

const filterByPolicyNumber = curry((arr, policyNumber) =>
  arr.filter(propEq('policyNumber', policyNumber))
)

const splitTransactionsByPolicy = transactions =>
  pipe(
    map(prop('policyNumber')),
    uniq,
    map(filterByPolicyNumber(transactions))
  )(transactions)

const TransactionCreationInfo = props => {
  const {
    policyTransactionFrequency,
    policyTransactionAmount,
    policyTransactionStartDate,
    policyTransactionEndDate,
    isContribution
  } = props

  return (
    <span>
      {[
        isContribution
          ? ContributionFrequenciesLabels[policyTransactionFrequency]
          : WithdrawalFrequenciesLabels[policyTransactionFrequency],
        formatMoney(policyTransactionAmount),
        formatDate(policyTransactionStartDate),
        formatDate(policyTransactionEndDate)
      ]
        .filter(Boolean)
        .join(' - ')}
    </span>
  )
}

const TransactionsCreated = props => {
  const {
    clientFirstName,
    clientLastName,
    transactions,
    isContribution
  } = props

  const transactionsByPolicy = splitTransactionsByPolicy(transactions)

  const mappedTransactions = transactionsByPolicy.map(transactions => {
    const heading = (
      <>
        <b>
          {transactions.length}{' '}
          {pluralize(
            `${isContribution ? 'contribution' : 'withdrawal'} `,
            transactions?.length
          )}
          {' '} added
        </b>{' '}
        - {clientFirstName} {clientLastName} - Policy number{' '}
        {transactions[0].policyNumber}
      </>
    )

    const description = transactions.map((transaction, i) => (
      <TransactionCreationInfo
        key={i}
        {...transaction}
        isContribution={isContribution}
      />
    ))

    return { heading, description }
  })

  return (
    <>
      {mappedTransactions.map(({ heading, description }, i) => (
        <ImportInfo key={i} {...{ heading, description, key: i }} />
      ))}
    </>
  )
}

TransactionsCreated.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string,
  isContribution: T.bool,
  transactions: T.arrayOf(
    T.shape({
      policyTransactionFrequency: T.string,
      policyTransactionAmount: moneyType(),
      policyTransactionStartDate: T.string,
      policyTransactionEndDate: T.string
    })
  ).isRequired
}

export { TransactionsCreated }
