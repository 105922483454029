const ChevronRightIcon = (props) => (
  <svg width='1em' height='1em' viewBox='0 0 12 20' {...props}>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='next-icon' transform='translate(-59.000000, 0.000000)' fill='currentColor'>
        <g id='Group-2'>
          <path d='M66.9177958,3 C67.4665883,3 67.9114724,3.44994876 67.9114724,3.98445281 L67.9114724,16.0155472 C67.9114724,16.5204099 67.5311724,16.9365098 67.0343865,16.9933769 L66.9177958,17 L66.9,16.999 L55.0004788,17 C54.4873974,17 54.0645237,16.6172798 54.006731,16.1173326 L54,16 C54,15.4477153 54.4471036,15 55.0004788,15 L65.924,14.999 L65.9241192,3.98445281 C65.9241192,3.47959013 66.3044193,3.06349016 66.8012052,3.00662313 L66.9177958,3 Z' id='Rectangle-223' transform='translate(60.955736, 10.000000) scale(-1, 1) rotate(-225.000000) translate(-60.955736, -10.000000)' />
        </g>
      </g>
    </g>
  </svg>
)

export default ChevronRightIcon
