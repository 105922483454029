import { Card } from './../../../misc'

import './SummaryCardNBR.less'

const SummaryCardNBR = ({ summary }) => {
  const {
    fromDate,
    toDate,
    newClientsCount,
    newPoliciesCount,
    replacementsCount,
    totalClientAmount,
    totalContributionAmount,
    totalPlatformAmount,
    totalProviderAmount
  } = summary

  const rows = [
    { label: 'Date range', value: `${fromDate} - ${toDate}` },
    { label: 'Number of new clients', value: newClientsCount },
    { label: 'Number of new policies', value: newPoliciesCount },
    { label: 'Number of replacements', value: replacementsCount },
    { label: 'Total contributions', value: totalContributionAmount },
    { label: 'Total fees from client', value: totalClientAmount },
    { label: 'Total fees from platform', value: totalPlatformAmount },
    { label: 'Total fees from provider', value: totalProviderAmount }
  ]

  return (
    <Card className='summary-card-nbr'>
      <Card.Header>New Business Register</Card.Header>

      {rows.map(({ label, value }) => (
        <Card.Group className='card-new-group--inline' key={label}>
          <Card.Text>{label}</Card.Text>
          <Card.Text>{value}</Card.Text>
        </Card.Group>
      ))}
    </Card>
  )
}

export { SummaryCardNBR }
