import { useRef, useEffect, useState, useCallback } from 'react'
import { not } from 'ramda'
import classnames from 'classnames'
import { WorkflowActionStatuses, WorkflowActionTypeLabels } from '@elenfs/elen-constants'
import T from 'prop-types'

import { ChevronRightIcon } from './../../icons'
import { ActionBody } from './ActionBody/ActionBody'
import { ActionTypeIcon } from './ActionTypeIcon'
import { DeleteActionIcon } from './DeleteActionIcon'

import { actionType } from './../../../helpers/propTypes'

import './WorkflowAction.less'

const { COMPLETED, IN_PROGRESS } = WorkflowActionStatuses

const WorkflowAction = props => {
  const { action, onDeleteAction, onSummaryClick, actionIndex } = props

  const { status } = action

  const isCompletedStatus = status === COMPLETED
  const isInProgressStatus = status === IN_PROGRESS

  const summary = action.taskSummary

  const [showBody, setShowBody] = useState(false)
  const actionBodyRef = useRef()

  useEffect(() => {
    const actionBodyEl = actionBodyRef.current
    const actionBodyHeight = actionBodyEl.children[0].offsetHeight
    actionBodyEl.style.height = (showBody ? actionBodyHeight : 0) + 'px'
  }, [showBody, actionBodyRef])

  //  Toggle the body if we resize X scale
  useEffect(() => {
    let prevWindowWidth = window.innerWidth
    const onResize = () => {
      if (prevWindowWidth !== window.innerWidth) {
        setShowBody(false)
        prevWindowWidth = window.innerWidth
      }
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const onDeleteActionCB = useCallback(
    isConfirmed => {
      if (isConfirmed) onDeleteAction(actionIndex)
    },
    [onDeleteAction, actionIndex]
  )

  const onSummaryClickCB = useCallback(actionIndex => {
    if (!status) {
      onSummaryClick(actionIndex)
    } else {
      setShowBody(not)
    }
  }, [status, onSummaryClick])

  return (
    <div
      className={classnames('workflow-action', {
        'workflow-action--completed': isCompletedStatus,
        'workflow-action--in-progress': isInProgressStatus
      })}
    >
      <div
        className={classnames('workflow-action__header', {
          'workflow-action__header--active': showBody
        })}
      >
        <ChevronRightIcon
          className={classnames('workflow-action__header__toggle-icon', {
            'workflow-action__header__toggle-icon--active': showBody
          })}
          onClick={() => setShowBody(not)}
        />

        <div className='workflow-action__header__action-icon-container'>
          <ActionTypeIcon actionType={action.type} />
        </div>

        <div className='workflow-action__header__meta'>
          <div className='workflow-action__header__meta__type'>
            {WorkflowActionTypeLabels[action.type]}
          </div>
          <div
            className='workflow-action__header__meta__title'
            onClick={() => onSummaryClickCB(actionIndex)}
          >
            {summary}
          </div>
        </div>

        {!status && (
          <DeleteActionIcon
            className='workflow-action__header__delete-icon'
            actionType={action.type}
            onDeleteAction={onDeleteActionCB}
          />
        )}
      </div>

      <div className='workflow-action__body' ref={actionBodyRef}>
        <ActionBody action={action} />
      </div>
    </div>
  )
}

WorkflowAction.propTypes = {
  action: actionType().isRequired,
  onDeleteAction: T.func,
  onSummaryClick: T.func,
  actionIndex: T.number
}

export { WorkflowAction }
