import T from 'prop-types'

import { WrapperScreen, Spinner } from '../index'

import './WaitingScreen.less'

const WaitingScreen = ({ text }) => {
  return (
    <WrapperScreen className='waiting-screen'>
      <>
        <Spinner
          config={{
            lines: 8,
            length: 17,
            width: 8,
            radius: 18,
            position: 'initial',
            color: ['#4159FF', '#4d70ff', '#4463e3']
          }}
        />

        <h2 className='content__title'>Please wait…</h2>
        <p className='content__text'>{text}</p>
      </>
    </WrapperScreen>
  )
}

WaitingScreen.propTypes = {
  text: T.node
}

export { WaitingScreen }
