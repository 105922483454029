import PropTypes from 'prop-types'

import { Pagination } from '../../misc'
import { SingleImportLogHeader, SingleImportLogHeaderSummary } from './SingleImportLogHeader'
import { SingleImportLogSummary } from './SingleImportLogSummary'

import './SingleImportLogLayout.less'

export const SingleImportLogLayout = (props) => {
  const {
    pageStatus,
    link,
    stats,
    job,
    logs,
    dataImportType,
    pageCount,
    handlePaginationChange
  } = props

  return (
    <div className='single-import-layout'>
      <div className='single-import-layout__container'>
        <SingleImportLogHeader
          pageStatus={pageStatus}
          link={link}
        />

        <div className='single-import-layout__mid-content'>
          <SingleImportLogHeaderSummary stats={stats} job={job} />
          <SingleImportLogSummary
            job={job}
            logs={logs}
            dataImportType={dataImportType}
          />

          {!!logs?.length && pageCount > 1 && (
            <div className='single-import-layout__pagination'>
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePaginationChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SingleImportLogLayout.propTypes = {
  pageStatus: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  stats: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired
}
