const CheckedIcon = (props) => (
  <svg viewBox='0 0 24 24' width={20} height={20} {...props}>
    <g fill='none' fillRule='evenodd'>
      <rect width='20' height='20' x='2' y='2' fill='#4D70FF' rx='3' />
      <path
        fill='#FFF'
        d='M6.542893 10.902893c.36048396-.360484.92771479-.3882129 1.320006-.083188l.094208.083188L10.5 13.445l5.542893-5.542107c.360484-.36048396.9277148-.38821289 1.320006-.083188l.094208.083188c.360484.36048396.3882129.92771479.083188 1.320006l-.083188.094208-6.0661525 6.066152c-.4592565.4592566-1.1848355.4898737-1.67960173.0918513l-.10230731-.0918513L6.542893 12.317107c-.39052429-.3905243-.39052429-1.0236897 0-1.414214z'
      />
    </g>
  </svg>
)

export default CheckedIcon
