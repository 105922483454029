const PlusBigIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M12 4c.552 0 1 .456 1 1.002V11l5.998.001c.514 0 .937.383.995.883L20 12c0 .552-.456 1-1.002 1L13 12.999v5.999a.999.999 0 01-.883.995L12 20c-.552 0-1-.456-1-1.002v-5.999L5.002 13a.999.999 0 110-2L11 10.999V5.002c0-.514.383-.937.883-.995L12 4z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { PlusBigIcon }
