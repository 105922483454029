import { useEffect } from 'react'
import T from 'prop-types'
import classnames from 'classnames'
import Select, { components } from 'react-select'
import { isEmpty } from 'ramda'

import { optionValueType, optionsType } from '../../../helpers/propTypes'
import {
  findSelectedOption,
  findSelectedOptions,
  getFixedOptions,
  findGroupedSelectedOption
} from './helpers'

import './DropdownSelection.less'

export const DropdownSelection = ({
  className,
  name,
  inputId,
  key,
  inputProps,
  selectProps,
  onChange,
  isMulti,
  isGrouped,
  ...restProps
}) => {
  const { options, selected } = restProps
  const { isClearable } = inputProps
  const { Option: CustomOption, Control: CustomControl } = selectProps

  const selectedOption = !isEmpty(options) && (
    isMulti
      ? findSelectedOptions(options, selected)
      : isGrouped
        ? findGroupedSelectedOption(options, selected)
        : findSelectedOption(options, selected)
  )

  // preselect fixed options
  useEffect(() => {
    const fixedOptions = getFixedOptions(options)
    if (isMulti && fixedOptions.length) {
      for (const fixedOption of fixedOptions) {
        // using indexOf instead of find because there can be falsy values like ""
        if (selected?.indexOf(fixedOption.value) === -1) {
          // Re-add all the fixed options to prevent ordering issues
          // (eg a fixed option being listed after a regular option)
          onChange(
            fixedOptions
              .concat(
                findSelectedOptions(options, selected).filter(({ isFixed }) => !isFixed)
              )
          )
          break
        }
      }
    }
  }, [selected, options, onChange])

  const customStyles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base
    }
  }

  const customComponents = {
    Option: CustomOption || components.Option,
    Control: CustomControl || components.Control
  }

  return (
    <Select
      name={name}
      inputId={`${name}-${inputId}`}
      key={key || `${name}__${selected}`} // Force re-render when reseting from Formik
      classNamePrefix='dropdown-selection'
      className={classnames('dropdown-selection', className)}
      isClearable={isClearable}
      value={selectedOption || null}
      defaultValue={selectedOption || null}
      options={options}
      onChange={onChange}
      isMulti={isMulti}
      styles={customStyles}
      components={customComponents}
      theme={theme => ({
        ...theme,
        borderRadius: '3px'
      })}
      {...inputProps}
    />
  )
}
DropdownSelection.defaultProps = {
  inputProps: {
    isClearable: true
  },
  selectProps: {},
  isMulti: false,
  isGrouped: false,
  inputId: ''
}

DropdownSelection.propTypes = {
  className: T.string,
  placeholder: T.string,
  // T.array is for multiselect dropdowns (when isMulti is true).
  selected: T.oneOfType([optionValueType(), T.array]),
  options: optionsType().isRequired,
  onChange: T.func.isRequired,
  inputProps: T.object,
  inputId: T.string
}
