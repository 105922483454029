import { pick } from 'ramda'

import { buildSelectOptionsWithNoSort } from '../../form/fields/SelectField'
import {
  FilterDateRangeLabels,
  FilterDateRanges
} from './../../../constants/filterDate'

const filterDateRangeKeys = [
  FilterDateRanges.LAST_WEEK,
  FilterDateRanges.NEXT_WEEK,
  FilterDateRanges.LAST_MONTH,
  FilterDateRanges.NEXT_MONTH,
  FilterDateRanges.THIS_MONTH_TO_DATE,
  FilterDateRanges.LAST_QUARTER,
  FilterDateRanges.NEXT_QUARTER,
  FilterDateRanges.THIS_QUARTER_TO_DATE,
  FilterDateRanges.LAST_HALF_YEAR,
  FilterDateRanges.THIS_YEAR_TO_DATE,
  FilterDateRanges.LAST_YEAR,
  FilterDateRanges.CUSTOM
]

export const filterDateRanges = pick(filterDateRangeKeys, FilterDateRanges)

const filterDateRangeLabels = pick(filterDateRangeKeys, FilterDateRangeLabels)

export const filterDateRangeOptions = buildSelectOptionsWithNoSort(
  filterDateRanges,
  filterDateRangeLabels
)
