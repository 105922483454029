import { useQuery } from '@apollo/client'
import { compose } from 'ramda'

import { Spinner, ErrorMessage } from '../../../misc'
import { withCurrentUser } from '../../../hoc/container'
import { ClientPortalOverview } from './ClientPortalOverview'

import clientPortalDetailsGql from '../../../../graphql/queries/client/clientPortalDetails.graphql'

const ClientPortalOverviewContainer = (WrappedComponent) => (props) => {
  const { currentUser: { client: { id } } } = props

  const { loading, error, data, refetch } = useQuery(clientPortalDetailsGql, {
    variables: { clientId: id }
  })

  if (loading && !data) return <Spinner />
  if (error) return <ErrorMessage text='Something went wrong while loading client details' />

  return (
    <WrappedComponent
      client={data.client}
      clientId={id}
      refetchClientDetails={refetch}
      {...props}
    />
  )
}

const ClientPortalOverviewContainerEnhanced = compose(
  withCurrentUser(),
  ClientPortalOverviewContainer
)(ClientPortalOverview)

export { ClientPortalOverviewContainerEnhanced as ClientPortalOverviewContainer }
