import T from 'prop-types'
import classnames from 'classnames'

export const FormInfoSectionContent = ({ children, className }) => (
  <div
    className={classnames(
      'form-new__section__info-content',
      'info-section-content', {
        [className]: !!className
      })}
  >
    {children}
  </div>
)

FormInfoSectionContent.propTtpes = {
  children: T.node,
  className: T.string
}
