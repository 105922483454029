import T from 'prop-types'

import { CreatePolicyButton } from '../CreatePolicyButton'
import { EmptyPage } from '../../misc/EmptyPage'

import './PoliciesTable.less'

const NoPolicies = ({ clientId, readOnly }) => (
  <EmptyPage
    text={`No policies currently available${!readOnly ? ', add your first one' : ''}`}
    renderButton={() => <CreatePolicyButton clientId={clientId} />}
    readOnly={readOnly}
  />
)

NoPolicies.propTypes = {
  clientId: T.string.isRequired
}

export default NoPolicies
