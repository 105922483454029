import { useState } from 'react'
import { prop } from 'ramda'
import T from 'prop-types'
import classnames from 'classnames'

import './DashboardTabsCard.less'

const labelsClass = 'dashboard-tabs-card__labels'
const labelClass = 'dashboard-tabs-card__labels__label'

const DashboardTabsCard = ({ tabs, className }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const labels = tabs.map(prop('label'))

  const isOnlyOneTab = tabs.length === 1

  return (
    <div className={classnames('dashboard-tabs-card', className)}>
      <div
        className={classnames(labelsClass, {
          [`${labelsClass}--hidden`]: isOnlyOneTab
        })}
      >
        {labels.map((label, i) => (
          <div
            key={label}
            className={classnames(labelClass, {
              [`${labelClass}--active`]: i === activeTabIndex
            })}
            onClick={() => setActiveTabIndex(i)}
          >
            {label}
          </div>
        ))}
      </div>
      <div className='dashboard-tabs-card__content'>
        {tabs[activeTabIndex].TabComponent}
      </div>
    </div>
  )
}

DashboardTabsCard.propTypes = {
  tabs: T.arrayOf(
    T.shape({
      label: T.string.isRequired,
      TabComponent: T.oneOfType([T.elementType.isRequired, T.node])
    })
  )
}

export { DashboardTabsCard }
