import { GoBackHeader } from '../../misc'
import { WorkflowDiagramList } from '../WorkflowDiagramList'
import { WorkflowClientInstanceHeader } from '../WorkflowClientInstanceHeader'

import './WorkflowInstancePage.less'

const WorkflowInstancePage = props => {
  const { headerProps, goBackHeaderProps, diagramProps } = props

  return (
    <div className='workflow-instance-page'>
      <div>
        <GoBackHeader {...goBackHeaderProps} />

        <WorkflowClientInstanceHeader {...headerProps} />

        <div className='workflow-instance-page__diagram-container'>
          <WorkflowDiagramList {...diagramProps} />
        </div>
      </div>
    </div>
  )
}

export { WorkflowInstancePage }
