import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { Heading, PageContent, IntegrationBadge, ServiceLevelBadge } from '../../misc'
import { Charges } from '../../charge'
import { ClientNav } from '../ClientNav'
import { ClientSummaryPage } from '../ClientSummaryPage'
import { ClientDetailsPage } from '../ClientDetailsPage'
import { PoliciesPage } from '../../policy/PoliciesPage'
import { ClientComplianceSection } from '../ClientComplianceSection'
import { AuditLogEntriesPage } from '../../auditLogEntry/AuditLogEntriesPage'
import { ClientMessagesPage } from '../ClientMessagesPage'
import { MessageInput } from '../ClientMessagesPage/MessagesComponents'
import { ClientTasksPage } from '../../tasks/ClientTasksPage'
import { ClientDocumentsRouteSwitch } from '../ClientDocumentsRouteSwitch'
import { pathMatchCurrentLocation } from '../../../helpers/path'
import { useLastVisitedClientMutation } from '../../../hooks'

import './ClientSection.less'

const ClientSection = props => {
  const { client } = props
  const { pathname } = useLocation()

  const messagesPath = '/secure/clients/:clientId/messages'
  const policiesInfoPath = '/secure/clients/:clientId/policies/:policyId/:rest'
  const policiesCreateNewPath = '/secure/clients/:clientId/policies/create'
  const policiesEditPath = '/secure/clients/:clientId/policies/:policyId/edit'

  const isInPoliciesInfoPath = !!pathMatchCurrentLocation({ pathToCheck: policiesInfoPath, pathname }) ||
    !!pathMatchCurrentLocation({ pathToCheck: policiesCreateNewPath, pathname })
  const isInMessagesPath = !!pathMatchCurrentLocation({ pathToCheck: messagesPath, pathname })
  const isPolicyForm = !!pathMatchCurrentLocation({ pathToCheck: policiesCreateNewPath, pathname }) ||
    !!pathMatchCurrentLocation({ pathToCheck: policiesEditPath, pathname })

  useLastVisitedClientMutation()

  return (
    <div className='client-section'>
      {!isInPoliciesInfoPath && (
        <div className='client-section__header'>
          <Heading as='h2' className='client-section__header--heading'>
            <span>{client.fullName}</span>

            <ServiceLevelBadge serviceLevel={client?.serviceLevel} />
            <IntegrationBadge
              labelType='Client'
              integrations={client.integrations}
            />
          </Heading>

          <ClientNav />
        </div>
      )}
      <div
        className={classnames('client-section__content', {
          'client-section__content--policy-form': isPolicyForm
        })}
      >
        <div>
          <Routes>
            <Route
              path='summary'
              element={<ClientSummaryPage />}
            />
            <Route
              path='fact-find'
              element={<ClientDetailsPage canEdit {...props} />}
            />
            <Route
              path='compliance/*'
              element={<ClientComplianceSection />}
            />
            <Route
              path='policies/*'
              element={<PoliciesPage />}
            />
            <Route
              path='audit-log'
              element={<AuditLogEntriesPage />}
            />
            <Route
              path='documents/*'
              element={<ClientDocumentsRouteSwitch {...{ ...props, clientId: client.id }} />}
            />
            <Route
              path='fees-and-commissions/*'
              element={
                <PageContent>
                  <Charges isPolicyCharges={false} {...props} />
                </PageContent>
              }
            />
            <Route
              path='tasks'
              element={
                <PageContent>
                  <ClientTasksPage clientId={client.id} {...props} />
                </PageContent>
              }
            />
            <Route
              path='messages'
              element={<ClientMessagesPage {...{ clientId: client.id, ...props }} />}
            />
            <Route index element={<Navigate replace to='summary' />} />
          </Routes>
        </div>
      </div>
      {isInMessagesPath && (
        <MessageInput clientId={client.id} toUserName={client.fullName} />
      )}
    </div>
  )
}

export { ClientSection }
