import { compose } from 'ramda'

import { NotAuthorizedPage } from '../../../authorization/NotAuthorizedPage'
import { withCurrentUser } from '../../../hoc/container'
import { doesCurrentUserHavePermission } from './helpers'

const WithPermissionCheck = ({ permissionName }) => (
  WrappedComponent
) => ({ currentUser, ...props }) => {
  if (doesCurrentUserHavePermission(currentUser, permissionName)) {
    return <WrappedComponent {...props} />
  }

  return <NotAuthorizedPage />
}

const WithPermissionCheckCurrentUser = (props) => compose(
  withCurrentUser(),
  WithPermissionCheck(props)
)

export { WithPermissionCheckCurrentUser, doesCurrentUserHavePermission }
