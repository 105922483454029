import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { compose } from 'ramda'
import {
  withCurrentUser,
  withUnreadMessagesSubscription
} from '../../hoc/container'

import { PlusIcon } from '../../icons'
import { MessagesDashboardCard } from './MessagesDashboardCard'
import { Modal, Button } from '../../misc'
import { SendMessageModal } from '../../misc/SendMessageModal'

const MessageModalTrigger = props => (
  <Modal.Trigger as={Button} modal={SendMessageModal} modalProps={props}>
    <PlusIcon /> New Message
  </Modal.Trigger>
)

const MessagesDashboardCardContainer = props => {
  const {
    subscribeForUnreadMessages,
    unreadMessagesCount,
    isClientPortal,
    client
  } = props

  const navigate = useNavigate()

  const navigateToClientMsg = useCallback(
    clientId =>
      navigate(
        isClientPortal
          ? '/clients/secure/overview/messages'
          : `/secure/clients/${clientId}/messages`
      ),
    [navigate, isClientPortal]
  )

  useEffect(() => {
    let unsubscribe
    if (subscribeForUnreadMessages) {
      unsubscribe = subscribeForUnreadMessages()
    }
    return () => unsubscribe && unsubscribe()
  }, [subscribeForUnreadMessages])

  const listProps = {
    messagesNotificationList: unreadMessagesCount,
    navigateOnMsgClick: navigateToClientMsg,
    isClientPortal
  }

  const titleProps = {
    ActionComponent: MessageModalTrigger,
    actionComponentProps: { isClientPortal, clientId: client?.id }
  }

  return <MessagesDashboardCard {...{ titleProps, listProps }} />
}

const EnhancedMessagesDashboardCardContainer = compose(
  withCurrentUser(),
  withUnreadMessagesSubscription()
)(MessagesDashboardCardContainer)

export { EnhancedMessagesDashboardCardContainer as MessagesDashboardCardContainer }
