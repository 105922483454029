import { Trash } from './../../icons'

import { showDeleteIcon, buildInputProps } from './helpers'

const FormArrayRow = props => {
  const {
    rowInputs,
    arrayHelpers,
    index,
    cantRemoveFirst,
    ...otherProps
  } = props

  const removeRow = () => arrayHelpers.remove(index)

  return (
    <div className='form-array-fields__section__row'>
      {rowInputs.map(({ InputComponent, ...otherRowProps }, i) => (
        <InputComponent
          key={i}
          {...buildInputProps({
            index,
            ...otherProps,
            ...otherRowProps
          })}
        />
      ))}

      {showDeleteIcon(cantRemoveFirst, index) && (
        <Trash
          className='form-array-fields__section__row__delete-icon'
          onClick={removeRow}
        />
      )}
    </div>
  )
}

export { FormArrayRow }
