import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import { enumSchema, stringSchema, dateSchema, booleanSchema } from '../helpers'

const {
  PolicyProviders,
  PolicyStatuses,
  PolicyCategories
} = constants.policy.common

export default () => ({
  status: enumSchema(values(PolicyStatuses))
    .label('Policy status')
    .required(),

  category: enumSchema(values(PolicyCategories))
    .label('Category')
    .required(),

  number: stringSchema()
    .label('Policy number'),

  currentHoldersIds: yup.array().of(
    stringSchema().required('Policy holder is a required field')
  ).default([]),

  provider: enumSchema(values(PolicyProviders))
    .label('Provider'),

  applicationDate: dateSchema()
    .label('Application date'),

  description: stringSchema()
    .label('Description'),

  isReplacementBusiness: booleanSchema()
    .label('Replacement business')
    .required(),

  isHighRisk: booleanSchema()
    .label('Pre-check / high risk')
    .required()
})
