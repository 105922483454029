const ShapeVerticalStripesIcon = props => (
  <svg width={112} height={111} viewBox='0 0 112 111' {...props}>
    <g stroke='currentColor' fill='none' fillRule='evenodd'>
      <path d='M259.587-249.257c7.081 32.202-17.834 62.437-50.36 80.645-32.522 18.205-72.17 27.381-109.173 39.885C63.05-116.22 26.522-98.764 9.2-70.407c-30.819 50.456 9 111.814 24.073 168.879 22.809 86.344-17.253 177.635-101.077 230.333' />
      <path d='M290.587-238.257c7.081 32.202-17.836 62.436-50.36 80.645-32.524 18.205-72.169 27.381-109.172 39.885-37.004 12.506-73.53 29.962-90.852 58.32-30.818 50.455 9 111.813 24.073 168.88 22.808 86.342-17.253 177.632-101.079 230.332' />
      <path d='M311.587-228.257c7.081 32.202-17.834 62.437-50.36 80.645-32.525 18.205-72.17 27.381-109.173 39.885C115.05-95.22 78.522-77.764 61.2-49.407 30.382 1.05 70.2 62.408 85.274 119.473c22.809 86.344-17.253 177.635-101.077 230.333' />
      <path d='M342.587-219.257c7.081 32.202-17.834 62.437-50.36 80.645-32.522 18.205-72.168 27.381-109.174 39.887-37.003 12.504-73.53 29.96-90.85 58.319-30.82 50.454 8.998 111.812 24.073 168.88 22.807 86.34-17.255 177.631-101.079 230.331' />
    </g>
  </svg>
)

export { ShapeVerticalStripesIcon }
