const InfoIcon = props => (
  <svg width={17} height={16} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M.93 0h16v16h-16z' />
      <path
        d='M8.93 16a8 8 0 110-16 8 8 0 010 16zm0-10.3a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4zm0 7.3a1 1 0 001-1V8.2a1 1 0 10-2 0V12a1 1 0 001 1z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { InfoIcon }
