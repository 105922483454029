"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SourceTypes = exports.ChargeTypes = exports.ChargeFrequencies = exports.AdviceTypes = void 0;
var _misc = require("./misc");
const ChargeTypes = exports.ChargeTypes = Object.freeze({
  COMMISSION: 'COMMISSION',
  FEE: 'FEE'
});
const AdviceTypes = exports.AdviceTypes = Object.freeze({
  INDEPENDENT: 'INDEPENDENT',
  RESTRICTED: 'RESTRICTED'
});
const SourceTypes = exports.SourceTypes = Object.freeze({
  PLATFORM: 'PLATFORM',
  CLIENT: 'CLIENT',
  PROVIDER: 'PROVIDER'
});
const ChargeFrequencies = exports.ChargeFrequencies = Object.freeze({
  INITIAL: 'INITIAL',
  SINGLE: 'SINGLE',
  AD_HOC: 'AD_HOC',
  ..._misc.RecurrentFrequencies
});
var _default = exports.default = {
  ChargeTypes,
  AdviceTypes,
  SourceTypes,
  ChargeFrequencies
};