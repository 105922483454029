export const splitToActiveAndInactiveByStatus = (list) => {
  const activeUserList = []
  const inactiveUserList = []

  list && list.forEach((item) => {
    if (item && item.enabled) {
      activeUserList.push(item)
    } else {
      inactiveUserList.push(item)
    }
  })

  return { activeUserList, inactiveUserList }
}

export const getInitialsFromUser = ({ nameOfBusiness, firstName, lastName }) => {
  if (nameOfBusiness) {
    return nameOfBusiness.slice(0, 2).toUpperCase().split('')
  } else if (firstName && lastName) {
    return [
      firstName[0].toUpperCase(),
      lastName[0].toUpperCase()
    ]
  } else {
    return []
  }
}
