import {
  WorkflowActionTypes,
  WorkflowTargetTypes
} from '@elenfs/elen-constants'
import { is } from 'ramda'

export const submitWorkflowTemplate = props => {
  const {
    name,
    actions,
    workflowId,
    updateWorkflowTemplate,
    createWorkflowTemplate
  } = props

  const input = {
    name,
    targetType: WorkflowTargetTypes.CLIENT,
    actionTemplates: actions.map((action, i) => {
      const taskData = {
        summary: action.taskSummary,
        type: action.taskType
      }

      if (is(Number, action.taskDueDateAfterMs)) {
        taskData.dueDateAfterMs = action.taskDueDateAfterMs
      }

      if (action.taskAssigneeTeamRole) {
        taskData.assigneeTeamRole = action.taskAssigneeTeamRole
      }

      if (action.taskDescription) {
        taskData.description = action.taskDescription
      }

      return {
        type: WorkflowActionTypes.TASK,
        order: i,
        taskData
      }
    })
  }

  if (workflowId) {
    updateWorkflowTemplate({
      variables: { input: { ...input, id: workflowId } }
    })
  } else {
    createWorkflowTemplate({ variables: { input } })
  }
}
