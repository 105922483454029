import { PolicyDetails, AmountCard, DetailsCard } from '../../PolicyDetails'
import { PolicyNoteCard } from '../../PolicyNoteCard'

import { formatMoney } from '../../../../helpers/policy'
import { formatDate } from '../../../../helpers/date'
import {
  BooleanLabels,
  PolicyCategoriesLabels,
  PolicyProvidersLabels
} from '../../../../helpers/enumLabels'
import {
  ProtectionTypesLabels,
  PlanStatusesLabels,
  AssuredLivesLabels,
  CoverTypesLabels,
  DeferredPeriodsLabels,
  OfferedTermsLabels,
  WaiversLabels
} from '../../../../helpers/enumLabels/protectionPolicy'
import { resolvePolicyHoldersData } from '../helpers'

const ProtectionPolicyDetails = ({ policy, headingText, readOnly }) => {
  const cardData = [
    { label: 'Surrender value', value: formatMoney(policy.surrenderValue) },
    { label: 'Valuation date', value: formatDate(policy.valuationDate) }
  ]

  const data = [
    [
      {
        label: 'Replacement business',
        value: policy?.isReplacementBusiness ? 'Yes' : 'No'
      },
      { label: 'Category', value: PolicyCategoriesLabels[policy.category] },
      {
        label: 'Policy type',
        value: ProtectionTypesLabels[policy.protectionType]
      },
      { label: 'Policy number', value: policy.number },
      ...resolvePolicyHoldersData(policy)(policy?.currentHolders, readOnly),
      { label: 'Plan status', value: PlanStatusesLabels[policy.planStatus] },
      { label: 'Provider', value: PolicyProvidersLabels[policy.provider] },
      {
        label: 'Live(s) assured',
        value: AssuredLivesLabels[policy.livesAssured]
      },
      { label: 'Waiver', value: WaiversLabels[policy.waiver] },
      {
        label: 'Guaranteed insurability option',
        value: BooleanLabels[policy.guaranteedInsurabilityOption]
      },
      { label: 'Terms offered', value: OfferedTermsLabels[policy.termsOffered] }
    ],
    [
      { label: 'Application date', value: formatDate(policy.applicationDate) },
      { label: 'On risk date', value: formatDate(policy.onRiskDate) },
      { label: 'End date', value: formatDate(policy.endDate) }
    ],
    [
      { label: 'Regular premium for cover', value: formatMoney(policy.regularPremiumForCover) },
      {
        label: 'Lump sum benefit on critical illness',
        value: formatMoney(policy.lumpSumBenefitOnCriticalIllness)
      },
      {
        label: 'Lump sum benefit on death',
        value: formatMoney(policy.lumpSumBenefitOnDeath)
      },
      {
        label: 'Annual benefit (PHI)',
        value: formatMoney(policy.annualBenefit)
      },
      {
        label: 'Term or maturity date',
        value: formatDate(policy.termOfMaturityDate)
      },
      {
        label: 'Deferred period',
        value: DeferredPeriodsLabels[policy.deferredPeriod]
      },
      { label: 'Cover type (WOL)', value: CoverTypesLabels[policy.coverType] }
    ]
  ]

  return (
    <PolicyDetails>
      <div>
        <DetailsCard {...{ headingText, data, readOnly }} />
      </div>
      <div>
        <AmountCard
          headingText='Current value'
          headingValue={formatMoney(policy.currentValue)}
          cardData={cardData}
        />

        {!readOnly && <PolicyNoteCard note={policy?.note} policyId={policy?.id} />}
      </div>
    </PolicyDetails>
  )
}

export default ProtectionPolicyDetails
