import { useState, useCallback, useEffect, useRef } from 'react'
import { compose, reject, equals } from 'ramda'
import { withFormik, connect } from 'formik'
import { debounce } from 'debounce'
import classnames from 'classnames'
import { useSearchParams, useLocation } from 'react-router-dom'

import { Form } from '../../form'
import { SelectField } from '../../form/fields'
import { Button, Heading } from '../../misc'
import { DropdownIcon } from './../../icons'
import { buildInitialValues } from '../../../helpers/form'
import { taskListFilterSchema } from '../../../schemas/tasks'

import { taskStatusOptions, taskTypeOptions } from './../const'

import './TasksFilter.less'

const DEBOUNCE_INTERVAL_MS = 300
const MOBILE_BREAK_POINT = 767
const isMobile = width => width < MOBILE_BREAK_POINT

const TasksFilter = props => {
  const {
    assigneeOptions,
    dirty,
    touched,
    submitForm,
    values,
    setFieldValue,
    disableStatus
  } = props
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const [showFilters, setShowFilters] = useState(false)

  const debouncedSubmit = useCallback(
    debounce(submitForm, DEBOUNCE_INTERVAL_MS, true),
    [dirty]
  )

  const onReset = useCallback(async () => {
    setFieldValue('status', [])
    setFieldValue('clientId', null)
    setFieldValue('assignedTo', null)
    setFieldValue('type', null)

    debouncedSubmit.clear()
    debouncedSubmit()
  }, [setFieldValue])

  const setValuesFromParams = useCallback((valuesParams) => {
    for (const property in valuesParams) {
      setFieldValue(property, valuesParams[property])
    }
  }, [setFieldValue])

  useEffect(() => {
    const valuesParam = {
      status: searchParams.getAll('status'),
      clientId: searchParams.get('clientId'),
      assignedTo: searchParams.get('assignedTo'),
      type: searchParams.get('type')
    }
    if (location.search) {
      setValuesFromParams(valuesParam)
    }
  }, [searchParams, location])

  useEffect(() => {
    const isTouched = Object.keys(touched).length
    if (isTouched && !dirty) onReset()
    if (dirty) debouncedSubmit()
  }, [touched, dirty, values])

  // Toggle Filters logic
  const animationWrapperRef = useRef()
  const filersContainerRef = useRef()

  const toggleFilters = () => {
    setShowFilters(!showFilters)
    const filtersHeight = filersContainerRef.current.offsetHeight
    animationWrapperRef.current.style.maxHeight =
      (!showFilters ? filtersHeight : 0) + 'px'
  }

  useEffect(() => {
    const resizeFunc = () => {
      const filtersHeight = filersContainerRef.current.offsetHeight

      if (isMobile(window.innerWidth)) {
        animationWrapperRef.current.style.maxHeight =
          (showFilters ? filtersHeight : 0) + 'px'
      } else {
        setShowFilters(false)
        animationWrapperRef.current.style.maxHeight = filtersHeight + 'px'
      }
    }

    window.addEventListener('resize', resizeFunc)
    return () => window.removeEventListener('resize', resizeFunc)
  }, [showFilters])

  useEffect(() => {
    if (isMobile(window.innerWidth)) {
      toggleFilters()
    }
  }, [])

  useEffect(() => {
    const filtersHeight = filersContainerRef.current.offsetHeight
    animationWrapperRef.current.style.maxHeight = filtersHeight + 'px'
  }, [values.status])

  return (
    <Form className='tasks-filter'>
      <div className='tasks-filter__header' onClick={toggleFilters}>
        <Heading as='h3'> Filters </Heading>

        <DropdownIcon
          className={classnames('tasks-filter__header__icon', {
            'tasks-filter__header__icon--rotate': !showFilters
          })}
        />
      </div>

      <div
        ref={animationWrapperRef}
        className={classnames('tasks-filter__animation-wrapper', {
          'tasks-filter__animation-wrapper--show': showFilters
        })}
      >
        <div
          ref={filersContainerRef}
          className='tasks-filter__fields-container'
        >
          <SelectField
            name='status'
            inputId='filter'
            options={taskStatusOptions}
            label='Status'
            isMulti
            inputProps={{
              placeholder: 'Select..',
              isDisabled: disableStatus
            }}
          />

          <SelectField
            name='clientId'
            label='Client'
            inputProps={{
              async: true,
              isClearable: true,
              placeholder: 'Select..',
              asyncProps: {
                labelProp: 'fullName',
                valueProp: 'id'
              }
            }}
          />

          <SelectField
            name='assignedTo'
            options={assigneeOptions}
            label='Assignee'
            inputProps={{
              placeholder: 'Select..',
              isClearable: true
            }}
          />

          <SelectField
            name='type'
            options={taskTypeOptions}
            label='Activity type'
            inputProps={{
              placeholder: 'Select..',
              isClearable: true
            }}
          />

          <Button onClick={onReset}> Reset </Button>
        </div>
      </div>
    </Form>
  )
}

const EnhancedTasksFilter = compose(
  withFormik({
    mapPropsToValues: ({ tasksFilter = {} }) =>
      buildInitialValues(taskListFilterSchema, { ...tasksFilter }),
    handleSubmit: async (values, { props: { onSubmit } }) => {
      await onSubmit({ ...reject(equals(null))(values) })
    }
  }),
  connect
)(TasksFilter)

export { EnhancedTasksFilter as TasksFilter }
