import { useCallback, useEffect } from 'react'
import { withFormik, connect } from 'formik'
import classnames from 'classnames'
import T from 'prop-types'
import { isEmpty, compose } from 'ramda'
import constants from '@elenfs/elen-constants'

import { Form, FormErrorMessages } from '../../../form'
import {
  SelectField,
  DateField,
  RadioGroupField,
  PercentField,
  MoneyField
} from '../../../form/fields'
import { buildSelectOptions } from '../../../form/fields/SelectField'
import { Button, LoadingMessage, ConfirmationModal } from '../../../misc'
import {
  AdviceTypesLabels,
  SourceTypesLabels,
  ChargeFrequenciesLabels
} from '../../../../helpers/enumLabels/charge'
import { chargeSchema } from '../../../../schemas/charge/chargeSchema'

import { chargeTypes } from './const'
import {
  isRecurrentFrequency,
  getUnitsInYear,
  getCalculatedCharge,
  getAnnualCharge,
  getDefaultGbpAmount
} from '../../../../helpers/charge'
import { optionsType } from '../../../../helpers/propTypes'
import { formatMoney } from '../../../../helpers/policy'
import { buildInitialValues } from '../../../../helpers/form'
import { parseGraphqlErrors } from '../../../../helpers/error'

import './ChargeDetailsForm.less'

const { AdviceTypes, SourceTypes, ChargeFrequencies } = constants.charge

const adviceTypesOptions = buildSelectOptions(AdviceTypes, AdviceTypesLabels)
const sourceTypesOptions = buildSelectOptions(SourceTypes, SourceTypesLabels)
const chargeFrequenciesOptions = buildSelectOptions(
  ChargeFrequencies,
  ChargeFrequenciesLabels
)

const getUnitsText = units => `${units}x recurring`

const ChargeDetailsForm = props => {
  const {
    advisersRate,
    adviserOptions,
    policyOptions,
    policyTransactionOptions,
    setSelectedPolicyTransactionId,
    selectedPolicyTransaction,
    chargeType,
    formik: {
      values,
      errors,
      submitForm,
      resetForm,
      validateForm,
      setFieldValue
    },
    loading,
    onDone,
    onDelete,
    handleSetSelectedClientId,
    handleSetSelectedPolicyId,
    handleSetSelectedAdviserId,
    adviserRate,
    charge,
    isChargeReconciled,
    isRecurrentCharge,
    latestDueDate,
    policyOptionsLoading,
    contributionAndWithdrawalLoading
  } = props

  const {
    useAmount,
    adviserId,
    policyTransactionId,
    startDate,
    frequency,
    clientId,
    policyId
  } = values

  const showOnlyStartDate = !isRecurrentFrequency(frequency)
  const annualUnits = getUnitsInYear(values)
  const calculatedCharge = getCalculatedCharge(
    values,
    selectedPolicyTransaction
  )
  const calculatedAnnualCharge = getAnnualCharge(
    values,
    selectedPolicyTransaction
  )
  const displayAnnualCharge = startDate && isRecurrentFrequency(frequency)
  const disabledAdviserRate =
    adviserId && advisersRate && advisersRate.length > 0

  const showSaveButton = !(!isRecurrentCharge && isChargeReconciled)
  const showDeleteButton = !isChargeReconciled

  // set the id of selected client so that the container would be able to load it
  useEffect(() => handleSetSelectedClientId(clientId), [clientId])

  // set the id of selected policy so that the container would be able to load it
  useEffect(() => handleSetSelectedPolicyId(policyId), [policyId])

  // set the id of selected client so that the container would be able to load it
  useEffect(() => handleSetSelectedAdviserId(adviserId), [adviserId])

  // set the id of selected transaction so that the container would be able to load it
  useEffect(() => setSelectedPolicyTransactionId(policyTransactionId), [
    policyTransactionId
  ])

  useEffect(() => {
    if (useAmount) {
      setFieldValue('policyTransactionPercentage', 100)
    } else {
      setFieldValue('amount', getDefaultGbpAmount())
    }
  }, [useAmount])

  useEffect(() => {
    if (adviserId && adviserRate) {
      setFieldValue('adviserRate', adviserRate)
    }

    if (!adviserId) {
      setFieldValue('adviserRate', null)
    }
  }, [adviserRate, adviserId])

  // clear policy if change to another client, and those does not have any policies
  useEffect(() => {
    const findPolicyInList = id =>
      policyOptions.find(({ value }) => id === value)

    if (
      !clientId ||
      (policyId && !policyOptionsLoading && !findPolicyInList(policyId))
    ) {
      setFieldValue('policyId', null)
    }
  }, [clientId, policyOptions, policyId, policyOptionsLoading])

  // clear transaction if change to another policy, and those does not have any transaction
  useEffect(() => {
    const findTransactionInList = id =>
      policyTransactionOptions.find(({ value }) => id === value)

    if (
      !policyId ||
      (policyTransactionId &&
        !contributionAndWithdrawalLoading &&
        !findTransactionInList(policyTransactionId))
    ) {
      setFieldValue('policyTransactionId', null)
    }
  }, [
    policyId,
    policyTransactionId,
    contributionAndWithdrawalLoading,
    policyTransactionOptions
  ])

  const validateAndSubmit = useCallback(async () => {
    try {
      const errors = await validateForm()
      if (Object.keys(errors).length) {
        return false
      }

      await submitForm()
      return true
    } catch (e) {
      return false
    }
  }, [submitForm])

  const onDoneClick = useCallback(async () => {
    if (await validateAndSubmit()) {
      onDone()
    }
  }, [validateAndSubmit])

  const onSaveAndAddNewClick = useCallback(async () => {
    if (await validateAndSubmit()) {
      resetForm()
    }
  }, [validateAndSubmit])

  const deleteAndCloseModal = useCallback(
    async confirmed => {
      if (confirmed) {
        await onDelete()
        onDone()
      }
    },
    [onDelete, onDone]
  )

  const useAmountOptions = [
    {
      label: (
        <MoneyField
          name='amount'
          label='Amount*'
          positiveOnly={false}
          inputProps={{ disabled: isChargeReconciled, placeholder: '0.00' }}
        />
      ),
      value: true
    },
    {
      label: (
        <div className='charge-details-form__row__radio-buttons__transaction'>
          <SelectField
            name='policyTransactionId'
            label='Link To Contribution / Withdrawal*'
            options={policyTransactionOptions}
            inputProps={{
              isDisabled: useAmount || isChargeReconciled || !policyId
            }}
          />

          <PercentField
            name='policyTransactionPercentage'
            label='% of'
            inputProps={{
              disabled: useAmount || isChargeReconciled || !policyId
            }}
          />
        </div>
      ),
      value: false
    }
  ]

  return (
    <Form className='charge-details-form'>
      <div className='charge-details-form__row'>
        <SelectField
          name='clientId'
          label='Client'
          optionsToResolve={[clientId].filter(Boolean)}
          inputProps={{
            async: true,
            isClearable: false,
            isDisabled: isChargeReconciled,
            asyncProps: {
              labelProp: 'fullName',
              valueProp: 'id'
            }
          }}
        />

        <SelectField
          name='policyId'
          label='Related Policy'
          options={policyOptions}
          inputProps={{ isClearable: true, isDisabled: isChargeReconciled }}
        />
      </div>

      <div className='charge-details-form__row'>
        <SelectField
          name='frequency'
          label='Frequency'
          options={chargeFrequenciesOptions}
          inputProps={{ isDisabled: isChargeReconciled }}
        />

        <div className='charge-details-form__row--50-50'>
          <DateField
            name='startDate'
            label={!showOnlyStartDate ? 'Start date*' : 'Date*'}
            inputProps={{
              disabled: isChargeReconciled,
              placeholder: 'dd/mm/yyyy'
            }}
          />
          {!showOnlyStartDate && (
            <DateField
              name='endDate'
              label='End date'
              inputProps={{
                disabled: isChargeReconciled && !isRecurrentCharge,
                placeholder: 'dd/mm/yyyy'
              }}
              flatpickrOptions={{ minDate: latestDueDate }}
            />
          )}
        </div>
      </div>

      <div
        className={classnames('charge-details-form__row__radio-buttons', {
          'charge-details-form__row__radio-buttons--active-first': useAmount,
          'charge-details-form__row__radio-buttons--active-second': !useAmount
        })}
      >
        <RadioGroupField
          name='useAmount'
          options={useAmountOptions}
          inputId='use-amount-radio-group'
          disabled={!!isChargeReconciled}
        />
      </div>

      <div className='charge-details-form__row--reverse'>
        <div className='charge-details-form__row--50-50'>
          <SelectField
            name='source'
            label='Source*'
            options={sourceTypesOptions}
            inputProps={{ isDisabled: isChargeReconciled }}
          />
          <SelectField
            name='adviceType'
            label='Advice type'
            options={adviceTypesOptions}
            inputProps={{ isDisabled: isChargeReconciled }}
          />
        </div>
      </div>

      <Form.Divider />

      <div className='charge-details-form__row--reverse'>
        <div className='charge-details-form__row--reverse__fa'>
          <SelectField
            name='adviserId'
            label='Adviser'
            options={adviserOptions}
            inputProps={{ isDisabled: isChargeReconciled }}
          />
          <SelectField
            name='adviserRate'
            label='Adviser rate'
            options={advisersRate}
            inputProps={{
              isDisabled: !disabledAdviserRate || isChargeReconciled
            }}
          />
        </div>
      </div>

      <div className='charge-details-form__calculated-fee'>
        <div className='charge-details-form__calculated-fee__row'>
          <div className='charge-details-form__calculated-fee__row__label'>
            Calculated {chargeType}
          </div>
          <div className='charge-details-form__calculated-fee__row__value'>
            {formatMoney(calculatedCharge)}
          </div>
        </div>

        {displayAnnualCharge && (
          <div className='charge-details-form__calculated-fee__row'>
            <div className='charge-details-form__calculated-fee__row__label'>
              Annual {chargeType} <i>({getUnitsText(annualUnits)})</i>
            </div>
            <div className='charge-details-form__calculated-fee__row__value'>
              <b> {formatMoney(calculatedAnnualCharge)} </b>
            </div>
          </div>
        )}
      </div>

      {loading && <LoadingMessage />}
      {!isEmpty(errors) && <FormErrorMessages validationErrors={errors} />}

      <Form.ButtonGroup>
        {showSaveButton && (
          <Button color={Button.Colors.BLUE} onClick={onDoneClick}>
            Save
          </Button>
        )}
        {!charge && (
          <Button color={Button.Colors.GREEN} onClick={onSaveAndAddNewClick}>
            Save and add new
          </Button>
        )}

        {charge && onDelete && showDeleteButton && (
          <ConfirmationModal.Trigger
            as={Button}
            className='charge-details-form__delete-button'
            modalProps={{
              confirmationCallback: deleteAndCloseModal,
              heading: `Delete ${chargeType}`,
              text: `Are you sure you want to delete this ${chargeType}`
            }}
            color={Button.Colors.RED_TEXT}
          >
            {`Delete ${chargeType}`}
          </ConfirmationModal.Trigger>
        )}
      </Form.ButtonGroup>
    </Form>
  )
}

const ChargeDetailsFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: chargeSchema,
    mapPropsToValues: ({ charge = {}, policyId, clientId, adviserRate }) =>
      buildInitialValues(chargeSchema, {
        clientId,
        policyId,
        adviserRate,
        useAmount: !(charge && charge.policyTransactionId),
        ...charge
      }),
    handleSubmit: async (values, { props, setErrors }) => {
      const { onSubmit, charge, chargeType } = props
      const valuesToSubmit = chargeSchema.cast(values, { stripUnknown: true })

      let newValues = Object.assign({}, valuesToSubmit)

      if (!newValues.useAmount) {
        delete newValues.amount
      } else {
        delete newValues.policyTransactionId
        delete newValues.policyTransactionPercentage
        delete newValues.percentageOf
      }

      if (newValues.policyId) {
        delete newValues.clientId
      } else {
        delete newValues.policyId
      }

      // remove policyId if null
      if (!newValues.policyId) {
        delete newValues.policyId
      }

      delete newValues.useAmount

      if (charge && charge.id) {
        newValues = {
          ...newValues,
          id: charge.id
        }
      }

      // make sure to unset end date
      if (!newValues.endDate) {
        newValues.endDate = null
      }

      // send adviserRate null if previously select and remove adviser
      if (!newValues.adviserId) {
        if (newValues.adviserId === null) {
          newValues.adviserRate = null
        } else {
          delete newValues.adviserRate
        }
      }

      try {
        return await onSubmit({ variables: { input: newValues } })
      } catch (err) {
        setErrors(
          parseGraphqlErrors(
            err,
            `There was an error saving the ${chargeType}, please try again later`
          )
        )
        throw err
      }
    }
  }),
  connect
)(ChargeDetailsForm)

ChargeDetailsFormEnhanced.defaultProps = {
  advisersRate: [],
  adviserOptions: [],
  policyOptions: [],
  policyTransactionOptions: [],
  adviserRateOptions: []
}

ChargeDetailsFormEnhanced.propTypes = {
  /**
   * Preselected clientId
   */
  clientId: T.string,
  /**
   * Preselected policyId
   */
  policyId: T.string,
  /**
   * Preselected adviserId
   */
  adviserId: T.string,
  /**
   * Preselected adviserRate
   */
  adviserRate: T.oneOfType([T.string, T.number]),
  /**
   * Preselected policyTransactionId
   */
  policyTransactionId: T.string,
  /**
   * Fee or commission.
   */
  chargeType: T.oneOf(Object.values(chargeTypes)).isRequired,
  /**
   * Options for the Adviser select field.
   */
  adviserOptions: optionsType().isRequired,
  /**
   * Options for the Adviser rate select field.
   */
  adviserRateOptions: optionsType().isRequired,
  /**
   * Options for the Policy select field.
   */
  policyOptions: optionsType().isRequired,
  /**
   * Options for the Fee / commission select field.
   */
  policyTransactionOptions: optionsType().isRequired,
  /**
   * Loaded policy transaction that was selected.
   */
  selectedPolicyTransaction: T.object,
  /**
   * Set id of the selected policy transaction.
   */
  setSelectedPolicyTransactionId: T.func.isRequired,
  /**
   * Set id of the selected client.
   */
  handleSetSelectedClientId: T.func.isRequired,
  /**
   * Set id of the selected policy.
   */
  handleSetSelectedPolicyId: T.func.isRequired,
  /**
   * Set id of the selected adviser.
   */
  handleSetSelectedAdviserId: T.func.isRequired,
  /**
   * Is any mutation loading. Eg mutation for saving or for deleting the charge.
   */
  loading: T.bool,
  /**
   * Callback to execute when form submits.
   */
  onDone: T.func.isRequired,
  /**
   * Callback to submit the form.
   */
  onSubmit: T.func.isRequired,
  /**
   * Callback to delete the charge.
   */
  onDelete: T.func,
  /**
   * Charge that's being edited.
   */
  charge: T.object
}

export { ChargeDetailsFormEnhanced as ChargeDetailsForm }
