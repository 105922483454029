export const TableHeaders = Object.freeze({
  CLIENT_NAME: 'Client name',
  KNOWN_AS: 'Known as',
  CURRENT_ADVISER: 'Current adviser',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  CITY: 'City',
  POST_CODE: 'Post code',
  EMAIL: 'Email',
  MOBILE_PHONE: 'Mobile',
  HOME_PHONE: 'Home',
  WORK_PHONE: 'Work',
  LANDLINE: 'Landline',
  VULNERABLE: 'Vulnerable'
})
