const WorkflowIcon = props => (
  <svg width={16} height={16} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M16 16V0H0v16z' />
      <path
        d='M10.46 11.172a.41.41 0 01.411.41v3.008a.41.41 0 01-.41.41H5.539a.41.41 0 01-.41-.41v-3.008a.41.41 0 01.41-.41zm-.41.82h-4.1v2.188h4.1v-2.188zM4.146 8.22a.41.41 0 110 .82H2.504a.684.684 0 00-.684.684v1.64c0 .377.307.684.684.684h.65l-.393-.394a.41.41 0 11.58-.58l1.094 1.094c.16.16.16.42 0 .58L3.34 13.841a.409.409 0 01-.58 0 .41.41 0 010-.58l.393-.394h-.65c-.83 0-1.504-.674-1.504-1.504v-1.64c0-.83.675-1.504 1.504-1.504zm6.316-2.133a.41.41 0 01.41.41v3.008a.41.41 0 01-.41.41H5.539a.41.41 0 01-.41-.41V6.496a.41.41 0 01.41-.41zm-.41.82H5.949v2.188h4.102V6.906zm3.445-3.773c.83 0 1.504.674 1.504 1.504v1.64c0 .83-.675 1.504-1.504 1.504h-.65l.393.394a.41.41 0 11-.58.58L11.565 7.66a.41.41 0 010-.58l1.094-1.094a.41.41 0 11.58.58l-.393.394h.65a.684.684 0 00.684-.684v-1.64a.684.684 0 00-.684-.684h-1.64a.41.41 0 110-.82zM10.461 1a.41.41 0 01.41.41v3.008a.41.41 0 01-.41.41H5.539a.41.41 0 01-.41-.41V1.41a.41.41 0 01.41-.41zm-.41.82H5.949v2.188h4.102V1.82z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { WorkflowIcon }
