const SaveIcon = props => (
  <svg width={24} height={24} {...props}>
    <title>Icon/24/Save_24</title>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M20.1 7.127 16.873 3.9c-.521-.522-1.436-.9-2.173-.9h-9A2.703 2.703 0 0 0 3 5.7v12.6C3 19.789 4.211 21 5.7 21h12.6c1.489 0 2.7-1.211 2.7-2.7v-9c0-.737-.379-1.652-.9-2.173ZM10.2 4.8h3.6v1.8h-3.6V4.8Zm5.4 14.4H8.4v-2.7a.9.9 0 0 1 .9-.9h5.4a.9.9 0 0 1 .9.9v2.7Zm3.6-.9a.9.9 0 0 1-.9.9h-.9v-2.7c0-1.489-1.211-2.7-2.7-2.7H9.3a2.703 2.703 0 0 0-2.7 2.7v2.7h-.9a.9.9 0 0 1-.9-.9V5.7a.9.9 0 0 1 .9-.9h2.7v2.7a.9.9 0 0 0 .9.9h5.4a.9.9 0 0 0 .9-.9V5.173L18.827 8.4c.181.18.373.643.373.9v9Z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { SaveIcon }
