import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Card from '../../../misc/Card'
import Summary from '../../../misc/Summary'
import { PageDivider } from '../../../misc/PageDivider'
import PencilIcon from '../../../icons/Pencil'

import './DetailsCard.less'

const DetailsCard = ({ headingText, data, readOnly }) => (
  <Card className='policy-details__card'>
    <Card.Group className='card-new-group--inline'>
      <Card.Heading>
        {headingText}
      </Card.Heading>
      {
        !readOnly &&
          <Link to='edit'>
            <PencilIcon className='icon' />
          </Link>
      }
    </Card.Group>

    {data && data.map((item, index) => (
      <Fragment key={`details-${index}`}>
        <Summary data={item} />
        {index + 1 !== data.length && <PageDivider />}
      </Fragment>
    ))}
  </Card>
)

export default DetailsCard
