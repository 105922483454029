const SuitCaseIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M20.182 6.273H16.09V3.818A.818.818 0 0015.273 3H8.727a.818.818 0 00-.818.818v2.455H3.82A.818.818 0 003 7.09v6c0 .395.283.734.672.805l.419.076v5.664c0 .452.366.819.818.819h14.182a.818.818 0 00.818-.819v-5.664l.42-.076A.818.818 0 0021 13.09v-6a.818.818 0 00-.818-.818zM9.545 4.636h4.91v1.637h-4.91V4.636zm8.728 14.182H5.727V14.27L9 14.865v.953c0 .452.366.818.818.818h4.364a.818.818 0 00.818-.818v-.953l3.273-.595v4.548zM10.636 15v-1.636h2.728V15h-2.728zm8.728-2.592L15 13.201v-.656a.818.818 0 00-.818-.818H9.818a.818.818 0 00-.818.818v.656l-4.364-.793V7.91h14.727v4.5z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { SuitCaseIcon }
