import { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import constants from '@elenfs/elen-constants'
import { useNavigate, useLocation } from 'react-router-dom'
import appConfig from '../../../appConfig'

import { logout } from '../../../auth'
import { DropdownMenu, AppVersion, CookieConsentModal } from '../../misc'
import { UserIcon } from '../../icons'
import { UserRolesLabels } from '../../../helpers/enumLabels/user'
import { pathMatchCurrentLocation } from '../../../helpers/path'

import './CurrentUserMenu.less'

const { UserTypes } = constants.user
const { LEGAL_DOCUMENTS } = appConfig

const DropdownItem = ({ children, className, ...props }) => (
  <DropdownMenu.Item
    className={classnames('curr-user-menu__dropdown__item', className)}
    {...props}
  >
    {children}
  </DropdownMenu.Item>
)

const HIDDEN_ON_MOBILE = 'curr-user-menu--hide-mobile'

const CurrentUserMenu = ({ match, showName, currentUser, logoutRedirect }) => {
  const notClientUser = currentUser.type !== UserTypes.CLIENT
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleLogout = () => {
    logout()
    navigate(logoutRedirect)
  }

  const onCustomerSupportClick = useCallback(() => {
    const customerSupportPagePath = '/secure/customer-support'
    const isCustomerSupportPagePath = !!pathMatchCurrentLocation({
      pathToCheck: customerSupportPagePath,
      pathname
    })

    if (!isCustomerSupportPagePath) {
      navigate(customerSupportPagePath)
    }
  }, [])

  const redirectToLegalDocs = () => (
    window.open(LEGAL_DOCUMENTS, '_blank')
  )

  return (
    <DropdownMenu
      className={classnames(
        notClientUser && HIDDEN_ON_MOBILE,
        'curr-user-menu'
      )}
      contentClassName='curr-user-menu__content'
      direction={DropdownMenu.Directions.RIGHT}
      trigger={(
        <DropdownMenu.Trigger
          className='curr-user-menu__trigger'
          icon={UserIcon}
        />
      )}
    >
      <div className='curr-user-menu__content-info'>
        {notClientUser && (
          <>
            <span>{currentUser.fullName}</span>
            <span>
              {UserRolesLabels[`${currentUser.role}`]}
            </span>
            <span>
              {currentUser.email}
            </span>
            <span>
              {currentUser.company.name}
            </span>
          </>
        )}
        <span>
          <AppVersion />
        </span>
      </div>

      <DropdownMenu.List className='curr-user-menu__list'>
        {notClientUser &&
          <DropdownItem onClick={onCustomerSupportClick} className='dropdown-menu__item__button'>
            Customer support
          </DropdownItem>}

        <DropdownItem onClick={handleLogout} className='dropdown-menu__item__button'>
          Log out
        </DropdownItem>
      </DropdownMenu.List>

      <CookieConsentModal.Trigger className='curr-user-menu__link'>
        Cookie settings
      </CookieConsentModal.Trigger>

      <a onClick={redirectToLegalDocs} className='curr-user-menu__link'>
        Legal documents
      </a>
    </DropdownMenu>
  )
}

CurrentUserMenu.propTypes = {
  currentUser: PropTypes.object.isRequired
}

export default CurrentUserMenu
