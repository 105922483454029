import T from 'prop-types'

import { Table } from '../../misc'
import { ImportSourceRow } from './ImportSourceRow'
import { TableResponsiveWrapper } from '../../misc/TableResponsiveWrapper'

const tableHeaders = Object.freeze({
  SOURCE: 'Source',
  FILE_SHARE: 'Type',
  LAST_UPDATED: 'Last updated',
  UPDATES: 'Status'
})

const ImportSources = ({ items }) => (
  <TableResponsiveWrapper>
    <Table>
      <Table.Header>
        <Table.Row>
          {Object.values(tableHeaders).map(header => (
            <Table.HeaderCell key={header}>
              {header}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map(({ type, ...rest }) => (
          <ImportSourceRow
            key={type}
            tableHeaders={tableHeaders}
            type={type}
            {...rest}
          />
        ))}
      </Table.Body>
    </Table>
  </TableResponsiveWrapper>
)

ImportSources.propTypes = {
  items: T.arrayOf(T.shape({
    type: T.string.isRequired
  })).isRequired
}

export { ImportSources }
