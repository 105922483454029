import PolicyDetails from './PensionPolicyDetails'
import PolicyDetailsForm from './PensionPolicyDetailsForm'
import PolicyForm from '../../PolicyForm'
import pensionPolicySchema from '../../../../schemas/policy/pensionPolicySchema'

const PensionPolicyForm = props => (
  <PolicyForm {...{
    PolicyDetails,
    PolicyDetailsForm,
    policySchema: pensionPolicySchema,
    ...props
  }}
  />
)
export default PensionPolicyForm
