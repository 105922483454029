import { useLocation } from 'react-router-dom'

export const useGetCurrentPaginationNumber = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const urlPage = searchParams.get('page')
  const urlPageNumber = urlPage ? urlPage - 1 : undefined

  return { urlPageNumber }
}
