import { Trash } from '../../icons'
import { Table, ConfirmationModal } from '../../misc'

import { formatDate } from './../../../helpers/date'

const DeleteWorkflowTemplateIcon = ({ onDeleteTemplate }) => (
  <ConfirmationModal.Trigger
    modalProps={{
      heading: 'Delete template',
      text: 'Are you sure you want to delete this template?',
      confirmationCallback: isTrue => isTrue && onDeleteTemplate(),
      awaitCallback: true
    }}
    as={Trash}
    className='workflow-templates-table__row__delete-icon'
  />
)

const WorkflowTemplatesTableRow = props => {
  const { workflowTemplate, onNameClick, onDeleteTemplate } = props

  return (
    <Table.Row>
      <Table.Cell data-label='Name'>
        <span
          onClick={() => onNameClick(workflowTemplate.id)}
          className='workflow-templates-table__row__name'
        >
          {workflowTemplate?.name}
        </span>
      </Table.Cell>
      <Table.Cell data-label='Created by'>
        {workflowTemplate?.createdByUser?.fullName}
      </Table.Cell>
      <Table.Cell data-label='Date modified'>
        {formatDate(workflowTemplate?.updatedAt)}
      </Table.Cell>
      <Table.Cell data-label='Delete' short>
        <DeleteWorkflowTemplateIcon
          onDeleteTemplate={() => onDeleteTemplate(workflowTemplate.id)}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export { WorkflowTemplatesTableRow }
