"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Protections = exports.PlanStatuses = exports.PensionTypesLabels = exports.PensionTypes = exports.PCLSEntitlements = exports.Guarantees = exports.DeathBenefits = void 0;
const DeathBenefits = exports.DeathBenefits = Object.freeze({
  RETURN_OF_CONTRIBUTIONS: 'RETURN_OF_CONTRIBUTIONS',
  RETURN_OF_CONTRIBUTIONS_WITH_INTEREST: 'RETURN_OF_CONTRIBUTIONS_WITH_INTEREST',
  RETURN_OF_FUND: 'RETURN_OF_FUND',
  RETURN_OF_FUND_PLUS_LIFE_COVER: 'RETURN_OF_FUND_PLUS_LIFE_COVER'
});
const Guarantees = exports.Guarantees = Object.freeze({
  GUARANTEED_ANNUITY_RATE: 'GUARANTEED_ANNUITY_RATE',
  GUARANTEED_MVA_FREE_DATE: 'GUARANTEED_MVA_FREE_DATE',
  GUARANTEED_BONUS_RATE: 'GUARANTEED_BONUS_RATE',
  GUARANTEED_FUTURE_FUND_VALUE: 'GUARANTEED_FUTURE_FUND_VALUE',
  GUARANTEED_GROWTH_RATE: 'GUARANTEED_GROWTH_RATE',
  GUARANTEED_INCOME_BASE: 'GUARANTEED_INCOME_BASE',
  OTHER_GUARANTEE: 'OTHER_GUARANTEE'
});
const PCLSEntitlements = exports.PCLSEntitlements = Object.freeze({
  PERCENT_OF_FUND_25: 'PERCENT_OF_FUND_25',
  PROTECTED: 'PROTECTED'
});
const PensionTypes = exports.PensionTypes = Object.freeze({
  AVC: 'AVC',
  DB_OCCUPATIONAL_SCHEME: 'DB_OCCUPATIONAL_SCHEME',
  DC_OCCUPATIONAL_SCHEME: 'DC_OCCUPATIONAL_SCHEME',
  ENHANCED_ANNUITY_IN_PAYMENT: 'ENHANCED_ANNUITY_IN_PAYMENT',
  EXECUTIVE_PENSION: 'EXECUTIVE_PENSION',
  FIXED_TERM_ANNUITY: 'FIXED_TERM_ANNUITY',
  FLEXI_ACCESS_DRAWDOWN: 'FLEXI_ACCESS_DRAWDOWN',
  FSAVC: 'FSAVC',
  LIFETIME_ANNUITY_IN_PAYMENT: 'LIFETIME_ANNUITY_IN_PAYMENT',
  OFFSHORE_PENSION: 'OFFSHORE_PENSION',
  OTHER: 'OTHER',
  PERSONAL_PENSION: 'PERSONAL_PENSION',
  RETIREMENT_ANNUITY_CONTRACT: 'RETIREMENT_ANNUITY_CONTRACT',
  SIPP: 'SIPP',
  SSAS: 'SSAS',
  SECTION_32_BUYOUT_PLAN: 'SECTION_32_BUYOUT_PLAN',
  STAKEHOLDER_PENSION: 'STAKEHOLDER_PENSION',
  UFPLS: 'UFPLS',
  UNKNOWN_CASH_CALC: 'UNKNOWN_CASH_CALC'
});
const PlanStatuses = exports.PlanStatuses = Object.freeze({
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  NTU: 'NTU',
  LIVE: 'LIVE',
  SURRENDERED: 'SURRENDERED',
  TRANSFERRED_OUT: 'TRANSFERRED_OUT'
});
const Protections = exports.Protections = Object.freeze({
  ENHANCED_PROTECTION: 'ENHANCED_PROTECTION',
  FIXED_PROTECTION_2012: 'FIXED_PROTECTION_2012',
  FIXED_PROTECTION_2014: 'FIXED_PROTECTION_2014',
  INDIVIDUAL_PROTECTION_2014: 'INDIVIDUAL_PROTECTION_2014',
  PRIMARY_PROTECTION: 'PRIMARY_PROTECTION'
});
const PensionTypesLabels = exports.PensionTypesLabels = Object.freeze({
  [PensionTypes.AVC]: 'AVC',
  [PensionTypes.DB_OCCUPATIONAL_SCHEME]: 'DB Occupational scheme',
  [PensionTypes.DC_OCCUPATIONAL_SCHEME]: 'DC Occupational scheme',
  [PensionTypes.ENHANCED_ANNUITY_IN_PAYMENT]: 'Enhanced Annuity in payment',
  [PensionTypes.EXECUTIVE_PENSION]: 'Executive Pension',
  [PensionTypes.FIXED_TERM_ANNUITY]: 'Fixed Term Annuity',
  [PensionTypes.FLEXI_ACCESS_DRAWDOWN]: 'Flexi Access Drawdown',
  [PensionTypes.FSAVC]: 'FSAVC',
  [PensionTypes.LIFETIME_ANNUITY_IN_PAYMENT]: 'Lifetime Annuity in payment',
  [PensionTypes.OFFSHORE_PENSION]: 'Offshore pension',
  [PensionTypes.OTHER]: 'Other',
  [PensionTypes.PERSONAL_PENSION]: 'Personal Pension',
  [PensionTypes.RETIREMENT_ANNUITY_CONTRACT]: 'Retirement Annuity Contract',
  [PensionTypes.SIPP]: 'SIPP',
  [PensionTypes.SSAS]: 'SSAS',
  [PensionTypes.SECTION_32_BUYOUT_PLAN]: 'Section 32 Buyout Plan',
  [PensionTypes.STAKEHOLDER_PENSION]: 'Stakeholder Pension',
  [PensionTypes.UFPLS]: 'UFPLS',
  [PensionTypes.UNKNOWN_CASH_CALC]: 'Unknown (CashCalc)'
});
var _default = exports.default = {
  DeathBenefits,
  Guarantees,
  PCLSEntitlements,
  PensionTypes,
  PlanStatuses,
  Protections,
  PensionTypesLabels
};