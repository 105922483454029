const PencilIcon = (props) => (
  <svg viewBox='0 0 24 24' width={24} height={24} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M21.1888969 8.45619408L11.0185201 18.6265708c-.2461112.2461113-.5521889.423735-.8879787.5153141l-5.57742399 1.5211156c-.53282435.1453157-1.0825655-.1688221-1.22788123-.7016464-.04698157-.1722658-.04698157-.3539691 0-.5262348l1.52111563-5.577424c.09157903-.3357898.26920282-.6418675.51531408-.8879788L15.5320426 2.79933983c.5857864-.58578644 1.5355339-.58578644 2.1213204 0l3.5355339 3.5355339c.5857864.58578644.5857864 1.53553391 0 2.12132035zm-4.7651108 1.94586402l-2.8284272-2.82842716-6.81161948 6.81161956-1.05989318 3.8883203 3.88832031-1.0598932 6.81161955-6.8116195zm3.0052038-3.00520385l-2.8284272-2.82842713-1.5909902 1.59099026 2.8284271 2.82842713 1.5909903-1.59099026z'
      />
    </g>
  </svg>
)

export default PencilIcon
