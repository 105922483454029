import PropTypes from 'prop-types'
import classnames from 'classnames'

const CardGroup = ({ children, className }) => (
  <div className={classnames('card-new-group', className)}>
    {children}
  </div>
)

CardGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default CardGroup
