import { useMemo } from 'react'
import T from 'prop-types'

import { ChargeTotals } from './ChargeTotals'

import { calcTotalCharges } from '../../../helpers/charge'

const ChargeTotalsContainer = ({ fees, commissions }) => {
  const dataPrepared = useMemo(
    () => calcTotalCharges(fees, commissions),
    [fees, commissions]
  )

  return (
    <ChargeTotals {...dataPrepared} />
  )
}

ChargeTotalsContainer.propTypes = {
  fees: T.arrayOf(T.object),
  commissions: T.arrayOf(T.object)
}

ChargeTotalsContainer.defaultProps = {
  fees: [],
  commissions: []
}

export { ChargeTotalsContainer }
