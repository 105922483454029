import { useCallback } from 'react'
import { connect } from 'formik'

import FormFieldsCard from '../../form/FormFieldsCard'
import { PageDivider } from '../../misc/PageDivider'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import Card from '../../misc/Card'
import { useNavigate } from 'react-router-dom'
import clientObjectivesAndActionsSchema from '../../../schemas/client/clientObjectivesAndActionsSchema'
import { validateAndNavigate } from '../../../helpers/client'

import { TextAreaField } from '../../form/fields'

import './ClientObjectivesAndActionsForm.less'

const ClientObjectivesAndActionsForm = ({
  setValidationSchema,
  nextLink,
  prevLink,
  formik
}) => {
  const navigate = useNavigate()
  const { validateForm } = formik

  const handleNavButtonClick = useCallback(
    isNext => {
      validateAndNavigate({
        setSchema: setValidationSchema,
        schema: clientObjectivesAndActionsSchema,
        validate: validateForm,
        nextLocation: isNext ? nextLink : prevLink,
        navigate
      })
    }, [
      navigate,
      nextLink,
      prevLink,
      validateForm,
      clientObjectivesAndActionsSchema,
      setValidationSchema
    ]
  )

  return (
    <Card className='client-objectives-and-actions-form'>
      <FormFieldsCard title='Objectives'>
        <TextAreaField
          name='objectives'
          inputProps={{ rows: 3 }}
        />
      </FormFieldsCard>

      <PageDivider />

      <FormFieldsCard title='Agreed actions'>
        <TextAreaField
          name='actions'
          inputProps={{ rows: 3 }}
        />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}
export default connect(ClientObjectivesAndActionsForm)
