import T from 'prop-types'
import classnames from 'classnames'

import { DropdownIcon, CloseIconBig } from './../../icons'

const TableHeaderCell = ({
  children,
  className,
  isSortable,
  header,
  onSort,
  onResetSort,
  ...rest
}) => (
  <th
    className={classnames('table-new__header-cell', className, {
      'table-new__header-cell--active': header?.isActive,
      'table-new__header-cell--descending': !header?.isAscending,
      'table-new__header-cell--hidden': header?.isHidden
    })}
    {...rest}
  >
    {isSortable
      ? (
        <span onClick={() => onSort(header.label)}>
          {header.label} <DropdownIcon /> <CloseIconBig onClick={onResetSort} />
        </span>
        )
      : (
          children
        )}
  </th>
)

TableHeaderCell.propTypes = {
  children: T.node,
  isSortable: T.bool,
  header: T.shape({
    label: T.string,
    isActive: T.bool,
    propPath: T.arrayOf(T.string.isRequired),
    isAscending: T.bool,
    isHidden: T.bool
  }),
  onSort: T.func,
  onResetSort: T.func,
  className: T.string
}

export default TableHeaderCell
