const Board = props => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <defs>
      <clipPath id='board-a'>
        <path d='M1440 0v1962H0V0h1440Z' />
      </clipPath>
      <clipPath id='board-b'>
        <path d='M5.212 9C6.18 9 7 9.82 7 10.788v3.424C7 15.18 6.18 16 5.212 16H1.788C.82 16 0 15.18 0 14.212v-3.424C0 9.82.82 9 1.788 9h3.424Zm9 0C15.18 9 16 9.82 16 10.788v3.424C16 15.18 15.18 16 14.212 16h-3.424C9.82 16 9 15.18 9 14.212v-3.424C9 9.82 9.82 9 10.788 9Zm-9 1.522H1.788c-.128 0-.266.138-.266.266v3.424c0 .128.138.266.266.266h3.424c.128 0 .266-.138.266-.266v-3.424c0-.128-.138-.266-.266-.266Zm9 0h-3.424c-.128 0-.266.138-.266.266v3.424c0 .128.138.266.266.266h3.424c.128 0 .266-.138.266-.266v-3.424c0-.128-.138-.266-.266-.266ZM5.212 0C6.18 0 7 .82 7 1.788v3.424C7 6.18 6.18 7 5.212 7H1.788C.82 7 0 6.18 0 5.212V1.788C0 .82.82 0 1.788 0Zm9 0C15.18 0 16 .82 16 1.788v3.424C16 6.18 15.18 7 14.212 7h-3.424C9.82 7 9 6.18 9 5.212V1.788C9 .82 9.82 0 10.788 0Zm-9 1.522H1.788c-.128 0-.266.138-.266.266v3.424c0 .128.138.266.266.266h3.424c.128 0 .266-.138.266-.266V1.788c0-.128-.138-.266-.266-.266Zm9 0h-3.424c-.128 0-.266.138-.266.266v3.424c0 .128.138.266.266.266h3.424c.128 0 .266-.138.266-.266V1.788c0-.128-.138-.266-.266-.266Z' />
      </clipPath>
    </defs>
    <g clipPath='url(#board-a)' transform='translate(-377 -215)'>
      <g clipPath='url(#board-b)' transform='translate(377 215)'>
        <path fill='currentColor' d='M0 0h16v16H0V0z' />
      </g>
    </g>
  </svg>
)

export { Board }
