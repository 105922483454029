import { Fragment } from 'react'
import { isEmpty, isNil } from 'ramda'
import pluralize from 'pluralize'

import { isPlainObject } from '../../../helpers/misc'
import { Table, TableResponsiveWrapper, Heading, Text } from './../../misc'
import {
  factFindFieldLabels,
  factFindFieldFormat,
  factFindCategoryLabels
} from './helpers'

const generateTableStructure = (RowComponent, title) => {
  const tableHeadersCaptions = [
    { caption: 'Data' },
    { caption: 'Previous value' },
    { caption: 'New value' }
  ]

  return (
    <>
      <Heading className='audit-log-entry-modal__table__heading' as='h3'>
        {title}
      </Heading>
      <Table className='audit-log-entry-modal__table' fixedLayout>
        <Table.Header>
          <Table.Row>
            {Object.values(tableHeadersCaptions).map(({ caption }) => (
              <Table.HeaderCell key={caption}>{caption}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <RowComponent />
        </Table.Body>
      </Table>
    </>
  )
}

const generateRow = (prevData, nextData, item) => {
  if (!(isNil(nextData) && isNil(prevData))) {
    return (
      <Table.Row key={item}>
        <Table.Cell key={1} data-label={item}>
          {factFindFieldLabels[item]}
        </Table.Cell>
        <Table.Cell key={2} data-label={item}>
          {
            isNil(prevData)
              ? (
                <span className='audit-log-entry-modal__table none-value'>
                  none
                </span>
                )
              : factFindFieldFormat[item]
                ? factFindFieldFormat[item](prevData)
                : prevData
          }
        </Table.Cell>
        <Table.Cell key={3} data-label={item}>
          {
            isNil(nextData)
              ? (
                <span className='audit-log-entry-modal__table none-value'>
                  none
                </span>
                )
              : factFindFieldFormat[item]
                ? factFindFieldFormat[item](nextData)
                : nextData
          }
        </Table.Cell>
      </Table.Row>
    )
  }
}

const generateTable = (tableData, heading) => {
  if (tableData && !isEmpty(tableData) && isPlainObject(tableData)) {
    const TableRow = () => {
      const rows = []

      for (const item in tableData) {
        rows.push(
          generateRow(tableData[item].prev, tableData[item].next, item)
        )
      }

      return rows.map(item => item)
    }

    return (
      generateTableStructure(TableRow, heading)
    )
  } else if (tableData && !isEmpty(tableData) && !isPlainObject(tableData)) {
    const TableRow = () => {
      return tableData.map((item, index) => {
        const rows = []

        if (item?.prev) {
          const { __typename: typenamePrev, docId: idPrev, ...copyPrev } = item.prev
          const copyNext = { ...item.next }

          for (const inner in copyPrev) {
            rows.push(
              generateRow(copyPrev[inner], copyNext[inner], inner)
            )
          }
        } else if (!item?.prev && item?.next) {
          const { __typename: typenameNext, docId: idNext, ...copyNext } = item.next
          const copyPrev = { ...item.prev }

          for (const inner in copyNext) {
            rows.push(
              generateRow(copyPrev[inner], copyNext[inner], inner)
            )
          }
        }

        return (
          <Fragment key={index}>
            <Heading
              as='h3'
              className='audit-log-entry-modal__table__heading--inner'
            >
              {`${pluralize(heading, 1)} ${index + 1}`}
            </Heading>
            {rows.map(item => item)}
          </Fragment>
        )
      })
    }

    return (
      generateTableStructure(TableRow, heading)
    )
  }
}

const AuditLogTable = ({ data, message }) => {
  const tablesList = []
  for (const category in data) {
    tablesList.push(generateTable(data[category], factFindCategoryLabels[category]))
  }
  const clearedList = tablesList.filter(Boolean)
  const body = isEmpty(clearedList)
    ? <Text as='p'>{message}</Text>
    : clearedList

  return (
    <TableResponsiveWrapper>
      {body}
    </TableResponsiveWrapper>
  )
}

export { AuditLogTable }
