import { Card } from '../../misc'

import { formatDate } from './../../../helpers/date'
import { formatNote } from './../../../helpers/client'

import './ClientDependantsCard.less'

const ClientDependantsCard = ({ canEdit, dependants, id }) => {
  const baseUrl = 'edit/dependants'

  return (
    <Card className='client-dependants-card' id={id}>
      <Card.Header
        allowToNavigate={canEdit}
        baseUrl={baseUrl}
      >
        Dependants
      </Card.Header>

      <div className='client-dependants-card__container'>
        {dependants.map(
          (
            {
              dateOfBirth,
              dependantFrom,
              dependantUntil,
              firstName,
              lastName,
              note,
              relationship
            },
            index
          ) => (
            <div key={index} className='client-dependants-card__container__box'>
              <div className='client-dependants-card__container__box__name-and-note'>
                <span className='client-dependants-card__container__box__name-and-note__name'>
                  {firstName} {lastName}
                </span>
                <span>{formatNote(note, `dependants-${index}`)}</span>
              </div>

              <div className='client-dependants-card__container__box__dob-and-relation'>
                <div>{relationship || ''}</div>
                <div>{dateOfBirth && `${formatDate(dateOfBirth)} born`}</div>
              </div>

              {(dependantFrom || dependantUntil) && (
                <div className='client-dependants-card__container__box__from-to'>
                  <div>Dependant time:</div>
                  <div>
                    {dependantFrom ? `${formatDate(dependantFrom)} - ` : 'until '}
                    {formatDate(dependantUntil) || 'now'}
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </div>
    </Card>
  )
}

export { ClientDependantsCard }
