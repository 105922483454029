import { withFormik } from 'formik'
import T from 'prop-types'
import { prop, ifElse, invoker, always } from 'ramda'

import { Form, FormErrorMessages } from '../../form'
import { Button } from '..'

const DynamicForm = props => {
  const {
    inputsArr,
    submitButtonLabel,
    handleSubmit,
    loading,
    error,
    errors
  } = props

  const isError = Object.keys(errors).length || error
  const errorObj = error || errors

  return (
    <Form className='dynamic-form-modal__form' onSubmit={handleSubmit}>
      {inputsArr.map((group, i) => (
        <Form.Group key={i}>
          {group.map(({ InputComponent, inputProps }) => (
            <InputComponent key={inputProps.name} {...inputProps} />
          ))}
        </Form.Group>
      ))}

      {isError && <FormErrorMessages validationErrors={{ errorObj }} />}

      <Button
        type='submit'
        color={Button.Colors.BLUE}
        className='dynamic-form-modal__form__submit-button'
        disabled={loading}
      >
        {submitButtonLabel}
      </Button>
    </Form>
  )
}

DynamicForm.defaultProps = {
  submitButtonLabel: 'Submit',
  inputsArr: [[]],
  onSubmit: () => {},
  loading: false,
  error: ''
}

DynamicForm.propTypes = {
  inputsArr: T.arrayOf(
    T.arrayOf(
      T.shape({
        InputComponent: T.elementType.isRequired,
        inputProps: T.object.isRequired
      }).isRequired
    )
  ).isRequired,
  onSubmit: T.func.isRequired,
  validationSchema: T.object.isRequired,
  submitButtonLabel: T.string,
  loading: T.bool,
  error: T.oneOfType([T.string, T.object]),
  buildInitialValues: T.func
}

const EnhancedDynamicForm = withFormik({
  mapPropsToValues: ifElse(
    prop('buildInitialValues'),
    invoker(0, 'buildInitialValues'),
    always({})
  ),
  validationSchema: prop('validationSchema'),
  handleSubmit: async (values, formikBag) => {
    const { onSubmit, close } = prop('props', formikBag)
    try {
      await onSubmit(values)
      close()
    } catch (error) {}
  }
})(DynamicForm)

export { EnhancedDynamicForm as DynamicForm }
