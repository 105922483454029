import classnames from 'classnames'
import T from 'prop-types'

import { PageDivider, Heading, Card, Summary } from '../../../misc'

import './OverviewCard.less'

const OverviewCard = props => {
  const { heading, summaries, hint, className, headingActionComponent } = props

  return (
    <div className={classnames('overview-card', className)}>
      <div className='overview-card__heading-container'>
        <Heading as='h3'>{heading}</Heading>
        {headingActionComponent && headingActionComponent}
      </div>

      <Card>
        {summaries?.map((data, index) => (
          <div key={index}>
            <Summary data={[data]} />
            <PageDivider />
          </div>
        ))}
      </Card>
      {hint && <p className='overview-card__hint'> {hint} </p>}
    </div>
  )
}

OverviewCard.propTypes = {
  heading: T.string.isRequired,
  summaries: T.arrayOf(
    T.shape({
      label: T.string.isRequired,
      value: T.oneOfType([T.string, T.number, T.node]).isRequired,
      dangerText: T.bool
    }).isRequired
  ).isRequired,
  hint: T.string,
  headingActionComponent: T.node
}

export { OverviewCard }
