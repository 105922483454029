import {
  ClientIncomeFrequencyLabels,
  ClientIncomeTypeLabels,
  ClientExpenseFrequencyLabels,
  ClientExpensePriorityLabels
} from '@elenfs/elen-constants'

import { formatMoney } from './../../../helpers/policy'
import { IncomeToolTip } from '../../misc'

const incomeTableHeaders = Object.freeze({
  SOURCE: 'Source',
  NET_AMOUNT: 'Net',
  GROSS_AMOUNT: 'Gross',
  FREQUENCY: 'Frequency',
  TYPE: 'Type'
})

const incomeTableHeadersCaptions = [
  { caption: incomeTableHeaders.SOURCE },
  { caption: incomeTableHeaders.NET_AMOUNT },
  { caption: incomeTableHeaders.GROSS_AMOUNT },
  { caption: incomeTableHeaders.FREQUENCY },
  { caption: incomeTableHeaders.TYPE }
]

const incomeTableRowCells = [
  { propPath: ['source'], dataLabel: incomeTableHeaders.SOURCE },
  {
    propPath: ['netAmount'],
    dataLabel: incomeTableHeaders.NET_AMOUNT,
    formatFunc: formatMoney
  },
  {
    propPath: ['grossAmount'],
    dataLabel: incomeTableHeaders.GROSS_AMOUNT,
    formatFunc: formatMoney
  },
  {
    propPath: ['frequency'],
    dataLabel: incomeTableHeaders.FREQUENCY,
    formatFunc: v => ClientIncomeFrequencyLabels[v]
  },
  {
    propPath: ['type'],
    dataLabel: incomeTableHeaders.TYPE,
    formatFunc: v => ClientIncomeTypeLabels[v]
  }
]

const incomeTableRowTotalCells = [
  {
    dataLabel: 'Monthly net total',
    getTotalFromProp: true
  },
  { emptyCell: true },
  { emptyCell: true },
  { emptyCell: true }
]

export const incomeCardTableProps = {
  tableHeadersCaptions: incomeTableHeadersCaptions,
  tableRowCells: incomeTableRowCells,
  tableRowTotalCells: incomeTableRowTotalCells,
  totalLabel: (
    <>
      Monthly net total <IncomeToolTip />
    </>
  )
}

// EXPENDITURE TABLE
const ExpenditureTableHeaders = Object.freeze({
  EXPENSE: 'Expense',
  AMOUNT: 'Amount',
  FREQUENCY: 'Frequency',
  PRIORITY: 'Priority',

  MONTHLY_EXPENSE_NOW: 'Monthly expense now',
  MONTHLY_EXPENSE_RETIREMENT: 'Monthly expense in retirement'
})

const expenditureTableHeadersCaptions = [
  { caption: ExpenditureTableHeaders.EXPENSE },
  { caption: ExpenditureTableHeaders.AMOUNT },
  { caption: ExpenditureTableHeaders.FREQUENCY },
  { caption: ExpenditureTableHeaders.PRIORITY }
]

const expenditureTableRowCells = [
  { propPath: ['expense'], dataLabel: ExpenditureTableHeaders.EXPENSE },
  {
    propPath: ['amount'],
    dataLabel: ExpenditureTableHeaders.AMOUNT,
    formatFunc: formatMoney
  },
  {
    propPath: ['frequency'],
    dataLabel: ExpenditureTableHeaders.FREQUENCY,
    formatFunc: v => ClientExpenseFrequencyLabels[v]
  },
  {
    propPath: ['priority'],
    dataLabel: ExpenditureTableHeaders.PRIORITY,
    formatFunc: v => ClientExpensePriorityLabels[v]
  }
]

const expenditureTableRowTotalCells = [
  {
    getTotalFromProp: true,
    dataLabel: 'Monthly total'
  },
  { emptyCell: true },
  { emptyCell: true }
]

export const expenditureCardTableProps = {
  tableHeadersCaptions: expenditureTableHeadersCaptions,
  tableRowCells: expenditureTableRowCells,
  tableRowTotalCells: expenditureTableRowTotalCells,
  totalLabel: 'Monthly total'
}
