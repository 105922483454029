import { PageContent } from '../../misc'
import { PolicyValuationEmpty } from './PolicyValuationEmpty'
import { PolicyValuationTable } from './PolicyValuationTable'
import { PolicyValuationChart } from './PolicyValuationChart'

const PolicyValuation = ({
  policyValuations,
  policyId,
  readOnly,
  ...restProps
}) => {
  const isEmpty = !(policyValuations && policyValuations.length)

  return (
    <div className='policy-valuation'>
      <PageContent>
        {
          !isEmpty &&
            <>
              <PolicyValuationChart {...{ policyId, readOnly, ...restProps }} />
              <PolicyValuationTable {...{ policyId, policyValuations, readOnly }} />
            </>
        }
        <PolicyValuationEmpty {...{ policyId, hidden: !isEmpty, readOnly }} />
      </PageContent>
    </div>
  )
}

PolicyValuation.defaultProps = {
  policyValuations: []
}

export { PolicyValuation }
