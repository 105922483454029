import { DashboardTabsCard } from '../DashboardTabsCard'

import './ReviewsAndBirthdaysDashboardCard.less'

const ReviewsAndBirthdaysDashboardCard = ({ tabs }) => {
  return (
    <div className='reviews-and-birthdays-dashboard-card'>
      <DashboardTabsCard tabs={tabs} />
    </div>
  )
}

export { ReviewsAndBirthdaysDashboardCard }
