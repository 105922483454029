import { useCallback, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { SelectField } from '../../../form/fields'
import { MaturingPoliciesPage } from './MaturingPoliciesPage'

import { policyTypeOptions as policyCategoryOptions } from '../../../../helpers/selectOptions'
import {
  buildRowSchema,
  filterDateRangeOptions,
  filterDateRanges,
  tableHeaderLabels,
  onFilterValuesChange
} from './../helpers'
import { withFinancialAdvisers } from '../../../hoc/container'
import { getFromToDateByDateRange } from './../../../../helpers/filterDate'

import maturingPoliciesReportGql from '../../../../graphql/queries/reports/maturingPoliciesReport.graphql'

const MaturingPoliciesPageContainer = props => {
  const { financialAdviserOptions } = props

  const [policySubcategoryOptions, setPolicySubcategoryOptions] = useState([])

  const [currentFilter, setCurrentFilter] = useState()

  const [getMaturingPolicies, { data, loading, error, called }] = useLazyQuery(
    maturingPoliciesReportGql,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )
  const initialLoading = loading && !data

  const onFilter = useCallback(
    filter => {
      getMaturingPolicies({ variables: { filter } })
      setCurrentFilter(filter)
    },
    [getMaturingPolicies, setCurrentFilter]
  )

  const filterProps = {
    onSubmit: onFilter,
    onValuesChange: values =>
      onFilterValuesChange(values, setPolicySubcategoryOptions),
    getFromToDateByDateRange,
    filterDateRangeOptions,
    filterDateRanges,
    inputsArr: [
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'clientId',
          inputProps: {
            async: true,
            isClearable: true,
            placeholder: 'Client',
            asyncProps: {
              labelProp: 'fullName',
              valueProp: 'id'
            }
          }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'adviserId',
          options: financialAdviserOptions,
          inputProps: { placeholder: 'Adviser', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'category',
          options: policyCategoryOptions,
          inputProps: { placeholder: 'Product', isClearable: true }
        }
      },
      {
        InputComponent: SelectField,
        inputProps: {
          name: 'subCategory',
          options: policySubcategoryOptions,
          inputProps: { placeholder: 'Policy type', isClearable: true }
        }
      }
    ],
    initialValues: {
      dateRange: filterDateRanges.THIS_MONTH_TO_DATE
    }
  }

  const downloadBtnProps = {
    currentFilter,
    reportName: 'policies/maturing'
  }

  const records = data?.maturingPoliciesReport?.rows || []

  const tableProps = {
    rows: records,
    rowSchema: buildRowSchema(),
    tableHeaderLabels,
    className: 'maturing-report-table'
  }

  return (
    <MaturingPoliciesPage
      {...{
        filterProps,
        tableProps,
        records,
        loading: initialLoading,
        error,
        called,
        downloadBtnProps
      }}
    />
  )
}

const EnhancedComponent = withFinancialAdvisers()(
  MaturingPoliciesPageContainer
)

export { EnhancedComponent as MaturingPoliciesPageContainer }
