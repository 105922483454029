import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableActions = ({ children, className, ...rest }) => (
  <div
    className={classnames('table-new__actions', className)}
    {...rest}
  >
    {children}
  </div>
)

TableActions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default TableActions
