import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import constants from '@elenfs/elen-constants'

import { formatMoney, getPolicyTypeByCategory } from '../../../helpers/policy'
import { Table } from '../../misc'

import { PolicyTableHeaders } from '../../../helpers/enumLabels/policy'
import { formatDate } from '../../../helpers/date'

const {
  PolicyHoldersLabels,
  PolicyProvidersLabels,
  PolicyCategoriesLabels
} = constants.policy.common

const PoliciesTableRow = ({ policy, url }) => {
  const navigate = useNavigate()

  const policyType = useMemo(
    () => PolicyHoldersLabels[policy?.currentHoldersIds?.length < 2 ? 'CLIENT' : 'JOINT'],
    [PolicyHoldersLabels, policy]
  )

  return (
    <Table.Row onClick={() => navigate(url)}>
      <Table.Cell to={url} data-label={PolicyTableHeaders.CATEGORY}>
        {PolicyCategoriesLabels[policy.category]}
      </Table.Cell>
      <Table.Cell to={url} data-label={PolicyTableHeaders.POLICY_TYPE}>
        {getPolicyTypeByCategory(policy)}
      </Table.Cell>
      <Table.Cell to={url} data-label={PolicyTableHeaders.NUMBER}>
        {policy.number ? policy.number.toUpperCase() : ''}
      </Table.Cell>
      <Table.Cell to={url} data-label={PolicyTableHeaders.POLICY_HOLDER}>
        {policyType}
      </Table.Cell>
      <Table.Cell to={url} data-label={PolicyTableHeaders.PROVIDER}>
        {PolicyProvidersLabels[policy.provider]}
      </Table.Cell>
      <Table.Cell to={url} data-label={PolicyTableHeaders.LATEST_VALUATION}>
        {policy.currentValue && formatMoney(policy.currentValue)}
        {policy.amountOutstanding && formatMoney(policy.amountOutstanding)}
      </Table.Cell>
      <Table.Cell to={url} data-label={PolicyTableHeaders.VALUATION_DATE}>
        {formatDate(policy.valuationDate, 'DD MMM YYYY')}
      </Table.Cell>
    </Table.Row>
  )
}

PoliciesTableRow.propTypes = {
  policy: PropTypes.shape({
    number: PropTypes.string,
    currentHoldersIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    category: PropTypes.string.isRequired,
    provider: PropTypes.string,
    valuationDate: PropTypes.string,
    description: PropTypes.string
  }).isRequired
}

export default PoliciesTableRow
