import T from 'prop-types'
import { BackButton } from '../../misc'

import './ImportLogLayout.less'

export const ImportLogHeader = ({ pageTitle, link }) => {
  return (
    <BackButton to={link}>
      <div className='back-button__page_title'>
        {pageTitle} logs
      </div>
    </BackButton>
  )
}

ImportLogHeader.propTypes = {
  pageTitle: T.string.isRequired,
  link: T.string.isRequired
}
