import { useQuery } from '@apollo/client'

import { TaskCommentList } from './TaskCommentList'

import taskCommentsGql from '../../../graphql/queries/tasks/taskComments.graphql'

const TaskCommentListContainer = ({ taskId, ...restProps }) => {
  const { data } = useQuery(taskCommentsGql, {
    fetchPolicy: 'cache-and-network',
    variables: {
      taskId
    }
  })

  const taskComments = data?.taskComments || []
  const isEmpty = !taskComments?.length

  return (
    <TaskCommentList {...{
      taskComments,
      isEmpty,
      taskId,
      ...restProps
    }}
    />
  )
}

export { TaskCommentListContainer }
