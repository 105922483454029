import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate, matchPath, useParams } from 'react-router-dom'

import { Spinner, ErrorMessage } from '../../misc'
import { PraemiumImportPage } from './PraemiumImportPage'
import praemiumConnectionGql
  from '../../../graphql/queries/dataImport/praemiumConnection.graphql'

const PraemiumImportPageContainer = props => {
  const navigate = useNavigate()
  const { pathname } = useParams()

  const { loading, error, data } = useQuery(praemiumConnectionGql, {
    fetchPolicy: 'cache-and-network'
  })

  const onCustomerSupportClick = useCallback(() => {
    const customerSupportPagePath = '/secure/customer-support'
    const isCustomerSupportPagePath = matchPath(
      pathname,
      { path: customerSupportPagePath }
    )

    if (!isCustomerSupportPagePath) {
      navigate(customerSupportPagePath)
    }
  }, [navigate, pathname])

  const initialLoading = loading && !data

  if (initialLoading) return <Spinner />
  if (error) return <ErrorMessage text='Failed to get Morningstar Wealth Platform data' />

  const praemiumData = { ...data?.dataImportPraemiumConnection }

  return (
    <PraemiumImportPage {...{
      data: praemiumData,
      onCustomerSupportClick,
      ...props
    }}
    />
  )
}

export { PraemiumImportPageContainer }
