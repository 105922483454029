import * as yup from 'yup'

import { ColourTypes } from '../../constants/serviceLevels'
import stringSchema from '../helpers/stringSchema'
import enumSchema from '../helpers/enumSchema'

const serviceLevelsSchema = yup
  .object()
  .shape({
    name: stringSchema()
      .label('Name')
      .required(),

    description: stringSchema()
      .label('Description'),

    colour: enumSchema(Object.values(ColourTypes))
      .label('colour')
      .required()
  })

export { serviceLevelsSchema }
