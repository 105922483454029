import { values } from 'ramda'
import * as yup from 'yup'
import constants from '@elenfs/elen-constants'

import booleanSchema from '../helpers/booleanSchema'
import dateSchema from '../helpers/dateSchema'
import enumSchema from '../helpers/enumSchema'
import percentSchema from '../helpers/percentSchema'
import moneySchema from '../helpers/moneySchema'
import commonFields from './commonFields'

const {
  DebtTypes,
  PlanStatuses,
  RepaymentTypes,
  RateTypes
} = constants.policy.debtPolicy

export default yup.object().shape({
  ...commonFields(),

  debtType: enumSchema(values(DebtTypes))
    .label('Policy type')
    .required(),

  planStatus: enumSchema(values(PlanStatuses))
    .label('Plan status'),

  startDate: dateSchema()
    .label('Start date'),

  endDate: dateSchema()
    .label('End date'),

  repaymentType: enumSchema(values(RepaymentTypes))
    .label('Repayment type'),

  rateType: enumSchema(values(RateTypes))
    .label('Rate type'),

  rate: percentSchema()
    .label('Rate'),

  endOfRatePeriod: dateSchema()
    .label('End of rate period'),

  exitPenaltiesApply: booleanSchema()
    .label('Exit penalties apply'),

  valuationDate: dateSchema()
    .label('Valuation Date'),

  amountOutstanding: moneySchema({
    amountLabel: 'Amount outstanding',
    currencyLabel: 'Amount outstanding currency'
  })
}).noUnknown(true)
