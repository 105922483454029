import { useQuery } from '@apollo/client'

import policyValuationsGql from '../../../graphql/queries/policy/policyValuations.graphql'

import { PolicyValuation } from './PolicyValuation'
import { Spinner, ErrorMessage } from '../../misc'
import { sortListByProp } from '../../../helpers/sort'
import { LIMIT_NUMBERS_OF_VALUATIONS } from './constants'

const PolicyValuationContainer = (props) => {
  const { policy: { id: policyId } } = props

  const { loading, error, data } = useQuery(policyValuationsGql, {
    variables: {
      policyId,
      limit: LIMIT_NUMBERS_OF_VALUATIONS
    },
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) {
    return <Spinner />
  }

  if (error) {
    return <ErrorMessage text='Failed to load policy valuations' />
  }

  const policyValuations = data?.policyValuations || []

  return (
    <PolicyValuation
      policyValuations={sortListByProp(policyValuations, 'date', 'DESC')}
      policyId={policyId}
      {...props}
    />
  )
}

export { PolicyValuationContainer }
