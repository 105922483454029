import './PraemiumGuide.less'

const PraemiumGuide = () => (
  <div className='praemium-guide'>
    <p>
      In order to use this integration it’s required that you authorise Elen to process your Morningstar Wealth Platform data. All matters related to your Morningstar Wealth Platform connection (connecting, disconnecting, reporting issues) are done through our Support team.
    </p>
  </div>
)

const PraemiumGuideSupport = () => (
  <div className='praemium-guide'>
    <p>
      In order to contact our Support please send an email to <a href='mailto: support@elen.co'>support@elen.co</a> or use the button below for the Support form.
    </p>
  </div>
)

export { PraemiumGuide, PraemiumGuideSupport }
