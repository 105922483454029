import classnames from 'classnames'

import { Header, HeaderLogo } from '../../nav'

import './WrapperScreen.less'

const WrapperScreen = ({ children, className, ...props }) => (
  <div className={classnames('wrapper-screen', {
    [className]: className
  })}
  >
    <div className='wrapper-screen__nav'>
      <Header>
        <Header.Left>
          <HeaderLogo />
        </Header.Left>
      </Header>
    </div>

    <div className={classnames('wrapper-screen__content', {
      [`${className}__content`]: className
    })}
    >
      {children}
    </div>
  </div>
)

export { WrapperScreen }
