import { withFormik } from 'formik'
import classnames from 'classnames'
import { evolve, isEmpty, unless } from 'ramda'
import { WorkflowActionTypes } from '@elenfs/elen-constants'

import { Form, FormErrorMessages } from '../../form'
import { SelectField, TextAreaField, InputField } from '../../form/fields'
import {
  ControlWithActivity,
  ControlWithUserIcon
} from '../../misc/DropdownSelection/DropdownCustom'
import { CalendarIcon } from '../../icons'
import { Button } from '../../misc'

import { taskTypeOptions } from '../../tasks/const'
import { daysToMilliSeconds } from './../../../helpers/date'
import { buildInitialValues, taskAssigneeRoleOptions } from './helpers'

import './TaskActionForm.less'

const TaskActionForm = ({ values, handleSubmit, loading, error }) => (
  <Form className='task-action-form' onSubmit={handleSubmit}>
    <div className='task-action-form__header'>
      <SelectField
        name='taskAssigneeTeamRole'
        options={taskAssigneeRoleOptions}
        inputProps={{ isClearable: false, isSearchable: false }}
        selectProps={{ Control: ControlWithUserIcon }}
      />

      <div className='task-action-form__header__due-date-row'>
        <div
          className={classnames(
            'task-action-form__header__due-date-row__icon-wrapper',
            {
              'task-action-form__header__due-date-row__icon-wrapper--active':
                values?.taskDueDateAfterMs
            }
          )}
        >
          <CalendarIcon />
        </div>
        <span>Due</span>
        <InputField
          name='taskDueDateAfterMs'
          // numbers from 0 to 99 or empty string
          inputProps={{ regex: /^(\s*|[0-9][0-9]?)$/ }}
        />
        <span> days after workflow starts </span>
      </div>

      <SelectField
        name='taskType'
        options={taskTypeOptions}
        inputProps={{ isClearable: false, isSearchable: false }}
        selectProps={{ Control: ControlWithActivity }}
      />
    </div>

    <div className='task-action-form__body'>
      <InputField
        name='taskSummary'
        label='Summary'
        inputProps={{ placeholder: 'Start typing...' }}
      />

      <TextAreaField
        name='taskDescription'
        label='Description'
        inputProps={{
          placeholder: 'Start typing...',
          rows: 4
        }}
      />

      {error && (
        <FormErrorMessages validationErrors={{ '': 'Something went wrong.' }} />
      )}

      <Button
        type='submit'
        color={Button.Colors.BLUE}
        disabled={!values?.taskSummary || loading}
        className='task-action-form__body__submit-button'
      >
        Save
      </Button>
    </div>
  </Form>
)

const EnhancedTaskActionForm = withFormik({
  mapPropsToValues: buildInitialValues,
  handleSubmit: async (values, { props: { onSubmit, closePanel } }) => {
    try {
      await onSubmit(
        evolve(
          {
            taskDueDateAfterMs: unless(isEmpty, daysToMilliSeconds)
          },
          { ...values, type: WorkflowActionTypes.TASK }
        )
      )
      closePanel()
    } catch (error) {}
  }
})(TaskActionForm)

export { EnhancedTaskActionForm as TaskActionForm }
