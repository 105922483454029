import { useCallback, useEffect } from 'react'
import { withFormik } from 'formik'
import { isEmpty } from 'ramda'

import { p1ImportSchema } from '../../../schemas/dataImport/p1ImportSchema'
import { Form } from '../../form/Form'
import { InputField } from '../../form/fields'
import { Button } from '../../misc'
import { buildInitialValues } from '../../../helpers/form'
import { P1DisconnectButton } from '../P1DisconnectButton'
import { P1SyncButton } from '../P1SyncButton'

const P1ImportForm = ({
  password,
  isLoggedIn,
  loading,
  submitForm,
  validateForm,
  errors: validationErrors,
  setFieldValue
}) => {
  const validateAndSubmit = useCallback(
    async (fn) => {
      const errors = await validateForm()

      if (!isEmpty(errors)) { return }

      const result = await submitForm()

      if (!result.graphQLErrors) { fn && fn() }
    },
    [validateForm, submitForm, validationErrors]
  )

  useEffect(() => {
    if (!isLoggedIn) {
      setFieldValue('password', null)
    }
  }, [password, isLoggedIn])

  const onDoneClick = useCallback(
    () => validateAndSubmit(),
    [validateAndSubmit])

  return (
    <Form className='p1-import-form'>
      <Form.Group>
        <InputField
          name='login'
          label='Email address'
          inputProps={{
            disabled: isLoggedIn
          }}
        />

        <InputField
          name='password'
          label='Password'
          inputProps={{
            type: 'password',
            disabled: isLoggedIn
          }}
        />
      </Form.Group>

      <Form.ButtonGroup>
        {isLoggedIn
          ? (
            <>
              <P1DisconnectButton
                disconnectMutation={onDoneClick}
              />
              <P1SyncButton />
            </>
            )
          : (
            <Button
              color={Button.Colors.BLUE}
              disabled={loading || isLoggedIn}
              onClick={onDoneClick}
            >
              Connect to P1
            </Button>
            )}
      </Form.ButtonGroup>
    </Form>
  )
}

const P1ImportFormEnhanced = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: p1ImportSchema,
  mapPropsToValues: ({ login, password }) =>
    buildInitialValues(p1ImportSchema, { login, password }),
  handleSubmit: (values, { props: { mutate } }) => {
    p1ImportSchema.cast(values)
    return mutate({ variables: { input: values } })
  }
})(P1ImportForm)

export { P1ImportFormEnhanced as P1ImportForm }
