import T from 'prop-types'

import { BaseField } from '../BaseField'
import { TextArea } from '../../inputs/TextArea'
import { withIcon } from '../../inputs/WithIcon'

const WithIcon = withIcon(TextArea)
const TextAreaWithIcon = props => <WithIcon {... { isTextArea: true, ...props }} />

const TextAreaField = ({
  inputProps,
  value,
  handleChange,
  error,
  label,
  name,
  className
}) => {
  const formikProps = { id: name, value: value || '', handleChange }
  return (
    <BaseField error={error} label={label} className={className}>
      {
        inputProps.icon
          ? (
            <TextAreaWithIcon {... { ...inputProps, ...formikProps }} />
            )
          : (
            <TextArea {... { ...inputProps, ...formikProps }} />
            )
      }
    </BaseField>
  )
}

TextAreaField.propTypes = {
  /**
   * Value to be passed to the Input.
   */
  value: T.string,
  /**
   * Props to be passed to the input.
   */
  inputProps: T.object,
  /**
   * Function that receives new values from the Input.
   */
  handleChange: T.func,
  /**
   * Error from Formik.
   */
  error: T.string,
  /**
   * Label to be displayed in the field.
   */
  label: T.string
}

export { TextAreaField }
