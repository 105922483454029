import T from 'prop-types'
import classnames from 'classnames'

import { DropdownMenu } from '../../../misc'
import { MenuDotsIcon } from '../../../icons'
import { RemoveAttachmentConfirmation } from '../RemoveAttachmentConfirmation'

const AttachmentListActionMenu = ({
  handleDownload,
  onRemoveAttachment,
  className
}) => (
  <DropdownMenu
    className={classnames(
      'attachment-list-action-menu',
      className
    )}
    direction={DropdownMenu.Directions.RIGHT}
    trigger={<MenuDotsIcon width={18} height={18} />}
  >
    <DropdownMenu.List>
      <DropdownMenu.Item onClick={handleDownload}>
        Download
      </DropdownMenu.Item>
      <RemoveAttachmentConfirmation {...{
        Component: (props) => (
          <DropdownMenu.Item {...props}>Delete</DropdownMenu.Item>
        ),
        onRemoveAttachment
      }}
      />
    </DropdownMenu.List>
  </DropdownMenu>
)

AttachmentListActionMenu.propTypes = {
  handleDownload: T.func.isRequired,
  onRemoveAttachment: T.func.isRequired,
  className: T.string
}

export { AttachmentListActionMenu }
