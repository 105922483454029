import { cloneElement } from 'react'
import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import ClientCreated from './ClientCreated'
import PoliciesCreated from './PoliciesCreated'
import PoliciesUpdated from './PoliciesUpdated'
import ParsingError from './ParsingError'
import InvalidClientError from './InvalidClientError'
import InvalidPoliciesError from './InvalidPoliciesError'
import ClientInternalError from './ClientInternalError'
import PoliciesInternalError from './PoliciesInternalError'
import { TransactionsCreated } from './TransactionsCreated'
import { TransactionsError } from './TransactionsError'
import { NoPolicyToUpdateWarning } from './NoPolicyToUpdateWarning'
import {
  findLogEntryWithType,
  filterLogEntriesWithType,
  filterLogEntriesByTransactionType
} from '../../../../helpers/dataImport'

const {
  DataImportProcessingEvents: {
    CLIENT_CREATED,
    POLICY_CREATED,
    POLICY_TRANSACTION_CREATED,
    POLICY_UPDATED,
    WARNING_NO_POLICY_TO_UPDATE,
    ERROR_PARSING_FAILURE,
    ERROR_CLIENT_INVALID,
    ERROR_POLICY_INVALID,
    ERROR_CLIENT_INTERNAL_FAILURE,
    ERROR_POLICY_INTERNAL_FAILURE,
    ERROR_POLICY_TRANSACTION_INTERNAL_FAILURE,
    ERROR_POLICY_TRANSACTION_INVALID
  }
} = constants.dataImport

const ClientLog = ({
  clientFirstName,
  clientLastName,
  logEntries,
  dataImportType
}) => {
  const content = []

  const commonProps = {
    clientFirstName,
    clientLastName,
    dataImportType
  }

  if (findLogEntryWithType(CLIENT_CREATED, logEntries)) {
    content.push(
      <ClientCreated {...commonProps} />
    )
  }

  const policyCreationLogEntries = filterLogEntriesWithType(
    POLICY_CREATED,
    logEntries
  )
  if (policyCreationLogEntries.length) {
    content.push(
      <PoliciesCreated
        {...commonProps}
        policies={policyCreationLogEntries}
      />
    )
  }

  const policyUpdateLogEntries = filterLogEntriesWithType(
    POLICY_UPDATED,
    logEntries
  )
  if (policyUpdateLogEntries.length) {
    content.push(
      <PoliciesUpdated
        {...commonProps}
        policies={policyUpdateLogEntries}
      />
    )
  }

  const noPolicyToUpdateWarn = findLogEntryWithType(
    WARNING_NO_POLICY_TO_UPDATE,
    logEntries
  )
  if (noPolicyToUpdateWarn) {
    content.push(
      <NoPolicyToUpdateWarning
        {...commonProps}
        {...noPolicyToUpdateWarn}
      />
    )
  }

  if (findLogEntryWithType(ERROR_PARSING_FAILURE, logEntries)) {
    content.push(
      <ParsingError {...commonProps} logEntries={logEntries} />
    )
  }

  const invalidClientLogEntry = findLogEntryWithType(
    ERROR_CLIENT_INVALID,
    logEntries
  )
  if (invalidClientLogEntry) {
    content.push(
      <InvalidClientError
        {...commonProps}
        fields={invalidClientLogEntry.fields}
      />
    )
  }

  const invalidPolicyLogEntries = filterLogEntriesWithType(
    ERROR_POLICY_INVALID,
    logEntries
  )
  if (invalidPolicyLogEntries.length) {
    content.push(
      <InvalidPoliciesError
        {...commonProps}
        policies={invalidPolicyLogEntries}
      />
    )
  }

  if (findLogEntryWithType(ERROR_CLIENT_INTERNAL_FAILURE, logEntries)) {
    content.push(
      <ClientInternalError {...commonProps} />
    )
  }

  const policyInternalErrorLogEntries = filterLogEntriesWithType(
    ERROR_POLICY_INTERNAL_FAILURE,
    logEntries
  )
  if (policyInternalErrorLogEntries.length) {
    content.push(
      <PoliciesInternalError
        {...commonProps}
        policies={policyInternalErrorLogEntries}
      />
    )
  }

  // TRANSACTIONS
  const transactionCreationLogEntries = filterLogEntriesWithType(
    POLICY_TRANSACTION_CREATED,
    logEntries
  )
  if (transactionCreationLogEntries.length) {
    const contributionCreationLogEntries = filterLogEntriesByTransactionType(transactionCreationLogEntries, true)
    const withdrawalCreationLogEntries = filterLogEntriesByTransactionType(transactionCreationLogEntries)

    if (contributionCreationLogEntries.length) {
      content.push(
        <TransactionsCreated
          {...commonProps}
          isContribution
          transactions={contributionCreationLogEntries}
        />
      )
    }

    if (withdrawalCreationLogEntries.length) {
      content.push(
        <TransactionsCreated
          {...commonProps}
          transactions={withdrawalCreationLogEntries}
        />
      )
    }
  }

  const transactionInternalErrorLogEntries = filterLogEntriesWithType(
    ERROR_POLICY_TRANSACTION_INTERNAL_FAILURE,
    logEntries
  )
  if (transactionInternalErrorLogEntries.length) {
    const contributionInternalLogEntries = filterLogEntriesByTransactionType(transactionInternalErrorLogEntries, true)
    const withdrawalInternalLogEntries = filterLogEntriesByTransactionType(transactionInternalErrorLogEntries)
    if (contributionInternalLogEntries.length) {
      content.push(
        <TransactionsError
          {...commonProps}
          transactions={contributionInternalLogEntries}
          isInternalError
          isContribution
        />
      )
    }

    if (withdrawalInternalLogEntries.length) {
      content.push(
        <TransactionsError
          {...commonProps}
          transactions={withdrawalInternalLogEntries}
          isInternalError
        />
      )
    }
  }

  const transactionInvalidErrorLogEntries = filterLogEntriesWithType(
    ERROR_POLICY_TRANSACTION_INVALID,
    logEntries
  )
  if (transactionInvalidErrorLogEntries.length) {
    const contributionInvalidLogEntries = filterLogEntriesByTransactionType(transactionInvalidErrorLogEntries, true)
    const withdrawalsInvalidLogEntries = filterLogEntriesByTransactionType(transactionInvalidErrorLogEntries)

    if (contributionInvalidLogEntries.length) {
      content.push(
        <TransactionsError
          {...commonProps}
          transactions={contributionInvalidLogEntries}
          isContribution
        />
      )
    }

    if (withdrawalsInvalidLogEntries.length) {
      content.push(
        <TransactionsError
          {...commonProps}
          transactions={withdrawalsInvalidLogEntries}
        />
      )
    }
  }

  return (
    <>
      {content.map((logEntry, i) =>
        cloneElement(logEntry, { key: i }))}
    </>
  )
}

ClientLog.propTypes = {
  firstName: T.string,
  lastName: T.string,
  logEntries: T.array.isRequired,
  dataImportType: T.string.isRequired
}

export default ClientLog
