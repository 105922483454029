import PropTypes from 'prop-types'
import constants from '@elenfs/elen-constants'
import { Navigate } from 'react-router-dom'

import { NotAuthorizedPage } from '../../authorization'

const { ErrorCodes } = constants.error

export const ErrorPage = ({
  graphQLErrors,
  children
}) => {
  if (!graphQLErrors) {
    return null
  }

  try {
    const { extensions } = graphQLErrors[0]
    const { details } = extensions.exception

    // In the most recent error shape the code lives in the
    // `extensions.code` property.
    // For compatibility with the old error shapes we check
    // `extensions.exception.details`
    // TODO: use only `extensions.code` when the back end has
    // fully transitioned to the new error shape.
    const errorCode = details
      ? (details.code || details)
      : extensions.code

    // TODO we can return different component based on error code
    // TODO else if (errorCode === ErrorCodes)
    if (errorCode === ErrorCodes.NOT_AUTHORIZED) {
      return <NotAuthorizedPage />
    }

    if (errorCode === ErrorCodes.SUBSCRIPTION_ALREADY_EXISTS) {
      return <Navigate to='/secure' />
    }
  } catch (e) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

ErrorPage.propTypes = {
  /**
   * Error object, that contain actual error
   */
  graphQLErrors: PropTypes.arrayOf(PropTypes.object),

  /**
   * Children is defaultPage to be used
   */
  children: PropTypes.node.isRequired
}
