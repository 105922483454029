import { MessageNotificationList } from '../../misc'
import { DashboardCardTitle } from '../DashboardCardTitle'

import './ClientDashboardCard.less'

const MessagesDashboardCard = ({ listProps, titleProps }) => {
  return (
    <div className='messages-dashboard-card'>
      <DashboardCardTitle {...titleProps}>
        {
          titleProps?.actionComponentProps?.isClientPortal
            ? 'Latest message'
            : 'Unread messages'
        }
      </DashboardCardTitle>
      <MessageNotificationList {...listProps} />
    </div>
  )
}

export { MessagesDashboardCard }
