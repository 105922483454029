import classnames from 'classnames'
import { components } from 'react-select'

import { ColorsAvatar } from '../../../misc/ColorsAvatar'

import './DropdownCustom.less'

const ControlWithColors = ({
  children,
  className,
  cx,
  getStyles,
  getValue,
  innerProps,
  ...rest
}) => {
  const colorComponent = getValue()?.length
    ? <ColorsAvatar color={getValue()?.[0]?.color} />
    : null

  return (
    <components.Control
      {...{
        cx,
        getStyles,
        getValue,
        innerProps,
        css: getStyles('control', rest),
        className: classnames('dropdown-selection__control--with-color', cx(
          {
            option: true
          },
          className
        )),
        ...rest
      }}
    >
      {colorComponent}
      {children}
    </components.Control>
  )
}

export { ControlWithColors }
