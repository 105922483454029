import * as yup from 'yup'
import { values } from 'ramda'
import constants from '@elenfs/elen-constants'

import enumSchema from '../helpers/enumSchema'
import stringSchema from '../helpers/stringSchema'
import emailSchema from '../helpers/emailSchema'
import booleanSchema from '../helpers/booleanSchema'
import percentSchema from '../helpers/percentSchema'

const { CompanyUserRoles, CompanyUserAdminAccess } = constants.user

const requiredForAdviser = (role, schema) => (role === CompanyUserRoles.ADVISER ? schema.required() : schema)

const userSchema = yup.object().shape({
  firstName: stringSchema().label('First name').required(),

  lastName: stringSchema().label('Last name').required(),

  email: emailSchema().label('Email').required(),

  role: enumSchema(values(CompanyUserRoles)).label('Job role').required(),

  isAdmin: booleanSchema().label('Permissions').default(CompanyUserAdminAccess.USER).required(),

  isAccountOwner: booleanSchema().label('Account Owner').default(false).required(),

  defaultRate: percentSchema().label('Default rate').when('role', requiredForAdviser),

  alternativeRate: percentSchema().label('Alternative rate'),

  permissions: yup.object().shape({
    allClients: booleanSchema().label('All clients').default(false).required(),
    income: booleanSchema().label('Income').default(false).required(),
    reports: booleanSchema().label('Reports').default(false).required(),
    adviserIds: yup
      .array()
      .of(yup.string())
      .label("Grant access to the following advisers' clients")
      .default([])
  })
}).required()

export { userSchema }
