import T from 'prop-types'
import constants from '@elenfs/elen-constants'

import LoggedOutLayout from '../../misc/LoggedOutLayout'
import UserFormPage from '../UserFormPage'
import ForgotPasswordForm from '../ForgotPasswordForm'

const { UserTypes } = constants.user

const ForgotPasswordPage = ({ userType }) => (
  <LoggedOutLayout>
    <UserFormPage
      {...{
        headerTitle: 'Forgotten password',
        form: ForgotPasswordForm,
        formProps: { userType }
      }}
    />
  </LoggedOutLayout>
)

ForgotPasswordPage.defaultProps = {
  userType: UserTypes.COMPANY_USER
}

ForgotPasswordPage.propTypes = {
  userType: T.oneOf(Object.values(UserTypes))
}

export default ForgotPasswordPage
