import { ESignatureUserStatuses } from '@elenfs/elen-constants'

import Table from '../../misc/Table'
import VisibleForClientCheckbox from './VisibleForClientCheckbox'
import ConnectToPolicy from './ConnectToPolicy'
import { DocumentDetails } from './DocumentDetails'
import { DocumentActions } from './DocumentActions'
import { ESignatureStatusLabels } from './../../../helpers/enumLabels/clientDocuments'

import { isFilePdf } from './../../../helpers/document'

import './DocumentRow.less'

const DocumentRow = props => {
  const {
    clientDocument,
    onStartDownload,
    policies,
    isClientPortal,
    getDownloadLink,
    fileDownloadUrl,
    policyId,
    onRequestSignatureDocument,
    isClientUser,
    isUnActiveClientLoading,
    refetchDocuments,
    onClickToSign
  } = props

  const clientNotRegistered = !isUnActiveClientLoading && !isClientUser

  const isPending = ESignatureUserStatuses.PENDING === clientDocument?.eSignature?.status
  const isError = ESignatureUserStatuses.ERROR === clientDocument?.eSignature?.status

  const showClientNotRegistered =
    isFilePdf(clientDocument) && clientNotRegistered && !isClientPortal

  return (
    <>
      <Table.Row className='client-document-row'>
        {/* cell with file name and other document details */}
        <Table.Cell className='client-document-row__cell client-document-row__name-cell'>
          <DocumentDetails
            {...{ clientDocument, fileDownloadUrl, getDownloadLink }}
          />
        </Table.Cell>

        {/* cell connect to policy */}
        <Table.Cell className='client-document-row__cell client-document-row__policy-related-cell'>
          {!isClientPortal && (
            <ConnectToPolicy
              {...{
                clientDocument,
                policies,
                policyId
              }}
            />
          )}
        </Table.Cell>

        {/* visibility checkbox */}
        <Table.Cell
          centered
          className='client-document-row__cell client-document-row__visible-cell'
        >
          {!isClientPortal && (
            <VisibleForClientCheckbox
              clientDocument={clientDocument}
              className='client-document-row__visible-cell__content'
            />
          )}
        </Table.Cell>

        {/* cell signing status */}
        <Table.Cell className='client-document-row__cell client-document-row__signing-status-cell'>
          {ESignatureStatusLabels[clientDocument?.eSignature?.status]}
          {isPending && (
            <div
              className='client-document-row__click-to-sign'
              onClick={() => onClickToSign(clientDocument)}
            >
              Click to sign
            </div>
          )}
          {showClientNotRegistered && (
            <span className='faded-text'> Client not registered </span>
          )}
        </Table.Cell>

        {/* cell with buttons for downloading and deleting document */}
        <Table.Cell className='client-document-row__cell client-document-row__management-cell'>
          <DocumentActions
            {...{
              isClientPortal,
              onStartDownload,
              clientDocument,
              onRequestSignatureDocument,
              clientNotRegistered,
              refetchDocuments,
              isError
            }}
          />
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default DocumentRow
