import T from 'prop-types'

import { Modal } from '..'
import { ImportClientModal } from './ImportClientModal'

const ImportClientModalTrigger = props => (
  <Modal.Trigger {...props} modal={ImportClientModal} />
)

ImportClientModalTrigger.propTypes = {
  modalProps: T.object,
  as: T.oneOfType([T.func, T.string]),
  children: T.node
}

export { ImportClientModalTrigger }
