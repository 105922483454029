import { Routes, Route, Navigate } from 'react-router-dom'

import { ListViewPage } from '../ListViewPage'
import { TasksKanbanBoard } from '../TasksKanbanBoard'

const TasksTableSection = ({ tableProps, className, filterProps }) => (
  <Routes>
    <Route index path='list-view' element={<ListViewPage />} />
    <Route path='kanban-view' element={<TasksKanbanBoard />} />
    <Route path='/' element={<Navigate replace to='list-view' />} />
  </Routes>
)

export { TasksTableSection }
