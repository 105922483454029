import { Component } from 'react'
import { is } from 'ramda'

import './WithIcon.less'

const withIcon = WrappedComponent =>
  class WithIcon extends Component {
    render () {
      const { icon, ...props } = this.props

      return (icon && !is(String, icon))
        ? (
          <div className='icon-wrapper'>
            {icon}
            <WrappedComponent {...props} />
          </div>
          )
        : (
          <WrappedComponent icon={icon} {...props} />
          )
    }
  }

export default withIcon
