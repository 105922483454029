import { useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { ClientGlobalSearch } from '../ClientGlobalSearch'

import searchClientsGql from '../../../graphql/queries/client/searchClientsByKeyword.graphql'

const ClientSearchContainer = () => {
  const navigate = useNavigate()

  const [searchInput, setSearchInput] = useState('')
  const [searchClientsByKeyword, { loading, data }] = useLazyQuery(searchClientsGql, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const clients = data?.clients?.result

  const handleKeyChange = useCallback(
    (searchInput) => {
      setSearchInput(searchInput)
      if (searchInput === '') return
      searchClientsByKeyword({
        variables: {
          searchInput
        }
      })
    },
    [searchInput]
  )

  const handleShowMore = () => navigate('/secure/clients')

  return (
    <ClientGlobalSearch
      autoCompleteData={clients}
      onInputChange={(e) => handleKeyChange(e)}
      loading={loading}
      handleShowMore={handleShowMore}
    />
  )
}

export { ClientSearchContainer }
