import T from 'prop-types'

import ImportWarning from './ImportWarning'

const NoPolicyToUpdateWarning = ({ policyNumber }) => (
  <ImportWarning
    heading={
      `Policy ${policyNumber} could not be found, valuations for the policy ` +
      'are not imported'
    }
  />
)

NoPolicyToUpdateWarning.propTypes = {
  policyNumber: T.string
}

export { NoPolicyToUpdateWarning }
