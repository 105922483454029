import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { compose } from 'ramda'
import constants from '@elenfs/elen-constants'

import { withCurrentUser } from '../WithCurrentUser'

const { UserTypes } = constants.user

const withAuthorization = (redirectTo, userType) => (WrappedComponent) => (props) => {
  const { currentUser } = props
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate(redirectTo, { prevPath: pathname + search })
    }
  }, [navigate, pathname, search])

  if (currentUser && currentUser.type === userType) {
    return <WrappedComponent {...props} />
  }

  return <></>
}

export default ({ redirectTo = '/login', userType = UserTypes.COMPANY_USER } = {}) => compose(
  withCurrentUser(),
  withAuthorization(redirectTo, userType)
)
