import T from 'prop-types'
import classnames from 'classnames'

import { Colors, Sizes, TextColor } from './constants'

import './Badge.less'

const Badge = ({ children, className, color, size, round, action, textColor, ...props }) => (
  <span
    className={classnames(
      'badge',
      `badge--${color}`,
      size && `badge--${size}`,
      round && 'badge--round',
      action && 'badge--action',
      textColor && `badge--${textColor}`,
      className
    )}
    {...props}
  >
    {children}
  </span>
)

Badge.propTypes = {
  /**
   * Children of the badge element
   */
  children: T.node.isRequired,
  /**
   * Color styles
   */
  color: T.oneOf(Object.values(Colors)),
  /**
   * Custom classname for the badge element.
   */
  className: T.string,
  /**
   * Size of the badge.
   */
  size: T.oneOf(Object.values(Sizes)),
  /**
   * Round or square (class)
   */
  round: T.bool,
  /**
   * Action or not (class)
   */
  action: T.bool,
  /**
   * Text color (class)
   */
  textColor: T.oneOf(Object.values(TextColor))
}

export default Badge
