import PropTypes from 'prop-types'
import classnames from 'classnames'

import SuccessIcon from '../../icons/Success'
import GoIcon from '../../icons/Go'
import CloseIcon from '../../icons/Close'
import { ExclamationErrorIcon } from './../../icons'

import { Types } from './const'

import './Toastr.less'

const { SUCCESS, ERROR } = Types

const Toastr = props => {
  const { type, title, content, showActionButtons } = props

  const iconClassName = 'elen-toast__content-container__header__icon'
  let icon
  if (type === SUCCESS) {
    icon = <SuccessIcon className={iconClassName + ' icon-success'} />
  } else if (type === ERROR) {
    icon = <ExclamationErrorIcon className={iconClassName + ' icon-error'} />
  }

  return (
    <div
      className={classnames('elen-toast', {
        'elen-toast--success': type === SUCCESS,
        'elen-toast--error': type === ERROR
      })}
    >
      <div className='elen-toast__content-container'>
        <div className='elen-toast__content-container__header'>
          {icon}
          <div className='elen-toast__content-container__header__title'>
            {title}
          </div>
        </div>
        <div className='elen-toast__content-container__content'>{content}</div>
      </div>
      {showActionButtons && (
        // TODO: styles for action buttons
        <div className='elen-toast__buttons-container'>
          <div className='elen-toast__buttons-container__button button-go'>
            <GoIcon />
          </div>
          <div className='elen-toast__buttons-container__button button-close'>
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  )
}

Toastr.defaultProps = {
  type: SUCCESS
}

Toastr.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  type: PropTypes.oneOf([SUCCESS, ERROR])
}

export { Toastr }
