import { useEffect, useCallback, useState } from 'react'
import { useTasksModal, useTaskGql } from './../../../hooks'

import { TaskDetailsModal } from '../../tasks'
import { GeneralErrorModal } from '../../misc'
import { DocumentNotFoundIcon } from '../../icons'

const TaskModal = ({ taskId, refetchLogEntries }) => {
  const [isTaskNotFound, setTaskNotFound] = useState(false)
  const { getTask, task, refetchTask, called, loading } = useTaskGql()
  const taskModalProps = useTasksModal()
  const { onTaskClick, close, isSubtask } = taskModalProps

  const onRefetchTask = useCallback(
    async id => {
      const { data } = await refetchTask(id && { id })
      onTaskClick(data?.task)
    },
    [refetchTask]
  )

  const onGetTask = () => {
    if (task) onRefetchTask(taskId)
    if (!task) getTask({ variables: { id: taskId } })
  }

  useEffect(() => {
    if (task) onTaskClick(task)
    if (called && !task && !loading) setTaskNotFound(true)
  }, [task, called, loading])

  const onCloseCB = useCallback(
    async props => {
      close(props)
      if (isSubtask && task?.subtasks) {
        onRefetchTask()
      }
    },
    [isSubtask, task, onRefetchTask]
  )

  const onParentTaskClickCB = useCallback(onRefetchTask, [
    onTaskClick,
    task,
    onRefetchTask
  ])

  return (
    <>
      <div className='audit-log-entry-form__task'>
        This was automatically created by this
        <span onClick={onGetTask}>Task.</span>
      </div>

      <GeneralErrorModal
        isOpen={isTaskNotFound}
        close={() => setTaskNotFound(false)}
        title='Task Unavailable'
        text='It’s possible that the document has been removed or is not accessible at the moment.'
        icon={DocumentNotFoundIcon}
        className='task-not-found-modal'
      />

      {task && (
        <TaskDetailsModal
          {...{
            ...taskModalProps,
            refetchLogEntries,
            onParentTaskClick: onParentTaskClickCB,
            close: onCloseCB
          }}
        />
      )}
    </>
  )
}

export { TaskModal }
