import { useNavigate } from 'react-router-dom'

import { DashboardBoxCardCount } from './DashboardBoxCardCount'

const DashboardBoxCardCountContainer = (props) => {
  const navigate = useNavigate()

  return <DashboardBoxCardCount {...{ navigate, ...props }} />
}

export { DashboardBoxCardCountContainer }
