import T from 'prop-types'

import { ConfirmationModal, Button } from '../../../misc'
import { Trash } from './../../../icons'

const RemoveConnectionButton = ({
  removeConnectionCallback,
  className
}) => (
  <ConfirmationModal.Trigger
    as={Trash}
    color={Button.Colors.RED_TEXT}
    className={className}
    modalProps={{
      confirmationCallback: removeConnectionCallback,
      heading: 'Remove connection',
      text: 'Are you sure you want to delete this connection?',
      yesButtonText: 'Delete',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
  />
)

RemoveConnectionButton.propTypes = {
  removeConnectionCallback: T.func.isRequired,
  className: T.string
}

export { RemoveConnectionButton }
