import { is, values } from 'ramda'
import * as yup from 'yup'
import { Currencies } from '@elenfs/elen-constants'

import enumSchema from './enumSchema'
import numberSchema from './numberSchema'

export default ({ amountLabel = '', min = 0 } = {}) =>
  yup
    .object()
    .shape({
      amount: (min === false)
        ? numberSchema().label(amountLabel)
        : numberSchema()
          .label(amountLabel)
          .min(min, min ? `${amountLabel} must have minimum value of ${min}` : undefined),
      currency: enumSchema(values(Currencies))
    })
    .label(amountLabel)
    .nullable()
    .noUnknown(true)
    .default({
      amount: null,
      currency: null
    })
    .transform((value) => {
      if (is(Object, value) && ['', null, undefined].includes(value.amount)) {
        return null
      }

      return value
    })
