import classnames from 'classnames'
import { useLocation, useParams } from 'react-router-dom'

import constants from '@elenfs/elen-constants'

import PageContent from '../../misc/PageContent'
import { PolicyPageHeader } from './PolicyPageHeader'
import { ClientPolicySwitch } from './ClientPolicySwitch'
import { PolicySwitch } from './PolicySwitch'
import { buildTabs, extractCategory, PolicyDetails, PolicyForms } from './helpers'
import { pathMatchCurrentLocation } from '../../../helpers/path'

import './PolicyPage.less'

const { PolicyValuationCategories } = constants.policy.common

const PolicyPage = ({
  policy,
  hasReconciledCharges,
  isClientPolicy,
  ...props
}) => {
  const { currentClient } = props
  const { search, pathname } = useLocation()
  const { clientId } = useParams()

  const category = policy ? policy.category : extractCategory(search)

  const showValuationRoute = PolicyValuationCategories[category]

  // if policy is new, add currentClientId into preselected policyHolders
  const policyNormalized = { ...(policy || { currentHoldersIds: [clientId] }), category }

  const editPath = '/secure/clients/:clientId/policies/:policyId/details/edit'
  const createPath = '/secure/clients/:clientId/policies/create'

  const isInEditPath = !!pathMatchCurrentLocation({ pathToCheck: editPath, pathname })
  const isInCreatePath = !!pathMatchCurrentLocation({ pathToCheck: createPath, pathname })

  // TODO: this should be split to its own component
  // which should decide what PolicyDetails component to use, like PolicyForm
  const PolicyCategoryDetails = PolicyDetails[category]
  const PolicyForm = PolicyForms[category]

  return (
    <div className={classnames('policy',
      { 'policy--form': isInCreatePath || isInEditPath })}
    >
      <PolicyPageHeader
        {...{
          isInEditPath,
          isInCreatePath,
          policy,
          currentClientName: currentClient ? currentClient.fullName : '',
          navTabs: buildTabs({ showValuationRoute, isClientPolicy }),
          linkUrl: isClientPolicy
            ? '/clients/secure/overview/policies'
            : `/secure/clients/${clientId}/policies`
        }}
      />

      <PageContent className='policy__content'>
        {
          isClientPolicy
            ? <ClientPolicySwitch {...{
              currentClient,
              policy: policyNormalized,
              search,
              clientId,
              isInCreatePath,
              isClientPolicy,
              showValuationRoute,
              hasReconciledCharges,
              category,
              PolicyCategoryDetails
            }}
              />
            : <PolicySwitch {...{
              currentClient,
              policy: policyNormalized,
              search,
              category,
              clientId,
              PolicyForm,
              isInCreatePath,
              isClientPolicy,
              showValuationRoute,
              hasReconciledCharges,
              PolicyCategoryDetails
            }}
              />
        }
      </PageContent>
    </div>
  )
}

export { PolicyPage }
