import { useEffect, useState, useCallback, useMemo } from 'react'
import { remove, pipe, prop, sortBy, path, append, update, is, insert } from 'ramda'
import { useWorkflowGql } from '../../../hooks/useWorkflowGql'
import { useParams, useNavigate } from 'react-router'

import { WorkflowPage } from './WorkflowPage'
import { TaskActionSidePanel } from '../TaskActionSidePanel'
import { Spinner, ErrorMessage } from '../../misc'

import { submitWorkflowTemplate } from './helpers'

// This component is used for both cases (create/edit) which is based on workflowId url param
const WorkflowPageContainer = () => {
  const { workflowId } = useParams()
  const navigate = useNavigate()

  const [initialLoading, setInitialLoading] = useState(!!workflowId)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [actions, setActions] = useState([])
  const [selectedActionIndex, setSelectedActionIndex] = useState(null)
  const [insertActionIndex, setInsertActionIndex] = useState(null)

  const {
    createWorkflowTemplate,
    mutationLoading,
    mutationError,
    workflowTemplateData,
    fetchWorkflowTemplate,
    fetchLoading,
    fetchWorkflowTemplateCalled,
    updateWorkflowTemplate
  } = useWorkflowGql({ onSuccessfulMutation: () => navigate('/secure/workflows') })

  useEffect(() => {
    const shouldFetchWorkflowTemplate =
      workflowId && !fetchLoading && !fetchWorkflowTemplateCalled

    if (shouldFetchWorkflowTemplate) {
      fetchWorkflowTemplate({ variables: { id: workflowId } })
    }
  }, [workflowId, fetchLoading, fetchWorkflowTemplateCalled])

  useEffect(() => {
    if (workflowTemplateData) {
      pipe(
        path(['workflowTemplate', 'actionTemplates']),
        sortBy(prop('order')),
        setActions
      )(workflowTemplateData)
    }
    setInitialLoading(false)
  }, [workflowTemplateData])

  const workflowDiagramProps = useMemo(
    () => ({
      actions,
      onDeleteAction: index => setActions(remove(index, 1)),
      onSummaryClick: index => {
        setSelectedActionIndex(index)
        setShowSidePanel(true)
      },
      onAddNewAction: actionIndex => {
        setShowSidePanel(true)
        if (actionIndex) {
          setInsertActionIndex(actionIndex)
        }
      },
      isWorkflowInstance: false
    }),
    [actions]
  )

  const onSubmitWorkflowTemplate = useCallback(
    ({ name }) => {
      submitWorkflowTemplate({
        name,
        actions,
        workflowId,
        updateWorkflowTemplate,
        createWorkflowTemplate
      })
    },
    [actions, workflowId, updateWorkflowTemplate, createWorkflowTemplate]
  )

  const closePanel = useCallback(() => {
    setShowSidePanel(false)
    setSelectedActionIndex(null)
    setInsertActionIndex(null)
  }, [])

  const onSidePanelSubmit = useCallback(
    action => {
      if (selectedActionIndex !== null) {
        setActions(update(selectedActionIndex, action))
      } else {
        if (is(Number, insertActionIndex)) {
          setActions(insert(insertActionIndex, action))
        } else {
          setActions(append(action))
        }
      }
    },
    [selectedActionIndex, insertActionIndex]
  )

  if (initialLoading || fetchLoading) {
    return <Spinner />
  }

  if (workflowId && !workflowTemplateData) {
    return <ErrorMessage text='Failed to retrieve workflow template' />
  }

  return (
    <>
      <TaskActionSidePanel
        showSidePanel={showSidePanel}
        closePanel={closePanel}
        sidePanelContentProps={{
          onSubmit: onSidePanelSubmit,
          action: actions[selectedActionIndex] || {}
        }}
      />

      <WorkflowPage
        workflowDiagramProps={workflowDiagramProps}
        loading={mutationLoading}
        error={mutationError}
        onSubmitWorkflowTemplate={onSubmitWorkflowTemplate}
        workflowTemplate={
          workflowTemplateData?.workflowTemplate || { name: '', actions: [] }
        }
      />
    </>
  )
}

export { WorkflowPageContainer }
