import { WorkflowActionTypes } from '@elenfs/elen-constants'
import T from 'prop-types'

import { Trash } from '../../../icons'
import { ConfirmationModal } from './../../../misc'

const DeleteActionIcon = ({ className, actionType, onDeleteAction }) => {
  const isTaskAction = actionType === WorkflowActionTypes.TASK

  const modalProps = {
    heading: 'Delete action',
    text: 'Are you sure you want to delete this action?',
    yesButtonText: 'Delete',
    noButtonText: 'Go back',
    confirmationCallback: onDeleteAction,
    awaitCallback: true
  }

  if (isTaskAction) {
    modalProps.heading = 'Delete task'
    modalProps.text = 'Are you sure you want to delete this task?'
  }

  return (
    <ConfirmationModal.Trigger
      modalProps={modalProps}
      as={Trash}
      className={className}
    />
  )
}

DeleteActionIcon.propTypes = {
  actionType: T.oneOf(Object.values(WorkflowActionTypes)).isRequired,
  onDeleteAction: T.func.isRequired
}

export { DeleteActionIcon }
