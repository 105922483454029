import T from 'prop-types'
import classnames from 'classnames'

export const FormSectionInputsBlock = ({ children, className }) => (
  <div
    className={classnames(
      'form-new__section__input-block',
      'section-inputs-block', {
        [className]: !!className
      })}
  >
    {children}
  </div>
)

FormSectionInputsBlock.propTtpes = {
  children: T.node,
  className: T.string
}
