import { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import messagesGql from '../../../../graphql/queries/messages/messages.graphql'
import messageCreatedGql from '../../../../graphql/subscriptions/messageCreated.graphql'

const withNewMessagesSubscription = () => WrappedComponent => props => {
  const { clientId } = props

  const { data, loading, subscribeToMore } = useQuery(messagesGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })
  const messages = data?.messages || []

  const subscribeForNewMessages = useCallback(
    () =>
      subscribeToMore({
        document: messageCreatedGql,
        variables: { clientId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          return {
            messages: [...prev.messages, subscriptionData.data.messageCreated]
          }
        }
      }),
    [subscribeToMore]
  )

  return (
    <WrappedComponent
      {...{
        ...props,
        subscribeForNewMessages,
        messages,
        messagesLoading: loading
      }}
    />
  )
}

export { withNewMessagesSubscription }
