import T from 'prop-types'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'

import constants from '@elenfs/elen-constants'

import { IntegrationBadge } from '../../../misc'
import { ChevronLeftIcon, CloseModal } from '../../../icons'
import SectionNav from '../../../nav/SectionNav'
import { getSubCategoryLabels } from '../../../../helpers/policy'
import {
  PolicyTypeByCategory
} from '../../../../helpers/enumLabels/policy'

import './policyPageHeader.less'

const { CategoryStrings } = constants.policy.common

const PolicyPageHeader = (props) => {
  const {
    isInEditPath,
    isInCreatePath,
    policy = {},
    currentClientName,
    navTabs,
    linkUrl
  } = props

  const navigate = useNavigate()
  const { updatedBy, updatedAt, number, category } = policy
  const updatedAtLabel = updatedAt && moment(updatedAt).format('D MMM YYYY k:mm')
  const showCreateEditHeader = isInEditPath || isInCreatePath

  let policyType = ''
  if (policy) {
    const currentPolicyType = policy[PolicyTypeByCategory[policy.category]]
    const policyTypes = getSubCategoryLabels(policy.category)
    policyType = policyTypes[currentPolicyType]
  }

  let headerContent = (
    <>
      <Link to={linkUrl} className='policy-header__info--link'>
        <ChevronLeftIcon />
      </Link>
      {
        policy
          ? (
            <>
              <h2 className='policy-header__info__title policy-header__info__title--long'>
                {currentClientName && `${currentClientName} - `}
                <span className='policy-header__info--bold'>
                  {`${CategoryStrings[category]} policy
                | ${policyType || ''}
                ${policyType ? '-' : ''} ${number || ''}`}
                </span>
              </h2>
              <h2 className='policy-header__info__title policy-header__info__title--short'>
                {policyType}
              </h2>
              <IntegrationBadge labelType='Policy' integrations={policy?.integrations} />
            </>
            )
          : 'Back'
      }
    </>
  )

  if (showCreateEditHeader) {
    headerContent = (
      <>
        <h2 className='policy-header__info__title'>
          <div className='policy-header__info--link' onClick={() => navigate(-1)}>
            <ChevronLeftIcon />
          </div>
          {policy && isInEditPath ? 'Edit policy' : (policy && isInCreatePath ? 'Add policy' : 'Back')}
        </h2>
        {isInEditPath && (
          <div className='policy-header__info__metadata'>
            {`Last updated ${updatedAtLabel} ${updatedBy ? `| ${updatedBy}` : ''} `}
          </div>)}
        <div className='policy-header__info__close-icon-wrapper' onClick={() => navigate(-1)}>
          <CloseModal />
        </div>
      </>
    )
  }

  return (
    <div className='policy-header'>
      <div className='policy-header__info'>
        {headerContent}
      </div>
      {!(isInEditPath || isInCreatePath) && <SectionNav className='policy-header__nav' items={navTabs} />}
    </div>
  )
}

PolicyPageHeader.propTypes = {
  isInEditPath: T.bool.isRequired,
  isInCreatePath: T.bool.isRequired,
  policy: T.shape({
    updatedAt: T.string.isRequired,
    updatedBy: T.string.isRequired
  }),
  navTabs: T.arrayOf(
    T.shape({ label: T.string.isRequired, path: T.string.isRequired }).isRequired
  ).isRequired
}

export { PolicyPageHeader }
