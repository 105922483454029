import { useNavigate } from 'react-router-dom'

import { SuccessIcon } from '../../icons'
import { Button } from '../../misc'

import './supportRequestSuccess.less'

export const SupportRequestSuccess = () => {
  const navigate = useNavigate()

  return (
    <div className='support-request-success'>
      <div className='support-request-success__header'>
        <h3>Message sent</h3>
        <SuccessIcon />
      </div>
      <div className='support-request-success__text'>
        Your message has been sent to our custmer support team. We aim to respond to you within 24 hours.
      </div>

      <Button type='button' color={Button.Colors.BLUE} onClick={() => navigate(-1)}>
        Done
      </Button>
    </div>
  )
}
