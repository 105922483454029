import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'ramda'
import updateClientNoteGql from '../../../../graphql/mutations/client/updateClientNote.graphql'
import fetchClientGql from '../../../../graphql/queries/client/clientDetails.graphql'

import ClientNotesCard from './ClientNotesCard'
import { withFormik } from 'formik'

const ClientNotesCardContainer = compose(
  graphql(updateClientNoteGql, {
    options: ({ client }) => {
      return {
        refetchQueries: [
          {
            query: fetchClientGql,
            variables: { clientId: client.id }
          }
        ],
        awaitRefetchQueries: true
      }
    }
  }),
  withFormik({
    mapPropsToValues: ({ client }) => ({
      content: client.note ? client.note.content || '' : ''
    }),

    handleSubmit: async (values, { props, setValues }) => {
      if (props.client && props.client.id) {
        values = { ...values, id: props.client.id }
      }

      const { data } = await props.mutate({ variables: { input: values } })
      !data.error && setValues({ content: data.updateClientNote.content })
    }
  })
)(ClientNotesCard)

export default ClientNotesCardContainer
