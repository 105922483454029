import classnames from 'classnames'
import PropTypes from 'prop-types'

import './PageContent.less'

const PageContent = ({
  children,
  className,
  withMaxWidth,
  withMaxWidthXXXL,
  ...props
}) => (
  <div
    className={classnames('page-content', {
      [className]: className,
      'page-content--max-width': withMaxWidth,
      'page-content--max-width-xxxl': withMaxWidthXXXL
    })}
    {...props}
  >
    {children}
  </div>
)

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  widthMaxWidth: PropTypes.bool
}

export default PageContent
