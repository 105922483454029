const DocumentIcon = props => (
  <svg width={42} height={42} viewBox='0 0 42 42' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h42v42H0z' />
      <path
        d='M25.157 3.5l9.716 9.766v21.132A4.106 4.106 0 0130.77 38.5H11.228a4.106 4.106 0 01-4.102-4.102V7.602A4.106 4.106 0 0111.228 3.5h13.929zm-1.974 2.734H11.228c-.754 0-1.367.614-1.367 1.368v26.796c0 .754.613 1.368 1.367 1.368H30.77c.754 0 1.367-.614 1.367-1.368V15.197h-4.853a4.106 4.106 0 01-4.102-4.102v-4.86zm5.866 22.044c.39 0 .706.316.706.706v1.059c0 .39-.317.706-.706.706H12.776a.706.706 0 01-.706-.706v-1.06c0-.389.317-.705.706-.705H29.05zm0-5.27c.39 0 .706.315.706.705v1.06c0 .389-.317.705-.706.705H12.776a.706.706 0 01-.706-.706v-1.059c0-.39.317-.706.706-.706H29.05zm0-5.271c.39 0 .706.316.706.706V19.5c0 .39-.317.706-.706.706H12.776a.706.706 0 01-.706-.706v-1.058c0-.39.317-.706.706-.706H29.05zm-3.132-9.595v2.953c0 .754.614 1.368 1.368 1.368h2.932l-4.3-4.321z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { DocumentIcon }
