const CheckedIcon = (props) => (
  <svg viewBox='0 0 24 24' width={20} height={20} {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx='12' cy='12' r='9.5' fill='#FFF' stroke='#4D70FF' />
      <circle cx='12' cy='12' r='7' fill='#4D70FF' />
    </g>
  </svg>
)

export default CheckedIcon
