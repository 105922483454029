import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { ClientCompliancePage } from './ClientCompliancePage'
import { ErrorPage, ErrorMessage } from '../../misc'
import Spinner from '../../misc/Spinner'
import clientDetailsGql from '../../../graphql/queries/client/clientDetails.graphql'

const ClientCompliancePageContainer = (prop) => {
  const { clientId } = useParams()

  const { loading, error, data } = useQuery(clientDetailsGql, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) return <Spinner />
  if (error) {
    return (
      <ErrorPage {...error}>
        <ErrorMessage text='Failed to retrieve client compliance' />
      </ErrorPage>
    )
  }

  return <ClientCompliancePage clientId={clientId} client={data?.client} {...prop} />
}

export { ClientCompliancePageContainer }
