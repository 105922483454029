import classnames from 'classnames'

import './DropdownCustom.less'

const ControlWithColoredFill = ({
  children,
  className,
  cx,
  getStyles,
  getValue,
  isDisabled,
  isFocused,
  isSelected,
  innerRef,
  innerProps,
  data,
  ...props
}) => {
  const hasValue = getValue()?.length
  const selectedColor = hasValue && getValue()?.[0]?.color

  return (
    <div
      ref={innerRef}
      className={classnames('dropdown-selection__control', 'dropdown-selection__control__coloured', cx(
        {
          option: true,
          [`control__coloured--${selectedColor}`]: hasValue
        },
        className
      ))}
      {...innerProps}
    >
      {children}
    </div>
  )
}

export { ControlWithColoredFill }
