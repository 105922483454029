import { Fragment } from 'react'

import { PageContent, PageDivider } from '../../misc'
import { PolicyTransactionsTable } from './PolicyTransactionsTable'
import { PolicyTransactionsTotals } from './PolicyTransactionsTotals'
import { PolicyTransactionsEmpty } from './PolicyTransactionsEmpty'
import { WITHDRAWAL, CONTRIBUTION } from '../../../constants/policy'

import './PolicyTransactionsTab.less'

const PolicyTransactionsTab = props => {
  const { contributions, withdrawals, readOnly } = props
  const isEmpty = !(
    (contributions && contributions.length) ||
    (withdrawals && withdrawals.length)
  )

  return (
    <div className='policy-transactions__tab'>
      <PageContent>
        {!isEmpty && (
          <>
            <PolicyTransactionsTotals
              contributions={contributions}
              withdrawals={withdrawals}
            />

            <PageDivider />

            <PolicyTransactionsTable
              key={CONTRIBUTION}
              policyTransactions={contributions}
              policyTransactionType={CONTRIBUTION}
              readOnly={readOnly}
            />

            <PageDivider />

            <PolicyTransactionsTable
              key={WITHDRAWAL}
              policyTransactions={withdrawals}
              policyTransactionType={WITHDRAWAL}
              readOnly={readOnly}
            />
          </>
        )}
        <PolicyTransactionsEmpty hidden={!isEmpty} readOnly={readOnly} />
      </PageContent>
    </div>
  )
}

export { PolicyTransactionsTab }
