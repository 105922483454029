import T from 'prop-types'

import { BaseField } from '../BaseField'
import { DateInput } from '../../inputs'

/**
 * Used where date input is needed.
 */
const DateField = ({ value, handleChange, inputProps, flatpickrOptions, label, error, required, ...props }) => (
  <BaseField label={label} error={error} required={required}>
    <DateInput
      value={value}
      handleChange={handleChange}
      inputProps={inputProps}
      flatpickrOptions={flatpickrOptions}
      {...props}
    />
  </BaseField>
)

DateField.defaultProps = {
  inputProps: {},
  flatpickrOptions: {}
}

DateField.propTypes = {
  /**
   * Value for DateInput.
   */
  value: T.oneOfType([T.string, T.object]),
  /**
   * Function that receives new values from DateInput.
   */
  handleChange: T.func,
  /**
   * Props for DateInput.
   */
  inputProps: T.object,
  /**
   * Options for flatpickr used by DateInput.
   */
  flatpickrOptions: T.object,
  /**
   * Label for the field.
   */
  label: T.string,
  /**
   * Error from Formik.
   */
  error: T.string
}

export { DateField }
