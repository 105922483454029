import constants from '@elenfs/elen-constants'

import { ImportSyncButton } from '../ImportSyncButton'
import syncP1Gql from '../../../graphql/mutations/dataImport/syncP1.graphql'

const { DataImportTypes } = constants.dataImport

const P1SyncButton = (props) => (
  <ImportSyncButton
    dataImportType={DataImportTypes.P1}
    gqlMutation={syncP1Gql}
    {...props}
  />
)

export { P1SyncButton }
