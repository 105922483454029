import { DashboardCardTitle } from '../DashboardCardTitle'
import { DashboardClientsList } from '../DashboardClientsList'

import './LastVisitedClientsCard.less'

const LastVisitedClientsCard = ({ clientList, ActionComponent }) => (
  <div className='last-visited-clients-dashboard-card'>
    <DashboardCardTitle> Last visited </DashboardCardTitle>
    <DashboardClientsList
      clientList={clientList}
      withInitials
      ActionComponent={ActionComponent}
      emptyListText='No clients recently visited.'
    />
  </div>
)

export { LastVisitedClientsCard }
