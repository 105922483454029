import SecureSection from './SecureSection'
import { SubscribePage } from '../../authorization'
import { WaitingPage } from '../../authorization/WaitingPage'

import { PaywallPage } from '../../authorization/PaywallPage'
import { withPaymentProvider } from '../../hoc/container'
import { Spinner } from '..'

const PaymentContainer = ({
  loading,
  waiting,
  hasActiveSubscription,
  SubscribePageProps,
  SecureSectionProps,
  isAccountOwner,
  error
}) => {
  if (loading) return <Spinner />

  if (waiting) return <WaitingPage />

  if (!hasActiveSubscription) {
    if (isAccountOwner) return <SubscribePage {...SubscribePageProps} />

    return <PaywallPage />
  }

  return <SecureSection {...SecureSectionProps} />
}

const PaymentWithPaymentProvider = withPaymentProvider()(
  PaymentContainer
)

export { PaymentWithPaymentProvider as PaymentContainer }
