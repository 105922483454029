"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RepaymentTypes = exports.RateTypes = exports.PlanStatuses = exports.MortgageTypesLabels = exports.MortgageTypes = exports.LifeCoverInPlaceValues = exports.CICInPlaceValues = exports.AccountHolders = void 0;
const AccountHolders = exports.AccountHolders = Object.freeze({
  CLIENT: 'CLIENT',
  JOINT: 'JOINT',
  PARTNER: 'PARTNER'
});
const CICInPlaceValues = exports.CICInPlaceValues = Object.freeze({
  BOTH_LIVES_PARTIALLY_COVERED: 'BOTH_LIVES_PARTIALLY_COVERED',
  LIFE_1_ONLY: 'LIFE_1_ONLY',
  LIFE_1_PARTIALLY_COVERED_ONLY: 'LIFE_1_PARTIALLY_COVERED_ONLY',
  LIFE_2_ONLY: 'LIFE_2_ONLY',
  LIFE_2_PARTIALLY_COVERED_ONLY: 'LIFE_2_PARTIALLY_COVERED_ONLY',
  NO: 'NO',
  YES: 'YES'
});
const LifeCoverInPlaceValues = exports.LifeCoverInPlaceValues = Object.freeze({
  BOTH_LIVES_PARTIALLY_COVERED: 'BOTH_LIVES_PARTIALLY_COVERED',
  LIFE_1_ONLY: 'LIFE_1_ONLY',
  LIFE_1_PARTIALLY_COVERED_ONLY: 'LIFE_1_PARTIALLY_COVERED_ONLY',
  LIFE_2_ONLY: 'LIFE_2_ONLY',
  LIFE_2_PARTIALLY_COVERED_ONLY: 'LIFE_2_PARTIALLY_COVERED_ONLY',
  NO: 'NO',
  YES: 'YES'
});
const MortgageTypes = exports.MortgageTypes = Object.freeze({
  BUY_TO_LET: 'BUY_TO_LET',
  BRIDGING_LOAN: 'BRIDGING_LOAN',
  COMMERCIAL: 'COMMERCIAL',
  HOME_REVERSION_PLAN: 'HOME_REVERSION_PLAN',
  LIFETIME_MORTGAGE: 'LIFETIME_MORTGAGE',
  OTHER: 'OTHER',
  RESIDENTIAL: 'RESIDENTIAL',
  SECOND_CHARGE: 'SECOND_CHARGE',
  UNKNOWN_CASH_CALC: 'UNKNOWN_CASH_CALC'
});
const PlanStatuses = exports.PlanStatuses = Object.freeze({
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  LAPSED: 'LAPSED',
  LIVE: 'LIVE',
  NTU: 'NTU',
  OFFERED: 'OFFERED',
  REPAID: 'REPAID'
});
const RepaymentTypes = exports.RepaymentTypes = Object.freeze({
  INTEREST_ONLY: 'INTEREST_ONLY',
  REPAYMENT: 'REPAYMENT',
  REPAYMENT_AND_INTEREST_ONLY: 'REPAYMENT_AND_INTEREST_ONLY'
});
const RateTypes = exports.RateTypes = Object.freeze({
  FIXED: 'FIXED',
  OFFSET: 'OFFSET',
  TRACKER: 'TRACKER',
  VARIABLE: 'VARIABLE'
});
const MortgageTypesLabels = exports.MortgageTypesLabels = Object.freeze({
  [MortgageTypes.BUY_TO_LET]: 'Buy to Let',
  [MortgageTypes.BRIDGING_LOAN]: 'Bridging Loan',
  [MortgageTypes.COMMERCIAL]: 'Commercial',
  [MortgageTypes.HOME_REVERSION_PLAN]: 'Home Reversion Plan',
  [MortgageTypes.LIFETIME_MORTGAGE]: 'Lifetime Mortgage',
  [MortgageTypes.OTHER]: 'Other',
  [MortgageTypes.RESIDENTIAL]: 'Residential',
  [MortgageTypes.SECOND_CHARGE]: 'Second Charge',
  [MortgageTypes.UNKNOWN_CASH_CALC]: 'Unknown (CashCalc)'
});
var _default = exports.default = {
  AccountHolders,
  CICInPlaceValues,
  LifeCoverInPlaceValues,
  MortgageTypes,
  PlanStatuses,
  RepaymentTypes,
  RateTypes,
  MortgageTypesLabels
};