import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import T from 'prop-types'

import InvitationSignupForm from '../InvitationSignupForm'
import { Spinner } from '../../misc'
import { TokenError } from './TokenError/TokenError'
import LoggedOutLayout from '../../misc/LoggedOutLayout'

import './InvitationSignupPage.less'

const InvitationSignupPage = ({ error, ...restProps }) => (
  <LoggedOutLayout>
    <div className='invitation-signup'>
      {error ? <TokenError /> : <InvitationSignupForm {...restProps} />}
    </div>
  </LoggedOutLayout>
)

InvitationSignupPage.propTypes = {
  error: T.object
}

const InvitationSignupPageContainer = ({ query, queryResultPath, ...restProps }) => {
  const { invitationToken } = useParams()

  const {
    data,
    error,
    loading
  } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      invitationToken
    }
  })

  if (loading && !data) {
    return <Spinner />
  }

  const user = data ? data[queryResultPath] : null

  return (
    <InvitationSignupPage
      error={error}
      {...user}
      {...restProps}
    />
  )
}

InvitationSignupPageContainer.propTypes = {
  query: T.object.isRequired,
  queryResultPath: T.string.isRequired,
  formLoginType: T.string.isRequired,
  formMutation: T.object.isRequired,
  formValidationSchema: T.object.isRequired
}

export default InvitationSignupPageContainer
