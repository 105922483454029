/**
 * Events that can be emitted by FileUploadManager.
 */
const EVENTS = Object.freeze({
  // Uploading of a single file has started.
  UPLOAD_START: 'UPLOAD_START',
  // Uploading of a single file has succeeded.
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  // Uploading of a single file has failed.
  UPLOAD_FAILURE: 'UPLOAD_FAILURE',
  // All uploads are complete (regardless of the results).
  COMPLETE: 'COMPLETE'
})

/**
 * Possible statuses of an individual file upload.
 */
const UPLOAD_STATUSES = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
})

/**
 * Utility for uploading files and keeping track of upload progress and results.
 * TODO: when there are requirements for tracking upload progress, replace
 * `fetch` with `axios`. Because `fetch` does NOT provide the possibility to
 * track upload progress.
 */
export default class FileUploadManager {
  /**
   * @constructor
   * @param {Function} fetch - Fetch implementation.
   */
  constructor (fetch) {
    this.EVENTS = EVENTS
    this._fetch = fetch

    this._listeners = Object.values(EVENTS).reduce((acc, curr) => ({
      ...acc,
      [curr]: []
    }), {})

    this._uploads = new Map()

    this._controller = new window.AbortController()
  }

  /**
   * Sets up a function that will be called whenever the specified event is emitted.
   * @param {string} event - Event name.
   * @param {Function} listener - Function to be called when the event is emitted.
   */
  on (event, listener) {
    this._listeners[event].push(listener)
  }

  /**
   * Removes a listener previously registered with `on`.
   * @param {string} event - Event name.
   * @param {Function} listener - Listener to remove.
   */
  off (event, listener) {
    const eventListeners = this._listeners[event]
    const index = eventListeners.indexOf(listener)
    if (index > -1) {
      eventListeners.splice(index, 1)
    }
  }

  /**
   * Starts uploading of the given file to the given URL.
   * @param {string} url - URL for uploading file.
   * @param {File} file - File that must be uploaded.
   * @returns {Promise} - Promise that resolves when uploading is completed.
   */

  cancel () {
    this._controller.abort()
  }

  upload (url, file) {
    if (typeof url !== 'string') {
      return Promise.reject(new Error('"url" argument must be of type string'))
    }

    if (!(file instanceof window.File)) {
      return Promise.reject(new Error('"file" argument must be of type File'))
    }

    this._controller = new window.AbortController()

    const reqOptions = {
      method: 'PUT',
      body: file,
      signal: this._controller.signal
    }

    const uploadPromise = this._fetch(url, reqOptions)
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            `Failed to upload file. Server responded with status ${res.status}`
          )
        }
        return res.text()
      })
      .then((data) => {
        const upload = this._uploads.get(file)
        upload.status = UPLOAD_STATUSES.SUCCESS
        upload.response = data

        this._emit(EVENTS.UPLOAD_SUCCESS, {
          file,
          response: data
        })

        return data
      })
      .catch((error) => {
        const upload = this._uploads.get(file)
        upload.status = UPLOAD_STATUSES.FAILURE
        upload.error = error

        this._emit(EVENTS.UPLOAD_FAILURE, {
          file,
          error
        })

        throw error
      })
      .finally(() => {
        this._emitIfAllCompleted()
      })

    this._uploads.set(file, {
      status: UPLOAD_STATUSES.IN_PROGRESS,
      uploadPromise
    })

    this._emit(EVENTS.UPLOAD_START, { file })

    return uploadPromise
  }

  _emitIfAllCompleted () {
    const isAllCompleted = ![...this._uploads.values()].find(upload =>
      upload.status === UPLOAD_STATUSES.IN_PROGRESS)

    if (isAllCompleted) {
      this._emit(EVENTS.COMPLETE)
    }
  }

  _emit (event, payload = {}) {
    this._listeners[event].forEach(fn => fn({
      type: event,
      details: payload
    }))
  }
}
