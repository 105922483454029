import { Heading } from '../../../misc'
import { EmailIntegrationList } from '../EmailIntegrationList'

import './EmailPage.less'

const EmailPage = ({ onConnect, list, loading }) => {
  return (
    <div className='email-page'>
      <div className='email-page__description'>
        <Heading as='h3'>Connect your email account</Heading>
        <span className='email-page__description__sub-title'>
          Select your email provider.
        </span>
      </div>

      <EmailIntegrationList
        loading={loading}
        list={list.map(item => ({
          ...item,
          onItemClick: () => onConnect(item?.platform)
        }))}
      />
    </div>
  )
}

export { EmailPage }
