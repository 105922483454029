import T from 'prop-types'

const ImportPlatformLayoutLeftColumn = ({ children }) => (
  <div className='import-platform-layout__left-col'>
    <div
      className='import-platform-layout__left-col__content'
    >
      {children}
    </div>
  </div>
)

ImportPlatformLayoutLeftColumn.propTypes = {
  children: T.node
}

export default ImportPlatformLayoutLeftColumn
