import T from 'prop-types'

import { CloseIconBig } from '../../icons'

export const CustomerSupportHeader = ({ onExClick }) => (
  <div className='customer-support-header'>
    <h2>
      Customer support
    </h2>
    <div className='ex-icon-container'>
      <CloseIconBig onClick={onExClick} />
    </div>
  </div>
)

CustomerSupportHeader.propTypes = {
  onExClick: T.func.isRequired
}
