import { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import constants from '@elenfs/elen-constants'

import unreadMessagesCountGql from '../../../../graphql/queries/messages/unreadMessagesCount.graphql'
import unreadMessagesCountClientPortalGql from '../../../../graphql/queries/messages/unreadMessagesCountClientPortal.graphql'
import unreadMessagesCountUpdateGql from '../../../../graphql/subscriptions/unreadMessagesCountUpdate.graphql'
import unreadMessagesCountUpdateClientPortalGql from '../../../../graphql/subscriptions/unreadMessagesCountUpdateClientPortal.graphql'

const withUnreadMessagesSubscription = () => WrappedComponent => props => {
  const isClientPortal = props?.currentUser?.type === constants.user.UserTypes.CLIENT

  const { data, subscribeToMore } = useQuery(isClientPortal ? unreadMessagesCountClientPortalGql : unreadMessagesCountGql)
  const unreadMessagesCount = data?.unreadMessagesCount || []

  const subscribeForUnreadMessages = useCallback(
    () =>
      subscribeToMore({
        document: isClientPortal ? unreadMessagesCountUpdateClientPortalGql : unreadMessagesCountUpdateGql,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          return {
            unreadMessagesCount: subscriptionData.data.unreadMessagesCountUpdate
          }
        },
        // TODO: Handle the error case
        onError: err => console.log({ err })
      }),
    [subscribeToMore, isClientPortal]
  )

  const totalUnreadMessages = useMemo(
    () => unreadMessagesCount.map(({ count }) => count).reduce((acc, t) => acc + t, 0),
    [unreadMessagesCount]
  )

  return (
    <WrappedComponent
      {...{
        ...props,
        unreadMessagesCount,
        subscribeForUnreadMessages,
        totalUnreadMessages
      }}
    />
  )
}

export { withUnreadMessagesSubscription }
