import T from 'prop-types'
import '../ImportJobSummary.less'

const ImportError = ({ heading, description }) => (
  <div className='import-job-summary__log__error'>
    <p className='import-job-summary__log__error__heading'>
      <span data-exclamation-mark>!</span>
      {heading}
    </p>
    <p className='import-job-summary__log__error__desc'>
      {description}
    </p>
  </div>
)

ImportError.propTypes = {
  heading: T.node.isRequired,
  description: T.node.isRequired
}

export default ImportError
