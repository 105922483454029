export const options = {
  borderWidth: 0,
  cutout: '68%',
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  },
  maintainAspectRatio: false
}

export const emptyData = {
  labels: [],
  datasets: [
    {
      data: [1],
      backgroundColor: ['#C5D0DE']
    }
  ]
}
