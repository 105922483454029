import { ActivityLogEntryTypes } from '@elenfs/elen-constants'

import { ActivityLogLayout } from './ActivityLogLayout'
import { AuditLogLayout } from './AuditLogLayout'

import './AuditLogEntryModal.less'

const { AUDIT_TRAIL } = ActivityLogEntryTypes

const AuditLogEntryModal = props => {
  const type = props?.auditLogEntry?.type
  const className = 'audit-log-entry-modal'

  if (type === AUDIT_TRAIL) {
    return <AuditLogLayout {...{ className, ...props }} />
  }

  return <ActivityLogLayout {...{ className, ...props }} />
}

export { AuditLogEntryModal }
