import { withFormik } from 'formik'
import { Navigate } from 'react-router-dom'
import T from 'prop-types'
import constants from '@elenfs/elen-constants'
import appConfig from '../../../appConfig'

import { Form } from '../../form'
import FormErrorMessages from '../../form/FormErrorMessages'
import { InputField, CheckboxField } from '../../form/fields'
import { Button, LoadingMessage, Heading } from '../../misc'

import { CLIENT_AGREEMENTS_ARR, USER_AGREEMENTS_ARR } from './const'

import './InvitationSignupForm.less'

const { UserTypes } = constants.user
const {
  SAAS_AGREEMENT_LINK,
  DATA_PROCESSING_AGREEMENT_LINK,
  PRIVACY_POLICY_LINK,
  CLIENT_TERMS_OF_SERVICE_LINK,
  ACCEPTABLE_USE_LINK
} = appConfig

const InvitationSignupForm = (props) => {
  const {
    handleSubmit,
    errors: validationErrors,
    loading,
    success,
    error: serverError,
    formRedirectUrl,
    email,
    firstName,
    values,
    formLoginType
  } = props

  if (success) {
    return <Navigate to={formRedirectUrl} />
  }

  const isClient = formLoginType === UserTypes.CLIENT

  const { password, confirmPassword, acceptPolicies } = values
  const disableBtn = loading || !password || !confirmPassword || !acceptPolicies

  return (
    <Form className='client-signup-form' onSubmit={handleSubmit}>
      <Heading as='h2'>{`Hi, ${!firstName ? '' : firstName}`}</Heading>

      {isClient &&
        <p>
          All you need to do is set up your account details, in order to access your information. Once you’re in, you will
          be able to access the personal data, policy details and documents shared with your Financial Adviser.
        </p>}

      <InputField
        name='email'
        label='Email'
        value={email}
        inputProps={{ type: 'email', readOnly: true }}
      />

      <div className='password-field'>
        <InputField
          name='password'
          label='Create a password'
          inputProps={{ type: 'password' }}
        />
        <span className='password-info'>
          Minimum of 8 characters, with <br /> a mixture of numbers, letters and symbols
        </span>
      </div>

      <InputField
        name='confirmPassword'
        label='Re-enter password'
        inputProps={{ type: 'password' }}
      />

      <div className='client-signup-form__accept-policy'>
        <CheckboxField
          name='acceptPolicies'
          inputId='accept-policies-checkbox}'
          label={
            <p>
              I agree with
              {isClient
                ? (
                  <a href={CLIENT_TERMS_OF_SERVICE_LINK} target='_blank' rel='noreferrer'>
                    {' '}
                    Elen Terms of Service
                  </a>
                  )
                : (
                  <>
                    <a href={SAAS_AGREEMENT_LINK} target='_blank' rel='noreferrer'>
                      {' '}
                      Elen SaaS Agreement
                    </a>
                    ,
                    <a href={DATA_PROCESSING_AGREEMENT_LINK} target='_blank' rel='noreferrer'>
                      {' '}
                      Data Processing Agreement
                    </a>
                  </>
                  )}
              ,
              <a href={ACCEPTABLE_USE_LINK} target='_blank' rel='noreferrer'>
                {' '}
                Acceptable Use Policy{' '}
              </a>
              and
              <a href={PRIVACY_POLICY_LINK} target='_blank' rel='noreferrer'>
                {' '}
                Privacy Policy
              </a>
              .
            </p>
          }
        />
      </div>

      <FormErrorMessages
        serverError={serverError}
        serverErrorMessage='Something went wrong, please try again'
        validationErrors={validationErrors}
      />

      {loading && <LoadingMessage text='Loading...' />}

      <Button disabled={disableBtn} color={Button.Colors.BLUE} type='submit'>
        Get started
      </Button>
    </Form>
  )
}

InvitationSignupForm.propTypes = {
  firstName: T.string.isRequired,
  email: T.string.isRequired,
  handleSubmit: T.func.isRequired,
  formLoginType: T.string.isRequired,
  errors: T.object,
  dirty: T.bool,
  loading: T.bool,
  success: T.bool,
  error: T.string,
  formRedirectUrl: T.string
}

InvitationSignupForm.defaultProps = {
  formRedirectUrl: '/clients'
}

const InvitationSignupFormEnhanced = withFormik({
  validationSchema: ({ formValidationSchema }) => formValidationSchema,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: ({ password, acceptPolicies }, { props }) => {
    const { handleUserLogin, formLoginType } = props
    const isClient = formLoginType === UserTypes.CLIENT

    if (acceptPolicies) {
      const agreements = (isClient ? CLIENT_AGREEMENTS_ARR : USER_AGREEMENTS_ARR).map(
        type => ({ type, isAccepted: true })
      )
      handleUserLogin(password, agreements)
    }
  }
})(InvitationSignupForm)

InvitationSignupFormEnhanced.propTypes = {
  formValidationSchema: T.object.isRequired,
  handleUserLogin: T.func.isRequired
}

export default InvitationSignupFormEnhanced
