const PrintIcon = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M16.36 3c.852 0 1.546.694 1.546 1.547v2.39h1.196A1.9 1.9 0 0121 8.837v6.61a1.9 1.9 0 01-1.898 1.898h-1.196v2.11c0 .852-.694 1.546-1.547 1.546H7.641a1.549 1.549 0 01-1.547-1.547v-2.11H4.898A1.9 1.9 0 013 15.446v-6.61a1.9 1.9 0 011.898-1.898h1.196v-2.39C6.094 3.694 6.788 3 7.64 3zm-.141 10.688H7.78v5.624h8.438v-5.625zm2.883-5.063H4.898a.211.211 0 00-.21.21v6.61c0 .117.094.211.21.211h1.196v-2.812c0-.466.378-.844.843-.844h10.125c.466 0 .844.378.844.844v2.812h1.196c.116 0 .21-.094.21-.21v-6.61a.211.211 0 00-.21-.211zM6.75 9.75a.75.75 0 110 1.5.75.75 0 010-1.5zm9.469-5.063H7.78v2.25h8.438v-2.25z'
        fill='currentColor'
      />
    </g>
  </svg>
)

export { PrintIcon }
