import {
  GoBackHeader,
  EmptyPage,
  ErrorMessage,
  Spinner,
  Filter,
  DownloadReportBtn
} from '../../../misc'
import { SummaryCardNBR } from '../SummaryCardNBR'
import { ReportTable } from '../../ReportTable'

import './NBRPage.less'
import './NBRTable.less'

const NBRPage = props => {
  const {
    filterProps,
    loading,
    error,
    called,
    summary,
    downloadBtnProps,
    tableProps
  } = props

  const { rows } = tableProps

  return (
    <div className='nbr'>
      <GoBackHeader title='New Business Report' />

      <Filter loading={loading} {...filterProps} />

      {loading && <Spinner />}

      {!rows.length && called && !loading && !error && (
        <EmptyPage text='No records found' hidden={false} />
      )}

      {error && <ErrorMessage text='Failed to load records' />}

      {!!rows.length && (
        <>
          <DownloadReportBtn
            className='nbr__download-btn'
            {...downloadBtnProps}
          />

          <div className='table-wrap'>
            <div className='nbr__summary-container'>
              <SummaryCardNBR summary={summary} />
            </div>

            <ReportTable {...tableProps} />
          </div>
        </>
      )}
    </div>
  )
}

export { NBRPage }
