import { useNavigate } from 'react-router-dom'
import T from 'prop-types'

import { PlusIcon } from '../../icons'
import { ImportClientModal, DropdownMenu, Button } from '../../misc'

import { useImportClientCashCalcModal } from '../../../hooks'

import './CreateClientButton.less'

const ImportCashCalcClientModalTrigger = ({ refetchClients }) => (
  <ImportClientModal.Trigger
    as={DropdownMenu.Item}
    modalProps={{
      importClientModalHook: useImportClientCashCalcModal,
      refetch: refetchClients
    }}
  >
    Import from CashCalc
  </ImportClientModal.Trigger>
)

const CreateClientButton = ({
  refetchClients,
  isCashCalcConnectionActive,
  direction,
  buttonColor,
  responsive
}) => {
  const navigate = useNavigate()
  const navigateToNewIndividualClient = () => navigate('/secure/clients/create/individual')
  const navigateToNewCorporateClient = () => navigate('/secure/clients/create/corporate')

  return (
    <div className='create-client-button'>
      <DropdownMenu
        direction={direction}
        trigger={
          <Button
            responsive={responsive}
            color={buttonColor || Button.Colors.BLUE}
          >
            <PlusIcon className='create-client-button__plus-icon' />
            <span>New Client</span>
          </Button>
        }
      >
        <DropdownMenu.List>
          <DropdownMenu.Item onClick={navigateToNewIndividualClient}>
            Individual
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={navigateToNewCorporateClient}>
            Corporate
          </DropdownMenu.Item>
          {isCashCalcConnectionActive && (
            <ImportCashCalcClientModalTrigger refetchClients={refetchClients} />
          )}
        </DropdownMenu.List>
      </DropdownMenu>
    </div>
  )
}

CreateClientButton.defaultProps = {
  direction: DropdownMenu.Directions.TOP
}

CreateClientButton.propTypes = {
  refetchClients: T.func,
  isCashCalcConnectionActive: T.bool,
  direction: T.string
}

export { CreateClientButton }
