import { useState, useRef, Fragment, useEffect } from 'react'
import { always, ifElse, invoker, pipe, tap, unless } from 'ramda'
import { extractFile, isValidFormat, handleValidFormat } from './helpers'
import classnames from 'classnames'
import T from 'prop-types'

import { DownloadIcon } from '../../icons'
import ErrorMessage from '../ErrorMessage'
import { DeleteImageIcon } from './DeleteImageIcon'
import { VALID_FORMATS } from './const'

import './UploadImageWithPreview.less'

const UploadImageWithPreview = props => {
  const {
    onChooseFile,
    mutationError,
    mutationLoading,
    title,
    description,
    recommendedSize,
    onRemoveFile,
    className,
    logo
  } = props
  const inputRef = useRef()

  const [imageSrc, setImageSrc] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    if (logo?.logoUrl) {
      setImageSrc(logo.logoUrl)
    }
  }, [logo?.logoUrl])

  const onDropOrChangeImage = pipe(
    tap(invoker(0, 'preventDefault')),
    unless(
      always(!!imageSrc),
      pipe(
        tap(() => setError(null)),
        extractFile,
        ifElse(
          isValidFormat,
          handleValidFormat(onChooseFile, setImageSrc),
          () => setError('Invalid format')
        )
      )
    )
  )

  const onRemoveImage = async () => {
    setError(null)
    try {
      await onRemoveFile()
      inputRef.current.value = null
    } catch (error) {}
    setImageSrc(null)
  }

  const errorMessage = mutationError || error

  return (
    <div className={classnames('upload-image-with-review', className)}>
      <h3 className='upload-image-with-review__title'> {title} </h3>

      <div className='upload-image-with-review__container'>
        <label
          className={classnames(
            'upload-image-with-review__container__image-box',
            {
              'upload-image-with-review__container__image-box--preview-mode': imageSrc,
              'upload-image-with-review__container__image-box--loading': mutationLoading
            }
          )}
          htmlFor='upload-image-with-preview-input'
          onDragOver={invoker(0, 'preventDefault')}
          onDrop={onDropOrChangeImage}
        >
          {errorMessage && <ErrorMessage text={errorMessage} />}

          {imageSrc
            ? (
              <div className='upload-image-with-review__container__image-box__image-container'>
                <DeleteImageIcon onRemoveImage={onRemoveImage} />
                <img src={imageSrc} />
              </div>
              )
            : (
              <div className='upload-image-with-review__container__image-box__description'>
                <div className='upload-image-with-review__container__image-box__description__icon-wrapper'>
                  <DownloadIcon />
                </div>

                <div className='upload-image-with-review__container__image-box__description__label'>
                  Drag & Drop file or
                </div>

                <div className='upload-image-with-review__container__image-box__description__highlighted'>
                  Browse file
                </div>
              </div>
              )}
        </label>

        <input
          type='file'
          hidden
          id='upload-image-with-preview-input'
          onChange={onDropOrChangeImage}
          disabled={imageSrc}
          ref={inputRef}
        />
        <div className='upload-image-with-review__container__description'>
          <div> {description} </div>
          <div>
            <div>
              Recommended size: <b>{recommendedSize.join(' x ')}.</b>
            </div>
            <div>
              File type:{' '}
              {VALID_FORMATS.map((type, i) => (
                <Fragment key={type}>
                  <b>{type?.toUpperCase()}</b>
                  {VALID_FORMATS.length > 1 && i === VALID_FORMATS.length - 2 && ' or '}
                  {VALID_FORMATS.length > 1 && i < VALID_FORMATS.length - 2 && ', '}
                </Fragment>
              ))}
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

UploadImageWithPreview.defaultProps = {
  recommendedSize: [],
  onChooseFile: () => {}
}

UploadImageWithPreview.propTypes = {
  onChooseFile: T.func.isRequired,
  mutationError: T.string,
  mutationLoading: T.bool,
  title: T.string,
  description: T.node,
  recommendedSize: T.arrayOf(T.string)
}

export { UploadImageWithPreview }
