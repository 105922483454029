import { useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import receivedIncome from '../../../graphql/queries/income/receivedIncome.graphql'
import receivedIncomeTotalGql from '../../../graphql/queries/income/receivedIncomeTotal.graphql'
import { INCOME_TYPES } from '../../../constants'

import { ReceivedIncome } from './ReceivedIncome'
import { ErrorMessage } from '../../misc'
import { paginationLimit } from '../constant'

const ReceivedIncomeContainer = () => {
  const [currentFilter, setCurrentFilter] = useState()
  const [incomeRecords, setIncomeRecords] = useState([])

  const [paginationData, setPaginationData] = useState({
    page: 0,
    perPage: 1,
    count: null
  })

  const limit = paginationLimit
  const pageCount = Math.ceil(paginationData?.count / limit)

  const handleOnCompleteCallback = (data) => {
    const { result, ...rest } = data?.companyIncome
    setIncomeRecords(data?.companyIncome?.result)
    setPaginationData((prevState) => ({
      ...prevState, ...rest
    }))
  }

  const [getReceivedIncomes, {
    loading,
    error,
    data,
    called,
    fetchMore
  }] = useLazyQuery(
    receivedIncome, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onCompleted: (data) => handleOnCompleteCallback(data)
    }
  )

  const [getReceivedIncomesTotal, {
    loading: incomeTotalLoading,
    error: incomeTotalError,
    data: incomeTotalData,
    called: incomeTotalCalled,
    refetch
  }] = useLazyQuery(
    receivedIncomeTotalGql, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  const incomeTotal = incomeTotalData?.companyIncomeTotal || {}
  const initialLoading = loading && !data

  const onFilter = useCallback(
    filter => {
      getReceivedIncomes({ variables: { limit, offset: 0, ...filter } })
      getReceivedIncomesTotal({ variables: filter })
      setCurrentFilter(filter)
    },
    [getReceivedIncomes, getReceivedIncomesTotal, setCurrentFilter]
  )

  const handlePaginationChange = useCallback(
    (page) => {
      const offset = Math.ceil(Math.abs(limit * page))
      fetchMore({
        variables: {
          limit,
          offset,
          ...currentFilter
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.companyIncome) return prev
          return { ...prev, ...fetchMoreResult }
        }
      })
      refetch(currentFilter)
    },
    [fetchMore, currentFilter]
  )

  if (error || incomeTotalError) {
    return <ErrorMessage text='Failed to load pending income' />
  }

  return (
    <ReceivedIncome
      {...{
        loading: initialLoading,
        incomeRecords,
        incomeTotal,
        incomeTotalCalled,
        incomeTotalLoading,
        pageCount,
        handlePaginationChange,
        incomeType: INCOME_TYPES.RECEIVED,
        onFilter,
        currentFilter,
        called
      }}
    />
  )
}

export { ReceivedIncomeContainer }
