const UncheckedIcon = (props) => (
  <svg viewBox='0 0 24 24' width={20} height={20} {...props}>
    <path
      fill='#FFF'
      fillRule='evenodd'
      stroke='#DFE3E9'
      d='M19 2.5H5c-.69035594 0-1.31535594.27982203-1.76776695.73223305C2.77982203 3.68464406 2.5 4.30964406 2.5 5v14c0 .6903559.27982203 1.3153559.73223305 1.767767C3.68464406 21.220178 4.30964406 21.5 5 21.5h14c.6903559 0 1.3153559-.279822 1.767767-.732233C21.220178 20.3153559 21.5 19.6903559 21.5 19V5c0-.69035594-.279822-1.31535594-.732233-1.76776695C20.3153559 2.77982203 19.6903559 2.5 19 2.5z'
    />
  </svg>
)

export default UncheckedIcon
