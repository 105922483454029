import { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { add } from 'ramda'

import { FilterIncomeEntriesForm } from '../../FilterIncomeEntriesForm'
import { PendingIncomeEmpty } from '../../PendingIncome/PendingIncomeEmpty'
import Spinner from './../../../misc/Spinner/Spinner'
import { PendingIncomeTable } from './PendingIncomeTable'
import { DownloadReportMenu } from '../../DownloadReportMenu'

import { formatMoney } from './../../../../helpers/policy'
import { useCurrentUser } from '../../../../hooks'
import { Pagination } from '../../../misc'

import reconcileGql from '../../../../graphql/mutations/income/reconcile.graphql'
import unreconcileGql from '../../../../graphql/mutations/income/unreconcile.graphql'

const PendingIncomeTableContainer = props => {
  const {
    loading,
    incomeRecords,
    incomeType,
    onFilter,
    currentFilter,
    called,
    incomeTotal,
    incomeTotalCalled,
    incomeTotalLoading,
    pageCount,
    handlePaginationChange,
    ...otherProps
  } = props

  const [totalReceived, setTotalReceived] = useState(0)
  const [reconcile, { loading: reconcileLoading }] = useMutation(reconcileGql)
  const [unreconcile, { loading: unreconcileLoading }] = useMutation(
    unreconcileGql
  )

  const { currency } = useCurrentUser()

  const onToggleReconciled = useCallback((incomeRecord, actualAmountToSet) => {
    const input = { id: incomeRecord.id }

    if (incomeRecord.actualAmount) {
      setTotalReceived(current => (current -= actualAmountToSet.amount))
      return unreconcile({
        variables: { input },
        optimisticResponse: {
          unreconcileIncomeRecord: {
            ...incomeRecord,
            actualAmount: null
          }
        }
      })
    }

    setTotalReceived(add(actualAmountToSet.amount))
    return reconcile({
      variables: {
        input: {
          ...input,
          amount: actualAmountToSet.amount
        }
      },
      optimisticResponse: {
        reconcileIncomeRecord: {
          ...incomeRecord,
          actualAmount: {
            __typename: 'Money',
            ...actualAmountToSet
          }
        }
      }
    })
  }, [])

  const amount = useMemo(() => formatMoney(incomeTotal), [incomeTotal])

  const hasIncomeRecords = useMemo(
    () => incomeRecords && incomeRecords.length > 0,
    [incomeRecords]
  )

  const mutationLoading = useMemo(
    () => reconcileLoading || unreconcileLoading,
    [reconcileLoading, unreconcileLoading]
  )

  return (
    <div className='pending-income__wrapper'>
      <div className='pending-income__sticky-content'>
        <FilterIncomeEntriesForm {...{ summary: amount, onSubmit: onFilter }} />

        <div className='pending-income__dl-btn-container'>
          <DownloadReportMenu
            values={currentFilter}
            disabled={loading || !hasIncomeRecords}
            incomeType={incomeType}
          />
        </div>

        {hasIncomeRecords && (
          <div className='pending-income__total-received'>
            Total received:
            <span>
              {formatMoney({ amount: totalReceived, currency })}
            </span>
          </div>
        )}
      </div>

      <div className='pending-income__content'>
        {loading && <Spinner />}

        {!loading && hasIncomeRecords && (
          <PendingIncomeTable
            {...{
              incomeRecords,
              onToggleReconciled,
              mutationLoading,
              ...otherProps
            }}
          />
        )}

        {!loading && !hasIncomeRecords && called && incomeTotalCalled && (
          <PendingIncomeEmpty text='There are no results for this search' />
        )}
      </div>

      {!incomeTotalLoading && !loading && hasIncomeRecords && pageCount > 1 && (
        <div className='pending-income__pagination'>
          <Pagination pageCount={pageCount} onPageChange={handlePaginationChange} />
        </div>
      )}
    </div>
  )
}

export { PendingIncomeTableContainer }
