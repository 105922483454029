import T from 'prop-types'
import classnames from 'classnames'
import { values } from 'ramda'

import { IconPositions } from './const'
import { CloseXIcon } from '../../../icons'

import './WithIcon.less'

const withIcon = (WrappedComponent) => {
  const WithIcon = (props) => {
    const {
      icon: IconComponent,
      renderIcon,
      iconPosition,
      className,
      inputWrapperClassName,
      isTextArea,
      onIconClick,
      isClearable,
      handleReset,
      showClearIndicator,
      ...restProps
    } = props

    const inputClassName = classnames('input-with-icon__input', className)
    const wrapperClassName = classnames(
      'input-with-icon',
      `input-with-icon--${iconPosition}`,
      !showClearIndicator && 'input-with-icon--empty',
      isTextArea && 'input-with-icon--text-area',
      inputWrapperClassName
    )

    if (renderIcon || IconComponent) {
      return (
        <div className={wrapperClassName}>
          {
            renderIcon
              ? renderIcon()
              : <IconComponent
                  className={classnames('input-with-icon__icon', {
                    'input-with-icon__icon--empty': !showClearIndicator
                  })}
                  onClick={onIconClick}
                />
          }
          <WrappedComponent className={inputClassName} {...restProps} />
          {
            (isClearable && showClearIndicator) &&
              <CloseXIcon width={16} height={16} className='input-with-icon--clear-indicator' onClick={handleReset} />
          }
        </div>
      )
    }

    return <WrappedComponent className={className} {...restProps} />
  }

  WithIcon.propTypes = {
    renderIcon: T.func,
    icon: T.elementType,
    iconPosition: T.oneOf(values(IconPositions)),
    className: T.string,
    isTextArea: T.bool,
    inputWrapperClassName: T.string
  }

  WithIcon.defaultProps = {
    iconPosition: IconPositions.RIGHT
  }

  return WithIcon
}

export { withIcon }
