import appConfig from '../../../appConfig'

const { SUPPORT_EMAIL } = appConfig

const ImportPageNote = () => (
  <div className='import-page__note'>
    <p>Can’t see the platform you need?</p>
    <p>
      Please be advised that we will be integrating with more investment platforms soon.
      If you would like us to prioritise a particular platform, let us know!&nbsp;
      <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
    </p>
  </div>
)

export { ImportPageNote }
