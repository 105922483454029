import { assocPath, path } from 'ramda'

export const isPlainObject = val =>
  Object.prototype.toString.call(val) === '[object Object]'

export const getPropByPath = (pathStr, obj) =>
  path(pathStr.split('.'), obj)

export const setPropByPath = (pathStr, value, obj) =>
  assocPath(pathStr.split('.'), value, obj)

export const capitalizeFirstLetter = (someString) => {
  return someString?.charAt(0).toUpperCase() + someString?.slice(1).toLowerCase()
}

export const formatBoolean = (value) =>
  typeof value === 'boolean' ? (value ? 'Yes' : 'No') : ''
