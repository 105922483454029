import T from 'prop-types'

import GroupFormFields from '../../misc/GroupedFormField'
import { InputField, DateField, TextAreaField } from '../../form/fields'

const ClientDependantsFormInputRow = ({ index }) => (
  <div className='dependant-input-row'>
    <GroupFormFields>
      <InputField
        name={`dependants.${index}.firstName`}
        label='First name'
        required
      />

      <InputField
        name={`dependants.${index}.lastName`}
        label='Last name'
        required
      />
    </GroupFormFields>

    <GroupFormFields>
      <DateField
        name={`dependants.${index}.dateOfBirth`}
        label='Date of birth'
        inputProps={{
          placeholder: 'dd/mm/yyyy'
        }}
      />
    </GroupFormFields>

    <GroupFormFields>
      <InputField
        name={`dependants.${index}.relationship`}
        label='Relationship'
      />
    </GroupFormFields>

    <GroupFormFields>
      <DateField
        name={`dependants.${index}.dependantFrom`}
        label='Dependant from'
        inputProps={{
          placeholder: 'dd/mm/yyyy'
        }}
      />

      <DateField
        name={`dependants.${index}.dependantUntil`}
        label='Dependant until'
        inputProps={{
          placeholder: 'dd/mm/yyyy'
        }}
      />
    </GroupFormFields>

    <GroupFormFields className='grouped-field--full-width'>
      <TextAreaField
        name={`dependants.${index}.note`}
        label='Additional notes and comments'
        inputProps={{ rows: 3 }}
      />
    </GroupFormFields>
  </div>
)

ClientDependantsFormInputRow.propTypes = {
  index: T.number.isRequired
}

export { ClientDependantsFormInputRow }
