import { useEffect, useCallback } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { compose, findLast } from 'ramda'
import T from 'prop-types'

import { Spinner } from '../../misc'
import { ClientMessagesPage } from './ClientMessagesPage'
import {
  withCurrentUser,
  withNewMessagesSubscription,
  withClientUser
} from '../../hoc/container'

import updateMessageAsReadGql from '../../../graphql/mutations/messages/updateMessageAsRead.graphql'
import messageAttachmentGql from '../../../graphql/queries/message/messageAttachment.graphql'

const ClientMessagesPageContainer = props => {
  const { messages, messagesLoading: loading, subscribeForNewMessages, currentUser, isClientUser } = props

  const [updateMessageAsRead] = useMutation(updateMessageAsReadGql)

  useEffect(() => {
    if (messages.length) {
      const lastUnreadMessageToCurrentUser = findLast(
        message => message.toUserId === currentUser.id && !message.isRead
      )(messages)

      if (lastUnreadMessageToCurrentUser) {
        updateMessageAsRead({
          variables: { input: { messageId: lastUnreadMessageToCurrentUser.id } }
        })
      }
    }
  }, [messages])

  useEffect(() => {
    let unsubscribe
    if (subscribeForNewMessages) {
      unsubscribe = subscribeForNewMessages()
    }
    return () => unsubscribe && unsubscribe()
  }, [subscribeForNewMessages])

  const [downLoadFile] = useLazyQuery(messageAttachmentGql, {
    onCompleted: ({ messageAttachmentDownloadUrl }) => window.location.assign(messageAttachmentDownloadUrl)
  })

  const onDownloadFile = useCallback(id =>
    downLoadFile({ variables: { id } })
  )

  if (loading && !messages) {
    return <Spinner />
  }

  const isEmpty = !messages?.length

  return (
    <ClientMessagesPage
      {...{
        messages,
        currentUser,
        subscribeForNewMessages,
        isEmpty,
        isClientUser,
        onDownloadFile
      }}
    />
  )
}

ClientMessagesPageContainer.propTypes = {
  clientId: T.string.isRequired
}

const ClientMessagesPageContainerWithCurrentUser = compose(
  withClientUser(),
  withCurrentUser(),
  withNewMessagesSubscription()
)(ClientMessagesPageContainer)

const ClientPortalMessagesPageContainerWithCurrentUser = compose(
  withCurrentUser(),
  withNewMessagesSubscription()
)(ClientMessagesPageContainer)

export { ClientMessagesPageContainerWithCurrentUser as ClientMessagesPageContainer }
export { ClientPortalMessagesPageContainerWithCurrentUser as ClientPortalMessagesPageContainer }
