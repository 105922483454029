import { useCallback, useEffect } from 'react'
import T from 'prop-types'
import { useMutation } from '@apollo/client'

import { RemovePolicyTransactionButton } from './RemovePolicyTransactionButton'
import { parseGraphqlErrors } from '../../../../../helpers/error'

import removePolicyTransactionGql
  from '../../../../../graphql/mutations/policy/policyTransactions/removePolicyTransaction.graphql'
import policyTransactionsGql from '../../../../../graphql/queries/policy/policyTransactions.graphql'

const RemovePolicyTransactionButtonContainer = (props) => {
  const {
    closeTransactionModal,
    transactionId,
    policyId,
    setErrors,
    policyTransactionType,
    ...restProps
  } = props

  const [removeMutation, { called, loading, error }] = useMutation(removePolicyTransactionGql, {
    variables: {
      input: {
        id: transactionId,
        policyId
      }
    },
    onError: (err) => {
      setErrors(parseGraphqlErrors(
        err,
        `There was an error deleting the ${policyTransactionType}, please try again later`))
    },
    update: (cache, operationResult) => {
      const { id: removedInstanceId } = operationResult.data.removePolicyTransaction
      const { withdrawals, contributions } = cache.readQuery({
        query: policyTransactionsGql,
        variables: { policyId }
      })
      const filterFn = ({ id }) => id !== removedInstanceId

      cache.writeQuery({
        query: policyTransactionsGql,
        variables: { policyId },
        data: {
          withdrawals: withdrawals.filter(filterFn),
          contributions: contributions.filter(filterFn)
        }
      })
    }
  })

  useEffect(() => {
    if (called && !loading && !error) {
      closeTransactionModal()
    }
  }, [called, loading, error, closeTransactionModal])

  const removeTransactionCallback = useCallback(
    async (removeConfirmationFlag) => {
      if (removeConfirmationFlag) {
        await removeMutation()
      }
    }, [removeMutation]
  )

  return (
    <RemovePolicyTransactionButton
      removeTransactionCallback={removeTransactionCallback}
      policyTransactionType={policyTransactionType}
      {...restProps}
    />
  )
}

RemovePolicyTransactionButtonContainer.propTypes = {
  closeTransactionModal: T.func.isRequired,
  transactionId: T.string.isRequired,
  policyId: T.string.isRequired
}

export { RemovePolicyTransactionButtonContainer }
