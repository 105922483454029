import { useEffect, useMemo, useCallback } from 'react'
import { pipe, prop, propEq, map, ifElse, isNil, always } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { WorkflowActionStatuses } from '@elenfs/elen-constants'

import { ClientWorkflowInstancesCard } from './ClientWorkflowInstancesCard'

import { useWorkflowGql } from './../../../../hooks/useWorkflowGql'

const { COMPLETED } = WorkflowActionStatuses

const calcProgress = actions => {
  const totalActions = actions.length
  const completedActions = actions.filter(propEq('status', COMPLETED)).length
  return (completedActions / totalActions) * 100
}

const ClientWorkflowInstancesCardContainer = ({ clientId }) => {
  const navigate = useNavigate()

  const {
    fetchClientWorkflowInstances,
    fetchClientWorkflowInstancesData,
    fetchClientWorkflowInstancesCalled,
    fetchLoading
  } = useWorkflowGql()

  useEffect(() => {
    const shouldFetchWorkflowInstances =
      !fetchLoading && !fetchClientWorkflowInstancesCalled && clientId

    if (shouldFetchWorkflowInstances) {
      fetchClientWorkflowInstances({ variables: { clientId } })
    }
  }, [
    clientId,
    fetchClientWorkflowInstancesData,
    fetchClientWorkflowInstancesCalled,
    fetchLoading
  ])

  const workflowInstances = useMemo(
    () =>
      ifElse(
        isNil,
        always([]),
        pipe(
          prop('clientWorkflowInstances'),
          map(({ actions, ...rest }) => ({
            ...rest,
            progress: calcProgress(actions)
          }))
        )
      )(fetchClientWorkflowInstancesData),
    [fetchClientWorkflowInstancesData]
  )

  const onInstanceClick = useCallback(
    id => navigate(`/secure/clients/${clientId}/workflow-instance/${id}`),
    []
  )

  return (
    <ClientWorkflowInstancesCard
      {...{
        workflowInstances,
        onInstanceClick,
        fetchClientWorkflowInstances,
        clientId
      }}
    />
  )
}

export { ClientWorkflowInstancesCardContainer }
