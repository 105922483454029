import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  split
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createBrowserHistory } from 'react-router-dom'

import { getAuthToken, logout } from '../auth'
import AppConfig from '../appConfig'
import possibleTypes from './possibleTypes'

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    DataImportSource: {
      keyFields: ['type']
    }
  }
})

const httpLink = createHttpLink({
  uri: AppConfig.GRAPHQL_URL
})

const wsLink = new WebSocketLink({
  uri: AppConfig.GRAPHQL_URL.replace('http', 'ws'),
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const token = getAuthToken()
      return {
        token: token ? `Bearer ${token}` : ''
      }
    }
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ forward, networkError }) => {
  if (networkError && networkError.response && networkError.response.status === 401) {
    const token = getAuthToken()
    if (token) {
      logout()
      createBrowserHistory().push('/login')
    }
  }
})
const link = ApolloLink.from([errorLink, authLink, splitLink])

export const client = new ApolloClient({
  cache,
  link
})
