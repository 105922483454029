import T from 'prop-types'
import { last } from 'ramda'
import classnames from 'classnames'

import { getFileIcon } from '../../../../../helpers/clientDocument'
import { CloseModalIcon, ExclamationErrorIcon, RetryIcon } from '../../../../icons'
import Spinner from '../../../../misc/Spinner/Spinner'

const spinnerConfig = {
  lines: 7,
  length: 3,
  width: 3,
  radius: 4,
  position: 'relative'
}

const fileNameBaseClass = 'message-input__file__info__file-name'

const FileIcon = ({ fileName }) => {
  const Icon = getFileIcon(last(fileName.split('.')))
  return <Icon />
}

const AttachmentList = ({ attachments, onRemoveAttachment, fileRemoveLoading, uploadFile }) => (
  <>
    {attachments.map(
      ({ id, fileName, isLoading, isError, file, uploadUrl }, index) => (
        <div key={index} className='message-input__file-wrapper'>
          <div className='message-input__file'>
            <div className='message-input__file__info'>
              {(!isError && !isLoading) && <FileIcon fileName={fileName} />}
              {isLoading && <Spinner config={spinnerConfig} />}
              {(isError && !isLoading) && (
                <ExclamationErrorIcon className='message-input__file__info__error-icon' />
              )}
              <span
                className={classnames(
                  fileNameBaseClass,
                  isLoading && `${fileNameBaseClass}--uploading`
                )}
              >
                {fileName}
              </span>
            </div>

            <div className='message-input__file__buttons'>
              {isError &&
                <RetryIcon
                  className='message-input__file__buttons__retry'
                  onClick={() => uploadFile(uploadUrl, file)}
                />}
              <CloseModalIcon
                className='message-input__file__buttons__remove'
                onClick={() => !fileRemoveLoading && onRemoveAttachment(id, fileName)}
              />
            </div>

          </div>
          {isError && (
            <span className='file-upload-error-msg'>
              Failed to upload. Review the import file, and then try again.
            </span>
          )}
        </div>
      )
    )}
  </>
)

AttachmentList.defaultProps = {
  attachments: []
}

AttachmentList.propTypes = {
  attachments: T.arrayOf(
    T.shape({
      fileName: T.string.isRequired,
      isLoading: T.bool,
      isError: T.bool,
      file: T.instanceOf(window.File).isRequired,
      id: T.string,
      uploadUrl: T.string
    })
  ).isRequired,
  onRemoveAttachment: T.func.isRequired,
  uploadFile: T.func
}

export { AttachmentList }
