import { TaskStatuses } from '@elenfs/elen-constants'

import { Badge } from './../../misc'

const {
  Colors: { GREY, YELLOW, GREEN }
} = Badge

export const TASK_CELL_ENTITY_DATA_LABEL = 'data-task-cell-entity'

export const TABLE_HEADERS = Object.freeze([
  {
    label: 'Task',
    propPath: ['summary'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['dueDate'],
    // Used for DND
    dataTaskCellEntity: 'summary'
  },
  {
    label: 'Client',
    propPath: ['client', 'fullName'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['dueDate']
  },
  {
    label: 'Due',
    propPath: ['dueDate'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['dueDate'],
    // Used for DND
    dataTaskCellEntity: 'due-date'

  },
  {
    label: 'Assignee',
    propPath: ['assignedTo', 'fullName'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['dueDate'],
    // Used for DND
    dataTaskCellEntity: 'assignee'
  },
  {
    label: 'Status',
    propPath: ['status'],
    isActive: false,
    isAscending: true,
    secondaryPropPath: ['dueDate'],
    // Used for DND
    dataTaskCellEntity: 'status'
  }
])

export const TaskStatusBadgeColors = Object.freeze({
  [TaskStatuses.TO_DO]: GREY,
  [TaskStatuses.IN_PROGRESS]: YELLOW,
  [TaskStatuses.COMPLETED]: GREEN
})
