import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import constants from '@elenfs/elen-constants'

import { CSVImportPage } from './CSVImportPage'

import ImportSyncSuccessToast from '../ImportSyncSuccessToast'

import { csvFileManager } from './CSVFileManager'
import initCSVImportGql from '../../../graphql/mutations/dataImport/initCSVImport.graphql'

import { TemplateDownloadLinkCSV } from './consts'

const { DataImportTypes } = constants.dataImport

const onDownloadTemplate = () => window.location.assign(TemplateDownloadLinkCSV)

const CSVImportPageContainer = () => {
  const [initCSVImportMutation, { loading, error }] = useMutation(initCSVImportGql)

  const onSubmit = useCallback(
    async (file) => {
      if (!(file instanceof window.File)) return

      const { data } = await initCSVImportMutation({
        variables: {
          input: {
            fileName: file.name,
            fileSize: file.size
          }
        }
      })

      await csvFileManager.upload(data?.initCSVImport?.uploadUrl, file)
      toast(
        <ImportSyncSuccessToast dataImportType={DataImportTypes.CSV_FILE} />
      )
    }
  )

  return <CSVImportPage {...{ onSubmit, onDownloadTemplate, loading, error }} />
}

export { CSVImportPageContainer }
