import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableHeader = ({ children, className, ...rest }) => (
  <thead
    className={classnames('table-new__header', className)}
    {...rest}
  >
    {children}
  </thead>
)

TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default TableHeader
