import T from 'prop-types'

import { formatMoney } from '../../../helpers/policy'
import Card from '../../misc/Card'
import { useCurrentUser } from '../../../hooks'

import './ChargeTotals.less'

const ChargeTotals = ({
  total,
  fees,
  commissions
}) => {
  const { currency } = useCurrentUser()

  return (
    <Card className='charges-total'>
      <Card.Group className='card-new-group--inline card-new-group--heading'>
        <Card.Heading>
          Total remuneration
        </Card.Heading>
        <Card.Heading>
          {formatMoney({ currency, amount: total })}
        </Card.Heading>
      </Card.Group>

      <Card.Group className='card-new-group--inline'>
        <Card.Text className='card-new__text--faded'>
          Fees
        </Card.Text>
        <Card.Text>
          {formatMoney({ currency, amount: fees })}
        </Card.Text>
      </Card.Group>

      <Card.Group className='card-new-group--inline'>
        <Card.Text className='card-new__text--faded'>
          Commissions
        </Card.Text>
        <Card.Text>
          {formatMoney({ currency, amount: commissions })}
        </Card.Text>
      </Card.Group>
    </Card>
  )
}

ChargeTotals.propTypes = {
  className: T.string,
  total: T.number.isRequired,
  fees: T.number.isRequired,
  commissions: T.number.isRequired
}

export { ChargeTotals }
