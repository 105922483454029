import { useQuery } from '@apollo/client'

import currentUserGql from '../../../../graphql/queries/user/currentUser.graphql'

const withCurrentUser = (
  { fetchPolicy = 'cache-first' } = {}
) => (WrappedComponent) => (props) => {
  const { loading, data, error } = useQuery(currentUserGql, {
    fetchPolicy
  })

  /**
   * Ignore errors, just pass null or undefined currentUser prop.
   * Redirecting, logging out etc will be handled somewhere else.
   * (eg in the WithAuthorization HOC)
  **/
  const currentUser = data && data.currentUser

  return (
    <WrappedComponent
      isCurrentUserLoading={loading}
      currentUserError={error}
      currentUser={currentUser}
      {...props}
    />
  )
}

export default withCurrentUser
