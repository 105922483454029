import * as yup from 'yup'
import stringSchema from '../helpers/stringSchema'

const loginSchema = yup.object().shape({
  email: stringSchema()
    .label('Email')
    .trim()
    .email()
    .lowercase()
    .required(),

  password: stringSchema()
    .label('Password')
    .required()
}).noUnknown(true)

export { loginSchema }
