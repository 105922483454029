import { useEffect } from 'react'
import T from 'prop-types'

import { Modal } from '..'
import { ImportClientForm } from './ImportClientForm'

import { optionsType } from '../../../helpers/propTypes'

const ImportClientModal = props => {
  const {
    isOpen,
    close,
    trigger,
    importClientModalHook,
    refetch,
    ...restProps
  } = props

  const { accountsProps, mutationProps } = importClientModalHook({
    closeModal: close,
    refetch
  })

  useEffect(() => {
    const { fetchAccounts } = accountsProps
    if (isOpen) fetchAccounts()
  }, [isOpen])

  return (
    <Modal
      {...{
        isOpen,
        close,
        trigger,
        className: 'import-client-modal',
        ...restProps
      }}
    >
      <div>
        <Modal.Heading>Select client to import</Modal.Heading>

        <ImportClientForm {...{ accountsProps, mutationProps }} />
      </div>
    </Modal>
  )
}

ImportClientModal.propTypes = {
  accountsProps: T.shape({
    accountsOptions: optionsType(),
    accountsLoading: T.bool,
    accountsError: T.string,
    fetchAccounts: T.func,
    onFilterAccounts: T.func
  }),
  mutationProps: T.shape({
    onSubmit: T.func,
    mutationLoading: T.bool,
    mutationError: T.string
  })
}

export { ImportClientModal }
