import { Modal } from '../Modal'
import { CookieConsentForm } from './CookieConsentForm'

import './CookieConsentModal.less'

const CookieConsentModal = ({ cookiesList, onSubmit, close, ...otherProps }) => (
  <Modal
    {...{
      className: 'cookie-consent-modal',
      contentClassName: 'cookie-consent-modal__modal-content',
      close,
      ...otherProps
    }}
  >
    <div className='cookie-consent-modal__content'>
      <CookieConsentForm {...{ cookiesList, onSubmit, close }} />
    </div>
  </Modal>
)

export { CookieConsentModal }
