import * as yup from 'yup'
import stringSchema from '../helpers/stringSchema'
import { passwordSchema } from '../helpers'

export default yup.object().shape({
  password: passwordSchema()
    .label('Password')
    .required(),

  confirmPassword: stringSchema()
    .label('Confirm password')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required()
}).noUnknown(true)
