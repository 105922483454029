import { TasksTableSection } from '../TasksTableSection'

import './TasksPage.less'

const TasksPage = props => {
  const {
    filterProps,
    tableProps,
    openNewTaskModal
  } = props

  return (
    <div className='tasks-section'>
      <div>
        <TasksTableSection
          tableProps={{ openNewTaskModal, ...tableProps }}
          filterProps={filterProps}
        />
      </div>
    </div>
  )
}

export { TasksPage }
