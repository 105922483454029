import { useMemo, useCallback } from 'react'
import { FieldArray, connect } from 'formik'
import T from 'prop-types'
import { useNavigate } from 'react-router-dom'
import constants from '@elenfs/elen-constants'

import AddNewFormRowButton from '../../misc/AddNewFormRowButton'
import Card from '../../misc/Card'
import FormFieldsCard from '../../form/FormFieldsCard'
import ClientFinancialAdvisersFormInputRow from './ClientRelationshipsFormInputRow'
import NextAndPreviousLinks from '../../misc/NextAndPreviousButton'
import { FieldArrayItem } from '../../form'

import { ClientRelationshipLabels } from './../../../helpers/enumLabels/client'
import { buildSelectOptions } from '../../form/fields/SelectField'
import { validateAndNavigate } from '../../../helpers/client'
import clientRelationshipsSchema from '../../../schemas/client/clientRelationshipsSchema'

import './ClientRelationshipsForm.less'

const { ClientRelationshipTypes } = constants.client

const buildRelationshipRowInput = () => ({
  relatedClientId: '',
  type: ''
})

const buildClientOptions = companyClients =>
  companyClients.map(({ id, fullName }) => ({ label: fullName, value: id }))

const relationshipOptions = buildSelectOptions(
  ClientRelationshipTypes,
  ClientRelationshipLabels
)

const ClientRelationshipsForm = ({
  formik,
  companyClients,
  nextLink,
  prevLink,
  setValidationSchema,
  isClientPortal
}) => {
  const navigate = useNavigate()
  const {
    values: { relationships = [] },
    validateForm
  } = formik

  const companyClientOptions = useMemo(() =>
    buildClientOptions(companyClients),
  [companyClients])

  const handleNavButtonClick = useCallback((isNext) => {
    validateAndNavigate({
      setSchema: setValidationSchema,
      schema: clientRelationshipsSchema,
      validate: validateForm,
      nextLocation: isNext ? nextLink : prevLink,
      navigate
    })
  }, [
    navigate,
    nextLink,
    prevLink,
    validateForm,
    clientRelationshipsSchema,
    setValidationSchema
  ])

  return (
    <Card className='relationships-form'>
      <FormFieldsCard title='Relationships'>
        <FieldArray
          validateOnChange={false}
          name='relationships'
          render={arrayHelpers => (
            <div className='relationships-input-rows-container'>
              {
                relationships.map((relationship, index) => (
                  <FieldArrayItem
                    key={index}
                    index={index}
                    label='Relationship'
                    onRemove={() => arrayHelpers.remove(index)}
                    disabled={isClientPortal}
                  >
                    <ClientFinancialAdvisersFormInputRow
                      index={index}
                      arrayHelpers={arrayHelpers}
                      companyClientOptions={companyClientOptions}
                      relationshipOptions={relationshipOptions}
                      disabled={isClientPortal}
                      relationships={relationships}
                    />
                  </FieldArrayItem>
                ))
              }

              {!isClientPortal && <AddNewFormRowButton
                label='add relationship'
                onClick={() => arrayHelpers.push(buildRelationshipRowInput())}
                                  />}
            </div>
          )}
        />
      </FormFieldsCard>

      <NextAndPreviousLinks
        onNextButtonClick={() => handleNavButtonClick(true)}
        onBackButtonClick={() => handleNavButtonClick(false)}
      />
    </Card>
  )
}

const EnhancedClientRelationshipsForm = connect(ClientRelationshipsForm)

EnhancedClientRelationshipsForm.propTypes = {
  companyClients: T.arrayOf(
    T.shape({
      fullName: T.string.isRequired,
      id: T.string.isRequired
    })
  ).isRequired,
  nextLink: T.string,
  prevLink: T.string
}

export { EnhancedClientRelationshipsForm as ClientRelationshipsForm }
