import moment from 'moment'
import { saveAs } from 'file-saver'
import AppConfig from '../../appConfig'

import { getAuthToken } from '../../auth'

const handleBlobWithFileSaver = (blob, fileName) => {
  if (!blob) { return }
  saveAs(blob, fileName)
}

const generateRequestOptions = (token) => ({
  method: 'GET',
  cache: 'no-cache',
  headers: {
    Authorization: `Bearer ${token}`
  }
})

const downloadIncomeReports = async (incomeType, filters) => {
  const url = new URL(`${AppConfig.API_BASE_URL}/export/income-report?type=${incomeType}`)

  // throw error for now, if there is no type selected
  if (!incomeType) {
    throw new Error('No filters applied!')
  }

  // throw error for now, if there is no filters applied
  if (!filters) {
    throw new Error('No filters applied!')
  }

  const token = getAuthToken()

  if (filters.fromDate) {
    filters.fromDate = moment(filters.fromDate).startOf('day').toDate()
  }

  if (filters.toDate) {
    filters.toDate = moment(filters.toDate).endOf('day').toDate()
  }

  // construct queryParams in the URL itself
  Object.keys(filters).forEach(key => url.searchParams.append(key, filters[key]))

  // call to API with constructed URL
  const res = await window.fetch(url, generateRequestOptions(token))
  // get the blob response
  const blob = await res.blob()
  // get Headers
  const resHeaders = res.headers.get('Content-Disposition')
  // extract filename
  const fileName = resHeaders.match(/filename="(.*?)"/i)[1]

  // download the file
  handleBlobWithFileSaver(blob, fileName)
}

export { downloadIncomeReports }
