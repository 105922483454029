import T from 'prop-types'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { useFormState } from '../../../hooks'
import InvitationSignupForm from './InvitationSignupForm'
import { login } from '../../../auth'
import { getCurrentUser } from '../../../graphql/helpers'

const InvitationSignupFormContainer = ({
  formMutation,
  formLoginType,
  ...props
}) => {
  const { email, firstName } = props

  const [formState, setFormState] = useFormState()
  const { invitationToken } = useParams()
  const [mutate] = useMutation(formMutation)

  const handleUserLogin = async (password, agreements) => {
    setFormState({ ...formState, loading: true })

    const registerAndLogin = async () => {
      await mutate({
        variables: {
          invitationToken,
          password,
          agreements
        }
      })

      await login(email, password, formLoginType)
      await getCurrentUser()
    }

    try {
      await registerAndLogin()
    } catch (e) {
      setFormState({ ...formState, loading: false, error: e })
    }

    setFormState({ ...formState, loading: false, success: true })
  }

  const { loading, error, success } = formState

  return (
    <InvitationSignupForm
      email={email}
      firstName={firstName}
      loading={loading}
      error={error}
      success={success}
      handleUserLogin={handleUserLogin}
      formLoginType={formLoginType}
      {...props}
    />
  )
}

InvitationSignupFormContainer.propTypes = {
  formValidationSchema: T.object.isRequired
}

export default InvitationSignupFormContainer
