"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Waivers = exports.ProtectionTypesLabels = exports.ProtectionTypes = exports.PlanStatuses = exports.OfferedTerms = exports.DeferredPeriods = exports.CoverTypes = exports.AssuredLives = void 0;
const AssuredLives = exports.AssuredLives = Object.freeze({
  CLIENT: 'CLIENT',
  JOINT_LIFE_FIRST_DEATH: 'JOINT_LIFE_FIRST_DEATH',
  JOINT_LIFE_SECOND_DEATH: 'JOINT_LIFE_SECOND_DEATH',
  PARTNER: 'PARTNER'
});
const CoverTypes = exports.CoverTypes = Object.freeze({
  BALANCED: 'BALANCED',
  MAXIMUM: 'MAXIMUM'
});
const DeferredPeriods = exports.DeferredPeriods = Object.freeze({
  DAY_1_COVER: 'DAY_1_COVER',
  WEEKS_1: 'WEEKS_1',
  WEEKS_2: 'WEEKS_2',
  WEEKS_4: 'WEEKS_4',
  WEEKS_6: 'WEEKS_6',
  WEEKS_13: 'WEEKS_13',
  WEEKS_26: 'WEEKS_26',
  WEEKS_36: 'WEEKS_36',
  WEEKS_52: 'WEEKS_52'
});
const OfferedTerms = exports.OfferedTerms = Object.freeze({
  RATED_TERMS: 'RATED_TERMS',
  STANDARD_TERMS: 'STANDARD_TERMS',
  STANDARD_TERMS_WITH_EXCLUSIONS: 'STANDARD_TERMS_WITH_EXCLUSIONS'
});
const PlanStatuses = exports.PlanStatuses = Object.freeze({
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  LAPSED: 'LAPSED',
  MATURED: 'MATURED',
  NTU: 'NTU',
  OFFERED: 'OFFERED',
  ON_RISK: 'ON_RISK',
  SURRENDERED: 'SURRENDERED'
});
const ProtectionTypes = exports.ProtectionTypes = Object.freeze({
  BUILDING_AND_CONTENTS: 'BUILDING_AND_CONTENTS',
  CASH_PLAN_PMI: 'CASH_PLAN_PMI',
  CONVERTIBLE_TERM_ASSURANCE: 'CONVERTIBLE_TERM_ASSURANCE',
  DECREASING_TERM_ASSURANCE: 'DECREASING_TERM_ASSURANCE',
  DECREASING_TERM_ASSURANCE_WITH_CIC: 'DECREASING_TERM_ASSURANCE_WITH_CIC',
  DECREASING_TERM_CIC_ONLY: 'DECREASING_TERM_CIC_ONLY',
  FAMILY_INCOME_BENEFIT: 'FAMILY_INCOME_BENEFIT',
  FAMILY_INCOME_BENEFIT_WITH_CIC: 'FAMILY_INCOME_BENEFIT_WITH_CIC',
  INCOME_PROTECTION: 'INCOME_PROTECTION',
  INCREASING_TERM_ASSURANCE: 'INCREASING_TERM_ASSURANCE',
  INCREASING_TERM_ASSURANCE_WITH_CIC: 'INCREASING_TERM_ASSURANCE_WITH_CIC',
  INCREASING_TERM_CIC_ONLY: 'INCREASING_TERM_CIC_ONLY',
  KEY_PERSON_LIFE_INSURANCE: 'KEY_PERSON_LIFE_INSURANCE',
  KEY_PERSON_INCOME_PROTECTION: 'KEY_PERSON_INCOME_PROTECTION',
  LEVEL_TERM_ASSURANCE: 'LEVEL_TERM_ASSURANCE',
  LEVEL_TERM_ASSURANCE_WITH_CIC: 'LEVEL_TERM_ASSURANCE_WITH_CIC',
  LEVEL_TERM_CIC_ONLY: 'LEVEL_TERM_CIC_ONLY',
  OTHER: 'OTHER',
  PRIVATE_MEDICAL_INSURANCE: 'PRIVATE_MEDICAL_INSURANCE',
  RELEVANT_LIFE: 'RELEVANT_LIFE',
  RENEWABLE_TERM_ASSURANCE: 'RENEWABLE_TERM_ASSURANCE',
  SHAREHOLDERS_PROTECTION: 'SHAREHOLDERS_PROTECTION',
  UNKNOWN_CASH_CALC: 'UNKNOWN_CASH_CALC',
  WHOLE_OF_LIFE: 'WHOLE_OF_LIFE',
  WHOLE_OF_LIFE_WITH_CIC: 'WHOLE_OF_LIFE_WITH_CIC'
});
const Waivers = exports.Waivers = Object.freeze({
  BOTH: 'BOTH',
  CLIENT: 'CLIENT',
  PARTNER: 'PARTNER',
  NO: 'NO'
});
const ProtectionTypesLabels = exports.ProtectionTypesLabels = Object.freeze({
  [ProtectionTypes.BUILDING_AND_CONTENTS]: 'Building and Contents',
  [ProtectionTypes.CASH_PLAN_PMI]: 'Cash Plan (PMI)',
  [ProtectionTypes.CONVERTIBLE_TERM_ASSURANCE]: 'Convertible Term Assurance',
  [ProtectionTypes.DECREASING_TERM_ASSURANCE]: 'Decreasing Term Assurance',
  [ProtectionTypes.DECREASING_TERM_ASSURANCE_WITH_CIC]: 'Decreasing Term Assurance with CIC',
  [ProtectionTypes.DECREASING_TERM_CIC_ONLY]: 'Decreasing Term CIC Only',
  [ProtectionTypes.FAMILY_INCOME_BENEFIT]: 'Family Income Benefit',
  [ProtectionTypes.FAMILY_INCOME_BENEFIT_WITH_CIC]: 'Family Income Benefit with CIC',
  [ProtectionTypes.INCOME_PROTECTION]: 'Income Protection',
  [ProtectionTypes.INCREASING_TERM_ASSURANCE]: 'Increasing Term Assurance',
  [ProtectionTypes.INCREASING_TERM_ASSURANCE_WITH_CIC]: 'Increasing Term Assurance with CIC',
  [ProtectionTypes.INCREASING_TERM_CIC_ONLY]: 'Increasing Term CIC Only',
  [ProtectionTypes.KEY_PERSON_LIFE_INSURANCE]: 'Key Person Life Insurance',
  [ProtectionTypes.KEY_PERSON_INCOME_PROTECTION]: 'Key Person Income Protection',
  [ProtectionTypes.LEVEL_TERM_ASSURANCE]: 'Level Term Assurance',
  [ProtectionTypes.LEVEL_TERM_ASSURANCE_WITH_CIC]: 'Level Term Assurance with CIC',
  [ProtectionTypes.LEVEL_TERM_CIC_ONLY]: 'Level Term CIC Only',
  [ProtectionTypes.OTHER]: 'Other',
  [ProtectionTypes.PRIVATE_MEDICAL_INSURANCE]: 'Private Medical Insurance',
  [ProtectionTypes.RELEVANT_LIFE]: 'Relevant Life',
  [ProtectionTypes.RENEWABLE_TERM_ASSURANCE]: 'Renewable Term Assurance',
  [ProtectionTypes.SHAREHOLDERS_PROTECTION]: 'Shareholders Protection',
  [ProtectionTypes.UNKNOWN_CASH_CALC]: 'Unknown (CashCalc)',
  [ProtectionTypes.WHOLE_OF_LIFE]: 'Whole of Life',
  [ProtectionTypes.WHOLE_OF_LIFE_WITH_CIC]: 'Whole of Life with CIC'
});
var _default = exports.default = {
  AssuredLives,
  CoverTypes,
  DeferredPeriods,
  OfferedTerms,
  PlanStatuses,
  ProtectionTypes,
  Waivers,
  ProtectionTypesLabels
};