import { useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { reject, propEq } from 'ramda'

import { SendMessageModal } from './SendMessageModal'
import { Toastr } from './../Toastr'

import { parseFilesToCreate, mapFilesToAttachments } from './../../../helpers/file'
import {
  mapFile,
  parseFilesToLocalState,
  uploadFile,
  normalizeFiles
} from './helpers'

import createMessageGql from '../../../graphql/mutations/message/createMessage.graphql'
import createMessageAttachmentsGql from '../../../graphql/mutations/message/createMessageAttachments.graphql'
import messagesGql from '../../../graphql/queries/messages/messages.graphql'

const SendMessageModalContainer = props => {
  const { isClientPortal, clientName, ...otherProps } = props

  const [messageSubmitLoading, setMessageSubmitLoading] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [clientId, setClientId] = useState('')
  const [fileCreateMutation] = useMutation(createMessageAttachmentsGql)

  const [createMessageMutate] = useMutation(createMessageGql, {
    update: (cache, { data: { createMessage: newMessage } }) => {
      try {
        const { messages } =
          cache.readQuery({
            query: messagesGql,
            variables: { clientId }
          }) || {}
        if (messages) {
          cache.writeQuery({
            query: messagesGql,
            data: { messages: [...messages, newMessage] }
          })
        }
      } catch (error) {
        console.log(
          'Error occur while updating messages cache on sending new message',
          error
        )
      }
    }
  })

  const onFilesSelect = useCallback(
    fileList => {
      if (!fileList.length) {
        return null
      }
      setAttachments(mapFilesToAttachments(attachments, fileList))
    },
    [clientId, attachments]
  )

  const onRemoveAttachment = useCallback((_, fileName) => {
    setAttachments(reject(propEq('fileName', fileName)))
  }, [])

  const onMessageSubmit = useCallback(
    async body => {
      try {
        let filesToUpload = []
        setMessageSubmitLoading(true)

        if (attachments?.length) {
          const {
            data: { createMessageAttachments }
          } = await fileCreateMutation({
            variables: {
              input: {
                clientId,
                files: parseFilesToCreate(mapFile(attachments))
              }
            }
          })

          filesToUpload = parseFilesToLocalState(
            createMessageAttachments,
            attachments
          )
        }

        await Promise.all([
          ...filesToUpload.map(({ uploadUrl, file }) =>
            uploadFile(uploadUrl, file)
          ),
          createMessageMutate({
            variables: {
              input: {
                body,
                clientId,
                attachments: normalizeFiles(filesToUpload)
              }
            }
          })
        ])
        toast(<Toastr type='success' title='Message sent' />)
      } catch (error) {
        toast(<Toastr type='error' title='Failed to send message' />)
      }
      setMessageSubmitLoading(false)
    },
    [createMessageMutate, clientId, attachments, fileCreateMutation]
  )

  const formProps = {
    onFilesSelect,
    onRemoveAttachment,
    uploadFile,
    onMessageSubmit,
    setClientId,
    attachments,
    messageSubmitLoading,
    clientId: otherProps.clientId,
    isClientPortal,
    clientName
  }

  const onCloseModal = useCallback(() => {
    const { close } = otherProps
    setAttachments([])
    close()
  }, [])

  return (
    <SendMessageModal {...{ formProps, ...otherProps, close: onCloseModal }} />
  )
}

export { SendMessageModalContainer }
