const SpinnerIcon = props => (
  <svg width={20} height={20} {...props}>
    <defs>
      <path id='prefix__a' d='M0 0h20v20H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='prefix__b' fill='#fff'>
        <use xlinkHref='#prefix__a' />
      </mask>
      <g fill='currentColor' fillRule='nonzero' mask='url(#prefix__b)'>
        <path
          d='M11.433 6.187c.61.246 1.31-.059 1.56-.68l.803-1.985a1.204 1.204 0 00-.65-1.572 1.204 1.204 0 00-1.56.679l-.803 1.986c-.25.62.041 1.325.65 1.572z'
          opacity={0.6}
        />
        <path
          d='M4.615 9.217c.62.25 1.325-.041 1.572-.65a1.204 1.204 0 00-.68-1.56l-1.985-.803a1.204 1.204 0 00-1.572.65c-.246.61.058 1.31.679 1.56l1.986.803z'
          opacity={0.4}
        />
        <path
          d='M2.712 11.76a1.22 1.22 0 00-.658.67 1.222 1.222 0 00.675 1.596c.304.123.636.12.937-.009l1.99-.848a1.23 1.23 0 00.65-1.609c-.255-.602-1.003-.905-1.605-.648l-1.99.848z'
          opacity={0.2}
        />
        <path d='M6.02 16.403c-.25.621.041 1.326.65 1.572.61.247 1.31-.058 1.56-.678l.803-1.986a1.204 1.204 0 00-.65-1.572 1.204 1.204 0 00-1.56.679l-.803 1.985z' />
        <path
          d='M10.924 15.298l.842 1.991c.127.302.364.535.667.658.304.122.636.12.937-.01a1.22 1.22 0 00.657-.67 1.22 1.22 0 00-.007-.938l-.842-1.992c-.254-.603-1.003-.905-1.604-.648a1.23 1.23 0 00-.65 1.609z'
          opacity={0.9}
        />
        <path
          d='M17.297 11.77l-1.986-.803a1.204 1.204 0 00-1.572.65c-.246.61.058 1.31.679 1.56l1.986.803c.62.25 1.325-.041 1.571-.65a1.204 1.204 0 00-.678-1.56z'
          opacity={0.8}
        />
        <path
          d='M17.293 8.229a1.23 1.23 0 00.65-1.609c-.254-.602-1.003-.905-1.604-.648l-1.99.848a1.22 1.22 0 00-.656.67 1.22 1.22 0 00.007.938c.127.302.364.536.667.658.303.123.636.119.937-.01l1.99-.847z'
          opacity={0.7}
        />
        <path
          d='M5.8 3.585a1.23 1.23 0 01.65-1.608c.602-.257 1.35.045 1.604.648l.842 1.991c.128.302.13.635.008.939a1.22 1.22 0 01-.658.67 1.214 1.214 0 01-.937.01 1.214 1.214 0 01-.667-.658L5.8 3.585z'
          opacity={0.5}
        />
      </g>
    </g>
  </svg>
)

export { SpinnerIcon }
