import { Route, Routes } from 'react-router-dom'
import DocumentsPage from '../../clientDocument/DocumentsPage'

const ClientDocumentsRouteSwitch = props => (
  <Routes>
    <Route index path='/' element={<DocumentsPage {...props} />} />
    <Route path=':docId' element={<DocumentsPage {...props} />} />
    <Route path='e-signature/:eSignatureId' element={<DocumentsPage {...props} />} />
  </Routes>
)

export { ClientDocumentsRouteSwitch }
