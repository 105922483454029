import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import classnames from 'classnames'

import { Table } from '../../../misc'
import { ImportLogTableHeader } from './ImportLogTableHeader'
import { getJobStats, getJobInfo } from '../helpers'

export const ImportLogTableRow = ({ job, link, page }) => {
  const navigate = useNavigate()
  const stats = getJobStats(job)
  const { indicatorColor, statusText } = getJobInfo({ job, stats })

  const onTableClick = () => navigate(`${link}/logs/${job.id}`, { state: { page } })

  return (
    <Table.Row
      className='import-log-table__content__row'
      onClick={onTableClick}
    >
      <Table.Cell
        date-label={ImportLogTableHeader.STATUS}
        className='import-log-table__content__row__cell'
      >
        <div
          className={classnames(
            'import-log-table__content__row__indicator',
            `import-log-table__content__row__indicator--${indicatorColor}`
          )}
        />
        <div
          className={classnames(
            'import-log-table__content__row__cell__status',
            `import-log-table__content__row__cell__status--${indicatorColor}`
          )}
        >
          {statusText}
        </div>
      </Table.Cell>

      <Table.Cell
        date-label={ImportLogTableHeader.DATE}
        className='import-log-table__content__row__cell'
      >
        <div className='import-log-table__content__row__cell__date'>
          {moment(job?.completedAt).format('D MMM YYYY - HH:mm')}
        </div>
      </Table.Cell>
    </Table.Row>
  )
}
