import T from 'prop-types'

import { ConfirmationModal, Button } from '../../misc'

const P1DisconnectButton = ({
  disconnectCallback,
  className,
  Component
}) => (
  <ConfirmationModal.Trigger
    as={Component}
    color={Button.Colors.GREY}
    className={className}
    modalProps={{
      confirmationCallback: disconnectCallback,
      heading: 'Disconnect from P1',
      text: 'Are you sure you want to disconnect from P1? You will no longer receive updates to your database from this platform',
      yesButtonText: 'Disconnect',
      noButtonText: 'Go back',
      awaitCallback: true
    }}
  >
    Disconnect from P1
  </ConfirmationModal.Trigger>
)

P1DisconnectButton.defaultProps = {
  Component: Button
}

P1DisconnectButton.propTypes = {
  disconnectCallback: T.func.isRequired,
  Component: T.elementType.isRequired,
  className: T.string
}

export { P1DisconnectButton }
