import classnames from 'classnames'
import T from 'prop-types'

import NextButton from '../NextButton'
import PreviousButton from '../PreviousButton'
import './NextAndPreviousLinks.less'

const NextAndPreviousLinks = ({ previousLinkTo, onNextButtonClick, onBackButtonClick }) => (
  <div className={classnames('next-with-previous-link', !(previousLinkTo || onBackButtonClick) && 'next-link-only')}>
    {(onBackButtonClick || previousLinkTo) && <PreviousButton onClick={onBackButtonClick} to={previousLinkTo} />}
    {onNextButtonClick && <NextButton onClick={onNextButtonClick} />}
  </div>
)

NextAndPreviousLinks.propTypes = {
  previousLinkTo: T.string,
  onNextButtonClick: T.func,
  onBackButtonClick: T.func
}

export default NextAndPreviousLinks
