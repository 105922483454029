const Stapler = props => {
  return (
    <svg viewBox='0 0 22 22' {...props}>
      <path
        d='M5.797 21.761c-1.532 0-2.968-.565-4.044-1.59C.677 19.146.083 17.78.083 16.32a5.28 5.28 0 011.67-3.852L13.58 1.203c1.692-1.61 4.322-1.539 6.117.17 1.794 1.71 1.872 4.214.18 5.826L8.764 17.782c-1.043.993-2.742.995-3.787 0a2.468 2.468 0 010-3.607l7.528-7.168a.673.673 0 01.919 0 .598.598 0 010 .875l-7.528 7.169a1.27 1.27 0 000 1.855 1.43 1.43 0 001.949 0L18.958 6.324c1.168-1.113 1.091-2.865-.18-4.075-1.27-1.21-3.11-1.283-4.278-.17L2.672 13.344a4.08 4.08 0 000 5.952 4.502 4.502 0 003.125 1.227 4.502 4.502 0 003.125-1.227L20.75 8.03a.673.673 0 01.92 0 .598.598 0 010 .875L9.84 20.171c-1.075 1.025-2.512 1.59-4.043 1.59z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Stapler
