export default {
  Policy: ['DebtPolicy', 'InvestmentPolicy', 'MortgagePolicy', 'PensionPolicy', 'ProtectionPolicy'],
  DataImportConnection: ['DataImportTransactConnection'],
  DataImportLogEntry: [
    'DataImportClientCreated',
    'DataImportPolicyCreated',
    'DataImportPolicyUpdated',
    'DataImportPolicyTransactionCreated',
    'DataImportNoPolicyToUpdate',
    'DataImportParsingError',
    'DataImportInvalidClient',
    'DataImportInvalidPolicy',
    'DataImportInvalidPolicyTransaction',
    'DataImportClientInternalFailure',
    'DataImportPolicyInternalFailure',
    'DataImportPolicyTransactionInternalFailure'
  ],
  TaskInterface: [
    'Task',
    'Subtask'
  ],
  DataImportIntegrationInterface: [
    'DataImportIntegration',
    'CashCalcImportIntegration'
  ]
}
