import { useCallback } from 'react'
import { withFormik, connect } from 'formik'
import classnames from 'classnames'
import T from 'prop-types'
import { isEmpty, compose } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { TaskStatuses } from '@elenfs/elen-constants'

import { Form, FormErrorMessages } from '../../../form'
import { FileInputIcon } from '../../../form/inputs'

import {
  SelectField,
  DateField,
  InputField,
  TextAreaField
} from '../../../form/fields'
import {
  Button,
  Heading,
  PageDivider,
  LoadingMessage,
  ConfirmationModal,
  ModalStickyLayout,
  AttachmentList
} from '../../../misc'
import { StaplerIcon } from '../../../icons'
import {
  ControlWithColoredFill,
  ControlWithActivity,
  ControlWithInitials
} from '../../../misc/DropdownSelection/DropdownCustom'

import { SubTasksTable } from '../../TasksTable'
import { taskSchema } from '../../../../schemas/tasks/taskSchema'
import { buildInitialValues } from '../../../../helpers/form'
import { parseGraphqlErrors } from '../../../../helpers/error'
import { optionsType } from '../../../../helpers/propTypes'
import { formatDate } from './../../../../helpers/date'
import { getFormState, getInitialValues } from './helpers'
import { taskStatusOptionsColored, taskTypeOptions, newTaskStatusOptions } from './../../const'
import { TaskCommentList } from '../../TaskCommentList'

import './TaskDetailsForm.less'

const FileInputIconComponent = () => (
  <div className='task-details-form__file-input'>
    <StaplerIcon /> attach file
  </div>
)

const ChangeStatusText = () => (
  <>
    Are you sure you want to set the status to Completed? <br /> <br /> Please
    note: This task has sub-tasks which are not yet completed. Their status will
    also be set to Completed if you continue.
  </>
)

const FieldRow = ({ className, children, ...restProps }) => (
  <Form.Group
    className={classnames('task-details-form__fields-row', className)}
    {...restProps}
  >
    {children}
  </Form.Group>
)

const TaskDetailsForm = props => {
  const {
    task,
    isSubtask,
    formik: { values, errors, submitForm, resetForm, validateForm },
    loadingOnSubmit,
    onDone,
    assigneeOptions,
    onCreateSubtask,
    loadingOnCreateSubtask,
    errorsOnCreateSubtask,
    onTaskClick,
    newTask,
    onParentTaskClick,
    onRemoveTask,
    workflow,
    currentUser,
    modalHeader,
    attachments,
    onRemoveAttachment,
    onFilesSelect,
    taskSubmitLoading,
    handleDownload
  } = props

  const {
    isCompletedStatus,
    isChangedToCompleted,
    isConfirmChangeStatus,
    clientOptionsDisabled,
    canSaveAndNew,
    canDeleteTask
  } = getFormState({ values, task, isSubtask, newTask, onRemoveTask })

  const navigate = useNavigate()

  const validateAndSubmit = useCallback(
    async (isDone, isSaveAndNew) => {
      try {
        const errors = await validateForm()
        if (Object.keys(errors).length) {
          throw new Error()
        }

        await submitForm()
        if (isDone) {
          onDone({
            showToast: values?.clientId && isChangedToCompleted,
            isSaveAndNew
          })
        }
      } catch (error) {
        throw new Error()
      }
    },
    [submitForm, isChangedToCompleted, isSubtask]
  )

  const onDoneClick = useCallback(async () => {
    const triggerDone = !(newTask && isSubtask)
    await validateAndSubmit(triggerDone)
  }, [validateAndSubmit, isSubtask])

  const onConfirmStatusDone = useCallback(
    async confirmed => {
      if (confirmed) {
        try {
          await validateAndSubmit(true)
        } catch (error) {
          throw new Error()
        }
      }
    },
    [validateAndSubmit]
  )

  const onSaveAndAddNewClick = useCallback(async () => {
    try {
      await validateAndSubmit(true, true)
      resetForm()
    } catch (error) { }
  }, [validateAndSubmit])

  const deleteAndCloseModal = useCallback(
    async confirmed => {
      if (confirmed) {
        try {
          await onRemoveTask(task?.id, task?.parentId)
          onDone({ isDelete: true })
        } catch (error) {
          throw new Error()
        }
      }
    },
    [onDone, onRemoveTask, task]
  )

  const showHeader = isSubtask || workflow?.name
  const headerLabel = isSubtask ? 'Parent task:' : 'Related workflow:'
  const headerValue = isSubtask ? task?.parentTask?.summary : workflow?.name

  const onHeaderValueClick = useCallback(() => {
    if (isSubtask) {
      onParentTaskClick(task?.parentId)
    } else {
      navigate(`/secure/clients/${task.client.id}/workflow-instance/${workflow.id}`)
    }
  }, [isSubtask, task, workflow])

  return (
    <>
      <ModalStickyLayout.Header close={onDone}>
        {modalHeader}
      </ModalStickyLayout.Header>
      <ModalStickyLayout.Body>
        {showHeader && (
          <>
            <div className='task-details__header'>
              <i>
                {headerLabel}
                <span onClick={onHeaderValueClick}>{headerValue}</span>
              </i>
            </div>
            <PageDivider className='task-details__header__divider' />
          </>
        )}
        <Form className='task-details-form'>
          <FieldRow className='task-details-form__fields-row--selects'>
            <SelectField
              name='status'
              options={newTask ? newTaskStatusOptions : taskStatusOptionsColored}
              inputProps={{ isClearable: false, isSearchable: false }}
              selectProps={{ Control: ControlWithColoredFill }}
            />
            <SelectField
              isGrouped
              name='assignedTo'
              options={assigneeOptions}
              inputProps={{ isClearable: false }}
              selectProps={{ Control: ControlWithInitials }}
            />
            <DateField
              name='dueDate'
              withIconLayout
              inputProps={{
                isClearable: true,
                placeholder: '+ due date'
              }}
            />
            <SelectField
              name='type'
              options={taskTypeOptions}
              inputProps={{ isSearchable: false }}
              selectProps={{ Control: ControlWithActivity }}
            />
          </FieldRow>

          <PageDivider />

          <FieldRow className='task-details-form__fields-row--client'>
            <SelectField
              name='clientId'
              label='Client'
              optionsToResolve={[task?.client?.id].filter(Boolean)}
              inputProps={{
                async: true,
                isClearable: true,
                isDisabled: clientOptionsDisabled,
                asyncProps: {
                  labelProp: 'fullName',
                  valueProp: 'id'
                }
              }}
            />
          </FieldRow>
          <FieldRow>
            <InputField
              name='summary'
              label='Summary'
              inputId={task?.id + '-modal'}
              inputProps={{
                placeholder: 'Start typing…'
              }}
            />
          </FieldRow>
          <FieldRow>
            <TextAreaField
              name='description'
              label='Description'
              inputProps={{
                placeholder: 'Start typing…'
              }}
            />
          </FieldRow>

          <div
            className={classnames('task-details-form__attachment__list', {
              disabled__attachments: taskSubmitLoading
            })}
          >
            <AttachmentList
              attachments={attachments}
              onRemoveAttachment={onRemoveAttachment}
              handleDownload={handleDownload}
            />
          </div>

          <FileInputIcon
            className='task-details-form__attachment__action'
            icon={FileInputIconComponent}
            multiple
            onChange={onFilesSelect}
            disabled={taskSubmitLoading}
          />
        </Form>

        {!isSubtask && (
          <div className='task-details-form__subtasks'>
            <PageDivider />
            <Heading as='h3' className='task-details-form__subtask-title'>
              Subtasks
            </Heading>
            <SubTasksTable
              {...{
                subTasks: task?.subtasks || [],
                onCreateSubtask: subtask => onCreateSubtask(subtask, values),
                loadingOnCreateSubtask,
                taskId: task?.id,
                errorsOnCreateSubtask,
                onTaskClick: (task, index) => onTaskClick(task, index, values),
                isModal: true
              }}
            />
          </div>
        )}

        {!newTask && (
          <>
            <PageDivider />
            <TaskCommentList taskId={task?.id} currentUser={currentUser} />
          </>
        )}

        {task && !newTask && (
          <div className='task-details-form__footer'>
            <span>Created {formatDate(task?.createdAt)} </span>|
            <span>
              {' '}
              {workflow?.name ? 'Workflow' : task?.createdBy?.fullName}{' '}
            </span>
            |<span>Updated {formatDate(task?.updatedAt)} </span>
          </div>
        )}
      </ModalStickyLayout.Body>

      <ModalStickyLayout.Footer>
        <ModalStickyLayout.Footer.Indicators>
          {loadingOnSubmit && !isConfirmChangeStatus && <LoadingMessage />}
          {!isEmpty(errors) && <FormErrorMessages validationErrors={errors} />}
        </ModalStickyLayout.Footer.Indicators>

        <div
          className={classnames('task-details-form__buttons', {
            'task-details-form__buttons--isSubtask': isSubtask
          })}
        >
          <div className='task-details-form__buttons__save-buttons'>
            {isConfirmChangeStatus
              ? (
                <ConfirmationModal.Trigger
                  as={Button}
                  color={Button.Colors.BLUE}
                  disabled={newTask && isCompletedStatus}
                  modalProps={{
                    confirmationCallback: onConfirmStatusDone,
                    awaitCallback: true,
                    heading: 'Completing task',
                    text: <ChangeStatusText />,
                    yesButtonText: 'Complete All',
                    noButtonText: 'Go Back'
                  }}
                >
                  Save
                </ConfirmationModal.Trigger>
                )
              : (
                <Button
                  color={Button.Colors.BLUE}
                  onClick={onDoneClick}
                  disabled={newTask && isCompletedStatus}
                >
                  Save
                </Button>
                )}

            {canSaveAndNew && (
              <Button
                color={Button.Colors.GREEN}
                onClick={onSaveAndAddNewClick}
                disabled={newTask && isCompletedStatus}
              >
                Save and add new
              </Button>
            )}
          </div>

          {canDeleteTask && !workflow?.name && (
            <div className='task-details-form__buttons__delete-button'>
              <ConfirmationModal.Trigger
                as={Button}
                color={Button.Colors.RED_TEXT}
                modalProps={{
                  confirmationCallback: deleteAndCloseModal,
                  awaitCallback: true,
                  heading: 'Delete Task',
                  text: 'Are you sure you want to delete this task?'
                }}
              >
                Delete
              </ConfirmationModal.Trigger>
            </div>
          )}
        </div>
      </ModalStickyLayout.Footer>
    </>
  )
}

const TaskDetailsFormEnhanced = compose(
  withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: taskSchema,
    mapPropsToValues: ({
      task = {},
      clientId,
      mainTaskChanges,
      isSubtask,
      newTask
    }) => {
      return buildInitialValues(
        taskSchema,
        getInitialValues({
          mainTaskChanges,
          newTask,
          isSubtask,
          task,
          clientId
        })
      )
    },
    handleSubmit: async (values, { props, setErrors }) => {
      const { task, onSubmit, isSubtask, newTask } = props
      const valuesToSubmit = taskSchema.cast(values, { stripUnknown: true })

      let newValues = Object.assign({}, valuesToSubmit)

      if (task && task.id) {
        newValues = {
          ...newValues,
          id: task.id
        }

        if (isSubtask) {
          newValues.parentId = task.parentId

          delete newValues.clientId
        }
      }

      if (newTask && !isSubtask) {
        newValues.subtasks = task.subtasks
      }

      try {
        await onSubmit(newValues)
      } catch (err) {
        const isConfirmChangeStatus =
          values?.status === TaskStatuses.COMPLETED &&
          task?.subtasks?.find(
            ({ status }) => status !== TaskStatuses.COMPLETED
          )
        if (!isConfirmChangeStatus) {
          setErrors(
            parseGraphqlErrors(
              err,
              'There was an error saving the task, please try again later'
            )
          )
        }
        throw err
      }
    }
  }),
  connect
)(TaskDetailsForm)

TaskDetailsFormEnhanced.defaultProps = {
  assigneeOptions: [],
  task: {}
}

TaskDetailsFormEnhanced.propTypes = {
  /**
   * Is any mutation loading. Eg mutation for saving or for deleting the charge.
   */
  loading: T.bool,
  /**
   * Callback to execute when form submits.
   */
  onDone: T.func.isRequired,
  /**
   * Callback to submit the form.
   */
  onSubmit: T.func.isRequired,
  /**
   * Callback to remove the task.
   */
  onRemoveTask: T.func,
  /**
   * Task that's being edited.
   */
  task: T.object,
  /**
   * Options for the task assignee select field.
   */
  assigneeOptions: optionsType().isRequired
}

export { TaskDetailsFormEnhanced as TaskDetailsForm }
