import T from 'prop-types'

import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt'
import ConfirmationModal from '../../misc/ConfirmationModal'

const RouteLeavingGuard = ({
  enabled,
  shouldBlockNavigation,
  yesButtonText,
  noButtonText
}) => {
  const [showPrompt, confirmNavigation, closeModal] = useCallbackPrompt(
    enabled,
    shouldBlockNavigation
  )
  return (
    <ConfirmationModal
      awaitCallback
      isOpen={showPrompt}
      close={closeModal}
      confirmationCallback={confirmNavigation}
      heading='Exit without saving?'
      text={
        <>
          <span>Are you sure you want to exit this window without saving?</span><br />
          <span>Any changes you have made will not be saved.</span>
        </>
      }
      yesButtonText={yesButtonText}
      noButtonText={noButtonText}
    />
  )
}

RouteLeavingGuard.defaultProps = {
  shouldBlockNavigation: false,
  yesButtonText: 'Exit',
  noButtonText: 'Go back'
}

RouteLeavingGuard.propTypes = {
  enabled: T.bool,
  shouldBlockNavigation: T.oneOfType([T.func, T.bool]),
  yesButtonText: T.string,
  noButtonText: T.string
}

export default RouteLeavingGuard
