import constants from '@elenfs/elen-constants'

import { ImportSyncButton } from '../ImportSyncButton'
import syncTransactGql
  from '../../../graphql/mutations/dataImport/syncTransact.graphql'

const { DataImportTypes } = constants.dataImport

const TransactSyncButton = (props) => (
  <ImportSyncButton
    dataImportType={DataImportTypes.TRANSACT}
    gqlMutation={syncTransactGql}
    {...props}
  />
)

export default TransactSyncButton
