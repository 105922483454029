import T from 'prop-types'

import { TableResponsiveActionHeader } from '../../misc/StylePlaceholders'
import Heading from '../../misc/Heading'
import { NewChargeButton } from '../NewChargeButton'
import { capitalizeFirstLetter } from '../../../helpers/misc'

const ChargesHeader = ({ chargeType, isPolicyCharges }) => (
  <TableResponsiveActionHeader>
    <Heading as='h3'>{capitalizeFirstLetter(chargeType)}s</Heading>
    <NewChargeButton chargeType={chargeType} isPolicyCharges={isPolicyCharges} />
  </TableResponsiveActionHeader>
)

ChargesHeader.propTypes = {
  chargeType: T.string.isRequired
}

export { ChargesHeader }
