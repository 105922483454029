import classnames from 'classnames'

import { Droppable } from '../TasksKanbanBoard/helpers'
import { TaskKanbanColumnHeader } from './TaskKanbanColumnHeader'
import { TasksKanbanColumnContent } from './TasksKanbanColumnContent'
import { TaskKanbanColumnFooter } from './TaskKanbanColumnFooter'

import './TasksKanbanColumn.less'

const TasksKanbanColumn = ({
  tasks,
  title,
  info,
  onCreateColumnTask,
  droppableId,
  highlightCard,
  ...restProps
}) => {
  return (
    <Droppable droppableId={droppableId}>
      {provided => (
        <div
          className={classnames(
            'kanban-board__column',
            `kanban-board__column--${title}`,
            {
              'kanban-board__highlight': highlightCard
            }
          )}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <TaskKanbanColumnHeader
            title={title}
            tasksCount={tasks?.length}
            onCreateColumnTask={onCreateColumnTask}
          />
          <TasksKanbanColumnContent
            tasks={tasks}
            providedPlaceholder={provided.placeholder}
            {...restProps}
          />

          <TaskKanbanColumnFooter {...{
            ...restProps,
            title
          }}
          />
        </div>
      )}
    </Droppable>
  )
}

export { TasksKanbanColumn }
