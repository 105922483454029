import { Tooltip } from './../../../misc'
import { WorkflowIcon } from '../../../icons'

const WorkflowToolTip = ({ className, taskId }) => (
  <div className={className}>
    <Tooltip.Trigger
      as={WorkflowIcon}
      tooltip={Tooltip.Info}
      tooltipId={`workflow-tooltip-icon-${taskId}`}
      tooltipProps={{
        text: 'Task generated by a workflow.',
        position: 'top'
      }}
    />
  </div>
)

export { WorkflowToolTip }
