import T from 'prop-types'

import ImportInfo from './ImportInfo'

const ClientCreated = ({ clientFirstName, clientLastName }) => (
  <ImportInfo
    heading={(
      <>
        <b>New client added</b>&nbsp;-&nbsp;
        {clientFirstName}&nbsp;
        {clientLastName}
      </>
    )}
  />
)

ClientCreated.propTypes = {
  clientFirstName: T.string,
  clientLastName: T.string
}

export default ClientCreated
