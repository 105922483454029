import './EmailIntegrationList.less'

const EmailIntegrationList = ({ list }) => (
  <div className='email-integration-list'>
    {list.map(({ name, IconComponent, onItemClick, loading }) => (
      <div
        className='email-integration-list__item'
        key={name}
        onClick={!loading && onItemClick}
      >
        <div className='email-integration-list__item__icon-wrapper'>
          <IconComponent />
        </div>

        <div className='email-integration-list__item__name'>{name}</div>
      </div>
    ))}
  </div>
)
export { EmailIntegrationList }
