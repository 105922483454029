export const Colors = Object.freeze({
  GREY: 'grey',
  GREEN: 'green',
  BLUE: 'blue',
  RED: 'red'
})

export const State = Object.freeze({
  DISABLED: 'disabled',
  ENABLED: 'enabled'
})
