import moment from 'moment'
import constants from '@elenfs/elen-constants'
import { isEmpty } from 'ramda'
import { useQuery, useLazyQuery } from '@apollo/client'

import { FEE } from '../constants/charge'
import { getPolicyTypeByCategory, formatMoney } from './policy'
import { formatDate } from './date'

import {
  FeesTableHeader,
  CommissionsTableHeader,
  ClientFeesTableHeader,
  ClientCommissionsTableHeader
} from './enumLabels/charge'

const { RecurrentFrequencies, Currencies } = constants.misc

export const isRecurrentFrequency = (frequency) => Object.values(RecurrentFrequencies).includes(frequency)

const diffUnits = Object.freeze({
  [RecurrentFrequencies.WEEKLY]: 'weeks',
  [RecurrentFrequencies.MONTHLY]: 'months',
  [RecurrentFrequencies.QUARTERLY]: 'quarters',
  [RecurrentFrequencies.ANNUALLY]: 'years'
})

const getUnitsBetweenDates = (frequency, startDate, endDate) => {
  if (frequency === RecurrentFrequencies.HALF_YEARLY) {
    return Math.floor(moment(endDate).diff(startDate, 'quarters') / 2) + 1
  }

  return moment(endDate).diff(startDate, diffUnits[frequency]) + 1
}

const unitsInYear = Object.freeze({
  [RecurrentFrequencies.WEEKLY]: 52,
  [RecurrentFrequencies.MONTHLY]: 12,
  [RecurrentFrequencies.QUARTERLY]: 4,
  [RecurrentFrequencies.HALF_YEARLY]: 2,
  [RecurrentFrequencies.ANNUALLY]: 1
})

export const getUnitsInYear = ({ frequency, startDate, endDate }) => {
  if (!isRecurrentFrequency(frequency)) {
    return 1
  }

  if (!endDate) {
    return unitsInYear[frequency]
  }

  const unitsBetweenStartAndEnd = getUnitsBetweenDates(frequency, startDate, endDate)

  return Math.min(unitsBetweenStartAndEnd, unitsInYear[frequency])
}

export const getUnits = (values) => {
  const { frequency } = values
  const startDate = values.startDate && moment(values.startDate)
  const valuesEndDate = values.endDate && moment(values.endDate)
  const now = new Date()

  if (moment(now).endOf('day').isBefore(startDate)) {
    return 0
  }

  if (!isRecurrentFrequency(frequency)) {
    return 1
  }

  const endDate = valuesEndDate instanceof Date ? Math.min(now, valuesEndDate) : now

  return getUnitsBetweenDates(frequency, startDate, endDate)
}

export const getCalculatedCharge = (values, policyTransaction) => {
  const { useAmount, amount, policyTransactionPercentage: percentage } = values

  if (useAmount) {
    return amount
  }

  return policyTransaction && policyTransaction.amount && percentage
    ? {
        ...policyTransaction.amount,
        amount: percentage * policyTransaction.amount.amount / 100 / getUnitsInYear(values)
      }
    : {}
}

export const getAnnualCharge = (values, policyTransaction) => {
  const { amount, useAmount } = values

  if (!useAmount) {
    const transactionAmount = policyTransaction?.amount
    return {
      ...transactionAmount,
      // || 0 in case that these values are undefined, which can happen while data is being loaded.
      amount: ((transactionAmount?.amount || 0) * (values?.policyTransactionPercentage || 0)) / 100
    }
  }

  return {
    ...amount,
    amount: getUnitsInYear(values) * amount.amount
  }
}

export const isFee = (chargeType) => chargeType === FEE

export const getChargeHeaders = (type) => (
  isFee(type)
    ? FeesTableHeader
    : CommissionsTableHeader
)

export const getClientChargeHeaders = (type) => (
  isFee(type)
    ? ClientFeesTableHeader
    : ClientCommissionsTableHeader
)

export const buildContributionAndWithdrawalOptions = (obj) => {
  if (obj && isEmpty(obj)) { return }

  const list = []

  obj.contributions.forEach(item => {
    list.push({
      key: item.id,
      value: item.id,
      label: `${formatMoney(item.amount)} - ${formatDate(item.startDate)}`,
      amount: item.amount
    })
  })
  obj.withdrawals.forEach(item => {
    list.push({
      key: item.id,
      value: item.id,
      label: `${formatMoney(item.amount)} - ${formatDate(item.startDate)}`,
      amount: item.amount
    })
  })

  return list
}

export const buildAdviserRateOptions = (id, advisers) => {
  if (!id) { return }
  if (!advisers || !(advisers && advisers.length > 0)) { return }

  const selectedAdviser = advisers.filter(item => item.key === id)[0]

  const adviserRateList = [{
    key: id,
    value: selectedAdviser.defaultRate,
    label: `${JSON.stringify(selectedAdviser.defaultRate)} %`
  }]

  if (selectedAdviser && selectedAdviser.alternativeRate) {
    adviserRateList.push({
      key: id + 1,
      value: selectedAdviser.alternativeRate,
      label: `${JSON.stringify(selectedAdviser.alternativeRate)} %`
    })
  }

  return adviserRateList
}

export const buildAdviserOptions = users => Array.isArray(users)
  ? users.map(({ id, fullName, defaultRate, alternativeRate }) => ({
    key: id,
    id,
    value: id,
    label: fullName,
    defaultRate,
    alternativeRate
  }))
  : []

export const buildPolicyOptions = policies => Array.isArray(policies)
  ? policies.map(policy => ({
    key: policy.id,
    label: `${getPolicyTypeByCategory(policy)} ${policy.number ? `- ${policy.number}` : ''}`,
    value: policy.id
  }))
  : []

export const useLazyQueryAndBuildList = ({
  query,
  queryVariable,
  propName,
  buildList,
  variableName
}) => {
  const [getLazyQuery, { loading, error, data, called }] = useLazyQuery(query)

  // using called here to avoid infinite loop
  if (!called && queryVariable[variableName]) {
    getLazyQuery({ variables: queryVariable, fetchPolicy: 'cache-and-network' })
  }

  let foundData = null

  if (data && !(loading || error)) {
    foundData = propName ? data[propName] : data
  }

  let list = []
  if (foundData && buildList) {
    list = buildList(foundData)
  }

  return { loading, error, data: list }
}

export const useQueryAndBuildList = ({
  query,
  queryVariable,
  propName,
  buildList,
  isPaginated = false
}) => {
  const {
    loading, error, data
  } = useQuery(query, { variables: queryVariable, fetchPolicy: 'cache-and-network' })

  let foundData = null
  const resultData = propName && isPaginated ? data?.[propName]?.result : data?.[propName]

  if (data && !(loading || error)) {
    foundData = propName ? resultData : data
  }

  let list = []
  if (foundData && buildList) {
    list = buildList(foundData)
  }

  return { loading, error, data: list }
}

const calcPaymentSum = (transactions) => transactions.reduce((acc, t) => acc + t.totalAmount.amount, 0)

export const calcTotalCharges = (fees, commissions) => {
  const feesSum = calcPaymentSum(fees)

  const commissionsSum = calcPaymentSum(commissions)

  return {
    fees: feesSum,
    commissions: commissionsSum,
    total: feesSum + commissionsSum
  }
}

export const getDefaultGbpAmount = () => ({
  currency: Currencies.GBP,
  amount: ''
})
