import constants from '@elenfs/elen-constants'
import { path, pipe, propEq, find, clone, reject, prop } from 'ramda'

const { INVESTMENT, PENSIONS } = path(
  ['policy', 'common', 'AUMCategories'],
  constants
)

export const initialData = {
  labels: ['Investment:', 'Pension:'],
  datasets: [
    {
      data: [0, 0, 1],
      backgroundColor: ['#516173', '#F7BF47', '#C5D0DE']
    }
  ]
}

export const options = {
  borderWidth: 0,
  cutout: '68%',
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  },
  maintainAspectRatio: false
}

const extractTotalByCategory = (type, data) =>
  pipe(find(propEq('type', type)), path(['total', 'amount']))(data)

const getTotals = data => {
  const investmentTotal = extractTotalByCategory(INVESTMENT, data)
  const pensionTotal = extractTotalByCategory(PENSIONS, data)
  const isEmpty = !investmentTotal && !pensionTotal

  return { investmentTotal, pensionTotal, isEmpty }
}

export const mapDataToState = (data, setState, setEmptyState) => {
  const { investmentTotal, pensionTotal, isEmpty } = getTotals(data)

  if (isEmpty) {
    setEmptyState(true)
  } else {
    setEmptyState(false)
    const clonedInitialData = clone(initialData)
    const dataSet = clonedInitialData.datasets[0]
    dataSet.data = [investmentTotal, pensionTotal]
    setState(clonedInitialData)
  }
}

export const filterTabs = (tabs, allClients, myClients, loading) => {
  if (loading) return tabs.slice(0, 1)

  if (myClients === null) {
    return reject(prop('isMyClients'), tabs)
  }

  if (allClients === null) {
    return reject(prop('isAllClients'), tabs)
  }

  return tabs
}
