import PropTypes from 'prop-types'
import qs from 'query-string'
import { useNavigate } from 'react-router-dom'

import constants from '@elenfs/elen-constants'

import { PlusIcon } from '../../icons'
import { DropdownMenu, Button } from '../../misc'

const { CategoryStrings } = constants.policy.common

export const CreatePolicyButton = ({ clientId }) => {
  const navigate = useNavigate()

  return (
    <DropdownMenu
      className='create-policy-menu'
      contentClassName='create-policy-menu__content'
      direction={DropdownMenu.Directions.RIGHT}
      trigger={
        <Button color={Button.Colors.BLUE}>
          <PlusIcon />
          <span>Add policy</span>
        </Button>
      }
    >
      <DropdownMenu.List className='create-policy-menu__list'>
        {Object.keys(CategoryStrings).map(
          (category) => (
            <DropdownMenu.Item
              className='create-policy-menu__list-item'
              key={category}
              onClick={() => navigate({
                pathname: `/secure/clients/${clientId}/policies/create`,
                search: qs.stringify({ category })
              })}
            >
              {CategoryStrings[category]}
            </DropdownMenu.Item>
          )
        )}
      </DropdownMenu.List>
    </DropdownMenu>
  )
}

CreatePolicyButton.propTypes = {
  clientId: PropTypes.string.isRequired
}
