import classnames from 'classnames'

import { Table, TableResponsiveWrapper } from './../../misc'
import { renderLabel, calcTotal, formatIcon } from './helpers'

import './ClientCardTable.less'

const ClientCardTable = props => {
  const {
    data,
    comments,
    tableHeadersCaptions,
    tableRowCells,
    tableRowTotalCells,
    totalLabel,
    total,
    className
  } = props

  const isAutoWidthCells = tableHeadersCaptions.length > 3

  return (
    <TableResponsiveWrapper
      className={classnames('client-card-table', className, {
        'client-card-table--auto-width-but-first': isAutoWidthCells
      })}
    >
      <Table fixedLayout>
        <Table.Header>
          <Table.Row>
            {Object.values(tableHeadersCaptions).map(({ caption }) => (
              <Table.HeaderCell key={caption}>{caption}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.map((record, dataIndex) => (
            <Table.Row key={dataIndex}>
              {tableRowCells.map(({ propPath, formatFunc, dataLabel, Icon }, i) => (
                <Table.Cell key={i} data-label={dataLabel}>
                  {renderLabel(formatFunc, propPath, record, dataIndex)}
                  {Icon && formatIcon(Icon, record)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}

          {tableRowTotalCells && (
            <Table.Row className='client-card-table__row--bold client-card-table__row--no-border-bottom'>
              <Table.Cell className='client-card-table__desktop-cell'>
                {totalLabel || 'Total:'}
              </Table.Cell>
              {tableRowTotalCells.map(
                ({ propPath, dataLabel, emptyCell, getTotalFromProp }, i) =>
                  emptyCell
                    ? (
                      <Table.Cell key={i} />
                      )
                    : (
                      <Table.Cell key={i} data-label={dataLabel}>
                        {getTotalFromProp ? total : calcTotal(propPath, data)}
                      </Table.Cell>
                      )
              )}
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {comments && !!data.length && (
        <div className='client-card-table__comments'>
          <div>Additional notes</div>
          <div> {comments} </div>
        </div>
      )}
    </TableResponsiveWrapper>
  )
}

export { ClientCardTable }
