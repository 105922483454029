import { ascend, descend, prop, sort, compose, sortWith, comparator, path, toLower, is, identity } from 'ramda'

const toUpperCase = str => str && str.toUpperCase()

const sorter = (by, dir = 'DESC') => (
  dir === 'DESC' ? descend : ascend
)(prop(by))

export const sortListByProp = (data, by, direction) => (
  sort(sorter(by, direction), data)
)

export const sortByTwoPropsAsc = (data, prop1, prop2) => (
  sortWith([
    ascend(prop(prop1)),
    ascend(compose(toUpperCase, prop(prop2)))
  ], data)
)

export const sortByTwoPropsDesc = (data, prop1, prop2) => (
  sortWith([
    descend(prop(prop1)),
    descend(compose(toUpperCase, prop(prop2)))
  ], data)
)

export const sortData = (arr, propertyPath, secondaryPropPath, isAscending) => {
  const mainSort = comparator((a, b) => {
    const toLowerCaseIfString = (value) => is(String, value) ? toLower(value) : value

    const valueA = toLowerCaseIfString(path(propertyPath, a))
    const valueB = toLowerCaseIfString(path(propertyPath, b))

    if (!valueA) return isAscending
    if (!valueB) return !isAscending

    return isAscending ? valueA < valueB : valueA > valueB
  })

  const secondarySort = secondaryPropPath ? ascend(path(secondaryPropPath)) : identity

  return sortWith([mainSort, secondarySort])(arr)
}
