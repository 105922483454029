import T from 'prop-types'

import ChevronRight from '../../icons/ChevronRight'

import './NextButton.less'

const NextButton = ({ onClick }) => (
  <div onClick={onClick} className='next-button'>
    <div>Next</div>
    <ChevronRight />
  </div>
)

NextButton.propTypes = {
  onClick: T.func
}

export default NextButton
