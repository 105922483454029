import T from 'prop-types'
import classnames from 'classnames'

import './ProfitAndLossIcon.less'

const ProfitAndLossIcon = ({ icon: IconComponent, record }) => (
  <div
    className={classnames('profit-and-loss-icon-wrapper',
      `profit-and-loss-icon-wrapper--${record?.amount < 0 ? 'red' : 'green'}`,
      `profit-and-loss-icon-wrapper--${record?.amount < 0 ? 'down' : 'up'}`
    )}
  >
    <IconComponent />
  </div>
)

ProfitAndLossIcon.propTypes = {
  IconComponent: T.elementType,
  record: T.object.isRequired
}

export { ProfitAndLossIcon }
