import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { BackButton } from '../../../misc'

import '../SingleImportLogLayout.less'

export const SingleImportLogHeader = ({ pageStatus, link }) => {
  const location = useLocation()
  const prevPageNumber = location.state?.page

  return (
    <BackButton to={prevPageNumber ? undefined : link}>
      <div className='back-button__page_title'>
        {pageStatus}
      </div>
    </BackButton>
  )
}

SingleImportLogHeader.propTypes = {
  pageStatus: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}
