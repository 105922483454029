import T from 'prop-types'

import { Table } from '../../misc'
import { getChargeHeaders, getClientChargeHeaders } from '../../../helpers/charge'

const ChargesTableHeader = ({ chargeType, isPolicyCharges }) => (
  <Table.Header>
    <Table.Row>
      {Object.values(
        isPolicyCharges
          ? getChargeHeaders(chargeType)
          : getClientChargeHeaders(chargeType)
      ).map((item) => (
        <Table.HeaderCell key={item}>
          {item}
        </Table.HeaderCell>
      ))}
    </Table.Row>
  </Table.Header>
)

ChargesTableHeader.propTypes = {
  chargeType: T.string.isRequired
}

export { ChargesTableHeader }
