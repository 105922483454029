import { Heading } from './../../../misc'
import { WorkflowInstancesProgress } from '../../../workflows/WorkflowInstancesProgress'
import { TriggerWorkflowTemplateModalButton } from './TriggerWorkflowTemplateModalButton'

import './ClientWorkflowInstancesCard.less'

const ClientWorkflowInstancesCard = props => {
  const {
    workflowInstances,
    onInstanceClick,
    fetchClientWorkflowInstances,
    clientId
  } = props

  return (
    <div className='client-workflow-instances-card'>
      <div className='client-workflow-instances-card__header'>
        <Heading as='h3'>Workflows</Heading>
        <TriggerWorkflowTemplateModalButton
          {...{ clientId, fetchClientWorkflowInstances }}
        />
      </div>

      <div className='client-workflow-instances-card__body'>
        <WorkflowInstancesProgress {...{ onInstanceClick, workflowInstances }} />
      </div>
    </div>
  )
}

export { ClientWorkflowInstancesCard }
