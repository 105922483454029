import { ConfirmationModal } from '..'
import { Trash } from '../../icons'

const DeleteImageIcon = ({ onRemoveImage }) => (
  <ConfirmationModal.Trigger
    modalProps={{
      heading: 'Delete logo',
      text: 'Are you sure you want to delete your logo?',
      confirmationCallback: isTrue => isTrue && onRemoveImage(),
      awaitCallback: true,
      yesButtonText: 'Delete',
      noButtonText: 'Go back'
    }}
    as={Trash}
  />
)

export { DeleteImageIcon }
