import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import tasksGql from '../graphql/queries/tasks/tasks.graphql'
import clientTasksGql from '../graphql/queries/tasks/clientTasks.graphql'
import createTaskGql from '../graphql/mutations/tasks/createTask.graphql'
import createSubtaskGql from '../graphql/mutations/tasks/createSubtask.graphql'
import updateSubtaskGql from '../graphql/mutations/tasks/updateSubtask.graphql'
import updateTaskGql from '../graphql/mutations/tasks/updateTask.graphql'
import removeTaskGql from '../graphql/mutations/tasks/removeTask.graphql'
import removeSubtaskGql from '../graphql/mutations/tasks/removeSubtask.graphql'
import updateClientTasksOrderGql from '../graphql/mutations/tasks/updateClientTasksOrder.graphql'

import {
  tryCatchTasksCacheUpdate,
  updateTasksCacheOnCreateTask,
  updateClientTasksCacheOnCreateTask,
  handleTasksCacheOnRemoveTask,
  handleClientTasksOnRemoveTask,
  handleTaskCacheOnRemove,
  updateTasksCacheOnCreateSubtask,
  updateClientTasksCacheOnCreateSubtask,
  updateTasksCacheOnRemoveSubtask,
  updateClientTasksCacheOnRemoveSubtask,
  updateClientTasksCacheOnUpdateTask,
  updateClientTasksCacheOnOrder,
  updateTasksCacheOnUpdateTask
} from '../helpers/tasksCache'

const updateCacheOnCreateTask = (cache, results, variables) => {
  const updateCache = () => {
    const newTask = results?.data?.createTask
    updateTasksCacheOnCreateTask(cache, newTask, variables)
    updateClientTasksCacheOnCreateTask(cache, newTask)
  }

  tryCatchTasksCacheUpdate(updateCache)
}

const updateCacheOnCreateSubtask = (cache, results, variables) => {
  const updateCache = () => {
    const newSubtask = results?.data?.createSubtask
    updateTasksCacheOnCreateSubtask(cache, newSubtask, variables)
    updateClientTasksCacheOnCreateSubtask(cache, newSubtask)
  }

  tryCatchTasksCacheUpdate(updateCache)
}

const updateCacheOnRemoveTask = (cache, results, clientId, variables) => {
  const updateCache = () => {
    const id = results?.data?.removeTask?.id
    const clientIdFromCache = handleTasksCacheOnRemoveTask(cache, id, variables)
    clientId = clientId || clientIdFromCache
    handleClientTasksOnRemoveTask(cache, id, clientId)
    handleTaskCacheOnRemove(cache, id)
  }

  tryCatchTasksCacheUpdate(updateCache)
}

const updateCacheOnRemoveSubtask = (
  cache,
  results,
  parentId,
  clientId,
  variables
) => {
  const updateCache = () => {
    const id = results?.data?.removeSubtask?.id
    updateTasksCacheOnRemoveSubtask(cache, id, parentId, variables)
    updateClientTasksCacheOnRemoveSubtask(cache, id, parentId, clientId)
    handleTaskCacheOnRemove(cache, id)
  }

  tryCatchTasksCacheUpdate(updateCache)
}

const updateCacheOnUpdateTask = (cache, results, variables) => {
  const updateCache = () => {
    const updateTask = results?.data?.updateTask
    updateTasksCacheOnUpdateTask(cache, updateTask, variables)
    updateClientTasksCacheOnUpdateTask(cache, updateTask)
  }

  tryCatchTasksCacheUpdate(updateCache)
}

const updateCacheOnOrder = (cache, orderedTasks) => {
  const updateCache = () => {
    updateClientTasksCacheOnOrder(cache, orderedTasks)
  }

  tryCatchTasksCacheUpdate(updateCache)
}

export const useTasksGql = ({ task = {}, clientId, queryFilters } = {}) => {
  const queryGql = clientId ? clientTasksGql : tasksGql
  const variables = clientId ? { clientId } : queryFilters

  const { data, loading, error } = useQuery(queryGql, {
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const tasks = useMemo(() => (
    data?.tasks || data?.clientTasks || []
  ), [data])

  const [
    createTask,
    { loading: loadingOnCreateTask, error: errorOnCreateTask }
  ] = useMutation(createTaskGql, {
    update: (cache, results) =>
      updateCacheOnCreateTask(cache, results, variables)
  })

  const [
    updateTask,
    { loading: loadingOnUpdateTask, error: errorOnUpdateTask }
  ] = useMutation(updateTaskGql, {
    update: (cache, results) =>
      updateCacheOnUpdateTask(cache, results, variables)
  })

  const [
    createSubtask,
    { loading: loadingOnCreateSubtask, error: errorOnCreateSubtask }
  ] = useMutation(createSubtaskGql, {
    update: (cache, results) =>
      updateCacheOnCreateSubtask(cache, results, variables)
  })

  const [updateSubtask, { loading: loadingOnUpdateSubtask }] = useMutation(
    updateSubtaskGql
  )

  const [removeTask] = useMutation(removeTaskGql, {
    update: (cache, results) =>
      updateCacheOnRemoveTask(cache, results, clientId, variables)
  })

  const [removeSubtask] = useMutation(removeSubtaskGql, {
    update: (cache, results) => {
      updateCacheOnRemoveSubtask(
        cache,
        results,
        task?.parentId,
        clientId,
        variables
      )
    }
  })

  const [orderedTasks, setOrderedTasks] = useState([])
  const [updateClientTasksOrder] = useMutation(updateClientTasksOrderGql, {
    update: cache => updateCacheOnOrder(cache, orderedTasks),
    optimisticResponse: {
      updateClientTasksOrder: {
        success: true,
        __typename: 'ReorderClientTasks'
      }
    }
  })

  const onUpdateClientTasksOrder = (mutationPayload, orderedTasks) => {
    setOrderedTasks([...orderedTasks])
    setTimeout(() => updateClientTasksOrder(mutationPayload), 0)
  }

  return {
    tasks,
    loadingTasks: loading && !data,
    errorTasks: error,

    createTask,
    loadingOnCreateTask,
    errorOnCreateTask,

    updateTask,
    loadingOnUpdateTask,
    errorOnUpdateTask,

    createSubtask,
    loadingOnCreateSubtask,
    errorOnCreateSubtask,

    updateSubtask,
    loadingOnUpdateSubtask,

    removeTask,
    removeSubtask,

    updateClientTasksOrder: onUpdateClientTasksOrder
  }
}
