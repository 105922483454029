import PageContent from '../../misc/PageContent'
import PoliciesTable from '../PoliciesTable'

import './Policies.less'

const Policies = props => (
  <PageContent>
    <div className='policies'>
      <PoliciesTable {...props} />
    </div>
  </PageContent>
)

export default Policies
