import { ModalStickyLayout } from './ModalStickyLayout'
import { ModalStickyHeader } from './ModalStickyHeader'
import { ModalStickyFooter } from './ModalStickyFooter'
import { ModalStickyBody } from './ModalStickyBody'
import { ModalStickyFooterIndicators } from './ModalStickyFooterIndicators'

ModalStickyLayout.Header = ModalStickyHeader

ModalStickyLayout.Footer = ModalStickyFooter
ModalStickyLayout.Footer.Indicators = ModalStickyFooterIndicators

ModalStickyLayout.Body = ModalStickyBody

export { ModalStickyLayout } from './ModalStickyLayout'
