import { ClientDetailsBasePage } from '../../client/ClientDetailsBasePage'
import { CorporateClientDetailsSideMenu } from '../CorporateClientDetailsSideMenu'
import { CorporateClientDetailsPageCards } from './CorporateClientDetailsPageCards'

const CorporateClientDetailsPage = (props) => {
  const { canEdit } = props
  const baseUrl = canEdit && 'edit/'

  const anchorSpecificIds = [
    'company-details',
    'profit-and-loss'
  ]

  return (
    <ClientDetailsBasePage
      baseUrl={baseUrl}
      anchorSpecificIds={anchorSpecificIds}
      ClientDetailsSpecificSideMenu={CorporateClientDetailsSideMenu}
      ClientSpecificDetailsCards={CorporateClientDetailsPageCards}
      {...props}
    />
  )
}

export { CorporateClientDetailsPage }
