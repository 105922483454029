import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import UsersTableRow from './UsersTableRow'
import NoUsers from './NoUsers'
import { withNoItems } from '../../../hoc/pure'
import Heading from '../../../misc/Heading'
import Table from '../../../misc/Table'
import { TableResponsiveActionHeader } from '../../../misc/StylePlaceholders'
import { TableResponsiveWrapper } from '../../../misc/TableResponsiveWrapper'
import { sortByTwoPropsAsc } from '../../../../helpers/sort'

import './UsersTable.less'

const TableHeaders = [
  { caption: 'First name' },
  { caption: 'Last name' },
  { caption: 'Job role' },
  { caption: 'Permissions' },
  { caption: 'Default rate' },
  { caption: 'Alternative rate' },
  { caption: 'Email' },
  { caption: '' }
]

const UsersTable = ({ currentUser, activeUserList, inactiveUserList }) => (
  <div className='users-table-wrapper'>
    {!isEmpty(activeUserList) &&
      <div className='users-table'>
        <div className='users-table__content'>
          <TableResponsiveWrapper className='table-responsive--active'>
            <Table>
              <Table.Header>
                <Table.Row>
                  {Object.values(TableHeaders).map((item) => (
                    <Table.HeaderCell key={item.caption}>
                      {item.caption}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {sortByTwoPropsAsc(activeUserList, 'firstName', 'lastName').map(
                  (user) => (
                    <UsersTableRow
                      currentUser={currentUser}
                      key={user.id}
                      user={user}
                      url={`/secure/admin/sections/users/${user.id}`}
                    />
                  ))}
              </Table.Body>
            </Table>
          </TableResponsiveWrapper>
        </div>
      </div>}
    {!isEmpty(inactiveUserList) &&
      <div className='users-table'>
        <TableResponsiveActionHeader>
          <Heading as='h3'>Deactivated users</Heading>
        </TableResponsiveActionHeader>
        <div className='users-table__content'>
          <TableResponsiveWrapper className='table-responsive--disabled'>
            <Table>
              <Table.Header>
                <Table.Row>
                  {Object.values(TableHeaders).map((item) => (
                    <Table.HeaderCell key={item.caption}>
                      {item.caption}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {sortByTwoPropsAsc(inactiveUserList, 'firstName', 'lastName').map(
                  (user) => (
                    <UsersTableRow
                      key={user.id}
                      user={user}
                      currentUser={currentUser}
                      url={`/secure/admin/sections/users/${user.id}`}
                    />
                  ))}
              </Table.Body>
            </Table>
          </TableResponsiveWrapper>
        </div>
      </div>}
  </div>
)

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object.isRequired
}

const UsersTableEnhanced = withNoItems('users', NoUsers)(
  UsersTable
)

export default UsersTableEnhanced
