import { useState, Fragment } from 'react'
import { TaskStatuses } from '@elenfs/elen-constants'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { DragDropContext } from 'react-beautiful-dnd'

import { TasksKanbanColumn } from '../TasksKanbanColumn'
import { TasksFilter } from '../TasksFilter'
import { TaskDetailsModal } from '../TaskDetailsModal'
import { Button, Spinner } from '../../misc'
import { TasksChangeViewSwitch } from '../TasksChangeViewSwitch'
import { PlusIcon } from '../../icons'
import { useMobileScreen } from '../../../hooks'

import 'swiper/css'
import 'swiper/css/pagination'
import './TasksKanbanBoard.less'

const { TO_DO, IN_PROGRESS, COMPLETED } = TaskStatuses

const TasksKanbanBoard = ({
  className,
  openNewTaskModal,
  toDoTasks,
  inProgressTasks,
  completedTasks,
  filterProps,
  taskModalProps,
  tableProps,
  mutate,
  isViewAllCompletedShown,
  ...restProps
}) => {
  const { isMobile } = useMobileScreen()

  const SliderHolder = isMobile ? Swiper : Fragment
  const SlideHolder = isMobile ? SwiperSlide : Fragment

  const [activeDragged, setActiveDragged] = useState({
    'droppable-todo-task': null,
    'droppable-inprogress-task': null,
    'droppable-completed-task': null
  })
  const [selectedColumn, setSelectedColumn] = useState(null)

  const getTasks = (taskType) => {
    const tasksIndex = {
      'droppable-todo-task': toDoTasks,
      'droppable-inprogress-task': inProgressTasks,
      'droppable-completed-task': completedTasks
    }
    return tasksIndex[taskType] ?? []
  }

  const taskStatusToUpdate = (sourceId) => {
    const taskType = {
      'droppable-todo-task': TO_DO,
      'droppable-inprogress-task': IN_PROGRESS,
      'droppable-completed-task': COMPLETED
    }

    return taskType[sourceId] ?? TO_DO
  }

  const moveItem = async ({ sourceId, destinationId, source, destination }) => {
    const sourceTasks = getTasks(sourceId)
    const destinationTasks = getTasks(destinationId)
    const [reorderedItem] = sourceTasks.splice(source.index, 1)
    const status = taskStatusToUpdate(destinationId)

    const input = {
      assignedTo: reorderedItem?.assignedTo?.id,
      attachments: reorderedItem?.attachments,
      clientId: reorderedItem?.client?.id,
      description: reorderedItem?.description,
      dueDate: reorderedItem?.dueDate,
      id: reorderedItem?.id,
      status,
      summary: reorderedItem?.summary,
      type: reorderedItem?.type
    }

    destinationTasks.splice(destination.index, 0, reorderedItem)

    // NB: API is called only after moving task to another column
    // as we do not make calls when re-arranging tasks in the same column
    return await mutate({
      variables: {
        input: {
          ...input
        }
      }
    })
  }

  const handleOnDragEnd = (result) => {
    if (!result?.destination) return

    const { source, destination } = result

    if (result?.destination?.droppableId === selectedColumn) {
      return setActiveDragged(null)
    }

    setSelectedColumn(null)

    // check if task is being rearranged or moved into another column
    if (source.droppableId === destination.droppableId) {
      const tasks = getTasks(source.droppableId)
      const [reorderedItem] = tasks.splice(result.source.index, 1)
      tasks.splice(result.destination.index, 0, reorderedItem)
    } else {
      moveItem({
        sourceId: source.droppableId,
        destinationId: destination.droppableId,
        source,
        destination
      })
    }

    setActiveDragged(null)
  }

  const handleBeforeDragStart = (start) => {
    const { source } = start
    setActiveDragged({
      'droppable-completed-task': source.droppableId !== 'droppable-completed-task',
      'droppable-inprogress-task': source.droppableId !== 'droppable-inprogress-task',
      'droppable-todo-task': source.droppableId !== 'droppable-todo-task'
    })
    setSelectedColumn(start?.source?.droppableId)
  }

  return (
    <>
      <TaskDetailsModal {...{ ...taskModalProps }} />
      <TasksFilter disableStatus {...filterProps} />

      {
        tableProps?.loadingTasks
          ? <Spinner />
          : (
            <>
              <div className='tasks-section__header-container'>
                <TasksChangeViewSwitch />
                <Button
                  color={Button.Colors.BLUE}
                  responsive
                  onClick={openNewTaskModal}
                >
                  <PlusIcon /> <span> New Task </span>
                </Button>
              </div>
              <DragDropContext
                onDragEnd={handleOnDragEnd}
                onBeforeDragStart={handleBeforeDragStart}
              >
                <div className='kanban-board'>
                  <SliderHolder
                    {...isMobile && {
                      modules: [Pagination],
                      spaceBetween: 10,
                      pagination: true,
                      autoHeight: true,
                      breakpoints: {
                        300: { slidesPerView: 1 },
                        600: { slidesPerView: 2 }
                      },
                      touchReleaseOnEdges: true
                    }}
                  >
                    <SlideHolder>
                      <TasksKanbanColumn
                        title='To do'
                        tasks={toDoTasks}
                        onCreateColumnTask={() => openNewTaskModal({ status: TO_DO })}
                        additionalInitialValues={{ status: TO_DO }}
                        droppableId='droppable-todo-task'
                        highlightCard={activeDragged?.['droppable-todo-task']}
                        {...restProps}
                        {...tableProps}
                      />
                    </SlideHolder>
                    <SlideHolder>
                      <TasksKanbanColumn
                        title='In progress'
                        tasks={inProgressTasks}
                        onCreateColumnTask={() => openNewTaskModal({ status: IN_PROGRESS })}
                        additionalInitialValues={{ status: IN_PROGRESS }}
                        droppableId='droppable-inprogress-task'
                        highlightCard={activeDragged?.['droppable-inprogress-task']}
                        {...restProps}
                        {...tableProps}
                      />
                    </SlideHolder>
                    <SlideHolder>
                      <TasksKanbanColumn
                        title='Completed'
                        tasks={completedTasks}
                        onCreateColumnTask={() => openNewTaskModal({ status: COMPLETED })}
                        additionalInitialValues={{ status: COMPLETED }}
                        isViewAllCompletedShown={isViewAllCompletedShown}
                        droppableId='droppable-completed-task'
                        highlightCard={activeDragged?.['droppable-completed-task']}
                        {...restProps}
                        {...tableProps}
                      />
                    </SlideHolder>
                  </SliderHolder>
                </div>
              </DragDropContext>
            </>
            )
      }
    </>
  )
}

export { TasksKanbanBoard }
