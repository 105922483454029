import { EmptyPage } from '../../misc'

import './ClientsSectionEmpty.less'

const ClientsSectionEmpty = ({ hidden }) => (
  <EmptyPage
    text={
      <>
        <span>No results found. Try changing your search criteria</span>
      </>
    }
    hidden={hidden}
  />
)

export { ClientsSectionEmpty }
