import { compose } from 'ramda'
import T from 'prop-types'

import { withCurrentUser } from '../../hoc/container'
import MobileSideMenu from './MobileSideMenu'

const MobileSideMenuContainer = compose(
  withCurrentUser()
)(MobileSideMenu)

MobileSideMenuContainer.defaultProps = {
  logoutRedirect: '/login'
}

MobileSideMenuContainer.propTypes = {
  logoutRedirect: T.string
}

export { MobileSideMenuContainer }
