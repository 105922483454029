import { useCallback } from 'react'
import T from 'prop-types'

import { Modal } from '..'
import { ConfirmPasswordModalForm } from './ConfirmPasswordModalForm'

import './ConfirmPasswordModal.less'

const ConfirmPasswordModal = props => {
  const {
    isOpen,
    close,
    onSubmit,
    loading,
    error,
    heading,
    description,
    trigger,
    onClose,
    ...restProps
  } = props

  const onSubmitCB = useCallback(
    async props => {
      await onSubmit(props)
      close()
      if (onClose) onClose()
    },
    [onSubmit, onClose, close]
  )

  return (
    <Modal
      {...{
        className: 'confirm-password-modal',
        contentClassName: 'confirm-password-modal__modal-content',
        isOpen,
        close,
        trigger,
        ...restProps
      }}
    >
      <div className='confirm-password-modal__content'>
        <Modal.Heading className='confirm-password-modal__content__header'>
          {heading}
        </Modal.Heading>

        <ConfirmPasswordModalForm
          {...{ onSubmit: onSubmitCB, error, loading, close, description }}
        />
      </div>
    </Modal>
  )
}

ConfirmPasswordModal.defaultProps = {
  heading: 'Confirm password'
}

ConfirmPasswordModal.propTypes = {
  isOpen: T.bool,
  close: T.func.isRequired,
  onSubmit: T.func.isRequired,
  heading: T.string.isRequired,
  description: T.node,
  trigger: T.node,
  loading: T.bool,
  error: T.shape({
    msg: T.string.isRequired,
    isRequestError: T.bool.isRequired
  })
}

export { ConfirmPasswordModal }
