import { Component } from 'react'

const withNoItems = (itemsProp, NoItemsComponent) => WrappedComponent =>
  class WithNoItems extends Component {
    render () {
      const items = this.props[itemsProp]
      if (!items || !items.length) {
        return <NoItemsComponent {...this.props} />
      }

      return <WrappedComponent {...this.props} />
    }
  }

export default withNoItems
