import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import T from 'prop-types'

import removePolicyGql from '../../../../graphql/mutations/policy/removePolicy.graphql'
import policiesTableGql from '../../../../graphql/queries/policy/policiesTable.graphql'
import RemovePolicyButton from './RemovePolicyButton'

const RemovePolicyButtonContainer = ({ policyId, policy, disabled, ...restProps }) => {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const { category, number } = policy

  const [removeMutation] = useMutation(removePolicyGql, {
    variables: {
      id: policyId
    },
    update: (cache, { data: { removePolicy: { id } } }) => {
      const policies = cache.readQuery({
        query: policiesTableGql,
        variables: { clientId }
      }).policies
      const newPolicies = policies.filter((policy) => policy.id !== id)
      cache.writeQuery({
        query: policiesTableGql,
        data: {
          policies: newPolicies
        }
      })
    }
  })

  const removeModalCallback = useCallback(
    async (accepted) => {
      if (accepted) {
        await removeMutation()
        navigate(`/secure/clients/${clientId}/policies`)
      }
    }, [removeMutation]
  )

  return (
    <RemovePolicyButton
      removePolicy={removeModalCallback}
      category={category}
      number={number}
      disabled={disabled}
      {...restProps}
    />
  )
}

RemovePolicyButtonContainer.propTypes = {
  policyId: T.string.isRequired
}

export default RemovePolicyButtonContainer
