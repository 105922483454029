import appConfig from '../../../../appConfig'

const { INTEGRATIONS_LINK } = appConfig

const EmailInfo = () => (
  <div className='email-page__note'>
    <p>Want to know more about our integrations?</p>
    <p>
      For more information about our email integrations, please check our <a href={INTEGRATIONS_LINK} target='_blank' rel='noreferrer'>knowledge base</a>
    </p>
  </div>
)

export { EmailInfo }
