export const TableHeaders = Object.freeze({
  NEXT_REVIEW_DATE: 'Next review date',
  CLIENT_NAME: 'Client name',
  CURRENT_ADVISER: 'Current adviser',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  CITY: 'City',
  POST_CODE: 'Post code',
  EMAIL: 'Email',
  MOBILE_PHONE: 'Mobile'
})
