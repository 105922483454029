import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Currencies } from '@elenfs/elen-constants'

import currentUserGql from '../graphql/queries/user/currentUser.graphql'

export const useCurrentUser = () => {
  const [currency, setCurrency] = useState(null)

  const { data, loading, error } = useQuery(currentUserGql)

  useEffect(() => {
    const getCurrency = Currencies[data?.currentUser?.company?.currency] || Currencies.GBP
    setCurrency(getCurrency)
  }, [data?.currentUser?.company?.currency])

  return { currency, loading, error }
}
