const CalendarIcon = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' color='#A8AAB7' {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M15 3c.5522847 0 1 .44771525 1 1v1h2.5c.8284271 0 1.5.67157288 1.5 1.5v12c0 .8284271-.6715729 1.5-1.5 1.5h-13c-.82842712 0-1.5-.6715729-1.5-1.5v-12C4 5.67157288 4.67157288 5 5.5 5H8V4c0-.55228475.44771525-1 1-1s1 .44771525 1 1v1h4V4c0-.55228475.4477153-1 1-1zm3 4H6v11h12V7zm-8.5 6c.27614237 0 .5.2238576.5.5v1c0 .2761424-.22385763.5-.5.5h-1c-.27614237 0-.5-.2238576-.5-.5v-1c0-.2761424.22385763-.5.5-.5h1zm3 0c.2761424 0 .5.2238576.5.5v1c0 .2761424-.2238576.5-.5.5h-1c-.2761424 0-.5-.2238576-.5-.5v-1c0-.2761424.2238576-.5.5-.5h1zm-3-3c.27614237 0 .5.2238576.5.5v1c0 .2761424-.22385763.5-.5.5h-1c-.27614237 0-.5-.2238576-.5-.5v-1c0-.2761424.22385763-.5.5-.5h1zm3 0c.2761424 0 .5.2238576.5.5v1c0 .2761424-.2238576.5-.5.5h-1c-.2761424 0-.5-.2238576-.5-.5v-1c0-.2761424.2238576-.5.5-.5h1zm3 0c.2761424 0 .5.2238576.5.5v1c0 .2761424-.2238576.5-.5.5h-1c-.2761424 0-.5-.2238576-.5-.5v-1c0-.2761424.2238576-.5.5-.5h1z'
      />
    </g>
  </svg>
)

export default CalendarIcon
