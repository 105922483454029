import PropTypes from 'prop-types'
import classnames from 'classnames'

import SummaryRow from './SummaryRow'
import SummaryCell from './SummaryCell'
import { PageDivider } from '../PageDivider'

import './Summary.less'

const renderChildren = (data, marginEachRow, className) => data.map(({ label, value, link, disabled, extraTextWithDash, dangerText }, i) => (
  <SummaryRow
    key={i}
    className={
      classnames(
        marginEachRow &&
        // (i + 1) - current index counted from 1
        // !(currIndex % marginEachRow) - if there's no modulo when dividing with marginEachRow
        !((i + 1) % marginEachRow) &&
        'summary--margin-bottom',
        className
      )
    }
  >
    <SummaryCell>{label}</SummaryCell>
    <SummaryCell {... { link, dangerText, disabled, extraTextWithDash }}>{value}</SummaryCell>
  </SummaryRow>
))

const Summary = ({ children, data, marginEachRow, className }) => (
  <>
    {data[0]?.isDivider
      ? (
        <PageDivider />
        )
      : (
        <div className='summary'>
          {data && data.length
            ? renderChildren(data, marginEachRow, className)
            : children}
        </div>
        )}
  </>
)

const cellValueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.node
])

Summary.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: cellValueType,
    value: cellValueType,
    link: PropTypes.string,
    disabled: PropTypes.bool,
    extraTextWithDash: PropTypes.string,
    dangerText: PropTypes.bool,
    isDivider: PropTypes.bool
  })),
  marginEachRow: PropTypes.number
}

export default Summary
