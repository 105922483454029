import { EmailMessageParticipantTypes } from '../../../constants'

export const resolveEmailParticipants = (participants) => {
  let sender
  const recipient = []
  const cc = []
  const bcc = []

  if (participants && participants.length > 0) {
    for (const participant of participants) {
      switch (participant.type) {
        case EmailMessageParticipantTypes.SENDER:
          sender = {
            email: participant.email,
            name: participant.name
          }
          break
        case EmailMessageParticipantTypes.RECIPIENT:
          recipient.push({
            email: participant.email,
            name: participant.name
          })
          break
        case EmailMessageParticipantTypes.CC:
          cc.push({
            email: participant.email,
            name: participant.name
          })
          break
        case EmailMessageParticipantTypes.BCC:
          bcc.push({
            email: participant.email,
            name: participant.name
          })
          break
      }
    }
  }

  return { sender, recipient, cc, bcc }
}
