import { useEffect } from 'react'
import { withFormik } from 'formik'
import { path, omit, always } from 'ramda'
import T from 'prop-types'

import { Form, FormErrorMessages } from '../../form'
import { InputField, RadioGroupField } from '../../form/fields'
import { LoadingMessage, Button } from '..'

import { optionsType } from '../../../helpers/propTypes'

import './ImportClientForm.less'

const ImportClientForm = props => {
  const { values, accountsProps, mutationProps, handleSubmit } = props
  const {
    accountsOptions,
    accountsLoading,
    accountsError,
    onFilterAccounts
  } = accountsProps
  const { mutationLoading } = mutationProps
  const filter = values.filter

  useEffect(() => {
    onFilterAccounts(filter)
  }, [filter])

  const noClients =
    !accountsOptions?.length && !accountsLoading && !accountsError
  const disabled = accountsLoading || mutationLoading
  const showSubmitBtn = !accountsError && !accountsLoading

  return (
    <Form className='import-client-modal__form' onSubmit={handleSubmit}>
      <InputField
        name='filter'
        inputProps={{
          placeholder: 'Find client...',
          disabled
        }}
      />

      <div className='import-client-modal__form__client-list'>
        {noClients && (
          <span className='import-client-modal__form__client-list__no-clients'>
            No clients found
          </span>
        )}

        {accountsLoading && <LoadingMessage text='Fetching clients...' />}

        <FormErrorMessages validationErrors={{ accountsError }} />

        {!accountsLoading && !accountsError && (
          <RadioGroupField
            name='account'
            options={accountsOptions}
            inputId='radio-group-client-list'
            disabled={disabled}
          />
        )}
      </div>

      {showSubmitBtn && (
        <Button
          type='submit'
          color={Button.Colors.BLUE}
          disabled={!values.account || mutationLoading}
        >
          Import
        </Button>
      )}
    </Form>
  )
}

ImportClientForm.propTypes = {
  accountsProps: T.shape({
    accountsOptions: optionsType(),
    accountsLoading: T.bool,
    accountsError: T.string,
    fetchAccounts: T.func,
    onFilterAccounts: T.func
  }),
  mutationProps: T.shape({
    onSubmit: T.func,
    mutationLoading: T.bool,
    mutationError: T.string
  })
}

const EnhancedImportClientForm = withFormik({
  mapPropsToValues: always({ account: '', filter: '' }),
  handleSubmit: (values, { props }) => {
    const onSubmit = path(['mutationProps', 'onSubmit'], props)
    onSubmit(omit(['filter'], values))
  }
})(ImportClientForm)

export { EnhancedImportClientForm as ImportClientForm }
