import { useState } from 'react'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'

import { GoBackHeader } from '../../misc'
import { CorporateClientDetailsForm } from '../../corporateClient/CorporateClientDetailsForm'
import { IndividualClientDetailsForm } from '../IndividualClientDetailsForm'
import { ClientFormPageWrapper } from '../ClientFormPageWrapper'
import { IndividualClientDetailsSideMenu } from '../IndividualClientDetailsSideMenu'
import {
  CorporateClientDetailsSideMenu
} from '../../corporateClient/CorporateClientDetailsSideMenu'

import './ClientDetailsFormPage.less'

const ClientDetailsFormPage = ({ match, client, isCorporate, isClientPortal }) => {
  const [onSubmitForm, setOnSubmitForm] = useState()
  const { clientId } = useParams()
  const title = isClientPortal ? 'Edit fact find' : clientId ? 'Edit client' : 'New client'
  const clientDetailsProps = {
    match,
    onSubmitForm,
    isNewClient: !clientId
  }
  const isFactFindEditable = client?.isFactFindEditable

  return (
    <ClientFormPageWrapper>
      <GoBackHeader
        title={title}
        className='clients-section__content--header'
      />

      <div className={classnames('client-details-form-page__content__form',
        'clients-section__content--body'
      )}
      >
        {
          isCorporate
            ? (
              <>
                <CorporateClientDetailsSideMenu {...{ isClientPortal, isFactFindEditable, client, ...clientDetailsProps }} />
                <CorporateClientDetailsForm {...{ isClientPortal, client, setOnSubmitForm, isCorporate }} />
              </>
              )
            : (
              <>
                <IndividualClientDetailsSideMenu {...{ isClientPortal, isFactFindEditable, client, ...clientDetailsProps }} />
                <IndividualClientDetailsForm {...{ isClientPortal, client, setOnSubmitForm, isCorporate }} />
              </>
              )
        }
      </div>
    </ClientFormPageWrapper>
  )
}

export default ClientDetailsFormPage
