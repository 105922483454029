"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebhookEventStatuses = void 0;
const WebhookEventStatuses = exports.WebhookEventStatuses = Object.freeze({
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS'
});