import T from 'prop-types'

import { EmptyPage } from '../../misc'
import { NewPolicyTransactionButton } from './NewPolicyTransactionButton'
import { WITHDRAWAL, CONTRIBUTION } from '../../../constants/policy'

const PolicyTransactionsEmpty = ({ hidden, readOnly }) => (
  <EmptyPage
    text={`No contributions or withdrawals currently added${!readOnly ? ', add your first one' : ''}`}
    className='policy-transactions__empty-content'
    renderButton={() => (
      <>
        <NewPolicyTransactionButton policyTransactionType={CONTRIBUTION} />
        <NewPolicyTransactionButton policyTransactionType={WITHDRAWAL} />
      </>
    )}
    hidden={hidden}
    readOnly={readOnly}
  />
)

PolicyTransactionsEmpty.propTypes = {
  hidden: T.bool.isRequired
}

export { PolicyTransactionsEmpty }
