import classnames from 'classnames'

import SectionNavItem from './SectionNavItem'
import { Nav } from '../Nav'

import './SectionNav.less'

const SectionNav = ({ className, ...props }) => (
  <Nav
    className={classnames('section-nav', {
      [className]: className
    })}
    itemComponent={SectionNavItem}
    {...props}
  />
)

export default SectionNav
